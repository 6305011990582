import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import Image from "./Image";
import {
  BedOutlined,
  CalendarMonth,
  CalendarMonthOutlined,
  OpenInFullOutlined,
  ShowerOutlined,
} from "@mui/icons-material";
import { CallMadeOutlined } from "@material-ui/icons";
import Button from "./Button";

const CardFeature = ({ icon, text }) => {
  return (
    <Box sx={{ mr: 2, color: "#717171" }} display="flex" alignItems="center">
      {icon}
      <Typography
        variant="subtitle2"
        style={{ marginLeft: "10px", fontWeight: 400 }}
      >
        {text}
      </Typography>
    </Box>
  );
};

const ListingCardVariant = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        boxShadow: "0px 0px 80px 0px rgba(171, 190, 209, 0.20)",
        p: 1,
      }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box
        sx={{
          borderRadius: "10px",
          overflow: "hidden",
          position: "relative",
        }}
        flex="1"
      >
        <Image
          aspectRatio="16:9"
          src="https://cdn.pixabay.com/photo/2016/11/29/03/53/house-1867187_1280.jpg"
          width="100%"
        />
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            top: 0,
            display: "flex",
            alignItems: "center",
            p: 1,
          }}
        >
          <Box
            sx={{
              borderRadius: "10px",
              backgroundColor: "rgba(255,255,255,0.7)",
              px: 2,
              py: 1,
            }}
          >
            For Sell
          </Box>
        </Box>
      </Box>
      <Box sx={{ pl: 6 }}>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{ mt: 1 }}
          flexDirection="column-reverse"
        >
          <Box>
            <Typography variant="h6">Luxurious House</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              style={{ marginRight: "10px" }}
              color="primary"
            >
              ₹66L
            </Typography>
            <Typography variant="subtitle2">2,99/sqft </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="subtitle2">Budhanagar Delhi </Typography>
          <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
            <CardFeature icon={<BedOutlined />} text="3 Beds" />
            <CardFeature icon={<ShowerOutlined />} text="1 Baths" />
            <CardFeature icon={<OpenInFullOutlined />} text="1200 Sq ft" />
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <CardFeature icon={<CalendarMonthOutlined />} text="4 Days ago" />
          <Button
            disableElevation
            color="primary"
            variant="outlined"
            size="small"
          >
            View More
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ListingCardVariant;
