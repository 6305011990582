import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import BasicPrivateRoute from "../components/PrivateRoute/BasicPrivateRoute";
import Dashboard from "../components/dashboard/index";
import Post from "../components/dashboard/Post/posts";
import ForumActivity from "../components/dashboard/Post/posts";
import CreateCommunity from "../components/community/CreateCommunity";

 

import Test from "../components/activity/Test";
 
const Routes = (props) => {
  return [
    <BasicPrivateRoute
      exact
      path="/dashboard/test"
      noAppbar={true}
      component={Test}
    />,
    <BasicPrivateRoute
      exact
      path="/workspace"
      component={Dashboard}
      useBothSide={true}
    />,
    <BasicPrivateRoute
      exact
      path="/dashboard/test"
      component={CreateCommunity}
    />,
    <BasicPrivateRoute
      exact
      path="/dashboard/ForumActivity"
      component={ForumActivity}
    />,
    <BasicPrivateRoute
      exact
      path="/dashboard/forum"
      noAppbar={true}
      component={Post}
    />,
  ];
};

export default Routes;
