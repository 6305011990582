import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import NormalDialog from "../styled/CommonComponents/NormalDialog.js";
import ReactQuill from "react-quill";
import { useSelector, useDispatch } from "react-redux";
import { TextField, Button } from "@material-ui/core";
import { AiOutlinePicture } from "react-icons/ai";
import { ImFilePicture } from "react-icons/im";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton.js";
import { getAllFiles, updateCommunityApi } from "./api.call.js";
import CreateIcon from '@material-ui/icons/Create';
import LinearProgress from '@material-ui/core/LinearProgress';
import "./index.css"

const useStyles = makeStyles((theme) => ({
    labelImageCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        "& h3": {
            fontWeight: "500",
            fontSize: "16px",
            marginBottom: "15px"
        }
    },
    picAndCoverCont: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "flex-start",
    },
    iconImage: {
        fontSize: "85px",
        marginBottom: "15px",
        color: "#aeacac"
    },
    addProfile: {
        position: "absolute",
        bottom: "19px",
        right: "5px",
        fontSize: "30px",
        color: theme.palette.primary.main
    },
    addCover: {
        position: "absolute",
        bottom: "5px",
        right: "5px",
        fontSize: "30px",
        color: theme.palette.primary.main
    },
    mainCont: {
        width: "550px",
        "& h4": {
            marginTop: "15px",
            fontSize: "16px",
            fontWeight: "500"
        }
    },
    btnCont: {
        display: "flex",
        justifyContent: "center",
        marginTop: "10px"
    },
    profilePicSty: {
        width: "100px",
        height: "100px",
        borderRadius: "50%"
    },
    coverPicSty: {
        width: "150px",
        maxHeight: "100px",
    },
    editProfile: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        padding: "5px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        bottom: "5px",
        right: "5px",
    },
    editCover: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        padding: "5px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        bottom: "-2px",
        right: "-6px",
    },
}));




export default function AboutEditDialog(props) {
    const {
        openEditAbout, setOpenEditAbout, community, setCommunity, ...other
    } = props
    const dispatch = useDispatch();
    const classes = useStyles();

    const { createdFileIds } = useSelector((state) => state.file);

    const [description, setDescription] = useState(community?.description || "")
    const [title, setTitle] = useState(community?.displayName || "")
    const [profilePic, setProfilePic] = useState(null)
    const [coverPic, setCoverPic] = useState(null)
    const [uploadType, setUploadType] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setProfilePic(community?.displayPicture)
        setCoverPic(community?.cover)
    }, [])

    const handleClose = () => {
        setOpenEditAbout(false)
    }

    const updateCommunity = async () => {
        setLoading(true)
        const obj = {
            _id: community?._id,
            displayPicture: profilePic?._id,
            cover: coverPic?._id,
            displayName: title,
            description: description
        };

        await updateCommunityApi(obj)
            .then((data) => {
                setCommunity(data)
                setOpenEditAbout(false)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }


    useEffect(() => {
        if (createdFileIds && createdFileIds.length > 0 && uploadType === "profile") {
            getAllFiles({ files: createdFileIds })
                .then((data) => {
                    setProfilePic(data[0])
                    dispatch({ type: "FileUploadReset" });
                })
                .catch((err) => {
                    console.log(err)
                })
        } else if (createdFileIds && createdFileIds.length > 0 && uploadType === "cover") {
            getAllFiles({ files: createdFileIds })
                .then((data) => {
                    setCoverPic(data[0])
                    dispatch({ type: "FileUploadReset" });
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [createdFileIds])


    return (<>
        <NormalDialog
            openDialog={openEditAbout}
            handleCloseShare={handleClose}
            pageTitle={"Edit About"}
            loading={loading}
            content={<>
                <div className={classes.mainCont} >
                    <div className={classes.picAndCoverCont} >
                        <div className={classes.labelImageCont} >
                            <h3>Profile Picture</h3>
                            <CustomFileUploadButton
                                showComponent={
                                    <span onClick={() => { setUploadType("profile") }} >
                                        {profilePic?.url || profilePic?.thumbUrl ? (<>
                                            <img src={profilePic?.url || profilePic?.thumbUrl} className={classes.profilePicSty} />
                                            <div className={classes.editProfile} >
                                                <CreateIcon style={{ fontSize: "17px" }} />
                                            </div>
                                        </>) : (<>
                                            <AiOutlinePicture className={classes.iconImage} />
                                            <AddCircleIcon className={classes.addProfile} />
                                        </>)}
                                    </span>
                                }
                                parentType={"Community"}
                                parentId={community?._id}
                                fileNum={1}
                                givenFileTypes={["image"]}
                                givenMaxSize={6214400}
                                closeFunCall={()=>{ console.log("close dialog") }}
                            />
                        </div>
                        <div className={classes.labelImageCont} >
                            <h3>Cover Picture</h3>
                            <CustomFileUploadButton
                                showComponent={<span onClick={() => { setUploadType("cover") }} >
                                    {coverPic?.url || coverPic?.thumbUrl ? (<>
                                        <img src={coverPic?.url || coverPic?.thumbUrl} className={classes.coverPicSty} />
                                        <div className={classes.editCover} >
                                            <CreateIcon style={{ fontSize: "17px" }} />
                                        </div>
                                    </>) : (<>
                                        <ImFilePicture className={classes.iconImage} />
                                        <AddCircleIcon className={classes.addCover} />
                                    </>)}
                                </span>}
                                parentType={"Community"}
                                parentId={community?._id}
                                fileNum={1}
                                givenFileTypes={["image"]}
                                givenMaxSize={6214400}
                                closeFunCall={()=>{ console.log("close dialog") }}
                            />
                        </div>
                    </div>
                    <h4>Title</h4>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Enter Title"
                        size="small"
                        value={title}
                        onChange={(e) => { setTitle(e.target.value) }}
                        style={{ width: "100%", marginBottom: "10px" }}
                    />
                    <h4>About</h4>
                    <ReactQuill
                        {...other}
                        className='style2'
                        value={description}
                        placeholder={"Enter Description"}
                        onChange={setDescription}
                        modules={{
                            toolbar: false
                        }}
                        theme='snow'
                    >
                    </ReactQuill>
                    <div style={{ height: "5px", marginTop: "15px" }} >
                        {loading && (<LinearProgress />)}
                    </div>
                    <div className={classes.btnCont} >
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={loading}
                            style={{ width: "70%" }}
                            onClick={updateCommunity}
                        >
                            Save Changes
                        </Button>
                    </div>
                </div>
            </>}
        />
    </>);
}
