import { Box, Button, Drawer, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import FormBox from "../../../styled/generic/FormBox";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import Select from "../../../styled/generic/Select";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../../helpers/Api";
import TextField from "../../../styled/generic/TextField";
import SearchEntityDialog from "../../../styled/CommonComponents/SearchEntityDrawer";

const LISTING_TYPES = [
  "Sell",
  "Rent",
  "Investment",
  "Job",
  "Materials",
  "Roommate",
];

const LEAD_SOURCES = ["Social Media", "Friend"];

const LeadsDrawer = ({ open, setOpen, defaultSelectedListingId }) => {
  const [listings, setListings] = useState([]);
  const [listingsLoading, setListingsLoading] = useState([]);
  const [listingType, setListingType] = useState(LISTING_TYPES[0]);
  const [leadSource, setLeadSource] = useState(LEAD_SOURCES[0]);
  const [selectedListing, setSelectedListing] = useState();
  const [notes, setNotes] = useState("");

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const getListings = async () => {
    try {
      setListingsLoading(true);

      const { data } = await Api.post("/listing/getListingsByFilters", {
        userProfile: user?.profile,
      });

      if (data) {
        setListings(data);

        if (defaultSelectedListingId) {
          setSelectedListing(
            data?.find((item) => item?._id === defaultSelectedListingId)
          );
        } else {
          setSelectedListing(data[0]);
        }
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setListingsLoading(false);
    }
  };

  useEffect(() => {
    getListings();
  }, []);

  const createLead = async () => {
    try {
      const { data } = await Api.post("/listing/app/leads/create", {
        listingId: selectedListing?._id,
        userId: selectedUser?.parent?._id,
        profileId: selectedUser?.parent?.profile,
        leadSource: leadSource,
        listingType: listingType,
      });

      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Lead created successfully",
          },
        });
        setOpen(false);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const [showEntityDialog, setShowEntityDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <Box sx={{ width: "40vw", p: 4 }}>
        <FormBox label="Add Lead" labelTypographyVariant="h5">
          Fill the following details
        </FormBox>
        <SpaceBetween
          left={
            <FormBox label="Select User">
              <TextField
                fullWidth
                placeholder="Enter"
                value={selectedUser?.parent?.displayName}
                onClick={() => setShowEntityDialog(true)}
              />
              <SearchEntityDialog
                entity={"User"}
                multiple={false}
                curEntityId={user?.profile}
                onSave={(entity) => {
                  setSelectedUser(entity);
                  setShowEntityDialog(false);
                }}
                open={showEntityDialog}
                setOpen={setShowEntityDialog}
                title="Select User"
              />
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label="Listing Type">
              <Select
                fullWidth
                value={listingType}
                onChange={(e) => setListingType(e.target.value)}
              >
                {LISTING_TYPES.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormBox>
          }
          right={
            <FormBox label="Lead Source">
              <Select
                fullWidth
                value={leadSource}
                onChange={(e) => setLeadSource(e.target.value)}
              >
                {LEAD_SOURCES.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label="Listing">
              <Select
                fullWidth
                value={selectedListing}
                onChange={(e) => setSelectedListing(e.target.value)}
              >
                {listings?.map((item) => (
                  <MenuItem key={item?._id} value={item}>
                    {item?.title || "Untitled"}
                  </MenuItem>
                ))}
              </Select>
            </FormBox>
          }
          // right={<FormBox label="Status">Todo</FormBox>} //TODO
        />
        <FormBox label="Notes">
          <TextField
            multiline
            fullWidth
            rows={5}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            inputProps={{ maxLength: 200 }}
            helperText={`${notes?.length}/200`}
          />
        </FormBox>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            sx={{ mr: 2, width: 100 }}
            onClick={() => setOpen(false)}
          >
            Discard
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ mr: 2, width: 100 }}
            onClick={() => createLead()}
          >
            Add
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default LeadsDrawer;
