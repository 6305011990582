import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import TableContainer from "../../../styled/generic/TableContainer";
import ListingTableSingleUserCell from "../../../styled/generic/ListingTableSingleUserCell";
import ListingTableActionCell from "../../../styled/generic/ListingTableActionCell";
import ListingTableDateAgoCell from "../../../styled/generic/ListingTableDateAgoCell";
import ListingTableVisiblityCell from "../../../styled/generic/ListingTableVisiblityCell";
import { KeyboardArrowDown } from "@mui/icons-material";
import StatusButton from "../../../styled/generic/StatusButton";
import { useDispatch, useSelector } from "react-redux";
import { changeLeadStatusApi } from "../../listing.api";
import MyPopOver from "../../../styled/CommonComponents/MyPopOver";

const RequestsTableComponent = ({
  requests,
  setRequests,
  loading,
  actions,
  handleActionClick,
}) => {
  const columns = [
    "LISTING TITLE",
    "LISTING TYPE",
    "APPLIED DATE",
    "APPLIED BY",
    "ACCESSIBILITY",
    "STATUS",
    "ACTIONS",
  ];

  const statusOptions = [
    { label: "Approve", value: "Approved" },
    { label: "Reject", value: "Rejected" },
    { label: "Action", value: "Initiated" },
  ];

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const changeStatus = async (id, status) => {
    try {
      const { data } = await changeLeadStatusApi({
        listingAppId: id,
        status: status,
        userId: user?._id,
        profileId: user?.profile,
      });

      if (data) {
        setRequests(
          requests?.map((r) => {
            if (r?._id === id) {
              return { ...r, approvalStatus: status };
            } else {
              return r;
            }
          })
        );
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to change status",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to change status",
        },
      });
    }
  };

  return (
    <Box sx={{ backgroundColor: "#FFFFFF", minHeight: "400px" }}>
      <TableContainer
        columns={columns}
        data={requests}
        loading={loading}
      >
        {requests?.map((item) => (
          <tr>
            <td style={{ fontWeight: "bold" }}>{item?.title}</td>
            <td style={{ fontWeight: "bold" }}>{item?.type}</td>
            <td>
              <ListingTableDateAgoCell date={item?.createdAt} />
            </td>
            <td>
              <ListingTableSingleUserCell
                label={item?.owner?.parent?.displayName}
                url={item?.owner?.parent?.displayPicture?.url}
              />
            </td>

            <td>
              <ListingTableVisiblityCell isPrivate={item?.isPrivate} />
            </td>
            <td>
              <MyPopOver
                closeOnClick={true}
                appearContent={
                  <StatusButton
                    variant={
                      item?.approvalStatus === "Rejected"
                        ? "Error"
                        : item?.approvalStatus === "Approved"
                        ? "Success"
                        : "Info"
                    }
                  >
                    {item?.approvalStatus}
                  </StatusButton>
                }
                showContent={
                  <List sx={{ width: 200 }}>
                    {statusOptions
                      .filter((s) => s.value !== item?.approvalStatus)
                      ?.map((status) => (
                        <ListItem
                          disablePadding
                          onClick={() => changeStatus(item?._id, status.value)}
                        >
                          <ListItemButton>
                            <ListItemText primary={status.label} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                }
              />
            </td>
            <td onClick={(e) => e.stopPropagation()}>
              <ListingTableActionCell
                cellData={item}
                cellActions={actions}
                cellActionListener={handleActionClick}
              />
            </td>
          </tr>
        ))}
      </TableContainer>
    </Box>
  );
};

export default RequestsTableComponent;
