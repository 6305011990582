import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { initConversation, updateDeleteFlagForSingleFiles } from "./apiCall";
import TextField from "@material-ui/core/TextField";
import { getProfileById } from "../propertyManagement/apiCall";
import { Typography } from "@material-ui/core";
import FileUploadButton from "../file/Uploader/FileUploadButton";
import FilesViewer from "../file/Viewer/FilesViewer";
import UserWithRoleComponent from "../styled/CommonComponents/UserWithRoleComponent";
import { Box, Divider, Drawer } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import GroupAddIcon from "@material-ui/icons/GroupAdd";

const Msgroles = ["Admin", "User"];

const useStyles = makeStyles((theme) => ({
  contStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    overflowY: "auto",
    paddingTop: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "320px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingBottom: "20px",
    },
  },
  dpUploadCont: {
    width: "100%",
    padding: "15px",
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #E1E2E5",
    borderRadius: "10px",
  },
  mainContStyle: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      maxHeight: "380px",
    },
  },
}));

const CreateGroupDialog = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  var dateNow = new Date();
  const history = useHistory();
  const theme = useTheme();

  const {
    setOpenGroupCreate,
    openGroupCreate,
    getProfileChat,
    setLoadingBool,
    loadingBool,
  } = props;

  const auth = useSelector((state) => state.auth);
  const { createdFileIds } = useSelector((state) => state.file);

  const [open, setOpen] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [multiInputBool, setmultiInputBool] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [participantsRole, setParticipantsRole] = useState([]);
  const [name, setName] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    setOpen(openGroupCreate);
  }, [openGroupCreate]);

  const handleClose = () => {
    setOpen(false);
    setOpenGroupCreate(false);
  };

  useEffect(() => {
    getProfileById({ profileId: auth?.user?.profile })
      .then((data) => {
        if (data) {
          setUserProfile(data);
          const initProfileArrs = [data];
          setParticipants(initProfileArrs);

          const userWithRole = [
            {
              user: data,
              role: "Admin",
            },
          ];
          setParticipantsRole(userWithRole);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [auth?.user?.profile]);

  const removeSingleImgFromReducerAndDelete = async (selectedId) => {
    const filteredFileIds = createdFileIds.filter((id) => id != selectedId);

    dispatch({
      type: "AddFile",
      payload: {
        createdFileIds: [...filteredFileIds],
      },
    });

    await updateDeleteFlagForSingleFiles({ fileId: selectedId })
      .then((data) => {
        console.log("data");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createGroup = async () => {
    setLoadingBool(true);
    let participantIds = [];
    participants.map((participant) => {
      participantIds.push(participant?._id);
    });
    const convObj = {
      participants: participantIds,
      participantsRole,
      user: auth?.user?.profile,
      type: "Group",
      groupName: name,
      text: msg,
      groupDP: createdFileIds.length > 0 ? createdFileIds[0] : null,
    };

    await initConversation(convObj)
      .then(async (data) => {
        console.log("data");

        await getProfileChat();
        setParticipants(userProfile);
        const userWithRole = [
          {
            user: userProfile,
            role: "Admin",
          },
        ];
        setParticipantsRole(userWithRole);
        setName("");
        setMsg("");
        dispatch({ type: "FileUploadReset" });
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
    setLoadingBool(false);
  };

  //remove user
  const removeParticipants = (id) => {
    setmultiInputBool(!multiInputBool);
    const filteredUserArr = participants.filter((user) => user?._id !== id);
    setParticipants(filteredUserArr);

    let newUserAccessRoleArr = [];
    participantsRole.map((userRole) => {
      if (userRole?.user?._id !== id) {
        newUserAccessRoleArr.push(userRole);
      }
    });
    setParticipantsRole(newUserAccessRoleArr);
    setmultiInputBool(!multiInputBool);
  };

  //update user role
  const updateParticipantsWithRole = (value, index) => {
    setmultiInputBool(!multiInputBool);
    let inputDataArr = participantsRole;
    let editObj = {
      ...participantsRole[index],
      role: value,
    };
    inputDataArr[index] = editObj;
    setParticipantsRole(inputDataArr);
    setmultiInputBool(!multiInputBool);
  };

  //add new user
  const addNewParticipants = (newAccessRoleObj) => {
    const accessableUserWithRoleObj = {
      user: newAccessRoleObj?.user,
      role: newAccessRoleObj?.role,
    };
    let newAccessableUsersWithRoleArr = [
      ...participantsRole,
      accessableUserWithRoleObj,
    ];
    setParticipantsRole(newAccessableUsersWithRoleArr);
  };

  return (
    <>
      <Drawer
        open={openGroupCreate}
        onClose={() => setOpenGroupCreate(false)}
        anchor="right"
      >
        <Box
          sx={{ width: 540, padding: "15px", height: "94%" }}
          role="presentation"
          onClick={() => setOpenGroupCreate(true)}
        >
          <Box
            sx={{
              pb: "15px",
            }}
          >
            <Typography variant="h5">Create Group</Typography>
            <Typography variant="body2">create a group</Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              py: "15px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div className={classes.contStyle}>
              <TextField
                label="Group Name"
                id="outlined-size-small"
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />

              <div className={classes.dpUploadCont}>
                <Typography>Upload Display Picture</Typography>
                <FileUploadButton
                  parentType="Message"
                  used={false}
                  parentId={null}
                />
              </div>
              <FilesViewer
                fileIds={createdFileIds}
                deletable={true}
                styleBody={{
                  width: "50%",
                  height: "auto",
                }}
                handler={removeSingleImgFromReducerAndDelete}
              />

              <Box
                sx={{
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                {participants.length > 0 && (
                  <div style={{ width: "100%" }}>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Participants
                    </Typography>
                  </div>
                )}
                <UserWithRoleComponent
                  userArr={participants}
                  setUserArr={setParticipants}
                  userRoleArr={participantsRole}
                  setUserRoleArr={setParticipantsRole}
                  roles={Msgroles}
                  defaultType={"User"}
                  userProfile={auth?.user?.profile}
                  walletId={auth?.user?.wallet}
                  relationType={"Customer"}
                  userOp={true}
                  projectOp={false}
                  orgOp={true}
                  removeUserRole={removeParticipants}
                  updateRoleOfUserRole={updateParticipantsWithRole}
                  addUserRole={addNewParticipants}
                />
              </Box>

              <TextField
                placeholder="Message"
                id="outlined-basic"
                variant="outlined"
                size={"small"}
                style={{ width: "100%" }}
                value={msg}
                onChange={(e) => {
                  setMsg(e.target.value);
                }}
              />
              <Divider />
            </div>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginBottom: "20px",
              }}
            >
              <Button
                autoFocus
                onClick={handleClose}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loadingBool}
                onClick={createGroup}
                loadingPosition="end"
                variant="contained"
                endIcon={<GroupAddIcon style={{ color: "white" }} />}
              >
                <span>Create</span>
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default CreateGroupDialog;
