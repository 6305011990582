import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const alertObj = {
//     show: [true, false],
//     message: "Any text",
//     status: ["error", "warning", "info", "success"]
// };
const NotifyAlert = ({ alertObj, setAlertObj }) => {
    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertObj({
            ...alertObj,
            show: false
        })
    }

    return (<Snackbar
        open={alertObj?.show}
        autoHideDuration={5000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
        <Alert onClose={handleSnackBarClose} severity={alertObj?.status}>
            {alertObj?.message}
        </Alert>
    </Snackbar>);
};

export default NotifyAlert;   