import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import OnlineCalendarSvg from "../../../Assets/onlineCalendar.svg";
import SelectDocDialog from "../../doc/select.dialog";
import DocumentCardSvg from "../../../Assets/Doc_logo.svg";
import DescriptionIcon from "@material-ui/icons/Description";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  TextField,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import PreviewDialog from "../../doc/diffDocTables/Preview.Dialog";
import AddNewDialog from "../../doc/Add.New.Dialog";
import LessText from "../../styled/CommonComponents/LessText";
import { format } from "timeago.js";
import emptyIcon from "../../../Assets/vivekEmpty.svg";
import Skeleton from "@material-ui/lab/Skeleton";
import EventIcon from "@material-ui/icons/Event";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import Api from "../../../helpers/Api";
import TimeSheetDrawer from "../../global/TimeSheetDrawer";
import useTimesheetDrawer from "../../useTimesheetDrawer";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "100%",
    height: "370px",
    borderRadius: "10px",
    marginBottom: "20px",
    padding: "10px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 5px",
    },
  },
  cardTopHeading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > h3": {
      fontWeight: "510",
      fontSize: "17px",
      color: "#193B56",
    },
  },
  createBtn: {
    width: "25px",
    height: "25px",
    borderRadius: "6px",
    backgroundColor: "#c1def6",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#70b8f4",
      color: "white",
    },
  },
  topBox: {
    width: "100%",
    padding: "3px 10px",
    display: "flex",
    cursor: "pointer",
    alignItems: "flex-start",
    marginTop: "10px",
    marginBottom: "20px",
    marginRight: "15px",
    "& > p": {
      fontSize: "15px",
      marginTop: "10px",
      color: "#696969",
    },
    "&:hover": {
      backgroundColor: "#edecec",
    },
  },
  topBoxSvgCont: {
    width: "120px",
    height: "70px",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    "& > img": {
      width: "95px",
      height: "95px",
    },
  },
  container: {
    width: "100%",
    height: "215px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  timeSty: {
    fontSize: "10px",
    fontWeight: "500",
    color: "gray",
  },
  iconSty: {
    fontSize: "38px",
  },
  tbHeaderCell: {
    borderTop: "1px solid #e0e0e0",
    color: "#696969",
    fontSize: "15px",
    padding: "0px",
  },
  tbBodyCell: {
    border: "none",
    padding: "8px 5px",
  },
  avatarGroupSty: {
    maxWidth: "100px",
    "& .MuiAvatar-root": {
      width: "30px",
      height: "30px",
    },
  },
  skeletonCont: {
    paddingTop: "10px",
    width: "100%",
    height: "175px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  emptyCont: {
    display: "flex",
    width: "100%",
    height: "175px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    "& img": {
      height: "100px",
      width: "auto",
    },
    "& p": {
      fontSize: "12px",
      color: "gray",
      textAlign: "center",
      marginTop: "5px",
    },
  },
}));

const CalendarCard = ({ appointments, profileId, loader }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const [docDialogOpen, setDialogOpen] = useState(false);
  const [docPreviewDialog, setDocPreviewDialog] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState({});
  const [addNewDialog, setAddNewDialog] = useState(false);

  const [showEventMenu, setShowEventMenu] = useState(false);
  const [eventMenuAnchorEl, setEventMenuAnchorEl] = useState(null);
  const [todayTimesheets, setTodayTimesheets] = useState([]);

  const handleEventMenuOpen = (e) => {
    setShowEventMenu(true);
    setEventMenuAnchorEl(e.currentTarget);
  };

  const handleEventMenuClose = () => {
    setShowEventMenu(false);
    setEventMenuAnchorEl(null);
  };

  const { user } = useSelector((state) => state.auth);

  const { timesheetData, handleTimesheetOpen, timesheetDrawer } =
    useTimesheetDrawer();

  const getTodayTimesheets = async () => {
    try {
      const { data } = await Api.post("/timesheet/get", {
        userId: user._id,
      });
      setTodayTimesheets(data);
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch today timesheets",
        },
      });
    }
  };

  useEffect(() => {
    getTodayTimesheets();
  }, []);

  useEffect(() => {
    setTodayTimesheets((prevItems) =>
      prevItems.map((item) =>
        item._id === timesheetData._id ? { ...timesheetData } : item
      )
    );
  }, [timesheetData]);

  const [
    showPersonalTimesheetCreationDialog,
    setShowPersonalTimesheetCreationDialog,
  ] = useState(false);

  const [personalTimesheetTitle, setPersonalTimesheetTitle] = useState("");

  const createPersonalTimesheet = async () => {
    try {
      if (!personalTimesheetTitle || personalTimesheetTitle.length < 1) {
        return;
      }

      const { data } = await Api.post("/timesheet/personal/create", {
        userId: user._id,
        title: personalTimesheetTitle,
        date: new Date(),
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Timesheet created successfully!",
        },
      });
      setShowPersonalTimesheetCreationDialog(false);

      setTodayTimesheets([...todayTimesheets, data]);
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create timesheet",
        },
      });
    }
  };

  const openPersonalTimesheetDialog = () => {
    let title = `${moment().format("D MMMM YYYY")}`;
    setPersonalTimesheetTitle(title);
    setShowPersonalTimesheetCreationDialog(true);
    handleEventMenuClose();
  };

  return (
    <>
      {timesheetDrawer}
      <Dialog
        open={showPersonalTimesheetCreationDialog}
        onClose={() => setShowPersonalTimesheetCreationDialog(false)}
      >
        <DialogTitle>Personal Timesheet</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can use a personal timesheet if you want to track personal
            activities
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Timesheet Name"
            type="text"
            fullWidth
            variant="outlined"
            value={personalTimesheetTitle}
            onChange={(e) => setPersonalTimesheetTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPersonalTimesheetCreationDialog(false)}>
            Cancel
          </Button>
          <Button onClick={() => createPersonalTimesheet()}>Create</Button>
        </DialogActions>
      </Dialog>
      <Menu
        id="basic-menu"
        anchorEl={eventMenuAnchorEl}
        open={showEventMenu}
        onClose={handleEventMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Paper elevation={0}>
          <MenuList dense>
            <MenuItem
              onClick={() => {
                history.push("/calendar/" + profileId + "?mode=open");
              }}
            >
              <ListItemIcon>
                <EventAvailableIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>New Event</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => openPersonalTimesheetDialog()}>
              <ListItemIcon>
                <ReceiptOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>New Personal Timesheet</ListItemText>
            </MenuItem>
            {todayTimesheets && todayTimesheets.length > 0 && (
              <>
                <Divider />
                {todayTimesheets.map((timesheet, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      handleTimesheetOpen(timesheet);
                      handleEventMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <AssessmentOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {timesheet?.scope === "Organization"
                        ? timesheet?.organization?.displayName + " (Timesheet)"
                        : timesheet?.title + " (Timesheet)"}
                    </ListItemText>
                  </MenuItem>
                ))}
              </>
            )}
          </MenuList>
        </Paper>
      </Menu>

      {/* <AddNewDialog profileId={user.profile} defaultOpen={addNewDialog} /> */}

      <Paper className={classes.cardContainer}>
        <div className={classes.cardTopHeading}>
          <h3>Event Calendar</h3>
          <IconButton
            className={classes.createBtn}
            onClick={(e) => {
              // setAddNewDialog(true);
              handleEventMenuOpen(e);
            }}
            color="primary"
          >
            +
          </IconButton>
        </div>

        <Paper
          elevation={1}
          onClick={() => history.push("/calendar/" + profileId)}
          className={classes.topBox}
        >
          <div className={classes.topBoxSvgCont}>
            <img src={OnlineCalendarSvg} />
          </div>
          <p>
            Create events, customize your calendar and keep track of them
            efficiently.
          </p>
        </Paper>

        {loader ? (
          <>
            <TableContainer style={{ width: "100%" }}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow
                    style={{
                      position: "relative",
                      zIndex: "99",
                      height: "40px",
                    }}
                  >
                    <TableCell
                      align="left"
                      className={classes.tbHeaderCell}
                      style={{ paddingLeft: "5px" }}
                    >
                      Appointment Title
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tbHeaderCell}
                      style={{ padding: "0px", width: "90px" }}
                    >
                      Start Date
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tbHeaderCell}
                      style={{ paddingRight: "5px", width: "110px" }}
                    >
                      Participants
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <div className={classes.skeletonCont}>
              <Skeleton
                variant="rect"
                style={{ width: "100%", marginBottom: "10px" }}
                height={50}
              />
              <Skeleton
                variant="rect"
                style={{ width: "100%", marginBottom: "10px" }}
                height={50}
              />
              <Skeleton
                variant="rect"
                style={{ width: "100%", marginBottom: "10px" }}
                height={50}
              />
            </div>
          </>
        ) : (
          <>
            {appointments && appointments?.length > 0 ? (
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow
                      style={{
                        position: "relative",
                        zIndex: "99",
                        height: "40px",
                      }}
                    >
                      <TableCell
                        align="left"
                        className={classes.tbHeaderCell}
                        style={{ paddingLeft: "5px" }}
                      >
                        Appointment Title
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tbHeaderCell}
                        style={{ padding: "0px", width: "90px" }}
                      >
                        Start Date
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tbHeaderCell}
                        style={{ paddingRight: "5px", width: "110px" }}
                      >
                        Participants
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appointments &&
                      appointments.length > 0 &&
                      appointments.map((appointment) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={appointment?.id}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            history.push(
                              "/calendar/" +
                              profileId +
                              "?eventId=" +
                              appointment?.id
                            );
                          }}
                        >
                          <TableCell
                            align="left"
                            className={classes.tbBodyCell}
                            style={{ paddingLeft: "0px" }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <EventIcon
                                // className={classes.iconSty}
                                color="primary"
                                fontSize="medium"
                              />
                              <div style={{ marginLeft: "5px" }}>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    color: "#333333",
                                    fontWeight: "510",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {appointment?.title || "Untitled"}
                                </p>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tbBodyCell}
                            style={{
                              padding: "0px",
                              color: "#4F4F4F",
                              fontSize: "13px",
                              fontWeight: "510",
                            }}
                          >
                            {format(appointment?.startDate)}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.tbBodyCell}
                            style={{ padding: "10px 5px" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <AvatarGroup
                                max={3}
                                className={classes.avatarGroupSty}
                              >
                                {appointment?.addedBy && (
                                  <Avatar
                                    key={appointment?.addedBy?._id}
                                    alt={
                                      appointment?.addedBy?.parent?.displayName
                                    }
                                    src={
                                      appointment?.addedBy?.parent
                                        ?.displayPicture?.url
                                    }
                                  />
                                )}
                                {appointment?.participants &&
                                  appointment?.participants?.length > 0 &&
                                  appointment.participants.map(
                                    (participant, idx) => (
                                      <Avatar
                                        key={idx}
                                        alt={participant?.parent?.displayName}
                                        src={
                                          participant?.parent?.displayPicture
                                            ?.url
                                        }
                                      />
                                    )
                                  )}
                              </AvatarGroup>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <>
                <TableContainer style={{ width: "100%" }}>
                  <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                      <TableRow
                        style={{
                          position: "relative",
                          zIndex: "99",
                          height: "40px",
                        }}
                      >
                        <TableCell
                          align="left"
                          className={classes.tbHeaderCell}
                          style={{ paddingLeft: "5px" }}
                        >
                          Appointment Title
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tbHeaderCell}
                          style={{ padding: "0px", width: "90px" }}
                        >
                          Start Date
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.tbHeaderCell}
                          style={{ paddingRight: "5px", width: "110px" }}
                        >
                          Participants
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
                <div className={classes.emptyCont}>
                  <img src={emptyIcon} />
                  <p>
                    There is nothing to show here.
                    <br />
                    <span
                      style={{
                        color: theme.palette.primary.main,
                        marginLeft: "3px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push("/calendar/" + profileId);
                      }}
                    >
                      Click to add new appointment.
                    </span>
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </Paper>
    </>
  );
};
export default CalendarCard;
