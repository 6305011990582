import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  Checkbox,
  Chip,
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  Grid,
  Divider,
  Breadcrumbs,
  Link,
  Card,
  CardContent,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchIcon from '@mui/icons-material/Search';
import Api from '../../helpers/Api';
import CustomFileUploadButton from '../file/Uploader/CustomFileUploadButton';
import { createFileDocs } from '../finance2o/commonComponent/transaction/api';

const useStyles = makeStyles((theme) => ({
  titleDropZoneCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  }
}));

const StickyHeader = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 1100,
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const ContentBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  padding: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  width: '18.75%', // Reduced by 1/4
}));

const PaginationBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(1),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const DocPicker = ({
  initialProfileId, onSelectionChange, selectedItems,
  setSelectedItems, initialModelName, parentModelName,
  parentId
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { createdFileIds } = useSelector((state) => state.file);
  const { user } = useSelector((state) => state.auth);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);

  const [modelName, setModelName] = useState(initialModelName);
  const [profileId, setProfileId] = useState(initialProfileId || user?.profile?._id);
  const [selectedProfileId, setSelectedProfileId] = useState(profileId);
  const [orgs, setOrgs] = useState([]);
  const [projects, setProjects] = useState([]);
  const [folders, setFolders] = useState([]);
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [foldersPage, setFoldersPage] = useState(1);
  const [foldersLimit] = useState(10);
  const [foldersPagination, setFoldersPagination] = useState({});
  const [docsPage, setDocsPage] = useState(1);
  const [docsLimit] = useState(10);
  const [docsPagination, setDocsPagination] = useState({});
  const [docTitle, setDocTitle] = useState("");
  const [docDescription, setDocDescription] = useState("");


  const setUpOrgsAndProjects = useCallback(() => {
    const organizationTeams = teamIds
      .map((id) => teamDictionary[id])
      .filter((team) => team?.parent?.model === 'Organization');
    setOrgs(organizationTeams.map((team) => team.parent));

    const projectTeams = teamIds
      .map((id) => teamDictionary[id])
      .filter((team) => team?.parent?.model === 'Project');
    setProjects(projectTeams.map((team) => team.parent));
  }, [teamIds, teamDictionary]);

  useEffect(() => {
    setUpOrgsAndProjects();
  }, [setUpOrgsAndProjects]);

  const fetchFolders = async (id, page = 1) => {
    setLoading(true);
    try {
      const response = await Api.post('/doc/picker/folder/get', {
        profileId: id,
        page: page,
        limit: foldersLimit,
        searchText: searchText,
      });
      if (response && response.data) {
        setFolders(response.data);
        setFoldersPagination(response.pagination);
      } else {
        dispatch({
          type: 'AddApiAlert',
          payload: {
            success: false,
            message: 'Failed to fetch folders',
          },
        });
      }
    } catch (error) {
      console.error('Error fetching folders:', error);
      dispatch({
        type: 'AddApiAlert',
        payload: {
          success: false,
          message: 'An error occurred while fetching folders',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchDocs = async (id, page = 1) => {
    setLoading(true);
    try {
      console.log({
        profileId: id,
        page: page,
        limit: docsLimit,
        searchText: searchText,
      })
      const response = await Api.post('/doc/picker/get', {
        profileId: id,
        page: page,
        limit: docsLimit,
        searchText: searchText,
      });

      if (response && response.data) {
        setDocs(response.data);
        setDocsPagination(response.pagination);
      } else {
        dispatch({
          type: 'AddApiAlert',
          payload: {
            success: false,
            message: 'Failed to fetch documents',
          },
        });
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
      dispatch({
        type: 'AddApiAlert',
        payload: {
          success: false,
          message: 'An error occurred while fetching documents',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Don't reset selectedItems here to maintain selections across navigation
    if (profileId) {
      fetchFolders(profileId, foldersPage);
      fetchDocs(profileId, docsPage);
    }
  }, [profileId, foldersPage, docsPage, searchText]);

  const updateInitialBreadcrumbs = () => {
    if (modelName === 'User') {
      setBreadcrumbs([{ name: 'User', profileId: user?.profile?._id }]);
    } else if (modelName === 'Project') {
      const project = projects.find((p) => p.profile._id === selectedProfileId);
      setBreadcrumbs([{ name: project?.displayName || 'Project', profileId: selectedProfileId }]);
    } else if (modelName === 'Organization') {
      const org = orgs.find((o) => o.profile._id === selectedProfileId);
      setBreadcrumbs([{ name: org?.displayName || 'Organization', profileId: selectedProfileId }]);
    }
  };

  const handleModelChange = (event) => {
    const newModelName = event.target.value;
    setModelName(newModelName);
    let newProfileId = '';
    if (newModelName === 'User') {
      console.log("under user 123")
      newProfileId = user?.profile;
      console.log("under user 321")
    } else if (newModelName === 'Project' && projects.length > 0) {
      newProfileId = projects[0].profile._id;
    } else if (newModelName === 'Organization' && orgs.length > 0) {
      newProfileId = orgs[0].profile._id;
    }
    console.log(newProfileId)
    setSelectedProfileId(newProfileId);
    setProfileId(newProfileId);
    setFoldersPage(1);
    setDocsPage(1);
    updateInitialBreadcrumbs();
  };

  const handleProfileChange = (event) => {
    const newSelectedId = event.target.value;
    setSelectedProfileId(newSelectedId);
    setProfileId(newSelectedId);
    setFoldersPage(1);
    setDocsPage(1);
    updateInitialBreadcrumbs();
  };

  const handleFolderClick = (folder) => {
    // Reset pagination
    setFoldersPage(1);
    setDocsPage(1);

    // Update profileId
    setProfileId(folder.profile);

    // Update breadcrumbs without duplicates
    setBreadcrumbs((prev) => {
      if (prev.length > 0 && prev[prev.length - 1].profileId === folder.profile) {
        return prev;
      }
      return [...prev, { name: folder.title, profileId: folder.profile }];
    });
  };

  const handleBreadcrumbClick = (index) => {
    const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
    setBreadcrumbs(newBreadcrumbs);
    setProfileId(newBreadcrumbs[newBreadcrumbs.length - 1].profileId);

    // Reset pagination
    setFoldersPage(1);
    setDocsPage(1);
  };

  const handleItemSelect = (item) => {
    setSelectedItems((prev) => {
      const isSelected = prev.some((i) => i._id === item._id);
      const newItems = isSelected ? prev.filter((i) => i._id !== item._id) : [...prev, item];
      onSelectionChange(newItems);
      return newItems;
    });
  };

  useEffect(() => {
    setFoldersPage(1);
    setDocsPage(1);
  }, [searchText]);

  useEffect(() => {
    updateInitialBreadcrumbs();
  }, [modelName, selectedProfileId]);

  const createNewDocs = async () => {
    if (createdFileIds.length > 0) {
      setLoading(true)
      await createFileDocs({
        title: docTitle,
        description: docDescription,
        files: createdFileIds,
        profile: user?.profile,
        user: user?._id,
        parent: parentId,
        parentModelName: parentModelName
      })
        .then(async (data) => {
          handleItemSelect(data)
          dispatch({ type: "FileUploadReset" });
          setDocTitle("")
          setDocDescription("")
        })
        .catch((err) => {
          console.log(err);
        })
      setLoading(false)
    }
  }

  return (
    <div>
      {(selectedItems && selectedItems.length > 0) && (<>
        <StickyHeader>
          <Typography variant="h6">Selected Document</Typography>
        </StickyHeader>
        <Box mb={2}>
          <Box display="flex" flexWrap="wrap">
            {selectedItems.map((item) => (
              <Chip
                key={item._id}
                label={item.title}
                onDelete={() => handleItemSelect(item)}
                sx={{ m: 0.5 }}
                size="small"
              />
            ))}
          </Box>
        </Box>
      </>)}

      <StickyHeader>
        <Typography variant="h6">Add Document</Typography>
      </StickyHeader>
      <ContentBox>
        <Grid container spacing={2} >
          <Grid item sm={12} lg={6} >
            <TextField
              fullWidth
              label={<>Title<span style={{ color: "red" }}>*</span></>}
              size="small"
              value={docTitle}
              onChange={(e) => {
                setDocTitle(e.target.value)
              }}
              style={{ marginBottom: "20px" }}
              variant="outlined"
            />
            <TextField
              label={<>Description<span style={{ color: "red" }}>*</span></>}
              id="outlined-multiline-static"
              multiline
              fullWidth
              rows={4}
              size="small"
              value={docDescription}
              onChange={(e) => {
                setDocDescription(e.target.value)
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item sm={12} lg={6} >
            {(docTitle && docTitle.length > 2 && docDescription && docDescription.length > 2) ? (
              <CustomFileUploadButton
                showComponent={
                  <Box
                    sx={{
                      width: "100%",
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '16px 20px',
                      gap: '12px',
                      flexShrink: 0,
                      borderRadius: '10px',
                      border: '1px dashed #f9f9f9',
                      background: '#f9f9f9',
                    }}
                  >
                    <img
                      src="/icons/app/document-upload.svg"
                      style={{ width: '28px', height: '28px' }}
                      alt="upload icon"
                    />
                  </Box>
                }
                parentType={parentModelName}
                parentId={parentId}
                fileNum={25}
                givenFileTypes={["image", "video", "html", "xhtml", "csv", "doc", "docx", "pdf", "rtf", "plain", "ppt", "pptx", "xls", "xlsx"]}
                givenMaxSize={26214400}
                closeFunCall={createNewDocs}
              />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '16px 20px',
                  gap: '12px',
                  flexShrink: 0,
                  borderRadius: '10px',
                  border: '1px dashed #ABBED1',
                  background: '#F5F7FA',
                }}
              >
                <img
                  src="/icons/app/document-upload.svg"
                  style={{ width: '28px', height: '28px' }}
                  alt="upload icon"
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </ContentBox>

      <StickyHeader>
        <Typography variant="h6">Pick Document</Typography>
      </StickyHeader>
      <ContentBox>
        <Box display="flex" alignItems="center" mb={2}>
          <StyledFormControl>
            <InputLabel id="model-select-label">Model</InputLabel>
            <Select native value={modelName} onChange={handleModelChange} label="Model" size="small">
              <option value="User">User</option>
              <option value="Project">Project</option>
              <option value="Organization">Organization</option>
            </Select>
          </StyledFormControl>
          {modelName !== 'User' && (
            <StyledFormControl>
              <InputLabel id="profile-select-label">{modelName}</InputLabel>
              <Select
                native
                labelId="profile-select-label"
                value={selectedProfileId}
                onChange={handleProfileChange}
                label={modelName}
                disabled={(modelName === 'Project' ? projects : orgs).length === 0}
                size="small"
              >
                {(modelName === 'Project' ? projects : orgs).map(
                  (item) =>
                    item?._id &&
                    item?.profile?._id && (
                      <option key={item?.profile?._id} value={item?.profile?._id}>
                        {item?.displayName}
                      </option>
                    )
                )}
              </Select>
            </StyledFormControl>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', width: '33%', ml: 2 }}>
            <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <TextField
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Box>
        </Box>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
          {breadcrumbs.map((crumb, index) => (
            <Link
              key={index}
              color="inherit"
              onClick={() => handleBreadcrumbClick(index)}
              style={{ cursor: 'pointer' }}
            >
              {crumb.name}
            </Link>
          ))}
        </Breadcrumbs>
        {loading ? (
          <Box display="flex" justifyContent="center" my={2}>
            <CircularProgress size={24} />
          </Box>
        ) : (
          <>
            <Typography variant="subtitle2" gutterBottom>
              Folders
            </Typography>
            <Grid container spacing={1} sx={{ mb: 1 }}>
              {folders.map((folder) => (
                <Grid item xs={6} sm={4} md={3} key={folder._id}>
                  <StyledCard onClick={() => handleFolderClick(folder)}>
                    <CardContent sx={{ p: 1 }}>
                      <Box display="flex" alignItems="center">
                        <FolderIcon sx={{ mr: 1, color: 'primary.main' }} />
                        <Typography variant="body2" noWrap>{folder.title}</Typography>
                      </Box>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
            <PaginationBox>
              <IconButton
                size="small"
                disabled={foldersPage <= 1}
                onClick={() => setFoldersPage(foldersPage - 1)}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="body2" sx={{ mx: 1 }}>
                Page {foldersPage}
              </Typography>
              <IconButton
                size="small"
                disabled={!foldersPagination.hasNextPage}
                onClick={() => setFoldersPage(foldersPage + 1)}
              >
                <ArrowForwardIcon />
              </IconButton>
            </PaginationBox>
            <Divider sx={{ my: 1 }} />
            <Typography variant="subtitle2" gutterBottom>
              Documents
            </Typography>
            <Grid container spacing={1}>
              {docs.map((doc) => (
                <Grid item xs={12} sm={6} key={doc._id}>
                  <StyledCard>
                    <CardContent sx={{ p: 1 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedItems.some((item) => item._id === doc._id)}
                            onChange={() => handleItemSelect(doc)}
                            size="small"
                          />
                        }
                        label={
                          <Box display="flex" alignItems="center">
                            <DescriptionIcon sx={{ mr: 1, color: 'secondary.main' }} />
                            <Typography variant="body2" noWrap>{doc.title}</Typography>
                          </Box>
                        }
                      />
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
            <PaginationBox>
              <IconButton
                size="small"
                disabled={docsPage <= 1}
                onClick={() => setDocsPage(docsPage - 1)}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="body2" sx={{ mx: 1 }}>
                Page {docsPage}
              </Typography>
              <IconButton
                size="small"
                disabled={!docsPagination.hasNextPage}
                onClick={() => setDocsPage(docsPage + 1)}
              >
                <ArrowForwardIcon />
              </IconButton>
            </PaginationBox>
          </>
        )}
      </ContentBox>
    </div>
  );
};

export default DocPicker;
