import { Box } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { format } from "timeago.js";

const ListingTableDateAgoCell = ({date}) => {
  return (
    <Box sx={{ textAlign: "left" }}>
      <div style={{ fontWeight: "bold" }}>
        {dayjs(date).format("D MMM YYYY")}
      </div>
      <div style={{ fontWeight: "normal" }}>{format(date)}</div>
    </Box>
  );
};

export default ListingTableDateAgoCell;
