import {
  Avatar,
  Box,
  Button,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import MoreButton from "../../../styled/generic/MoreButton";
import MyPopOver from "../../../styled/CommonComponents/MyPopOver";
import { Add, PersonAddOutlined } from "@material-ui/icons";
import TabBox from "../../../styled/generic/TabBox";
import TabItem from "../../../styled/generic/TabItem";
import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Search } from "@mui/icons-material";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import TabsWithActions from "../../../styled/generic/TabsWithActions";
import Matches from "./matches/Matches";
import Bookings from "./bookings/Bookings";
import Requests from "./requests/Requests";
import TabActionButton from "../../../styled/generic/TabActionButton";
import Api from "../../../../helpers/Api";
import Leads from "./leads/Leads";
import LeadsDrawer from "../../common/drawers/LeadsDrawer";
import dayjs from "dayjs";
import ListingTableNewItemCell from "../../../styled/generic/ListingTableNewItemCell";
import RequirementsForListing from "../../common/tablecontainers/RequirementsForListing";
import Applications from "./applications/Applications";

const LISTING_ACTIONS = ["Share", "Edit", "Mark as Private", "Remove Property"];

const useStyles = makeStyles((theme) => ({
  tableSty: {
    width: "100%",
    border: "none",
    backgroundColor: "#F5F7FA",
    borderCollapse: "collapse",
    marginTop: "10px",
    "& tr": {
      height: "60px",
      marginTop: "5px",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      borderBottom: "1px solid rgba(0,0,0,0.10)",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.005)",
      },
    },
    "& th": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "10px",
      backgroundColor: "#F5F7FA",
      textAlign: "left",
      fontSize: "0.85rem",
    },
    "& td": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "15px",
      textAlign: "left",
      verticalAlign: "center",
      fontSize: "0.9rem",
    },
  },
}));

const Listing = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [listingTab, setListingTab] = useState("Leads");
  const [listing, setListing] = useState();
  const [showLeadsDrawer, setShowLeadsDrawer] = useState(false);
  const { listingId } = useParams();

  const getListing = async () => {
    try {
      const { data } = await Api.post("/listing/getListingById", {
        listingId: listingId,
      });
      setListing(data);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Unable to fetch listing",
        },
      });
    }
  };

  useEffect(() => {
    getListing();
  }, []);

  const handleListingActionClick = () => {};

  return (
    <Box style={{ padding: "2rem" }}>
      <LeadsDrawer
        open={showLeadsDrawer}
        setOpen={setShowLeadsDrawer}
        defaultSelectedListingId={listingId}
      />
      <Box sx={{ backgroundColor: "#ffffff", borderRadius: 3, p: 2 }}>
        <Grid container>
          <Grid xs={6}>
            <Box display="flex" height={200}>
              <Box
                flex="0.4"
                sx={{ overflow: "hidden", borderRadius: 2, p: 1 }}
              >
                <img
                  src={
                    listing?.form?.images?.length > 0
                      ? listing?.form?.images[0]?.url
                      : ""
                  }
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
              <Box flex="0.6" sx={{ p: 1 }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" sx={{ fontWeight: 600 }}>
                    {listing?.title}
                  </Typography>
                  <ListingTableNewItemCell date={listing?.createdAt} />
                </Box>

                <Typography variant="body1" gutterBottom>
                  {listing?.form?.locality && listing.form.locality + ","}{" "}
                  {listing?.form?.state}
                </Typography>
                <Typography variant="body2">
                  Created at:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {dayjs(listing?.createdAt).format("D MMM YYYY")}
                  </span>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid xs={6}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  border: "1px solid #48A8F5",
                  borderStyle: "dashed",
                  mr: 2,
                }}
              >
                <Box display="flex" sx={{ mr: 1 }}>
                  {[1, 2].map((item) => (
                    <Avatar
                      alt={item?.parent?.displayName}
                      src={item?.parent?.displayPicture?.url}
                      sx={{
                        width: 30,
                        height: 30,
                        marginRight: "-5px",
                        border: "3px solid #ffffff",
                      }}
                    />
                  ))}
                </Box>
                <Button
                  variant="text"
                  startIcon={<PersonAddOutlined />}
                  color="primary"
                  sx={{ mr: 1, textTransform: "none" }}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  disableElevation
                >
                  Add
                </Button>
              </Box>
              <MyPopOver
                closeOnClick={true}
                appearContent={<MoreButton />}
                showContent={
                  <List sx={{ width: 200 }}>
                    {LISTING_ACTIONS?.map((action) => (
                      <ListItem
                        disablePadding
                        onClick={() => handleListingActionClick(action)}
                      >
                        <ListItemButton>
                          <ListItemText primary={action} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 2 }}>
        <TabsWithActions
          tabs={[
            {
              title: "Leads",
              action: (
                <TabActionButton onClick={() => setShowLeadsDrawer(true)}>
                  Add Leads
                </TabActionButton>
              ),
            },
            {
              title: "Matches",
            },
            {
              title: "Bookings",
              action: <TabActionButton>Add Bookings</TabActionButton>,
            },
            {
              title: "Requests",
            },
            {
              title: "My Applications",
            },
          ]}
          onTabChange={(val) => setListingTab(val)}
          currentTabVariable={listingTab}
        />
      </Box>

      {listingTab === "Leads" && <Leads />}

      {listingTab === "Matches" && <Matches />}
      {listingTab === "Bookings" && <Bookings />}
      {listingTab === "Requests" && <Requests />}
      {listingTab === "My Applications" && <Applications />}
    </Box>
  );
};

export default Listing;
