import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { createCostCodeCategory, updateCostCodeCategory } from './Api.call';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SketchPicker } from 'react-color';
import CategoryAutocomplete from './CategoryAutocomplete';

const useStyles = makeStyles((theme) => ({
  mainDialogCont: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px"
    }
  },
  bodyCont: {
    width: "100%",
    height: "calc(100% - 55px)",
    overflow: "hidden",
    padding: "15px"
  },
  bottomAct: {
    width: "100%",
    height: "50px",
    padding: "0px 7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: "1px solid #ececec"
  },
  duleInputCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px"
  }
}));

export default function CategoryCreateDrawer({
  createCategoryDawer, setCreateCategoryDrawer, givenParentCategory, categoryType,
  profileId, afterCategoryCreateOrEdit, libraryId, categoryForEditDelete
}) {
  const classes = useStyles();
  const theme = useTheme();

  const { user } = useSelector((state) => state.auth);

  const PROGRESS_TYPE = ["Value", "Percentage"];
  const CATEGORY_TYPE = ["Asset", "Inventory", "Task", "Common", "CRM", "Structure"];
  const [loader, setLoader] = useState(false)
  const [name, setName] = useState("")
  const [code, setCode] = useState("")
  const [colorCode, setColorCode] = useState("#f7f7f7")
  const [desc, setDesc] = useState("")
  const [parenCategory, setParenCategory] = useState()
  const [progressType, setProgressType] = useState();
  const [type, setType] = useState(categoryType);

  const setInitialState = () => {
    setLoader(false)
    setName("")
    setCode("")
    setDesc("")
    setColorCode("#f7f7f7")
    setParenCategory(null)
    setProgressType(null)
  }

  useEffect(() => {
    if (createCategoryDawer === false && categoryForEditDelete?._id) {
      setInitialState()
    }
  }, [createCategoryDawer])

  useEffect(() => {
    if (givenParentCategory?._id) {
      setParenCategory(givenParentCategory)
    }
  }, [givenParentCategory])

  useEffect(() => {
    if (categoryForEditDelete?._id) {
      setName(categoryForEditDelete?.name)
      setCode(categoryForEditDelete?.code)
      setDesc(categoryForEditDelete?.description)
      setParenCategory(categoryForEditDelete?.parent || null)
      setProgressType(categoryForEditDelete?.progressType)
      setType(categoryForEditDelete?.type)
      setColorCode(categoryForEditDelete?.colorCode)
    }
  }, [categoryForEditDelete])

  const onSubmit = async (e) => {
    setLoader(true)
    e.preventDefault();
    if (categoryForEditDelete?._id) {
      const updateObj = {
        _id: categoryForEditDelete?._id,
        name: name,
        code: code,
        description: desc,
        type: type,
        progressType: progressType,
        colorCode
      }

      await updateCostCodeCategory(updateObj)
        .then((data) => {
          if (data) {
            if (data) {
              afterCategoryCreateOrEdit(data, true)
            }
            setInitialState()
            setCreateCategoryDrawer(false)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      let parentAncestors = [];
      let parentCategory = null;
      if (parenCategory && parenCategory._id) {
        parentCategory = parenCategory._id;
        parentAncestors = parenCategory?.ancestors || [];
        parentAncestors.push(parentCategory)
      }
      const createObj = {
        name: name,
        code: code,
        description: desc,
        shared: [profileId],
        library: libraryId,
        profileId: profileId,
        creator: user._id,
        type: type,
        progressType: progressType,
        colorCode
      }
      if (parentCategory) {
        createObj.parent = parentCategory;
      }
      if (parentAncestors && parentAncestors.length > 0) {
        createObj.ancestors = parentAncestors
      }
      await createCostCodeCategory(createObj)
        .then((data) => {
          if (data) {
            if (data) {
              afterCategoryCreateOrEdit(data, false)
            }
            setInitialState()
            setCreateCategoryDrawer(false)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <NormalDrawer
      openDrawer={createCategoryDawer}
      setOpenDrawer={setCreateCategoryDrawer}
      anchor={"right"}
      title={categoryForEditDelete?._id ? "Edit Category" : "Create Category"}
      content={
        <form className={classes.mainDialogCont} onSubmit={onSubmit} >
          <div className={classes.bodyCont} >
            <div className={classes.duleInputCont} >
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                label={<>Name<span style={{ color: "red" }}>*</span></>}
                placeholder="Enter Category Name"
                autoComplete='off'
                value={name}
                style={{ width: "48%" }}
                onChange={(e) => { setName(e.target.value); }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                label={"Cost Code"}
                placeholder="Enter Cost Code"
                autoComplete='off'
                value={code}
                style={{ width: "48%" }}
                onChange={(e) => { setCode(e.target.value); }}
              />
            </div>
            <div className={classes.duleInputCont} >
              <Autocomplete
                options={CATEGORY_TYPE}
                value={type}
                onChange={(event, value) => {
                  setType(value)
                }}
                size="small"
                style={{ width: "48%" }}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Category Type"
                    variant="outlined"
                    {...params}
                    placeholder="Select category type"
                  />
                )}
              />
              {type === CATEGORY_TYPE[2] && (
                <Autocomplete
                  options={PROGRESS_TYPE}
                  value={progressType}
                  onChange={(event, value) => {
                    setProgressType(value)
                  }}
                  size="small"
                  style={{ width: "48%" }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Progress Type"
                      variant="outlined"
                      {...params}
                      placeholder="Select progress type"
                    />
                  )}
                />
              )}
            </div>
            {categoryForEditDelete?._id ? null : (
              <div style={{ width: "100%", marginBottom: "15px" }} >
                <CategoryAutocomplete
                  libraryId={libraryId}
                  selectedCategories={parenCategory}
                  setSelectedCategories={setParenCategory}
                  isMultiple={false}
                  label={"Parent"}
                  profileId={profileId}
                  type={type}
                  isCreateNew={false}
                  placeholder="Select Category"
                />
              </div>
            )}
            <TextField
              id="outlined-multiline-static"
              label={"Description"}
              placeholder={"Enter Description"}
              value={desc}
              onChange={(e) => { setDesc(e.target.value) }}
              multiline
              rows={3}
              fullWidth
              variant="outlined"
            />
            <div style={{ width: "100%", marginTop: "15px" }} >
              <p style={{ fontSize: "15px", fontWeight: "500", marginBottom: "5px" }} >Select color for your category</p>
              <SketchPicker
                color={colorCode}
                style={{ width: "100%" }}
                onChangeComplete={(color) => {
                  console.log(color)
                  setColorCode(color.hex)
                }}
              />
            </div>
          </div>
          <div style={{ width: "100%", height: "5px" }} >
            {loader && (<LinearProgress />)}
          </div>
          <div className={classes.bottomAct} >
            <Button
              variant="contained"
              size="small"
              color="primary"
              type="submit"
              disabled={name && name.length > 2 ? false : true}
            >
              Save & Close
            </Button>
          </div>
        </form>}
    />
  );
}
