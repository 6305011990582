const { default: Api } = require('../../helpers/Api');

export const createBudgetCostApi = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/create/by-team", obj);
        const resultData = resData?.data;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getBudgetCostById = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/get/by-id", obj);
        const resultData = resData?.data;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const updateBudgetCostById = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/update/by-id", obj);
        const resultData = resData?.data;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const createHeadForBudgetCost = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/create/head/by-budget-cost-id", obj);
        const resultData = resData?.data;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const updateHeadForBudgetCostById = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/update/head/by-id", obj);
        const resultData = resData?.data;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const createSubheadByBudegthead = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/create/sub-head/by-budget-head-id", obj);
        const resultData = resData?.data;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};


export const updateSubheadById = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/update/sub-head/by-id", obj);
        const resultData = resData?.data;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getSubheadDataAndWorkItems = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/get/subhead-workitem/data/by-subhead", obj);
        const resultData = resData?.data;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const createWorkItemForGroup = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/create/workitem/grouped", obj);
        const resultData = resData?.data;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const updateWorkItem = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/update/workitem/by-id", obj);
        const resultData = resData?.data;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getItemsByFilter = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/search/workitem/with-filter", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const createNewWorkItemWithNewLibrary = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/create/budget-workitem/with-new-library", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const createNewWorkItemWithExistingLibraryItem = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/create/budget-workitem/with-existing-library-item", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const reloadCategoriesAndSubcategoriesAndLibraries = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/get/categories-subcategories/by-projectId", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const addWorkItemAsChildOfWorkItem = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/create/budget-workitem/as-child-of-workitem", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const updateWorkItemAndResources = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/update/budget-workitem/resources", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const deleteBudgetCostHead = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/delete/budget-head", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const deleteBudgetCostSubhead = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/delete/budget-subhead", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const deleteBudgetWorkItem = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/delete/budget-workitem", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const cloneBudgetCostHead = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/clone/head", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const cloneBudgetCostSubhead = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/clone/subhead", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getDeepPopulatedBudgetCost = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/get/all-budget-cost/deep-populated", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getSearchResultAgainstBudgetHead = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/get/search-result/subhead-workitem", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getSubheadAndWorkitem = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/get/subhead/workitem", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const createUpdateDeleteBudgetUpdate = async (obj) => {
    try {
        const resData = await Api.post("create/delete/update/budgetUpdateItem", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getBudgetUpdateByGivenFilter = async (obj) => {
    try {
        const resData = await Api.post("get/budgetUpdateItems/by-given-filter", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getOpsOrderById = async (obj) => {
    try {
        const resData = await Api.post("procurement/ops/get-by-id", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getAllBudgetCostByProfileId = async (obj) => {
    try {
        const resData = await Api.post("budget-cost/get/by-profile", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getAllProjectBlocks = async (obj) => {
    try {
        const resData = await Api.post("public/project/block/get", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getAllBudgetUpdateByGivenFilter = async (obj) => {
    try {
        const resData = await Api.post("get/budget-update/by-given-filter", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const updateBudgetUpdateById = async (obj) => {
    try {
        const resData = await Api.post("update/budget-update/by-id", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const addUpdateBudgetMapping = async (obj) => {
    try {
        const resData = await Api.post("add-update/budget-mapping", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getBudgetMapping = async (obj) => {
    try {
        const resData = await Api.post("get/budget-mapping", obj);
        const resultData = resData?.data || [];
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};