import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import Api from "../../helpers/Api";
import UserProfileTopView from "./UserProfileTopView";
import OrgProfileTopView from "./OrgProfileTopView";
import useGetAdminProfiles from "./useGetAdminProfiles";
import AccountSkeleton from "../styled/CommonComponents/Account.Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    padding: "20px 90px 50px",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px 50px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 50px",
    },
  }
}));

export default function ProfileView(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const userProfileId = user?.profile;
  const { profileId } = useParams();

  const [profile, setProfile] = useState(null);
  const { adminProfiles } = useGetAdminProfiles();

  const getProfile = async () => {
    const res = await Api.post("profile/getProfileParent", { profileId });
    if (res?.data) {
      setProfile(res.data);
    }
  };

  useEffect(() => {
    getProfile();
  }, [profileId]);

  switch (profile?.parentModelName) {
    case "Organization":
      break;

    case "User":
      break;

    default:
      break;
  }

  return (
    <div className={classes.root}>
      {profile?._id ? (profile?.parentModelName === "User" ? (
        <UserProfileTopView
          profile={profile}
          adminProfiles={adminProfiles}
          onSearch={true}
          isOwnProfile={userProfileId === profileId}
        />
      ) : (
        <OrgProfileTopView
          profile={profile}
          adminProfiles={adminProfiles}
          onSearch={true}
        />
      )) : <AccountSkeleton />}
    </div>
  );
}