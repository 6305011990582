import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HorizBox from "../styled/generic/HorizBox";
import ListingPicker from "../styled/generic/ListingPicker";
import SpaceBetween from "../styled/generic/SpaceBetween";
import TextField from "../styled/generic/TextField";
import Select from "../styled/generic/Select";
import { CurrencyRupee } from "@mui/icons-material";
import PropertyTypeChooser from "../listing/editlisting/components/PropertyTypeChooser";
import UnitAutocomplete from "../styled/CommonComponents/UnitAutocomplete";
import CheckGroup from "../styled/generic/CheckGroup";
import { Avatar, Box, Checkbox, InputAdornment, MenuItem, Typography } from "@mui/material";
import FormBox from "../styled/generic/FormBox";
import CountPicker from "../styled/generic/CountPicker";
import CheckGroupGrid from "../styled/generic/CheckGroupGrid";
import CheckGroupWithObj from "../styled/generic/CheckGroupWithObj";
import InputBase from "@mui/material/InputBase";

const REQUIREMENT_TYPE_OPTIONS = [
    "Rent",
    "Sell",
    "Roommate",
    "Job",
    "Investment",
    "Tender",
];

// Add source options
const SOURCE_OPTIONS = [
    "Facebook",
    "Instagram",
    "Website",
    "Referral",
    "WhatsApp",
    "Direct Walk-in",
    "Property Portal",
    "Newspaper",
    "Broker",
    "Other"
];

const FURNISHING_OPTIONS = [
    "Fully Furnished",
    "Semi Furnished",
    "Un Furnished",
];

const FLOOR_OPTIONS = [
    { title: "Ground Floor", value: 0 },
    { title: "1st Floor", value: 1 },
    { title: "2nd Floor", value: 2 },
    { title: "3rd Floor", value: 3 },
    { title: "4th Floor", value: 4 },
    { title: "5th or More", value: -1 },
];

const AMENITY_OPTIONS = [
    "Power Backup",
    "Wifi",
    "Gym",
    "Air conditioner",
    "Swimming Pool",
    "Gas Station",
    "Park",
    "Fire Safety",
    "Elevator",
    "Play Area",
    "Park",
];

const useStyles = makeStyles((theme) => ({

}));

export default function ProspectForm({
    prospectData, setProspectData, locationForm
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { user } = useSelector((state) => state.auth);
    const [showRequirements, setShowRequirements] = useState(false);

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ mt: 2 }}>
                <SpaceBetween
                    left={
                        <FormBox label="Source">
                            <Select
                                value={prospectData?.source || SOURCE_OPTIONS[0]}
                                onChange={(evt) => {
                                    setProspectData({
                                        ...prospectData,
                                        source: evt.target.value
                                    })
                                }}
                                size="small"
                                fullWidth={true}
                            >
                                {SOURCE_OPTIONS.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormBox>
                    }
                    right={
                        <FormBox label="Source Note">
                            <InputBase
                                placeholder="Enter source details"
                                value={prospectData?.sourceNote}
                                onChange={(e) =>
                                    setProspectData({
                                        ...prospectData,
                                        sourceNote: e.target.value
                                    })
                                }
                                multiline
                                rows={3}
                                sx={{
                                    width: '100%',
                                    p: 1,
                                    border: '1px solid #D8DEE4',
                                    borderRadius: '8px',
                                    '&:hover': {
                                        border: '1px solid #B2BAC2'
                                    },
                                    '&.Mui-focused': {
                                        border: '1px solid #0078D4'
                                    }
                                }}
                            />
                        </FormBox>
                    }
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showRequirements}
                            onChange={(evt, checked) => {
                                setShowRequirements(checked);
                            }}
                        />
                    }
                    label="Add Requirement"
                />

                {showRequirements && (
                    <>
                        <SpaceBetween
                            left={
                                <FormBox label="Title">
                                    <TextField
                                        placeholder="Enter Title"
                                        value={prospectData?.title}
                                        onChange={(e) =>
                                            setProspectData({
                                                ...prospectData,
                                                title: e.target.value
                                            })
                                        }
                                        size="small"
                                        fullWidth={true}
                                    />
                                </FormBox>
                            }
                            right={
                                <FormBox label="Requirement Type">
                                    <Select
                                        value={prospectData?.requirementType}
                                        onChange={(evt) => {
                                            setProspectData({
                                                ...prospectData,
                                                requirementType: evt.target.value
                                            })
                                        }}
                                        size="small"
                                        fullWidth={true}
                                    >
                                        {REQUIREMENT_TYPE_OPTIONS.map((item) => (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormBox>
                            }
                        />
                        <FormBox label="Price Range">
                            <SpaceBetween
                                left={
                                    <TextField
                                        placeholder="Min"
                                        type="Number"
                                        sx={{ mr: 2 }}
                                        value={prospectData?.minPrice}
                                        onChange={(e) => {
                                            setProspectData({
                                                ...prospectData,
                                                minPrice: e.target.value
                                            })
                                        }}
                                        fullWidth={true}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CurrencyRupee />
                                                </InputAdornment>
                                            ),
                                        }}
                                        size="small"
                                    />
                                }
                                right={
                                    <TextField
                                        placeholder="Max"
                                        type="Number"
                                        value={prospectData?.maxPrice}
                                        onChange={(e) => {
                                            setProspectData({
                                                ...prospectData,
                                                maxPrice: e.target.value
                                            })
                                        }}
                                        fullWidth={true}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CurrencyRupee />
                                                </InputAdornment>
                                            ),
                                        }}
                                        size="small"
                                    />
                                }
                            />
                        </FormBox>
                        <SpaceBetween left={locationForm} />
                        <PropertyTypeChooser
                            propertyType={prospectData?.propertyType}
                            setPropertyType={(value) => {
                                setProspectData({
                                    ...prospectData,
                                    propertyType: value
                                })
                            }}
                        />
                        <FormBox label="Area">
                            <SpaceBetween
                                left={
                                    <TextField
                                        placeholder="Min"
                                        type="Number"
                                        sx={{ mr: 2 }}
                                        value={prospectData?.minArea}
                                        onChange={(e) => {
                                            setProspectData({
                                                ...prospectData,
                                                minArea: e.target.value
                                            })
                                        }}
                                        size="small"
                                        fullWidth={true}
                                    />
                                }
                                right={
                                    <TextField
                                        placeholder="Max"
                                        type="Number"
                                        sx={{ mr: 2 }}
                                        value={prospectData?.maxArea}
                                        onChange={(e) => {
                                            setProspectData({
                                                ...prospectData,
                                                maxArea: e.target.value
                                            })
                                        }}
                                        size="small"
                                        fullWidth={true}
                                    />
                                }
                            />
                        </FormBox>
                        <FormBox label="Area Unit">
                            <SpaceBetween
                                left={
                                    <UnitAutocomplete
                                        value={prospectData?.areaUnit}
                                        onChange={(evt, value) =>
                                            setProspectData({
                                                ...prospectData,
                                                areaUnit: value
                                            })
                                        }
                                        size="small"
                                    />
                                }
                            />
                        </FormBox>
                        <CheckGroup
                            label="Furnishing"
                            options={FURNISHING_OPTIONS}
                            selectedOptions={prospectData?.furnishings || []}
                            setSelectedOptions={(value) => {
                                setProspectData({
                                    ...prospectData,
                                    furnishings: value
                                })
                            }}
                        />
                        <SpaceBetween
                            left={
                                <CountPicker
                                    countFrom={1}
                                    countTo={5}
                                    label="Bedrooms"
                                    fullwidth={true}
                                    count={prospectData?.bedRoomCount}
                                    setCount={(val) => {
                                        setProspectData({
                                            ...prospectData,
                                            bedRoomCount: parseInt(val || 0)
                                        })
                                    }}
                                    showMoreButton={true}
                                />
                            }
                            right={
                                <CountPicker
                                    countFrom={1}
                                    countTo={5}
                                    label="Bathroom"
                                    count={prospectData?.bathRoomCount}
                                    setCount={(val) => {
                                        setProspectData({
                                            ...prospectData,
                                            bathRoomCount: parseInt(val || 0)
                                        })
                                    }}
                                    showMoreButton={true}
                                />
                            }
                        />
                        <SpaceBetween
                            left={
                                <CountPicker
                                    countFrom={1}
                                    countTo={5}
                                    label="Balcony"
                                    count={prospectData?.balconyCount}
                                    setCount={(val) => {
                                        setProspectData({
                                            ...prospectData,
                                            balconyCount: parseInt(val || 0)
                                        })
                                    }}
                                    showMoreButton={true}
                                />
                            }
                        />
                        <SpaceBetween
                            alignItems={"flex-start"}
                            left={
                                <CheckGroupWithObj
                                    label="Floor"
                                    options={FLOOR_OPTIONS}
                                    selectedOptions={prospectData?.floors || []}
                                    setSelectedOptions={(value) => {
                                        setProspectData({
                                            ...prospectData,
                                            floors: value
                                        })
                                    }}
                                />
                            }
                            right={
                                <CheckGroupGrid
                                    label="Amenities"
                                    options={AMENITY_OPTIONS}
                                    selectedOptions={prospectData?.amenities || []}
                                    setSelectedOptions={(value) => {
                                        setProspectData({
                                            ...prospectData,
                                            amenities: value
                                        })
                                    }}
                                />
                            }
                        />
                    </>
                )}
            </Box>
        </Box>
    );
}