import { Box } from "@mui/material";
import React, { useState } from "react";
import TabsWithSearch from "../../../../styled/generic/TabsWithSearch";
import MyRequirementLeads from "../../../common/tablecontainers/MyRequirementLeads";
import MyRequirementRequests from "../../../common/tablecontainers/MyRequirementRequests";
import MyRequirementApplications from "../../../common/tablecontainers/MyRequirementApplications";

const Applications = () => {
  const [activeTab, setActiveTab] = useState("All Applications");

  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "10px", p: 2, mt: 2 }}>
      <TabsWithSearch
        tabs={["All Applications"]}
        onTabChange={(newTabVal) => setActiveTab(newTabVal)}
        currentTabVariable={activeTab}
        disableUnderline={true}
      />
      {activeTab === "All Applications" && <MyRequirementApplications />}
    </Box>
  );
};

export default Applications;
