import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Avatar, IconButton } from '@material-ui/core';
import emptyIcon from "../../Assets/FileIcon/emptyfolder.png"
import { getProfileForEntitySearch } from '../styled/CommonComponents/api.call';
import CancelIcon from '@material-ui/icons/Cancel';
import "../styled/CommonComponents/common.css"
import EntitySearch from '../styled/CommonComponents/EntitySearch';
import PaginatedEntityDropdown from '../styled/CommonComponents/PaginatedEntityDropdown';

const useStyles = makeStyles((theme) => ({
    searchCont: {
        height: "70px",
        padding: "0px 10px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    selectedCont: {
        width: "100%",
        height: "calc(100% - 70px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "0px 10px 0px"
    },
    emptySty: {
        width: "100%",
        height: "100%",
        padding: "20px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& img": {
            width: "30%",
            height: "auto"
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "300",
            textAlign: "center",
        }
    },
    cardShowFlex: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    nameRoleCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    detailsCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "column",
        "& h3": {
            fontSize: "14px",
            fontWeight: "500",
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "200"
        }
    },
    roleChip: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        fontSize: "10px",
        fontWeight: "400",
        padding: "1px 5px",
        borderRadius: "7px",
        marginRight: "5px"
    },
}));

export default function AssignedEntitySelectView({
    entity, curEntityId, selectedEntities, setSelectedEntities, givenFilterOption
}) {
    const classes = useStyles();
    const theme = useTheme();

    const [profileIdArr, setProfileIdArr] = useState([])
    const [mainProfileWithParent, setMainProfileWithParent] = useState(null)

    useEffect(() => {
        if (curEntityId && entity) {
            getProfileForEntitySearch({
                entityId: curEntityId,
                type: entity
            })
                .then((data) => {
                    const profiles = data?.profiles || []
                    const currentProfileWithParent = data?.currentProfileWithParent
                    let locProfileIdArr = new Set([...profiles])
                    setProfileIdArr([...locProfileIdArr])
                    setMainProfileWithParent(currentProfileWithParent)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [curEntityId, entity])

    return (
        <>
            <div className={classes.searchCont} >
                <PaginatedEntityDropdown
                    value={selectedEntities}
                    onChange={(value) => {
                        setSelectedEntities(value)
                    }}
                    isMulti={true}
                    entity={entity}
                    curEntityId={curEntityId}
                    givenProfileIdArr={profileIdArr}
                    palCreate={false}
                    givenFilterOption={givenFilterOption}
                />
            </div>
        </>
    );
}