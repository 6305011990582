import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, TextField, Button } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import teamUtils from "../team.utils";
import Api from "../../../helpers/Api";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTheme } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import { useDebounce } from 'react-use';
import LinearProgress from '@material-ui/core/LinearProgress';
import MailIcon from '@material-ui/icons/Mail';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as yup from "yup";
import _ from "lodash";

const schemaEmail = yup.object().shape({
	email: yup.string().email("Enter a valid email")
})
const { _createInvites, newCreateEmailInvite } = teamUtils;

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		[theme.breakpoints.down("xs")]: {
			alignItems: "flex-start",
		}
	},
	titleSty: {
		fontSize: "18px",
		fontWeight: "510",
		marginBottom: "25px"
	},
	noBorder: {
		border: "none",
	},
	searchText: {
		width: "calc(100% - 80px)",
		backgroundColor: "white",
		borderRadius: "12px",
		margin: "0px"
	},
	formCont: {
		width: "350px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
		borderRadius: "4px",
		padding: "3px 5px",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		}
	},
	loaderSty: {
		width: "350px",
		marginTop: "2px",
		marginBottom: "5px",
	},
	cardStyle: {
		width: "400px",
		borderRadius: "8px",
		display: "flex",
		flexDirection: "column",
		padding: "10px",
		marginTop: "25px",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		}
	},
	rowDiv: {
		width: "100%",
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "space-between",
		marginBottom: "15px",
		"& h3": {
			fontSize: "14px",
			fontWeight: "510"
		},
		"& p": {
			fontSize: "11px",
			fontWeight: "350",
			color: "gray"
		}
	},
	rowDivWrap: {
		width: "100%",
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
	},
	chipStyle: {
		marginTop: "7px",
		marginRight: "7px",
		height: "25px"
	},
	inviteChipStyle: {
		height: 28,
		width: 100,
	},
	loadingCont: {
		width: "100%",
		height: "350px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	doneSty: {
		backgroundColor: "ececec",
		color: "white",
		padding: "1px 7px",
		borderRadius: "3px",
		display: "flex",
		alignItems: "center",
	}
}));

const EmailAdd = (props) => {
	const { teamId, invites, setInvites, roles, invitesLoading } = props;
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();

	const { teamDictionary } = useSelector((state) => state.team);
	const { user } = useSelector((state) => state.auth);
	const team = teamDictionary[teamId];

	const [email, setEmail] = useState("")
	const [newInvitations, setNewInvitations] = useState([]);
	const [validationObj, setValidationObj] = useState({});
	const [loading, setLoading] = useState(false);
	const [notAllowedMailIds, setNotAllowedMailIds] = useState([])


	useEffect(() => {
		let locNotAllowedMailIds = [];
		const locParticipantsRole = teamDictionary[teamId]?.participantsRole || []
		invites.map((inviteData) => {
			if (inviteData?.type === "Platform" && inviteData?.invitee?.parent?.email) {
				locNotAllowedMailIds.push(inviteData?.invitee?.parent?.email)
			} else {
				locNotAllowedMailIds.push(inviteData?.email)
			}
		})
		locParticipantsRole.map((participantRoleData) => {
			locNotAllowedMailIds.push(participantRoleData?.user?.parent?.email)
		})

		setNotAllowedMailIds(locNotAllowedMailIds)
	}, [invites, teamDictionary[teamId]?.participantsRole])

	useEffect(() => {
		setValidationObj(null)
	}, [email])

	useDebounce(() => {
		schemaEmail.validate({ email: email }, { abortEarly: false })
			.then(async (data) => {
				if (notAllowedMailIds.includes(email)) {
					setValidationObj({
						email: "the mail id is already a member or invited"
					})
				}
			})
			.catch((err) => {
				let errorArr = err?.inner
				let obj = {}
				errorArr.map((err) => {
					obj[err?.path] = err?.message
				})
				setValidationObj(obj)
			})
	}, 1000, [email])

	const emailAddSubmit = async (e) => {
		e.preventDefault();
		setLoading(true)
		await Api.post("search/email", { email })
			.then((res) => {
				const entities = res || [];

				if (entities.length > 0) {
					const newObj = {
						type: "Platform",
						member: entities[0],
						role: "Viewer",
						invitionSend: false
					}
					setNewInvitations([newObj, ...newInvitations])
				} else {
					const newObj = {
						type: "Email",
						mailId: email,
						role: "Viewer",
						invitionSend: false
					}
					setNewInvitations([newObj, ...newInvitations])
				}
			})
			.catch((err) => {
				console.log(err);
			})
		setLoading(false)
		setEmail("")
	};

	const createPlatformInvites = async (memberRoles, team, user, index) => {
		setLoading(true)
		await _createInvites(memberRoles, team, user)
			.then((res) => {
				const results = res.result;
				if (results && results.length > 0) {
					setInvites([...invites, ...results]);
				}
				let locArr = [...newInvitations]
				locArr[index].invitionSend = true;
				setNewInvitations(locArr)
			})
			.catch((err) => {
				console.log(err)
			})
		setLoading(false)
	};

	const createEmailInvite = async (email, role, index) => {
		setLoading(true)
		await newCreateEmailInvite(email, role, team, user)
			.then((res) => {
				const results = res.result;
				if (results && results.length > 0) {
					setInvites([...invites, ...results]);
				}
				let locArr = [...newInvitations]
				locArr[index].invitionSend = true;
				setNewInvitations(locArr)
			})
			.catch((err) => {
				console.log(err)
			})
		setLoading(false)
	};


	return (
		<div className={classes.root}>
			{invitesLoading && notAllowedMailIds.length > 0 ? (<div className={classes.loadingCont} >
				<CircularProgress />
			</div>) : (<>
				<h3 className={classes.titleSty} >Invite by Email</h3>
				<form onSubmit={emailAddSubmit} className={classes.formCont} >
					<TextField
						variant="outlined"
						margin="normal"
						value={email}
						onChange={(e) => { setEmail(e.target.value) }}
						id="email_address"
						disableUnderline={false}
						autoFocus
						className={classes.searchText}
						placeholder="Enter mail to invite"
						size="small"
						InputProps={{ classes: { notchedOutline: classes.noBorder } }}
					/>
					<Button
						startIcon={<AddIcon />}
						variant="contained"
						color="primary"
						type="submit"
						size="small"
						disabled={email && email.length > 2 ? validationObj?.email ? true : false : true}
					>
						Add
					</Button>
				</form>
				<div className={classes.loaderSty} >
					{loading && (<LinearProgress />)}
				</div>
				{validationObj && validationObj?.email && (<p style={{ fontSize: "12px", color: "red" }} >{validationObj?.email}</p>)}
				{newInvitations.map((newInvitation, index) => (
					<>{newInvitation?.type === "Platform" ? (
						<Paper elevation={1} key={index} className={classes.cardStyle} >
							<div className={classes.rowDiv}>
								<div style={{ display: "flex", alignItems: "center" }} >
									<Avatar
										style={{ width: "35px", height: "35px" }}
										alt={newInvitation?.member?.parent?.displayName}
										src={newInvitation?.member?.parent?.displayPicture?.thumbUrl || newInvitation?.member?.parent?.displayPicture?.url}
									/>
									<div style={{ marginLeft: "5px" }} >
										<h3>{newInvitation?.member?.parent?.displayName}</h3>
										<p>{newInvitation?.member?.parent?.email}</p>
									</div>
								</div>

								{newInvitation?.invitionSend ? (<div className={classes.doneSty} >
									<CheckCircleIcon style={{ fontSize: "25px", color: "green", marginRight: "5px" }} /> <p>Sent</p>
								</div>) : (
									<Chip
										label={"Send Invite"}
										color={"primary"}
										className={classes.inviteChipStyle}
										style={{ marginLeft: "9px" }}
										onClick={() => {
											const memberRole = {
												member: newInvitation?.member,
												role: newInvitation?.role || "Viewer",
											};
											createPlatformInvites([memberRole], team, user, index);
										}}
									/>
								)}
							</div>
							<div className={classes.rowDivWrap}>
								{roles.map((role, i) => (
									<Chip
										label={role}
										key={i}
										className={classes.chipStyle}
										color={role === newInvitation?.role ? "primary" : "disabled"}
										onClick={() => {
											let locNewInvitations = [...newInvitations]
											locNewInvitations[index].role = role;
											setNewInvitations(locNewInvitations)
										}}
									/>
								))}
							</div>
						</Paper>
					) : (<>
						<Paper elevation={1} key={index} className={classes.cardStyle} >
							<div className={classes.rowDiv}>
								<div style={{ display: "flex", alignItems: "center" }} >
									<Avatar style={{ width: "35px", height: "35px" }} >
										<MailIcon />
									</Avatar>
									<div style={{ marginLeft: "5px" }} >
										<h3>{newInvitation?.mailId}</h3>
									</div>
								</div>

								{newInvitation?.invitionSend ? (<div className={classes.doneSty} >
									<CheckCircleIcon style={{ fontSize: "25px", color: "green", marginRight: "5px" }} /> <p>Sent</p>
								</div>) : (
									<Chip
										label={"Send Invite"}
										color={"primary"}
										className={classes.inviteChipStyle}
										style={{ marginLeft: "9px" }}
										onClick={() => {
											const email = newInvitation?.mailId
											const role = newInvitation?.role || "Viewer"
											createEmailInvite(email, role, index)
										}}
									/>
								)}
							</div>
							<div className={classes.rowDivWrap}>
								{roles.map((role, i) => (
									<Chip
										label={role}
										key={i}
										className={classes.chipStyle}
										color={role === newInvitation?.role ? "primary" : "disabled"}
										onClick={() => {
											let locNewInvitations = [...newInvitations]
											locNewInvitations[index].role = role;
											setNewInvitations(locNewInvitations)
										}}
									/>
								))}
							</div>
						</Paper>
					</>)}</>
				))}
			</>)}
		</div>
	);
};

export default EmailAdd;