import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Avatar, Paper, Button } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from "react-router-dom";
import { getPaginatedContact } from "./api.call";
import AddIcon from "@material-ui/icons/Add";
import { useMediaQuery } from "@material-ui/core";
import emptyIcon from "../../Assets/EmptyInboxOutline.svg";
import { useDebounce } from "react-use";
import Skeleton from "@material-ui/lab/Skeleton";
import LessText from "../styled/CommonComponents/LessText";
import ContactCreateDrawer from "./ContactCreateDrawer";
import { mainRoles } from "../../helpers/contactHelper";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ContactAnalysis from "./ContactAnalysis";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  cardHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  infoCont: {
    marginLeft: "5px",
    "& h3": {
      fontSize: "15px",
      fontWeight: "510",
      textAlign: "left",
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "450",
      color: "gray",
      textAlign: "left",
    },
  },
  threeDotSty: {
    position: "absolute",
    top: "5px",
    right: "10px",
    zIndex: "11",
  },
  typeContSty: {
    position: "absolute",
    top: "-18px",
    left: "5px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontSize: "12px",
    fontWeight: "400",
    padding: "0px 5px 1px",
    borderRadius: "4px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  emptyCont: {
    width: "100%",
    height: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      width: "250px",
      height: "auto",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "gray",
    },
  },
  paginationCont: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid #d2d1d1",
  },
}));

export default function Contacts({
  searchWidth, roles, team, searchAndAddContStyle, allCardsContStyle,
  singleCardStyle, cardSkeletonStyle, searchPaperCont, openContactDrawer,
  setOpenContactDrawer, givenIsMdSmall, addSmallBtn
}) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { user } = useSelector((state) => state.auth);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [filteredContactsArr, setFilteredContactsArr] = useState([]);
  const [selectedSearchRole, setSelectedSearchRole] = useState("Any");
  const [searchText, setSearchText] = useState("");
  const [curPage, setCurPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(25)
  const [changeState, setChangeState] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [loader, setLoader] = useState(true)
  const [openRelationDetails, setOpenRelationDetails] = useState(false)
  const [financialRelation, setFinancialRelation] = useState(null)

  const getAllContactsApiCall = async () => {
    setLoader(true)
    await getPaginatedContact({
      profileId: team?.parent?.profile?._id,
      role: selectedSearchRole,
      searchText: searchText,
      limit: pageLimit,
      curPage: curPage - 1
    })
      .then((data) => {
        const resultContacts = data?.allContacts || [];
        const resultCount = data?.count || 0;
        let locTotalPage = Math.ceil(resultCount / pageLimit);
        setTotalPages(locTotalPage)
        setFilteredContactsArr(resultContacts)
        setLoader(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useDebounce(() => {
    getAllContactsApiCall()
  }, 1000, [curPage, changeState])

  useEffect(() => {
    setCurPage(1)
    setChangeState(!changeState)
  }, [searchText, selectedSearchRole])

  return (
    <div className={classes.root}>
      <div className={searchAndAddContStyle}>
        <Paper
          className={searchPaperCont}
          style={{ width: searchWidth }}
          elevation={0}
        >
          <Autocomplete
            value={selectedSearchRole}
            freeSolo={true}
            disableClearable={true}
            options={["Any", ...mainRoles]}
            getOptionLabel={(option) => { return option || "" }}
            getOptionSelected={(option) => {
              return option === selectedSearchRole
            }}
            style={{ width: "110px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ margin: "0px" }}
                margin="normal"
                variant="outlined"
                placeholder="Select role"
              />
            )}
            onChange={(e, value) => { setSelectedSearchRole(value) }}
            size="small"
          />
          <TextField
            variant="outlined"
            placeholder="Search by name or username"
            size="small"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
          />
        </Paper>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenContactDrawer(true);
          }}
          style={{ marginLeft: "5px" }}
          startIcon={addSmallBtn ? null : isSmall ? null : <AddIcon />}
        >
          {addSmallBtn ? <AddIcon /> : isSmall ? <AddIcon /> : "Add"}
        </Button>
        <ContactCreateDrawer
          openContactDrawer={openContactDrawer}
          setOpenContactDrawer={setOpenContactDrawer}
          team={team}
          roles={roles}
          entityProfileId={team?.parent?.profile?._id}
          callAfterFun={() => {
            setCurPage(1)
            setChangeState(!changeState)
          }}
        />
      </div>

      <div className={allCardsContStyle}>
        {loader ? (
          <>
            <Skeleton variant="rect" className={cardSkeletonStyle} />
            <Skeleton variant="rect" className={cardSkeletonStyle} />
            <Skeleton variant="rect" className={cardSkeletonStyle} />
            <Skeleton variant="rect" className={cardSkeletonStyle} />
            <Skeleton variant="rect" className={cardSkeletonStyle} />
            <Skeleton variant="rect" className={cardSkeletonStyle} />
            <Skeleton variant="rect" className={cardSkeletonStyle} />
          </>
        ) : (
          <>
            {filteredContactsArr && filteredContactsArr.length > 0 ? (
              filteredContactsArr.map((contactData, i) => (
                <Paper style={givenIsMdSmall ? { width: "100%" } : {}} className={singleCardStyle} key={i} elevation={0}>
                  <div
                    className={classes.cardHeader}
                    onClick={() => { setFinancialRelation(contactData); setOpenRelationDetails(true) }}
                  >
                    <Avatar
                      style={{ width: "35px", height: "35px" }}
                      src={contactData?.profile?.parent?.displayPicture?.url}
                      alt={contactData?.profile?.parent?.displayName}
                    />
                    <div className={classes.infoCont}>
                      <h3>
                        <LessText
                          limit={25}
                          string={contactData?.profile?.parent?.displayName}
                        />
                      </h3>
                      <p>
                        {contactData?.profile?.parent?.username
                          ? `@${contactData?.profile?.parent?.username}`
                          : contactData?.profile?.parent?.email}
                      </p>
                    </div>
                  </div>
                  <div className={classes.threeDotSty}></div>
                  <div className={classes.typeContSty}>{contactData?.type}</div>
                </Paper>
              ))
            ) : (
              <div className={classes.emptyCont}>
                <img src={emptyIcon} />
                <p>No contact is available.</p>
              </div>
            )}
          </>
        )}
      </div>

      <ContactAnalysis
        openRelationDetails={openRelationDetails}
        setOpenRelationDetails={setOpenRelationDetails}
        financialRelation={financialRelation}
        curEntityId={team?.parent?._id}
        entity={team?.parentModelName}
        walletId={team?.parent?.wallet}
        curEntityProfileId={team?.parent?.profile?._id}
      />

      {loader ? (
        <Skeleton variant="rect" style={{ width: "100%", height: "52px" }} />
      ) : (
        <div className={classes.paginationCont} >
          <Pagination
            count={totalPages}
            page={curPage}
            color="primary"
            siblingCount={0}
            onChange={(event, value) => { setCurPage(value) }}
          />
        </div>
      )}
    </div>
  );
}