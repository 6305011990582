import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import ProjectContactAnalysis from "./ProjectContactAnalysis";
import OrganizationContactAnalysis from "./OrganizationContactAnalysis";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        overflowY: "auto",
        padding: "20px"
    }
}));

export default function ContactAnalysisDashboard({
    entity, curEntityId, curEntityProfileId, secondPartyId
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    return (<div className={classes.root} >
        {entity === "Project" ? (
            <ProjectContactAnalysis
                curEntityId={curEntityId}
                entity={entity}
                secondPartyId={secondPartyId}
            />
        ) : (
            <OrganizationContactAnalysis
                curEntityId={curEntityId}
                entity={entity}
                curEntityProfileId={curEntityProfileId}
                secondPartyId={secondPartyId}
            />
        )}
    </div>);
}