import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Box, TextField, Button, InputAdornment } from "@material-ui/core";
import { CalendarToday, Email, LocationCity, Person } from "@material-ui/icons";
import { CurrencyRupee } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

export default function CustomerForm({ customerData, setCustomerData }) {
  console.log(customerData, "customerData");
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    basicValue: customerData?.basicValue || "",
    regNo: customerData?.regNo || "",
    areaCarpet: customerData?.areaCarpet || "",
    pdfdsf: customerData.pdfdsf || "",
    bankerName: customerData.bankName || "",
    bankName: customerData.bankName || "",
    infrastructureCharges: customerData.infrastructureCharges || "",
    bankerContactNumber: customerData.bankerContactNumber || "",
    infraBalance: customerData.infraBalance || "",
    infraReceived: customerData.infraReceived || "",
    possessionDate: customerData.possessionDate || "",
    gstOnInfra: customerData.gstOnInfra || "",
    tdsReminderEmail: customerData.tdsReminderEmail || "",
    saleableArea: customerData.saleableArea || "",
    constructionFinanceNOC: customerData.constructionFinanceNOC || "",
    carParkDevelopmentCharges: customerData.carParkDevelopmentCharges || "",
    totalReraCarpet: customerData.totalReraCarpet || "",
    newMapping: customerData.newMapping || "",
  });

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCustomerData((prevData) => ({
        ...prevData,
        ...formData,
      }));
    }, 1000);
    return () => clearTimeout(timeout);
  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setCustomerData((prevData) => ({
      ...prevData,
      ...formData,
    }));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <form className={classes.form} onSubmit={handleSubmit} noValidate>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextField
            label="Basic Value"
            name="basicValue"
            variant="outlined"
            value={formData.basicValue}
            onChange={handleChange}
            fullWidth
            size="small"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupee />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Registration Number"
            name="regNo"
            variant="outlined"
            value={formData.regNo}
            onChange={handleChange}
            fullWidth
            size="small"
            type="text"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextField
            label="Area Carpet"
            name="areaCarpet"
            variant="outlined"
            value={formData.areaCarpet}
            onChange={handleChange}
            fullWidth
            size="small"
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">sq ft</InputAdornment>
              ),
            }}
          />
          <TextField
            label="PDFDSF"
            variant="outlined"
            name="pdfdsf"
            value={formData.pdfdsf}
            onChange={handleChange}
            fullWidth
            size="small"
            type="text"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextField
            label="New Mapping"
            name="newMapping"
            value={formData.newMapping}
            variant="outlined"
            onChange={handleChange}
            fullWidth
            size="small"
            type="text"
          />
          <TextField
            label="Banker Name"
            variant="outlined"
            name="bankerName"
            value={formData.bankerName}
            onChange={handleChange}
            fullWidth
            size="small"
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextField
            label="Bank Name"
            name="bankName"
            variant="outlined"
            value={formData.bankName}
            onChange={handleChange}
            fullWidth
            size="small"
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCity />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Infrastructure Charges"
            name="infrastructureCharges"
            variant="outlined"
            value={formData.infrastructureCharges}
            onChange={handleChange}
            fullWidth
            size="small"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupee />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextField
            label="Banker Contact Number"
            name="bankerContactNumber"
            variant="outlined"
            value={formData.bankerContactNumber}
            onChange={handleChange}
            fullWidth
            size="small"
            type="tel"
          />
          <TextField
            label="Infra Balance"
            variant="outlined"
            name="infraBalance"
            value={formData.infraBalance}
            onChange={handleChange}
            fullWidth
            size="small"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupee />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextField
            label="Infra Received"
            name="infraReceived"
            variant="outlined"
            value={formData.infraReceived}
            onChange={handleChange}
            fullWidth
            size="small"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupee />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Possession Date"
            name="possessionDate"
            value={formData.possessionDate}
            variant="outlined"
            onChange={handleChange}
            fullWidth
            size="small"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarToday />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextField
            label="GST on Infra"
            name="gstOnInfra"
            variant="outlined"
            value={formData.gstOnInfra}
            onChange={handleChange}
            fullWidth
            size="small"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <TextField
            label="TDS Reminder Email"
            name="tdsReminderEmail"
            variant="outlined"
            value={formData.tdsReminderEmail}
            onChange={handleChange}
            fullWidth
            size="small"
            type="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextField
            label="Saleable Area"
            variant="outlined"
            name="saleableArea"
            value={formData.saleableArea}
            onChange={handleChange}
            fullWidth
            size="small"
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">sq ft</InputAdornment>
              ),
            }}
          />
          <TextField
            label="Construction Finance NOC"
            name="constructionFinanceNOC"
            variant="outlined"
            value={formData.constructionFinanceNOC}
            onChange={handleChange}
            fullWidth
            size="small"
            type="text"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextField
            label="Car Park Development Charges"
            name="carParkDevelopmentCharges"
            value={formData.carParkDevelopmentCharges}
            variant="outlined"
            onChange={handleChange}
            fullWidth
            size="small"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupee />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Total Rera Carpet"
            name="totalReraCarpet"
            value={formData.totalReraCarpet}
            variant="outlined"
            onChange={handleChange}
            fullWidth
            size="small"
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">sq ft</InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.submitButton}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
}
