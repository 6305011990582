import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useParams, useHistory } from 'react-router-dom';
import { GoInbox } from "react-icons/go";
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import { Avatar } from "@material-ui/core"
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import { getMutalFinancialCertificate } from './api.call';

const useStyles = makeStyles((theme) => ({
    emptySty: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            color: "gray"
        }
    },
    tableContainer: {
        width: "100%",
        height: "calc(100% - 50px)",
        "& tr": {
            height: "45px"
        },
        "& table": {
            tableLayout: "auto"
        }
    },
    tableRow: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec !important"
        }
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #d2d1d1",
    },
    selectedUserOrgSty: {
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            marginLeft: "5px"
        },
        "& .MuiIconButton-root": {
            padding: "3px"
        },
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
        "& h3": {
            fontSize: "13px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "10px",
            fontWeight: "400",
            color: "gray"
        }
    },
    loaderCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));

export default function Certificate({
    walletId, secondPartyId
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [certificates, setCertificates] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)
    const [loader, setLoader] = useState(true)

    const getCertificateData = async () => {
        setLoader(true)
        await getMutalFinancialCertificate({
            walletId,
            secondPartyId,
            curPage: curPage - 1,
            limit: pageLimit
        })
            .then((data) => {
                console.log(data);
                setCertificates(data?.certificateDataArr || [])
                let locTotalPage = Math.ceil((data?.certificateDataCount || 0) / pageLimit);
                setTotalPages(locTotalPage)
                setLoader(false)
            })
            .catch((err) => {
                console.log(err)
                setLoader(false)
            })
    }

    useEffect(() => {
        getCertificateData()
    }, [curPage])

    return (<>
        {loader ? (<div className={classes.loaderCont} >
            <CircularProgress />
        </div>) : (<>{(certificates && certificates.length > 0) ? (<>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table" size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" >
                                Vendor
                            </TableCell>
                            <TableCell align="center"  >
                                Certificate No.
                            </TableCell>
                            <TableCell align="center" >
                                Work/Purchase Order
                            </TableCell>
                            <TableCell align="center"  >
                                Submitted
                            </TableCell>
                            <TableCell align="right"  >
                                Net Payment
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {certificates && certificates.map((cfData) => (
                            <TableRow
                                onClick={() => {
                                    history.push(`/finance/${walletId}/workorder-certificate/edit/${cfData?._id}`)
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                <TableCell align="left" >
                                    <div className={classes.selectedUserOrgSty} >
                                        <Avatar
                                            src={cfData?.workOrder?.vendor?.parent?.displayPicture?.thumbUrl ? cfData?.workOrder?.vendor?.parent?.displayPicture?.thumbUrl : cfData?.workOrder?.vendor?.parent?.displayPicture?.url}
                                            alt={cfData?.workOrder?.vendor?.parent?.displayName}
                                        />
                                        <div style={{ marginLeft: "5px" }} >
                                            <h3>{cfData?.workOrder?.vendor?.parent?.displayName}</h3>
                                            <p>{cfData?.workOrder?.vendor?.parent?.username}</p>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell align="center"  >
                                    {cfData?.certificateNumber}
                                </TableCell>
                                <TableCell align="center" >
                                    {cfData?.workOrder?.name}
                                </TableCell>
                                <TableCell align="center"  >
                                    {cfData?.isSubmitted ? "Submitted" : "Not Submitted"}
                                </TableCell>
                                <TableCell align="right"  >
                                    {cfData?.netPayment}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.paginationCont} >
                <Pagination
                    count={totalPages}
                    page={curPage}
                    color="primary"
                    siblingCount={0}
                    onChange={(event, value) => { setCurPage(value) }}
                />
            </div>
        </>) : (
            <div className={classes.emptySty} >
                <GoInbox style={{ fontSize: "100px" }} />
                <p>No certificate exisit for current contact</p>
            </div>
        )}</>)}
    </>);
}