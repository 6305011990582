import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import RequirementsForListingTableComponent from "../tables/RequirementsForListingComponent";
import { matches } from "lodash";
import {
  applyListingRequirementApi,
  getRequirementsForListingApi,
  requestRequirementApi,
} from "../../listing.api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";

const RequirementsForListing = () => {
  const { listingId } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const actions = ["TODO"];
  const handleActionClick = () => {};

  const requestRequirement = async (requirementId) => {
    try {
      const { success } = await requestRequirementApi({
        listingId: listingId,
        requirementId: requirementId,
        userId: user?._id,
        profileId: user?.profile,
        applicationType: "Requirement",
      });
      if (success) {
        setMatches(
          matches?.map((item) => {
            if (item._id === listingId) {
              return { ...item, applied: true };
            } else {
              return item;
            }
          })
        );

        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Request sent successfully!",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to make request",
        },
      });
    }
  };

  const applyRequirement = async (requirementId) => {
    try {
      const { success } = await applyListingRequirementApi({
        listingId: listingId,
        requirementId: requirementId,
        userId: user?._id,
        profileId: user?.profile,
        applicationType: "Requirement",
      });
      if (success) {
        setMatches(
          matches?.map((item) => {
            if (item._id === listingId) {
              return { ...item, applied: true };
            } else {
              return item;
            }
          })
        );

        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Applied successfully!",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to apply for listing",
        },
      });
    }
  };

  const [matches, setMatches] = useState([]);
  const [matchesLoading, setMatchesLoading] = useState(false);

  const getMatches = async () => {
    try {
      setMatchesLoading(true);
      const { data } = await getRequirementsForListingApi({
        listingId: listingId,
      });
      let processed = [];
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item?.applicants.includes(user?.profile)) {
          processed.push({ ...item, applied: true });
        } else {
          processed.push({ ...item, applied: false });
        }
      }
      setMatches(processed);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch matches",
        },
      });
    } finally {
      setMatchesLoading(false);
    }
  };

  useEffect(() => {
    getMatches();
  }, []);

  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "10px", p: 2, mt: 2 }}>
      <RequirementsForListingTableComponent
        matches={matches}
        setMatches={setMatches}
        actions={actions}
        handleActionClick={handleActionClick}
        loading={matchesLoading}
        requestRequirement={requestRequirement}
        applyRequirement={applyRequirement}
      />
    </Box>
  );
};

export default RequirementsForListing;
