import {
  AddCircleOutline,
  ArrowForward,
  ChevronRight,
  Clear,
  ClearAll,
  CloseOutlined,
  Home,
  KeyboardArrowDown,
  RemoveCircleOutline,
  Visibility,
} from "@material-ui/icons";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  SvgIcon,
  Tab,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect } from "react";
import HomeIcon from "../../../Assets/icons/homepropertydetails.svg";
import NoteIcon from "../../../Assets/icons/notebasicinfo.svg";
import LocationIcon from "../../../Assets/icons/location-pin.svg";
import WalletIcon from "../../../Assets/icons/wallet-2.svg";
import HouseIcon from "../../../Assets/icons/house-2.svg";
import Home2Icon from "../../../Assets/icons/House.svg";
import ImageIcon from "../../../Assets/icons/image.svg";
import { useState } from "react";
import { MenuItem } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import BuilderFloorIcon from "../../../Assets/icons/Group.svg";
import FarmHouseIcon from "../../../Assets/icons/Group1.svg";
import FlatAppartmentIcon from "../../../Assets/icons/Group2.svg";
import StorageIcon from "../../../Assets/icons/Group3.svg";
import HospitalityIcon from "../../../Assets/icons/Group4.svg";
import IndustryIcon from "../../../Assets/icons/industry1.svg";
import ShowerIcon from "../../../Assets/icons/Shower.svg";
import BedroomIcon from "../../../Assets/icons/Bedroom.svg";
import PatioIcon from "../../../Assets/icons/Patio.svg";
import { MdLocalAirport } from "react-icons/md";
import { MdOutlineStoreMallDirectory } from "react-icons/md";
import { IoBusOutline } from "react-icons/io5";
import { IoRestaurantOutline } from "react-icons/io5";
import { BsTrainFront, BsHospital } from "react-icons/bs";
import { LuSchool } from "react-icons/lu";
import { FaUniversity } from "react-icons/fa";
import { IoWaterOutline } from "react-icons/io5";
import { IoCarOutline } from "react-icons/io5";
import { BiCctv } from "react-icons/bi";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomFileUploadButton from "../../file/Uploader/CustomFileUploadButton";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "../../styled/generic/Accordion";
import FormBox from "../../styled/generic/FormBox";
import SpaceBetween from "../../styled/generic/SpaceBetween";
import Select from "../../styled/generic/Select";
import TextField from "../../styled/generic/TextField";
import Chip from "../../styled/generic/Chip";
import Counter from "../../styled/generic/Counter";
import LabelDivider from "../../styled/generic/LabelDivider";
import YesNoChooser from "../../styled/generic/YesNoChooser";
import UploadButton from "../../styled/generic/UploadButton";
import ProjectPickerDropdown from "../../styled/generic/ProjectPickerDropdown";
import OrgPickerDropdown from "../../styled/generic/OrgPickerDropdown";
import UserPickerDropdown from "../../styled/generic/UserPickerDropdown";
import dayjs from "dayjs";
import { BsHouseDoor } from "react-icons/bs";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { PiWarehouseDuotone } from "react-icons/pi";
import { GiFarmTractor } from "react-icons/gi";
import { PiTentDuotone } from "react-icons/pi";
import { LiaIndustrySolid } from "react-icons/lia";
import { PiGarageDuotone } from "react-icons/pi";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { HighlightOffOutlined } from "@mui/icons-material";
import Api from "../../../helpers/Api";
import keyConfig from "../../../config/keys.config";
import axios from "axios";
import { useDebounce } from "react-use";
import PropertyTypeChooser from "./components/PropertyTypeChooser";
import SelectProjectBlockDrawer from "../common/drawers/SelectProjectBlockDrawer";
import GoogleMapComponent from "../../styled/CommonComponents/Google.Map";
import ImageGroupUploader from "../../styled/generic/ImageGroupUploader";
import DocumentUploader from "../../styled/generic/DocumentUploader";
import UnitAutocomplete from "../../styled/CommonComponents/UnitAutocomplete";

const ROLE_OPTIONS = ["Owner", "Broker"];
const PROPERTY_AGE_OPTIONS = [
  "0 - 2 Years",
  "2 - 5 Years",
  "5 - 10 Years",
  "More than 10 Years",
];
const TENANT_OPTIONS = ["Any One", "Family", "Bachelor", "Company"];
const FURNISHING_OPTIONS = [
  "Fully Furnished",
  "Semi Furnished",
  "Un Furnished",
];
const FLOORING_OPTIONS = ["Hardwood", "Laminate", "Tile", "Carpet", "Bamboo"];
const CEILING_HEIGHT_OPTIONS = [
  "Standard Ceiling Heights",
  "Higher Ceilings",
  "Vaulted Or Cathedral Ceilings",
  "Loft Spaces",
  "Commercial Spaces",
];
const ROOM_OPTIONS = ["Puja Room", "Study Room", "Store Room"];
const VIEW_OPTIONS = [
  "Front View",
  "Street View",
  "360° View",
  "Landscape View",
  "Aerial View",
];
const PROXIMITY_OPTIONS = [
  {
    title: "Hospital",
    icon: BsHospital,
  },
  {
    title: "Airport",
    icon: MdLocalAirport,
  },
  {
    title: "Shopping Mall",
    icon: MdOutlineStoreMallDirectory,
  },
  {
    title: "Bus Stop",
    icon: IoBusOutline,
  },
  {
    title: "Restaurants",
    icon: IoRestaurantOutline,
  },
  {
    title: "Railway Station",
    icon: BsTrainFront,
  },
  {
    title: "School",
    icon: LuSchool,
  },
  {
    title: "University",
    icon: FaUniversity,
  },
];
const AMENITY_OPTIONS = [
  {
    title: "Water Supply",
    icon: IoWaterOutline,
  },
  {
    title: "Parking",
    icon: IoCarOutline,
  },
  {
    title: "Security",
    icon: BiCctv,
  },
];
const OTHER_AMENITY_OPTIONS = [
  "Power Backup",
  "Wifi",
  "Gym",
  "Air conditioner",
  "Swimming Pool",
  "Gas Station",
  "Park",
  "Fire Safety",
  "Elevator",
  "Play Area",
  "Park",
];

const FINANCING_OPTIONS = [
  "Loan",
  "Home Equity",
  "Leasing",
  "Business / Commercial",
  "Investment / Securities",
];

const EditListing = () => {
  const { listingId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const files = useSelector((state) => state.file);
  const { fileDictionary, createdFileIds } = files;
  const dispatch = useDispatch();

  // States for Basic Information
  const [listingTitle, setListingTitle] = useState("");
  const [role, setRole] = useState(ROLE_OPTIONS[0]);
  const [listBy, setListBy] = useState("User");
  const [selectedOrg, setSelectedOrg] = useState();
  const [selectedDefaultOrgId, setSelectedDefaultOrgId] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [selectedDefaultProjectId, setSelectedDefaultProjectId] = useState();
  const [managedBy, setManagedBy] = useState([]);
  const [privateListing, setPrivateListing] = useState(true);
  const [dueDate, setDueDate] = useState(dayjs());
  const [unitType, setUnitType] = useState("Single");
  const [showProjectBlockDrawer, setShowProjectBlockDrawer] = useState(false);

  // States for Property Details
  const [propertyType, setPropertyType] = useState();
  const [defaultPropertyType, setDefaultPropertyType] = useState();
  const [propertyBuildUpArea, setPropertyBuildUpArea] = useState();
  const [propertyBuildUpAreaUnit, setPropertyBuildUpAreaUnit] = useState();
  const [propertyAge, setPropertyAge] = useState(PROPERTY_AGE_OPTIONS[0]);
  const [propertyAvailablity, setPropertyAvailablity] = useState(dayjs());
  const [floor, setFloor] = useState();
  const [tenant, setTenant] = useState();
  const [furnishing, setFurnishing] = useState();
  const [flooring, setFlooring] = useState(FLOORING_OPTIONS[0]);
  const [ceilingHeight, setCeilingHeight] = useState(CEILING_HEIGHT_OPTIONS[0]);
  const [bedroomCount, setBedroomCount] = useState(0);
  const [bathroomCount, setBathroomCount] = useState(0);
  const [balconyCount, setBalconyCount] = useState(0);
  const [roomOptions, setRoomOptions] = useState([]);
  const [exteriorView, setExteriorView] = useState(VIEW_OPTIONS[0]);
  const [propertyDescription, setPropertyDescription] = useState();

  // States for Location Details
  const [city, setCity] = useState("");
  const [locality, setLocality] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [landMark, setLandMark] = useState("");
  const [proximities, setProximities] = useState([]);
  const [places, setPlaces] = useState([]);
  const [placeText, setPlaceText] = useState();
  const [selectedPlace, setSelectedPlace] = useState();
  const [placesLoading, setPlacesLoading] = useState(false);
  const [mapPos, setMapPos] = useState([]);

  // States for Media
  const [mediaImages, setMediaImages] = useState([]);
  const [mediaImageIds, setMediaImageIds] = useState([]);

  // States for Amenities
  const [amenities, setAmenities] = useState(
    AMENITY_OPTIONS.map((item) => item.title)
  );
  const [otherAmenities, setOtherAmenities] = useState([]);

  // States for Pricing Details
  const [amount, setAmount] = useState();
  const [financingOption, setFinancingOption] = useState(FINANCING_OPTIONS[0]);
  const [terms, setTerms] = useState();
  const [additionalCosts, setAdditionalCosts] = useState();
  const [documents, setDocuments] = useState([]);
  const [documentIds, setDocumentIds] = useState([]);

  const getListing = async () => {
    try {
      const { data } = await Api.post("/listing/getListingById", {
        listingId: listingId,
      });

      let form = data?.form;

      // Listing
      setListingTitle(data?.title);
      setUnitType(data?.unit);
      setSelectedDefaultProjectId(data?.project?._id);
      setRole(data?.role);
      setListBy(data?.listedBy);
      setSelectedDefaultOrgId(data?.organization?._id);
      setManagedBy(data?.shared?.map((user) => user?.parent));
      setPrivateListing(data?.isPrivate);
      setDueDate(dayjs(data?.dueDate));

      // Listing form:
      // Property Details
      setDefaultPropertyType(form?.propertyType || "House / Villa");
      setPropertyBuildUpArea(form?.area);
      setPropertyBuildUpAreaUnit(form?.areaUnit);
      setFloor(form?.floor || 0);
      setTenant(form?.tenant);
      setFurnishing(form?.furnishing);
      setFlooring(form?.flooring);
      setCeilingHeight(form?.ceilingHeight);
      setBedroomCount(form?.bedRoomCount);
      setBathroomCount(form?.bathRoomCount);
      setBalconyCount(form?.balconyCount);
      setRoomOptions(form?.additionalRooms);
      setExteriorView(form?.exteriorViews);
      setPropertyDescription(form?.exteriorDescription);

      // Location
      setCity(form?.city);
      setState(form?.state);
      setZip(form?.zip);
      setLandMark(form?.landMark);
      setPlaceText(form?.locality);
      setProximities(form?.nearByProximities);
      if (form?.lat && form?.lon) {
        setMapPos([
          {
            latitude: form?.lat,
            longitude: form?.lon,
            label:
              form?.location?.address_line1 ||
              form?.location?.address_line2 ||
              form?.location?.name,
            marker_color: "FF5F1F",
            marker_text_color: "ffffff",
          },
        ]);
      }

      // Media
      setMediaImageIds(form?.images?.map((item) => item?._id));
      setMediaImages(form?.images);

      // Amenities
      setAmenities(form?.amenities);
      setOtherAmenities(form?.otherAmenities);

      // Price Details
      setAmount(form?.price);
      setFinancingOption(form?.financingOption);
      setTerms(form?.paymentTerms);
      setAdditionalCosts(form?.additionalCosts);
      setDocumentIds(form?.propertyDocuments?.map((item) => item?._id));
      setDocuments(form?.propertyDocuments);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to update listing",
        },
      });
    }
  };

  const updateListing = async () => {
    try {
      const { data } = await Api.post("/listing/updateListing", {
        listingId: listingId,

        listingData: {
          title: listingTitle,
          role: role,
          unit: unitType,
          listedBy: listBy,
          listingOwner: listBy === "User" ? user.profile : selectedOrg?.profile,
          organization: selectedOrg?._id,
          project: selectedProject?._id,
          shared: managedBy.map((user) => user.profile),
          isPrivate: privateListing,
          dueDate: dueDate.toDate(),
        },

        formData: {
          propertyType: propertyType,
          area: propertyBuildUpArea,
          areaUnit: propertyBuildUpAreaUnit,
          propertyAge: propertyAge,
          availablity: propertyAvailablity.toDate(),
          floor: floor,
          tenant: tenant,
          furnishing: furnishing,
          flooring: flooring,
          ceilingHeight: ceilingHeight,
          bedRoomCount: bedroomCount,
          bathRoomCount: bathroomCount,
          balconyCount: balconyCount,
          additionalRooms: roomOptions,
          exteriorViews: exteriorView,
          exteriorDescription: propertyDescription,
          city: city,
          locality: locality,
          state: state,
          zip: zip,
          landMark: landMark,
          lat: selectedPlace?.lat,
          lon: selectedPlace?.lon,
          nearByProximities: proximities,
          images: mediaImageIds,
          amenities: amenities,
          otherAmenities: otherAmenities,
          price: amount,
          financingOption: financingOption,
          paymentTerms: terms,
          additionalCosts: additionalCosts,
          propertyDocuments: documentIds,
          location: selectedPlace,
        },
      });

      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Listing data updated successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to update listing",
        },
      });
    }
  };

  const searchPlaces = async (text) => {
    try {
      setPlacesLoading(true);
      const options = {
        method: "GET",
        url: "https://address-completion.p.rapidapi.com/v1/geocode/autocomplete",
        params: {
          text: text,
          limit: "15",
          lang: "en",
          countrycodes: "in",
        },
        headers: {
          "X-RapidAPI-Key": keyConfig?.rapidApi,
          "X-RapidAPI-Host": "address-completion.p.rapidapi.com",
        },
      };

      let response = await axios.request(options);

      let places = response?.data?.features;
      let formatted_places = [];

      for (let i = 0; i < places?.length; i++) {
        let place = places[i];

        if (!place?.properties?.postcode) {
          continue;
        }

        if (place?.properties?.name) {
          formatted_places.push(place?.properties);
        } else {
          formatted_places.push({
            ...place?.properties,
            name: place?.properties?.address_line1,
          });
        }
      }

      setPlaces(formatted_places);
    } catch (err) {
      console.log(err);
    } finally {
      setPlacesLoading(false);
    }
  };

  useDebounce(
    () => {
      if (placeText?.length >= 2) {
        searchPlaces(placeText);
      }
    },
    1000,
    [placeText]
  );

  useEffect(() => {
    if (selectedPlace?.state) {
      setState(selectedPlace?.state);
    }
    if (selectedPlace?.city) {
      setCity(selectedPlace?.city);
    }
    if (selectedPlace?.suburb) {
      setLocality(selectedPlace?.suburb);
    }
    if (selectedPlace?.postcode) {
      setZip(selectedPlace?.postcode);
    }
    if (selectedPlace?.lat && selectedPlace?.lon) {
      setMapPos([
        {
          latitude: selectedPlace?.lat,
          longitude: selectedPlace?.lon,
          label:
            selectedPlace?.address_line1 ||
            selectedPlace?.address_line2 ||
            selectedPlace?.name,
          marker_color: "FF5F1F",
          marker_text_color: "ffffff",
        },
      ]);
    }
  }, [selectedPlace]);

  useEffect(() => {
    getListing();
  }, []);

  return (
    <Box sx={{ backgroundColor: "#F4F7FF" }}>
      <SelectProjectBlockDrawer
        open={showProjectBlockDrawer}
        setOpen={setShowProjectBlockDrawer}
        projectId={selectedProject?._id}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          px: 1,
          py: 2,
          backgroundColor: "#ffffff",
          boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.04)",
        }}
      >
        <Button
          sx={{ width: 120, mr: 2 }}
          variant="outlined"
          startIcon={<Visibility />}
        >
          Preview
        </Button>
        <Button
          sx={{ width: 120 }}
          variant="contained"
          onClick={() => updateListing()}
        >
          Post
        </Button>
      </Box>
      <Box sx={{ px: 3, py: 2 }}>
        <Box>
          <Typography variant="h6" gutterBottom>
            Listing Details
          </Typography>
          <Typography variant="body1" gutterBottom>
            Please fill the information about your listing
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Accordion
            title="Basic Information"
            icon={NoteIcon}
            collapseContent={
              <Box>
                <FormBox>
                  <SpaceBetween
                    left={
                      <Box>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          defaultValue={unitType}
                        >
                          <FormControlLabel
                            value="Single"
                            control={<Radio />}
                            label="Single Unit"
                            componentsProps={{ typography: { variant: "h6" } }}
                            onChange={(evt) => setUnitType(evt.target.value)}
                          />
                          <FormControlLabel
                            value="Multiple"
                            control={<Radio />}
                            label="Multiple Unit"
                            componentsProps={{ typography: { variant: "h6" } }}
                            onChange={(evt) => setUnitType(evt.target.value)}
                          />
                        </RadioGroup>
                      </Box>
                    }
                  />
                </FormBox>

                <FormBox label="Link to Project">
                  <SpaceBetween
                    left={
                      <Box>
                        <FormControl fullWidth>
                          <ProjectPickerDropdown
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                            hideLabel={true}
                            defaultProjectId={selectedDefaultProjectId}
                          />
                        </FormControl>
                        {unitType === "Multiple" && selectedProject?._id && (
                          <Box
                            display="flex"
                            sx={{
                              borderRadius: "12px",
                              border:
                                "1px solid var(--accent-tone-16, #F3F2F8)",
                              background: "#F6F8FF",
                              boxShadow:
                                "0px 4px 4px 0px rgba(9, 11, 33, 0.02)",
                              padding: "12px",
                              mt: 3,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box display="flex" alignItems="center">
                              <Box
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "12px",
                                  backgroundColor: "#E7ECFD",
                                  padding: "3px",
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  style={{ width: "100%", height: "100%" }}
                                  src="/icons/app/rocket-emj.svg"
                                />
                              </Box>
                              <Box sx={{ ml: 2 }}>
                                <Typography>
                                  Almost done! Manage your Project Units before
                                  Start.
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Button
                                variant="text"
                                color="primary"
                                endIcon={<ArrowForward />}
                                sx={{ textTransform: "none" }}
                                onClick={() => setShowProjectBlockDrawer(true)}
                              >
                                Manage
                              </Button>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    }
                  />
                </FormBox>

                <FormBox label="Listing Name">
                  <SpaceBetween
                    left={
                      <TextField
                        placeholder="Enter"
                        fullWidth
                        value={listingTitle}
                        onChange={(e) => setListingTitle(e.target.value)}
                      />
                    }
                  />
                </FormBox>

                <FormBox label="Role">
                  <SpaceBetween
                    left={
                      <Box>
                        {ROLE_OPTIONS.map((item) => (
                          <Chip
                            label={item}
                            variant="outlined"
                            clickable
                            onClick={() => setRole(item)}
                            selected={true}
                            color={role === item ? "primary" : "default"}
                          />
                        ))}
                      </Box>
                    }
                  />
                </FormBox>

                <FormBox label="List By">
                  <Box>
                    <RadioGroup
                      row
                      defaultValue={listBy}
                      onChange={(evt, val) => setListBy(val)}
                    >
                      <FormControlLabel
                        value="User"
                        control={<Radio />}
                        label="User"
                      />
                      <FormControlLabel
                        value="Organization"
                        control={<Radio />}
                        label="Organization"
                      />
                    </RadioGroup>
                  </Box>
                  <SpaceBetween
                    left={
                      <FormControl fullWidth>
                        <OrgPickerDropdown
                          selectedOrg={selectedOrg}
                          setSelectedOrg={setSelectedOrg}
                          hideLabel={true}
                        />
                      </FormControl>
                    }
                  />
                </FormBox>

                <FormBox label="Managed By">
                  <SpaceBetween
                    left={
                      <UserPickerDropdown
                        selectedUsers={managedBy}
                        setSelectedUsers={setManagedBy}
                        hideLabel={true}
                      />
                    }
                  />

                  <Box sx={{ mt: 1 }}>
                    {managedBy?.map((item) => (
                      <Chip
                        label={item?.displayName}
                        size="medium"
                        onDelete={() => {
                          setManagedBy(
                            managedBy?.filter((u) => u._id !== item._id)
                          );
                        }}
                        deleteIcon={<Clear />}
                        avatar={
                          <Avatar
                            alt={item?.displayName}
                            src={item?.displayPicture?.thumbUrl}
                          />
                        }
                        variant="outlined"
                      />
                    ))}
                  </Box>
                </FormBox>

                <FormBox label="Listing Access">
                  <SpaceBetween
                    left={
                      <Box
                        sx={{
                          backgroundColor: "#F5F7FA",
                          borderRadius: "10px",
                          p: 2,
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Box display="flex" alignItems="flex-start">
                          <Radio
                            checked={privateListing}
                            onChange={() => setPrivateListing(true)}
                            value="Private"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "Private" }}
                            sx={{ mr: 1 }}
                          />
                          <Box>
                            <Typography variant="h6" gutterBottom>
                              Private
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                              All your listing information will only be visible
                              to you and the people whom you decide to share
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="flex-start">
                          <Radio
                            checked={!privateListing}
                            onChange={() => setPrivateListing(false)}
                            value="Public"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "Public" }}
                            sx={{ mr: 1 }}
                          />
                          <Box>
                            <Typography variant="h6" gutterBottom>
                              Public
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                              All your listing information will be publically
                              visible to everyone on RealList platform
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    }
                  />
                </FormBox>

                <FormBox label="Due Date">
                  <SpaceBetween
                    left={
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dueDate}
                          onChange={(val) => setDueDate(val)}
                          slotProps={{
                            textField: {
                              placeholder: "Select",
                              fullWidth: true,
                              InputProps: {
                                style: {
                                  borderRadius: "8px",
                                },
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    }
                  />
                </FormBox>
              </Box>
            }
          />

          <Accordion
            title="Property Details"
            icon={HomeIcon}
            collapseContent={
              <Box>
                <PropertyTypeChooser
                  propertyType={propertyType}
                  setPropertyType={setPropertyType}
                  defaultPropertyType={defaultPropertyType}
                />

                <SpaceBetween
                  left={
                    <FormBox label="Build up area">
                      <Box display="flex">
                        <TextField
                          placeholder="Carpet"
                          fullWidth
                          value={propertyBuildUpArea}
                          onChange={(e) =>
                            setPropertyBuildUpArea(e.target.value)
                          }
                        />
                      </Box>
                    </FormBox>
                  }
                  right={
                    <FormBox label="Unit Type">
                      <FormControl fullWidth>
                        <UnitAutocomplete
                          value={propertyBuildUpAreaUnit}
                          size="large"
                          onChange={(evt, value) =>
                            setPropertyBuildUpAreaUnit(value)
                          }
                        />
                      </FormControl>
                    </FormBox>
                  }
                />

                <SpaceBetween
                  left={
                    <FormBox label="Property Age">
                      <FormControl fullWidth>
                        <Select
                          value={propertyAge}
                          onChange={(evt) => setPropertyAge(evt.target.value)}
                        >
                          {PROPERTY_AGE_OPTIONS.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormBox>
                  }
                  right={
                    <FormBox label="Property Availablity">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={propertyAvailablity}
                          onChange={(date) => setPropertyAvailablity(date)}
                          slotProps={{
                            textField: {
                              placeholder: "Select",
                              fullWidth: true,
                              InputProps: {
                                style: {
                                  borderRadius: "8px",
                                },
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </FormBox>
                  }
                />

                <FormBox label="Floor">
                  <SpaceBetween
                    left={
                      <TextField
                        placeholder="Enter"
                        fullWidth
                        value={floor}
                        onChange={(evt) => setFloor(evt.target.value)}
                      />
                    }
                  />
                </FormBox>

                <FormBox label="Prefer Tenant">
                  <SpaceBetween
                    left={
                      <Box>
                        {TENANT_OPTIONS.map((item) => (
                          <Chip
                            label={item}
                            variant="outlined"
                            clickable
                            onClick={() => setTenant(item)}
                            color={tenant === item ? "primary" : "default"}
                          />
                        ))}
                      </Box>
                    }
                  />
                </FormBox>

                <LabelDivider variant="h5">Interior Feature</LabelDivider>

                <FormBox label="Furnishing">
                  <SpaceBetween
                    left={
                      <Box>
                        {FURNISHING_OPTIONS.map((item) => (
                          <Chip
                            label={item}
                            variant="outlined"
                            clickable
                            onClick={() => setFurnishing(item)}
                            color={furnishing === item ? "primary" : "default"}
                          />
                        ))}
                      </Box>
                    }
                  />
                </FormBox>

                <SpaceBetween
                  left={
                    <FormBox label="Flooring">
                      <FormControl fullWidth>
                        <Select
                          value={flooring}
                          onChange={(evt, val) => setFlooring(evt.target.value)}
                        >
                          {FLOORING_OPTIONS.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormBox>
                  }
                  right={
                    <FormBox label="Ceiling Height">
                      <FormControl fullWidth>
                        <Select
                          value={ceilingHeight}
                          onChange={(evt, val) =>
                            setCeilingHeight(evt.target.value)
                          }
                        >
                          {CEILING_HEIGHT_OPTIONS.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormBox>
                  }
                />

                <SpaceBetween
                  left={
                    <FormBox label="Room Details">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ mt: 2 }}
                        flexDirection={{ xs: "column", lg: "row" }}
                      >
                        <Counter
                          label="Bed Room"
                          icon={BedroomIcon}
                          count={bedroomCount}
                          setCount={setBedroomCount}
                        />
                        <Counter
                          label="Bathroom"
                          icon={ShowerIcon}
                          count={bathroomCount}
                          setCount={setBathroomCount}
                        />
                        <Counter
                          label="Balcony"
                          icon={PatioIcon}
                          count={balconyCount}
                          setCount={setBalconyCount}
                        />
                      </Box>
                    </FormBox>
                  }
                />

                <FormBox label="Additional Room">
                  <SpaceBetween
                    left={
                      <Box>
                        {ROOM_OPTIONS.map((item) => (
                          <Chip
                            label={item}
                            variant="outlined"
                            clickable
                            onClick={() => {
                              if (roomOptions?.find((r) => r === item)) {
                                setRoomOptions(
                                  roomOptions.filter((r) => r !== item)
                                );
                              } else {
                                setRoomOptions([...roomOptions, item]);
                              }
                            }}
                            color={
                              roomOptions?.find((r) => r === item)
                                ? "primary"
                                : "default"
                            }
                          />
                        ))}
                      </Box>
                    }
                  />
                </FormBox>

                <LabelDivider variant="h5">Exterior Feature</LabelDivider>

                <SpaceBetween
                  left={
                    <FormBox label="Exterior Views">
                      <FormControl fullWidth>
                        <Select
                          value={exteriorView}
                          onChange={(evt) => setExteriorView(evt.target.value)}
                        >
                          {VIEW_OPTIONS.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormBox>
                  }
                />

                <SpaceBetween
                  left={
                    <FormBox label="Description">
                      <TextField
                        placeholder="Enter Text"
                        fullWidth
                        multiline
                        minRows={5}
                        value={propertyDescription}
                        onChange={(evt) =>
                          setPropertyDescription(evt.target.value)
                        }
                      />
                    </FormBox>
                  }
                />
              </Box>
            }
          />

          <Accordion
            title="Location Details"
            icon={LocationIcon}
            collapseContent={
              <Box>
                <SpaceBetween
                  left={
                    <FormBox label="Locality">
                      <Autocomplete
                        options={places}
                        defaultValue={selectedPlace}
                        onChange={(evt, newPlace) => setSelectedPlace(newPlace)}
                        fullWidth
                        getOptionLabel={(option) => option?.name || option}
                        popupIcon={<KeyboardArrowDown />}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            value={placeText}
                            onChange={(e) => setPlaceText(e.target.value)}
                            placeholder={
                              placeText ? placeText : "Search location"
                            }
                          />
                        )}
                        loading={placesLoading}
                      />
                    </FormBox>
                  }
                  right={
                    <FormBox label="City">
                      <TextField
                        placeholder="Enter"
                        fullWidth
                        value={city}
                        onChange={(evt) => setCity(evt.target.value)}
                      />
                    </FormBox>
                  }
                />

                <SpaceBetween
                  left={
                    <FormBox label="State">
                      <TextField
                        placeholder="State Name"
                        fullWidth
                        value={state}
                        onChange={(evt) => setState(evt.target.value)}
                      />
                    </FormBox>
                  }
                  right={
                    <FormBox label="Zip Code">
                      <TextField
                        placeholder="Enter"
                        fullWidth
                        value={zip}
                        onChange={(evt) => setZip(evt.target.value)}
                      />
                    </FormBox>
                  }
                />

                <SpaceBetween
                  left={
                    <FormBox label="Land Mark">
                      <TextField
                        placeholder="Enter"
                        fullWidth
                        value={landMark}
                        onChange={(evt) => setLandMark(evt.target.value)}
                      />
                    </FormBox>
                  }
                />

                <SpaceBetween
                  left={
                    <FormBox label="Near By Proximities">
                      {PROXIMITY_OPTIONS.map((item) => (
                        <Chip
                          label={item.title}
                          variant="outlined"
                          clickable
                          onClick={() => {
                            if (proximities?.find((r) => r === item.title)) {
                              setProximities(
                                proximities.filter((r) => r !== item.title)
                              );
                            } else {
                              setProximities([...proximities, item.title]);
                            }
                          }}
                          color={
                            proximities?.find((p) => p === item.title)
                              ? "primary"
                              : "default"
                          }
                          avatar={<item.icon size={42} />}
                        />
                      ))}
                    </FormBox>
                  }
                />

                <SpaceBetween
                  left={
                    <Box>
                      <GoogleMapComponent
                        marks={mapPos}
                        MakerType={"maker"}
                        height={400}
                        redius={800}
                      />
                    </Box>
                  }
                />
              </Box>
            }
          />

          <Accordion
            title="Media"
            icon={ImageIcon}
            collapseContent={
              <Box sx={{ px: 4, py: 2 }}>
                <Box
                  sx={{
                    backgroundColor: "#F4F7FF",
                    borderRadius: "20px",
                    p: 4,
                  }}
                >
                  <ImageGroupUploader
                    imageList={mediaImages}
                    listingId={listingId}
                    onUpload={() => {
                      setMediaImageIds(createdFileIds);
                      let imgData = [];
                      for (let i = 0; i < createdFileIds?.length; i++) {
                        imgData.push(fileDictionary[createdFileIds[i]]);
                      }
                      setMediaImages(imgData);
                    }}
                    onDelete={(imgId) => {
                      setMediaImageIds(
                        mediaImageIds.filter((m) => m !== imgId)
                      );
                      setMediaImages(
                        mediaImages.filter((m) => m._id !== imgId)
                      );
                    }}
                  />
                </Box>
              </Box>
            }
          />

          <Accordion
            title="Amenities"
            icon={HouseIcon}
            collapseContent={
              <Box>
                <Grid container spacing={2}>
                  {AMENITY_OPTIONS.map((item) => (
                    <Grid item xs={12} md={4}>
                      <YesNoChooser
                        label={item.title}
                        icon={<item.icon size={42} />}
                        onYes={(val) => setAmenities([...amenities, val])}
                        onNo={(val) =>
                          setAmenities(amenities.map((a) => a !== val))
                        }
                        defaultYesActive={amenities?.find(
                          (a) => a === item.title
                        )}
                      />
                    </Grid>
                  ))}
                </Grid>
                <FormBox label="Other Amenities">
                  {OTHER_AMENITY_OPTIONS.map((item) => (
                    <Chip
                      label={item}
                      variant="outlined"
                      clickable
                      onClick={() => {
                        if (otherAmenities?.find((a) => a === item)) {
                          otherAmenities.filter((a) => a !== item);
                        } else {
                          setOtherAmenities([...otherAmenities, item]);
                        }
                      }}
                      color={
                        otherAmenities?.find((a) => a === item)
                          ? "primary"
                          : "default"
                      }
                    />
                  ))}
                </FormBox>
              </Box>
            }
          />

          <Accordion
            title="Price Details"
            icon={WalletIcon}
            collapseContent={
              <Box>
                <SpaceBetween
                  left={
                    <FormBox label="Pricing">
                      Fill up the following details about financing options of
                      your listing
                    </FormBox>
                  }
                />
                <SpaceBetween
                  left={
                    <FormBox label="Price">
                      <TextField
                        placeholder="Enter Amount"
                        fullWidth
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </FormBox>
                  }
                  right={
                    <FormBox label="Financing Option">
                      <FormControl fullWidth>
                        <Select
                          value={financingOption}
                          onChange={(evt) =>
                            setFinancingOption(evt.target.value)
                          }
                          displayEmpty
                        >
                          {FINANCING_OPTIONS.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormBox>
                  }
                />
                <SpaceBetween
                  left={
                    <FormBox label="Payment Terms">
                      <TextField
                        placeholder="Enter Text"
                        multiline
                        minRows={5}
                        fullWidth
                        value={terms}
                        onChange={(e) => setTerms(e.target.value)}
                      />
                    </FormBox>
                  }
                />
                <SpaceBetween
                  left={
                    <FormBox label="Additional Costs">
                      <TextField
                        placeholder="Enter Text"
                        multiline
                        minRows={5}
                        fullWidth
                        value={additionalCosts}
                        onChange={(e) => setAdditionalCosts(e.target.value)}
                      />
                    </FormBox>
                  }
                />

                <SpaceBetween
                  left={
                    <FormBox label="Property Documents">
                      <Typography variant="body1" gutterBottom>
                        Please upload all relavant documents of your listing
                      </Typography>

                      <DocumentUploader
                        documents={documents}
                        onUpload={() => {
                          setDocumentIds(createdFileIds);
                          let imgData = [];
                          for (let i = 0; i < createdFileIds?.length; i++) {
                            imgData.push(fileDictionary[createdFileIds[i]]);
                          }
                          setDocuments(imgData);
                        }}
                        onDelete={(imgId) => {
                          setDocumentIds(
                            documentIds.filter((m) => m !== imgId)
                          );
                          setDocuments(
                            documents.filter((m) => m._id !== imgId)
                          );
                        }}
                        parentId={listingId}
                        parentType="Listing"
                      />
                    </FormBox>
                  }
                />
              </Box>
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EditListing;
