import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton } from "@material-ui/core";
import { useTheme } from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { TbCrane } from "react-icons/tb";
import LinearProgress from "@material-ui/core/LinearProgress";
import PersonIcon from "@material-ui/icons/Person";
import { GiOrganigram } from "react-icons/gi";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import Tab from "@material-ui/core/Tab";
import { useSelector } from "react-redux";
import {
  getUserOrganizationProject,
  importLibrariesByProfile,
} from "./Api.call";
import LibraryImportAccordionBody from "./Library.Import.Accordion.Body";
import emptyPage from "../../Assets/EmptyPageSVG.svg";
import CloseIcon from "@material-ui/icons/Close";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { BiImport } from "react-icons/bi";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  mainDialogCont: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px",
    },
  },
  bodyCont: {
    width: "100%",
    height: "calc(100% - 55px)",
    overflow: "hidden",
  },
  bodyCont1: {
    width: "100%",
    height: "calc(100% - 55px)",
    overflowX: "hidden",
    overflowY: "auto",
  },
  emptyCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 30px",
    "& img": {
      width: "200px",
      height: "auto",
    },
    "& p": {
      fontSize: "14px",
      color: "gray",
      textAlign: "center",
      margin: "10px 0px 25px",
    },
  },
  bottomAct: {
    width: "100%",
    height: "50px",
    padding: "0px 7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "1px solid #ececec",
  },
  dataShowCont: {
    width: "100%",
    height: "calc(100% - 80px)",
    overflow: "hidden",
  },
  selectedItemSty: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 10px",
    border: "1px solid #ececec",
    "& .MuiIconButton-root": {
      padding: "8px",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
      color: "gray",
    },
  },
  nameIconCont: {
    width: "calc(100% - 50px)",
    display: "flex",
    alignItems: "center",
    "& p": {
      width: "calc(100% - 25px)",
      fontSize: "15px",
      fontWeight: "500",
      marginLeft: "5px",
    },
  },
  selectCount: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: "16px",
    marginLeft: "5px",
  },
}));

const LibraryImport = ({
  importLibraryOpen,
  setImportLibraryOpen,
  profileId,
  wallet,
  importForIssues = false,
  setLibrary,
  getLibrariesAndCategories,
}) => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [selectLibraryOpen, setSelectLibraryOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [userProjectsAndOrgObj, setUserProjectsAndOrgObj] = useState(null);
  const [clickedEntity, setClickedEntity] = useState([]);
  const [selectedLibrarIds, setSelectedLibrarIds] = useState([]);
  const [selectedLibrarIdProfileIdObjArr, setSelectedLibrarIdProfileIdObjArr] =
    useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    getUserOrganizationProject({
      curProfileId: profileId,
      userProfileId: user?.profile,
    })
      .then((data) => {
        if (
          (data?.Organization && data.Organization.length > 0) ||
          (data?.Project && data.Project.length > 0) ||
          (data.User && data.User.length > 0)
        ) {
          setUserProjectsAndOrgObj(data);
          if (data?.User && data.User.length > 0) {
            setClickedEntity("User");
          } else if (data?.Organization && data.Organization.length > 0) {
            setClickedEntity("Organization");
          } else if (data?.Project && data.Project.length > 0) {
            setClickedEntity("Project");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onImportSubmit = async () => {
    if (importForIssues) {
      setLibrary(selectedLibrarIdProfileIdObjArr);
      console.log("selected Libraries", selectedLibrarIdProfileIdObjArr);
      setLoader(false);
      setImportLibraryOpen(false);
      setSelectLibraryOpen(false);
      setValue(0);
      setSelectedLibrarIds([]);
      setSelectedLibrarIdProfileIdObjArr([]);
      return;
    }

    setLoader(true);
    let libraryProfiles = [];
    selectedLibrarIdProfileIdObjArr.map((s) => {
      libraryProfiles.push({
        libraryId: s?.library?._id,
        profileId: s?.profileId,
      });
    });
    await importLibrariesByProfile({
      libraryProfiles,
      walletId: wallet?._id,
      profileId: profileId,
      userId: user?._id,
    })
      .then(async () => {
        await getLibrariesAndCategories(false);
        setLoader(false);
        setImportLibraryOpen(false);
        setSelectLibraryOpen(false);
        setValue(0);
        setSelectedLibrarIds([]);
        setSelectedLibrarIdProfileIdObjArr([]);
        if (
          userProjectsAndOrgObj?.User &&
          userProjectsAndOrgObj.User.length > 0
        ) {
          setClickedEntity("User");
        } else if (
          userProjectsAndOrgObj?.Organization &&
          userProjectsAndOrgObj.Organization.length > 0
        ) {
          setClickedEntity("Organization");
        } else if (
          userProjectsAndOrgObj?.Project &&
          userProjectsAndOrgObj.Project.length > 0
        ) {
          setClickedEntity("Project");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <NormalDrawer
      openDrawer={importLibraryOpen}
      setOpenDrawer={setImportLibraryOpen}
      anchor={"right"}
      title={"Import Library(s)"}
      content={
        <div className={classes.mainDialogCont}>
          <div className={classes.bodyCont1}>
            {selectedLibrarIds && selectedLibrarIds.length > 0 ? (
              <>
                {selectedLibrarIdProfileIdObjArr &&
                  selectedLibrarIdProfileIdObjArr.map((libraryProfile) => (
                    <div className={classes.selectedItemSty}>
                      <div className={classes.nameIconCont}>
                        <LibraryBooksIcon />
                        <p>{libraryProfile?.library?.name}</p>
                      </div>

                      <IconButton
                        onClick={() => {
                          const arrObj = selectedLibrarIdProfileIdObjArr.filter(
                            (s) =>
                              s?.library?._id != libraryProfile?.library?._id
                          );
                          const arr = selectedLibrarIds.filter(
                            (id) => id != libraryProfile?.library?._id
                          );
                          setSelectedLibrarIds(arr);
                          setSelectedLibrarIdProfileIdObjArr(arrObj);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  ))}
              </>
            ) : (
              <div className={classes.emptyCont}>
                <img src={emptyPage} />
                <p>
                  You still didn't select any library.
                  <br />
                  Click on then select library button to select library from
                  different directories
                </p>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  startIcon={<TouchAppIcon />}
                  onClick={() => {
                    setSelectLibraryOpen(true);
                  }}
                >
                  Select Library(s)
                </Button>
              </div>
            )}
            <NormalDrawer
              openDrawer={selectLibraryOpen}
              setOpenDrawer={setSelectLibraryOpen}
              anchor={"right"}
              title={
                <span>
                  Select Library(s)
                  {selectedLibrarIds.length && selectedLibrarIds.length > 0 ? (
                    <span className={classes.selectCount}>
                      ({selectedLibrarIds.length})
                    </span>
                  ) : null}
                </span>
              }
              content={
                <div className={classes.mainDialogCont}>
                  <div className={classes.bodyCont}>
                    {userProjectsAndOrgObj && (
                      <>
                        <div className={classes.root}>
                          <AppBar position="static" color="default">
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              indicatorColor="primary"
                              textColor="primary"
                              centered
                            >
                              {Object.keys(userProjectsAndOrgObj).map(
                                (entity) => {
                                  if (entity === "User") {
                                    return (
                                      <Tab
                                        label="User"
                                        icon={<PersonIcon />}
                                        onClick={() => {
                                          setClickedEntity("User");
                                        }}
                                      />
                                    );
                                  } else if (entity === "Organization") {
                                    return (
                                      <Tab
                                        label="Organization"
                                        icon={
                                          <GiOrganigram
                                            style={{ fontSize: "25px" }}
                                          />
                                        }
                                        onClick={() => {
                                          setClickedEntity("Organization");
                                        }}
                                      />
                                    );
                                  } else if (entity === "Project") {
                                    return (
                                      <Tab
                                        label="Project"
                                        icon={
                                          <TbCrane
                                            style={{ fontSize: "21px" }}
                                          />
                                        }
                                        onClick={() => {
                                          setClickedEntity("Project");
                                        }}
                                      />
                                    );
                                  }
                                }
                              )}
                            </Tabs>
                          </AppBar>
                        </div>
                        <div className={classes.dataShowCont}>
                          {clickedEntity === "User" &&
                            userProjectsAndOrgObj?.User && (
                              <LibraryImportAccordionBody
                                profilesArr={userProjectsAndOrgObj?.User || []}
                                selectedLibrarIds={selectedLibrarIds}
                                setSelectedLibrarIds={setSelectedLibrarIds}
                                selectedLibrarIdProfileIdObjArr={
                                  selectedLibrarIdProfileIdObjArr
                                }
                                setSelectedLibrarIdProfileIdObjArr={
                                  setSelectedLibrarIdProfileIdObjArr
                                }
                              />
                            )}
                          {clickedEntity === "Project" &&
                            userProjectsAndOrgObj?.Project && (
                              <LibraryImportAccordionBody
                                profilesArr={
                                  userProjectsAndOrgObj?.Project || []
                                }
                                selectedLibrarIds={selectedLibrarIds}
                                setSelectedLibrarIds={setSelectedLibrarIds}
                                selectedLibrarIdProfileIdObjArr={
                                  selectedLibrarIdProfileIdObjArr
                                }
                                setSelectedLibrarIdProfileIdObjArr={
                                  setSelectedLibrarIdProfileIdObjArr
                                }
                              />
                            )}
                          {clickedEntity === "Organization" &&
                            userProjectsAndOrgObj?.Organization && (
                              <LibraryImportAccordionBody
                                profilesArr={
                                  userProjectsAndOrgObj?.Organization || []
                                }
                                selectedLibrarIds={selectedLibrarIds}
                                setSelectedLibrarIds={setSelectedLibrarIds}
                                selectedLibrarIdProfileIdObjArr={
                                  selectedLibrarIdProfileIdObjArr
                                }
                                setSelectedLibrarIdProfileIdObjArr={
                                  setSelectedLibrarIdProfileIdObjArr
                                }
                              />
                            )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className={classes.bottomAct}>
                    <div></div>

                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      endIcon={<NavigateNextIcon />}
                      onClick={() => {
                        setSelectLibraryOpen(false);
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
            />
          </div>
          <div style={{ width: "100%", height: "5px" }}>
            {loader && <LinearProgress />}
          </div>
          <div className={classes.bottomAct}>
            {selectedLibrarIds && selectedLibrarIds.length > 0 ? (
              <Button
                variant="contained"
                size="small"
                color="primary"
                startIcon={<TouchAppIcon />}
                onClick={() => {
                  setSelectLibraryOpen(true);
                }}
              >
                Select Library(s)
              </Button>
            ) : (
              <div></div>
            )}
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={
                selectedLibrarIds && selectedLibrarIds.length > 0 ? false : true
              }
              startIcon={<BiImport />}
              onClick={onImportSubmit}
            >
              Import
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default LibraryImport;
