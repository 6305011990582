import Api from '../../../helpers/Api';

export const getAppsForWorkspace = async (obj) => {
    try {
        const res = await Api.post('appModule/by-parentmodel', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};