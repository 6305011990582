import { makeStyles } from "@material-ui/core";

const useTableAStyles = makeStyles((theme) => ({
  tableSty: {
    width: "100%",
    border: "none",
    backgroundColor: "#F5F7FA",
    borderCollapse: "collapse",
    marginTop: "10px",
    "& tr": {
      height: "60px",
      marginTop: "5px",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      borderBottom: "1px solid rgba(0,0,0,0.10)",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.005)",
      },
    },
    "& th": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "10px",
      backgroundColor: "#F5F7FA",
      textAlign: "left",
      fontSize: "0.85rem",
    },
    "& td": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "15px",
      textAlign: "left",
      verticalAlign: "center",
      fontSize: "0.9rem",
      color: "#111111",
      "&:nth-child(odd)": {
        fontWeight: "bold",
      },
      "&:nth-child(even)": {
        fontWeight: (props) => props.boldTextEnabled ? "bold" : "normal",
      },
    },
  },
}));

const useTableBStyles = makeStyles((theme) => ({
  tableSty: {
    width: "100%",
    border: "none",
    backgroundColor: "#F5F7FA",
    borderCollapse: "collapse",
    marginTop: "10px",
    "& tr": {
      height: "60px",
      marginTop: "5px",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      borderBottom: "1px solid rgba(0,0,0,0.10)",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.005)",
      },
    },
    "& th": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "10px",
      backgroundColor: "#FFFFFF",
      textAlign: "left",
      fontSize: "0.85rem",
    },
    "& td": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "15px",
      textAlign: "left",
      verticalAlign: "center",
      fontSize: "0.9rem",
      color: "#111111",
      "&:nth-child(odd)": {
        fontWeight: "bold",
      },
      "&:nth-child(even)": {
        fontWeight: "normal",
      },
    },
  },
}));

const useTableCStyles = makeStyles((theme) => ({
  tableSty: {
    width: "auto",
    border: "1px solid rgba(0,0,0,0.10)",
    backgroundColor: "#F5F7FA",
    borderCollapse: "collapse",
    marginTop: "10px",
    "& tr": {
      height: "60px",
      marginTop: "5px",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      borderBottom: "1px solid rgba(0,0,0,0.10)",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.005)",
      },
    },
    "& th": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "10px",
      backgroundColor: "#F5F7FA",
      textAlign: "left",
      fontSize: "0.85rem",
      border: "1px solid rgba(0,0,0,0.10)",
    },
    "& td": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "15px",
      textAlign: "left",
      verticalAlign: "center",
      border: "1px solid rgba(0,0,0,0.10)",
      fontSize: "0.9rem",
      color: "#111111",
      "&:nth-child(odd)": {
        fontWeight: "bold",
      },
      "&:nth-child(even)": {
        fontWeight: "normal",
      },
    },
  },
}));

const useTableDStyles = makeStyles((theme) => ({
  tableSty: {
    width: "100%",
    border: "1px solid rgba(0,0,0,0.10)",
    backgroundColor: "#F5F7FA",
    borderCollapse: "collapse",
    marginTop: "10px",
    "& tr": {
      height: "60px",
      marginTop: "5px",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      borderBottom: "none",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.005)",
      },
    },
    "& th": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "10px",
      backgroundColor: "#F5F7FA",
      textAlign: "left",
      fontSize: "0.85rem",
      border: "none",
      "&:first-child": {
        textAlign: "left",
      },
      "&:last-child": {
        textAlign: "right",
      },
    },
    "& td": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "15px",
      textAlign: "left",
      verticalAlign: "center",
      border: "none",
      fontSize: "0.9rem",
      color: "#111111",
      "&:nth-child(odd)": {
        fontWeight: "bold",
        textAlign: "left",
      },
      "&:nth-child(even)": {
        fontWeight: "normal",
        textAlign: "left",
      },
      "&:first-child": {
        textAlign: "left",
      },
      "&:last-child": {
        textAlign: "right",
      },
    },
  },
}));

const useTableEStyles = makeStyles((theme) => ({
  tableSty: {
    width: "auto",
    border: "1px solid rgba(0,0,0,0.10)",
    backgroundColor: "#F5F7FA",
    borderCollapse: "collapse",
    marginTop: "10px",
    "& tr": {
      height: "60px",
      marginTop: "5px",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      borderBottom: "1px solid rgba(0,0,0,0.10)",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.005)",
      },
    },
    "& th": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "10px",
      backgroundColor: "#F5F7FA",
      textAlign: "left",
      fontSize: "0.85rem",
      border: "1px solid rgba(0,0,0,0.10)",
      minWidth: "200px",
    },
    "& td": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "15px",
      textAlign: "left",
      verticalAlign: "center",
      border: "1px solid rgba(0,0,0,0.10)",
      fontSize: "0.9rem",
      color: "#111111",
      minWidth: "200px",
      "&:nth-child(odd)": {
        fontWeight: "bold",
      },
      "&:nth-child(even)": {
        fontWeight: "normal",
      },
    },
  },
}));

const useTableFStyles = makeStyles((theme) => ({
  tableSty: {
    width: "auto",
    border: "1px solid rgba(0,0,0,0.10)",
    backgroundColor: "#F5F7FA",
    borderCollapse: "collapse",
    marginTop: "10px",
    "& tr": {
      height: "60px",
      marginTop: "5px",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      borderBottom: "1px solid rgba(0,0,0,0.10)",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.005)",
      },
    },
    "& th": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "10px",
      backgroundColor: "#F5F7FA",
      textAlign: "left",
      fontSize: "0.85rem",
      border: "1px solid rgba(0,0,0,0.10)",
    },
    "& td": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "15px",
      textAlign: "left",
      verticalAlign: "center",
      border: "1px solid rgba(0,0,0,0.10)",
      fontSize: "0.9rem",
      color: "#111111",
    },
  },
}));



export {
  useTableAStyles,
  useTableBStyles,
  useTableCStyles,
  useTableDStyles,
  useTableEStyles,
  useTableFStyles,
};
