import { Avatar, Box } from "@mui/material";
import React from "react";

const ListingTableSingleUserCell = ({ label, url }) => {
  return (
    <Box display="flex" alignItems="center">
      <Avatar src={url} sx={{ mr: 2 }} />
      {label}
    </Box>
  );
};

export default ListingTableSingleUserCell;
