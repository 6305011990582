import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        padding: "5px"
    },
    libraryAndCategoryHeaderCont: {
        width: "100%",
        height: "280px",
        backgroundColor: "white",
        padding: "10px",
    },
    librayHeader: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        borderBottom: "1px solid #ececec"
    },
    offeringCategoryHeader: {
        width: "100%",
        height: "40px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        borderBottom: "1px solid #ececec"
    },
    librariesCont: {
        width: "100%",
        height: "calc(100% - 90px)",
        padding: "15px",
        overflowY: "hidden",
        overflowX: "auto",
        display: "flex",
        alignItems: "flex-start",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    searchAndTableCont: {
        width: "100%",
        height: "calc(100% - 290px)",
        marginTop: "10px",
        padding: "10px",
        backgroundColor: "white",
    },
    searchAndAddBtnCont: {
        width: "100%",
        height: "60px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between"
    },
    tableCont: {
        width: "100%",
        height: "calc(100% - 60px)",
        overflow: "hidden"
    }
}));

const LibrarySkeleton = ({ headerHeight, isSubHead }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classes.mainCont} >
            <div className={classes.libraryAndCategoryHeaderCont} >
                <div className={classes.librayHeader} >
                    <Skeleton variant="rect" style={{ width: "300px", height: "30px" }} />

                    <div style={{ display: "flex", alignItems: "center" }}  >
                        <Skeleton variant="rect" style={{ width: "120px", height: "40px", marginRight: "15px" }} />
                        <Skeleton variant="rect" style={{ width: "120px", height: "40px" }} />
                    </div>
                </div>
                <div className={classes.librariesCont} >
                    <Skeleton variant="rect" style={{ width: "250px", height: "130px", marginRight: "15px" }} />
                    <Skeleton variant="rect" style={{ width: "250px", height: "130px", marginRight: "15px" }} />
                    <Skeleton variant="rect" style={{ width: "250px", height: "130px", marginRight: "15px" }} />
                    <Skeleton variant="rect" style={{ width: "250px", height: "130px" }} />
                </div>
                <div className={classes.offeringCategoryHeader} >
                    <div style={{ display: "flex", alignItems: "center" }}  >
                        <Skeleton variant="rect" style={{ width: "250px", height: "30px", marginRight: "15px" }} />
                        <Skeleton variant="rect" style={{ width: "190px", height: "30px" }} />
                    </div>

                    <div></div>
                </div>
            </div>

            <div className={classes.searchAndTableCont} >
                <div className={classes.searchAndAddBtnCont} >
                    <Skeleton variant="rect" style={{ width: "400px", height: "40px" }} />

                    <Skeleton variant="rect" style={{ width: "120px", height: "40px" }} />
                </div>
                <div className={classes.tableCont} >
                    <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "10px" }} />
                    <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "10px" }} />
                    <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "10px" }} />
                    <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "10px" }} />
                    <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "10px" }} />
                    <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "10px" }} />
                    <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "10px" }} />
                    <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "10px" }} />
                    <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "10px" }} />
                    <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "10px" }} />
                </div>
            </div>
            <div></div>
        </div>
    );
};

export default LibrarySkeleton;