import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { GoInbox } from "react-icons/go";
import { getMutalTxByType } from "./api.call";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import TxDetailsDialog from "../finance2o/commonComponent/TxDetails.Dialog";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    emptySty: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            color: "gray"
        }
    },
    tableContainer: {
        width: "100%",
        height: "calc(100% - 50px)",
        "& tr": {
            height: "45px"
        },
        "& table": {
            tableLayout: "auto"
        }
    },
    tableRow: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec !important"
        }
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #d2d1d1",
    },
    nameEmailSty: {
        width: "100%",
        paddingLeft: "10px",
        "& h3": {
            fontSize: "14px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "11px",
            fontWeight: "400",
            color: "gray"
        }
    },
    loaderCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));

export default function Transactions({
    curEntityProfileId, secondPartyId
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [loader, setLoader] = useState(true)
    const [totalPages, setTotalPages] = useState(0)
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)
    const [allTxs, setAllTxs] = useState([])
    const [openDetails, setOpenDetails] = useState(false)
    const [selectedTx, setSelectedTx] = useState(null)

    const getAllTxs = async () => {
        if (curEntityProfileId && secondPartyId) {
            setLoader(true)
            await getMutalTxByType({
                firstPartyProfile: curEntityProfileId,
                curPage: curPage - 1,
                limit: pageLimit,
                secondPartyProfile: secondPartyId
            })
                .then((data) => {
                    setAllTxs(data?.data || [])
                    let locTotalPage = Math.ceil((data?.count || 0) / pageLimit);
                    setTotalPages(locTotalPage)
                    setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                    setLoader(false)
                })
        }
    }

    useEffect(() => {
        getAllTxs()
    }, 1000, [curPage, curEntityProfileId, secondPartyId])

    const getFormatedData = (data) => {
        let formatedData = new Intl.NumberFormat('en-GB', { notation: "compact", compactDisplay: "short" }).format(data);
        return formatedData;
    }


    return (<>
        {loader ? (<div className={classes.loaderCont} >
            <CircularProgress />
        </div>) : (<>
            {(allTxs && allTxs.length > 0) ? (<>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="sticky table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px", width: "95px" }} >
                                    Date
                                </TableCell>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px", width: "70px" }} >
                                    No
                                </TableCell>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px", width: "70px" }} >
                                    Type
                                </TableCell>
                                <TableCell align="center" className={classes.tbHeaderCell} style={{ padding: "0px", width: "90px" }} >
                                    Accounting
                                </TableCell>
                                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "120px" }} >
                                    Due Date
                                </TableCell>
                                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "90px" }} >
                                    Amount
                                </TableCell>
                                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "110px" }} >
                                    Amount Paid
                                </TableCell>
                                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "15px", width: "110px" }} >
                                    Status
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allTxs && allTxs.length > 0 && allTxs.map((data) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={data._id}
                                        onClick={() => {
                                            if (data?._id) {
                                                setSelectedTx(data);
                                                setOpenDetails(true);
                                            }
                                        }}
                                        style={data?._id ? { cursor: "pointer" } : {}}
                                    >
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "5px", width: "90px" }} >
                                            {data?._id ? (<>
                                                {new Date(data?.createdAt).getMonth() + 1 + "/" + new Date(data?.createdAt).getDate() + "/" + new Date(data?.createdAt).getFullYear()}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "5px", width: "70px" }} >
                                            {data?._id ? (<>
                                                {data?.txNo}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "5px", width: "70px" }} >
                                            {data?._id ? (<>
                                                {data?.type === "Bill" ? "Expense" : data?.type}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="center" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "5px", width: "90px" }} >
                                            {data?._id ? (<>
                                                {data?.notIntregratedAccounting ? (<CloseIcon style={{ color: "red" }} />) : (<CheckIcon style={{ color: "green" }} />)}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "120px" }} >
                                            {data?._id ? (<>
                                                {new Date(data?.dueDate).getMonth() + 1 + "/" + new Date(data?.dueDate).getDate() + "/" + new Date(data?.dueDate).getFullYear()}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "90px" }} >
                                            {data?._id ? (<>
                                                ₹{getFormatedData(data?.finalAmount || 0)}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "110px" }} >
                                            {data?._id ? (<>
                                                ₹{getFormatedData(data?.amountPaid || 0)}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "15px", width: "110px" }} >
                                            {data?._id ? (<>
                                                {data?.status}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.paginationCont} >
                    <Pagination
                        count={totalPages}
                        page={curPage}
                        color="primary"
                        siblingCount={0}
                        onChange={(event, value) => { setCurPage(value) }}
                    />
                </div>
                {selectedTx && (
                    <TxDetailsDialog
                        walletId={selectedTx?.firstPartyWallet?._id}
                        openDetails={openDetails}
                        setOpenDetails={setOpenDetails}
                        selectedTx={selectedTx}
                        setSelectedTx={setSelectedTx}
                        editClickUrl={`/finance/${selectedTx?.firstPartyWallet?._id}/bill/edit/`}
                        viewClickUrl={`/finance/${selectedTx?.firstPartyWallet?._id}/bill/view/`}
                    />
                )}
            </>) : (
                <div className={classes.emptySty} >
                    <GoInbox style={{ fontSize: "100px" }} />
                    <p>No transactions exisit for current contact</p>
                </div>
            )}
        </>)}
    </>);
}