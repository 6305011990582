import React from "react";
import { useParams } from "react-router-dom";
import ProjectHome from "./Project.Home";

export default function ProjectViewHome(props) {
    let { teamId } = useParams();
    return (
        <ProjectHome
            viewMode={true}
            viewedTeamId={teamId}
        />
    );
}