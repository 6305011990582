import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TabsWithSearch from "../../../styled/generic/TabsWithSearch";
import MyPopOver from "../../../styled/CommonComponents/MyPopOver";
import MoreButton from "../../../styled/generic/MoreButton";
import { makeStyles } from "@material-ui/core";
import TabsWithActions from "../../../styled/generic/TabsWithActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Matches from "./matches/Matches";
import Leads from "./leads/Leads";
import Applications from "./applications/Applications";
import Requests from "./requests/Requests";

const useStyles = makeStyles({
  tableSty: {
    border: "none",
    width: "50%",
    borderCollapse: "collapse",
    "& tr": {
      height: "25px",
    },
    "& th": {
      border: "none",
      borderCollapse: "collapse",
      fontSize: "14px",
      fontWeight: "500",
      padding: "0px",
      textAlign: "left",
    },
    "& td": {
      border: "none",
      borderCollapse: "collapse",
      fontSize: "18px",
      fontWeight: "600",
      padding: "0px",
      textAlign: "left",
    },
  },
});

const RequirementListings = () => {
  const REQUIREMENT_ACTIONS = ["Todo"];
  const handleRequirementActionClick = () => {};

  const classes = useStyles();
  const dispatch = useDispatch();
  const { requirementId } = useParams();
  const { user } = useSelector((state) => state.auth);

  const [requirementTab, setRequirementTab] = useState("Matches");

  return (
    <Box style={{ padding: "2rem" }}>
      <Box sx={{ backgroundColor: "#ffffff", borderRadius: 3, p: 2 }}>
        <Box>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Avatar />
                <Typography sx={{ ml: 2 }} variant="h6">
                  MyRequirement
                </Typography>
              </Box>
              <Box>
                <MyPopOver
                  closeOnClick={true}
                  appearContent={<MoreButton />}
                  showContent={
                    <List sx={{ width: 200 }}>
                      {REQUIREMENT_ACTIONS?.map((action) => (
                        <ListItem
                          disablePadding
                          onClick={() => handleRequirementActionClick(action)}
                        >
                          <ListItemButton>
                            <ListItemText primary={action} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Typography variant="body2">Requirement • 12 Days ago</Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 4 }}>
          <table className={classes.tableSty}>
            <tr>
              <th>Property Type</th>
              <th>Location</th>
              <th>Bed Room</th>
              <th>Budget</th>
            </tr>
            <tr>
              <td>Appartment</td>
              <td>Delhi, India</td>
              <td>3 BHK, 2 BHK</td>
              <td>₹ 2000 - ₹ 3000</td>
            </tr>
          </table>
        </Box>
      </Box>

      <Box sx={{ mt: 2 }}>
        <TabsWithActions
          tabs={[
            {
              title: "Matches",
            },
            {
              title: "Leads",
            },
            {
              title: "Requests",
            },
            {
              title: "My Applications",
            },
          ]}
          onTabChange={(val) => setRequirementTab(val)}
          currentTabVariable={requirementTab}
        />

        {requirementTab === "Matches" && <Matches />}
        {requirementTab === "My Applications" && <Applications />}
        {requirementTab === "Requests" && <Requests />}
        {requirementTab === "Leads" && <Leads />}
      </Box>
    </Box>
  );
};

export default RequirementListings;
