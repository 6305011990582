import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar, useScrollTrigger, useMediaQuery,
  IconButton, Avatar, ClickAwayListener
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Search as SearchIcon } from '@material-ui/icons';
import SearchBar from "./SimpleSearchBar";
import { useHistory } from "react-router-dom";
import Notification from "./notification";
import ChatIcon from '@material-ui/icons/Chat';
import firebase from "firebase";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

const useStyles = makeStyles((theme) => ({
  actionsCont: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 20px"
  },
  btnsCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiIconButton-root": {
      padding: "8px"
    }
  },
  searchBtn: {
    height: "40px",
    width: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: "#f5f4f6",
  },
  searchCloseCont: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between"
  },
  logOutBtnCont: {
    position: "fixed",
    top: "55px",
    right: "6px",
    zIndex: "99999",
    backgroundColor: "rgba(0,0,0,0)"
  },
  textIconCont: {
    backgroundColor: "white",
    position: "relative",
    width: "100px",
    height: "0px",
    borderRadius: "7px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    overflow: "hidden",
    cursor: "pointer",
    transition: "all .3s ease-in-out",
  },
  openTextIconCont: {
    backgroundColor: "white",
    position: "relative",
    width: "100px",
    height: "40px",
    borderRadius: "7px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    overflow: "hidden",
    cursor: "pointer",
    transition: "all .3s ease-in-out",
  },
  onlyTextIconCont: {
    width: "100px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
    "& p": {
      marginLeft: "5px",
      fontSize: "14px",
      fontWeight: "500",
    }
  }
}));

export default function AppbarComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { user } = useSelector((state) => state.auth);

  const [showSearchInput, setShowSearchInput] = useState(false)
  const [openSignOut, setOpenSignOut] = useState(false)

  const logout = () => {
    firebase.auth().signOut()
      .then(() => {
        dispatch({
          type: "TeamReset",
        });
        dispatch({
          type: "FeedReset",
        });
        dispatch({
          type: "FileReset",
        });
        dispatch({
          type: "IssueReset",
        });

        dispatch({
          type: "WalletReset",
        });
        dispatch({
          type: "PipelineReset",
        });
        dispatch({
          type: "DashboardReset",
        });
        dispatch({
          type: "CommentReset",
        });
        dispatch({
          type: "ProjectReset",
        });
        dispatch({
          type: "ChatReset",
        });
        dispatch({
          type: "REMOVE_AUTH_USER",
        });
        localStorage.removeItem("token");
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  return (
    <AppBar
      position="relative"
      elevation={trigger ? 4 : 0}
      className={classes.appBarStyle + " appbar-main"}
    >
      <div className={classes.actionsCont} >
        {isMobile ? (<>
          {showSearchInput ? (<div className={classes.searchCloseCont} >
            <SearchBar />
            <CloseIcon
              style={{ color: "black" }}
              onClick={() => { setShowSearchInput(false) }}
            />
          </div>) : (<>
            <span className={classes.searchBtn} >
              <SearchIcon
                style={{ color: "#7C7C8D" }}
                onClick={() => { setShowSearchInput(true) }}
              />
            </span>

            <div className={classes.btnsCont} >
              <IconButton>
                <Notification />
              </IconButton>
              <IconButton
                style={{ marginLeft: "15px" }}
                onClick={() => { history.push("/messages") }}
              >
                <ChatIcon style={{ color: theme.palette.action.disabled }} />
              </IconButton>
              <ClickAwayListener onClickAway={() => { setOpenSignOut(false) }}>
                <div style={{ position: "relative" }} >
                  <IconButton
                    style={{ marginLeft: "15px" }}
                    onClick={() => { setOpenSignOut(!openSignOut) }}
                  >
                    <Avatar style={{ width: "24px", height: "24px" }} src={user?.displayPicture?.url} />
                  </IconButton>
                  <div className={classes.logOutBtnCont} >
                    <div className={openSignOut ? classes.openTextIconCont : classes.textIconCont} >
                      <div className={classes.onlyTextIconCont} onClick={logout} >
                        <PowerSettingsNewIcon style={{ fontSize: "15px" }} />
                        <p>Sign out</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            </div>
          </>)}
        </>) : (<>
          <SearchBar />
          <div className={classes.btnsCont} >
            <IconButton>
              <Notification />
            </IconButton>
            <IconButton
              style={{ marginLeft: "15px", marginRight: "10px" }}
              onClick={() => { history.push("/messages") }}
            >
              <ChatIcon style={{ color: theme.palette.action.disabled }} />
            </IconButton>
          </div>
        </>)}
      </div>
    </AppBar>
  );
}