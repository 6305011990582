import React, { useState, useEffect } from 'react';
import useSound from 'use-sound';

const VoiceNotePlayer = ({ voiceNotes }) => {
  const [currentNoteIndex, setCurrentNoteIndex] = useState(null);
  const [play, { stop, isPlaying }] = useSound(currentNoteIndex !== null ? voiceNotes[currentNoteIndex].url : null);

  useEffect(() => {
    return () => {
      stop(); // Stop playback when component unmounts
    };
  }, [stop]);

  const handlePlay = (index) => {
    if (currentNoteIndex === index && isPlaying) {
      stop();
      setCurrentNoteIndex(null);
    } else {
      stop();
      setCurrentNoteIndex(index);
      play();
    }
  };

  return (
    <div className="voice-note-player">
      {voiceNotes.map((note, index) => (
        <button
          key={note._id}
          onClick={() => handlePlay(index)}
          className={`voice-note-button ${currentNoteIndex === index && isPlaying ? 'playing' : ''}`}
        >
          {currentNoteIndex === index && isPlaying ? 'Pause' : 'Play'} Voice Note {index + 1}
        </button>
      ))}
    </div>
  );
};

export default VoiceNotePlayer;