import { MoreVert } from "@material-ui/icons";
import { IconButton } from "@mui/material";
import React from "react";

const MoreButton = ({ size }) => {
  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.05)",
        borderRadius: "50%",
        display: "inline-block",
      }}
    >
      <IconButton size={size ? size : "small"}>
        <MoreVert fontSize={size ? size : "small"} />
      </IconButton>
    </div>
  );
};

export default MoreButton;
