import { Box } from "@mui/material";
import React, { useState } from "react";
import TabsWithSearch from "../../../../styled/generic/TabsWithSearch";

const Bookings = () => {
  const [bookingsTab, setBookingsTab] = useState("Active");
  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "10px", p: 2, mt: 2 }}>
      <TabsWithSearch
        tabs={["Active", "successful", "Canceled"]}
        onTabChange={(newTabVal) => setBookingsTab(newTabVal)}
        currentTabVariable={bookingsTab}
        disableUnderline={true}
      />
    </Box>
  );
};

export default Bookings;
