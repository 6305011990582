import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, IconButton, useMediaQuery } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useSelector, useDispatch } from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import { updateOrgWithPopulate } from '../api.call';
import teamUtils from "../../team/team.utils";

const useStyles = makeStyles((theme) => ({
    header: {
        height: "60px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #e4e7e7",
        padding: "0px 25px",
        "& h3": {
            fontSize: "17px",
            fontWeight: "450",
            color: "#170f49"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0px 10px",
        }
    },
    body: {
        width: "100%",
        height: "calc(100% - 65px)",
        padding: "20px 40px 40px",
        paddingRight: "0px",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        [theme.breakpoints.down("md")]: {
            padding: "15px 10px 30px",
        }
    },
    singleEducationCont: {
        width: "700px",
        marginBottom: "20px",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    textLineCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column"
        }
    },
    halfCont: {
        width: "47%",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }
    },
    checkedCont: {
        display: "flex",
        alignItems: "center",
        marginTop: "10px",
        marginBottom: "-10px",
        "& h3": {
            fontSize: "15px",
            marginRight: "15px",
            fontWeight: "500",
        }
    },
    label: {
        fontSize: "15px",
        fontWeight: "500",
        marginTop: "15px",
        marginBottom: "2px"
    }
}));

const calendarMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export default function AwardHonorsEdit({ setOpenEditView, profile, teamId }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch()
    const { handleTeams } = teamUtils;

    const state = useSelector((state) => state);
    const { teamDictionary } = useSelector((state) => state.team);
    const { user } = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false)
    const [stateChange, setStateChange] = useState(false)
    const [calendarYear, setCalendarYear] = useState([]);
    const [awardHonorsObjArr, setAwardHonorsObjArr] = useState([])

    const isSmall = useMediaQuery(theme.breakpoints.down("xs"));


    useEffect(() => {
        let honorsArr = teamDictionary[teamId]?.parent?.honors || []
        if (honorsArr && honorsArr.length > 0) {
            let arr = []
            honorsArr.map((license) => {
                let loc_startMonth
                let loc_startYear

                let locStartDate = license?.issuing_date || ""
                if (locStartDate && locStartDate.length > 3) {
                    loc_startMonth = locStartDate.split("-")[0]
                    loc_startYear = locStartDate.split("-")[1]
                } else {
                    loc_startMonth = ""
                    loc_startYear = ""
                }

                arr.push({
                    name: license?.title,
                    description: license?.description,
                    issuingOrganization: license?.issuer,
                    issuing_month: loc_startMonth,
                    issuing_year: loc_startYear,
                })
            })
            setAwardHonorsObjArr(arr)
        }
    }, [teamDictionary[teamId], teamDictionary[teamId]?.parent, teamDictionary[teamId]?.parent?.honors])

    useEffect(() => {
        const years = [];
        const date = new Date();
        const endYear = date.getFullYear();
        for (let i = 1951; i <= endYear; i++) {
            years.push(i + "");
        }
        setCalendarYear(years);
    }, []);

    const addNewAwardHonors = () => {
        setStateChange(!stateChange)
        setAwardHonorsObjArr([
            {
                name: "",
                description: "",
                issuingOrganization: "",
                issuing_month: "",
                issuing_year: "",
            },
            ...awardHonorsObjArr
        ])
        setStateChange(!stateChange)
    }

    const onChangeValue = (value, i, key) => {
        setStateChange(!stateChange)
        let arr = [...awardHonorsObjArr]
        arr[i][key] = value
        setAwardHonorsObjArr(arr)
        setStateChange(!stateChange)
    }

    const deleteLine = (i) => {
        setStateChange(!stateChange)
        let arr = [...awardHonorsObjArr]
        arr.splice(i, 1)
        setAwardHonorsObjArr(arr)
        setStateChange(!stateChange)
    }

    const updateProfileSection = async () => {
        setLoading(true)
        let objArr = []
        awardHonorsObjArr.map((awardHonorData) => {
            objArr.push({
                title: awardHonorData?.name,
                issuing_date: `${awardHonorData?.issuing_month}-${awardHonorData?.issuing_year}`,
                issuer: awardHonorData?.issuingOrganization,
                description: awardHonorData?.description
            })
        })

        await updateOrgWithPopulate({
            _id: profile?.parent?._id,
            honors: objArr
        })
            .then((data) => {
                let teamDataFromDic = teamDictionary[teamId]
                let teamParent = teamDataFromDic?.parent
                let updatedHonors = data?.honors || []
                const team = {
                    ...teamDataFromDic,
                    parent: {
                        ...teamParent,
                        honors: updatedHonors
                    }
                }
                handleTeams([team], state, dispatch);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            })
    }


    return (
        <>
            <div className={classes.header} >
                <h3>Add & Edit Awards</h3>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={addNewAwardHonors}
                        size={isSmall ? "small" : "medium"}
                    >
                        Add
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        size={isSmall ? "small" : "medium"}
                        style={{ marginLeft: "10px" }}
                        onClick={updateProfileSection}
                    >
                        Save
                    </Button>

                    <IconButton
                        style={{ marginLeft: "10px" }}
                        onClick={() => { setOpenEditView(false) }}
                        size={isSmall ? "small" : "medium"}
                    >
                        <ClearIcon />
                    </IconButton>
                </div>
            </div>
            <div style={{ height: "5px" }} >
                {loading && (<LinearProgress />)}
            </div>
            <div className={classes.body} >
                {awardHonorsObjArr && awardHonorsObjArr.length > 0 && awardHonorsObjArr.map((awardHonorsObj, i) => (
                    <div key={i} className={classes.singleEducationCont} >
                        <p className={classes.label} >Award Name</p>
                        <TextField
                            required
                            id="school"
                            placeholder="Enter Award Name"
                            value={awardHonorsObj?.name}
                            onChange={(e) => {
                                onChangeValue(e.target.value, i, "name")
                            }}
                            size="small"
                            variant="outlined"
                            style={{ width: "100%" }}
                        />

                        <p className={classes.label} >Issuer</p>
                        <TextField
                            required
                            id="school"
                            placeholder="Enter Issuer Name"
                            value={awardHonorsObj?.issuingOrganization}
                            onChange={(e) => {
                                onChangeValue(e.target.value, i, "issuingOrganization")
                            }}
                            size="small"
                            variant="outlined"
                            style={{ width: "100%" }}
                        />

                        <p className={classes.label} >Issuing Date</p>
                        <div className={classes.textLineCont} >
                            <div className={classes.halfCont} >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={awardHonorsObj?.issuing_month}
                                    options={calendarMonth}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option === awardHonorsObj?.issuing_month
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Enter issuing month"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "issuing_month")
                                    }}
                                    style={{ width: "100%", marginBottom: "5px" }}
                                    size="small"
                                />
                            </div>
                            <div className={classes.halfCont} >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={awardHonorsObj?.issuing_year}
                                    options={calendarYear}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option === awardHonorsObj?.issuing_year
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Enter issuing year"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "issuing_year")
                                    }}
                                    style={{ width: "100%", marginBottom: "5px" }}
                                    size="small"
                                />
                            </div>
                        </div>

                        <p className={classes.label} >Description</p>
                        <TextField
                            id="description"
                            placeholder="Write anything notable..."
                            multiline
                            rows={3}
                            variant="outlined"
                            size="small"
                            value={awardHonorsObj?.description}
                            onChange={(e) => {
                                onChangeValue(e.target.value, i, "description");
                            }}
                            fullWidth
                        />

                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "15px 0px 30px" }} >
                            <div></div>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<DeleteIcon />}
                                onClick={() => { deleteLine(i) }}
                                size={isSmall ? "small" : "medium"}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}