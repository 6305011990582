import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import Api from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";

const WorkScheduleInvitation = () => {
  const [pendingSchedules, setPendingSchedules] = useState([]);
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth);
  const getPendingInvitations = async () => {
    try {
      const { data } = await Api.post("/workschedule/pending", {
        userId: user._id,
      });
      setPendingSchedules(data);
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch pending work schedule invitations",
        },
      });
    }
  };

  const acceptInvitation = async (workScheduleId) => {
    try {
      const { data } = await Api.post("/workschedule/accept", {
        workScheduleId,
      });
      getPendingInvitations();
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to accept invitation",
        },
      });
    }
  };

  const rejectInvitation = async (workScheduleId) => {
    try {
      const { data } = await Api.post("/workschedule/reject", {
        workScheduleId,
      });
      getPendingInvitations();
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to reject invitation",
        },
      });
    }
  };

  useEffect(() => {
    getPendingInvitations();
  }, []);

  return (
    <div>
      {pendingSchedules && pendingSchedules.length > 0 && (
        <div>
          {pendingSchedules.map((item, i) => (
            <Box
              key={i}
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ backgroundColor: "#DBECFF", padding: "12px" }}
            >
              <Typography fontSize="1rem">
                {`Incoming work schedule invitation from ${item.organization.displayName}`}
              </Typography>
              <Box display="flex" style={{ marginLeft: "10px" }}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => acceptInvitation(item._id)}
                >
                  Accept
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={() => rejectInvitation(item._id)}
                >
                  Reject
                </Button>
              </Box>
            </Box>
          ))}
        </div>
      )}
    </div>
  );
};

export default WorkScheduleInvitation;
