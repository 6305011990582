import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import AccountingCardSvg from "../../../Assets/accounting_logo.svg";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Avatar } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { format } from "timeago.js";
import LessText from "../../styled/CommonComponents/LessText";
import AddNewTxDialog from "../../finance2o/commonComponent/transaction/AddNewTxDialog";
import emptyIcon from "../../../Assets/vivekEmpty.svg"
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "100%",
    height: "370px",
    borderRadius: "10px",
    marginBottom: "20px",
    padding: "10px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 5px",
    },
  },
  cardTopHeading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > h3": {
      fontWeight: "510",
      fontSize: "17px",
      color: "#193B56"
    }
  },
  createBtn: {
    width: "25px",
    height: "25px",
    borderRadius: "6px",
    backgroundColor: "#c1def6",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#70b8f4",
      color: "white"
    }
  },
  topBox: {
    width: "100%",
    padding: "3px 10px",
    display: "flex",
    alignItems: "flex-start",
    marginTop: "10px",
    cursor: "pointer",
    marginBottom: "10px",
    "& > p": {
      fontSize: "15px",
      marginTop: "10px",
      color: "#696969"
    },
    "&:hover": {
      backgroundColor: "#edecec"
    }
  },
  topBoxSvgCont: {
    width: "120px",
    height: "70px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
    "& > img": {
      width: "95px",
      height: "95px",
    }
  },
  container: {
    width: "100%",
    height: "160px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  timeSty: {
    fontSize: "10px",
    fontWeight: "500",
    color: "gray"
  },
  iconSty: {
    fontSize: "45px"
  },
  tbHeaderCell: {
    borderTop: "1px solid #e0e0e0",
    color: "#696969",
    fontSize: "15px",
    padding: "0px",
  },
  tbBodyCell: {
    border: "none",
    padding: "10px 5px"
  },
  statusCont: {
    fontSize: "11px",
    padding: "1px 4px",
    borderRadius: "3px",
  },
  valuesCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0px 5px 7px",
    marginBottom: "15px",
    justifyContent: "space-between",
    "& div": {
      width: "24%",
      "& h3": {
        fontSize: "14px",
        fontWeight: "510",
        textAlign: "center",
        marginBottom: "8px"
      },
      "& p": {
        fontSize: "12px",
        fontWeight: "510",
        textAlign: "center",
      }
    }
  },
  skeletonCont: {
    paddingTop: "10px",
    width: "100%",
    height: "125px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  emptyCont: {
    display: "flex",
    width: "100%",
    height: "120px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    "& img": {
      height: "70px",
      width: "auto"
    },
    "& p": {
      fontSize: "12px",
      color: "gray",
      marginTop: "5px",
      textAlign: "center",
    }
  }
}));

const textColorArr = {
  "Draft": "rgb(128, 128, 128)",
  "Submitted": "rgb(33, 150, 243)",
  "Sent": "rgb(255, 165, 0)",
  "Opened": "rgb(255, 127, 80)",
  "Partially Paid": "rgb(238, 29, 82)",
  "Processing": "rgb(255, 215, 0)",
  "Failed": "rgb(238, 29, 82)",
  "Paid": "rgb(0, 156, 121)"
}

const bgColorArr = {
  "Draft": "rgba(128, 128, 128, 0.2)",
  "Submitted": "rgba(33, 150, 243, 0.2)",
  "Sent": "rgba(255, 165, 0, 0.2)",
  "Opened": "rgba(255, 127, 80, 0.2)",
  "Partially Paid": "rgba(238, 29, 82, 0.2)",
  "Processing": "rgba(255, 215, 0, 0.2)",
  "Failed": "rgba(238, 29, 82, 0.2)",
  "Paid": "rgba(0, 156, 121, 0.2)"
}

const PaymentsCard = ({ transactions, walletId, chartAccounts, loader }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const getFormatedData = (data) => {
    let formatedData = new Intl.NumberFormat('en-GB', { notation: "compact", compactDisplay: "short" }).format(data);
    return formatedData;
  }

  const [receivableAmount, setReceivableAmount] = useState(0)
  const [payablesAmount, setPayablesAmount] = useState(0)
  const [incomeAmount, setIncomeAmount] = useState(0)
  const [expenseAmount, setExpenseAmount] = useState(0)


  useEffect(() => {
    let locReceivableAmount = 0
    let locPayablesAmount = 0
    let locIncomeAmount = 0
    let locExpenseAmount = 0
    if (chartAccounts && chartAccounts.length > 0) {
      chartAccounts.map((account) => {
        if (account?.name === "Account Receivable (A/R)") {
          locReceivableAmount = account.balance
        } else if (account?.name === "Accounts Payables (A/P)") {
          locPayablesAmount = account.balance
        } else if (account?.topLevel === "Revenue") {
          locIncomeAmount += account?.balance
        } else if (account?.topLevel === "Expense") {
          locExpenseAmount += account?.balance
        }
      })
    }

    setReceivableAmount(locReceivableAmount)
    setPayablesAmount(locPayablesAmount)
    setIncomeAmount(locIncomeAmount)
    setExpenseAmount(locExpenseAmount)
  }, [chartAccounts])


  return (
    <Paper className={classes.cardContainer}>
      <div className={classes.cardTopHeading} >
        <h3>Accounts & Payments</h3>
        <AddNewTxDialog
          btnComp={
            <IconButton
              className={classes.createBtn}
              color="primary"
            >+</IconButton>
          }
          walletId={walletId}
        />
      </div>

      <Paper
        elevation={1}
        onClick={() => {
          if (walletId) {
            history.push(`/finance/${walletId}/bank-accounts`);
          }
        }}
        className={classes.topBox}
      >
        <div className={classes.topBoxSvgCont} >
          <img src={AccountingCardSvg} />
        </div>
        <p>Keep track of your transactions and manage your account.</p>
      </Paper>

      <div className={classes.valuesCont} >
        <div>
          <h3>Income</h3>
          <p style={{ color: "#219653" }} >
            {`₹ ${getFormatedData(incomeAmount || 0)}`}
          </p>
        </div>
        <div>
          <h3>Expenses</h3>
          <p style={{ color: "#EB5757" }} >
            {`₹ ${getFormatedData(expenseAmount || 0)}`}
          </p>
        </div>
        <div>
          <h3>Receivables</h3>
          <p style={{ color: "#219653" }} >
            {`₹ ${getFormatedData(receivableAmount || 0)}`}
          </p>
        </div>
        <div>
          <h3>Payables</h3>
          <p style={{ color: "#EB5757" }} >
            {`₹ ${getFormatedData(payablesAmount || 0)}`}
          </p>
        </div>
      </div>

      {loader ? (<>
        <TableContainer style={{ width: "100%" }} >
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow style={{ position: "relative", zIndex: "99", height: "40px" }} >
                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px" }} >
                  Transactions
                </TableCell>
                <TableCell align="center" className={classes.tbHeaderCell} style={{ padding: "0px", width: "90px" }} >
                  Type
                </TableCell>
                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "85px" }} >
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <div className={classes.skeletonCont} >
          <Skeleton variant="rect" style={{ width: "100%", marginBottom: "10px" }} height={50} />
          <Skeleton variant="rect" style={{ width: "100%", marginBottom: "10px" }} height={50} />
          <Skeleton variant="rect" style={{ width: "100%", marginBottom: "10px" }} height={50} />
        </div>
      </>) : (<>{transactions && transactions.length > 0 ? (
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow style={{ position: "relative", zIndex: "99", height: "35px" }} >
                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px" }} >
                  Transactions
                </TableCell>
                <TableCell align="center" className={classes.tbHeaderCell} style={{ padding: "0px", width: "110px" }} >
                  Type
                </TableCell>
                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "85px" }} >
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction, index) => (
                <TableRow
                  key={index}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    let goUrl = "/"
                    if (walletId == transaction?.firstPartyWallet) {
                      if (transaction?.type === "Bill") {
                        goUrl = `/finance/${walletId}/bill/edit/${transaction?._id}`
                      } else if (transaction?.type === "Invoice") {
                        goUrl = `/finance/${walletId}/invoice/edit/${transaction?._id}`
                      }
                    } else if (walletId == transaction?.secondPartyWallet) {
                      if (transaction?.type === "Bill") {
                        goUrl = `/finance/${walletId}/bill/view/${transaction?._id}`
                      } else if (transaction?.type === "Invoice") {
                        goUrl = `/finance/${walletId}/invoice/view/${transaction?._id}`
                      }
                    }
                    
                    history.push(goUrl);
                  }}
                >
                  <TableCell align="left" className={classes.tbBodyCell} style={{ paddingLeft: "0px" }} >
                    <div style={{ display: "flex", alignItems: "center" }} >
                      <Avatar src={transaction?.firstParty?.parent?.displayPicture?.url} style={{ width: "30px", height: "30px" }} />
                      <div style={{ marginLeft: "5px" }} >
                        <p style={{ fontSize: "13px", color: "#333333", fontWeight: "510", textTransform: "capitalize" }} >
                          <LessText
                            limit={13}
                            string={transaction?.firstParty?.parent?.displayName}
                          />
                        </p>
                        <p className={classes.timeSty} >{format(transaction?.createdAt)}</p>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center" className={classes.tbBodyCell} style={{ padding: "0px" }} >
                    <p style={{ color: "#4F4F4F", fontSize: "13px", fontWeight: "510", textTransform: "capitalize" }} >{transaction.type}</p>
                    <span className={classes.statusCont}
                      style={{
                        color: textColorArr[transaction?.status],
                        backgroundColor: bgColorArr[transaction?.status]
                      }}
                    >
                      {transaction.status}
                    </span>
                  </TableCell>
                  <TableCell align="right" className={classes.tbBodyCell} style={{ color: "#4F4F4F", fontSize: "13px", fontWeight: "500", paddingRight: "5px" }} >
                    {`₹ ${getFormatedData(transaction?.amount || 0)}`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (<>
        <TableContainer style={{ width: "100%" }} >
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow style={{ position: "relative", zIndex: "99", height: "40px" }} >
                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px" }} >
                  Transactions
                </TableCell>
                <TableCell align="center" className={classes.tbHeaderCell} style={{ padding: "0px", width: "90px" }} >
                  Type
                </TableCell>
                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "85px" }} >
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <div className={classes.emptyCont} >
          <img src={emptyIcon} />
          <p>There is nothing to show here.
            <br />
            <AddNewTxDialog
              btnComp={
                <span style={{ color: theme.palette.primary.main, marginLeft: "3px", cursor: "pointer" }} >
                  Click to add new item.
                </span>
              }
              walletId={walletId}
            />
          </p>
        </div>
      </>)}</>)}
    </Paper>
  );
};
export default PaymentsCard;