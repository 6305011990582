import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";
import TabsWithSearch from "../../styled/generic/TabsWithSearch";
import MyRequirements from "../common/tablecontainers/MyRequirements";
import MyRequirementLeads from "../common/tablecontainers/MyRequirementLeads";
import MyRequirementRequests from "../common/tablecontainers/MyRequirementRequests";
import MyRequirementApplications from "../common/tablecontainers/MyRequirementApplications";

const Requirements = () => {
  const [requirementTab, setRequirementTab] = useState("All Requirements");
  const [requirements, setRequirements] = useState([]);
  const [requirementsLoading, setRequirementsLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const getRequirements = async () => {
    try {
      setRequirementsLoading(true);
      const { data } = await Api.post("/listing/requirements/get-all", {
        owner: user?.profile,
      });
      setRequirements(data);
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get requirement",
        },
      });
    } finally {
      setRequirementsLoading(false);
    }
  };

  useEffect(() => {
    getRequirements();
  }, []);

  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "10px", p: 2 }}>
      <TabsWithSearch
        tabs={["All Requirements", "Leads", "Requests", "My Applications"]}
        onTabChange={(newTabVal) => setRequirementTab(newTabVal)}
        currentTabVariable={requirementTab}
        disableUnderline={true}
      />
      {requirementTab === "All Requirements" && <MyRequirements />}
      {requirementTab === "Leads" && <MyRequirementLeads />}
      {requirementTab === "Requests" && <MyRequirementRequests />}
      {requirementTab === "My Applications" && <MyRequirementApplications />}
    </Box>
  );
};

export default Requirements;
