import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import dln from "../../../Assets/DLN Builders-logos_transparent 1.svg";
import pyramid from "../../../Assets/Pyramid Builders and Developers-logos_transparent 1.svg";
import pre from "../../../Assets/Premier Realty-logos_black 1.svg";
import keyhomes from "../../../Assets/Key Homes-logos_transparent 1.svg";
import { Grid, makeStyles, useTheme } from "@material-ui/core";
import "./index.css";
const images = [dln, pyramid, pre, keyhomes];

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "150px",
    padding: "0px 50px 0px"
  },
  titleSty: {
    fontSize: "35px",
    textAlign: "center",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      fontWeight: "600"
    },
  },
  imageSty: {
    width: "300px",
    objectFit: "cover",
    display: "flex",
    justifyContent: "space-around",
    marginRight: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
      marginRight: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "140px",
      marginRight: "20px",
    },
  }
}));

const Dev = ({ pageRef }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [contWidth, setContWidth] = useState((4 * 300) + (4 * 40))


  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));


  useEffect(() => {
    let locWidth = (4 * 300) + (4 * 40);

    if (isExSmall) {
      locWidth = (4 * 140) + (4 * 20);
    } else if (isSmall) {
      locWidth = (4 * 200) + (4 * 30);
    } else {
      locWidth = (4 * 300) + (4 * 40);
    }

    setContWidth(locWidth)
  }, [isSmall, isExSmall])



  return (
    <section ref={pageRef} className={classes.root} >
      <h3 className={classes.titleSty} data-aos="fade-down" >Developers we work with</h3>


      <Grid container className="carousel" style={{ flexWrap: "nowrap", width: `${contWidth}px` }} >
        {images.map((src, index) => {
          return (
            <Grid item className="imageSty1" key={index} >
              <img src={src} alt="Image 1" className={classes.imageSty} />
            </Grid>
          );
        })}
      </Grid>
      <Grid container className="carousel1" style={{ flexWrap: "nowrap", width: `${contWidth}px` }} >
        {images.map((src, index) => {
          return (
            <Grid item key={index} >
              <img src={src} alt="Image 1" className={classes.imageSty} />
            </Grid>
          );
        })}
      </Grid>
    </section>
  );
};

export default Dev;