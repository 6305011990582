import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { FaCalendarAlt } from "react-icons/fa";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ImLocation2 } from "react-icons/im";
import AccessControlStoreHook from "../AccessControl/AccessControlStoreHook";
import { RiUserAddFill, RiUserFollowFill } from "react-icons/ri";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import {
  followUnfollowApiHit,
  getFollowerAndFollowingsApiHit,
  getFullOrgData,
  updateOrgWithPopulate,
} from "./api.call";
import SendMessage from "../profileChat/send.message";
import FollowingsFollowers from "./Followers.Followings";
import teamUtils from "../team/team.utils";
import TimelineIcon from "@material-ui/icons/Timeline";
import HomeIcon from "@material-ui/icons/Home";
import GroupIcon from "@material-ui/icons/Group";
import PostAddIcon from "@material-ui/icons/PostAdd";
import InfoIcon from "@material-ui/icons/Info";
import SettingsIcon from "@material-ui/icons/Settings";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import styled, { css } from "styled-components";
import TeamActivities from "../activity/team.activity.timeline";
import MemberSetting from "../team/ManageMembers/member.settings";
import OrgDashboard from "../team/Org.Dashboard";
import OrgPosts from "./Org.Posts";
import OrgAbout from "./Org.About";
import { AvatarGroup } from "@material-ui/lab";
import { RiContactsBookFill } from "react-icons/ri";
import OrgContacts from "./org.contacts";
import OrgSettings from "./org.settings";
import useWorkspace from "../useWorkspace";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import MenuOverlay from "../styled/generic/MenuOverlay";
import {
  Add,
  CenterFocusStrongOutlined,
  LocationOnOutlined,
  MoreVert,
  SettingsOutlined,
  Sort,
} from "@material-ui/icons";
import TabsWithCommonAction from "../styled/generic/TabsWithCommonAction";
import PopupMenuList from "../styled/generic/PopupMenuList";
import Image from "../styled/generic/Image";
import SpaceBetween from "../styled/generic/SpaceBetween";
import StatusChip from "../styled/generic/StatusChip";
import AddressText from "../styled/generic/AddressText";
import RoundedChip from "../styled/generic/RoundedChip";
import DateText from "../styled/generic/DateText";
import AvatarGroupButton from "../styled/generic/AvatarGroupButton";
import { CalendarMonthOutlined } from "@mui/icons-material";
import FormBox from "../styled/generic/FormBox";
import WorkspaceApp from "../styled/generic/WorkspaceApp";
import OrgListings from "./Org.Listings";
import AccessContacts from "../AccessControl/AccessContacts";
import AccessRoles from "../AccessControl/AccessRole";
import { getAppsForWorkspace } from "../styled/generic/Api.call";
import ProjectContacts from "../projects/project.contacts";
import OrgSettingsDrawer from "./OrgSettingsDrawer";
import ActivitiesList from "../activity/ActivitiesList";
import { Drawer } from "@mui/material";
import config from '../../config/index';
import { setAuthCookieData } from '../../helpers/cookie.helper';

const ActivitySidebar = styled.aside`
  ${({ open }) => css`
    position: absolute;
    top: 0;
    right: ${open ? "0px" : "-100%"};
    bottom: 0;
    display: flex;
    flex-direction: column;
    background: #fff;
    max-height: 100%;
    min-width: 300px;
    overflow: auto;
    box-shadow: -10px 0px 20px 0px #00000030;
    transition: all 0.5s;
    .sidebar-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0.5rem 1rem 1.5rem;
      font-size: 1.5rem;
      font-weight: bold;
    }
  `}
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderTop: "1px solid white",
    borderBottom: "0",
    borderRadius: "0px",
    marginBottom: "30px",
  },
  overlay: {
    position: "relative",
    backgroundColor: "#f2f2f2",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "15rem",
    width: "100%",
    borderRadius: "1px solid white",
    borderBottom: "0",
    [theme.breakpoints.down("xs")]: {
      height: "10rem",
    },
  },
  coverUploadButton: {
    position: "absolute",
    right: "10px",
    backgroundColor: "white",
    padding: "4px",
    border: "1px solid #2296f3",
    borderRadius: "100%",
    top: "10px",
  },
  imgParent: {
    position: "absolute",
    zIndex: 100,
    cursor: (props) => (props.isViewOnlyMode ? null : "pointer"),
    top: "130px",
    left: "10px",
    "&:hover": {
      "& $hoverUploadButton": {
        opacity: 1,
      },
    },
    [theme.breakpoints.down("xs")]: {
      top: "90px",
    },
  },
  imgAvatar: {
    height: "10rem",
    width: "10rem",
    objectFit: "cover",
    borderRadius: "100%",
    [theme.breakpoints.down("xs")]: {
      height: "7rem",
      width: "7rem",
    },
  },
  profileContent: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "1.5rem",
    "& $h2": {
      fontSize: "24px",
      fontWeight: "800",
      display: "inline",
      marginBottom: "2px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "15px",
      "& $h2": {
        fontSize: "20px",
        fontWeight: "600",
      },
    },
  },
  locationAndJoinCont: {
    display: "flex",
    alignItems: "center",
    marginTop: "2px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  content_left: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    "& $p": {
      fontSize: "14px",
      color: "grey",
    },
    "& h4": {
      fontSize: "1rem",
      color: "black",
      fontWeight: "400",
    },
    [theme.breakpoints.down("xs")]: {
      "& h4": {
        fontSize: "16px",
      },
      "& $p": {
        fontSize: "13px",
      },
    },
  },
  skillsAndMarketsCont: {
    marginTop: "25px",
  },
  tagsCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "7px",
    "& h2": {
      fontSize: "16px",
      fontWeight: "510",
      marginTop: "-4px",
      color: "#170f49",
      marginRight: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      "& h2": {
        fontSize: "14px",
        marginRight: "10px",
      },
    },
  },
  allTagsCont: {
    width: "calc(100% - 65px)",
    display: "flex",
    flexWrap: "wrap",
    "& div": {
      backgroundColor: "#DFE9FF",
      marginRight: "8px",
      marginBottom: "5px",
      padding: "2px 10px",
      fontWeight: "500",
      borderRadius: "4px",
    },
    [theme.breakpoints.down("xs")]: {
      "& div": {
        marginRight: "5px",
        marginBottom: "3px",
        padding: "1px 5px",
        borderRadius: "3px",
        fontSize: "12px",
      },
    },
  },
  editButtonSty: {
    padding: "5px 12px",
    borderRadius: "20px",
    backgroundColor: "white",
    fontSize: "15px",
    border: "1px solid #cccbcb",
    color: "black",
    cursor: "pointer",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#f7f4f4",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5px 10px",
      borderRadius: "15px",
      fontSize: "13px",
    },
  },
  headerActionBtns: {
    display: "flex",
    alignItems: "center",
    marginLeft: "1.5rem",
    marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "15px",
    },
  },
  editIcon: {
    marginLeft: "auto",
    height: "50px",
    paddingTop: "10px",
    paddingRight: "15px",
  },
  hoverUploadButton: {
    transition: ".5s ease",
    position: "absolute",
    opacity: 0,
    backgroundColor: "white",
    border: "1px solid white",
    borderRadius: "100%",
    top: "50%",
    left: "50%",
    padding: "4px",
    transform: "translate(-50%, -50%)",
  },
  followBox: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "flex-start",
    fontSize: "16px",
    color: "#3c3c3c",
  },
  followerCount: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  followBold: {
    fontWeight: "600",
    marginRight: "5px",
  },
  tabsPanel: {
    marginTop: "1rem",
    borderTop: "2px solid #fafafa",
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  bodySectionCont: {
    width: "100%",
    overflowX: "hidden",
  },
  paper: {
    backgroundColor: "rgba(0,0,0,0)",
  },
  avatarGroupSty: {
    marginLeft: "20px",
    marginTop: "20px",
  },
}));

const colorArr = [
  "#FFE7E7",
  "#FFEDD8",
  "#FFEFEF",
  "#F8F6E9",
  "#F8E9E9",
  "#E9F8F0",
  "#E9F8F0",
];

const CustomTab = withStyles({
  root: {
    textTransform: "none",
    fontSize: "1rem",
    minWidth: 50,
  },
})(Tab);

const OrgBothView = ({
  profile,
  isViewOnlyMode,
  setOpenEditView,
  setProfileEditView,
  teamId,
  userRole,
}) => {
  const componentRef = useRef();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles({ isViewOnlyMode });
  const dispatch = useDispatch();
  const { handleTeams } = teamUtils;
  const entireState = useSelector(state => state);

  const state = useSelector((state) => state);
  const { user } = useSelector((state) => state.auth);
  const { createdFileIds } = useSelector((state) => state.file);
  const { teamDictionary } = useSelector((state) => state.team);
  const { teamAccessMap, accessRoleMap } = useSelector(
    (state) => state.accessControl
  );
  const { checkAccess } = AccessControlStoreHook();

  const [defaultLibId, setDefaultLibId] = useState(
    profile?.parent?.defaultLibrary
  );

  const [followingCount, setFollowingCount] = useState(
    profile?.parent?.followingCount || 0
  );
  const [followerCount, setFollowerCount] = useState(
    profile?.parent?.followerCount || 0
  );
  const [followersArr, setFollowersArr] = useState([]);
  const [followingArr, setFollowingArr] = useState([]);
  const [followerIdsArr, setFollowerIdsArr] = useState([]);
  const [followingIdsArr, setFollowingIdsArr] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followingLoading, setFollowingLoading] = useState(false);
  const [openFollowing, setOpenFollowing] = useState(false);
  const [openFollowers, setOpenFollowers] = useState(false);

  const [activitySidebar, setActivitySidebar] = useState(false);
  const [value, setValue] = useState(0);

  const [docs, setDocs] = useState([]);
  const [issues, setIssues] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [chartAccounts, setChartAccounts] = useState([]);
  const [reports, setReports] = useState([]);
  const [units, setUnits] = useState([]);
  const [loader, setLoader] = useState(false);
  const [team, setTeam] = useState({});
  const [apps, setApps] = useState([]);
  const [tab, setTab] = useState("");
  const [actualImages, setActualImages] = useState([]);
  const [options, setOptions] = useState([]);
  const [openOrgSettingsDrawer, setOpenOrgSettingsDrawer] = useState(false);
  useEffect(() => {
    const teamData = teamDictionary[teamId];
    setTeam(teamData);
  }, [teamDictionary, teamId]);

  useEffect(() => {
    getFullOrgData({
      profileId: profile?._id,
      walletId: profile?.parent?.wallet,
      teamId: teamId,
      isAdmin: true,
    })
      .then((data) => {
        setDocs(data?.docs || []);
        setIssues(data?.issues || []);
        setUnits(data?.units || []);
        setReports(data?.analysisReports || []);
        setChartAccounts(data?.chartAccounts || []);
        setTransactions(data?.transactions || []);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //profile picture and cover update
  const setUploadedProfilePicture = async () => {
    await updateOrgWithPopulate({
      _id: profile?.parent?._id,
      displayPicture: createdFileIds[0],
    })
      .then((data) => {
        let teamDataFromDic = teamDictionary[teamId];
        let teamParent = teamDataFromDic?.parent;
        const team = {
          ...teamDataFromDic,
          parent: {
            ...teamParent,
            displayPicture: data?.displayPicture,
          },
        };
        handleTeams([team], state, dispatch);
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch({ type: "FileUploadReset" });
  };

  const setUploadedCoverPicture = async () => {
    await updateOrgWithPopulate({
      _id: profile?.parent?._id,
      cover: createdFileIds[0],
    })
      .then((data) => {
        let teamDataFromDic = teamDictionary[teamId];
        let teamParent = teamDataFromDic?.parent;
        const team = {
          ...teamDataFromDic,
          parent: {
            ...teamParent,
            cover: data?.cover,
          },
        };
        handleTeams([team], state, dispatch);
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch({ type: "FileUploadReset" });
  };

  // get followers and followings and to check isFollowing
  const getFollowerAndFollowings = async () => {
    await getFollowerAndFollowingsApiHit({ profileId: profile?._id })
      .then((data) => {
        let followersLocArr = [];
        let followingsLocArr = [];
        let followerIdsLocArr = [];
        let followingIdsLocArr = [];
        let isFollowingLoc = false;
        if (data && data.length > 0) {
          data.map((follow) => {
            if (profile?._id === follow?.profile?._id) {
              followersLocArr.push(follow);
              followerIdsLocArr.push(follow?.userProfile?._id);
              if (follow?.userProfile?._id === user?.profile) {
                isFollowingLoc = true;
              }
            } else if (profile?._id === follow?.userProfile?._id) {
              followingsLocArr.push(follow);
              followingIdsLocArr.push(follow?.profile?._id);
            }
          });

          setFollowerIdsArr(followerIdsLocArr);
          setFollowingIdsArr(followingIdsLocArr);
          setIsFollowing(isFollowingLoc);
          setFollowerCount(followersLocArr.length);
          setFollowingCount(followingsLocArr.length);
          setFollowersArr(followersLocArr);
          setFollowingArr(followingsLocArr);

          if (isViewOnlyMode === false) {
            let teamDataFromDic = teamDictionary[teamId];
            let teamParent = teamDataFromDic?.parent;
            const team = {
              ...teamDataFromDic,
              parent: {
                ...teamParent,
                followerCount: followersLocArr.length,
                followingCount: followingsLocArr.length,
              },
            };
            handleTeams([team], state, dispatch);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getFollowerAndFollowings();
  }, [profile?._id]);

  // follow or unfollow
  const handleFollow = async () => {
    setFollowingLoading(true);
    await followUnfollowApiHit({
      mainProfile: user?.profile, // who is going to follow (User)
      orgProfile: profile?._id, // whome will be followed by user (Org)
      mainUser: user?._id, // who is going to follow (User)
      orgId: profile?.parent?._id, // whome will be followed by user (Org)
    })
      .then(async (data) => {
        await getFollowerAndFollowings();

        if (data) {
          if (data?.followerCount) {
            setFollowerCount(data?.followerCount || 0);
          }
          if (data?.followingCount) {
            setFollowingCount(data?.followingCount || 0);
          }

          let teamDataFromDic = teamDictionary[teamId];
          let teamParent = teamDataFromDic?.parent;
          const team = {
            ...teamDataFromDic,
            parent: {
              ...teamParent,
              followerCount: data?.followerCount || 0,
              followingCount: data?.followingCount || 0,
            },
          };
          handleTeams([team], state, dispatch);
        }
        setFollowingLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAppClick = (code) => {
    let orgData = profile?.parent;
    let queryId = profile?.parent?._id;
    let profileId = orgData?.profile?._id;
    let walletId = profile?.parent?.wallet;
    let teamId = profile?.parent?.team;

    if (config.mode === 'prod') {
      const token = localStorage.getItem("token");
      if (entireState && typeof entireState === 'object') {
        setAuthCookieData({
          user, token, entireState
        });
      } else {
        console.warn("Skipped state persistence due to invalid state");
      }
    }

    let url;

    switch (code) {
      case "ticketing":
      case "teams":
        url = `/issues/profile/${profileId}`;
        break;
      case "booking":
        url = `/booking/${profileId}`;
        break;
      case "planning":
        url = `/planning/${profileId}`;
        break;
      case "bugs":
        url = `/bugs/${profileId}`;
        break;
      case "accounts":
        url = `/finance/${walletId}/bank-accounts`;
        break;
      case "documents":
        url = `/docs/${profileId}`;
        break;
      case "timesheet":
        url = `/organization/workschedule/${teamId}`;
        break;
      case "procurement":
        url = `/procurements/management/${profileId}`;
        break;
      case "inventory":
        url = `/inventory?organizationId=${queryId}`;
        break;
      case "sitereport":
        url = `/site-report/${profileId}`;
        break;
      case "hrm":
        url = `/hr/${queryId}`;
        break;
      case "approval":
        url = `/approval/${profileId}`;
        break;
      case "assets":
        url = `/assets/home/${profileId}`;
        break;
      case "productservices":
        url = `/offerings-categories/management/${profileId}`;
        break;
      case "meeting":
        url = `/meeting/${profileId}`;
        break;
      default:
        alert("No action found for this type of app");
        return;
    }

    navigateTo(code, url);
  };

  const navigateTo = (code, path) => {
    if (config.mode === 'prod') {
      const subdomain = getSubdomain(code);
      if (subdomain === "app") {
        history.push(path);
      } else {
        const url = `https://${subdomain}.reallist.ai${path}`;
        window.open(url, "_self");
      }
    } else {
      history.push(path);
    }
  };

  const getSubdomain = (code) => {
    switch (code) {
      case "ticketing":
      case "teams": return "teams";
      case "booking": return "bookings";
      case "planning": return "planning";
      case "bugs": return "bugs";
      case "accounts": return "accounts";
      case "documents": return "docs";
      case "timesheet": return "timesheets";
      case "procurement": return "operations";
      case "inventory": return "inventory";
      case "sitereport": return "sitereport";
      case "hrm": return "hrms";
      case "approval": return "approval";
      case "assets": return "assets";
      case "productservices": return "app";
      case "meeting": return "app";
      default: return "app";
    }
  };

  useEffect(() => {
    getAppsForWorkspace({ parentmodel: "Organization" })
      .then((data) => {
        let filteredApps = [];
        data.map((item) => {
          if (checkAccess(profile?._id, item?.code)) {
            filteredApps.push(item);
          }
        });
        setApps(filteredApps);
        let arr = [];
        if (filteredApps && filteredApps.length > 0 || apps.length > 0) {
          arr.push("Database");
        }

        arr.push("Activities");

        if (checkAccess(profile?._id, "basicSettings-About")) {
          arr.push("About");
        }
        if (checkAccess(profile?._id, "basicSettings-Posts")) {
          arr.push("Posts");
        }
        if (checkAccess(profile?._id, "basicSettings-Listing")) {
          arr.push("Listings");
        }
        if (checkAccess(profile?._id, "basicSettings-Bookings")) {
          arr.push("Bookings");
        }
        if (checkAccess(profile?._id, "basicSettings-Contacts")) {
          arr.push("Contacts");
        }
        if (checkAccess(profile?._id, "basicSettings-Access")) {
          arr.push("Access");
        }

        if (arr.includes("Access")) {
          setTab("Access");
        }

        if (arr.includes("Activities")) {
          setTab("Activities");
        }

        if (arr.includes("Contacts")) {
          setTab("Contacts");
        }
        if (arr.includes("Bookings")) {
          setTab("Bookings");
        }
        if (arr.includes("Listings")) {
          setTab("Listings");
        }
        if (arr.includes("Posts")) {
          setTab("Posts");
        }
        if (arr.includes("About")) {
          setTab("About");
        }
        if (arr.includes("Database")) {
          setTab("Database");
        }

        setOptions(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [profile?._id, teamAccessMap, accessRoleMap]);

  return (
    <>
      <StandardAppContainerRounded>
        <OrgSettingsDrawer
          open={openOrgSettingsDrawer}
          setOpen={setOpenOrgSettingsDrawer}
          organization={profile?.parent}
        />
        <MenuOverlay
          menuItems={[
            {
              component: (
                <CustomFileUploadButton
                  showComponent={
                    <IconButton
                      size="small"
                      sx={{
                        backgroundColor: "#ffffff",
                        "&:hover": {
                          backgroundColor: "#f5f5f5",
                        },
                      }}
                    >
                      <CenterFocusStrongOutlined />
                    </IconButton>
                  }
                  parentType={"User"}
                  parentId={user?._id}
                  fileNum={1}
                  givenFileTypes={["image"]}
                  givenMaxSize={26214400}
                  closeFunCall={setUploadedCoverPicture}
                />
              ),
            },
          ]}
        >
          <Box sx={{ position: "relative" }}>
            <Image
              width="100%"
              height="240px"
              src={profile?.parent?.cover?.url}
              imageProps={{ borderRadius: "6px 6px 0px 0px" }}
            />
            <Box sx={{ position: "absolute", bottom: "-30px", left: "10px" }}>
              <Image
                width="180px"
                height="180px"
                src={profile?.parent?.displayPicture?.url}
                imageProps={{
                  borderRadius: "100px",
                  border: "5px solid #ffffff",
                }}
              />
            </Box>
          </Box>
        </MenuOverlay>

        <Box sx={{ paddingX: "20px", mt: 5 }}>
          <SpaceBetween
            rightStyleProps={{
              alignSelf: "flex-start",
              justifyContent: "flex-end",
            }}
            left={
              <Box>
                <Typography sx={{ mr: 2, fontWeight: 600 }} variant="h5">
                  {profile?.parent?.displayName}
                </Typography>
                <Typography variant="subtitle1">
                  {profile?.parent?.description || "No descrition"}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ mb: 2, color: "#536471" }}
                >
                  <Box display="flex" alignItems="center" sx={{ mr: 2 }}>
                    <LocationOnOutlined
                      fontSize="small"
                      style={{ marginRight: "5px" }}
                    />
                    <AddressText location={profile?.parent?.address} />
                  </Box>
                  <Box display="flex" alignItems="center">
                    <CalendarMonthOutlined
                      fontSize="small"
                      style={{ marginRight: "5px" }}
                    />
                    <DateText
                      prefix="Joined: "
                      date={profile?.parent?.createdAt}
                    />
                  </Box>
                </Box>
                <Box>
                  <span style={{ marginRight: "10px", fontSize: "1rem" }}>
                    <span style={{ fontWeight: "bold" }}>123</span> Followers
                  </span>
                  <span style={{ fontSize: "1rem" }}>
                    <span style={{ fontWeight: "bold" }}>123</span> Followings
                  </span>
                </Box>
              </Box>
            }
            right={
              <Box display="flex" justifyContent="flex-end" fullWidth>
                <CustomFileUploadButton
                  showComponent={
                    <PhotoCameraIcon
                      style={{ fontSize: "35px", marginBottom: "-4px" }}
                    />
                  }
                  parentType={"User"}
                  parentId={user?._id}
                  fileNum={1}
                  givenFileTypes={["image"]}
                  givenMaxSize={26214400}
                  closeFunCall={setUploadedProfilePicture}
                />
              </Box>
            }
          />
        </Box>
        <Divider style={{ marginTop: "10px" }} />
        {options && options.length > 0 && (
          <TabsWithCommonAction
            disableUnderline={true}
            tabs={options}
            currentTabVariable={tab}
            onTabChange={(val) => setTab(val)}
            variant="h6"
            action={
              <Button
                onClick={() => setOpenOrgSettingsDrawer(true)}
                variant="outlined"
                startIcon={<SettingsOutlined />}
                color="primary"
                sx={{ textTransform: "none" }}
              >
                Settings
              </Button>
            }
          />
        )}
        {/* <OrgSettings
          profile={profile}
          teamId={teamId}
          orgId={profile?.parent?._id}
          setShowWorkspaceConfigDialog={""}
          defaultLibId={defaultLibId}
        /> */}
      </StandardAppContainerRounded>
      {tab === "Database" && (
        <StandardAppContainerRounded>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <StandardAppContainerRounded>
                <SpaceBetween
                  rightStyleProps={{ alignSelf: "flex-start" }}
                  left={
                    <FormBox label="Apps">
                      {/* <Typography variant="subtitle1">
                        Configure your apps here
                      </Typography> */}
                    </FormBox>
                  }
                  right={
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      {/* <Button
                        startIcon={<Add />}
                        color="primary"
                        variant="contained"
                        disableElevation
                        onClick={() => setShowWorkspaceConfigDialog(true)}
                      >
                        Apps
                      </Button> */}
                    </Box>
                  }
                />
                <Box>
                  <Grid container spacing={2}>
                    {apps?.map((item) => (
                      <Grid item xs={3}>
                        <WorkspaceApp
                          isSelectable={false}
                          onClick={() => handleAppClick(item.code)}
                          appData={item}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </StandardAppContainerRounded>
            </Grid>
            <Grid item xs={3}>
              <StandardAppContainerRounded>
                <SpaceBetween
                  left={<Typography variant="h6">Activities</Typography>}
                  right={
                    <Box display="flex" justifyContent="flex-end">
                      <IconButton onClick={() => setActivitySidebar(true)}>
                        <Sort />
                      </IconButton>
                    </Box>
                  }
                />
              </StandardAppContainerRounded>

              <Drawer
                anchor="right"
                open={activitySidebar}
                onClose={() => setActivitySidebar(false)}
              >
                <Box
                  sx={{ width: 800 }}
                  role="presentation"
                >
                  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
                    <Typography variant="h6">Activities</Typography>
                    <IconButton onClick={() => setActivitySidebar(false)}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Divider />
                  <ActivitiesList
                    modules={[]}
                    profileId={profile?._id}
                    entityType="Organization"
                  />
                </Box>
              </Drawer>
            </Grid>
          </Grid>
        </StandardAppContainerRounded>
      )}
      {tab === "About" && (
        <StandardAppContainerRounded>
          <FormBox label="About">
            <Typography variant="body1">
              {profile?.parent?.description || "No description"}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Website:{" "}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Industry Type:{" "}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Company Size:{" "}
              </Typography>
            </Box>
          </FormBox>
        </StandardAppContainerRounded>
      )}
      {tab === "Activities" && <ActivitiesList modules={[]} profileId={profile?._id} entityType="Organization" />}
      {tab === "Posts" && <div>Posts</div>}
      {tab === "Listings" && (
        <OrgListings orgId={profile?.parent?._id} team={team} />
      )}
      {tab === "Bookings" && <div>Bookings</div>}
      {tab === "Contacts" && (
        <ProjectContacts
          team={team}
          profileId={profile?._id}
          userRole={"Owner"}
          wallet={profile?.parent?.wallet}
          parent={profile?.parent?._id}
          parentModelName={profile?.parentModelName}
        />
      )}
      {tab === "Access" && <AccessRoles team={team} />}
    </>
  );
};
export default OrgBothView;
//               key={i}
//               alt={userData?.user?.parent?.displayName}
//               src={
//                 userData?.user?.parent?.displayPicture?.thumbUrl ||
//                 userData?.user?.parent?.displayPicture?.url
//               }
//               style={{ width: "35px", height: "35px" }}
//             />
//           ))}
//       </AvatarGroup>

//       {workspaceConfigDialog}
//       <div className={classes.tabsPanel}>
//         <Tabs
//           value={value}
//           onChange={handleChange}
//           TabIndicatorProps={{ style: { backgroundColor: "#17804f" } }}
//           variant="scrollable"
//           scrollButtons="auto"
//           aria-label="scrollable auto tabs example"
//         >
//           {!isViewOnlyMode && (
//             <CustomTab
//               label={
//                 <div>
//                   <HomeIcon style={{ verticalAlign: "middle" }} /> Workspace
//                 </div>
//               }
//               onClick={() => {
//                 setView("Workspace");
//                 setActivitySidebar(false);
//               }}
//             />
//           )}
//           <CustomTab
//             label={
//               <div>
//                 <InfoIcon style={{ verticalAlign: "middle" }} /> About
//               </div>
//             }
//             onClick={() => {
//               setView("About");
//               setActivitySidebar(false);
//             }}
//           />
//           <CustomTab
//             label={
//               <div>
//                 <PostAddIcon style={{ verticalAlign: "middle" }} /> Posts
//               </div>
//             }
//             onClick={() => {
//               setView("Posts");
//               setActivitySidebar(false);
//             }}
//           />
//           {!isViewOnlyMode && (
//             <CustomTab
//               label={
//                 <div>
//                   <GroupIcon style={{ verticalAlign: "middle" }} /> Participants
//                 </div>
//               }
//               onClick={() => {
//                 setView("People");
//                 setActivitySidebar(false);
//               }}
//             />
//           )}
//           {!isViewOnlyMode && (
//             <CustomTab
//               label={
//                 <div>
//                   <RiContactsBookFill
//                     style={{
//                       verticalAlign: "middle",
//                       fontSize: "23px",
//                       marginRight: "3px",
//                     }}
//                   />{" "}
//                   Contacts
//                 </div>
//               }
//               onClick={() => {
//                 setView("Contacts");
//                 setActivitySidebar(false);
//               }}
//             />
//           )}
//           {!isViewOnlyMode && (
//             <CustomTab
//               label={
//                 <div>
//                   <TimelineIcon style={{ verticalAlign: "middle" }} />{" "}
//                   Activities
//                 </div>
//               }
//               onClick={() => {
//                 setActivitySidebar(true);
//               }}
//             />
//           )}

//           <CustomTab
//             label={
//               <div>
//                 <SettingsIcon style={{ verticalAlign: "middle" }} /> Settings
//               </div>
//             }
//             onClick={() => {
//               setView("Settings");
//               setActivitySidebar(false);
//             }}
//           />
//         </Tabs>
//       </div>
//     </Paper>

//     <div className={classes.bodySectionCont}>
//       <Slide
//         direction="left"
//         in={view === "Workspace" ? true : false}
//         mountOnEnter
//         unmountOnExit
//       >
//         <Paper elevation={0} className={classes.paper}>
//           <OrgDashboard
//             reports={reports}
//             loader={loader}
//             docs={docs}
//             units={units}
//             setUnits={setUnits}
//             chartAccounts={chartAccounts}
//             teamId={teamId}
//             transactions={transactions}
//             issues={issues}
//             walletId={profile?.parent?.wallet}
//             profile={profile}
//             setShowWorkspaceConfigDialog={setShowWorkspaceConfigDialog}
//             config={config}
//           />
//         </Paper>
//       </Slide>

//       <Slide
//         direction="left"
//         in={view === "About" ? true : false}
//         mountOnEnter
//         unmountOnExit
//       >
//         <Paper elevation={0} className={classes.paper}>
//           <OrgAbout
//             profile={profile}
//             teamId={teamId}
//             setOpenEditView={setOpenEditView}
//             setProfileEditView={setProfileEditView}
//           />
//         </Paper>
//       </Slide>

//       <Slide
//         direction="left"
//         in={view === "Posts" ? true : false}
//         mountOnEnter
//         unmountOnExit
//       >
//         <Paper elevation={0} className={classes.paper}>
//           <OrgPosts />
//         </Paper>
//       </Slide>

//       <Slide
//         direction="left"
//         in={view === "People" ? true : false}
//         mountOnEnter
//         unmountOnExit
//       >
//         <Paper elevation={0} className={classes.paper}>
//           <MemberSetting teamId={teamId} userRole={userRole} />
//         </Paper>
//       </Slide>

//       <Slide
//         direction="left"
//         in={view === "Contacts" ? true : false}
//         mountOnEnter
//         unmountOnExit
//       >
//         <Paper elevation={0} className={classes.paper}>
//           <OrgContacts
//             profileId={profile?._id}
//             userRole={userRole}
//             wallet={profile?.parent?.wallet}
//             parent={profile?.parent?._id}
//             parentModelName={profile?.parentModelName}
//           />
//         </Paper>
//       </Slide>

//       <Slide
//         direction="left"
//         in={view === "Settings" ? true : false}
//         mountOnEnter
//         unmountOnExit
//       >
//         <Paper elevation={0} className={classes.paper}>
//           <OrgSettings
//             profile={profile}
//             teamId={teamId}
//             orgId={profile?.parent?._id}
//             setShowWorkspaceConfigDialog={setShowWorkspaceConfigDialog}
//             defaultLibId={defaultLibId}
//           />
//         </Paper>
//       </Slide>
//     </div>

//     <ActivitySidebar style={{ zIndex: "99999" }} open={activitySidebar}>
//       <div className="sidebar-title">
//         <div>Activities</div>
//         <IconButton>
//           <CloseIcon onClick={() => setActivitySidebar(false)} />
//         </IconButton>
//       </div>
//       <TeamActivities
//         parent={profile?.parent?._id}
//         parentModelName={"Organization"}
//       />
//     </ActivitySidebar>
//   </div>
// );
