import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import MyPopOver from "../styled/CommonComponents/MyPopOver"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LessText from "../styled/CommonComponents/LessText";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    "& h3": {
      fontSize: "16px",
      fontWeight: "500"
    }
  },
  selectedSty: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px 5px",
    cursor: "pointer",
    borderRadius: "15px",
    border: "1px solid #ececec",
    "& .MuiAvatar-root": {
      width: "32px",
      height: "32px",
    },
    "& p": {
      fontSize: "15px",
      fontWeight: "500",
      marginLeft: "5px",
      marginRight: "15px"
    }
  },
  namePicCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  optionsCont: {
    width: "300px",
    height: "400px",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "5px 0px"
  },
  singleOption: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "5px 10px",
    cursor: "pointer",
    "& .MuiAvatar-root": {
      width: "32px",
      height: "32px",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "500",
      paddingLeft: "5px"
    },
    "&:hover": {
      backgroundColor: "#ececec"
    },
  }
}));

const ProfileSelect = ({
  adminProfiles, onChange, title, displayOwner,
  owner, setOwner, hideTitle
}) => {
  const classes = useStyles();

  useEffect(() => {
    setOwner(adminProfiles[0])
  }, [adminProfiles])

  return (
    <div className={classes.mainCont} >
      {title && !hideTitle ? (
        <h3>{title}</h3>
      ) : null}

      <MyPopOver
        closeOnClick={true}
        appearContent={<div className={classes.selectedSty} >
          <div className={classes.namePicCont} >
            <Avatar src={owner?.displayPicture?.url || owner?.displayName} />
            <p title={owner?.displayName} >
              <LessText
                limit={25}
                string={owner?.displayName}
              />
            </p>
          </div>
          <ExpandMoreIcon />
        </div>}
        showContent={<div className={classes.optionsCont} >
          {adminProfiles && adminProfiles.map((profile) => (
            <div
              onClick={() => {
                onChange(profile)
              }}
              className={classes.singleOption}
              style={profile?._id == owner?._id ? { backgroundColor: "#ececec" } : {}}
            >
              <Avatar src={profile?.displayPicture?.url || profile.displayName} />
              {displayOwner && profile.displayName ? (
                <p title={profile?.displayName} >
                  <LessText
                    limit={30}
                    string={profile?.displayName}
                  />
                </p>
              ) : null}
            </div>
          ))}
        </div>}
      />
    </div>
  );
};

export default ProfileSelect;