import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "timeago.js";
import ApartmentIcon from "@material-ui/icons/Apartment";
import LessText from "../styled/CommonComponents/LessText";
import { Avatar, Chip } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { TbCrane } from "react-icons/tb";
import Api from "../../helpers/Api";
import teamUtils from "../team/team.utils";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import SpaceBetween from "../styled/generic/SpaceBetween";
import { Typography } from "@mui/material";
import emptyIcon from "../../Assets/EmptyInboxOutline.svg";
import AccessControlStoreHook from "../AccessControl/AccessControlStoreHook";
import { inviteAccept } from "../team/api.call";

const useStyles = makeStyles((theme) => ({
  acceptBtn: {
    backgroundColor: "#407BFF",
    padding: "4px 7px",
    color: "white",
    fontSize: "13px",
    cursor: "pointer",
    borderRadius: "4px",
    fontWeight: "510",
    border: "none",
    marginRight: "10px"
  },
  rejectBtn: {
    padding: "4px 7px",
    fontSize: "13px",
    cursor: "pointer",
    borderRadius: "4px",
    fontWeight: "510",
    backgroundColor: "#e04141",
    border: "1px solid gray",
    color: "white",
  },
  emptyCont: {
    width: "100%",
    height: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      width: "250px",
      height: "auto",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "gray",
    },
  },
  cardSingleCont: {
    width: "100%",
    padding: "15px 0px",
    borderBottom: "1px solid #ececec"
  },
  imageNameCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root": {
      width: "30px",
      height: "30px",
      marginRight: "5px"
    },
    "& h3": {
      fontSize: "14px",
      fontWeight: "500"
    },
    "& p": {
      fontSize: "10px",
      fontWeight: "400",
      color: "gray",
    }
  },
  chipBtnCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
    justifyContent: "space-between",
    "& .MuiChip-root": {
      fontSize: "12px",
      height: "20px"
    }
  },
  allInvitationsCont:{
    width: "100%",
    height: "200px",
    overflowX: "hidden",
    overflowY: "auto",
  }
}));

const DashboardInvitations = (props) => {
  const { addCreatedOne } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleTeams } = teamUtils;

  let { invitations } = useSelector((state) => state.team);
  const state = useSelector((state) => state);

  const { updateAccessByTeam } = AccessControlStoreHook();

  const [invites, setInvites] = useState([]);

  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setInvites(invitations || []);
  }, [invitations]);

  const handleReject = async (invite) => {
    setLoader(true)
    const reject = await Api.post("invite/reject", { invite: invite._id });
    let filteredInvites = invites.filter((el) => el._id !== invite._id);
    dispatch({
      type: "AddTeam",
      payload: {
        invitations: filteredInvites,
      },
    });
    setInvites(filteredInvites);
    setLoader(false)
  };

  const handleAccept = async (invite) => {
    setLoader(true)
    await inviteAccept({ invite: invite._id, acceptBy: "_id", })
      .then((teamData) => {
        let filteredInvites = invites.filter((el) => el._id !== invite._id);
        dispatch({
          type: "AddTeam",
          payload: {
            invitations: filteredInvites,
          },
        });
        setInvites(filteredInvites);

        if (teamData?.parentModelName === "Project" || teamData?.parentModelName === "Organization") {
          handleTeams([teamData], state, dispatch);
          if (addCreatedOne && teamData?.parentModelName === "Organization") {
            addCreatedOne(teamData);
          }
          updateAccessByTeam(teamData?._id)
        }
        setLoader(false)
      })
      .catch((err) => {
        console.log(err)
      })
  };

  return (
    <StandardAppContainerRounded>
      <SpaceBetween left={<Typography variant="h6">Invitations</Typography>} />
      <div style={{ width: "100%", height: "3px" }} >
        {loader && (<LinearProgress />)}
      </div>
      <div className={classes.allInvitationsCont} >
        {invitations?.length > 0 ? (<>
          {invites.map((invite, i) => (
            <div className={classes.cardSingleCont} style={invites.length - 1 === i ? { border: "none" } : {}} >
              <div className={classes.imageNameCont} >
                {invite?.teamType === "Project" ? (
                  <Avatar style={{ backgroundColor: "#F39014" }} >
                    <TbCrane style={{ fontSize: "20px" }} />
                  </Avatar>
                ) : (
                  <Avatar style={{ backgroundColor: "#0A66C2" }} >
                    <ApartmentIcon style={{ fontSize: "20px" }} />
                  </Avatar>
                )}
                <div style={{ width: "100%" }} >
                  <h3>
                    <LessText
                      limit={30}
                      string={invite?.team?.parent?.displayName}
                    />
                  </h3>
                  <p>
                    By
                    <LessText
                      limit={35}
                      string={invite?.invitedById?.parent?.displayName}
                    />
                  </p>
                </div>
              </div>
              <div className={classes.chipBtnCont} >
                <Chip size="small" label={invite?.role} />

                <div style={{ display: "flex" }} >
                  <button
                    onClick={() => { handleAccept(invite) }}
                    className={classes.acceptBtn}
                    disabled={loader}
                  >
                    <span>Accept</span>
                  </button>
                  <button
                    onClick={() => { handleReject(invite) }}
                    className={classes.rejectBtn}
                    disabled={loader}
                  >
                    <span>Reject</span>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </>) : (
          <div className={classes.emptyCont}>
            <img src={emptyIcon} />
            <p>No invitations</p>
          </div>
        )}
      </div>
    </StandardAppContainerRounded>
  );
};

export default DashboardInvitations;