import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import DocumentCard from "../dashboard/cards/DocumentCard";
import PaymentsCard from "../dashboard/cards/PaymentsCard";
import ProjectManagementCard from "../dashboard/cards/ProjectManagementCard";
import ProjectAnalysisCard from "../dashboard/cards/Project.Analysis.Card";
import PropertyManagementUnitCard from "../propertyManagement/PropertyManagementUnitCard";
import { Button } from "@material-ui/core";
import WorkScheduleCard from "../dashboard/cards/WorkScheduleCard";
import ProcurementCard from "../dashboard/cards/ProcurementCard";
import InventoryCard from "../dashboard/cards/InventoryCard";
import OfferingsAndProductsCard from "../dashboard/cards/OfferingsAndProducts.Card";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  mainCont: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  singleCont: {
    width: "49%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const OrgDashboard = ({
  reports,
  loader,
  docs,
  units,
  setUnits,
  chartAccounts,
  teamId,
  transactions,
  issues,
  walletId,
  profile,
  setShowWorkspaceConfigDialog,
  config,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <div className={classes.header}>
        <Button
          onClick={() => setShowWorkspaceConfigDialog(true)}
          variant="contained"
          color="primary"
        >
          Add Apps +
        </Button>
      </div>
      <div className={classes.mainCont}>
        {config &&
          config.apps &&
          config.apps.some((item) => item.code === "projectmanagement") && (
            <div className={classes.singleCont}>
              <ProjectManagementCard
                issues={issues}
                profileId={profile?._id}
                loader={loader}
              />
            </div>
          )}

        {config &&
          config.apps &&
          config.apps.some((item) => item.code === "accountspayments") && (
            <div className={classes.singleCont}>
              <PaymentsCard
                chartAccounts={chartAccounts}
                transactions={transactions}
                walletId={walletId}
                loader={loader}
              />
            </div>
          )}

        {config &&
          config.apps &&
          config.apps.some((item) => item.code === "documents") && (
            <div className={classes.singleCont}>
              <DocumentCard
                docs={docs}
                profileId={profile?._id}
                loader={loader}
              />
            </div>
          )}

        {config &&
          config.apps &&
          config.apps.some((item) => item.code === "boq") && (
            <div className={classes.singleCont}>
              <ProcurementCard
                queryId={profile?.parent?._id}
                queryType="organization"
              />
            </div>
          )}

        {config &&
          config.apps &&
          config.apps.some((item) => item.code === "inventory") && (
            <div className={classes.singleCont}>
              <InventoryCard
                queryId={profile?.parent?._id}
                queryType="organization"
              />
            </div>
          )}

        <div className={classes.singleCont}>
          <WorkScheduleCard />
        </div>

        <div className={classes.singleCont}>
          <OfferingsAndProductsCard
            profileId={profile?._id}
          />
        </div>
      </div>
    </>
  );
};
export default OrgDashboard;
