import { Box, Button, Checkbox } from "@mui/material";
import React from "react";
import TableContainer from "../../../styled/generic/TableContainer";
import ListingTableActionCell from "../../../styled/generic/ListingTableActionCell";
import ListingTableDateAgoCell from "../../../styled/generic/ListingTableDateAgoCell";
import ListingTableSingleUserCell from "../../../styled/generic/ListingTableSingleUserCell";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const RequirementsForListingTableComponent = ({
  matches,
  setMatches,
  actions,
  handleActionClick,
  loading,
  requestRequirement,
  applyRequirement,
}) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { listingId } = useParams();

  const columns = [
    "TITLE",
    "REQUIREMENT TYPE",
    "OWNER",
    "CREATED DATE",
    "MATCHING %",
    "ACTIONS",
  ];

  return (
    <Box sx={{ backgroundColor: "#FFFFFF", minHeight: "400px" }}>
      <TableContainer
        columns={columns}
        data={matches}
        loading={loading}
      >
        {matches?.map((item) => (
          <tr>
            <td>{item?.title || "Untitled"}</td>
            <td>{item?.requirementType}</td>
            <td>
              <ListingTableSingleUserCell
                label={item?.owner?.parent?.displayName || "xxxxxxxxx"}
                url={item?.owner?.parent?.displayPicture?.url}
              />
            </td>
            <td>
              <ListingTableDateAgoCell date={item?.createdAt} />
            </td>
            <td>70%</td>
            <td>
              <Box display="flex" alignItems="center">
                {item?.isPrivate ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    sx={{ mr: 3, width: 100, textTransform: "none" }}
                    onClick={() => requestRequirement(item?._id)}
                    disabled={item?.applied}
                  >
                    {item?.applied ? "Requested" : "Request"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    sx={{ mr: 3, width: 100, textTransform: "none" }}
                    onClick={() => applyRequirement(item?._id)}
                    disabled={item?.applied}
                  >
                    Contact
                  </Button>
                )}

                <ListingTableActionCell
                  cellData={item}
                  cellActions={actions}
                  cellActionListener={handleActionClick}
                />
              </Box>
            </td>
          </tr>
        ))}
      </TableContainer>
    </Box>
  );
};

export default RequirementsForListingTableComponent;
