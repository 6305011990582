import { styled } from "@mui/material";
import MuiChip from "@mui/material/Chip";

const RoundedChip = styled(MuiChip)({
  "&.MuiChip-root": {
    padding: "20px",
    fontSize: "1rem",
    borderRadius: "25px",
  },
});

export default RoundedChip;
