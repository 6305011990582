import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
import LinearProgress from "@material-ui/core/LinearProgress";
import teamUtils from "../../team/team.utils";
// import { createOrg } from "./organization.utils";
import LoadingButton from "../../styled/actionBtns/loading.btn";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Avatar, Chip, IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { green } from "@material-ui/core/colors";
import { Paper } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import UploadZone from "../../file/Uploader/UploadZone";
import FileUploadingView from "../../file/Viewer/FileUploadingView";
import FilesViewer from "../../file/Viewer/FilesViewer";
import FileUploadButton from "../../file/Uploader/FileUploadButton";
import { updateDeleteFlagForSingleFiles } from "../../propertyManagement/apiCall";
import UserWithRoleComponent from "../../styled/CommonComponents/UserWithRoleComponent";
import { Autocomplete } from "@material-ui/lab";
import Api from "../../../helpers/Api";

const GreenCheckbox = withStyles({
	root: {
		color: green[400],
		"&$checked": {
			color: green[600],
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const useStyles = makeStyles((theme) => ({
	dialog__content: {
		// margin: "1rem 0",
		"& $p": {
			// borderBottom: "1px solid grey",
			fontSize: "1rem",
			// padding: "1rem",
			fontWeight: "400",
			marginTop: "0.5rem",
		},
		"& .MuiFormControl-root": {
			// width: "90%",
			margin: theme.spacing(1),
		},
	},
	action__content: {
		padding: "0.5rem",
	},
	section: {
		padding: "1rem",
		"& *": {
			marginBottom: "0.5rem",
		},
		marginBottom: "1rem",
	},
	createButton: {
		textAlign: "right",
	},
	select_input: {
		width: "100%",
	},
}));

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

export default function CreatePortfolioDialog(props) {
	const classes = useStyles();

	const history = useHistory();
	const { handleTeams } = teamUtils;

	const dispatch = useDispatch();
	const { setOpen, open, onCreate, addCreatedOne } = props;
	const state = useSelector((state) => state);
	const { user, profileDictionary } = useSelector((state) => state.auth);
	const [access, setAccess] = useState([]);
	const [accessWithRole, setAccessWithRole] = useState([]);
	const fileReducer = useSelector((state) => state.file);
	const { createdFileIds } = fileReducer;
	const [deleteRoles, setDeleteRoles] = useState([]);
	const [userProjects, setUserProjects] = useState([]);
	const [checkedAgreement, setChecked] = useState(false);
	const [tagline, setTagline] = useState("");
	const [name, setName] = useState("");
	const [selectedProjects, setSelectedProjects] = useState([]);

	const [loading, setLoading] = useState(false);

	const {
		dialog__content,
		section,
		createButton,
		select_input,
		action__content,
	} = classes;

	const handleClose = () => {
		// do not close
		setOpen(false);
	};

	const callbackLocal = () => {
		setOpen(false);
	};

	const callback = onCreate ? onCreate : callbackLocal;

	const removeSingleImgFromReducerAndDelete = async (selectedId) => {
		const filteredFileIds = createdFileIds.filter((id) => id != selectedId);
		dispatch({
			type: "AddFile",
			payload: {
				createdFileIds: [...filteredFileIds],
			},
		});

		await updateDeleteFlagForSingleFiles({ fileId: selectedId })
			.then((data) => {
				console.log("data");
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const removeMsgWithUser = (id) => {
		const filteredUserArr = access.filter((user) => user?._id !== id);
		setAccess(filteredUserArr);

		let newUserAccessRoleArr = [];
		accessWithRole.map((userRole) => {
			if (userRole?.user?._id !== id) {
				newUserAccessRoleArr.push(userRole);
			}
		});
		setAccessWithRole(newUserAccessRoleArr);
	};

	//update user role
	const updateMsgUserWithRole = (value, index) => {
		let inputDataArr = accessWithRole;
		let editObj = {
			...accessWithRole[index],
			role: value,
		};
		inputDataArr[index] = editObj;
		setAccessWithRole(inputDataArr);
	};

	//add new user
	const addNewMsgAccessUser = (newAccessRoleObj) => {
		const accessableUserWithRoleObj = {
			user: newAccessRoleObj?.user,
			role: newAccessRoleObj?.role,
		};
		let newAccessableUsersWithRoleArr = [
			...accessWithRole,
			accessableUserWithRoleObj,
		];
		setAccessWithRole(newAccessableUsersWithRoleArr);
	};
	const getUserPorjects = async () => {
		if (user?._id) {
			const res = await Api.post("project/getUserProject", {
				user: user?._id,
			});
			if (res) {
				setUserProjects(res);
			}
		}
	};

	useEffect(() => {
		getUserPorjects();
		setAccess([user?.profile]);
		setAccessWithRole([
			{
				user: profileDictionary[user?.profile],
				role: "Admin",
			},
		]);
	}, [user]);
	const createPortfolioApi = async () => {
		setLoading(true);
		let obj = {
			name: name,
			access: access,
			projectsArray: selectedProjects.map((ele) => ele?._id),
			userProfile: user?.profile,
			accessRoleArray: accessWithRole.map((Obj) => {
				return { user: Obj?.user?._id, role: Obj?.role };
			}),
		};
		if (createdFileIds[0]) {
			obj.displayPicture = createdFileIds[0];
		}
		try {
			const res = await Api.post("projectportfolio/create", obj);
			if (res?.success) {
				handleClose();
				addCreatedOne();
			}
		} catch (err) {
			console.error(err);
		}
		setLoading(false);
	};
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
			scroll={"paper"}
			maxWidth={"sm"}
			fullWidth
		>
			<DialogTitle id="form-dialog-title" onClose={handleClose}>
				Create Project Portfolio
			</DialogTitle>
			<DialogContent dividers className={dialog__content}>
				<div style={{ marginLeft: "1rem" }}>
					<TextField
						label="Title"
						placeholder="Enter Portfolio Name"
						fullWidth
						value={name}
						variant="outlined"
						onChange={(e) => setName(e.target.value)}
					/>
					<div
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<p
							style={{
								fontSize: "17px",
								fontWeight: "550",
							}}
						>
							Logo
						</p>
						<FileUploadButton
							parentType="Doc"
							used={false}
							parentId={null}
							IconColor="white"
							iconBig={true}
							aditionalText={"Add Logo"}
							attachIconStyle={classes.attachIconFont}
							iconWithTextStyle={classes.iconWithTextStyle}
						/>
						<FilesViewer
							fileIds={createdFileIds}
							deletable={true}
							handler={removeSingleImgFromReducerAndDelete}
						/>
					</div>
				</div>
				<div style={{ marginTop: "1rem" }}>
					<div style={{ width: "100%", opacity: "0.5" }}>
						<Typography>Add Users With roles</Typography>
					</div>
					<UserWithRoleComponent
						userArr={access}
						setUserArr={setAccess}
						userProfile={user?.profile}
						walletId={user?.wallet}
						relationType={"Customer"}
						userOp={true}
						projectOp={false}
						orgOp={false}
						roles={["Admin", "User"]}
						defaultType={"User"}
						userRoleArr={accessWithRole}
						setUserRoleArr={setAccessWithRole}
						removeUserRole={removeMsgWithUser}
						updateRoleOfUserRole={updateMsgUserWithRole}
						addUserRole={addNewMsgAccessUser}
						setDeleteRoles={setDeleteRoles}
					/>
				</div>
				<div style={{ marginTop: "1rem" }}>
					<Autocomplete
						multiple
						id="tags-outlined"
						options={userProjects}
						getOptionLabel={(option) => option.displayName}
						filterSelectedOptions
						value={selectedProjects}
						onChange={(e, newValue) =>
							setSelectedProjects(newValue)
						}
						renderOption={(option, { selected }) => (
							<React.Fragment>
								<div
									style={{
										display: "flex",
										gap: "7px",
										alignItems: "center",
									}}
								>
									<Avatar
										alt={option?.displayName}
										src={option?.displayPicture?.url}
									/>
									<Typography>
										{option?.displayName}
									</Typography>
								</div>
							</React.Fragment>
						)}
						renderTags={(value, getTagProps) =>
							value.map((option, index) => (
								<Chip
									key={index}
									color="primary"
									avatar={
										<Avatar
											alt={option?.displayName}
											src={option?.displayPicture?.url}
										/>
									}
									label={option.displayName}
									{...getTagProps({ index })}
								/>
							))
						}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label="Projects"
								placeholder="Type to Search Projects"
							/>
						)}
					/>
				</div>
				{/* </Paper> */}
			</DialogContent>
			<DialogActions>
				<div className={createButton}>
					<LoadingButton
						loading={loading}
						variant="outlined"
						color="primary"
						text="Create"
						onClick={() => {
							createPortfolioApi();
						}}
						style={{
							textTransform: "none",
							fontSize: "1rem",
						}}
					/>
				</div>
			</DialogActions>
		</Dialog>
	);
}
