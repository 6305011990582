import firebase from "firebase";
import Api from "../../helpers/Api";

export const handleGoogleLogin = (dispatch,history) => {
	const provider = new firebase.auth.GoogleAuthProvider();

	firebase
		.auth()
		.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
		.then(() => {
			firebase
				.auth()
				.signInWithPopup(provider)
				.then((result) => {
					dispatch({
						type: 'AddAuth',
						payload: {
							authDoneAt:new Date()
						}
					})
					firebaseLoginHelper({firebaseUser:result.user, dispatch,history});
				})
				.catch((e) => {
					console.log(e, " is the firebase error");
				});
		});
};

export const handleEmailPasswordLogin = ({dispatch,history, email, password, setErr}) => {
	const auth = firebase.auth();
	auth.signInWithEmailAndPassword(email, password)
		.then((result) => {

			console.log('before firebaseLoginHelper ',result)
			firebaseLoginHelper({firebaseUser:result.user, dispatch,history});
		})
		.catch((e) => {
			setErr(true);
		});
};

export const handleEmailPasswordSignup = async({
	dispatch,
	history,
	email,
	password,
	displayNameParam = null,
	isEmailSignUp = false,
	firstName,
	lastName
}) => {
	console.log('handleEmailPasswordSignup called')
	const auth = await firebase.auth();
	console.log(auth,' is the firebase auth')
	console.log(email,' is the firebase email')
    try {
        
        console.log('About to call createUserWithEmailAndPassword');
        const result = await auth.createUserWithEmailAndPassword(email, password);
        console.log(result, 'authenticated');
        
        await firebaseLoginHelper({
            firebaseUser: result.user,
            history,
            dispatch,
            displayNameParam,
            isEmailSignUp,
            firstName,
            lastName
        });
    } catch (e) {
        console.error('Error in createUser function:', e);
		var errorCode = e.code;
		var errorMessage = e.message;
		console.log(errorCode, errorMessage);
        // You might want to throw the error here to propagate it upwards
        throw e;
    }

};

export const handleForgotPassword = (email) => {
	const auth = firebase.auth();
	auth.sendPasswordResetEmail(email)
		.then(() => {
			console.log("email Sent Successfully");
		})
		.catch((e) => {
			console.log(e, " is the firebase error");
		});
};

export const handleResetPassword = (oobCode, password) => {
	const auth = firebase.auth();
	auth.confirmPasswordReset(oobCode, password)
		.then(() => {
			console.log("Password Reset Successfully");
		})
		.catch((e) => {
			console.log(e, " is the firebase error");
		});
};


// backend call for auth 
export const firebaseLoginHelper = async ({
	firebaseUser, dispatch,history, displayNameParam = null, 
	isEmailSignUp, firstName, lastName
}) => {
	console.log('firebaseLoginHelper called',firebaseUser)
	if (firebaseUser) {
		let { uid, email, displayName, photoURL } = firebaseUser;
		displayName = displayName || displayNameParam;
		const token = await firebase.auth().currentUser.getIdToken(true);
		localStorage.setItem("token", token);
		localStorage.setItem("loggedInFirebaseUId", uid);
		// dispatch({
		// 	type: "AddAuth",
		// 	payload: { signinupLoader: true }
		// });
		const nameObject = isEmailSignUp? {
			firstName, lastName
		}:{};
		console.log(displayName,'displayName', 
		displayNameParam, 'displayNameParam',
		firstName, lastName)


		await Api.post("signIn/", {
			firebaseUid: uid,
			email,
			displayName,
			...nameObject,
			isEmailSignUp,
			imagePath: photoURL,
			emailVerified: true,
			token
		})
			.then((user) => {

				const userId = user._id;
				const personDictionary = {};
				personDictionary[userId] = user;
				// dispatch({
				// 	type: "AddAuth",
				// 	payload: { signinupLoader: false }
				// });
				
				dispatch({
					type: "SET_AUTH_USER",
					user,
				});
				if (user?._id) {
					console.log(user,' is the userbeforepush')
					if (
					  user?.wallet 
					) {
					  console.log("all good with the profile");
					  history.push('/');
					} else {
						let path = "/pre-login/";
						history.push(path);
					}
				  }
			})
			.catch((err) => {
				console.error("FIREBASE: ", err);
			});
	}
};

export const updateUser = (userObject, state, dispatch) => {
	const { auth } = state;
	const { user } = auth;
	Api.post("user/update", userObject).then((user) => { });
	dispatch({
		type: "AddAuth",
		payload: {
			user: {
				...user,
				...userObject,
			},
		},
	});
};
