import React, { useState, useEffect } from 'react';
import { getAccessControlOptions } from './api.call';
import AccessControlStoreHook from './AccessControlStoreHook';

export function useGetAccessOptionWithStucther(cutEntityProfileId) {
    const [accessOptions, setAccessOptions] = useState([]);
    const [idObjectMap, setIdObjectMap] = useState({});

    const { checkAccess } = AccessControlStoreHook();

    function transformStructure(input, parentId = "") {
        let resultArr = [];
        input.map((item, index) => {
            const itemId = parentId ? `${parentId}-${item.title}` : item.title;
            if (checkAccess(cutEntityProfileId, itemId)) {
                if (item.children) {
                    const children = transformStructure(item.children, itemId);
                    resultArr.push({
                        ...item,
                        _id: itemId,
                        children
                    })
                } else {
                    resultArr.push({
                        ...item,
                        _id: itemId
                    })
                }
            }
        });
        return resultArr;
    }

    function buildIdObjectMap(input, idObjMap = {}) {
        let locIdObjMap = { ...idObjMap };
        input.map((item) => {
            if (item.children) {
                locIdObjMap[item._id] = item;
                locIdObjMap = buildIdObjectMap(item.children, locIdObjMap);
            } else {
                locIdObjMap[item._id] = item;
            }
        });
        return locIdObjMap;
    }

    useEffect(() => {
        if (cutEntityProfileId != null && cutEntityProfileId != undefined) {
            getAccessControlOptions()
                .then((data) => {
                    const transformedArray = transformStructure(data);
                    console.log(transformedArray)
                    setAccessOptions(transformedArray)
                    const idObj = buildIdObjectMap(transformedArray);
                    console.log(idObj)
                    setIdObjectMap(idObj)
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }, [cutEntityProfileId]);

    return {
        accessOptions,
        setAccessOptions,
        idObjectMap,
        setIdObjectMap
    };
}