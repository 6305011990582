import React, { useEffect, useState } from "react";
import { Box, Checkbox, CircularProgress } from "@mui/material";
import { useTableAStyles } from "../../../utils/style";

const TableContainer = ({
  columns,
  data,
  loading,
  children,
  enableSelectAll = false,
  headerAlignment = "start",
  selectedRows,
  setSelectedRows,
  tableStyle,
  width,
  boldTextEnabled = false,
  hideHeader = false,
  containerHeight = "400px",
  containerStyleProps = {},
  forceResponsive = false,
}) => {
  const defaultTableStyle = useTableAStyles({ boldTextEnabled });
  const classes = tableStyle || defaultTableStyle;
  const [isLoading, setIsLoading] = useState(loading);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const tableStyles = {
    width: "100%",
    ...(forceResponsive ? { tableLayout: "fixed" } : {}),
  };

  const headerCellStyles = {
    padding: "10px",
    textAlign: headerAlignment,
    fontSize: "14px",
    color: "black",
    width: forceResponsive ? width : "",
    letterSpacing: "0.5px",
    borderBottom: "2px solid #444",
    ...(forceResponsive
      ? {
          wordWrap: "break-word",
          overflowWrap: "break-word",
          hyphens: "auto",
        }
      : {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }),
  };

  return (
    <Box
      sx={{
        ...containerStyleProps,
        overflowX: "auto",
        width: "100%",
      }}
    >
      {isLoading ? (
       <Box
            sx={{
              width: "100%",
              height: "40vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={30} />
          </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            minHeight: containerHeight,
            width: "100%",
            overflowX: "auto",
          }}
        >
          {!data || !Array.isArray(data) ? (
            <Box
              width="100%"
              height={containerHeight}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              No results found
            </Box>
          ) : (
            <table className={classes.tableSty} style={tableStyles}>
              {!hideHeader && (
                <thead>
                  <tr
                    style={{
                      backgroundColor: "#222",
                      color: "white",
                    }}
                  >
                    {enableSelectAll && (
                      <th
                        style={{
                          width: "80px",
                          padding: "10px",
                          textAlign: "center",
                          borderBottom: "1px solid #444",
                        }}
                      >
                        <Checkbox
                          disabled={data?.length === 0}
                          checked={
                            selectedRows?.length === data?.length &&
                            data?.length !== 0
                          }
                          onChange={(evt, checked) => {
                            if (checked) {
                              setSelectedRows([...data]);
                            } else {
                              setSelectedRows([]);
                            }
                          }}
                          style={{ color: "white" }}
                        />
                      </th>
                    )}
                    {columns.map((col) => (
                      <th key={col} style={headerCellStyles}>
                        {col}
                      </th>
                    ))}
                  </tr>
                </thead>
              )}
              {children}
            </table>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TableContainer;
