import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import MailIcon from '@material-ui/icons/Mail';
import { format } from "timeago.js";
import { CircularProgress } from "@material-ui/core";
import inviteEmail from "../../../Assets/invite_email.svg"
import invitePlatform from "../../../Assets/invite_platform.svg"



const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		paddingLeft: "20px",
		[theme.breakpoints.down("md")]: {
			paddingLeft: "10px",
		},
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			alignItems: "center"
		},
		[theme.breakpoints.down("xs")]: {
			width: "320px"
		}
	},
	loaderCont: {
		width: "100%",
		height: "55vh",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	leftSide: {
		width: "49%",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		}
	},
	rightSide: {
		width: "49%",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			borderTop: "1px solid #ececec",
			paddingTop: "10px",
			marginTop: "40px"
		}
	},
	divider: {
		borderRight: "1px solid #ececec",
		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
	},
	titleSty: {
		width: "100%",
		textAlign: "left",
		marginBottom: "30px"
	},
	cardMainCont: {
		width: "90%",
		padding: "10px",
		borderRadius: "4px",
		border: "1px solid #ececec",
		marginBottom: "20px",
		cursor: "pointer",
		boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
		"& h5": {
			marginBottom: "3px",
			marginTop: "20px"
		},
		"&:hover": {
			transform: "scale(1.02)"
		},
		[theme.breakpoints.down("md")]: {
			width: "100%",
		},
		[theme.breakpoints.down("sm")]: {
			width: "90%",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	inviteCont: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		"& h3": {
			fontSize: "15px",
			fontWeight: "510"
		},
		"& p": {
			fontSize: "12px",
			color: "gray"
		},
		"& h4": {
			fontSize: "14px",
			color: "white",
			fontWeight: "500",
			backgroundColor: "gray",
			padding: "2px 4px",
			borderRadius: "3px"
		},
	},
	inviteByCont: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		"& h3": {
			fontSize: "13px",
			fontWeight: "500"
		},
		"& p": {
			fontSize: "10px",
			color: "gray"
		},
		"& h4": {
			fontSize: "12px",
			color: "gray"
		},
	},
	emptyCont: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		padding: "20px 0px",
		"& img": {
			width: "300px",
			height: "auto"
		},
		"& p": {
			fontSize: "13px",
			color: "gray",
			marginTop: "10px"
		},
		[theme.breakpoints.down("xs")]: {
			"& img": {
				width: "200px",
				height: "auto"
			},
			"& p": {
				fontSize: "11px",
				color: "gray",
				marginTop: "7px"
			},	
		}
	}
}));

const Invites = ({ teamId, invites, invitesLoading }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);

	const [emailInvites, setEmailInvites] = useState([]);
	const [platformInvites, setPlatformInvites] = useState([]);

	useEffect(() => {
		let eInvites = [];
		let pInvites = [];

		invites.map((invite) => {
			if (invite.email) {
				eInvites.push(invite);
			} else {
				pInvites.push(invite);
			}
			setEmailInvites(eInvites);
			setPlatformInvites(pInvites);
		});
	}, [invites]);

	return (
		<div className={classes.root} >
			{invitesLoading ? (
				<div className={classes.loaderCont} >
					<CircularProgress />
				</div>
			) : (<>
				<div className={classes.leftSide} >
					<h3 className={classes.titleSty} >Platform Invitation</h3>

					{platformInvites && platformInvites.length > 0 ? platformInvites.map((invitesData, i) => (
						<div key={i} className={classes.cardMainCont} >
							<div className={classes.inviteCont} >
								<div style={{ display: "flex", alignItems: "center" }} >
									<Avatar style={{ width: "35px", height: "35px" }} src={invitesData?.invitee?.parent?.displayPicture?.url} />
									<div style={{ marginLeft: "7px" }} >
										<h3>{invitesData?.invitee?.parent?.displayName}</h3>
										<p>{invitesData?.invitee?.parent?.email}</p>
									</div>
								</div>
								<h4>{invitesData?.role}</h4>
							</div>
							<h5>Invited by</h5>
							<div className={classes.inviteByCont} >
								<div style={{ display: "flex", alignItems: "center" }} >
									<Avatar style={{ width: "30px", height: "30px" }} src={invitesData?.invitedById?.parent?.displayPicture?.url} />
									<div style={{ marginLeft: "7px" }} >
										<h3>{invitesData?.invitedById?.parent?.displayName}</h3>
										<p>{invitesData?.invitedById?.parent?.email}</p>
									</div>
								</div>
								<h4>{format(invitesData?.createdAt)}</h4>
							</div>
						</div>
					)) : (<div className={classes.emptyCont} >
						<img src={invitePlatform} />
						<p>No invitation sent through paltform</p>
					</div>)}
				</div>
				<div className={classes.divider} ></div>
				<div className={classes.rightSide} >
					<h3 className={classes.titleSty} >Mail Invitation</h3>

					{emailInvites && emailInvites.length > 0 ? emailInvites.map((invitesData, i) => (
						<div key={i} className={classes.cardMainCont} >
							<div className={classes.inviteCont} >
								<div style={{ display: "flex", alignItems: "center" }} >
									<Avatar style={{ width: "35px", height: "35px" }} >
										<MailIcon />
									</Avatar>
									<div style={{ marginLeft: "7px" }} >
										<h3>Invitation by mail ID</h3>
										<p>{invitesData?.email}</p>
									</div>
								</div>
								<h4>{invitesData?.role}</h4>
							</div>
							<h5>Invited by</h5>
							<div className={classes.inviteByCont} >
								<div style={{ display: "flex", alignItems: "center" }} >
									<Avatar style={{ width: "30px", height: "30px" }} src={invitesData?.invitedById?.parent?.displayPicture?.url} />
									<div style={{ marginLeft: "7px" }} >
										<h3>{invitesData?.invitedById?.parent?.displayName}</h3>
										<p>{invitesData?.invitedById?.parent?.email}</p>
									</div>
								</div>
								<h4>{format(invitesData?.createdAt)}</h4>
							</div>
						</div>
					)) : (<div className={classes.emptyCont} >
						<img src={inviteEmail} />
						<p>No invitation sent through mail</p>
					</div>)}
				</div>
			</>)}
		</div>
	);
};

export default Invites;