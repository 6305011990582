const { default: Api } = require("../../helpers/Api");


export const getTopCategories = async (obj) => {
    try {
        const res = await Api.post("get/categories/top", obj);
        const data = res?.data;
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const getCategoriesByName = async (obj) => {
    try {
        const res = await Api.post("category/getCats", obj);
        const data = res?.data;
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const getUserCallForUsernameCheck = async (obj) => {
    try {
        const res = await Api.post("find/user/by-username", obj);
        const data = res?.data || [];
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const updateUserData = async (obj) => {
    try {
        const res = await Api.post("user/update", obj);
        const data = res;
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const updateProfileData = async (obj) => {
    try {
        const res = await Api.post("profile/update", obj);
        const data = res;
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const getWorkspaceConfigById = async (obj) => {
    try {
        const res = await Api.post("get/appModule/by-workspaceConfigId", obj);
        const result = res?.data;
        return result;
    } catch (error) {
        console.log(error);
    }
};

export const updateWorkspaceAndWalletForUser = async (obj) => {
    try {
        const res = await Api.post("user/config-workspace/create-wallet", obj);
        const result = res?.data;
        return result;
    } catch (error) {
        console.log(error);
    }
};