import React from 'react';
import {
  Chip,
  makeStyles,
  Grid,
  Paper
} from '@material-ui/core';
import { FaBinoculars } from 'react-icons/fa';
import html2plaintext from "html2plaintext";
import LessText from "../styled/CommonComponents/LessText";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  binocularsIcon: {
    color: theme.palette.primary.main,
    fontSize: "25px"
  },
  statusChip: {
    marginLeft: 'auto',
  },
  paperCont: {
    padding: "15px"
  },
  cardTop: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px"
  },
  iconTitleCont: {
    display: 'flex',
    alignItems: "center",
    "& h3": {
      fontSize: "16px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "12px",
      color: "gray"
    },
    "& div": {
      marginLeft: "10px",
    }
  },
  bottomSty: {
    display: 'flex',
    alignItems: "center",
    padding: "10px 10px",
    backgroundColor: "#e2f3ff",
    justifyContent: "space-between",
    "& p": {
      fontSize: "14px",
      color: "gray"
    }
  }
}));

const ObservationCard = ({ observation, onClick, xs = 12, md = 6, lg = 4 }) => {
  const classes = useStyles();

  return (
    <Grid item xs={xs} md={md} lg={lg}>
      <Paper style={{ cursor: "pointer" }} elevation={2} onClick={() => onClick(observation)}  >
        <div className={classes.paperCont} >
          <div className={classes.cardTop} >
            <div className={classes.iconTitleCont} >
              <FaBinoculars className={classes.binocularsIcon} />
              <div>
                <h3>{observation?.title || "Untitled"}</h3>
                <p>{moment(observation?.createdAt).format('MMM Do YYYY, hh:mm a')}</p>
              </div>
            </div>
            <Chip
              label={observation?.status}
              size="small"
              color="primary"
              className={classes.statusChip}
            />
          </div>
          <div>
            <LessText
              limit={200}
              string={html2plaintext(observation?.description)}
            />
          </div>
        </div>
        <div className={classes.bottomSty} >
          <p>{observation.docs.length} Docs</p>
          <p>By {observation?.user?.displayName}</p>
        </div>
      </Paper>
    </Grid>
  );
};

export default ObservationCard;