import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { GoInbox } from "react-icons/go";
import { Avatar } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import moment from "moment";
import { getMutalOpsByType } from "./api.call";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    emptySty: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            color: "gray"
        }
    },
    tableContainer: {
        width: "100%",
        height: "calc(100% - 50px)",
        "& tr": {
            height: "45px"
        },
        "& table": {
            tableLayout: "auto"
        }
    },
    tableRow: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec !important"
        }
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #d2d1d1",
    },
    nameEmailSty: {
        width: "100%",
        paddingLeft: "10px",
        "& h3": {
            fontSize: "14px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "11px",
            fontWeight: "400",
            color: "gray"
        }
    },
    loaderCont:{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));

export default function PurchaseOrder({
    curEntityId, secondPartyId,
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [loader, setLoader] = useState(true)
    const [totalPages, setTotalPages] = useState(0)
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)
    const [opsOrder, setOpsOrder] = useState([])

    const getAllProcurements = async () => {
        if (curEntityId && secondPartyId) {
            setLoader(true)
            await getMutalOpsByType({
                identifier: curEntityId,
                curPage: curPage - 1,
                limit: pageLimit,
                type: "Purchase Order",
                secondPartyId
            })
                .then((data) => {
                    console.log("The procurement data:", data)
                    setOpsOrder(data?.data || [])
                    let locTotalPage = Math.ceil((data?.count || 0) / pageLimit);
                    setTotalPages(locTotalPage)
                    setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                    setLoader(false)
                })
        }
    }

    useEffect(() => {
        getAllProcurements()
    }, 1000, [curPage, curEntityId, secondPartyId])

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (<>
        {loader ? (<div className={classes.loaderCont} >
            <CircularProgress />
        </div>) : (<>
            {(opsOrder && opsOrder.length > 0) ? (<>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="sticky table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{ minWidth: "170px" }} >
                                    Name
                                </TableCell>
                                <TableCell align="left" style={{ minWidth: "100px" }} >
                                    Status
                                </TableCell>
                                <TableCell align="left" style={{ minWidth: "120px" }} >
                                    Total Amount
                                </TableCell>
                                <TableCell align="left" style={{ minWidth: "120px" }} >
                                    Certified Amount
                                </TableCell>
                                <TableCell align="left" style={{ minWidth: "230px" }} >
                                    Project
                                </TableCell>
                                <TableCell align="left" style={{ minWidth: "230px" }} >
                                    Created By
                                </TableCell>
                                <TableCell align="right" style={{ minWidth: "120px" }} >
                                    Created At
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {opsOrder && opsOrder.length > 0 && opsOrder.map((dataRow) => {
                                return (
                                    <TableRow
                                        className={classes.tableRow}
                                        onClick={() => {
                                            history.push(`/procurements/ops-order/${dataRow?._id}/edit`)
                                        }}
                                    >
                                        <TableCell align="left" style={{ minWidth: "170px" }} >
                                            {dataRow?.name}
                                        </TableCell>
                                        <TableCell align="left" style={{ minWidth: "100px" }} >
                                            {dataRow?.status}
                                        </TableCell>
                                        <TableCell align="left" style={{ minWidth: "120px" }} >
                                            ₹{numberWithCommas(dataRow?.totalAmountVal || 0)}
                                        </TableCell>
                                        <TableCell align="left" style={{ minWidth: "120px" }} >
                                            ₹{numberWithCommas(dataRow?.totalCertifiedAmount || 0)}
                                        </TableCell>
                                        <TableCell align="left" style={{ minWidth: "230px" }} >
                                            {dataRow?.project?.displayName && (
                                                <div style={{ display: "flex", alignItems: "center" }} >
                                                    <Avatar style={{ width: "30px", height: "30px" }} src={dataRow?.project?.displayPicture?.url} />
                                                    <div className={classes.nameEmailSty} >
                                                        <h3>{dataRow?.project?.displayName}</h3>
                                                        <p>{dataRow?.project?.email}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell align="left" style={{ minWidth: "230px" }} >
                                            {dataRow?.user?.displayName && (
                                                <div style={{ display: "flex", alignItems: "center" }} >
                                                    <Avatar style={{ width: "30px", height: "30px" }} src={dataRow?.user?.displayPicture?.url} />
                                                    <div className={classes.nameEmailSty} >
                                                        <h3>{dataRow?.user?.displayName}</h3>
                                                        <p>{dataRow?.user?.email}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell align="right" style={{ minWidth: "120px" }} >
                                            {moment(dataRow?.created).format("DD/MMM/YYYY")}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.paginationCont} >
                    <Pagination
                        count={totalPages}
                        page={curPage}
                        color="primary"
                        siblingCount={0}
                        onChange={(event, value) => { setCurPage(value) }}
                    />
                </div>
            </>) : (
                <div className={classes.emptySty} >
                    <GoInbox style={{ fontSize: "100px" }} />
                    <p>No purchase order exisit for current contact</p>
                </div>
            )}
        </>)}
    </>);
}