import React, { useEffect, useState } from "react";
import TabsWithSearch from "../../../../styled/generic/TabsWithSearch";
import { Box } from "@mui/material";
import MyLeads from "../../../common/tablecontainers/MyLeads";
import RequirementsForListing from "../../../common/tablecontainers/RequirementsForListing";

const Matches = () => {
  const [activeTab, setActiveTab] = useState("All Matches");

  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "10px", p: 2, mt: 2 }}>
      <TabsWithSearch
        tabs={["All Matches"]}
        onTabChange={(newTabVal) => setActiveTab(newTabVal)}
        currentTabVariable={activeTab}
        disableUnderline={true}
      />
      {activeTab === "All Matches" && <RequirementsForListing />}
    </Box>
  );
};

export default Matches;
