import { KeyboardArrowDown } from "@material-ui/icons";
import { styled } from "@mui/material";
import MuiSelect from "@mui/material/Select";
import React from "react";

const StyledSelect = styled(MuiSelect)({
  border: "1px solid #E4E4E4",
  borderRadius: "25px",
  boxShadow: "0px 1px 2px 0px rgba(31, 41, 55, 0.08)",
  padding: "0px 12px",
});

const SelectElevated = ({ children, ...props }) => {
  return (
    <StyledSelect {...props} IconComponent={KeyboardArrowDown}>
      {children}
    </StyledSelect>
  );
};

export default SelectElevated;
