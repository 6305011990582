import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles({

});

const AccessContacts = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { invitations } = useSelector((state) => state.team);
    const { user } = useSelector((state) => state.auth);




    return (<div>

    </div>);
};

export default AccessContacts;