import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Button,
    TextField,
    Typography,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    Box
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import {
    createChecklist, updateChecklist,
    addCheckpointToChecklist, removeCheckpointFromChecklist,
    getChecklistDetail
} from './Api.call';
import * as yup from "yup";
import CategoryAutocomplete from "./CategoryAutocomplete";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import arrayToReducer from '../../helpers/arrayToReducer';
import CheckpointTreeView from './CheckpointTreeView';

const checklistValidationSchema = yup.object().shape({
    title: yup.string().min(2).max(250).required("Title is required"),
    checkpoints: yup.array().of(yup.object().shape({
        description: yup.string().required("Checkpoint description is required")
    })).min(1, "At least one checkpoint is required"),
});

const useStyles = makeStyles((theme) => ({
    mainDialogCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "14px"
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflow: "auto",
        padding: "15px"
    },
    bottomAct: {
        width: "100%",
        height: "50px",
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #ececec"
    },
    formField: {
        marginBottom: "15px"
    },
    validationSty: {
        fontSize: "12px",
        color: "red",
        fontWeight: "400",
        marginTop: "5px"
    },
    checkpointContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
    },
    checkpointField: {
        flex: 1,
        marginRight: "10px",
    },
    addCheckpointButton: {
        marginTop: "10px",
        marginBottom: "20px",
    },
    chipContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default function ChecklistCreateDrawer({
    createChecklistDrawer,
    setCreateChecklistDrawer,
    profileId,
    afterChecklistCreateOrEdit,
    libraryId,
    checklistForEditDelete,
    isTemplate,
    ...other
}) {
    const classes = useStyles();
    const theme = useTheme();
    const { user } = useSelector((state) => state.auth);

    const [loader, setLoader] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [checkpoints, setCheckpoints] = useState([{ description: "", isCompleted: false, notes: "" }]);
    const [checkpointIds, setCheckpointIds] = useState([]);
    const [checkpointDict, setCheckpointDict] = useState({});

    const [category, setCategory] = useState("");
    const [validationObj, setValidationObj] = useState({});
    const [status, setStatus] = useState("active");
    const [labels, setLabels] = useState([]);
    console.log(checklistForEditDelete, ' is the oldchecklist')

    useEffect(() => {
        if (createChecklistDrawer) {
            if (checklistForEditDelete?._id) {
                setTitle(checklistForEditDelete?.title || "");
                setDescription(checklistForEditDelete?.description || "");

                setCategory(checklistForEditDelete?.category || "");
                setStatus(checklistForEditDelete?.status || "active");
                setLabels(checklistForEditDelete?.labels || []);
                getChecklistDetail({
                    checklistId: checklistForEditDelete?._id
                }).then(res => {
                    console.log(res, ' is getcheckpointdetail')
                    let data = res?.checklist?.checkpoints;
                    console.log(JSON.stringify(data))
                    if (Array.isArray(data)) {
                        setCheckpoints(data)
                        const {
                            idArr: checkpointIds,
                            newDict: checkpointDict
                        } = arrayToReducer(data)
                        setCheckpointIds(checkpointIds)
                        setCheckpointDict(checkpointDict)
                    }
                })
            } else {
                setInitialState();
            }
        }
    }, [createChecklistDrawer, checklistForEditDelete]);

    const setInitialState = () => {
        setLoader(false);
        setTitle("");
        setDescription("");
        setCheckpointIds([]);
        setCheckpointDict({});
        setCategory("");
        setStatus("active");
        setLabels([]);
        setValidationObj({});
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        let formData;
        if (checklistForEditDelete?._id) {
            // Editing existing checklist
            formData = {
                title,
                description,
                checkpoints: checkpointIds,
                category,
                status,
                labels: labels.map(label => label._id),
                isTemplate,
                libraryId,
                lastModifiedBy: user?._id
            };
        } else {
            // Creating new checklist
            formData = {
                title,
                description,
                checkpoints: checkpointIds.map(id => checkpointDict[id]),
                category,
                status,
                labels: labels.map(label => label._id),
                isTemplate,
                libraryId,
                creatorId: user?._id
            };
        }

        try {
            // await checklistValidationSchema.validate(formData, { abortEarly: false });
            setLoader(true);
            console.log(formData, ' istheformdata');

            let data;
            if (checklistForEditDelete?._id) {
                const updateObj = {
                    checklistId: checklistForEditDelete._id,
                    userId: user?._id,
                    updateData: formData
                };
                data = await updateChecklist(updateObj);
            } else {
                console.log('before createChecklist')
                data = await createChecklist(formData);
                console.log(data, 'after createChecklist')

            }
            afterChecklistCreateOrEdit(data);
            setLoader(false);
            setCreateChecklistDrawer(false);
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                const errorObj = {};
                err.inner.forEach((error) => {
                    errorObj[error.path] = error.message;
                });
                setValidationObj(errorObj);
            } else {
                console.error(err);
            }
            setLoader(false);
        }
    };



    const removeCheckpoint = async (index) => {
        const checkpointToRemove = checkpoints[index];
        const newCheckpoints = checkpoints.filter((_, i) => i !== index);
        setCheckpoints(newCheckpoints);

        if (checklistForEditDelete?._id && checkpointToRemove._id) {
            try {
                setLoader(true);
                await removeCheckpointFromChecklist({
                    checklistId: checklistForEditDelete._id,
                    checkpointId: checkpointToRemove._id,
                    userId: user?._id
                });
                setLoader(false);
            } catch (error) {
                console.error('Error removing checkpoint from backend:', error);
                setLoader(false);
                // Optionally, you can show an error message to the user here
            }
        }
    };

    const updateCheckpoint = (index, value) => {
        const newCheckpoints = [...checkpoints];
        newCheckpoints[index] = {
            ...newCheckpoints[index],
            description: value
        };
        setCheckpoints(newCheckpoints);
    };

    return (
        <NormalDrawer
            openDrawer={createChecklistDrawer}
            setOpenDrawer={setCreateChecklistDrawer}
            anchor={"right"}
            width={"60vw"}
            title={`${checklistForEditDelete?._id ? "Edit" : "Create"} Checklist`}
            content={
                <form className={classes.mainDialogCont} onSubmit={onSubmit}>
                    <div className={classes.bodyCont}>
                        <TextField
                            className={classes.formField}
                            variant="outlined"
                            size="small"
                            label={<>Title<span style={{ color: "red" }}>*</span></>}
                            placeholder="Enter title"
                            value={title}
                            fullWidth
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        {validationObj.title && <Typography className={classes.validationSty}>{validationObj.title}</Typography>}

                        <TextField
                            className={classes.formField}
                            variant="outlined"
                            size="small"
                            label="Description"
                            placeholder="Enter description"
                            value={description}
                            fullWidth
                            multiline
                            rows={3}
                            onChange={(e) => setDescription(e.target.value)}
                        />

                        <FormControl variant="outlined" size="small" fullWidth className={classes.formField}>
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                                labelId="status-label"
                                id="status-select"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                label="Status"
                            >
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="inactive">Inactive</MenuItem>
                                <MenuItem value="archived">Archived</MenuItem>
                            </Select>
                        </FormControl>



                        <Typography variant="subtitle1" gutterBottom style={{ marginTop: '20px' }}>
                            Labels
                        </Typography>
                        <CategoryAutocomplete
                            libraryId={libraryId}
                            selectedCategories={labels}
                            setSelectedCategories={setLabels}
                            isMultiple={true}
                            label="Labels"
                            profileId={profileId}
                            type="Task"
                            isCreateNew={true}
                            placeholder="Select or create labels"
                        />
                        <Box className={classes.chipContainer}>
                            {labels.map((label) => (
                                <Chip
                                    key={label._id}
                                    label={label.name}
                                    onDelete={() => setLabels(labels.filter((l) => l._id !== label._id))}
                                    color="primary"
                                    variant="outlined"
                                />
                            ))}
                        </Box>

                        {/* <Typography variant="subtitle1" gutterBottom style={{ marginTop: '20px' }}>
                            Checkpoints<span style={{ color: "red" }}>*</span>
                        </Typography>
                        {checkpoints.map((checkpoint, index) => (
                            <div key={index} className={classes.checkpointContainer}>
                                <TextField
                                    className={classes.checkpointField}
                                    variant="outlined"
                                    size="small"
                                    label={`Checkpoint ${index + 1}`}
                                    placeholder="Enter checkpoint description"
                                    value={checkpoint.description}
                                    fullWidth
                                    onChange={(e) => updateCheckpoint(index, e.target.value)}
                                />
                                <IconButton onClick={() => removeCheckpoint(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        ))} */}

                        <CheckpointTreeView
                            checkpointIds={checkpointIds}
                            checkpointDict={checkpointDict}
                            setCheckpointIds={setCheckpointIds}
                            setCheckpointDict={setCheckpointDict}
                            checklistId={checklistForEditDelete?._id}
                            profileId={profileId}
                        />

                        {validationObj.checkpoints && <Typography className={classes.validationSty}>{validationObj.checkpoints}</Typography>}
                    </div>
                    <div style={{ width: "100%", height: "5px" }}>
                        {loader && (<LinearProgress />)}
                    </div>
                    <div className={classes.bottomAct}>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            type="submit"
                        >
                            {checklistForEditDelete?._id ? "Update" : "Create"} Checklist
                        </Button>
                    </div>
                </form>
            }
        />
    );
}