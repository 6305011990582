import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import teamUtils from "../team/team.utils";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Api from "../../helpers/Api";
import ProfileSelect from "../styled/profile.select";
import useGetAdminProfiles from "../profile/useGetAdminProfiles";
import GooglePlaceAutocomplete from "../styled/CommonComponents/Google.Place.Auto";
import NormalDialog from "../styled/CommonComponents/NormalDialog";
import { nanoid } from "nanoid";
import AccessControlStoreHook from "../AccessControl/AccessControlStoreHook";
import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles((theme) => ({
  titleStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "7px 10px",
    backgroundColor: theme.palette.primary.main,
  },
  mainCont: {
    width: "600px",
    [theme.breakpoints.down("sm")]: {
      width: "400px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "290px",
    },
  },
  bodyCont: {
    width: "100%",
    height: "500px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down("xs")]: {
      height: "380px",
      padding: "0px 5px 15px",
    },
  },
  projectTitle: {
    width: "100%",
    marginBottom: "15px",
  },
  textArgu: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: "30px",
    "& p": {
      fontSize: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      "& p": {
        fontSize: "10px",
      },
    },
  },
  descStyle: {
    width: "100%",
    marginBottom: "15px",
  },
  addressCont: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));
const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
export default function CreateProjectDialog(props) {
  const classes = useStyles();
  const { handleTeams } = teamUtils;
  const dispatch = useDispatch();
  const { setOpen, open, onCreate, addCreatedOne } = props;

  const state = useSelector((state) => state);
  const { user, userProfile } = useSelector((state) => state.auth);
  const userId = user._id;
  const { adminProfiles } = useGetAdminProfiles();

  const { updateAccessByTeam} = AccessControlStoreHook();

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [ownerType, setOwnerType] = useState("Organization");
  const [isDependant, setIsDependant] = useState(true);
  const [owner, setOwner] = useState();
  const [checkBox, setCheckBox] = useState(false);
  const [fullAddressLine, setFullAddressLine] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [regionCode, setRegionCode] = useState("");
  const [country, setCountry] = useState("India(IN)");
  const [countryCode, setCountryCode] = useState("in");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const agreementSentence = "I verify that I am an author of the project and have the right to act on its behalf in the creation and management of this project. The Project is made in accordance with the terms & conditions of the community.";

  const createProjectApi = async () => {
    setLoading(true);

    let participantsArr = [];
    if (user.profile == owner?.profile) {
      participantsArr.push(user.profile);
    } else {
      participantsArr.push(user.profile);
      participantsArr.push(owner?.profile);
    }
    await Api.post("project/create", {
      owner: owner?._id,
      ownerModelName: owner?.model,
      user: userId,
      creator: userId,
      createrProfile: user?.profile,
      userModelName: "User",
      participants: participantsArr,
      projectIdCode: `${slugify(title).slice(0, 5)}-${nanoid(8)}`,
      displayName: title,
      description,
      isDependant,
      ownerProfile: owner?.profile,
      address: {
        fullAddressLine: fullAddressLine,
        streetAddress: streetAddress,
        zip: zip,
        city: city,
        region: region,
        regionCode: regionCode,
        country,
      },
      latitude,
      longitude,
    }).then(async(resData) => {
      const {team} = resData
      await updateAccessByTeam(team?._id)
      handleTeams([team], state, dispatch);
      if (onCreate) {
        onCreate(team);
      }
      if (addCreatedOne) {
        addCreatedOne(team);
      }
      setOpen(false);
      setLoading(false);
    });
    setTitle("");
    setDescription("");
    setCheckBox(false);
  };

  console.log(adminProfiles)

  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={() => {
        setOpen(false);
      }}
      pageTitle={"Create Project"}
      content={
        <div className={classes.mainCont}>
          <div className={classes.bodyCont}>

            <TextField
              id="outlined-basic"
              label={
                <>
                  Project Title
                  <span style={{ color: "red" }}>*</span>
                </>
              }
              variant="outlined"
              size="small"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              className={classes.projectTitle}
            />
            <TextField
              id="outlined-multiline-static"
              label="Description"
              rows={3}
              variant="outlined"
              size="small"
              multiline
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              className={classes.descStyle}
            />

            Select Project Owner

            <Radio
              checked={ownerType === 'Organization'}
              onChange={()=>{
                setOwnerType('Organization')
                setOwner(adminProfiles[0])
              }}
              color="primary"
              value="Organization"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'Organization' }}
            />
            Organization
            <Radio
              checked={ownerType === 'Personal'}
              onChange={()=>{
                setOwnerType('Personal')
                setOwner(user)
              }}
              color="primary"
              value="Personal"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'Personal' }}
            />
            Personal

            {ownerType=='Organization'?<>
            {adminProfiles.length > 0 && (
              <ProfileSelect
                owner={owner}
                setOwner={setOwner}
                adminProfiles={adminProfiles}
                displayOwner={true}
                title={"Select Organization"}
                onChange={(value) => {
                  setOwner(value);
                }}
              />
            )}
            </>:null}

            <div>
              <Checkbox
                checked={isDependant}
                onChange={() => {
                  setIsDependant(!isDependant);
                }}
                color="primary"
              /> Import accounting and library from owner  
              {isDependant?'':<p style={{color:"red"}}>This is a very advanced use case. This will create inDependant
                accounting system for project. Make sure that you can handle accounting
               </p>}
              
            </div>

            <GooglePlaceAutocomplete
              inputContStyle={classes.addressCont}
              autoWidth={"100%"}
              textWidth={"100%"}
              isGetLogLat={true}
              fullAddressLine={fullAddressLine}
              setFullAddressLine={setFullAddressLine}
              streetAddress={streetAddress}
              setStreetAddress={setStreetAddress}
              zip={zip}
              setZip={setZip}
              city={city}
              setCity={setCity}
              region={region}
              setRegion={setRegion}
              regionCode={regionCode}
              setRegionCode={setRegionCode}
              country={country}
              setCountry={setCountry}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              latitude={latitude}
              setLatitude={setLatitude}
              longitude={longitude}
              setLongitude={setLongitude}
              isShowCountry={true}
              isShowCityStreet={true}
              isStateZip={true}
            />
            <div className={classes.textArgu}>
              <Checkbox
                checked={checkBox}
                onChange={() => {
                  setCheckBox(!checkBox);
                }}
                color="primary"
                style={{ padding: "0px 9px 9px" }}
              />
              <p>{agreementSentence}</p>
            </div>
          </div>
          {loading && (
            <div style={{ width: "100%", height: "4px" }}>
              <LinearProgress />
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "10px",
            }}
          >
            <div></div>
            {checkBox && title && title.length > 2 ? (
              <Button
                onClick={createProjectApi}
                color="primary"
                variant="outlined"
                disabled={!checkBox && title.length}
              >
                Create
              </Button>
            ) : (
              <Button color="primary" variant="outlined" disabled>
                Create
              </Button>
            )}
          </div>
        </div>
      }
    />
  );
}
