import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, TextField, Autocomplete, MenuItem, Grid, Slider, CircularProgress } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { styled } from '@mui/material/styles';
import Api from '../../../../helpers/Api';
import moment from 'moment';
import _ from 'lodash';

// Configure moment.js for Indian locale
import 'moment/locale/en-in';
moment.locale('en-in');

const StyledFormBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const SchedulingInfo = ({ taskId, projectId, schedulingInfo, onSchedulingChange, progress, onProgressChange }) => {
  // Initialize state with empty arrays to prevent undefined errors
  const [predecessors, setPredecessors] = useState(schedulingInfo.predecessors || []);
  const [duration, setDuration] = useState(schedulingInfo.duration || 1);
  const [durationUnit, setDurationUnit] = useState(schedulingInfo.durationUnit || 'days');
  const [availableTasks, setAvailableTasks] = useState([]); // Initialize as empty array
  const [calculatedInfo, setCalculatedInfo] = useState({
    isCritical: false,
    earliestStartDate: null,
    earliestFinishDate: null,
    latestStartDate: null,
    latestFinishDate: null,
    slack: 0,
  });
  const [searchStr, setSearchStr] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  console.log(schedulingInfo,' schedulingInfo')

  // Add this useEffect to initialize progress from taskToUpdate
  useEffect(() => {
    if (taskId) {
      // If we have a taskId, this is an edit mode
      setIsEditMode(true);
    }
  }, [taskId]);

  const [isEditMode, setIsEditMode] = useState(false);

  const fetchAvailableTasks = useCallback(async (search = '') => {
    if (!projectId) return;
    
    try {
      setIsSearching(true);
      const res = await Api.post('/wbs/search', {
        projectId,
        searchStr,
        excludeTaskIds: taskId ? [taskId] : [], // Exclude current task from available predecessors
      });
      setAvailableTasks(res?.data || []); // Ensure we always set an array
    } catch (error) {
      console.error('Error fetching available tasks:', error);
      setAvailableTasks([]); // Set empty array on error
    } finally {
      setIsSearching(false);
    }
  }, [projectId, taskId, searchStr]);

  // Debounced search function
  const debouncedSearch = useCallback(
    _.debounce((search) => {
      fetchAvailableTasks(search);
    }, 300),
    [fetchAvailableTasks]
  );

  // Handle search input change
  const handleSearchChange = (event, value, reason) => {
    if (reason === 'input') {
      setSearchStr(value);
      debouncedSearch(value);
    }
  };

  useEffect(() => {
    fetchAvailableTasks();
  }, [fetchAvailableTasks]);

  const calculateSchedulingInfo = useCallback(() => {
    let duration = schedulingInfo?.duration;
    if (!duration || duration <= 0) return;

    const currentDate = new Date();
    let earliestStartDate = null;
    let earliestFinishDate = null;
    let latestStartDate = null;
    let latestFinishDate = null;
    let slack = 0;

    if (!predecessors.length) {
      // Case 1: No predecessors
      if (progress === 0) {
        if (currentDate > schedulingInfo.plannedStart) {
          // If current date is past planned start
          earliestStartDate = currentDate;
          latestStartDate = currentDate;
        } else {
          // If current date is before planned start
          earliestStartDate = schedulingInfo.plannedStart;
          latestStartDate = schedulingInfo.plannedStart;
        }
        earliestFinishDate = new Date(earliestStartDate);
        latestFinishDate = new Date(latestStartDate);
      } else if (progress > 0 && progress < 100) {
        // In-progress task
        earliestStartDate = schedulingInfo.startDate;
        latestStartDate = schedulingInfo.startDate;

        const elapsedTime = currentDate - new Date(schedulingInfo.startDate);
        const remainingTime = (100 / progress) * elapsedTime;

        earliestFinishDate = new Date(currentDate.getTime() + remainingTime);
        latestFinishDate = new Date(currentDate.getTime() + remainingTime);
      } else if (progress === 100) {
        // Completed task
        earliestStartDate = schedulingInfo.startDate;
        latestStartDate = schedulingInfo.startDate;
        earliestFinishDate = schedulingInfo.endDate;
        latestFinishDate = schedulingInfo.endDate;
      }

      // Calculate finish dates based on duration
      const durationValue = parseInt(duration || 0);
      switch (durationUnit) {
        case 'minutes':
          earliestFinishDate.setMinutes(earliestFinishDate.getMinutes() + durationValue);
          latestFinishDate.setMinutes(latestFinishDate.getMinutes() + durationValue);
          break;
        case 'hours':
          earliestFinishDate.setHours(earliestFinishDate.getHours() + durationValue);
          latestFinishDate.setHours(latestFinishDate.getHours() + durationValue);
          break;
        case 'days':
          earliestFinishDate.setDate(earliestFinishDate.getDate() + durationValue);
          latestFinishDate.setDate(latestFinishDate.getDate() + durationValue);
          break;
        case 'weeks':
          earliestFinishDate.setDate(earliestFinishDate.getDate() + (durationValue * 7));
          latestFinishDate.setDate(latestFinishDate.getDate() + (durationValue * 7));
          break;
      }

      // Calculate slack (in days)
      slack = Math.round((latestStartDate - earliestStartDate) / (1000 * 60 * 60 * 24));

      const isCritical = slack === 0;

      setCalculatedInfo({
        isCritical,
        earliestStartDate,
        earliestFinishDate,
        latestStartDate,
        latestFinishDate,
        slack,
      });

      const newInfo = {
        isCritical,
        earliestStartDate,
        earliestFinishDate,
        latestStartDate,
        latestFinishDate,
        slack,
      };

      onSchedulingChange(newInfo);
    } else {
      // Case 2: Has predecessors
      // Existing logic for handling predecessors
      // ...
    }
  }, [schedulingInfo.plannedStart,
    schedulingInfo.plannedEnd, schedulingInfo.startDate,
    schedulingInfo.endDate,
    predecessors, progress, schedulingInfo.duration, schedulingInfo.durationUnit ]);

  // Update useEffect to use the memoized callback
  // useEffect(() => {
  //   calculateSchedulingInfo();
  // }, [calculateSchedulingInfo]);

  const handlePredecessorsChange = (event, newValue) => {
    setPredecessors(newValue);
  };

  const handleDurationChange = (event) => {
    const newDuration = event.target.value;
    onSchedulingChange({
      ...schedulingInfo,
      duration: newDuration,
      plannedFinish: calculateFinishDate(schedulingInfo.plannedStart, newDuration)?.toDate(),
    });
  };

  const handleDurationUnitChange = (event) => {
    const newUnit = event.target.value;
    onSchedulingChange({
      ...schedulingInfo,
      durationUnit: newUnit,
    });
  };

  const hasPredecessors = predecessors.length > 0;

  // Add this helper function at the top of your component
  const formatDate = (date) => {
    if (!date) return 'Not set';
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    if (isNaN(date.getTime())) return 'Invalid date';
    return date.toLocaleDateString();
  };

  const calculateFinishDate = useCallback((startDate, durationValue) => {
    if (!startDate || !durationValue) return null;
    const start = moment(startDate);
    if (!start.isValid()) return null;
    
    switch (durationUnit) {
      case 'minutes':
        return start.clone().add(durationValue, 'minutes');
      case 'hours':
        return start.clone().add(durationValue, 'hours');
      case 'days':
        return start.clone().add(durationValue, 'days');
      case 'weeks':
        return start.clone().add(durationValue, 'weeks');
      default:
        return start.clone().add(durationValue, 'days');
    }
  }, [durationUnit]);

  const calculateDuration = useCallback((start, finish) => {
    if (!start || !finish) return null;
    const startDate = moment(start);
    const finishDate = moment(finish);
    
    if (!startDate.isValid() || !finishDate.isValid()) return null;
    
    switch (durationUnit) {
      case 'minutes':
        return finishDate.diff(startDate, 'minutes');
      case 'hours':
        return finishDate.diff(startDate, 'hours');
      case 'days':
        return finishDate.diff(startDate, 'days');
      case 'weeks':
        return finishDate.diff(startDate, 'weeks');
      default:
        return finishDate.diff(startDate, 'days');
    }
  }, [durationUnit]);

  const handleDateChange = useCallback((field, value) => {
    if (!value || !value.isValid()) return;

    let updates = { ...schedulingInfo };
    
    console.log(schedulingInfo,' insidehandleDateChange');

    if (field === 'plannedStart') {
      const newStart = moment(value);
      const newFinish = calculateFinishDate(newStart, schedulingInfo.duration);
      
      updates = {
        ...updates,
        plannedStart: newStart,
        plannedFinish: newFinish,
      };
      
    } else if (field === 'plannedFinish') {
      const newFinish = moment(value);
      const newDuration = calculateDuration(schedulingInfo.plannedStart, newFinish);
      
      updates = {
        ...updates,
        plannedFinish: newFinish,
        duration: newDuration || schedulingInfo.duration,
      };
    } else if (field === 'startDate') {
      // Handle startDate
      updates = {
        ...updates,
        startDate: moment(value),
      };
    } else if (field === 'endDate') {
      // Handle endDate
      updates = {
        ...updates,
        endDate: moment(value),
      };
    }
    
    onSchedulingChange(updates);
  }, [schedulingInfo]);

  return (
    <LocalizationProvider 
      dateAdapter={AdapterMoment}
      adapterLocale="en-in"
      dateFormats={{ shortDate: "DD/MM/YYYY" }}
    >
      <Box>
        <Typography variant="h6" gutterBottom>
          Scheduling Information
        </Typography>



        <StyledFormBox>
          <Autocomplete
            multiple
            options={availableTasks || []} // Provide fallback empty array
            getOptionLabel={(option) => option?.taskName || ''} // Add null check
            value={predecessors || []} // Add null check
            onChange={handlePredecessorsChange}
            onInputChange={handleSearchChange}
            loading={isSearching}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Predecessors"
                placeholder="Search predecessor tasks"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isSearching ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </StyledFormBox>


        <StyledFormBox>
          <Typography variant="subtitle1" gutterBottom>
            Progress
          </Typography>
          <Box sx={{ px: 2, py: 1 }}>
            <Slider
              sx={{
                height: "0.6em",
                color: "success.main",
                "& .MuiSlider-thumb": {
                  width: "1.3rem",
                  height: "1.3rem",
                },
                "&.Mui-disabled": {
                  color: "success.main",
                  opacity: 0.7,
                }
              }}
              value={progress}
              onChange={(event, newValue) => !isEditMode && onProgressChange(newValue)}
              valueLabelDisplay="auto"
              min={0}
              max={100}
              disabled={isEditMode}
            />
          </Box>
        </StyledFormBox>

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              label="Duration"
              type="number"
              value={schedulingInfo.duration}
              onChange={handleDurationChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              select
              label="Duration Unit"
              value={schedulingInfo.durationUnit}
              onChange={handleDurationUnitChange}
              fullWidth
              variant="outlined"
            >
              <MenuItem value="minutes">Minutes</MenuItem>
              <MenuItem value="hours">Hours</MenuItem>
              <MenuItem value="days">Days</MenuItem>
              <MenuItem value="weeks">Weeks</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: '16px', marginBottom: '24px' }}>
          <Grid item xs={3}>
            <DatePicker
              label="Planned Start"
              value={schedulingInfo.plannedStart ? moment(schedulingInfo.plannedStart) : null}
              onChange={(newValue) => handleDateChange('plannedStart', newValue)}
              maxDate={schedulingInfo.plannedFinish ? moment(schedulingInfo.plannedFinish) : undefined}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              label="Planned Finish"
              value={schedulingInfo.plannedFinish ? moment(schedulingInfo.plannedFinish) : null}
              onChange={(newValue) => handleDateChange('plannedFinish', newValue)}
              minDate={schedulingInfo.plannedStart ? moment(schedulingInfo.plannedStart) : undefined}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </Grid>

          {progress > 0 && (
            <Grid item xs={3}>
              <DatePicker
                label="Start Date"
                value={schedulingInfo.startDate ? moment(schedulingInfo.startDate) : null}
                onChange={(newValue) => handleDateChange('startDate', newValue)}
                maxDate={schedulingInfo.endDate ? moment(schedulingInfo.endDate) : undefined}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid>
          )}
          
          {progress === 100 && (
            <Grid item xs={3}>
              <DatePicker
                label="End Date"
                value={schedulingInfo.endDate ? moment(schedulingInfo.endDate) : null}
                onChange={(newValue) => handleDateChange('endDate', newValue)}
                minDate={schedulingInfo.startDate ? moment(schedulingInfo.startDate) : undefined}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid>
          )}
        </Grid>

        {/* <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 1 }}>
          <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'medium' }}>
            Calculated Schedule Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom>
                Earliest Start: {formatDate(calculatedInfo.earliestStartDate)}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Earliest Finish: {formatDate(calculatedInfo.earliestFinishDate)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom>
                Latest Start: {formatDate(calculatedInfo.latestStartDate)}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Latest Finish: {formatDate(calculatedInfo.latestFinishDate)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom>
                Slack: {calculatedInfo.slack} days
              </Typography>
            </Grid>
          </Grid>
        </Box> */}
      </Box>
    </LocalizationProvider>
  );
};

export default SchedulingInfo;
