import {
  Avatar,
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import TableContainer from "../../../styled/generic/TableContainer";
import ListingTableDateAgoCell from "../../../styled/generic/ListingTableDateAgoCell";
import ListingTableActionCell from "../../../styled/generic/ListingTableActionCell";
import ListingTableSingleUserCell from "../../../styled/generic/ListingTableSingleUserCell";
import ListingTableVisiblityCell from "../../../styled/generic/ListingTableVisiblityCell";

const ListingRequirementsTableComponent = ({
  matches,
  setMatches,
  actions,
  handleActionClick,
  loading,
  requestListing,
  applyListing,
}) => {
  const columns = [
    "LISTING TITLE",
    "LISTING TYPE",
    "CREATED DATE",
    "OWNER",
    "MATCHING TYPE",
    "ACTIONS",
  ];

  return (
    <Box sx={{ backgroundColor: "#FFFFFF", minHeight: "400px" }}>
      <TableContainer
        columns={columns}
        data={matches}
        loading={loading}
      >
        {matches?.map((item) => (
          <tr>
            <td>{item?.title}</td>
            <td>{item?.type}</td>
            <td>
              <ListingTableDateAgoCell date={item?.createdAt} />
            </td>
            <td>
              <ListingTableSingleUserCell
                label={item?.owner?.parent?.displayName || "xxxxxxxxx"}
                url={item?.owner?.parent?.displayPicture?.url}
              />
            </td>
            <td style={{ fontWeight: "bold", color: "#2D76E0" }}>
              <ListingTableVisiblityCell isPrivate={item?.isPrivate} />
            </td>
            <td>
              <Box display="flex" alignItems="center">
                {item?.isPrivate ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    sx={{ mr: 3, width: 100, textTransform: "none" }}
                    onClick={() => requestListing(item?._id)}
                    disabled={item?.applied}
                  >
                    {item?.applied ? "Requested" : "Request"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    sx={{ mr: 3, width: 100, textTransform: "none" }}
                    onClick={() => applyListing(item?._id)}
                    disabled={item?.applied}
                  >
                    {item?.applied ? "Applied" : "Apply"}
                  </Button>
                )}
                <ListingTableActionCell
                  cellData={item}
                  cellActions={actions}
                  cellActionListener={handleActionClick}
                />
              </Box>
            </td>
          </tr>
        ))}
      </TableContainer>
    </Box>
  );
};

export default ListingRequirementsTableComponent;
