import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { createTermsAndCondition, updateTermsAndCondition } from './Api.call';
import Autocomplete from "@material-ui/lab/Autocomplete";
import "react-quill/dist/quill.snow.css";
import "../doc/editor.css"
import { allLanguagesName } from '../styled/CommonComponents/languages.array';
import ReactQuill from "react-quill";
import * as yup from "yup";

const tCValidationSchema = yup.object().shape({
    title: yup.string().min(2).max(250).required("Name is required"),
    language: yup.string().required("Category is required")
});

const useStyles = makeStyles((theme) => ({
    mainDialogCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "14px"
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflow: "hidden",
        padding: "15px"
    },
    bottomAct: {
        width: "100%",
        height: "50px",
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #ececec"
    },
    duleInputCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "15px"
    },
    validationSty: {
        fontSize: "12px",
        color: "red",
        fontWeight: "400",
        marginTop: "5px"
    },
}));

const modules = {
    toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link"],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};

const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "script",
    "color",
    "background",
];

export default function TermsAndConditionCreateDrawer({
    createTCDawer, setCreateTCDawer, profileId, afterTCCreateOrEdit, libraryId, tcForEditDelete, ...other
}) {
    const classes = useStyles();
    const theme = useTheme();

    const { user } = useSelector((state) => state.auth);

    const [loader, setLoader] = useState(false)
    const [language, setLanguage] = useState("")
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [validationObj, setValidationObj] = useState({})

    const setInitialState = () => {
        setLoader(false)
        setLanguage("")
        setTitle("")
        setContent("")
    }

    useEffect(() => {
        if (createTCDawer) {
            if (tcForEditDelete?._id) {
                setLanguage(tcForEditDelete?.language || "")
                setTitle(tcForEditDelete?.title || "")
                setContent(tcForEditDelete?.content || "")
            } else {
                setInitialState()
            }
        }
    }, [createTCDawer])

    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            title: title,
            language: language
        }
        await tCValidationSchema.validate(formData, { abortEarly: false })
            .then(async (checkStatus) => {
                setLoader(true)
                if (tcForEditDelete?._id) {
                    const updateObj = {
                        _id: tcForEditDelete?._id,
                        language: language,
                        title: title,
                        content: content,
                    }

                    await updateTermsAndCondition({ updateObj })
                        .then((data) => {
                            console.log(data)
                            afterTCCreateOrEdit(data)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    const createObj = {
                        language: language,
                        title: title,
                        content: content,
                        shared: [profileId],
                        library: libraryId,
                        originProfile: profileId,
                        profile: user?.profile,
                        creator: user?._id,
                    }

                    await createTermsAndCondition(createObj)
                        .then((data) => {
                            console.log(data)
                            afterTCCreateOrEdit(data)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                setLoader(false)
                setCreateTCDawer(false)
            })
            .catch((err) => {
                console.log(err)
                let errorArr = err?.inner
                let obj = {}
                errorArr.map((err) => {
                    obj[err?.path] = err?.message
                })
                setValidationObj(obj)
            })
    }

    return (
        <NormalDrawer
            openDrawer={createTCDawer}
            setOpenDrawer={setCreateTCDawer}
            anchor={"right"}
            width={"60vw"}
            title={tcForEditDelete?._id ? "Edit Terms & Condition" : "Create Terms & Condition"}
            content={
                <form className={classes.mainDialogCont} onSubmit={onSubmit} >
                    <div className={classes.bodyCont} >
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            label={<>Name<span style={{ color: "red" }}>*</span></>}
                            placeholder="Enter name"
                            autoComplete='off'
                            value={title}
                            fullWidth
                            onChange={(e) => { setTitle(e.target.value); }}
                        />
                        <p className={classes.validationSty} style={{ marginBottom: "15px" }} >{validationObj?.title}</p>
                        <Autocomplete
                            options={allLanguagesName}
                            value={language}
                            onChange={(event, value) => {
                                setLanguage(value)
                            }}
                            size="small"
                            fullWidth
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    size="small"
                                    label={<>Language<span style={{ color: "red" }}>*</span></>}
                                    variant="outlined"
                                    {...params}
                                    placeholder="Select language"
                                />
                            )}
                        />
                        <p className={classes.validationSty} style={{ marginBottom: "15px" }} >{validationObj?.language}</p>

                        <ReactQuill
                            {...other}
                            className='EditorStyle1'
                            value={content}
                            placeholder={"Enter terms and condition"}
                            onChange={setContent}
                            modules={modules}
                            formats={formats}
                            theme='snow'
                        >
                        </ReactQuill>
                    </div>
                    <div style={{ width: "100%", height: "5px" }} >
                        {loader && (<LinearProgress />)}
                    </div>
                    <div className={classes.bottomAct} >
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            type="submit"
                        >
                            Save & Close
                        </Button>
                    </div>
                </form>}
        />
    );
}
