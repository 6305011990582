import React, { useEffect, useState } from "react";
import ListingsTableComponent from "../tables/ListingsTableComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { filterListingApi, updateListingApi } from "../../listing.api";
import { useDispatch, useSelector } from "react-redux";

const MyListings = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [listings, setListings] = useState([]);
  const [listingsLoading, setListingsLoading] = useState(false);
  const [actions, setActions] = useState([
    "Share",
    "Edit",
    "Mark as Closed",
    "Mark as Private",
    "Mark as Archived",
    "View Listing Details",
    "Add Lead",
  ]);

  const getListings = async () => {
    try {
      setListingsLoading(true);

      const { data } = await filterListingApi({
        userProfile: user?.profile,
      });

      setListings(data);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setListingsLoading(false);
    }
  };

  useEffect(() => {
    getListings();
  }, []);

  const handleActionClick = (action, item) => {
    switch (action) {
      case "Share":
        // TODO
        break;

      case "Edit":
        history.push(`/listing/edit/${item?._id}`);
        break;

      case "Mark as Closed":
        updateListing(
          item._id,
          { status: "Closed" },
          "Listing marked as closed"
        );
        break;

      case "Mark as Private":
        updateListing(
          item._id,
          { isPrivate: true },
          "Listing marked as private"
        );
        break;

      case "Mark as Public":
        updateListing(
          item._id,
          { isPrivate: false },
          "Listing marked as public"
        );
        break;

      case "Mark as Archived":
        updateListing(
          item._id,
          { isArchived: true },
          "Listing marked as archived"
        );
        break;

      case "Remove from Archived":
        updateListing(
          item._id,
          { isArchived: false },
          "Listing removed from archive"
        );
        break;

      case "View Listing Details":
        history.push(`/listing/edit/${item?._id}`);
        break;

      case "Add Lead":
        // TODO
        break;
    }
  };

  const updateListing = async (id, listingData, successMessage) => {
    try {
      const { data } = await updateListingApi({
        listingId: id,
        listingData: listingData,
      });
      if (data) {
        getListings();
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: successMessage,
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  return (
    <ListingsTableComponent
      listings={listings}
      loading={listingsLoading}
      actions={actions}
      handleActionClick={handleActionClick}
    />
  );
};

export default MyListings;
