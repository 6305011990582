import React, { useState } from "react";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import ListingsTableComponent from "../listing/common/tables/ListingsTableComponent";
import { useTableBStyles } from "../../utils/style";
import { useDispatch } from "react-redux";
import Api from "../../helpers/Api";
import { useEffect } from "react";
import PrivateListingCreateDrawer from "../listing/listings/private.listing.create.drawer";
import Button from "../styled/generic/Button";
import { Add } from "@material-ui/icons";

const OrgListings = ({ orgId, team }) => {
  const dispatch = useDispatch();
  const [listings, setListings] = useState([]);
  const [listingsLoading, setListingsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const getListingsByOrg = async () => {
    try {
      setListingsLoading(true);
      const { data } = await Api.post(`/listing/getListingsByOrg`, {
        orgId: orgId,
      });
      if (data) {
        setListings(data);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setListingsLoading(false);
    }
  };

  useEffect(() => {
    getListingsByOrg();
  }, []);

  const actions = [];
  const handleActionClick = (action, listing) => {};

  const tableStyle = useTableBStyles();

  return (
    <StandardAppContainerRounded>
      <Button
        startIcon={<Add />}
        variant="outlined"
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Add Listing
      </Button>
      <PrivateListingCreateDrawer
        openDrawer={open}
        setOpenDrawer={setOpen}
        contextModelName={"Organization"}
        context={team?.parent}
        onListingCreated={(data) => {
          setListings([...listings, data]);
        }}
      />
      <ListingsTableComponent
        listings={listings}
        loading={listingsLoading}
        actions={actions}
        handleActionClick={handleActionClick}
        tableStyle={tableStyle}
        enableCheck={false}
      />
    </StandardAppContainerRounded>
  );
};

export default OrgListings;
