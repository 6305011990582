import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { GiCrane } from "react-icons/gi";
import { SlPencil } from "react-icons/sl";
import { IconButton } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Avatar from "@material-ui/core/Avatar";
import Carousel from "react-multi-carousel";
import profileProject from "../../../Assets/profileProject.svg"
import { GiGearHammer } from "react-icons/gi";
import moment from "moment";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import "react-multi-carousel/lib/styles.css";
import "./crad.css"
import LessText from "../../styled/CommonComponents/LessText";

const useStyles = makeStyles((theme) => ({
  paper__section: {
    display: "flex",
    flexDirection: "column",
    fontSize: "1.05rem",
    marginTop: "1rem",
    border: "1px solid white",
    borderRadius: "3px",
    padding: "5px 15px 15px",
    "& $h2": {
      fontWeight: "400",
      fontSize: "1.5rem",
      display: "inline",
    },
    "& p": {
      color: "grey",
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      "& p": {
        width: "100%",
      },
    }
  },
  section__header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h3": {
      fontSize: "20px",
      fontWeight: "600",
      color: "#00345d",
    },
  },
  emptyCont: {
    padding: "10px 0px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "& img": {
      width: "auto",
      height: "130px",
      marginTop: "-40px",
      marginBottom: "-15px"
    }
  },
  allExpEdit: {
    padding: "0px 30px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  singleExperienceCont: {
    display: "flex",
    alignItems: "flex-start",
    padding: "25px 5px"
  },
  imageCont: {
    marginRight: "15px"
  },
  experienceRight: {
    width: "calc(100% - 60px)",
    marginTop: "-4px",
    "& h3": {
      fontSize: "20px",
      fontWeight: "500"
    },
    "& h4": {
      fontSize: "16px",
      fontWeight: "450",
      display: "flex",
      alignItems: "center",
    },
    "& h5": {
      fontSize: "14px",
      fontWeight: "350",
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      "& h5": {
        flexDirection: "column",
        alignItems: "flex-start"
      }
    }
  },
  singleCardMainCont: {
    width: "230px",
    height: "250px",
    borderRadius: "7px"
  },
  proImageCont: {
    width: "100%",
    height: "200px",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  textCont: {
    width: "100%",
    height: "50px",
    padding: "2px 5px",
    backgroundColor: theme.palette.primary.main
  },
  imageTitle: {
    fontSize: "14px",
    fontWeight: "500",
    color: "white"
  },
  imageDec: {
    fontSize: "11px",
    fontWeight: "300",
    color: "white"
  },
  tagCont: {
    fontSize: "14px",
    color: "gray"
  },
  photosCont: {
    width: "100%",
    padding: "10px 5px",
    overflowX: "auto",
    overflowY: "hidden",
    display: "flex",
  }
}));

const colorArr = [
  "#F39014",
  "#0A66C2",
  "#36e055"
]

const ProjectSection = ({
  isOwnProfile,
  profile,
  setShowProject,
  setDialog,
  setOpenEditView,
  setProfileEditView
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);

  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const duration = (startMonth, startYear, isCurrentlyWorking, endMonth, endYeark) => {
    const calendarMonth = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    let endYear = d.getFullYear();
    let endMonthNum = d.getMonth() + 1

    if (!isCurrentlyWorking && endMonth && endYeark) {
      endMonthNum = calendarMonth.indexOf(endMonth) + 1
      endYear = endYeark
    }

    const startMonthNum = calendarMonth.indexOf(startMonth) + 1

    let sDateStr = `1.${startMonthNum}.${startYear}`
    let eDateStr = `1.${endMonthNum}.${endYear}`

    var startDate = moment(sDateStr, "DD.MM.YYYY");
    var endDate = moment(eDateStr, "DD.MM.YYYY");

    var result = endDate.diff(startDate, 'months')

    if (result > 12) {
      return `${parseInt(result / 12)} yr, ${result % 12} mos`
    } else {
      return `${result} mos`
    }
  }

  const responsive = {
    desktop1: {
      breakpoint: { max: 3000, min: 2000 },
      items: 5
    },
    desktop2: {
      breakpoint: { max: 1999, min: 1500 },
      items: 4.3
    },
    tablet1: {
      breakpoint: { max: 1499, min: 1300 },
      items: 3.3
    },
    tablet2: {
      breakpoint: { max: 1299, min: 1100 },
      items: 2.6
    },
    mobile1: {
      breakpoint: { max: 1099, min: 800 },
      items: 2.1
    },
    mobile2: {
      breakpoint: { max: 799, min: 600 },
      items: 1.5
    },
    mobile3: {
      breakpoint: { max: 599, min: 400 },
      items: 1.2
    },
    mobile4: {
      breakpoint: { max: 399, min: 0 },
      items: 1
    },
  };


  return (
    <div>
      {isOwnProfile ? (
        <div>
          <Paper className={classes.paper__section}>
            <div className={classes.section__header}>
              <h3><span><GiCrane style={{ marginBottom: "-2px" }} /></span> Projects</h3>
              <div>
                <IconButton
                  className={classes.editBtn}
                  onClick={() => {
                    setShowProject(true);
                    setDialog("projects");
                  }}
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  className={classes.editBtn}
                  onClick={() => {
                    setProfileEditView("project")
                    setOpenEditView(true)
                  }}
                >
                  <SlPencil />
                </IconButton>
              </div>
            </div>
            {user?.projectExp && user?.projectExp?.length !== 0 ? (
              <div className={classes.allExpEdit} >
                {user?.projectExp.map((project, index) => (
                  <div
                    className={classes.singleExperienceCont}
                    key={index}
                    style={index !== 0 ? { borderTop: "1px solid #e4e7e7" } : {}}
                  >
                    <Avatar
                      variant="square"
                      className={classes.imageCont}
                      style={{ backgroundColor: colorArr[index % 3] }}
                    >
                      <GiGearHammer />
                    </Avatar>
                    <div className={classes.experienceRight}>
                      <h3>{project?.project?.displayName}</h3>
                      <h5>{`lot {project?.project?.lotSize} / area {project?.project?.area} / year ${project?.project?.year} / price ₹${project?.project?.price}`}</h5>
                      <h4>{project?.role}</h4>
                      <h5>
                        <div>{`${project?.startMonth}, ${project?.startYear}  -  ${project?.isCurrentlyWorking ? "Present" : `${project?.endMonth}, ${project?.endYear}`}`}</div>
                        {isSmall ? null : (<FiberManualRecordIcon style={{ width: "5px", height: "5px", margin: "0px 4px" }} />)}
                        <div>{duration(project?.startMonth, project?.startYear, project?.isCurrentlyWorking, project?.endMonth, project?.endYear)}</div>
                      </h5>
                      <h5>{project?.project?.address?.streetAddress}</h5>
                      <p>{project?.desc}</p>
                      {project?.project?.docs && project?.project?.docs.length > 0 && (
                        <div style={{ width: "100%", marginTop: "15px" }} >
                          <Carousel
                            responsive={responsive}
                            infinite={true}
                            swipeable={false}
                            draggable={false}
                            keyBoardControl={true}
                            showDots={false}
                            autoPlaySpeed={1000}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                            containerClass="carousel-container"
                          >
                            {project?.project?.docs.map((file, index) => (
                              <div key={index} style={{ padding: "10px", paddingLeft: "15px" }} >
                                <div className="photoCard">
                                  <div
                                    className="thumb"
                                    style={{ backgroundImage: `url(${file?.files[0]?.url})` }}
                                  ></div>
                                  <div className="infos">
                                    <h2 className="title">{file?.title}</h2>
                                    <div className={classes.tagCont}>{file?.tag}</div>
                                    <p className="txt" style={{ width: "100%", wordBreak: "break-all" }}>
                                      <LessText
                                        limit={350}
                                        string={file?.description}
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Carousel>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (<div className={classes.emptyCont} >
              <img src={profileProject} />
            </div>)}
          </Paper>
        </div>
      ) : (
        <>
          {profile?.parent?.projectExp && profile?.parent?.projectExp?.length !== 0 ? (
            <Paper className={classes.paper__section}>
              <div className={classes.section__header}>
                <h3><span><GiCrane style={{ marginBottom: "-2px" }} /></span> Projects</h3>
                <div></div>
              </div>
              <div className={classes.allExpEdit} >
                {profile?.parent?.projectExp?.map((project, index) => (
                  <div
                    className={classes.singleExperienceCont}
                    key={index}
                    style={index !== 0 ? { borderTop: "1px solid #e4e7e7" } : {}}
                  >
                    <Avatar
                      variant="square"
                      className={classes.imageCont}
                      style={{ backgroundColor: colorArr[index % 3] }}
                    >
                      <GiGearHammer />
                    </Avatar>
                    <div className={classes.experienceRight}>
                      <h3>{project?.project?.displayName}</h3>
                      <h5>{`lot ${project?.project?.lotSize} / area ${project?.project?.area} / year ${project?.project?.year} / price ₹${project?.project?.price}`}</h5>
                      <h4>{project?.role}</h4>
                      <h5>
                        <div>{`${project?.startMonth}, ${project?.startYear}  -  ${project?.isCurrentlyWorking ? "Present" : `${project?.endMonth}, ${project?.endYear}`}`}</div>
                        {isSmall ? null : (<FiberManualRecordIcon style={{ width: "5px", height: "5px", margin: "0px 4px" }} />)}
                        <div>{duration(project?.startMonth, project?.startYear, project?.isCurrentlyWorking, project?.endMonth, project?.endYear)}</div>
                      </h5>
                      <h5>{project?.project?.address?.streetAddress}</h5>
                      <p>{project?.desc}</p>
                      {project?.project?.docs && project?.project?.docs.length > 0 && (
                        <div style={{ width: "100%", marginTop: "15px" }} >
                          <Carousel
                            responsive={responsive}
                            infinite={true}
                            swipeable={false}
                            draggable={false}
                            keyBoardControl={true}
                            showDots={false}
                            autoPlaySpeed={1000}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                            containerClass="carousel-container"
                          >
                            {project?.project?.docs.map((file, index) => (
                              <div key={index} style={{ padding: "10px", paddingLeft: "15px" }} >
                                <div className="photoCard">
                                  <div
                                    className="thumb"
                                    style={{ backgroundImage: `url(${file?.files[0]?.url})` }}
                                  ></div>
                                  <div className="infos">
                                    <h2 className="title">{file?.title}</h2>
                                    <div className={classes.tagCont}>{file?.tag}</div>
                                    <p className="txt" style={{ width: "100%", wordBreak: "break-all" }}>
                                      <LessText
                                        limit={350}
                                        string={file?.description}
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Carousel>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Paper>
          ) : null}
        </>
      )}
    </div>
  );
};

export default ProjectSection;