import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import { useParams } from "react-router-dom";
import AccountSkeleton from "../styled/CommonComponents/Account.Skeleton";
import OrgBothView from "../organization/Org.Both.View";
import OrgBothViewEdit from "../organization/orgEdit/Org.Both.View.Edit";
import AccessControlStoreHook from "../AccessControl/AccessControlStoreHook";
import {
  getParticipantsPermissionsForTeam,
  getPopulatedTeamDataByIdForOrg,
} from "./api.call";
import teamUtils from "../team/team.utils";
import StandardContainer from "../styled/generic/StandardContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 52px)",
      marginTop: "-1px",
    },
  },
  page1: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    // padding: "20px 90px 50px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      padding: "15px 15px 50px",
    },
    [theme.breakpoints.down("xs")]: {
      // padding: "10px 10px 50px",
    },
  },
  page2: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0px",
    left: "0px",
    zIndex: "1000",
    backgroundColor: "white",
  },
}));

const OrgHome = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { handleTeams } = teamUtils;
  const { viewMode, viewedTeamId } = props;
  let { teamId } = useParams();
  teamId = viewMode ? viewedTeamId : teamId;

  const state = useSelector((state) => state);
  const { user } = useSelector((state) => state.auth);
  const { teamDictionary } = useSelector((state) => state.team);
  const { updateAccessByTeam } = AccessControlStoreHook();

  const [profile, setProfile] = useState({});
  const [loader, setLoader] = useState(true);
  const [openEditView, setOpenEditView] = useState(false);
  const [profileEditView, setProfileEditView] = useState("basicInfo");
  const [userRole, setUserRole] = useState("Viewer");
  const [isViewOnlyMode, setIsViewOnlyMode] = useState(true);
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    if (teamId) {
      updateAccessByTeam(teamId)
    }
  }, [teamId])

  //to get participants & permissions from api and initialize the profile
  useEffect(() => {
    setLoader(true);
    getParticipantsPermissionsForTeam({ teamId: teamId })
      .then(async (data) => {
        let locParticipantsRole = data?.participantsRole || [];
        setParticipants(data?.participants || []);
        let curUserRole = "none";

        locParticipantsRole.map((participantsRoleData) => {
          if (participantsRoleData?.user?._id === user?.profile) {
            curUserRole = participantsRoleData?.role || "Viewer";
          }
        });

        setUserRole(curUserRole);

        if (curUserRole === "Owner" || curUserRole === "Admin") {
          if (viewMode) {
            setIsViewOnlyMode(true);
          } else {
            setIsViewOnlyMode(false);
          }
        } else {
          setIsViewOnlyMode(true);
        }

        if (teamDictionary[teamId]) {
          const teamData = teamDictionary[teamId];
          setProfile({
            _id: teamData?.parent?.profile?._id
              ? teamData?.parent?.profile?._id
              : teamData?.parent?.profile,
            parent: teamData?.parent,
            parentModelName: "Organization",
          });
        } else {
          await getPopulatedTeamDataByIdForOrg({ teamId: teamId })
            .then((data) => {
              setProfile({
                _id: data?.parent?.profile?._id
                  ? data?.parent?.profile?._id
                  : data?.parent?.profile,
                parent: data?.parent,
                parentModelName: "Organization",
              });

              setIsViewOnlyMode(true);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [teamId]);

  //to update profile data by listening reduex store
  useEffect(() => {
    if (!isViewOnlyMode) {
      const teamData = teamDictionary[teamId];
      setProfile({
        _id: teamData?.parent?.profile?._id
          ? teamData?.parent?.profile?._id
          : teamData?.parent?.profile,
        parent: teamData?.parent,
        parentModelName: "Organization",
      });
    }
  }, [
    teamDictionary,
    teamDictionary[teamId],
    viewMode,
    teamId,
    teamDictionary[teamId]?.parent,
    teamDictionary[teamId]?.parent?.displayName,
    teamDictionary[teamId]?.parent?.displayPicture,
    teamDictionary[teamId]?.parent?.displayPicture?.url,
    teamDictionary[teamId]?.parent?.cover,
    teamDictionary[teamId]?.parent?.cover?.url,
    teamDictionary[teamId]?.parent?.description,
    teamDictionary[teamId]?.parent?.email,
    teamDictionary[teamId]?.parent?.phone,
    teamDictionary[teamId]?.parent?.address,
    teamDictionary[teamId]?.parent?.tagline,
    teamDictionary[teamId]?.parent?.username,
    teamDictionary[teamId]?.parent?.companyNo,
    teamDictionary[teamId]?.parent?.followerCount,
    teamDictionary[teamId]?.parent?.followingCount,
    teamDictionary[teamId]?.parent?.numberOfProjects,
    teamDictionary[teamId]?.parent?.foundingYear,
    teamDictionary[teamId]?.parent?.website,
    teamDictionary[teamId]?.parent?.companySize,
    teamDictionary[teamId]?.parent?.licenses,
    teamDictionary[teamId]?.parent?.projectExp,
    teamDictionary[teamId]?.parent?.honors,
    teamDictionary[teamId]?.parent?.skills,
    teamDictionary[teamId]?.parent?.skillTags,
    teamDictionary[teamId]?.parent?.markets,
    teamDictionary[teamId]?.parent?.marketsList,
    teamDictionary[teamId]?.parent?.mainTypes,
    teamDictionary[teamId]?.parent?.subTypes,
  ]);

  return (
    <StandardContainer>
      <div className={classes.page1}>
        {loader ? (
          <AccountSkeleton />
        ) : (
          <OrgBothView
            profile={profile}
            isViewOnlyMode={isViewOnlyMode}
            setOpenEditView={setOpenEditView}
            setProfileEditView={setProfileEditView}
            participants={participants}
            teamId={teamId}
            userRole={userRole}
          />
        )}
      </div>
      <Slide direction="left" in={false} mountOnEnter unmountOnExit>
        <div className={classes.page2}>
          <OrgBothViewEdit
            setOpenEditView={setOpenEditView}
            profileEditView={profileEditView}
            setProfileEditView={setProfileEditView}
            profile={profile}
            teamId={teamId}
          />
        </div>
      </Slide>
    </StandardContainer>
  );
};

export default OrgHome;
