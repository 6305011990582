import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { getParticipantsByUser, getParticipantsByUserAndTeam } from './api.call';

export default function AccessControlStoreHook() {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { accessControl } = useSelector((state) => state);

    const getParticipantAccess = async () => {
        dispatch({
            type: "AddAccessControl",
            payload: {
                loader: true
            }
        });
        await getParticipantsByUser({ profileId: user?.profile })
            .then((data) => {
                if (data && data.length > 0) {
                    let teamProfileAccessmap = {}
                    let accessAbleTeams = []
                    let accessAbleProjectTeams = []
                    let accessAbleOrganizationTeams = []
                    let accessAbleProfiles = []
                    let accessAbleProjectProfiles = []
                    let accessAbleOrganizationProfiles = []
                    let teamRoleMap = {}

                    data.map((teamAccess) => {
                        const roleAccess = teamAccess?.permissions || []
                        let accessMap = {}
                        roleAccess.map((access) => {
                            accessMap[access?.identifier] = true;
                            if (access?.fields && access.fields.length > 0) {
                                access.fields.map((field) => {
                                    accessMap[`${access?.identifier}-${field}`] = true;
                                })
                            }
                        })
                        if (teamAccess?.team?.parentModelName === "Project") {
                            accessAbleProjectTeams.push(teamAccess?.team?._id)
                            accessAbleProjectProfiles.push(teamAccess?.team?.parent?.profile)
                        } else if (teamAccess?.team?.parentModelName === "Organization") {
                            accessAbleOrganizationTeams.push(teamAccess?.team?._id)
                            accessAbleOrganizationProfiles.push(teamAccess?.team?.parent?.profile)
                        }
                        teamRoleMap[teamAccess?.team?.parent?.profile] = teamAccess?.mainRole || "Viewer"
                        accessAbleTeams.push(teamAccess?.team?._id)
                        accessAbleProfiles.push(teamAccess?.team?.parent?.profile)
                        teamProfileAccessmap[teamAccess?.team?.parent?.profile] = accessMap;
                    })

                    const payload = {
                        teamAccessMap: teamProfileAccessmap,
                        accessAbleTeams: accessAbleTeams,
                        accessAbleProjectTeams: accessAbleProjectTeams,
                        accessAbleOrganizationTeams: accessAbleOrganizationTeams,
                        accessAbleProfiles: accessAbleProfiles,
                        accessAbleProjectProfiles: accessAbleProjectProfiles,
                        accessAbleOrganizationProfiles: accessAbleOrganizationProfiles,
                        accessRoleMap: teamRoleMap
                    }
                    dispatch({
                        type: "SetAllAccessControl",
                        payload: payload
                    });
                }
                dispatch({
                    type: "AddAccessControl",
                    payload: {
                        loader: false
                    }
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: "AddAccessControl",
                    payload: {
                        loader: false
                    }
                });
            })
    }

    const addACLForTeams = async (accesses) => {
        const {
            teamAccessMap,
            accessAbleTeams,
            accessAbleProjectTeams,
            accessAbleOrganizationTeams,
            accessAbleProfiles,
            accessAbleProjectProfiles,
            accessAbleOrganizationProfiles,
            accessRoleMap
        } = accessControl;

        let locAccessAbleTeams = new Set([...accessAbleTeams])
        let locAccessAbleProjectTeams = new Set([...accessAbleProjectTeams])
        let locAccessAbleOrganizationTeams = new Set([...accessAbleOrganizationTeams])
        let locAccessAbleProfiles = new Set([...accessAbleProfiles])
        let locAccessAbleProjectProfiles = new Set([...accessAbleProjectProfiles])
        let locAccessAbleOrganizationProfiles = new Set([...accessAbleOrganizationProfiles])
        let locAccessMap = JSON.parse(JSON.stringify(teamAccessMap))
        let locAccessRoleMap = JSON.parse(JSON.stringify(accessRoleMap))

        accesses.map((teamAccess) => {
            const roleAccess = teamAccess?.permissions || []
            let accessMap = {}
            roleAccess.map((access) => {
                accessMap[access?.identifier] = true;
                if (access?.fields && access.fields.length > 0) {
                    access.fields.map((field) => {
                        accessMap[`${access?.identifier}-${field}`] = true;
                    })
                }
            })
            if (teamAccess?.team?.parentModelName === "Project") {
                locAccessAbleProjectTeams.add(teamAccess?.team?._id)
                locAccessAbleProjectProfiles.add(teamAccess?.team?.parent?.profile)
            } else if (teamAccess?.team?.parentModelName === "Organization") {
                locAccessAbleOrganizationTeams.add(teamAccess?.team?._id)
                locAccessAbleOrganizationProfiles.add(teamAccess?.team?.parent?.profile)
            }
            locAccessAbleTeams.add(teamAccess?.team?._id)
            locAccessAbleProfiles.add(teamAccess?.team?.parent?.profile)
            locAccessMap[teamAccess?.team?.parent?.profile] = accessMap;
            locAccessRoleMap[teamAccess?.team?.parent?.profile] = teamAccess?.mainRole || "Viewer"
        })

        const payload = {
            teamAccessMap: locAccessMap,
            accessAbleTeams: [...locAccessAbleTeams],
            accessAbleProjectTeams: [...locAccessAbleProjectTeams],
            accessAbleOrganizationTeams: [...locAccessAbleOrganizationTeams],
            accessAbleProfiles: [...locAccessAbleProfiles],
            accessAbleProjectProfiles: [...locAccessAbleProjectProfiles],
            accessAbleOrganizationProfiles: [...locAccessAbleOrganizationProfiles],
            accessRoleMap: locAccessRoleMap
        }
        dispatch({
            type: "AddAccessControl",
            payload: payload
        });
    }

    const updateAccessByTeam = async (teamId) => {
        dispatch({
            type: "AddAccessControl",
            payload: {
                loader: true
            }
        });
        await getParticipantsByUserAndTeam({
            profileId: user?.profile,
            teamId: teamId
        })
            .then(async (data) => {
                await addACLForTeams(data)
                dispatch({
                    type: "AddAccessControl",
                    payload: {
                        loader: false
                    }
                });
            })
            .catch((err) => {
                console.log(err)
                dispatch({
                    type: "AddAccessControl",
                    payload: {
                        loader: false
                    }
                });
            })
    }

    const checkAccess = (profileId, accessType) => {
        const accessMap = accessControl?.teamAccessMap || {};
        const accessRoleMap = accessControl?.accessRoleMap || {};
        if (accessRoleMap[profileId] === "Owner" || (accessMap && accessMap[profileId] && accessMap[profileId][accessType])) {
            return true;
        } else {
            return false
        }
    }

    return {
        addACLForTeams,
        checkAccess,
        getParticipantAccess,
        updateAccessByTeam
    };
}