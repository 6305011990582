import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getContrastColor } from "../../../helpers/colorHelper"

const useStyles = makeStyles((theme) => ({
    singleOptionsCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            padding: "0px !important",
            margin: "0px !important",
            marginRight: "10px",
            marginLeft: "-5px",
            marginTop: "2px",
            fontSize: "20px",
            fontWeight: "400",
        }
    }
}));

export default function HierarchicalAutocomplete({
    options = [], value, lableKey, rootId = "_id", parentId = "parent",
    isMultiple = false, label = null, placeholder = "", onChange, size = "small"
}) {
    const classes = useStyles();
    const theme = useTheme();

    const [allHierarchicalCategories, setAllHierarchicalCategories] = useState([])

    function buildLinearStructure(dataArray) {
        const linearStructure = [];
        const map = new Map();
        dataArray?.forEach(item => map.set(item?.[rootId], item));

        function traverse(id, depth) {
            const item = map.get(id);
            if (item) {
                const children = dataArray.filter(child => child?.[parentId] == id);
                linearStructure.push({ ...item, depth, numChildren: children?.length });
                children.forEach(child => traverse(child?.[rootId], depth + 1));
            }
        }

        dataArray
            .filter(item => (item?.[parentId] == null || item?.[parentId] == undefined))
            .forEach(root => traverse(root?.[rootId], 0));

        return linearStructure;
    }

    // console.log("options", options)

    useEffect(() => {
        if (options?.length > 0) {
            const arr = buildLinearStructure(options);
            setAllHierarchicalCategories(arr)
        }
    }, [options])

    return (
        <Autocomplete
            multiple={isMultiple}
            id="tags-outlined"
            value={value}
            options={allHierarchicalCategories}
            getOptionLabel={(option) => option?.[lableKey]}
            filterSelectedOptions
            size={size}
            renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                    <Chip
                        label={option?.[lableKey]}
                        {...getTagProps({ index })}
                        style={option?.colorCode ? { backgroundColor: option?.colorCode, color: getContrastColor(option?.colorCode) } : {}}
                    />
                ))
            }}
            renderOption={(option, props) => (
                <li {...props} >
                    <div className={classes.singleOptionsCont} style={{ paddingLeft: `${(option?.depth || 0) * 10}px` }} >
                        {option?.numChildren ? (<ExpandMoreIcon />) : (<div style={option?.depth == 0 ? {} : { width: "20px" }} ></div>)}
                        <p>{option?.[lableKey]}</p>
                    </div>
                </li>
            )}
            onChange={onChange}
            style={{ width: "100%" }}
            renderInput={(params) => (<>
                {label ? (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={label}
                        size={size}
                        placeholder="Favorites"
                    />
                ) : (
                    <TextField
                        {...params}
                        variant="outlined"
                        size={size}
                        placeholder={placeholder}
                    />
                )}
            </>)}
        />
    );
}