import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import TableContainer from "../../../styled/generic/TableContainer";
import dayjs from "dayjs";
import { format } from "timeago.js";
import StatusChip from "../../../styled/generic/StatusChip";
import MyPopOver from "../../../styled/CommonComponents/MyPopOver";
import MoreButton from "../../../styled/generic/MoreButton";
import ListingTableDateAgoCell from "../../../styled/generic/ListingTableDateAgoCell";
import ListingTableVisiblityCell from "../../../styled/generic/ListingTableVisiblityCell";
import ListingTableActionCell from "../../../styled/generic/ListingTableActionCell";

const ApplicationsTableComponent = ({
  applications,
  loading,
  actions,
  handleActionClick,
}) => {
  const [columns, setColumns] = useState([
    "LISTING TITLE",
    "LISTING TYPE",
    "APPLIED DATE",
    "MATCHING TYPE",
    "STATUS",
    "ACTIONS",
  ]);

  return (
    <Box sx={{ backgroundColor: "#FFFFFF", minHeight: "400px" }}>
      <TableContainer
        columns={columns}
        data={applications}
        loading={loading}
      >
        {applications?.map((item) => (
          <tr>
            <td style={{ fontWeight: "bold" }}>{item?.title}</td>
            <td style={{ fontWeight: "bold" }}>{item?.type}</td>
            <td>
              <ListingTableDateAgoCell date={item?.createdAt} />
            </td>
            <td>
              <ListingTableVisiblityCell isPrivate={item?.isPrivate} />
            </td>
            <td>
              <StatusChip
                label={item?.approvalStatus}
                variant={
                  item?.approvalStatus === "Approved"
                    ? "Success"
                    : item?.approvalStatus === "Requested"
                    ? "Info"
                    : "Error"
                }
              />
            </td>
            <td onClick={(e) => e.stopPropagation()}>
              <ListingTableActionCell
                cellData={item}
                cellActions={actions}
                cellActionListener={handleActionClick}
              />
            </td>
          </tr>
        ))}
      </TableContainer>
    </Box>
  );
};

export default ApplicationsTableComponent;
