import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import { Paper, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import LessText from "../../styled/CommonComponents/LessText";
import { updateAccessRole } from "../api.call";
import teamUtils from "../../team/team.utils";
import { useMediaQuery } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingLeft: "20px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "365px",
      minHeight: "400px"
    }
  },
  container: {
    width: "49%",
    border: "1px solid #e0e0e0",
    borderTop: "none",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  tbHeaderCell: {
    borderTop: "1px solid #e0e0e0",
    color: "#696969",
    fontSize: "15px",
    padding: "0px",
  },
  tbBodyCell: {
    border: "none",
    padding: "10px 5px"
  },
  nameMailCont: {
    marginLeft: "5px",
    "& h4": {
      fontSize: "14px",
      fontWeight: "510"
    },
    "& p": {
      fontSize: "11px",
      fontWeight: "400",
      color: "gray"
    }
  },
  loaderCont: {
    width: "100%",
    height: "67vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
}));

const TeamMembers = ({ teamId, membersRole, roles, setMembersRole, curUserRole, membersLoading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { handleTeams } = teamUtils;
  const tableContRef = useRef(null);

  const state = useSelector((state) => state);
  const { user } = useSelector((state) => state.auth);
  const { teamDictionary } = useSelector((state) => state.team);

  const [roleUpdateLoader, setRoleUpdateLoader] = useState(false)
  const [roleUpdateLoaderType, setRoleUpdateLoaderType] = useState("even")
  const [loaderWidth, setLoaderWidth] = useState("even")

  const isMdSmall = useMediaQuery(theme.breakpoints.down("md"));

  const updateRole = async (i, value) => {
    setRoleUpdateLoader(true)
    if (i % 2 === 0) {
      setRoleUpdateLoaderType("even")
    } else {
      setRoleUpdateLoaderType("odd")
    }

    let accessRoleObj = membersRole[i]
    let updatedAccessRole = [...membersRole]
    await updateAccessRole({
      _id: accessRoleObj?._id,
      role: value
    })
      .then((data) => {
        updatedAccessRole[i] = data
        setMembersRole(updatedAccessRole)

        let teamDataFromDic = teamDictionary[teamId]
        const team = {
          ...teamDataFromDic,
          participantsRole: updatedAccessRole
        }
        handleTeams([team], state, dispatch);
        setRoleUpdateLoader(false)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    setLoaderWidth(tableContRef?.current?.clientWidth)
  }, [teamId, membersRole, roles, roleUpdateLoader,
    roleUpdateLoaderType, setMembersRole, curUserRole,
    membersLoading])

  return (
    <div className={classes.root} >
      {membersLoading ? (<div className={classes.loaderCont} >
        <CircularProgress />
      </div>) : (<>
        <TableContainer className={classes.container} ref={tableContRef} >
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow style={{ height: "35px" }} >
                <TableCell align="left" className={classes.tbHeaderCell} style={{ padding: "0px", paddingLeft: "7px" }} >
                  Member
                </TableCell>
                <TableCell align="center" className={classes.tbHeaderCell} style={{ width: "120px", padding: "0px" }} >
                  Designation
                </TableCell>
                <TableCell align="right" className={classes.tbHeaderCell} style={{ width: "80px", padding: "0px", paddingRight: "7px" }} >
                  Added
                </TableCell>
              </TableRow>
            </TableHead>
            {roleUpdateLoaderType === "even" && roleUpdateLoader && (<div style={{ width: loaderWidth ? `${loaderWidth}px` : "150%", height: "5px" }} >
              <LinearProgress />
            </div>)}
            <TableBody>
              {membersRole && membersRole.length > 0 && membersRole.map((memberData, index) => {
                if ((isMdSmall && 2 === 2) || (!isMdSmall && index % 2 === 0)) {
                  return (
                    <TableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "15px 0px", paddingLeft: "7px" }} >
                        <div style={{ display: "flex", alignItems: "center" }} >
                          <Avatar src={memberData?.user?.parent?.displayPicture?.url} style={{ width: "30px", height: "30px" }} />
                          <div className={classes.nameMailCont} >
                            <h4><LessText limit={12} string={memberData?.user?.parent?.displayName} /></h4>
                            <p><LessText limit={15} string={memberData?.user?.parent?.email} /></p>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "15px 0px" }} >
                        {curUserRole === "Owner" || curUserRole === "Admin" ? (
                          <div style={{ display: "flex", justifyContent: "end" }} >
                            <Autocomplete
                              id="free-solo-demo"
                              freeSolo
                              value={memberData?.role || ""}
                              options={roles}
                              getOptionLabel={(option) => { return option }}
                              getOptionSelected={(option) => {
                                return option === memberData?.role
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  style={{ margin: "0px" }}
                                  margin="normal"
                                  variant="outlined"
                                  placeholder={"Select role"}
                                />
                              )}
                              onChange={(event, value) => {
                                updateRole(index, value)
                              }}
                              style={{ width: "110px" }}
                              size="small"
                            />
                          </div>
                        ) : (<>{memberData?.role}</>)}
                      </TableCell>
                      <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "15px 0px", fontSize: "12px", paddingRight: "7px", }} >
                        {moment(memberData?.createdAt).format('DD MMM YY')}
                      </TableCell>
                    </TableRow>
                  )
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!isMdSmall && membersRole && membersRole.length > 1 && (
          <TableContainer className={classes.container} ref={tableContRef} >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow style={{ height: "35px" }} >
                  <TableCell align="left" className={classes.tbHeaderCell} style={{ padding: "0px", paddingLeft: "7px" }} >
                    Member
                  </TableCell>
                  <TableCell align="center" className={classes.tbHeaderCell} style={{ width: "120px", padding: "0px" }} >
                    Designation
                  </TableCell>
                  <TableCell align="right" className={classes.tbHeaderCell} style={{ width: "80px", padding: "0px", paddingRight: "7px" }} >
                    Added
                  </TableCell>
                </TableRow>
              </TableHead>
              {roleUpdateLoaderType === "odd" && roleUpdateLoader && (<div style={{ width: loaderWidth ? `${loaderWidth}px` : "150%", height: "5px" }} >
                <LinearProgress />
              </div>)}
              <TableBody>
                {membersRole && membersRole.length > 0 && membersRole.map((memberData, index) => {
                  if (index % 2 !== 0) {
                    return (
                      <TableRow
                        key={index}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "15px 0px", paddingLeft: "7px" }} >
                          <div style={{ display: "flex", alignItems: "center" }} >
                            <Avatar src={memberData?.user?.parent?.displayPicture?.url} style={{ width: "30px", height: "30px" }} />
                            <div className={classes.nameMailCont} >
                              <h4><LessText limit={12} string={memberData?.user?.parent?.displayName} /></h4>
                              <p><LessText limit={15} string={memberData?.user?.parent?.email} /></p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "15px 0px" }} >
                          {curUserRole === "Owner" || curUserRole === "Admin" ? (
                            <div style={{ display: "flex", justifyContent: "end" }} >
                              <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                value={memberData?.role || ""}
                                options={roles}
                                getOptionLabel={(option) => { return option }}
                                getOptionSelected={(option) => {
                                  return option === memberData?.role
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    style={{ margin: "0px" }}
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={"Select role"}
                                  />
                                )}
                                onChange={(event, value) => {
                                  updateRole(index, value)
                                }}
                                style={{ width: "110px" }}
                                size="small"
                              />
                            </div>
                          ) : (<>{memberData?.role}</>)}
                        </TableCell>
                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "15px 0px", fontSize: "12px", paddingRight: "7px", }} >
                          {moment(memberData?.createdAt).format('DD MMM YY')}
                        </TableCell>
                      </TableRow>
                    )
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>)}
    </div>
  );
};

export default TeamMembers;