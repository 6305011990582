import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import currency from "../../../Assets/currency.svg";
import stats from "../../../Assets/stats.svg";
import moneyverse from "../../../Assets/moneyvers.svg";
import group from "../../../Assets/Group.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        padding: "100px 120px 60px",
        [theme.breakpoints.down("lg")]: {
            padding: "100px 80px 60px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "70px 50px 60px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "50px 15px 60px",
            //have to add height

        },
        [theme.breakpoints.down("xs")]: {
            padding: "20px 15px 60px",
            //have to add height

        },
    },
    cardMainCont: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }
    },
    singleCardCont: {
        width: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        marginBottom: "80px",
        [theme.breakpoints.down("md")]: {
            width: "100%"
        },
        [theme.breakpoints.down("sm")]: {
            position: "relative",
        }
    },
    textContRight: {
        width: "calc(100% - 162px)",
        padding: "30px",
        border: "2px solid #2D76E0",
        borderRadius: "12px",
        position: "relative",
        right: "35px",
        "& h3": {
            fontSize: "30px",
            borderBottom: `2px solid ${theme.palette.primary.main}`,
            marginBottom: "10px",
            paddingBottom: "6px"
        },
        "& p": {
            fontSize: "18px",
            fontWeight: "500"
        },
        [theme.breakpoints.down("sm")]: {
            width: "calc(100% - 50px)",
            left: "25px",
            padding: "15px",
            "& h3": {
                fontSize: "25px"
            },
            "& p": {
                fontSize: "14px",
                fontWeight: "500"
            },
        }
    },
    textContLeft: {
        width: "calc(100% - 162px)",
        padding: "30px",
        border: "2px solid #2D76E0",
        borderRadius: "12px",
        position: "relative",
        left: "35px",
        "& h3": {
            fontSize: "30px",
            borderBottom: `2px solid ${theme.palette.primary.main}`,
            marginBottom: "10px",
            paddingBottom: "6px"
        },
        "& p": {
            fontSize: "18px",
            fontWeight: "500"
        },
        [theme.breakpoints.down("sm")]: {
            width: "calc(100% - 50px)",
            left: "-25px",
            padding: "15px",
            "& h3": {
                fontSize: "25px"
            },
            "& p": {
                fontSize: "14px",
                fontWeight: "500"
            },
        }
    },
    titleSty: {
        textAlign: "center",
        fontSize: "35px",
        marginBottom: "90px",
        [theme.breakpoints.down("md")]: {
            fontSize: "32px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "30px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "25px",
            fontWeight: "600"
        },
    },
    leftimgsty: {
        padding: "0px",
        position: "relative",
        left: "35px",
        zIndex: "1",
        backgroundColor: "white",
        "& img": {
            height: "150px",
            width: "auto",
        },
        [theme.breakpoints.down("md")]: {
            "& img": {
                height: "115px",
            },

        },
        [theme.breakpoints.down("sm")]: {
            position: "absolute",
            left: "0px",
            "& img": {
                width: "105px",
                height: "auto"
            },
        }
    },
    rightimgsty: {
        padding: "0px",
        position: "relative",
        right: "35px",
        zIndex: "1",
        backgroundColor: "white",
        "& img": {
            height: "150px",
            width: "auto",
        },
        [theme.breakpoints.down("md")]: {
            "& img": {
                height: "115px",
            },
        },
        [theme.breakpoints.down("sm")]: {
            position: "absolute",
            right: "0px",
            "& img": {
                width: "105px",
                height: "auto"
            },
        }
    }
}));

export default function Advantage({ pageRef }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();


    return (
        <section ref={pageRef} className={classes.root} >
            <h3 className={classes.titleSty} data-aos="fade-down" >RealList Advantage for{" "} <span style={{ color: "#2D76E0", borderBottom: "2px solid #2D76E0" }}>Developers</span></h3>

            <div className={classes.cardMainCont} data-aos="fade-right" >
                <div className={classes.singleCardCont} >
                    <div className={classes.leftimgsty} >
                        <img src={currency} />
                    </div>

                    <div className={classes.textContRight} style={{ paddingLeft: "75px" }} >
                        <h3>Capital</h3>
                        <p>We connect devloper to individual/ and instutional capital who provide debt/ and equity</p>
                    </div>
                </div>

                <div className={classes.singleCardCont} >
                    <div className={classes.leftimgsty} >
                        <img src={stats} />
                    </div>

                    <div className={classes.textContRight} style={{ paddingLeft: "75px" }} >
                        <h3>Technology</h3>
                        <p>We provide developers powerful Real estate OS to manage every stage of project lifecycle</p>
                    </div>
                </div>
            </div>

            <div className={classes.cardMainCont} data-aos="fade-left" >
                <div className={classes.singleCardCont} >
                    <div className={classes.textContLeft} style={{ paddingRight: "75px" }} >
                        <h3>Procurement</h3>
                        <p>We aggregate demand and provide resources on real time need basis.</p>
                    </div>
                    <div className={classes.rightimgsty} >
                        <img src={group} />
                    </div>
                </div>

                <div className={classes.singleCardCont} >
                    <div className={classes.textContLeft} style={{ paddingRight: "75px" }} >
                        <h3>Marketplace</h3>
                        <p>Help developers find customers, landowners, property managers, consultants etc</p>
                    </div>
                    <div className={classes.rightimgsty} >
                        <img src={moneyverse} />
                    </div>
                </div>
            </div>
        </section>
    );
}