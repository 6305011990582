import { Stack } from "@mui/material";
import React from "react";

const HorizBox = ({
  alignItems = "center",
  spacing = 2,
  children,
  ...props
}) => {
  return (
    <Stack direction="row" alignItems={alignItems} spacing={spacing} {...props}>
      {children}
    </Stack>
  );
};

export default HorizBox;
