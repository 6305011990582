import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Api from "../../helpers/Api";
import { IconButton, TextField, Button, Avatar } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CategoryCreateDialog from "../styled/CommonComponents/Category.Create";
import CategoryFilter from "../styled/CommonComponents/Category.Filter";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CreateNewPost from "../styled/CommonComponents/CreateNewPost";
import moment from "moment";
import Drawer from "@material-ui/core/Drawer";
import { getCommunityCategoryCount, joinOrLeaveCommunity, updateCommunityApi } from "./api.call";
import LocationStateFilter from "../styled/CommonComponents/Location.State.Filter";
import DeleteIcon from '@material-ui/icons/Delete';
import CommunityFeeds from "./CommunityFeeds";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import defaultBlogDP from "../../Assets/defaultBlogDP.jpg"
import investmentAnalysis from "../../Assets/investmentAnalysis.png"
import Skeleton from '@material-ui/lab/Skeleton';
import EditIcon from '@material-ui/icons/Edit';
import AboutEditDialog from "./AboutEdit.Dialog";
import ReactHtmlParser from "react-html-parser";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import { FiFilter } from "react-icons/fi";
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
	mainCont: {
		width: "100%",
		height: `calc(100vh - ${theme.appbar.height})`,
		display: "flex",
		justifyContent: "space-between",
		padding: "5px",
		paddingTop: "25px",
		[theme.breakpoints.down('sm')]: {
			padding: "0px",
			paddingTop: "10px",
		},
		[theme.breakpoints.down('xs')]: {
			height: `calc(100vh - 120px)`,
		},
	},
	feedCont: {
		width: "56%",
		height: "100%",
		overflowY: "auto",
		padding: "0px 30px",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		scrollbarWidth: "none",
		[theme.breakpoints.down('md')]: {
			width: "100%",
		},
		[theme.breakpoints.down('sm')]: {
			padding: "0px 10px",
		}
	},
	leftSideCont: {
		width: "23%",
		height: "100%",
		overflowY: "auto",
		paddingLeft: "20px",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		scrollbarWidth: "none",
	},
	rightSideCont: {
		width: "23%",
		height: "100%",
		overflowY: "auto",
		paddingRight: "20px",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		scrollbarWidth: "none",
	},
	titleSty: {
		fontSize: "20px",
		fontWeight: "600",
		color: "#00345D"
	},
	loaderCont: {
		width: "100%",
		borderTop: "1px solid #e3e0e0",
		height: "4px",
	},
	categoryCont: {
		height: "230px",
		width: "100%",
		overflowY: "auto",
		display: "flex",
		alignItems: "flex-start",
		flexDirection: "column",
	},
	filterLocationCont: {
		height: "230px",
		width: "100%",
		overflowY: "auto",
		display: "flex",
		flexDirection: "column",
	},
	categoryTagCont: {
		height: "230px",
		overflowY: "auto"
	},
	aboutCard: {
		borderRadius: "7px",
		boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)",
		backgroundColor: "white",
		position: "relative",
		paddingBottom: "12px",
		marginBottom: "20px",
		position: "relative",
	},
	coverImg: {
		width: "100%",
		height: "100px",
		overflow: "hidden",
	},
	profilePicCont: {
		width: "100%",
		position: "absolute",
		top: "50px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "rgba(0,0,0,0)"
	},
	profilePic: {
		width: "100px",
		height: "100px",
		borderRadius: "50%",
		border: "7px solid white"
	},
	aboutInfoCont: {
		padding: "50px 15px 0px",
		"& h3": {
			textAlign: "center",
			fontSize: "18px",
			marginBottom: "8px"
		},
		"& p": {
			textAlign: "center",
			color: "#181818",
			fontSize: "13px",
		}
	},
	subCardCont: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-around",
		marginTop: "10px",
		"& div": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexDirection: "column",
			"& h3": {
				fontSize: "16px",
				marginBottom: "0px"
			}
		}
	},
	noBorder: {
		border: "none",
	},
	searchText: {
		backgroundColor: "white",
		borderRadius: "12px",
		margin: "0px"
	},
	tagsCont: {
		backgroundColor: "white",
		padding: "0px 0px 10px",
		borderRadius: "7px",
		boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)",
	},
	tagsHead: {
		padding: "7px 10px",
		paddingLeft: "15px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		borderBottom: "1px solid rgba(0,0,0,0.2)",
		"& p": {
			fontSize: "18px",
			fontWeight: "500"
		}
	},
	tagSty: {
		padding: "6px 0px",
		paddingLeft: "15px",
		paddingRight: "10px",
		display: "flex",
		height: "35px",
		alignItems: "center",
		justifyContent: "space-between",
		fontSize: "15px",
		fontWeight: "400",
		"& .MuiSvgIcon-root": {
			display: "none"
		},
		"&:hover .MuiSvgIcon-root": {
			display: "flex"
		}
	},
	editIcon: {
		position: "absolute",
		top: "0px",
		right: "0px",
		backgroundColor: "rgba(255, 255, 255, 0.5)",
		padding: "8px",
		"&:hover": {
			backgroundColor: "white"
		}
	},
	actionBtnCont: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "0px 12px 5px",
		marginBottom: "15px"
	},
	communityShort: {
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
	},
	ditCont: {
		display: "flex",
		width: "190px",
		flexDirection: "column",
		alignItems: "flex-start",
		marginLeft: "7px",
		"& h3": {
			width: "190px",
			overflow: "hidden",
			fontSize: "15px",
			fontWeight: "450"
		},
		"& p": {
			width: "190px",
			maxHeight: "29px",
			overflow: "hidden",
			fontSize: "10px"
		}
	},
	filterCont: {
		display: "flex",
		fontSize: "15px",
		alignItems: "center",
		textTransform: "capitalize"
	},
	drawerPaper: {
		width: "300px",
		height: "100vh",
		padding: "20px 10px 0px",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	arrowCont: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "20px",
		"& p": {
			fontSize: "19px",
			fontWeight: "510"
		}
	},
	iconSty: {
		cursor: "pointer",
	}
}));

function getTomorrowDateTime() {
	var tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	const date = moment(tomorrow).format("YYYY-MM-DDTkk:mm");
	return date;
}

function CommunityPage({ match }) {
	var history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme()

	const { user } = useSelector((state) => state.auth);
	const { createdFileIds } = useSelector((state) => state.file);
	const profile = user?.profile;

	const isMdSmall = useMediaQuery(theme.breakpoints.down("md"));
	const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

	const [haveMasterAccess, setHaveMasterAccess] = useState()
	const [community, setCommunity] = useState(null);
	const [isJoin, setIsJoin] = useState(null);
	const [openCategoryCreate, setOpenCategoryCreate] = useState(false)
	const [openEditAbout, setOpenEditAbout] = useState(false)
	const [changePageState, setChangePageState] = useState(false)

	//api call loader
	const [postCreateLoading, setPostCreateLoading] = useState(false)
	const [joinLoading, setJoinLoading] = useState(false)
	const [getCategoryLoader, setCategoryLoader] = useState(false)

	// filter create related variables
	const [searchTitle, setSearchTitle] = useState("")
	const [locationTags, setLocationTags] = useState([])
	const [filterCategories, setFilterCategories] = useState([])
	const [filterSelectedCategories, setFilterSelectedCategories] = useState([])
	const [categories, setCategories] = useState([])

	// post create related variables
	const [categoriesStr, setCategoriesStr] = useState([])
	const [selectedCategories, setSelectedCategories] = useState([])
	const [newPostCreate, setNewPostCreate] = useState(false)
	const [title, setTitle] = useState("")
	const [description, setDescription] = useState("")
	const [selectedLocationTags, setSelectedLocationTags] = useState(["Florida"])
	const [isPoll, setIsPoll] = useState(false)
	const [pollOptions, setPollOptions] = useState([""]);
	const [pollExireAt, setPollExireAt] = useState(getTomorrowDateTime());
	const [mobileOpen, setMobileOpen] = useState(false);
	const [mobileAboutOpen, setMobileAboutOpen] = useState(false);


	function handleDrawerToggle() {
		setMobileOpen(!mobileOpen);
	}

	function handleAboutDrawerToggle() {
		setMobileOpen(!mobileOpen);
	}

	// edit access
	useEffect(() => {
		const canAccessEverything = community?.userProfile?._id === profile || community?.user?._id === user?._id;
		setHaveMasterAccess(canAccessEverything);
	}, [community, community?.userProfile?._id, community?.user?._id]);

	// get category tag count
	const getTagCount = async () => {
		setCategoryLoader(true)
		await getCommunityCategoryCount({ slug: match.params.communityNameSlug })
			.then((data) => {
				let arr = data[0]?.category_details || []
				arr.sort((a, b) => {
					return b.count - a.count;
				})
				setFilterCategories(arr)
				setCategoryLoader(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	useEffect(() => {
		getTagCount()
	}, [])

	// get community 
	async function getCommunity() {
		try {
			const res = await Api.post("community/get", {
				communitySlug: match.params.communityNameSlug,
				profile: profile,
			});
			setCommunity(res.data);
			setCategories(res?.data?.tags)
			setCategoriesStr(res?.data?.tagStrs)
			if (res?.join?.status == "accepted") {
				setIsJoin(true);
			} else {
				setIsJoin(false);
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getCommunity();
	}, []);

	// join or leave community
	const joinCommunity = async () => {
		setJoinLoading(true)
		joinOrLeaveCommunity({
			user: user?._id,
			profile: profile,
			communityObj: community,
			communityProfile: community?.profile
		})
			.then((data) => {
				if (data?.join) {
					setIsJoin(true);
				} else {
					setIsJoin(false);
				}

				if (data?.data) {
					const updatedCom = {
						...community,
						joinCount: data?.data?.joinCount
					}
					setCommunity(updatedCom)
				}

				setJoinLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	};

	//remove category tag from community 
	const removeCategoryTag = async (removeCat) => {
		let updatedCategories = categories.filter((cat) => cat?._id !== removeCat?._id)
		let categoriesId = new Set([])
		let categoriesStr = new Set([]);

		updatedCategories.map((category) => {
			categoriesId.add(category?._id)
			categoriesStr.add(category?.name)
		})

		let categoriesIdArr = [...categoriesId]
		let categoriesStrArr = [...categoriesStr];

		const obj = {
			...community,
			tagStrs: categoriesStrArr,
			tags: categoriesIdArr,
		};
		await updateCommunityApi(obj)
			.then((data) => {
				setCommunity(data);
				setCategories(data?.tags)
				setCategoriesStr(data?.tagStrs)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	//add category tag in community
	const addCategoryTag = async (newTag) => {
		const updatedCategories = [...categories, newTag]
		let categoriesId = new Set([])
		let categoriesStr = new Set([]);

		updatedCategories.map((category) => {
			categoriesId.add(category?._id)
			categoriesStr.add(category?.name)
		})

		let categoriesIdArr = [...categoriesId]
		let categoriesStrArr = [...categoriesStr];

		const obj = {
			...community,
			tagStrs: categoriesStrArr,
			tags: categoriesIdArr,
		};

		await updateCommunityApi(obj)
			.then((data) => {
				setCommunity(data);
				setCategories(data?.tags)
				setCategoriesStr(data?.tagStrs)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	//publish new post
	const publish = async () => {
		if (community?.profile?._id && title && title.length > 5) {
			setPostCreateLoading(true)
			let postBody = {
				title,
				description,
				published: true,
				profile: profile,
				tagStrs: selectedCategories,
				stateTags: selectedLocationTags,
				parent: community?._id,
				parentModelName: "Community",
				files: [
					...(Array.isArray(createdFileIds) ? createdFileIds : []),
				],
				channels: [community?.profile?._id],
			};

			if (isPoll) {
				postBody.poll = {};
				postBody.poll.options = pollOptions;
				postBody.poll.expireAt = pollExireAt;
				postBody.poll.user = user;
				postBody.poll.profile = user?.profile;
				postBody.poll.parentModelName = "Post";
			}
			await Api.post("post/create", postBody);

			//to add new post in feed
			setChangePageState(!changePageState)

			await getTagCount()
			dispatch({ type: "FileUploadReset" });
			setNewPostCreate(false)
			setSelectedCategories([])
			setTitle("")
			setDescription("")
			setSelectedLocationTags(["Florida"])
			setIsPoll(false)
			setPollOptions([""]);
			setPollExireAt(getTomorrowDateTime());
			setPostCreateLoading(false)
		}
	}

	return (
		<div className={classes.mainCont} >
			{isMdSmall ? null : (
				<div className={classes.leftSideCont} >
					<Accordion
						style={{
							borderRadius: "7px",
							marginBottom: "20px",
							boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)"
						}}
						defaultExpanded
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ height: "50px", minHeight: "0px" }}
						>
							<h3 className={classes.titleSty}>Category</h3>
						</AccordionSummary>
						<div className={classes.loaderCont} >
						</div>
						<AccordionDetails>
							<div className={classes.categoryCont} >
								<CategoryFilter
									selectedCategories={filterSelectedCategories}
									setSelectedCategories={setFilterSelectedCategories}
									categories={filterCategories}
									getCategoryLoader={getCategoryLoader}
								/>
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion
						style={{
							borderRadius: "7px",
							boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)"
						}}
						defaultExpanded
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ height: "50px", minHeight: "0px" }}
						>
							<h3 className={classes.titleSty}>Location</h3>
						</AccordionSummary>
						<div className={classes.loaderCont} >
						</div>
						<AccordionDetails>
							<div className={classes.filterLocationCont} >
								<LocationStateFilter
									locationTags={locationTags}
									setLocationTags={setLocationTags}
								/>
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
			)}
			<div className={classes.feedCont} >
				<div style={{ marginBottom: "15px" }} >
					{newPostCreate ? (<>
						<CreateNewPost
							titlePlaceholder={"Title"}
							descPlaceholder={"What is in your mind"}
							description={description}
							setDescription={setDescription}
							title={title}
							setTitle={setTitle}
							categoriesStr={categoriesStr}
							setCategoriesStr={setCategoriesStr}
							selectedCategories={selectedCategories}
							setSelectedCategories={setSelectedCategories}
							selectedLocationTags={selectedLocationTags}
							setSelectedLocationTags={setSelectedLocationTags}
							isPoll={isPoll}
							setIsPoll={setIsPoll}
							pollOptions={pollOptions}
							setPollOptions={setPollOptions}
							pollExireAt={pollExireAt}
							setPollExireAt={setPollExireAt}
							closeIt={() => { setNewPostCreate(false) }}
							publish={publish}
							postCreateLoading={postCreateLoading}
						/>
					</>) : (
						<TextField
							variant="outlined"
							margin="normal"
							fullWidth
							value={searchTitle}
							onChange={(e) => { setSearchTitle(e.target.value) }}
							id="phoneNumber"
							disableUnderline={false}
							autoFocus
							className={classes.searchText}
							placeholder="Search or create a post..."
							InputProps={{
								startAdornment: (<InputAdornment position="start"><SearchIcon style={{ color: "#7C7C8D" }} /></InputAdornment>),
								endAdornment: (<InputAdornment position="end">
									<Button
										variant="contained"
										color="primary"
										startIcon={<PostAddIcon />}
										disabled={community ? false : true}
										style={{ borderRadius: "15px" }}
										onClick={() => {
											setTitle(searchTitle)
											setNewPostCreate(true)
										}}
									>
										{isExSmall ? "Post" : "New Post"}
									</Button>
								</InputAdornment>),
								classes: { notchedOutline: classes.noBorder }
							}}
						/>
					)}
				</div>
				{isMdSmall ? (
					<div className={classes.actionBtnCont} >
						{community?._id ? (
							<div className={classes.communityShort} onClick={() => { setMobileAboutOpen(true) }} >
								<Avatar src={community?.displayPicture?.thumbUrl || community?.displayPicture?.url || investmentAnalysis} />
								<div className={classes.ditCont} >
									<h3>{community?.displayName || "Add Title"}</h3>
									<p>
										{ReactHtmlParser(community?.description || "<p></p>")}
									</p>
								</div>
							</div>
						) : (
							<div className={classes.communityShort} >
								<Skeleton variant="circle" width={40} height={40} />
								<div className={classes.ditCont} >
									<Skeleton style={{ width: "100%", height: "18px" }} variant="text" />
									<Skeleton style={{ width: "100%", height: "12px" }} variant="text" />
									<Skeleton style={{ width: "100%", height: "12px" }} variant="text" />
								</div>
							</div>
						)}

						{community?._id ? (
							<Button onClick={() => { setMobileOpen(true) }} >
								<div className={classes.filterCont} >
									Filter <FiFilter style={{ marginLeft: "5px" }} />
								</div>
							</Button>
						) : (
							<Skeleton style={{ width: "70px", height: "50px" }} variant="text" />
						)}
					</div>
				) : null}
				<CommunityFeeds
					locationTags={locationTags}
					filterSelectedCategories={filterSelectedCategories}
					searchTitle={searchTitle}
					communityId={community?._id}
					community={community}
					slug={match.params.communityNameSlug}
					setChangePageState={setChangePageState}
					changePageState={changePageState}
				/>
			</div>
			{isMdSmall ? null : (
				<div className={classes.rightSideCont} >
					<div className={classes.aboutCard} >
						{community ? (<>
							{haveMasterAccess && (<>
								<IconButton className={classes.editIcon} onClick={() => { setOpenEditAbout(true) }} >
									<EditIcon />
								</IconButton>
								<AboutEditDialog
									openEditAbout={openEditAbout}
									setOpenEditAbout={setOpenEditAbout}
									community={community}
									setCommunity={setCommunity}
								/>
							</>)}
						</>) : null}
						<div className={classes.coverImg} >
							{community ? (
								<img
									src={community?.cover?.thumbUrl || community?.cover?.url || defaultBlogDP}
									style={{
										width: "100%",
										height: "auto",
										borderTopLeftRadius: "7px",
										borderTopRightRadius: "7px"
									}}
								/>
							) : (
								<Skeleton variant="rect" style={{ width: "100%" }} height={100} />
							)}
						</div>
						<div className={classes.profilePicCont} >
							{community ? (
								<img
									src={community?.displayPicture?.thumbUrl || community?.displayPicture?.url || investmentAnalysis}
									className={classes.profilePic}
								/>
							) : (
								<Skeleton variant="circle" width={100} height={100} />
							)}
						</div>
						<div className={classes.aboutInfoCont} >
							<h3>
								{community ? (<>
									{community?.displayName || "Add Title"}
								</>) : (
									<Skeleton style={{ width: "100%", height: "25px" }} variant="text" />
								)}
							</h3>
							<p>
								{community ? (<>
									{ReactHtmlParser(community?.description || "<p></p>")}
								</>) : (
									<div>
										<Skeleton style={{ width: "100%", height: "18px" }} variant="text" />
										<Skeleton style={{ width: "100%", height: "18px" }} variant="text" />
										<Skeleton style={{ width: "100%", height: "18px" }} variant="text" />
									</div>
								)}
							</p>
							<div className={classes.subCardCont} >
								<div>
									<h3>Members</h3>
									<p>{community ? <>{community?.joinCount}</> : (
										<Skeleton style={{ width: "60px", height: "18px" }} variant="text" />
									)}</p>
								</div>
								<div>
									<h3>Type</h3>
									<p>{community ? (<>
										{community?.communityType}
									</>) : (<>
										<Skeleton style={{ width: "60px", height: "18px" }} variant="text" />
									</>)}</p>
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "10px" }} >
								{community ? (
									<Button
										variant="contained"
										color="primary"
										size="small"
										disabled={joinLoading}
										onClick={joinCommunity}
									>
										{joinLoading ? "Updating..." : isJoin ? "Leave" : "Join"}
									</Button>
								) : (
									<Skeleton style={{ width: "80px", height: "50px" }} variant="text" />
								)}
							</div>
						</div>
					</div>
					<div className={classes.tagsCont} >
						<div className={classes.tagsHead} >
							<h3 className={classes.titleSty} >Tags</h3>
							{haveMasterAccess ? (<>
								<Button
									variant="contained"
									color="primary"
									size="small"
									disabled={community ? false : true}
									startIcon={<AddIcon />}
									onClick={() => { setOpenCategoryCreate(true) }}
								>
									Add Tag
								</Button>
								<CategoryCreateDialog
									openCategoryCreate={openCategoryCreate}
									setOpenCategoryCreate={setOpenCategoryCreate}
									addCategoryTag={addCategoryTag}
								/>
							</>) : (<div></div>)}
						</div>
						<div className={classes.categoryTagCont} >
							{community ? categories && categories.length > 0 && categories.map((cat, i) => (
								<div className={classes.tagSty} key={i} >
									<p>{cat?.name}</p>
									{haveMasterAccess && (
										<DeleteIcon className={classes.deleteIcon} onClick={() => { removeCategoryTag(cat) }} />
									)}
								</div>
							)) : (<div style={{ padding: "15px" }} >
								<Skeleton style={{ width: "100%", height: "30px" }} variant="text" />
								<Skeleton style={{ width: "100%", height: "30px" }} variant="text" />
								<Skeleton style={{ width: "100%", height: "30px" }} variant="text" />
								<Skeleton style={{ width: "100%", height: "30px" }} variant="text" />
								<Skeleton style={{ width: "100%", height: "30px" }} variant="text" />
								<Skeleton style={{ width: "100%", height: "30px" }} variant="text" />
							</div>)}
						</div>
					</div>
				</div>
			)}


			<Drawer
				variant="temporary"
				anchor={"right"}
				open={mobileOpen}
				onClose={handleDrawerToggle}
				classes={{
					paper: classes.drawerPaper
				}}
				ModalProps={{
					keepMounted: true
				}}
			>
				<div className={classes.arrowCont} >
					<p>Filter</p>
					<CancelIcon
						className={classes.iconSty}
						onClick={() => { setMobileOpen(false) }}
					/>
				</div>
				<div>
					<Accordion
						style={{
							borderRadius: "7px",
							marginBottom: "20px",
							boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)"
						}}
						defaultExpanded
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ height: "50px", minHeight: "0px" }}
						>
							<h3 className={classes.titleSty}>Category</h3>
						</AccordionSummary>
						<div className={classes.loaderCont} >
						</div>
						<AccordionDetails>
							<div className={classes.categoryCont} >
								<CategoryFilter
									selectedCategories={filterSelectedCategories}
									setSelectedCategories={setFilterSelectedCategories}
									categories={filterCategories}
									getCategoryLoader={getCategoryLoader}
								/>
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion
						style={{
							borderRadius: "7px",
							boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)"
						}}
						defaultExpanded
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							style={{ height: "50px", minHeight: "0px" }}
						>
							<h3 className={classes.titleSty}>Location</h3>
						</AccordionSummary>
						<div className={classes.loaderCont} >
						</div>
						<AccordionDetails>
							<div className={classes.filterLocationCont} >
								<LocationStateFilter
									locationTags={locationTags}
									setLocationTags={setLocationTags}
								/>
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
			</Drawer>

			<Drawer
				variant="temporary"
				anchor={"right"}
				open={mobileAboutOpen}
				onClose={handleAboutDrawerToggle}
				classes={{
					paper: classes.drawerPaper
				}}
				ModalProps={{
					keepMounted: true
				}}
			>
				<div className={classes.arrowCont} >
					<p>About</p>
					<CancelIcon
						className={classes.iconSty}
						onClick={() => { setMobileAboutOpen(false) }}
					/>
				</div>
				<div>
					<div className={classes.aboutCard} >
						{community ? (<>
							{haveMasterAccess && (<>
								<IconButton className={classes.editIcon} onClick={() => { setOpenEditAbout(true) }} >
									<EditIcon />
								</IconButton>
								<AboutEditDialog
									openEditAbout={openEditAbout}
									setOpenEditAbout={setOpenEditAbout}
									community={community}
									setCommunity={setCommunity}
								/>
							</>)}
						</>) : null}
						<div className={classes.coverImg} >
							<img
								src={community?.cover?.thumbUrl || community?.cover?.url || defaultBlogDP}
								style={{
									width: "100%",
									height: "auto",
									borderTopLeftRadius: "7px",
									borderTopRightRadius: "7px"
								}}
							/>
						</div>
						<div className={classes.profilePicCont} >
							<img
								src={community?.displayPicture?.thumbUrl || community?.displayPicture?.url || investmentAnalysis}
								className={classes.profilePic}
							/>
						</div>
						<div className={classes.aboutInfoCont} >
							<h3>
								{community?.displayName || "Add Title"}
							</h3>
							<p>
								{ReactHtmlParser(community?.description || "<p></p>")}
							</p>
							<div className={classes.subCardCont} >
								<div>
									<h3>Members</h3>
									<p>{community?.joinCount}</p>
								</div>
								<div>
									<h3>Type</h3>
									<p>{community?.communityType}</p>
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "10px" }} >
								<Button
									variant="contained"
									color="primary"
									size="small"
									disabled={joinLoading}
									onClick={joinCommunity}
								>
									{joinLoading ? "Updating..." : isJoin ? "Leave" : "Join"}
								</Button>
							</div>
						</div>
					</div>
					<div className={classes.tagsCont} >
						<div className={classes.tagsHead} >
							<h3 className={classes.titleSty} >Tags</h3>
							{haveMasterAccess ? (<>
								<Button
									variant="contained"
									color="primary"
									size="small"
									disabled={community ? false : true}
									startIcon={<AddIcon />}
									onClick={() => { setOpenCategoryCreate(true) }}
								>
									Add Tag
								</Button>
								<CategoryCreateDialog
									openCategoryCreate={openCategoryCreate}
									setOpenCategoryCreate={setOpenCategoryCreate}
									addCategoryTag={addCategoryTag}
								/>
							</>) : (<div></div>)}
						</div>
						<div className={classes.categoryTagCont} >
							{categories && categories.length > 0 && categories.map((cat, i) => (
								<div className={classes.tagSty} key={i} >
									<p>{cat?.name}</p>
									{haveMasterAccess && (
										<DeleteIcon className={classes.deleteIcon} onClick={() => { removeCategoryTag(cat) }} />
									)}
								</div>
							))}
						</div>
					</div>
				</div>
			</Drawer>
		</div>
	);
}
export default CommunityPage;