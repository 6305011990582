import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { IconButton, useMediaQuery, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import ClearIcon from "@material-ui/icons/Clear";
import LinearProgress from "@material-ui/core/LinearProgress";
import AddIcon from "@material-ui/icons/Add";
import ProjectEditSingle from "./Single.Project.Edit";
import ProjectDialog from "../Dialogs/ProjectDialog";

const useStyles = makeStyles((theme) => ({
    header: {
        height: "60px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #e4e7e7",
        padding: "0px 25px",
        "& h3": {
            fontSize: "17px",
            fontWeight: "450",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0px 10px",
        },
    },
    body: {
        width: "100%",
        height: "calc(100% - 65px)",
        overflowY: "auto",
        paddingTop: "20px",
        paddingLeft: "40px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        scrollbarWidth: "none",
        [theme.breakpoints.down("md")]: {
            padding: "15px 15px 30px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "15px 10px 30px",
        },
    },
}));

export default function ExprojectEdit({ setOpenEditView, profile, teamId }) {
    const classes = useStyles();
    const theme = useTheme();

    const auth = useSelector((state) => state.auth);
    const { teamDictionary } = useSelector((state) => state.team);

    const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

    const [expProjects, setExpProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showProject, setShowProject] = useState(false);

    useEffect(() => {
        let teamDataFromDic = teamDictionary[teamId]
        setExpProjects(teamDataFromDic?.parent?.projectExp);
    }, [teamDictionary[teamId], teamDictionary[teamId]?.projectExp]);


    return (
        <>
            <div className={classes.header}>
                <h3>Edit Project</h3>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setShowProject(true);
                        }}
                        size={isSmall ? "small" : "medium"}
                    >
                        Add
                    </Button>
                    <IconButton
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                            setOpenEditView(false);
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </div>
            </div>
            <div style={{ height: "5px" }}>{loading && <LinearProgress />}</div>
            <div className={classes.body}>
                {expProjects &&
                    expProjects.length > 0 &&
                    expProjects.map((project, i) => (
                        <ProjectEditSingle
                            project={project}
                            loading={loading}
                            setLoading={setLoading}
                            index={i}
                            key={i}
                            teamId={teamId}
                            profile={profile}
                        />
                    ))}
            </div>
            <ProjectDialog
                open={showProject}
                setOpen={setShowProject}
                profile={profile}
                teamId={teamId}
            />
        </>
    );
}
