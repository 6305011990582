import prodClientJson from "./client.json";
import devClientJson from "./dev.client.json";
// mode = prod , dev,
const mode = "prod";
// server = local, cloud
const server = "cloud";

// frontEnd = local, cloud
const frontend = "cloud";

const clientJson = mode == "dev" ? devClientJson : prodClientJson;

/**
 * Change above configuration and client.json files for production and development
 * behaviors
 */

var apiUrl = "https://api.reallist.ai/api/";
var serverUrl = "https://api.reallist.ai/";
var BASE_URL = "https://reallist.ai/";
var dwollaWebapp = "https://dwolla.reallist.ai/";
var landingPageMailID = "";
var googleOneTapClientId =
  "856711131829-40tphe43trdfrtvp6sgf0lgp2liouqk1.apps.googleusercontent.com";
// where is server running

if (mode == "dev") {
  googleOneTapClientId =
    "671558970982-9bm0o6follagg1ulfqrbgbo39dcu44ac.apps.googleusercontent.com";
}

if (server == "cloud") {
  if (mode == "dev") {
    apiUrl = "https://devapi.reallist.ai/api/";
    serverUrl = "https://devapi.reallist.ai/";
  } else {
    apiUrl = "https://api.reallist.ai/api/";
    serverUrl = "https://api.reallist.ai/";
  }
} else if (server == "local") {
  apiUrl = "http://localhost:5555/api";
  serverUrl = "http://localhost:5555/";
}

// where is frontend running?

if (frontend == "cloud") {
  if (mode == "dev") {
    dwollaWebapp = "https://devdwolla.contractflo.com/";
  } else {
    dwollaWebapp = "https://dwolla.contractflo.com/";
  }
} else if (frontend == "local") {
  dwollaWebapp = "http://localhost:3001/";
}

if (mode == "dev") {
  BASE_URL = "http://localhost:3000/";
} else {
  BASE_URL = "https://reallist.ai/";
}

// mail ids

if (frontend == "cloud") {
  if (mode == "dev") {
    landingPageMailID = "team@reallist.ai";
  } else {
    landingPageMailID = "team@reallist.ai";
  }
} else if (frontend == "local") {
  landingPageMailID = "team@reallist.ai";
}

const tutorialLinks = {
  brrrrLink: "https://www.contractflo.com/blog/public/63b42f2403e6abed354f8138",
  rentalLink:
    "https://www.contractflo.com/blog/public/63b2ee84be77ff381190b093",
  flipLink: "https://www.contractflo.com/blog/public/63b5467103e6ab0f404f8678",
};

var configObject = {
  googleOneTapClientId,
  apiUrl,
  landingPageMailID,
  serverUrl,
  tutorialLinks,
  BASE_URL,
  mode,
  dwollaWebapp,
  ...clientJson,
};

export default configObject;
