import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import { useDispatch } from "react-redux";
import Api from "../../helpers/Api";
import ListingsTableComponent from "../listing/common/tables/ListingsTableComponent";
import { useTableBStyles } from "../../utils/style";
import PrivateListingCreateDrawer from "../listing/listings/private.listing.create.drawer";
import { Button } from "@material-ui/core";

const ProjectHomeListings = ({ team, projectId }) => {
  const dispatch = useDispatch();
  const [listings, setListings] = useState([]);
  const [listingsLoading, setListingsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const getListingsByProject = async () => {
    try {
      setListingsLoading(true);
      const { data } = await Api.post(`/listing/getListingsByProject`, {
        projectId: team?.parent?._id ?? projectId,
      });
      if (data) {
        setListings(data);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setListingsLoading(false);
    }
  };

  useEffect(() => {
    getListingsByProject();
  }, []);

  const actions = [];
  const handleActionClick = (action, listing) => {};

  const tableStyle = useTableBStyles();

  return (
    <StandardAppContainerRounded>
      <Button
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
        sx={{
          marginBottom: "0.5rem",
        }}
      >
        Add Listing
      </Button>
      <PrivateListingCreateDrawer
        openDrawer={open}
        setOpenDrawer={setOpen}
        contextModelName={"Project"}
        context={team?.parent}
        onListingCreated={(data) => {
          console.log(data);
          setListings([...listings, data]);
        }}
      />
      <ListingsTableComponent
        listings={listings}
        loading={listingsLoading}
        actions={actions}
        handleActionClick={handleActionClick}
        tableStyle={tableStyle}
        enableCheck={false}
      />
    </StandardAppContainerRounded>
  );
};

export default ProjectHomeListings;
