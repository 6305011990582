import { Box } from "@mui/material";
import React from "react";

const MenuOverlay = ({ children, menuItems }) => {
  return (
    <Box sx={{ position: "relative" }}>
      {children}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ position: "absolute", top: 0, p: 2, zIndex: 999, width: "100%" }}
      >
        {menuItems?.map((item) => {
          return <span style={{ marginLeft: "10px" }}>{item.component}</span>;
        })}
      </Box>
    </Box>
  );
};

export default MenuOverlay;
