import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    inputCont: {
        padding: "10px",
        "& h3": {
            fontSize: "14px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "400",
            color: "red"
        }
    }
}));

export default function InputWrapper({
    inputCom, label, validation, isRequired=false, space = 12
}) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    return (
        <div className={classes.inputCont} style={{ width: `calc(100% / (12/${space}))` }} >
            <h3>{label}{isRequired && (<span style={{ color: "red" }} >*</span>)}</h3>
            {inputCom}
            <p>{validation}</p>
        </div>
    );
}