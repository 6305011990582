import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import RequirementsTableComponent from "../tables/RequirementsTableComponent";

const MyRequirements = () => {
  const [requirements, setRequirements] = useState([]);
  const [requirementsLoading, setRequirementsLoading] = useState(false);

  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const getRequirements = async () => {
    try {
      setRequirementsLoading(true);
      const { data } = await Api.post("/listing/requirements/get-all", {
        owner: user?.profile,
      });
      setRequirements(data);
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get requirement",
        },
      });
    } finally {
      setRequirementsLoading(false);
    }
  };

  useEffect(() => {
    getRequirements();
  }, []);

  const actions = ["Edit", "Delete"];

  const handleActionClick = async (action, item) => {
    switch (action) {
      case "Edit":
        history.push(`/listing/requirement/${item?._id}`);
        break;
    }
  };

  return (
    <RequirementsTableComponent
      requirements={requirements}
      loading={requirementsLoading}
      actions={actions}
      handleActionClick={handleActionClick}
    />
  );
};

export default MyRequirements;
