const { default: Api } = require("../../helpers/Api");

//CRUD for User Description Section

export const updateUserInfo = async (userData) => {
  try {
    const updatedData = await Api.post("/user/updateInfo", userData);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserInfoArray = async (userData) => {
  try {
    const updatedData = await Api.post("/user/updateInfoArray", userData);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchUserInfo = async (userData) => {
  try {
    const userInfo = await Api.post("/user/fetchUserInfo", userData);
    return userInfo.data;
  } catch (error) {
    console.log(error);
  }
};

export const editUserArray = async (userData) => {
  try {
    const updatedData = await Api.post("/user/edit/array", userData);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteArrayItem = async (userData) => {
  try {
    const updatedData = await Api.post("/user/delete/array", userData);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const saveProjectInfo = async (projectData) => {
  try {
    const updatedData = await Api.post("/user/update/project", projectData);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};
export const deleteProjectPic = async (projectData) => {
  try {
    const updatedData = await Api.post("/user/delete/projectPic", projectData);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};


export const addProfileSectionApiHit = async (obj) => {
  try {
    const updatedData = await Api.post("user/profile/section/add", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};


export const addExpProjectHitApi = async (obj) => {
  try {
    const updatedData = await Api.post("public/project/create", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateRootProjectForPublicProject = async (obj) => {
  try {
    const updatedData = await Api.post("public/project/update/root-project", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateExpProjectForPublicProject = async (obj) => {
  try {
    const updatedData = await Api.post("public/project/update/exp-project", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateExpProjectAndRootProjectForPublicProject = async (obj) => {
  try {
    const updatedData = await Api.post("public/project/update/exp-project/root-project", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const addFilesToPublicProject = async (obj) => {
  try {
    const updatedData = await Api.post("public/project/addFiles", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateDoc = async (obj) => {
  try {
    const updatedData = await Api.post("doc/update/by-id", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const removeFiles = async (obj) => {
  try {
    const updatedData = await Api.post("public/project/removeFile", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const getFollowerAndFollowingsApiHit = async (obj) => {
  try {
    const updatedData = await Api.post("get/follower/following", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const isFollowingsApiHit = async (obj) => {
  try {
    const updatedData = await Api.post("follow/isfollowing", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const followUnfollowApiHit = async (obj) => {
  try {
    const updatedData = await Api.post("follow/follow", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const deletePublicProject = async (obj) => {
  try {
    const updatedData = await Api.post("public/project/delete/by-id", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserForMainSearch = async (obj) => {
  try {
    const updatedData = await Api.post("search/people/by-filter", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUsersSkillsForFilter = async (obj) => {
  try {
    const updatedData = await Api.post("get/users/skills/for-filter", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const getFollowersAndFollowingsId = async (obj) => {
  try {
    const updatedData = await Api.post("follow/followers-followings/id-only", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateFileFlag = async (fileData) => {
  try {
    await Api.post("/user/updateFileDelete", fileData);
  } catch (error) {
    console.log(error);
  }
};
