import configObject from "../../../config";

function getLink(path) {
    if (configObject.mode === 'prod') {
        return `https://planning.reallist.ai${path}`;
    }
    return path; // For dev mode or any other mode, return the path as is
}

const Planning = {
 
        'CheckpointObservationCreated': (activity) => ({
            name: 'Checkpoint Observation Created',
            link: getLink(`/observation/preview/${activity?.parent?._id}`)
        }),
        'CheckpointObservationUpdated': (activity) => ({
            name: 'Checkpoint Observation Updated',
            link: getLink(`/observation/preview/${activity?.parent?._id}`)
        }),
        'TaskObservationCreated': (activity) => ({
            name: 'Task Observation Created',
            link: getLink(`/observation/preview/${activity?.parent?._id}`)
        }),
        'CheckpointCompleted': (activity) => ({
            name: 'Checkpoint Completed',
            link: getLink(`/planning/task/${activity.tags.find(tag => tag.dataModel === 'WBS')?.data}?tab=Checkpoints`)
        }),

        'TaskCompleted': (activity) => ({
            name: 'Task Completed',
            link: getLink(`/planning/checkpoint-observation/${activity?.parent?._id}`)
        }),
        'TaskUpdated': (activity) => ({
            name: 'Task Updated',
            link: getLink(`/planning/checkpoint-observation/${activity?.parent?._id}`)
        }),
        'TaskCreated': (activity) => ({
            name: 'Task Created',
            link: getLink(`/planning/checkpoint-observation/${activity?.parent?._id}`)
        })
}

export default Planning;
