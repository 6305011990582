import { Switch } from "react-router-dom";
import { IoBusOutline } from "react-icons/io5";
import { IoRestaurantOutline } from "react-icons/io5";
import { BsTrainFront, BsHospital } from "react-icons/bs";
import { LuSchool } from "react-icons/lu";
import { FaUniversity } from "react-icons/fa";
import { IoWaterOutline } from "react-icons/io5";
import { IoCarOutline } from "react-icons/io5";
import { BiCctv } from "react-icons/bi";
import { MdLocalAirport } from "react-icons/md";
import { MdOutlineStoreMallDirectory } from "react-icons/md";
import { FaPowerOff } from "react-icons/fa6";
import { FaWifi } from "react-icons/fa";
import { GiGymBag } from "react-icons/gi";
import { TbAirConditioningDisabled } from "react-icons/tb";
import { FaSwimmer } from "react-icons/fa";
import { FaGasPump } from "react-icons/fa";
import { GiGrass } from "react-icons/gi";
import { FaFireExtinguisher } from "react-icons/fa";
import { GrElevator } from "react-icons/gr";
import { MdOutlineSportsBasketball } from "react-icons/md";

const PROXIMITY_OPTIONS = [
  {
    title: "Hospital",
    icon: BsHospital,
  },
  {
    title: "Airport",
    icon: MdLocalAirport,
  },
  {
    title: "Shopping Mall",
    icon: MdOutlineStoreMallDirectory,
  },
  {
    title: "Bus Stop",
    icon: IoBusOutline,
  },
  {
    title: "Restaurants",
    icon: IoRestaurantOutline,
  },
  {
    title: "Railway Station",
    icon: BsTrainFront,
  },
  {
    title: "School",
    icon: LuSchool,
  },
  {
    title: "University",
    icon: FaUniversity,
  },
];

const AMENITY_OPTIONS = [
  {
    title: "Water Supply",
    icon: IoWaterOutline,
  },
  {
    title: "Parking",
    icon: IoCarOutline,
  },
  {
    title: "Security",
    icon: BiCctv,
  },
  {
    title: "Power Backup",
    icon: FaPowerOff,
  },
  {
    title: "Wifi",
    icon: FaWifi,
  },
  {
    title: "Gym",
    icon: GiGymBag,
  },
  {
    title: "Air conditioner",
    icon: TbAirConditioningDisabled,
  },
  {
    title: "Swimming Pool",
    icon: FaSwimmer,
  },
  {
    title: "Gas Station",
    icon: FaGasPump,
  },
  {
    title: "Park",
    icon: GiGrass,
  },
  {
    title: "Fire Safety",
    icon: FaFireExtinguisher,
  },
  {
    title: "Elevator",
    icon: GrElevator,
  },
  {
    title: "Play Area",
    icon: MdOutlineSportsBasketball,
  },
];

const UNIT_OPTIONS = {
  "classifications": ["number", "area", "length", "volume", "mass"],
  "units": {
    "area": [
      { "unit": "sqm", "name": "square meter" },
      { "unit": "sqmm", "name": "square millimeter" },
      { "unit": "sqcm", "name": "square centimeter" },
      { "unit": "sqft", "name": "square feet" },
      { "unit": "sqin", "name": "square inch" },
      { "unit": "sqyd", "name": "square yard" }
    ],
    "length": [
      { "unit": "m", "name": "meter" },
      { "unit": "mm", "name": "millimeter" },
      { "unit": "cm", "name": "centimeter" },
      { "unit": "ft", "name": "feet" },
      { "unit": "rft", "name": "running feet" },
      { "unit": "in", "name": "inch" },
      { "unit": "yd", "name": "yard" },
      { "unit": "km", "name": "kilometer" }
    ],
    "volume": [
      { "unit": "cum", "name": "cubic meter" },
      { "unit": "cucm", "name": "cubic centimeter" },
      { "unit": "cft", "name": "cubic feet" },
      { "unit": "gal", "name": "gallon" },
      { "unit": "KL", "name": "kiloliter" },
      { "unit": "L", "name": "liter" },
      { "unit": "q.", "name": "quintal" }
    ],
    "mass": [
      { "unit": "mg", "name": "milligram" },
      { "unit": "kg", "name": "kilogram" },
      { "unit": "lb", "name": "pound" },
      { "unit": "oz", "name": "ounce" },
      { "unit": "T", "name": "tonne" }
    ],
    "number": [
      { "unit": "nos", "name": "numbers" },
      { "unit": "bags", "name": "bags" },
      { "unit": "box", "name": "box" },
      { "unit": "ct", "name": "catridge" },
      { "unit": "doz", "name": "Dozen" },
      { "unit": "each", "name": "each" },
      { "unit": "pk", "name": "pack" }
    ],
    "percentage": [
      { "unit": "%", "name": "percent" }
    ]
  }
}




const AMENITY_OPTIONS_TEXT_ARR = [
  "Water Supply",
  "Security",
  "Power Backup",
  "Wifi",
  "Gym",
  "Air conditioner",
  "Swimming Pool",
  "Gas Station",
  "Park",
  "Fire Safety",
  "Elevator",
  "Play Area",
];

const UNIT_ARRAY = [
  "Meter",
  "Item",
  "Foot",
  "Square Meter",
  "Square Foot",
  "Cubic Meter",
  "Cubic Foot",
  "Kilogram",
  "Number",
  "Bags",
  "Hour",
  "Labour",
  "Liter",
  "Lumpsum",
  "Job",
  "Day",
  "Month",
  "Tonne",
  "Yard",
  "Acre",
  "Inch",
  "Week",
  "Square Yard",
  "Cubic Yard",
  "Gallon",
  "Barrel",
  "Pound",
  "Point",
  "Packet",
  "Box",
  "Carton",
  "Unit",
  "Sample",
  "Cubic Yard",
  "Square Foot",
  "Pounds",
  "Linear Foot",
  "Each",
  "Cubic Foot",
  "Sheet",
  "Pack",
  "Roll",
  "Brass",
];

const FLOOR_OPTIONS = [
  { title: "Ground Floor", value: 0 },
  { title: "1st Floor", value: 1 },
  { title: "2nd Floor", value: 2 },
  { title: "3rd Floor", value: 3 },
  { title: "5th or More", value: -1 },
];

const FURNISHING_OPTIONS = [
  "Fully Furnished",
  "Semi Furnished",
  "Un Furnished",
];

const ASSET_OPTIONS = {
  type: [
    "Hardware",
    "Software",
    "Haevy Equipment"
  ],
  condition: [
    "New",
    "Good",
    "Fair",
    "Needs Repair"
  ],
  location: [
    "Office",
    "Construction site",
    "Warehouse/Main Office",
    "Third Party",
    "Unknown"
  ],
  category: [
    "Bank Accounts",
    "Brand and Reputation",
    "Building Materials",
    "Buildings",
    "Cash",
    "Compliance Documentation",
    "Computers and Servers",
    "Furniture",
    "Hand Tools",
    "Heavy Machinery",
    "Intellectual Property",
    "Investments",
    "Land",
    "Leased Equipment or Vehicles",
    "Power Tools",
    "Roads and Bridges",
    "Safety Equipment",
    "Software Licenses",
    "Spare Parts",
    "Supplies",
    "Utilities",
    "Vehicles",
  ],
  events: [
    "Move (between site)",
    "Assign (between people)",
    "Under Repair",
    "Lost",
    "Expired",
    "Sold"
  ],
  eventTypes: [
    {
      lable: "Move (between site)",
      value: "move",
      status: "moved",
    },
    {
      lable: "Assign (between people)",
      value: "assign",
      status: "assigned",
    },
    {
      lable: "Under Repair",
      value: "repair",
      status: "repair",
    },
    {
      lable: "Lost",
      value: "lost",
      status: "lost",
    },
    {
      lable: "Expired",
      value: "expired",
      status: "expired",
    },
    {
      lable: "Sold",
      value: "sold",
      status: "sold",
    }
  ],
  status: [
    "unassigned",
    "moved",
    "assigned",
    "repair",
    "lost",
    "expired",
    "sold"
  ],
}

const TOTAL_ACL_PERMISION = 251;

export {
  PROXIMITY_OPTIONS,
  AMENITY_OPTIONS,
  UNIT_OPTIONS,
  UNIT_ARRAY,
  AMENITY_OPTIONS_TEXT_ARR,
  FLOOR_OPTIONS,
  FURNISHING_OPTIONS,
  ASSET_OPTIONS,
  TOTAL_ACL_PERMISION
};