import React, { useEffect, useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../../helpers/Api";
import { Divider } from "@material-ui/core";

export const unitTypes = [
  "1BHK",
  "2BHK",
  "3BHK",
  "4BHK",
  "5BHK",
  "6BHK",
  "7BHK",
];

const CreateProjectBlockDrawer = ({
  open,
  setOpen,
  projectId,
  setProjectBlocks,
  setRentalUnitCount,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);

  const [blockName, setBlockName] = useState("");
  const [floorNumber, setFloorNumber] = useState(0);
  const [description, setDescription] = useState("");
  const [floorData, setFloorData] = useState([]);
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setBlockName("");
    setFloorNumber(0);
    setDescription("");
    setFloorData([]);
  };

  useEffect(() => {
    resetForm();
  }, [open]);

  useEffect(() => {
    createFloorsAndUnits();
  }, [floorNumber]);

  const createFloorsAndUnits = () => {
    if (floorNumber <= 0) return;
    let newAry = [];
    for (let i = 0; i < floorNumber; i++) {
      newAry.push({
        floor: i,
        units: [{ unitType: "2BHK", unitValue: 0 }],
      });
    }
    setFloorData(newAry);
  };

  const handleAddUnit = (floorIndex) => {
    const newFloorData = [...floorData];
    newFloorData[floorIndex]?.units?.push({ unitType: "2BHK", unitValue: 0 });
    setFloorData(newFloorData);
  };

  const handleUnitTypeChange = (floorIndex, unitIndex, value) => {
    const newFloorData = [...floorData];
    if (newFloorData[floorIndex]?.units?.[unitIndex]) {
      newFloorData[floorIndex].units[unitIndex].unitType = value;
    }
    setFloorData(newFloorData);
  };

  const handleUnitValueChange = (floorIndex, unitIndex, value) => {
    const newFloorData = [...floorData];
    if (newFloorData[floorIndex]?.units?.[unitIndex]) {
      newFloorData[floorIndex].units[unitIndex].unitValue = value;
    }
    setFloorData(newFloorData);
  };

  const createProjectBlockAndUnits = async () => {
    try {
      setLoading(true);
      if (floorNumber <= 0) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Please add at least one floor",
          },
        });
        setLoading(false);
        return;
      }

      const { projectBlocks, rentalUnits } = await Api.post(
        "/public/project/block/create",
        {
          projectId: projectId,
          userId: user?._id,
          name: blockName,
          description: description,
          rentalUnits: floorData,
          isRental: true,
          floors: floorNumber,
        }
      );

      if (projectBlocks && rentalUnits) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Project Block Created Successfully",
          },
        });
        setProjectBlocks(projectBlocks);
        setRentalUnitCount(rentalUnits?.length);
        setOpen(false);
      }
    } catch (error) {
      console.error("Error creating project block:", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to Create Project Block",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <Box sx={{ width: 500, p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={0}
        >
          <Typography variant="h6">Add Block</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>

        <Divider sx={{ mb: 2 }} />

        <TextField
          fullWidth
          label="Block Name"
          value={blockName}
          onChange={(e) => setBlockName(e.target.value)}
          margin="normal"
        />

        <TextField
          fullWidth
          label="Total Floor"
          type="number"
          value={floorNumber}
          onChange={(e) => setFloorNumber(parseInt(e.target.value))}
          margin="normal"
        />

        {floorData?.map((floor, floorIndex) => (
          <Box key={floorIndex} mt={2}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
              }}
            >
              Floor {floor?.floor}:
            </Typography>
            {floor?.units?.map((unit, unitIndex) => (
              <Grid container spacing={1} key={unitIndex}>
                <Grid item xs={5}>
                  <Select
                    fullWidth
                    value={unit?.unitType}
                    onChange={(e) =>
                      handleUnitTypeChange(
                        floorIndex,
                        unitIndex,
                        e.target.value
                      )
                    }
                  >
                    {unitTypes?.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    type="number"
                    placeholder="Enter Units"
                    value={unit?.unitValue}
                    onChange={(e) =>
                      handleUnitValueChange(
                        floorIndex,
                        unitIndex,
                        e.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => handleAddUnit(floorIndex)}
                    sx={{
                      border: "1px solid rgba(0,0,0,0.5)",
                    }}
                  >
                    <Add />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Box>
        ))}

        <TextField
          fullWidth
          label="Description"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          margin="normal"
          inputProps={{ maxLength: 200 }}
          helperText={`${description?.length}/200`}
        />

        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={() => setOpen(false)}
            sx={{ mr: 1 }}
          >
            Discard
          </Button>
          <Button
            variant="contained"
            onClick={createProjectBlockAndUnits}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Add"}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CreateProjectBlockDrawer;
