import { Box } from "@mui/material";
import React, { useState } from "react";
import TabsWithSearch from "../../../../styled/generic/TabsWithSearch";
import MyRequirementLeads from "../../../common/tablecontainers/MyRequirementLeads";
import MyRequirementRequests from "../../../common/tablecontainers/MyRequirementRequests";

const Requests = () => {
  const [activeTab, setActiveTab] = useState("All Requests");

  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "10px", p: 2, mt: 2 }}>
      <TabsWithSearch
        tabs={["All Requests"]}
        onTabChange={(newTabVal) => setActiveTab(newTabVal)}
        currentTabVariable={activeTab}
        disableUnderline={true}
      />
      {activeTab === "All Requests" && <MyRequirementRequests />}
    </Box>
  );
};

export default Requests;
