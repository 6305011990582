const { default: Api } = require("../../../helpers/Api");

export const getProfileData = async (obj) => {
    try {
        const resResult = await Api.post("profile/getProfileParent", obj);
        const data = resResult?.data;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getProfileDataPopulate = async (obj) => {
    try {
        const resResult = await Api.post("profile/get/profile/populated", obj);
        const data = resResult?.data;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getBlocksForProject = async (obj) => {
    try {
        const resResult = await Api.post("/public/project/block/get", obj);
        const data = resResult?.data;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getProjectsByOwner = async (obj) => {
    try {
        const resResult = await Api.post("get/projects/by-ownerprofile", obj);
        const data = resResult?.data || [];
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const createProcurementApiCall = async (obj) => {
    try {
        const resResult = await Api.post("/procurement/ops/create", obj);
        const data = resResult?.opsOrderSchema;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getOpsOrders = async (obj) => {
    try {
        const resResult = await Api.post("procurement/get/pagination", obj);
        return resResult;
    } catch (error) {
        console.log(error);
        return null;
    }
};


export const getOpsOrdersForFilter = async (obj) => {
    try {
        const resResult = await Api.post("procurement/get/opsorder/name-only", obj);
        return resResult;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const opsOrderDahsboardAggregate = async (obj) => {
    try {
        const resResult = await Api.post("procurement/get/dahsboard/aggregate", obj);
        return resResult?.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getNamesByProfileIds = async (obj) => {
    try {
        const resResult = await Api.post("procurement/get/names/from-profile/ids", obj);
        return resResult?.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getProcurementVendors = async (obj) => {
    try {
        const resResult = await Api.post("procurement/ops/get/procurement/vendors", obj);
        return resResult?.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


export const updateOpsOrderWithPopulate = async (obj) => {
    try {
        const resResult = await Api.post("procurement/ops/update/by-id", obj);
        return resResult?.data || {};
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateOpsOrderWithoutPopulate= async (obj) => {
    try {
        const resResult = await Api.post("/procurement/ops/update", obj);
        return resResult?.data || {};
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const cloneBillItems= async (obj) => {
    try {
        const resResult = await Api.post("wallet/clone/bill-items", obj);
        return resResult?.data || {};
    } catch (error) {
        console.log(error);
        return null;
    }
};