import React from "react";
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring/web.cjs';

function TransitionComponent(props) {
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles((theme) => ({
    checkAndNameCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            fontSize: "20px"
        },
        "& p": {
            margin: "0px !important"
        }
    },
}));

const SelectTreeBody = ({
    item, selectedOptionIds, handleCheck
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const handleLableClick = (event) => {
        // event.preventDefault();
        // event.stopPropagation();
    };

    return (
        <StyledTreeItem
            nodeId={item?._id}
            onLabelClick={handleLableClick}
            label={<div className={classes.checkAndNameCont} >
                <Checkbox
                    color="primary"
                    checked={item?._id && selectedOptionIds ? selectedOptionIds.includes(item?._id) : false}
                    onChange={(event) => {
                        handleCheck(item?._id)
                    }}
                />
                <p>
                    {item?.field ? item?.field : item?.action ? item?.action : item?.resource ? item?.resource : item?.module}
                </p>
            </div>}
        >
            {item.children && item.children.map((item, p) => (
                <SelectTreeBody
                    item={item}
                    key={item._id}
                    selectedOptionIds={selectedOptionIds}
                    handleCheck={handleCheck}
                />
            ))}
        </StyledTreeItem>
    );
};

export default SelectTreeBody;