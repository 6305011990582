import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { getPublicLibrary, cloneLibrary } from './Api.call'
import DrawerContainer from "../styled/generic/DrawerContainer";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import arrayToReducer from "../../helpers/arrayToReducer";
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({

}));

export default function OfferingCopyDrawer({
    library,cloneOfferingDrawer,setCloneOfferingDrawer
}) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [value, setValue] = React.useState(null);
    const [libIds, setLibIds] = useState([])
    const [libDict, setLibDict] = useState({})

    
    const handleChange = (event) => {
      setValue(event.target.value);
    };

    useEffect(() => {
       getPublicLibrary().then(data=>{
            setData(data)
            const {
                newDict, idArr
            } = arrayToReducer(data)
            setLibDict(newDict)
            setLibIds(idArr)
            setValue(idArr[0])
       })
    }, [])
    

    function getLibraryId(library) {
        if (typeof library === 'object' && library !== null) {
          return library?._id;
        } else if (typeof library === 'string') {
          return library;
        }
        return null;
    }

    return (
        <DrawerContainer
        key={'CloneLibrary'}
        open={cloneOfferingDrawer}
        setOpen={setCloneOfferingDrawer}
        title={ "Select library"}
        library={library}
      >


        <FormControl component="fieldset">

            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
            {libIds?libIds.map(libId=>{
                const lib = libDict[libId];

                return <>
                    <FormControlLabel key={libId} value={libId} control={<Radio />} label={lib?.name} />
                    {libId==value?<Button onClick={async ()=>{
                        
                        const success = await cloneLibrary({
                            newLibraryId:getLibraryId(library),
                            cloneFromLibraryId:value
                        })
                        dispatch({
                            type: "AddApiAlert",
                            payload: {
                            success,
                            message: success?"Successfully cloned library":"Cloning library failed",
                            },
                        });
                    }}>
                        Clone
                </Button>:null}
                
                </>  
             }):null}
               
            </RadioGroup>
        </FormControl>

      </DrawerContainer>
    )
}