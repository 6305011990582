import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TeamMembers from "./team.members";
import AddMember from "./NewAddMember";
import Slide from '@material-ui/core/Slide';
import { Paper, Button } from "@material-ui/core";
import { getParticipantsPermissionsForTeam, getTeamInvites } from "../api.call";
import Invites from "./invites";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "80vh",
    display: "flex",
    padding: "20px",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      height: "80vh",
    }
  },
  sidebarSty: {
    width: "200px",
    height: "100%",
    "& .MuiButton-root": {
      borderRadius: "0px"
    },
    "& .MuiButton-label": {
      textTransform: "capitalize"
    },
    [theme.breakpoints.down("md")]: {
      width: "150px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100px",
      "& .MuiButton-label": {
        fontSize: "14px"
      },
    },
  },
  bodySty: {
    width: "calc(100% - 200px)",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative",
    borderLeft: "1px solid #cecece",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 150px)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 100px)",
    },
  },
  notSelectedOptionSty: {
    width: "100%",
    padding: "7px 0px",
    paddingLeft: "5px",
    fontSize: "16px",
    fontWeight: "510",
    opacity: "0.7",
    borderLeft: `0px solid ${theme.palette.primary.main}`,
    transition: "0.3s all ease-in-out",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "3px",
    }
  },
  selectedOptionSty: {
    width: "100%",
    padding: "7px 0px",
    paddingLeft: "0px",
    fontSize: "16px",
    fontWeight: "510",
    opacity: "1",
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    transition: "0.3s all ease-in-out",
    [theme.breakpoints.down("xs")]: {
      borderLeft: `3px solid ${theme.palette.primary.main}`,
    }
  },
  sectionCont: {
    width: "100%",
    overflowX: "auto",
    position: "absolute",
    top: "0px",
    left: "0px",
  }
}));

const MemberSetting = (props) => {
  const classes = useStyles();
  const { teamId, userRole } = props;
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const [view, setView] = useState("Members");
  const [invites, setInvites] = useState([]);
  const [invitesLoading, setInvitesLoading] = useState(true);

  const [roles, setRoles] = useState([]);
  const [membersRole, setMembersRole] = useState([]);
  const [curUserRole, setCurUserRole] = useState("Viewer");
  const [membersLoading, setMembersLoading] = useState(true);

  useEffect(() => {
    if (userRole === "Owner") {
      setRoles(["Owner", "Admin", "Viewer", "Contractor", "Customer", "Employee", "Investor", "Lender", "Vendor", "Tenant"])
    } else if (userRole === "Admin") {
      setRoles(["Admin", "Viewer", "Contractor", "Customer", "Employee", "Investor", "Lender", "Vendor", "Tenant"])
    } else {
      setRoles([])
    }
  }, [userRole])


  useEffect(() => {
    setMembersLoading(true)
    getParticipantsPermissionsForTeam({ teamId: teamId })
      .then((data) => {
        let locParticipantsRole = data?.participantsRole || []
        setMembersRole(data?.participantsRole || [])

        locParticipantsRole.map((participantsRoleData) => {
          if (participantsRoleData?.user?._id === user?.profile) {
            setCurUserRole(participantsRoleData?.role || "Viewer")
          }
        })
        setMembersLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [teamId]);

  useEffect(() => {
    setInvitesLoading(true);
    getTeamInvites({ team: teamId })
      .then((data) => {
        setInvites(data);
        setInvitesLoading(false);
      })
      .catch((err) => {
        console.log(err)
      })
  }, [teamId]);


  return (
    <Paper elevation={2} className={classes.root}>
      <div className={classes.sidebarSty} >
        <Button
          className={view === "Members" ? classes.selectedOptionSty : classes.notSelectedOptionSty}
          onClick={() => { setView("Members") }}
        >
          Members
        </Button>
        <Button
          className={view === "Add People" ? classes.selectedOptionSty : classes.notSelectedOptionSty}
          onClick={() => { setView("Add People") }}
        >
          Add People
        </Button>
        <Button
          className={view === "Invites" ? classes.selectedOptionSty : classes.notSelectedOptionSty}
          onClick={() => { setView("Invites") }}
        >
          Invites
        </Button>
      </div>
      <div className={classes.bodySty} >
        <Slide direction="left" in={view === "Members" ? true : false} mountOnEnter unmountOnExit>
          <div className={classes.sectionCont}>
            <TeamMembers
              teamId={teamId}
              membersRole={membersRole}
              curUserRole={curUserRole}
              membersLoading={membersLoading}
              roles={roles}
              setMembersRole={setMembersRole}
            />
          </div>
        </Slide>

        <Slide direction="left" in={view === "Add People" ? true : false} mountOnEnter unmountOnExit>
          <div className={classes.sectionCont}>
            <AddMember
              teamId={teamId}
              invites={invites}
              setInvites={setInvites}
              invitesLoading={invitesLoading}
              roles={roles}
            />
          </div>
        </Slide>

        <Slide direction="left" in={view === "Invites" ? true : false} mountOnEnter unmountOnExit>
          <div className={classes.sectionCont}>
            <Invites
              teamId={teamId}
              invites={invites}
              invitesLoading={invitesLoading}
            />
          </div>
        </Slide>
      </div>
    </Paper>
  );
};

export default MemberSetting;