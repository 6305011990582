import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Avatar, TextField } from '@material-ui/core';
import { useDebounce } from 'react-use';
import { getOptionsForMuiAutocomplete } from './api.call';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    defaultClass: {},
    optionCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& h3": {
            width: "100%",
            fontSize: "14px",
            fontWeight: "500",
        },
        "& p": {
            width: "100%",
            fontSize: "10px",
            fontWeight: "400",
        }
    },
    optionContNonWidth: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& h5": {
            fontSize: "14px",
            fontWeight: "500",
        },
        "& h6": {
            fontSize: "10px",
            fontWeight: "400",
        }
    },
    noBorder: {
        border: "none"
    },
}))

const SinglePaginationMUIAutocomplete = ({
    selectOnFocus = false, clearOnBlur = false, handleHomeEndKeys = false, freeSolo = false, disableClearable = false,
    fullWidth = false, value, onChange, addonFilter, apiUrl, isNoBorder = false, placeholder, isAddnew = false,
    disabled = false, style = null, className = null, label = null, variant, size = "small"
}) => {
    const classes = useStyles();

    const [textVal, setTextVal] = useState("")
    const [options, setOptions] = useState([])
    const [valueArr, setValueArr] = useState([])

    useEffect(() => {
        if (value?._id) {
            setValueArr([value])
        } else {
            setValueArr([])
        }
    }, [value])

    useDebounce(() => {
        getOptionsForMuiAutocomplete(apiUrl, { ...addonFilter, name: textVal })
            .then((data) => {
                let arr = []
                if (isAddnew) {
                    arr = [
                        {
                            _id: "addNew",
                            name: "+ Add New"
                        },
                        ...data
                    ]
                } else {
                    arr = [...data]
                }
                setOptions(arr)
            })
            .catch((err) => {
                console.log(err)
            })
    }, 2000, [textVal]);

    return (
        <Autocomplete
            id="free-solo-demo"
            multiple={true}
            selectOnFocus={selectOnFocus}
            freeSolo={freeSolo}
            clearOnBlur={clearOnBlur}
            handleHomeEndKeys={handleHomeEndKeys}
            disabled={disabled}
            fullWidth={fullWidth}
            options={options}
            disableClearable={disableClearable}
            value={valueArr}
            onChange={(e, v) => {
                onChange(e, v[v.length - 1])
            }}
            className={className ? className : classes.default}
            style={style ? style : {}}
            getOptionLabel={(option) => option?.displayName || ""}
            getOptionSelected={(option) => option?._id == value?._id}
            renderTags={(value, getTagProps) => {
                let filteredRes = value.filter(s => s?._id != null || s?._id != undefined)
                if (filteredRes && filteredRes.length > 0) {
                    return filteredRes.map((option, index) => (
                        <li className={classes.optionContNonWidth} >
                            <Avatar
                                style={{ width: "25px", height: "25px" }}
                                src={option?.displayPicture?.thumbUrl ? option?.displayPicture?.thumbUrl : option?.displayPicture?.url}
                                alt={option?.displayName}
                            />
                            <div style={{ width: "100%", paddingLeft: "10px" }} >
                                <h5>{option?.displayName}</h5>
                                <h6>{option?.username}</h6>
                            </div>
                        </li>
                    ))
                }
            }}
            renderOption={(option, state) => (
                <li className={classes.optionCont} >
                    <Avatar
                        style={{ width: "25px", height: "25px" }}
                        src={option?.displayPicture?.thumbUrl ? option?.displayPicture?.thumbUrl : option?.displayPicture?.url}
                        alt={option?.displayName}
                    />
                    <div style={{ width: "100%", paddingLeft: "10px" }} >
                        <h3>{option?.displayName}</h3>
                        <p>{option?.username}</p>
                    </div>
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    value={textVal}
                    onChange={(e) => {
                        setTextVal(e.target.value)
                    }}
                    variant={variant}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        classes: isNoBorder ? { notchedOutline: classes.noBorder } : {},
                    }}
                    placeholder={placeholder}
                    size={size}
                />
            )}
        />
    );
};

export default SinglePaginationMUIAutocomplete;