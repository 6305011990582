import { Typography } from "@mui/material";
import React from "react";

const LabelDivider = ({ children, ...props }) => {
  return (
    <Typography {...props} sx={{ mt: 4, mb: 3 }}>
      {children}
    </Typography>
  );
};

export default LabelDivider;
