import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Api from "../helpers/Api";
import Paper from "@material-ui/core/Paper";
import { useDebounce } from 'react-use';
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%"
	},
	inputPaper: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "350px",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		}
	},
	iconButton: {
		padding: 10,
	},
	itemCont: {
		width: "100%",
		padding: "5px 0px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "flex-start",
		"& h3": {
			fontSize: "15px",
			fontWeight: "500"
		},
		"& p": {
			fontSize: "12px",
			fontWeight: "400",
			color: "gray"
		},
		"& h4": {
			backgroundColor: "gray",
			color: "white",
			fontSize: "10px",
			fontWeight: "350",
			padding: "0px 5px",
			borderRadius: "3px"
		}
	},
	autoSty: {
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		}
	}
}));

export default function UserPalAutoComplete(props) {
	const classes = useStyles();
	const {
		participant, setParticipant, setSelectUser, placeholder,
		notAllowedProfileIds = [], notAllowedUserIds = [], notAllowedOrgIds = []
	} = props;

	const [text, setText] = useState("");
	const [users, setUsers] = useState([]);
	const [initialUsers, setInitialUsers] = useState([]);

	const sendQuery = async () => {
		await Api.post("searchPeople", { name: text, notAllowedUserIds, notAllowedOrgIds })
			.then((users) => {
				setUsers(users);
			})
			.catch((err) => {
				console.log(err)
			})
	};

	useDebounce(() => {
		if (text && text.length > 2) {
			sendQuery()
		} else if (text && text.length === 0) {
			setUsers([])
		}
	}, 1200, [text]);

	const getInitalUsers = async () => {
		await Api.post("get-initial/search", { notAllowedUserIds, notAllowedOrgIds })
			.then((users) => {
				setUsers(users);
				setInitialUsers(users)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	useEffect(() => {
		getInitalUsers()
	}, []);

	useEffect(() => {
		if (users.length === 0) {
			setUsers(initialUsers)
		}
	}, [initialUsers, users])

	return (
		<div className={classes.root}>
			<Autocomplete
				id="userpalautocomplete"
				value={participant}
				options={users}
				className={classes.autoSty}
				getOptionLabel={(option) => { return option?.parent?.displayName || ""; }}
				onChange={(event, value) => {
					setParticipant(value);
					if (setSelectUser) {
						setSelectUser(false);
					}
				}}
				renderInput={(params) => (
					<Paper
						component="form"
						className={classes.inputPaper}
						ref={params.InputProps.ref}
					>
						<InputBase
							{...params}
							{...params.inputProps}
							placeholder={placeholder ? placeholder : "Add Members"}
							inputProps={{ "aria-label": "Add Members" }}
							value={text}
							onChange={(e) => { setText(e.target.value) }}
						/>
						<IconButton
							type="submit"
							className={classes.iconButton}
							aria-label="search"
						>
							<SearchIcon />
						</IconButton>
					</Paper>
				)}
				renderOption={(option, state) => {
					if (option && !notAllowedProfileIds.includes(option?._id)) {
						return (
							<div className={classes.itemCont} >
								<div style={{ display: "flex", alignItems: "center" }}  >
									<Avatar
										src={option?.parent?.displayPicture?.thumbUrl ? option?.parent?.displayPicture?.thumbUrl : option?.parent?.displayPicture?.url}
										alt={option?.parent?.displayName}
										style={{ width: "35px", height: "35px" }}
									/>
									<div style={{ marginLeft: "7px" }} >
										<h3>{option?.parent?.displayName}</h3>
										<p>@{option?.parent?.username}</p>
									</div>
								</div>

								<h4>{option?.parent?.model == "Pal" ? "Connection" : option?.parentModelName}</h4>
							</div>
						)
					}
				}}
			/>
		</div>
	);
}