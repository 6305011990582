import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { 
    Button, 
    TextField, 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem,
    Typography
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { createSOP, updateSOP } from './Api.call';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../doc/editor.css"
import * as yup from "yup";
import CategoryAutocomplete from "./CategoryAutocomplete";

const sopValidationSchema = yup.object().shape({
    title: yup.string().min(2).max(250).required("Title is required"),
    version: yup.string().required("Version is required"),
    status: yup.string().required("Status is required"),
    content: yup.string().required("Content is required")
});

const useStyles = makeStyles((theme) => ({
    mainDialogCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "14px"
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflow: "hidden",
        padding: "15px"
    },
    bottomAct: {
        width: "100%",
        height: "50px",
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #ececec"
    },
    formField: {
        marginBottom: "15px"
    },
    validationSty: {
        fontSize: "12px",
        color: "red",
        fontWeight: "400",
        marginTop: "5px"
    },
}));

const modules = {
    toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link"],
        [{ script: "sub" }, { script: "super" }],
        [{ color: [] }, { background: [] }],
        ["clean"],
    ],
    clipboard: {
        matchVisual: false,
    },
};

const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "script",
    "color",
    "background",
];

export default function SOPCreateDrawer({
    createSOPDrawer,
    setCreateSOPDrawer,
    profileId,
    afterSOPCreateOrEdit,
    libraryId,
    sopForEditDelete,
    ...other
}) {
    const classes = useStyles();
    const theme = useTheme();
    const { user } = useSelector((state) => state.auth);

    const [loader, setLoader] = useState(false);
    const [title, setTitle] = useState("");
    const [version, setVersion] = useState("");
    const [status, setStatus] = useState("draft");
    const [content, setContent] = useState("");
    const [labels, setLabels] = useState([]);
    const [validationObj, setValidationObj] = useState({});

    const setInitialState = () => {
        setLoader(false);
        setTitle("");
        setVersion("");
        setStatus("draft");
        setContent("");
        setLabels([]);
        setValidationObj({});
    };

    useEffect(() => {
        if (createSOPDrawer) {
            if (sopForEditDelete?._id) {
                setTitle(sopForEditDelete?.title || "");
                setVersion(sopForEditDelete?.version || "");
                setStatus(sopForEditDelete?.status || "draft");
                setContent(sopForEditDelete?.content || "");
                setLabels(sopForEditDelete?.labels || []);
            } else {
                setInitialState();
            }
        }
    }, [createSOPDrawer, sopForEditDelete]);

    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            title,
            version,
            status,
            content,
            labels: labels.map(label => label?._id)
        };
        try {
            await sopValidationSchema.validate(formData, { abortEarly: false });
            setLoader(true);
            if (sopForEditDelete?._id) {
                const updateObj = {
                    sopId: sopForEditDelete._id,
                    userId:user?._id,
                    updateData:formData
                };
                const data = await updateSOP(updateObj );
                afterSOPCreateOrEdit(data);
            } else {
                const createObj = {
                    ...formData,
                    shared: [profileId],
                    libraryId: libraryId,
                    originProfile: profileId,
                    profile: user?.profile,
                    creatorId: user?._id,
                };
                const data = await createSOP(createObj);
                afterSOPCreateOrEdit(data);
            }
            setLoader(false);
            setCreateSOPDrawer(false);
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                const errorObj = {};
                err.inner.forEach((error) => {
                    errorObj[error.path] = error.message;
                });
                setValidationObj(errorObj);
            } else {
                console.error(err);
            }
            setLoader(false);
        }
    };

    return (
        <NormalDrawer
            openDrawer={createSOPDrawer}
            setOpenDrawer={setCreateSOPDrawer}
            anchor={"right"}
            width={"60vw"}
            title={sopForEditDelete?._id ? "Edit SOP" : "Create SOP"}
            content={
                <form className={classes.mainDialogCont} onSubmit={onSubmit}>
                    <div className={classes.bodyCont}>
                        <TextField
                            className={classes.formField}
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            label={<>Title<span style={{ color: "red" }}>*</span></>}
                            placeholder="Enter title"
                            autoComplete='off'
                            value={title}
                            fullWidth
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        {validationObj.title && <Typography className={classes.validationSty}>{validationObj.title}</Typography>}

                        <TextField
                            className={classes.formField}
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            label={<>Version<span style={{ color: "red" }}>*</span></>}
                            placeholder="Enter version"
                            autoComplete='off'
                            value={version}
                            fullWidth
                            onChange={(e) => setVersion(e.target.value)}
                        />
                        {validationObj.version && <Typography className={classes.validationSty}>{validationObj.version}</Typography>}

                        <FormControl className={classes.formField} fullWidth>
                            <Typography variant="subtitle1" gutterBottom>
                                Labels
                            </Typography>
                            <CategoryAutocomplete
                                libraryId={libraryId}
                                selectedCategories={labels}
                                setSelectedCategories={setLabels}
                                isMultiple={true}
                                label={"Label"}
                                profileId={profileId}
                                type={"Task"}
                                isCreateNew={true}
                                placeholder="Select Label"
                            />
                        </FormControl>

                        <FormControl variant="outlined" size="small" fullWidth className={classes.formField}>
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                                labelId="status-label"
                                id="status-select"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                label="Status"
                            >
                                <MenuItem value="draft">Draft</MenuItem>
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="archived">Archived</MenuItem>
                            </Select>
                        </FormControl>
                        {validationObj.status && <Typography className={classes.validationSty}>{validationObj.status}</Typography>}

                        <Typography variant="subtitle1" gutterBottom>
                            Content<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <ReactQuill
                            {...other}
                            className='EditorStyle1'
                            value={content}
                            placeholder={"Enter SOP content"}
                            onChange={setContent}
                            modules={modules}
                            formats={formats}
                            theme='snow'
                        />
                        {validationObj.content && <Typography className={classes.validationSty}>{validationObj.content}</Typography>}
                    </div>
                    <div style={{ width: "100%", height: "5px" }}>
                        {loader && (<LinearProgress />)}
                    </div>
                    <div className={classes.bottomAct}>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            type="submit"
                        >
                            Save & Close
                        </Button>
                    </div>
                </form>
            }
        />
    );
}