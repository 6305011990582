import { Add } from "@material-ui/icons";
import { Button } from "@mui/material";
import React from "react";

const TabActionButton = ({ children, ...props }) => {
  return (
    <Button
      startIcon={<Add />}
      variant="contained"
      color="primary"
      disableElevation
      style={{ textTransform: "none" }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default TabActionButton;
