import { Typography } from "@mui/material";
import React from "react";

const UploadButton = () => {
  return (
    <div
      style={{
        width: "160px",
        height: "120px",
        borderRadius: "7px",
        borderWidth: "2px",
        borderColor: "#2D76E0",
        borderStyle: "dashed",
        padding: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        cursor: "pointer",
      }}
    >
      <img src="/icons/Camera.svg" width="40px" height="40px" />
      <Typography variant="h6">Upload image</Typography>
    </div>
  );
};

export default UploadButton;
