import { makeStyles } from "@material-ui/core";
import { Box, Checkbox, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  tableSty: {
    width: "100%",
    border: "none",
    backgroundColor: "#F5F7FA",
    borderCollapse: "collapse",
    marginTop: "10px",
    "& tr": {
      height: "30px",
      marginTop: "5px",
      backgroundColor: "#FFFFFF",
    },
    "& th": {
      borderCollapse: "collapse",
      fontWeight: "550",
      backgroundColor: "#F5F7FA",
      textAlign: "left",
    },
    "& td": {
      borderCollapse: "collapse",
      fontWeight: "500",
      textAlign: "left",
      verticalAlign: "center",
      color: "#111111",
    },
  },
}));

const DataTableContainer = ({ fontSize = "1rem", children }) => {
  const classes = useStyles();

  return (
    <Box>
      <table style={{ fontSize: fontSize }} className={classes.tableSty}>
        {children}
      </table>
    </Box>
  );
};

export default DataTableContainer;
