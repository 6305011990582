import React, { useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
import PhoneInput from "react-phone-input-2";
import { useDebounce } from "react-use";
import { getUserCallForUsernameCheck } from "../api.call";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as yup from "yup";
import "react-phone-input-2/lib/style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "all .5s ease-in-out",
  },
  header: {
    "& p": {
      color: "grey",
      fontSize: "1rem",
    },
    "& h1": {
      fontSize: "1.75rem",
    },
    [theme.breakpoints.down("sm")]: {
      "& p": {
        fontSize: "0.9rem",
      },
      "& h1": {
        fontSize: "1.3rem",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "& p": {
        fontSize: "0.7rem",
      },
      "& h1": {
        fontSize: "1rem",
      },
    },
  },
  formContainer: {
    height: "calc( 100% - 90px )",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      height: "calc( 100% - 50px )",
    }
  },
  textBox: {
    backgroundColor: "white",
    border: "2px solid #f5f5f5",
  },
  nameBox: {
    width: "450px",
    height: "110px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "97%",
    }
  },
  duleInputCont: {
    width: "48%"
  },
  warningSty: {
    width: "100%",
    height: "4px",
    fontSize: "14px",
    color: "red",
    fontWeight: "450",
    textAlign: "left"
  },
  usernameSty: {
    width: "450px",
    height: "90px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "97%",
    }
  },
  phoneBox: {
    width: "450px",
    "& .react-tel-input": {
      height: "50px",
      "& .form-control": {
        height: "50px",
        width: "100%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "97%",
    }
  },
}));

const BasicInfo = ({
  activeStep, setIsBasicValid, basicUserData, setBasicUserData,
  basicValidObject, setBasicValidObject,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const [usernameCheckLoading, setUsernameCheckLoading] = useState(false);

  //check username validation
  useDebounce(async () => {
    if (basicUserData?.username && basicUserData?.username.length > 4) {
      await handleUsernameOnChange()
    }
  }, 1500, [basicUserData?.username]);

  //check username validation api call
  const handleUsernameOnChange = async () => {
    setUsernameCheckLoading(true)
    await getUserCallForUsernameCheck({ username: basicUserData?.username })
      .then(async (data) => {
        if (data && data.length === 0) {
          setIsBasicValid(true);
        }
        else if (data && data.length === 1 && user?.username === basicUserData?.username) {
          setIsBasicValid(true);
        } else {
          setIsBasicValid(false);
          setBasicValidObject({
            ...basicValidObject,
            username: "Username already exists"
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
    setUsernameCheckLoading(false)
  }

  return (
    <div className={classes.root}>
      <Grow
        in={activeStep == 0}
        {...(activeStep == 0 ? { timeout: 1000 } : {})}
      >
        <div className={classes.header}>
          <h1>Get Started with your basic details.</h1>
          <p>Its just a brief, don't worry!</p>
        </div>
      </Grow>
      <Grow
        in={activeStep == 0}
        style={{ transformOrigin: "0 0 0" }}
        {...(activeStep == 0 ? { timeout: 1000 } : {})}
      >
        <div className={classes.formContainer}>
          <div className={classes.nameBox}>
            <div className={classes.duleInputCont} >
              <TextField
                label="First Name"
                variant="outlined"
                placeholder="E.g: Gaurav"
                value={basicUserData?.firstName}
                InputLabelProps={{ shrink: !!basicUserData?.firstName }}
                onChange={(e) => {
                  setBasicUserData({
                    ...basicUserData,
                    firstName: e.target.value
                  })
                }}
                InputProps={{
                  className: classes.textBox,
                  disableUnderline: true,
                }}
                style={{ width: "100%" }}
              />
              <p className={classes.warningSty} >{basicValidObject?.firstName}</p>
            </div>
            <div className={classes.duleInputCont} >
              <TextField
                label="Last Name"
                variant="outlined"
                placeholder="E.g: Parmar"
                InputLabelProps={{ shrink: !!basicUserData?.lastName }}
                value={basicUserData?.lastName}
                onChange={(e) => {
                  setBasicUserData({
                    ...basicUserData,
                    lastName: e.target.value
                  })
                }}
                InputProps={{
                  className: classes.textBox,
                  disableUnderline: true
                }}
                style={{ width: "100%" }}
              />
              <p className={classes.warningSty} >{basicValidObject?.lastName}</p>
            </div>
          </div>
          <div className={classes.usernameSty} >
            <TextField
              label={"Username"}
              variant="outlined"
              placeholder="E.g: gauravparmar653"
              value={basicUserData?.username}
              InputLabelProps={{ shrink: !!basicUserData?.username }}
              onChange={(e) => {
                setBasicUserData({
                  ...basicUserData,
                  username: e.target.value
                })
              }}
              InputProps={{
                className: classes.textBox,
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {usernameCheckLoading && (
                      <CircularProgress
                        size={30}
                        thickness={3}
                      />
                    )}
                  </InputAdornment>),
              }}
            />
            <p className={classes.warningSty} >{basicValidObject?.username}</p>
          </div>
          <div className={classes.phoneBox}>
            <PhoneInput
              label="Phone Number"
              required
              countryCodeEditable={false}
              country={"in"}
              inputStyle={{
                backgroundColor: "white",
                border: "2px solid #f5f5f5",
              }}
              variant="outlined"
              value={basicUserData?.phoneNo}
              onChange={(phn) => {
                setBasicUserData({
                  ...basicUserData,
                  phoneNo: phn
                })
              }}
              placeholder="E.g: 934510xxxx"
              InputProps={{
                className: classes.textBox,
                disableUnderline: true
              }}
              style={{ width: "100%" }}
            />
            <p className={classes.warningSty} >{basicValidObject?.phoneNo}</p>
          </div>
        </div>
      </Grow>
    </div>
  );
};

export default BasicInfo;