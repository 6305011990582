import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { UNIT_OPTIONS } from "../../../helpers/constants"

const useStyles = makeStyles((theme) => ({
    default: {

    },
    optionCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& h3": {
            width: "100%",
            fontSize: "14px",
            fontWeight: "500",
            textAlign: "left"
        },
        "& p": {
            width: "100%",
            fontSize: "13px",
            fontWeight: "400",
            textAlign: "right"
        }
    },
    noBorder: {
        border: "none"
    }
}));

export default function UnitAutocomplete({
    allowUnits = UNIT_OPTIONS?.classifications || [], value, onChange, style = null, className = null,
    disableClearable = false, fullWidth = false, label = null, size = "small", isNoBorder = false, 
    freeSolo = true, selectOnFocus=false, clearOnBlur=false, handleHomeEndKeys=false, disabled=false
}) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const [unitsObjArr, setUnitsObjArr] = useState([])
    const [idObjMap, setIdObjMap] = useState({})
    const [selectedValue, setSelectedValue] = useState({})

    function capitalizeEachWord(str) {
        return str.replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });
    }

    useEffect(() => {
        let arr = [];
        let mapObj = {}
        Object.keys(UNIT_OPTIONS.units).map((unitGroup) => {
            if (allowUnits.includes(unitGroup)) {
                let groupUntitArr = UNIT_OPTIONS.units[unitGroup] || []
                groupUntitArr.map((unitData) => {
                    arr.push({
                        _id: unitData?.unit.toLowerCase(),
                        mainGroup: capitalizeEachWord(unitGroup),
                        fullName: capitalizeEachWord(unitData?.name)
                    })
                    mapObj[unitData?.unit.toLowerCase()] = {
                        _id: unitData?.unit.toLowerCase(),
                        mainGroup: capitalizeEachWord(unitGroup),
                        fullName: capitalizeEachWord(unitData?.name)
                    }
                })
            }
        })
        setIdObjMap(mapObj)
        setUnitsObjArr(arr)
    }, [UNIT_OPTIONS])

    useEffect(() => {
        setSelectedValue(idObjMap?.[value])
    }, [value, unitsObjArr, idObjMap])

    return (
        <Autocomplete
            id="free-solo-demo"
            selectOnFocus={selectOnFocus}
            freeSolo={freeSolo}
            clearOnBlur={clearOnBlur}
            handleHomeEndKeys={handleHomeEndKeys}
            fullWidth={fullWidth}
            disabled={disabled}
            options={unitsObjArr}
            disableClearable={disableClearable}
            value={selectedValue}
            onChange={(e, v) => {
                onChange(e, v?._id)
            }}
            className={className ? className : classes.default}
            style={style ? style : {}}
            groupBy={(option) => option?.mainGroup}
            getOptionLabel={(option) => option?._id}
            getOptionSelected={(option) => option?._id == selectedValue?._id}
            renderOption={(option, state) => (
                <li className={classes.optionCont} >
                    <h3>{option?._id}</h3>
                    <p>{option?.fullName}</p>
                </li>
            )}
            size={size}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        classes: isNoBorder ? { notchedOutline: classes.noBorder } : {},
                    }}
                    placeholder={"Select Unit"}
                    size={size}
                />
            )}
        />
    );
}