import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import { ImProfile } from "react-icons/im";
import { IoLanguageSharp } from "react-icons/io5";
import { MdCastForEducation } from "react-icons/md";
import { GiCrane } from "react-icons/gi";
import { TbCertificate } from "react-icons/tb";
import { BsBank } from "react-icons/bs";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaAward } from "react-icons/fa";
import { RiAdminLine } from "react-icons/ri";
import { PiAddressBook } from "react-icons/pi";
import { PiNetwork } from "react-icons/pi";
import { Drawer } from "@material-ui/core";
import { BsWindowDock } from "react-icons/bs";
import { BsFillPersonBadgeFill } from "react-icons/bs";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import BasicInformation from "./BasicInformation";
import AdministartionEdit from "./AdministartionEdit";

import BankEdit from "./BankEdit";
import LocationsEdit from "./LocationsEdit";
import DepartmentEdit from "./DepartmentsEdit";
import DesignationEdit from "./DesignationsEdit";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "90vw",
    display: "flex",
    height: "100vh",
    flexDirection: "column",
  },
  mainEditCont: {
    width: "90vw",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    borderTop: "1px solid #e4e7e7",
  },
  leftNavBar: {
    width: "20%",
    height: "100%",
    borderRight: "1px solid #e4e7e7",
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  rightNavBar: {
    width: "80%",
    height: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 200px)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  header: {
    height: "4rem",
    borderTop: "1px solid #e4e7e7",
    borderBottom: "1px solid #e4e7e7",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0rem 1rem",
  },
  optionBtn: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: "0px",
    fontSize: "16px",
    opacity: "0.6",
    justifyContent: "flex-start",
    paddingLeft: "20px",
    height: "52px",
    borderTop: "1px solid #e4e7e7",
    "& p": {
      textTransform: "capitalize",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
      paddingLeft: "4px",
      "& p": {
        textTransform: "capitalize",
        textAlign: "left",
        width: "160px",
      },
    },
  },
  sideTopBar: {
    height: "70px",
  },
}));

export default function AccountEdit({
  open,
  organization,
  orgEditView,
  setOpen,
}) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const [profileEditView, setProfileEditView] = useState("basicInfo");

  return (
    <Drawer anchor="right" open={open}>
      <div className={classes.root}>
        <div className={classes.header}>
          <div style={{ display: "flex", alignItems: "center", gap: "0.8rem" }}>
            <span>
              <ArrowBackIosIcon style={{ color: "#2d76e0" }} />{" "}
            </span>
            <span style={{ fontSize: "1.4rem", fontWeight: "500" }}>
              {organization?.displayName}
            </span>
          </div>
          <span onClick={() => setOpen(false)}>
            <CloseIcon style={{ color: "#2d76e0", cursor: "pointer" }} />{" "}
          </span>
        </div>
        <div className={classes.mainEditCont}>
          <div className={classes.leftNavBar}>
            <Button
              className={classes.optionBtn}
              onClick={() => {
                setProfileEditView("basicInfo");
              }}
              style={
                profileEditView === "basicInfo"
                  ? { opacity: "1", borderTop: "none" }
                  : { borderTop: "none" }
              }
            >
              <BsWindowDock style={{ fontSize: "19px", marginRight: "15px" }} />{" "}
              <p>Basic information</p>
            </Button>
            <Button
              className={classes.optionBtn}
              onClick={() => {
                setProfileEditView("departments");
                
              }}
              style={profileEditView === "departments" ? { opacity: "1" } : {}}
            >
              <PiNetwork
                style={{ fontSize: "21px", marginRight: "15px" }}
              />{" "}
              <p>Departments</p>
            </Button>

            <Button
              className={classes.optionBtn}
              onClick={() => {
                setProfileEditView("designations");
              }}
              style={profileEditView === "designations" ? { opacity: "1" } : {}}
            >
              <BsFillPersonBadgeFill
                style={{ fontSize: "21px", marginRight: "15px" }}
              />{" "}
              <p>Designations</p>
            </Button>

            <Button
              className={classes.optionBtn}
              onClick={() => {
                setProfileEditView("locations");
              }}
              style={profileEditView === "address" ? { opacity: "1" } : {}}
            >
              <PiAddressBook
                style={{ fontSize: "21px", marginRight: "15px" }}
              />{" "}
              <p>Locations</p>
            </Button>
            <Button
              className={classes.optionBtn}
              onClick={() => {
                setProfileEditView("bank");
              }}
              style={profileEditView === "bank" ? { opacity: "1" } : {}}
            >
              <BsBank style={{ fontSize: "21px", marginRight: "15px" }} />{" "}
              <p>Bank </p>
            </Button>
          </div>
          <div className={classes.rightNavBar}>
            {profileEditView === "basicInfo" && (
              <BasicInformation organization={organization} />
            )}
            {profileEditView === "departments" && (
              <DepartmentEdit organization={organization}/>
            )}
            
            {profileEditView === "designations" && (
              <DesignationEdit organization={organization}/>
            )}
            {profileEditView === "locations" && (
              <LocationsEdit organization={organization} />
            )}
            {profileEditView === "bank" && (
              <BankEdit organization={organization} />
            )}
            {/* {profileEditView === "basicInfo" && (
          <BasicInfoEdit setOpenEditView={setOpenEditView} />
        )}
        {profileEditView === "certificat" && (
          <CertificateEdit setOpenEditView={setOpenEditView} />
        )}
        {profileEditView === "education" && (
          <EducationEdit setOpenEditView={setOpenEditView} />
        )}
        {profileEditView === "experience" && (
          <ExperienceEdit setOpenEditView={setOpenEditView} />
        )}
        {profileEditView === "language" && (
          <LanguagesEdit setOpenEditView={setOpenEditView} />
        )}
        {profileEditView === "project" && (
          <ProjectEdit setOpenEditView={setOpenEditView} />
        )}
        {profileEditView === "business" && (
          <BusinessEmail setOpenEditView={setOpenEditView} />
        )} */}
          </div>
        </div>
      </div>
    </Drawer>
  );
}
