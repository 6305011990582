import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { createLibrary } from "./Api.call";
import { LoadingButton } from "@mui/lab";
import { BiSave } from "react-icons/bi";

const useStyles = makeStyles(() => ({
  mainDialogCont: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px",
    },
  },
  bodyCont: {
    width: "100%",
    height: "calc(100% - 55px)",
    overflow: "hidden",
    padding: "15px",
  },
  bottomAct: {
    width: "100%",
    height: "50px",
    padding: "0px 7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: "1px solid #ececec",
  },
}));

const defaultLibraryObj = {
  name: "",
};

export default function LibraryCreateDrawer({
  createLibraryDawer,
  setCreateLibraryDrawer,
  profileId,
  afterCreateOrEditNewLibrary,
  curEditDeleteLibrary,
}) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);

  const [libraryObj, setLibraryObj] = useState(defaultLibraryObj);
  const [loader, setLoader] = useState(false);
  const [changeState, setChangeState] = useState(false);

  useEffect(() => {
    if (curEditDeleteLibrary?._id) {
      setLibraryObj({
        name: curEditDeleteLibrary?.name,
      });
    } else {
      setLibraryObj(defaultLibraryObj);
    }
  }, [curEditDeleteLibrary, createLibraryDawer]);

  const handleSubmit = async (name) => {
    setLoader(true);
    const createObj = {
      name: name,
      public: false,
      country: "India",
      countryWide: true,
      profile: profileId,
      user: user._id,
    };
    await createLibrary(createObj)
      .then((data) => {
        if (data) {
          afterCreateOrEditNewLibrary(data, false);
          setLibraryObj(defaultLibraryObj);
          setCreateLibraryDrawer(false);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <NormalDrawer
      openDrawer={createLibraryDawer}
      setOpenDrawer={setCreateLibraryDrawer}
      anchor={"right"}
      title={curEditDeleteLibrary?._id ? "Edit Library" : "Create Library"}
      content={
        <form
          className={classes.mainDialogCont}
          onSubmit={(event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const name = formJson.title;
            const obj = { ...libraryObj };
            obj.name = formJson.title;
            setLibraryObj(obj);
            setChangeState(!changeState);
            setTimeout(handleSubmit(name), 1000);
          }}
        >
          <div className={classes.bodyCont}>
            <TextField
              label={<>Title</>}
              placeholder={"Enter library title"}
              fullWidth
              size="small"
              variant="outlined"
              required
              id="title"
              name="title"
            />
          </div>
          <div style={{ width: "100%", height: "5px" }}>
            {loader && <LinearProgress />}
          </div>
          <div className={classes.bottomAct}>
            <LoadingButton
              loading={loader}
              type="submit"
              variant="contained"
              endIcon={<BiSave />}
            >
              Create Library
            </LoadingButton>
          </div>
        </form>
      }
    />
  );
}
