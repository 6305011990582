const { default: Api } = require('../../helpers/Api');

export const addObservationToParent = async (obj) => {
    try {
        const resData = await Api.post("site-observation/addToParent", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getDetailsObservation = async (obj) => {
    try {
        const resData = await Api.post("site-observation/get-detail", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const updateObservation = async (obj) => {
    try {
        const resData = await Api.post("site-observation/update-many", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};