import { Box } from "@mui/material";
import React from "react";

const BoxSpaceBetween = ({ alignItems = "center", children, sx, ...props }) => {
  return (
    <Box
      display={"flex"}
      alignItems={alignItems}
      justifyContent={"space-between"}
      sx={{ ...sx }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default BoxSpaceBetween;
