import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { deleteDiscountOrTaxAndRelation, getdiscountAndTaxByLibraryId } from './Api.call';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import AddIcon from '@material-ui/icons/Add';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import { Button, IconButton, TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import DeleteConfirmBox from '../styled/CommonComponents/DeleteConfirmBox';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { useDebounce } from 'react-use';
import TaxDiscountCreateDrawer from '../finance2o/expenseAndBills/bills/BillList/TaxDiscountCreateDrawer';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        "& table": {
            tableLayout: "auto"
        },
        "& .MuiTableCell-sizeSmall": {
            padding: "0px 10px"
        }
    },
    searchAndAddBtnCont: {
        width: "100%",
        height: "60px",
        display: "flex",
        alignItems: "center",
        padding: "0px 5px",
        justifyContent: "space-between"
    },
    tableContainer: {
        width: "100%",
        height: "calc(100% - 106px)",
        "& tr": {
            height: "45px"
        },
        "&::-webkit-scrollbar": {
            display: "none"
        },
        "& table": {
            tableLayout: "auto"
        }
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid #ececec",
    },
    singleOption: {
        padding: "4px 15px",
        fontSize: "15px",
        fontWeight: "500",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    },
    iconBtn: {
        "& .MuiSvgIcon-root": {
            color: "black"
        }
    },
    deleteConfirm: {
        width: "350px",
        padding: "25px 0px 35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& .MuiSvgIcon-root": {
            fontSize: "75px",
            color: "red"
        },
        "& h3": {
            fontSize: "20px"
        },
        "& p": {
            fontSize: "14px"
        }
    }
}));

export default function DiscountByLibrary({
    libraryId, wallet, setResponseStatusAndMsg, setSanckBarBool, profileId
}) {
    const classes = useStyles();
    const theme = useTheme();
    const { user } = useSelector((state) => state.auth);

    const [loader, setLoader] = useState(false)
    const [deleteConfirmBox, setDeleteConfirmBox] = useState(false)
    const [changeState, setChangeState] = useState(false)
    const [searchStr, setSearchStr] = useState("")
    const [createDiscountDrawer, setCreateDiscountDrawer] = useState(false)
    const [discounts, setDiscounts] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [curEditDeleteDiscount, setCurEditDeleteDiscount] = useState(null)

    const getDiscountsPagination = async () => {
        setLoader(true)
        await getdiscountAndTaxByLibraryId({
            libraryId,
            walletId: wallet?._id,
            limit: pageLimit,
            type: "Discount",
            curPage: curPage - 1,
            nameSearch: searchStr
        })
            .then((data) => {
                const dataArr = data?.data || [];
                const dataCount = data?.count || 0;
                setDiscounts(dataArr)
                let locTotalPage = Math.ceil(dataCount / pageLimit);
                setTotalPages(locTotalPage)
                setLoader(false)
            })
            .catch((err) => {
                console.log(err)
                setLoader(false)
            })
    }

    useDebounce(() => {
        getDiscountsPagination()
    }, 1000, [curPage, changeState])

    useEffect(() => {
        setCurPage(1)
        setChangeState(!changeState)
    }, [searchStr])

    const discountAction = async (type, taxDiscount) => {
        setCreateDiscountDrawer(false)
        setCurEditDeleteDiscount(null)
        setDeleteConfirmBox(false)
        switch (type) {
            case "create":
                setCreateDiscountDrawer(true);
                break;
            case "edit":
                setCurEditDeleteDiscount(taxDiscount);
                setCreateDiscountDrawer(true);
                break;
            case "delete":
                setCurEditDeleteDiscount(taxDiscount);
                setDeleteConfirmBox(true);
                break
        }
    }

    const afterDiscountCreateOrEdit = async () => {
        if (curEditDeleteDiscount?._id) {
            setChangeState(!changeState)
            setResponseStatusAndMsg({
                status: "success",
                message: `Tax successfully modified`
            })
            setSanckBarBool(true)
        } else {
            setChangeState(!changeState)
            setCurPage(1)
            setResponseStatusAndMsg({
                status: "success",
                message: `Tax successfully added`
            })
            setSanckBarBool(true)
        }
        setCurEditDeleteDiscount(null)
    }

    const deleteDiscount = async () => {
        if (curEditDeleteDiscount?._id) {
            setLoader(true)
            await deleteDiscountOrTaxAndRelation({
                isDeleteFromOrigin: curEditDeleteDiscount?.originProfile == profileId,
                taxDiscountId: curEditDeleteDiscount?._id,
                taxDiscountRelationId: curEditDeleteDiscount?.discountOrTaxRelation?._id
            })
                .then((data) => {
                    setChangeState(!changeState)
                    setLoader(false)
                    setDeleteConfirmBox(false)
                    setResponseStatusAndMsg({
                        status: "success",
                        message: `${curEditDeleteDiscount?.model} successfully deleted`
                    })
                    setSanckBarBool(true)
                    setCurEditDeleteDiscount(null)
                })
                .catch((err) => {
                    console.log(err)
                    setLoader(false)
                })
        }
    }

    return (
        <div className={classes.mainCont} >
            <div className={classes.searchAndAddBtnCont} >
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder={"Enter name"}
                    autoComplete='off'
                    value={searchStr}
                    style={{ width: "350px" }}
                    onChange={(e) => {
                        setSearchStr(e.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setCurEditDeleteDiscount(null)
                        discountAction("create", null)
                    }}
                >
                    New
                </Button>
            </div>
            <div style={{ width: "100%", height: "5px" }} >
                {loader && (<LinearProgress />)}
            </div>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table" size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" >
                                Name
                            </TableCell>
                            <TableCell align="left" >
                                Description
                            </TableCell>
                            <TableCell align="center" >
                                Value
                            </TableCell>
                            <TableCell align="center" >
                                Chart Account
                            </TableCell>
                            <TableCell align="center" >
                                Add By
                            </TableCell>
                            <TableCell align="center" >
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {discounts && discounts.length > 0 && discounts.map((data) => {
                            return (
                                <TableRow>
                                    <TableCell align="left" >
                                        {data?.name}
                                    </TableCell>
                                    <TableCell align="left" >
                                        {data?.description}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {`${data?.type === "$" ? `₹${data?.amount}` : `${data?.percent}%`}`}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {data?.chartAccount?.name}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {data?.isPublic ? "RealList" : data?.user?.displayName}
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "50px" }} >
                                        <MyPopOver
                                            closeOnClick={true}
                                            appearContent={<span className={classes.iconBtn} >
                                                <IconButton>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </span>}
                                            showContent={<div style={{ padding: "5px 0px" }} >
                                                <div
                                                    className={classes.singleOption}
                                                    onClick={() => { discountAction("edit", data) }}
                                                >
                                                    Edit
                                                </div>
                                                <div
                                                    className={classes.singleOption}
                                                    onClick={() => { discountAction("delete", data) }}
                                                >
                                                    Delete
                                                </div>
                                            </div>}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.paginationCont} >
                <Pagination
                    count={totalPages}
                    page={curPage}
                    color="primary"
                    siblingCount={0}
                    onChange={(event, value) => { setCurPage(value) }}
                />
            </div>

            {/* after create  disccount relation drawer */}
            <TaxDiscountCreateDrawer
                open={createDiscountDrawer}
                setOpen={setCreateDiscountDrawer}
                libraryId={libraryId}
                profileId={profileId}
                walletId={wallet}
                curEditDeleteTax={curEditDeleteDiscount}
                onSelect={(value) => {
                    afterDiscountCreateOrEdit()
                }}
                createType={"Discount"}
            />
            {/* after create  disccount relation drawer */}

            {/* confirm box to delete offering and offering relation */}
            <DeleteConfirmBox
                open={deleteConfirmBox}
                setOpen={setDeleteConfirmBox}
                loading={loader}
                pageTitle={`Delete ${curEditDeleteDiscount?.name}`}
                cantent={
                    <div className={classes.deleteConfirm} >
                        <ReportProblemIcon />
                        <h3>Are you sure?</h3>
                        <p>You won't be able to revert this!</p>
                    </div>
                }
                cancleConfirmDelete={() => {
                    setDeleteConfirmBox(false)
                }}
                confirmDelete={deleteDiscount}
            />
            {/* confirm box to delete offering and offering relation */}
        </div>
    );
}