import { Box, Typography } from "@mui/material";
import React from "react";
import FormBox from "./FormBox";
import Button from "./Button";

const LabelWithSingleActionButton = ({
  label,
  caption,
  actionButtonText,
  actionButtonListener,
  actionButtonIcon,
  actionButtonVariant = "text",
  captionTypographyVariant = "subtitle1",
  actionButtonProps,
  ...props
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ marginBottom: caption ? "auto" : "10px" }}
      {...props}
    >
      <FormBox label={label} disableMargins={!caption}>
        {caption && (
          <Typography variant={captionTypographyVariant}>{caption}</Typography>
        )}
      </FormBox>
      <Box sx={{ alignSelf: "flex-start" }}>
        <Button
          startIcon={actionButtonIcon}
          onClick={() => {
            if (actionButtonListener) {
              actionButtonListener();
            }
          }}
          variant={actionButtonVariant}
          color="primary"
          style={{
            textTransform: "none",
            ...actionButtonProps,
          }}
        >
          {actionButtonText}
        </Button>
      </Box>
    </Box>
  );
};

export default LabelWithSingleActionButton;
