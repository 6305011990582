import React from "react";
import { Switch, Route } from "react-router-dom";
import BasicPrivateRoute from "../components/PrivateRoute/BasicPrivateRoute";
import PublicPrivateRoute from "../components/PrivateRoute/PublicPrivateRoute";
import Search from "../components/profile/advanced.search";
import ProfileView from "../components/profile/profile.view";
import CommunityPage from "../components/community/CommunityPage";
import AuthBox from "../components/auth/AuthBox";
import PostPageLayout from "../components/post/PostPageLayout";
import Forum from "../components/community/Forum";
import SchedulerFormTester from "../components/scheduler/SchdeulerFormTester";
import PublicProfileView from "../components/profile/public.profile.view";
import PreLogin from "../components/preLogin/PreLogin";
import PublicPostView from "../components/profile/public.post.view";
import DocResources from "../components/resources/Doc.Resources";
import SinglePostView from "../components/post/Single.Post.View";
import DocPostView from "../components/doc/Doc.Post.View";

import ListingsHome from "../components/listing/ListingsHome";
import Listing from "../components/listing/listings/listing/Listing";
import EditRequirement from "../components/listing/editrequirement/EditRequirement";
import EditListing from "../components/listing/editlisting/EditListing";
import RequirementListings from "../components/listing/requirements/listings/RequirementListings";

const Routes = () => {
  return [
    <BasicPrivateRoute
      exact
      path="/feed"
      component={PostPageLayout}
      useBothSide
    />,
    <BasicPrivateRoute
      exact
      path="/explore/forum"
      component={Forum}
      useBothSide
    />,
    <BasicPrivateRoute
      exact
      path="/explore/doc/resources"
      component={DocResources}
      useBothSide={true}
    />,
    <BasicPrivateRoute
      exact
      path="/explore/doc/resources/:categorySlug"
      component={DocResources}
      useBothSide={true}
    />,
    <BasicPrivateRoute
      exact
      path="/explore/forum/communities/:communityNameSlug"
      component={CommunityPage}
      useBothSide
    />,
    <PublicPrivateRoute
      path="/explore/forum/post/:curPostId"
      component={SinglePostView}
      useBothSide
      PublicComponent={PublicPostView}
    />,
    <BasicPrivateRoute
      exact
      path="/explore/doc/:docId"
      component={DocPostView}
      useBothSide={true}
    />,
    <PublicPrivateRoute
      exact
      path="/profile/view/:profileId"
      component={ProfileView}
      PublicComponent={PublicProfileView}
      useBothSide={true}
    />,
    <BasicPrivateRoute exact path="/temp" component={SchedulerFormTester} />,
    <Route exact path="/explore/test" component={AuthBox} />,
    <Route exact path="/pre-login" component={PreLogin} />,
    <BasicPrivateRoute exact path="/search" component={Search} useBothSide />,
    <BasicPrivateRoute
      exact
      path="/search/:searchSlug"
      component={Search}
      useBothSide
    />,
    <BasicPrivateRoute
      exact
      path="/listings"
      component={ListingsHome}
      useBothSide
    />,

    <BasicPrivateRoute
      exact
      path="/listing/edit/:listingId"
      noAppbar={true}
      component={EditListing}
      useBothSide
    />,
    <BasicPrivateRoute
      exact
      path="/listing/:listingId"
      component={Listing}
      useBothSide
    />,
    <BasicPrivateRoute
      exact
      path="/listing/requirement/:requirementId"
      component={EditRequirement}
      useBothSide
    />,
    <BasicPrivateRoute
      exact
      path="/requirements/listings/:requirementId"
      component={RequirementListings}
      useBothSide
    />,
  ];
};
export default Routes;
