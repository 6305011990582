import { Typography } from "@mui/material";
import React from "react";

const AddressText = ({ location, variant = "subtitle1", ...props }) => {
  return (
    <Typography variant={variant} {...props}>
      {location?.region && `${location.region}, `}
      {location?.city && `${location.city}, `}
      {location?.country && `${location.country} `}
    </Typography>
  );
};

export default AddressText;
