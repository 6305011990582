import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
  Paper,
  useMediaQuery,
  makeStyles,
} from "@material-ui/core";
import { Autocomplete, Grid } from "@mui/material";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import StandardDialogContainer from "../../styled/generic/StandardDialogContainer";
import Dialog from "../../styled/generic/Dialog";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import WorkspaceApp from "../../styled/generic/WorkspaceApp";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "25px",
    marginBottom: "20px",
    "& h2": {
      fontSize: "15px",
      marginTop: "15px",
    },
    "& h3": {
      fontSize: "22px",
      fontWeight: "700",
      marginBottom: "20px",
    },
    "& h4": {
      fontSize: "18px",
      fontWeight: "510",
    },
    "& h5": {
      fontSize: "15px",
      fontWeight: "500",
    },
    "& h6": {
      fontSize: "13px",
      fontWeight: "400",
      color: "gray",
    },
    "& p": {
      fontSize: "15px",
    },
  },
  cardRoot: {
    width: "100%",
    minHeight: "170px",
    padding: "15px",
    borderRadius: "5px",
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  cardSubTitle: {
    fontSize: 15,
  },
  cardTopHeading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > h3": {
      fontWeight: "510",
      fontSize: "17px",
      color: "#193B56",
    },
  },
  createBtn: {
    width: "25px",
    height: "25px",
    borderRadius: "6px",
    backgroundColor: "#c1def6",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#70b8f4",
      color: "white",
    },
  },
  allProjectsCont: {
    width: "100%",
    padding: "25px",
    marginBottom: "20px",
  },
  headerSty: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h3": {
      fontSize: "22px",
      fontWeight: "700",
    },
  },
  allExpEditLicense: {
    padding: "0px 30px",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      padding: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  allExpEditProject: {
    padding: "0px 30px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  singleExperienceCont: {
    display: "flex",
    alignItems: "flex-start",
    padding: "25px 5px",
  },
  imageCont: {
    marginRight: "15px",
  },
  experienceRight: {
    width: "calc(100% - 60px)",
    marginTop: "-4px",
    "& h3": {
      fontSize: "20px",
      fontWeight: "500",
    },
    "& h4": {
      fontSize: "16px",
      fontWeight: "450",
      display: "flex",
      alignItems: "center",
    },
    "& h5": {
      fontSize: "14px",
      fontWeight: "350",
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      "& h5": {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
  },
  tagCont: {
    fontSize: "14px",
    color: "gray",
  },
  credentialLink: {
    width: "175px",
    border: "1px solid gray",
    padding: "2px 0px",
    fontSize: "16px",
    fontWeight: "500",
    color: "gray",
    marginTop: "10px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
}));

const WorkspaceConfigDialog = ({
  showWorkspaceConfigDialog,
  setShowWorkspaceConfigDialog,
  title,
  configTitle,
  configAppList,
  config,
  setConfig,
  saveFunction,
  previousApps,
  setOpen,
}) => {
  const classes = useStyles();

  const [apps, setApps] = useState([]);

  useEffect(() => {
    setApps(Array.isArray(config?.apps) ? config?.apps : []);
  }, [config]);

  return (
    <Dialog
      open={showWorkspaceConfigDialog}
      setOpen={setShowWorkspaceConfigDialog}
      title="Add Apps"
      disableHeaderMargin={true}
    >
      <Box>
        <Typography variant="body1" gutterBottom>
          Configure the apps for your project
        </Typography>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {configAppList?.map((item) => (
            <Grid item xs={3}>
              <WorkspaceApp
                isSelectable={true}
                isSelected={apps?.some((app) => app?.code === item?.code)}
                onClick={() => {
                  if (apps?.find((app) => app?.code == item?.code)) {
                    let newApps = apps.filter((app) => app?.code != item?.code);
                    setApps(newApps);
                    setConfig({ ...config, apps: newApps });
                  } else {
                    let newApps = [...apps, item];
                    setApps(newApps);
                    setConfig({ ...config, apps: newApps });
                  }
                }}
                appData={item}
              />
            </Grid>
          ))}
        </Grid>
        <DuoButtonGroup
          primaryButtonText="Save"
          primaryButtonListener={() => {
            saveFunction();
          }}
          secondaryButtonText="Close"
          secondaryButtonListener={() => setOpen(false)}
        />

        {/* <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
            }}
          >
            <Box>
              <Typography id="modal-modal-title" variant="h5">
                {title}
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                style={{ marginTop: "15px" }}
              >
                {configTitle}
              </Typography>
              <Autocomplete
                multiple
                limitTags={2}
                options={configAppList}
                value={previousApps}
                onChange={(evt, value) => handleSelection(value)}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select user apps"
                    variant="outlined"
                  />
                )}
                fullWidth
              />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                style={{ marginTop: "15px" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => saveFunction()}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box> */}
      </Box>
    </Dialog>
  );
};

export default WorkspaceConfigDialog;
