import { KeyboardArrowDown } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { getColorByLabel } from "../../../helpers/colorHelper";

const StatusButton = ({ children, variant }) => {
  const { statusTextColor, statusBackgroundColor } = getColorByLabel(variant);

  return (
    <Button
      variant="outlined"
      endIcon={<KeyboardArrowDown />}
      sx={{
        borderColor: statusTextColor,
        color: statusTextColor,
        backgroundColor: statusBackgroundColor,
        "&:hover": {
          borderColor: statusTextColor,
          color: statusTextColor,
          backgroundColor: statusBackgroundColor,
        },
        "&:active": {
          borderColor: statusTextColor,
          color: statusTextColor,
          backgroundColor: statusBackgroundColor,
        },
        "&:focus": {
          borderColor: statusTextColor,
          color: statusTextColor,
          backgroundColor: statusBackgroundColor,
        },
      }}
    >
      {children}
    </Button>
  );
};

export default StatusButton;
