import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Contacts from "../../contacts";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import SpaceBetween from "../../styled/generic/SpaceBetween";
import { Box, Typography } from "@mui/material";
import { Add } from "@material-ui/icons";
import LabelWithSingleActionButton from "../../styled/generic/LabelWithSingleActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "15px",
    paddingBottom: "0px",
    backgroundColor: "white",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      width: "49%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  contactBodyCont: {
    width: "100%",
    height: "80vh",
  },
  addNewContact: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
    paddingRight: "0px",
  },
  contactCardsCont: {
    width: "100%",
    maxHeight: "calc(100% - 75px)",
    overflowY: "auto",
    padding: "10px 5px 20px",
    paddingRight: "5px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  cardSty: {
    width: "100%",
    textAlign: "center",
    borderRadius: "5px",
    padding: "10px 10px",
    margin: "0px",
    marginTop: "30px",
    position: "relative",
    zIndex: "11",
  },
  cardSkeleton: {
    width: "100%",
    height: "50px",
    margin: "0px",
    marginBottom: "20px",
  },
  searchPaperCont: {
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  cardTopHeading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingBottom: "8px",
    marginBottom: "8px",
    justifyContent: "space-between",
    borderBottom: "1px solid #e0e0e0",
    "& > h3": {
      fontWeight: "510",
      fontSize: "17px",
      color: "#193B56",
    },
  },
  createBtn: {
    width: "25px",
    height: "25px",
    borderRadius: "6px",
    backgroundColor: "#c1def6",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#70b8f4",
      color: "white",
    },
  },
}));

export default function ContactsCard({
  profileId,
  wallet,
  parent,
  parentModelName,
}) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { user } = useSelector((state) => state.auth);

  const [roles, setRoles] = useState([
    "Contractor",
    "Customer",
    "Employee",
    "Investor",
    "Lender",
    "Vendor",
    "Tenant",
  ]);
  const [openContactDialog, setOpenContactDialog] = useState(false);

  return (
    <StandardAppContainerRounded>
      <LabelWithSingleActionButton
        label="Contacts"
        actionButtonText="Add"
        actionButtonIcon={<Add />}
        actionButtonVariant="text"
        actionButtonListener={() => setOpenContactDialog(true)}
      />

      <Contacts
        profileId={profileId}
        searchWidth={"100%"}
        isAddNewBtn={false}
        roles={roles}
        wallet={wallet}
        parent={parent}
        parentModelName={parentModelName}
        searchAndAddContStyle={classes.addNewContact}
        allCardsContStyle={classes.contactCardsCont}
        singleCardStyle={classes.cardSty}
        cardSkeletonStyle={classes.cardSkeleton}
        searchPaperCont={classes.searchPaperCont}
        openContactDialog={openContactDialog}
        setOpenContactDialog={setOpenContactDialog}
      />
    </StandardAppContainerRounded>
  );
}
