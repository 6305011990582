import React, { useState } from "react";
import StandardContainer from "../styled/generic/StandardContainer";
import FormBox from "../styled/generic/FormBox";
import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  Grid,
  MenuItem,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import FormBoxWithTwoLabels from "../styled/generic/FormBoxWithTwoLabels";
import ListingCard from "../styled/generic/ListingCard";
import Android12Switch from "../styled/generic/Android12Switch";
import SpaceBetween from "../styled/generic/SpaceBetween";
import Select from "../styled/generic/Select";
import SelectElevated from "../styled/generic/SelectElevated";
import { Filter } from "@mui/icons-material";
import { Tune } from "@material-ui/icons";
import ListingCardVariant from "../styled/generic/ListingCardVariant";
import GoogleMapComponent from "../styled/CommonComponents/Google.Map";
import useLocationForm from "../styled/hooks/useLocationForm";
import PropertyTypeChooser from "../listing/editlisting/components/PropertyTypeChooser";
import {
  AMENITY_OPTIONS_TEXT_ARR,
  FLOOR_OPTIONS,
  FURNISHING_OPTIONS,
} from "../../helpers/constants";
import TextField from "../styled/generic/TextField";
import CountPicker from "../styled/generic/CountPicker";
import CheckGroup from "../styled/generic/CheckGroup";
import CheckGroupWithObj from "../styled/generic/CheckGroupWithObj";
import CheckGroupGrid from "../styled/generic/CheckGroupGrid";
import UnitAutocomplete from "../styled/CommonComponents/UnitAutocomplete";

const MAP_DRAWER_WIDTH = "45vw";
const FILTER_DRAWER_WIDTH = "30vw";

const MarketplaceExplore = () => {
  const [showMapView, setShowMapView] = useState(false);
  const [showFilterView, setShowFilterView] = useState(false);
  const [mapPos, setMapPos] = useState([]);
  const { locationForm, locations, setLocations } = useLocationForm({
    size: "small",
    singleOnly: true,
  });
  const [propertyType, setPropertyType] = useState("House / Villa");
  const [minArea, setMinArea] = useState("");
  const [maxArea, setMaxArea] = useState("");
  const [propertyAreaUnit, setPropertyAreaUnit] = useState("Sq ft");
  const [bedroomCount, setBedroomCount] = useState();
  const [bathroomCount, setBathroomCount] = useState();
  const [balconyCount, setBalconyCount] = useState();
  const [furnishings, setFurnishings] = useState([]);
  const [floors, setFloors] = useState([]);
  const [amenities, setAmenities] = useState([]);

  const handleDrawer = (drawerName) => {
    switch (drawerName) {
      case "map":
        setShowMapView(!showMapView);
        setShowFilterView(false);
        break;
      case "filter":
        setShowFilterView(!showFilterView);
        setShowMapView(false);
        break;
      default:
        break;
    }
  };

  const valuetext = (value) => {
    return `₹ ${value}`;
  };

  const minDistance = 10;

  const [priceRange, setPriceRange] = React.useState([20, 37]);

  const handlePriceRangeChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setPriceRange([
        Math.min(newValue[0], priceRange[1] - minDistance),
        priceRange[1],
      ]);
    } else {
      setPriceRange([
        priceRange[0],
        Math.max(newValue[1], priceRange[0] + minDistance),
      ]);
    }
  };

  return (
    <StandardContainer sx={{ position: "relative" }}>
      <Box
        sx={{
          ml: showFilterView ? FILTER_DRAWER_WIDTH : "0vw",
          mr: showMapView ? MAP_DRAWER_WIDTH : "0vw",
          transition: "all 0.3s ease",
        }}
      >
        <SpaceBetween
          left={
            <Box>
              {!showMapView && !showFilterView && (
                <Stack spacing={2} direction="row">
                  <SelectElevated displayEmpty size="small">
                    <MenuItem>Location</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                  </SelectElevated>

                  <SelectElevated displayEmpty size="small">
                    <MenuItem>Property Type</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                  </SelectElevated>

                  <SelectElevated displayEmpty size="small">
                    <MenuItem>Project</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                  </SelectElevated>

                  <SelectElevated displayEmpty size="small">
                    <MenuItem>Price</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                  </SelectElevated>

                  <SelectElevated displayEmpty size="small">
                    <MenuItem>Bed</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                  </SelectElevated>

                  <SelectElevated displayEmpty size="small">
                    <MenuItem>Bathroom</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                  </SelectElevated>

                  <SelectElevated displayEmpty size="small">
                    <MenuItem>Furnishing</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                  </SelectElevated>
                </Stack>
              )}
            </Box>
          }
          right={
            <Box
              fullWidth
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                variant="outlined"
                sx={{ mr: 2 }}
                startIcon={<Tune />}
                onClick={() => handleDrawer("filter")}
              >
                Filter
              </Button>
              <Select displayEmpty size="small">
                <MenuItem>Sort By</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
              </Select>
            </Box>
          }
          sx={{ mb: 3 }}
        />

        <FormBoxWithTwoLabels
          labelPrimary="Properties in bangalore"
          labelSecondary="1248 Results"
          actions={
            <Box>
              <FormControlLabel
                control={
                  <Android12Switch
                    value={showMapView}
                    onChange={(e, checked) => handleDrawer("map")}
                  />
                }
                label="Map View"
                labelPlacement="start"
              />
            </Box>
          }
        >
          {showMapView ? (
            <Grid container spacing={2}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((item) => (
                <Grid item xs={12}>
                  <ListingCardVariant />
                </Grid>
              ))}
            </Grid>
          ) : showFilterView ? (
            <Grid container spacing={2}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((item) => (
                <Grid item xs={4}>
                  <ListingCard />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container spacing={2}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((item) => (
                <Grid item xs={3}>
                  <ListingCard />
                </Grid>
              ))}
            </Grid>
          )}
        </FormBoxWithTwoLabels>
      </Box>

      <Drawer
        variant="persistent"
        anchor="right"
        open={showMapView}
        onClose={() => setShowMapView(false)}
        sx={{
          "& .MuiDrawer-root": {
            position: "absolute",
          },
          "& .MuiPaper-root": {
            position: "absolute",
          },
        }}
      >
        <Box sx={{ width: MAP_DRAWER_WIDTH }}>
          <GoogleMapComponent
            marks={mapPos}
            MakerType={"maker"}
            unitHeight="100vh"
            redius={800}
          />
        </Box>
      </Drawer>

      <Drawer
        variant="persistent"
        anchor="left"
        open={showFilterView}
        onClose={() => setShowMapView(false)}
        sx={{
          "& .MuiDrawer-root": {
            position: "absolute",
          },
          "& .MuiPaper-root": {
            position: "absolute",
          },
        }}
      >
        <Box
          sx={{
            width: FILTER_DRAWER_WIDTH,
            p: 2,
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {locationForm}
          <PropertyTypeChooser
            propertyType={propertyType}
            setPropertyType={setPropertyType}
          />
          <FormBox label="Price Range">
            <Box sx={{ px: 2 }}>
              <Slider
                getAriaLabel={() => "Price Range"}
                value={priceRange}
                onChange={handlePriceRangeChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                disableSwap
                step={15}
                marks={[
                  {
                    value: 0,
                    label: "₹0",
                  },
                  {
                    value: 20,
                    label: "₹ 10L",
                  },
                  {
                    value: 40,
                    label: "₹ 50L",
                  },
                  {
                    value: 60,
                    label: "₹ 1Cr",
                  },
                  {
                    value: 80,
                    label: "₹ 5Cr",
                  },
                  {
                    value: 100,
                    label: "₹ 10Cr+",
                  },
                ]}
              />
            </Box>
          </FormBox>
          <FormBox label="Area">
            <TextField
              placeholder="Min"
              type="Number"
              sx={{ mr: 2 }}
              value={minArea}
              onChange={(e) => setMinArea(e.target.value)}
            />
            <TextField
              placeholder="Max"
              type="Number"
              sx={{ mr: 2 }}
              value={maxArea}
              onChange={(e) => setMaxArea(e.target.value)}
            />
          </FormBox>
          <FormBox label="Area Unit">
            <UnitAutocomplete
              value={propertyAreaUnit}
              onChange={(evt, value) => setPropertyAreaUnit(value)}
              size="large"
              fullWidth={true}
            />
          </FormBox>

          <CountPicker
            countFrom={1}
            countTo={5}
            label="Bedrooms"
            count={bedroomCount}
            setCount={(val) => setBedroomCount(val)}
            showMoreButton={true}
          />

          <CountPicker
            countFrom={1}
            countTo={5}
            label="Bathrooms"
            count={bathroomCount}
            setCount={(val) => setBathroomCount(val)}
            showMoreButton={true}
          />

          <CountPicker
            countFrom={1}
            countTo={5}
            label="Balcony"
            count={balconyCount}
            setCount={(val) => setBalconyCount(val)}
            showMoreButton={true}
          />

          <CheckGroup
            label="Furnishing"
            options={FURNISHING_OPTIONS}
            selectedOptions={furnishings}
            setSelectedOptions={setFurnishings}
          />

          <CheckGroupWithObj
            label="Floor"
            options={FLOOR_OPTIONS}
            selectedOptions={floors}
            setSelectedOptions={setFloors}
          />

          <CheckGroupGrid
            label="Amenities"
            options={AMENITY_OPTIONS_TEXT_ARR}
            selectedOptions={amenities}
            setSelectedOptions={setAmenities}
            xs={12}
          />
        </Box>
      </Drawer>
    </StandardContainer>
  );
};

export default MarketplaceExplore;
