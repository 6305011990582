import { Button, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import BasicInfo from "./GenericQuestions/BasicInfo";
import ConfigApps from "./GenericQuestions/ConfigApps";
import { getAppsForWorkspace } from "../styled/generic/Api.call";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getWorkspaceConfigById, updateWorkspaceAndWalletForUser, updateUserData, updateProfileData } from "./api.call";
import * as yup from "yup";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const basicDataValidSchema = yup.object().shape({
  firstName: yup.string().min(3, 'First name should be 3 characters minimum.').max(30, 'First name should have less then 30 characters.').required("First name should be required"),
  lastName: yup.string().max(30, 'Last name should have less then 30 characters.'),
  phoneNo: yup.string().matches(phoneRegExp, 'Phone number is not valid').min(12, "too short").max(15, "too long"),
  username: yup.string().max(40, 'username should have less then 40 characters.').min(5, 'username should be 5 characters minimum.').required("username should be required")
})

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2rem 2rem 0.5rem",
    height: "100%",
    width: "100%",
    backgroundColor: "white",
    transition: "all .5s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      padding: "1.5rem 1.5rem 0.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 1rem 0.5rem",
    },
  },
  mainContainer: {
    height: "calc( 100% - 140px )",
  },
  btnsCont: {
    height: "140px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& .MuiButton-root": {
      width: "400px",
      marginTop: "10px"
    },
    "& .MuiButton-label": {
      textTransform: "capitalize"
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiButton-root": {
        width: "90%"
      },
    }
  },
  stepper: {
    width: "200px",
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "rgba(255, 255, 255, 0)"
  },
}));

const PreLogin = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const { auth } = useSelector((state) => state);
  const { user } = auth;

  const [activeStep, setActiveStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(2);
  const [basicUserData, setBasicUserData] = useState({})
  const [basicValidObject, setBasicValidObject] = useState({});
  const [isBasicValid, setIsBasicValid] = useState(false);
  const [isAppConfigValid, setIsAppConfigValid] = useState(false);
  const [userWorkSpaceApps, setUserWorkSpaceApps] = useState([])
  const [selectedApps, setSelectedApps] = useState([])
  const [workspaceConfig, setWorkspaceConfig] = useState(null)
  const [loader, setLoader] = useState(false)

  //to set basicData
  useEffect(() => {
    if (user?._id) {
      let fullName = user?.displayName || ""
      let firstName = user?.firstName
      let lastName = user?.lastName

      let strArr = fullName.split(" ")
      let strFirstName = strArr[0]
      let strLastName = strArr[1] || "";
      for (let i = 2; i < strArr.length; i++) {
        strLastName = strLastName + " " + strArr[i]
      }
      strLastName = strLastName.replace(/\s+/g, ' ').trim()
      console.log(fullName, firstName, lastName,  strLastName )
      setBasicUserData({
        firstName: (firstName?.length > 2) ? firstName : strFirstName,
        lastName: (firstName?.length > 2) ? lastName : strLastName,
        phoneNo: user?.phone,
        username: user?.username
      })
    }
  }, [user, user?.displayName, user?.phone, user?.username])

  //to get already selected apps
  useEffect(() => {
    if (user?.workspaceConfig) {
      getWorkspaceConfigById({ workspaceConfigId: user?.workspaceConfig })
        .then((data) => {
          setWorkspaceConfig(data)
          setSelectedApps(data?.apps || [])
          if (data?.apps && data?.apps.length > 0) {
            setIsAppConfigValid(true)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [user])

  //to get all workspaceApps
  useEffect(() => {
    getAppsForWorkspace({ parentmodel: "User" })
      .then((data) => {
        setUserWorkSpaceApps(data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])

  //to handle bottom next button
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  //to handle bottom back button
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (activeStep === 0) {
      if (isBasicValid) {
        await updateUserBasicData();
      }
    } else if (activeStep === 1) {
      if (isAppConfigValid) {
        await updateWorkspaceConfig();
      }
    } else {
      console.log("wrong set");
    }
  }

  //to update basic data
  const updateUserBasicData = async () => {
    await basicDataValidSchema.validate(basicUserData, { abortEarly: false })
      .then(async (data) => {
        setLoader(true)
        await updateProfileData({
          _id: user?.profile,
          username: basicUserData?.username
        })
          .then(async (profileUpdateRes) => {
            await updateUserData({
              _id: user?._id,
              displayName: `${basicUserData?.firstName} ${basicUserData?.lastName}`,
              firstName: basicUserData?.firstName,
              lastName: basicUserData?.lastName,
              phone: basicUserData?.phoneNo,
              username: basicUserData?.username
            })
              .then(async (userUpdateRes) => {
                dispatch({
                  type: "AddAuth",
                  payload: {
                    user: {
                      ...user,
                      displayName: `${basicUserData?.firstName} ${basicUserData?.lastName}`,
                      phone: basicUserData?.phoneNo,
                      username: basicUserData?.username,
                      firstName: basicUserData?.firstName,
                      lastName: basicUserData?.lastName,
                    }
                  }
                });
                setLoader(false)
                handleNext();
              })
              .catch((err) => {
                console.log(err)
                setLoader(false)
              })
          })
          .catch((err) => {
            console.log(err)
            setLoader(false)
          })
      })
      .catch((err) => {
        console.log(err)
        let errorArr = err?.inner
        let obj = {}
        errorArr.map((err) => {
          obj[err?.path] = err?.message
        })
        setBasicValidObject(obj)
      })
  }

  //to update workspace apps api call(step==2)
  const updateWorkspaceConfig = async () => {
    setLoader(true)
    await updateWorkspaceAndWalletForUser({
      userId: user?._id,
      userProfileId: user?.profile,
      selectedApps: selectedApps,
    })
      .then((data) => {
        if (data?.userUpdateRes) {
          dispatch({
            type: "AddAuth",
            payload: { user: data?.userUpdateRes }
          });
        }
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Profile saved successfully!",
          },
        });
        setLoader(false);
        history.push('/')

      })
      .catch((err) => {
        console.log(err)
        setLoader(false)
      })
  }

  return (
    <form className={classes.root} onSubmit={onSubmit} >
      <div className={classes.mainContainer}>
        {activeStep == 0 && (
          <BasicInfo
            activeStep={activeStep}
            setIsBasicValid={setIsBasicValid}
            basicUserData={basicUserData}
            setBasicUserData={setBasicUserData}
            basicValidObject={basicValidObject}
            setBasicValidObject={setBasicValidObject}
          />
        )}
        {activeStep == 1 && (
          <ConfigApps
            activeStep={activeStep}
            userWorkSpaceApps={userWorkSpaceApps}
            selectedApps={selectedApps}
            setSelectedApps={setSelectedApps}
            setIsAppConfigValid={setIsAppConfigValid}
          />
        )}
      </div>

      <div className={classes.btnsCont} >
        <div style={{ width: "100%", height: "4px" }} >
          {loader && (<LinearProgress />)}
        </div>
        <MobileStepper
          variant="dots"
          steps={totalSteps}
          position="static"
          activeStep={activeStep}
          className={classes.stepper}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={loader ? true : activeStep === 0 ? !isBasicValid : !isAppConfigValid}
        >
          {activeStep === 0 ? "Next" : "Get Started"}
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={handleBack}
          disabled={loader ? true : activeStep === 0}
          style={{ marginTop: "25px" }}
        >
          Back
        </Button>
      </div>
    </form>
  );
};

export default PreLogin;