import { makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
import "react-phone-input-2/lib/style.css";
import WorkspaceApp from "../../styled/generic/WorkspaceApp";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        transition: "all .5s ease-in-out",
    },
    header: {
        "& p": {
            color: "grey",
            fontSize: "1rem",
        },
        "& h1": {
            fontSize: "1.75rem",
        },
        [theme.breakpoints.down("sm")]: {
            "& p": {
                fontSize: "0.9rem",
            },
            "& h1": {
                fontSize: "1.3rem",
            },
        },
        [theme.breakpoints.down("xs")]: {
            "& p": {
                fontSize: "0.7rem",
            },
            "& h1": {
                fontSize: "1rem",
            },
        },
    },
    formContainer: {
        height: "100%",
        width: "100%",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& .MuiFormControl-root": {
            marginBottom: theme.spacing(1),
        },
    },
    cardsCont: {
        height: "calc(100% - 90px)",
        width: "100%",
        marginTop: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },
    mainCardCont: {
        width: "900px",
        height: "100%",
        overflowY: "auto",
        display: "flex",
        flexWrap: "wrap",
    },
    singleCard: {
        width: "150px",
        height: "150px",
        padding: "15px"
    }
}));

const ConfigApps = ({ activeStep, userWorkSpaceApps, selectedApps, setSelectedApps, setIsAppConfigValid }) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { auth } = useSelector((state) => state);
    const { user } = auth;

    return (
        <div className={classes.root}>
            <Grow
                in={activeStep == 1}
                {...(activeStep == 1 ? { timeout: 1000 } : {})}
            >
                <div className={classes.header}>
                    <h1>Add Apps</h1>
                    <p>Configure the apps for your workspace</p>
                </div>
            </Grow>
            <Grow
                in={activeStep == 1}
                style={{ transformOrigin: "0 0 0" }}
                {...(activeStep == 1 ? { timeout: 1000 } : {})}
            >
                <div className={classes.cardsCont}>
                    <div className={classes.mainCardCont} >
                        <Grid container spacing={2}>
                            {userWorkSpaceApps?.map((item) => (
                                <Grid item xs={3}>
                                    <WorkspaceApp
                                        isSelectable={true}
                                        onClick={() => {
                                            let arr = [];
                                            if (selectedApps.includes(item?._id)) {
                                                arr = selectedApps.filter(s => s != item?._id)
                                                setSelectedApps(arr)
                                            } else {
                                                arr = [...selectedApps, item?._id]
                                                setSelectedApps(arr)
                                            }
                                            if (arr && arr.length > 0) {
                                                setIsAppConfigValid(true)
                                            } else {
                                                setIsAppConfigValid(false)
                                            }
                                        }}
                                        isSelected={selectedApps.includes(item?._id)}
                                        appData={item}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </div>
            </Grow>
        </div>
    );
};

export default ConfigApps;