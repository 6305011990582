import React, { useEffect, useState } from "react";
import {
  Drawer,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  IconButton,
  Typography,
  TextField,
  makeStyles,
  Tooltip,
  styled,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import LocationIcon from "@material-ui/icons/LocationSearching";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import AlarmOffIcon from "@material-ui/icons/AlarmOff";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import Api from "../../helpers/Api";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import GoogleMapComponent from "../styled/CommonComponents/Google.Map";
import { useDebounce } from "react-use";

const useStyles = makeStyles((theme) => ({
  input: {
    background: "#ffffff",
  },
}));

const ClockInButton = styled(Button)(({ theme, disabled }) => ({
  color: "#ffffff",
  backgroundColor: disabled ? "#a3a3a3" : "#16a34a",
  "&:hover": {
    backgroundColor: disabled ? "#a3a3a3" : "#15803d",
  },
}));

const ClockOutButton = styled(Button)(({ theme, disabled }) => ({
  color: "#ffffff",
  backgroundColor: disabled ? "#a3a3a3" : "#dc2626",
  "&:hover": {
    backgroundColor: disabled ? "#a3a3a3" : "#b91c1c",
  },
}));

const TimeSheetDrawer = ({
  isDrawerOpen,
  setIsDrawerOpen,
  timesheet,
  setTimesheet,
}) => {
  const classes = useStyles();
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(open);
  };

  const [position, setPosition] = useState(null);
  const [positions, setPositions] = useState([]);

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let coords = position.coords;

          if (!coords || !coords.latitude || !coords.longitude) {
            return;
          }

          let posObj = {
            latitude: coords?.latitude,
            longitude: coords?.longitude,
            label: "Location", //moment().format("hh:mm A").toString(),
            character: timesheet?.user?.displayName
              ? timesheet.displayName.charAt(0)
              : "U",
            marker_color: "FF5F1F",
            marker_text_color: "ffffff",
          };
          setPosition(posObj);
          setPositions([...positions, posObj]);
        },
        (error) => {
          console.error(error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const [workingHours, setWorkingHours] = useState("0 hrs 0 mins 0 secs");
  const dispatch = useDispatch();

  // const calculateWorkingHours = (start, finish) => {
  //   if (start && finish) {
  //     const date1 = moment(start);
  //     const date2 = moment(finish);
  //     const dateDiff = date2.diff(date1);
  //     const diff = moment.duration(dateDiff);
  //     const duration = moment.duration(diff);
  //     const hours = duration.hours();
  //     const minutes = duration.minutes();
  //     const seconds = duration.seconds();
  //     const diffStr = `${hours} hrs ${minutes} mins ${seconds} secs`;
  //     setWorkingHours(diffStr);
  //   } else {
  //     setWorkingHours("0 hrs 0 mins 0 secs");
  //   }
  // };

  // useEffect(() => {
  //   let updater;

  //   if (timesheet?.clockInTime && timesheet?.clockOutTime) {
  //     calculateWorkingHours(timesheet?.clockInTime, timesheet?.clockOutTime);
  //   } else {
  //     if (timesheet?.clockInTime && !timesheet?.clockOutTime) {
  //       updater = setInterval(() => {
  //         calculateWorkingHours(timesheet?.clockInTime, new Date());
  //       }, 1000);
  //     } else {
  //       setWorkingHours("0 hrs 0 mins 0 secs");
  //     }
  //   }

  //   return () => {
  //     clearInterval(updater);
  //   };
  // }, [timesheet]);

  const getPeriodSum = () => {
    if (
      timesheet &&
      timesheet.clockPeriods &&
      timesheet.clockPeriods.length > 0
    ) {
      let totalDuration = 0;

      for (let i = 0; i < timesheet.clockPeriods.length; i++) {
        let period = timesheet.clockPeriods[i];
        const { clockInTime, clockOutTime } = period;
        if (clockInTime && clockOutTime) {
          const date1 = moment(clockInTime);
          const date2 = moment(clockOutTime);
          const diff = date2.diff(date1);
          totalDuration += diff;
        }
      }
      return totalDuration;
    } else {
      return 0;
    }
  };

  const calculateWorkingHours = () => {
    const diff = getPeriodSum();
    const duration = moment.duration(diff);
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const diffStr = `${hours} hrs ${minutes} mins ${seconds} secs`;
    setWorkingHours(diffStr);
  };

  const calculateWorkingHoursLive = (start) => {
    if (start) {
      const date1 = moment(start);
      const date2 = moment();
      const dateDiff = date2.diff(date1) + getPeriodSum();

      const duration = moment.duration(dateDiff);
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      const diffStr = `${hours} hrs ${minutes} mins ${seconds} secs`;
      setWorkingHours(diffStr);
    } else {
      setWorkingHours("0 hrs 0 mins 0 secs");
    }
  };

  useEffect(() => {
    let updater;
    let periods = timesheet?.clockPeriods;

    if (!periods || periods.length < 1) {
      return;
    }

    let lastPeriod = periods[periods.length - 1] || null;
    if (lastPeriod?.clockInTime && lastPeriod?.clockOutTime) {
      calculateWorkingHours();
    } else {
      if (lastPeriod?.clockInTime && !lastPeriod?.clockOutTime) {
        updater = setInterval(() => {
          calculateWorkingHoursLive(lastPeriod?.clockInTime);
        }, 1000);
      } else {
        setWorkingHours("0 hrs 0 mins 0 secs");
      }
    }

    return () => {
      clearInterval(updater);
    };
  }, [timesheet]);

  useEffect(() => {
    if (
      timesheet &&
      timesheet.clockPeriods &&
      timesheet.clockPeriods.length > 0
    ) {
      let periods = timesheet.clockPeriods;
      let lastPeriod = periods[periods.length - 1];
      if (lastPeriod) {
        if (lastPeriod.clockInTime && lastPeriod.clockOutTime) {
          setEnableClockIn(true);
          setEnableClockOut(false);
        } else if (lastPeriod.clockInTime && !lastPeriod.clockOutTime) {
          setEnableClockIn(false);
          setEnableClockOut(true);
        } else if (!lastPeriod.clockInTime && lastPeriod.clockOutTime) {
          setEnableClockIn(false);
          setEnableClockOut(true);
        } else {
          // NO-OP
        }
      }
    }
  }, []);

  const clockIn = async () => {
    handleGetLocation();
    try {
      enableClockButton("ClockIn");
      const { data } = await Api.post("/timesheet/clockin", {
        timesheetId: timesheet._id,
        location: {
          type: "Point",
          coordinates: [position?.latitude, position?.longitude],
        },
      });
      setTimesheet(data);
    } catch (err) {
      console.log(err)
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to clock in",
        },
      });
    }
  };

  const clockOut = async () => {
    handleGetLocation();
    try {
      enableClockButton("ClockOut");
      const { data } = await Api.post("/timesheet/clockout", {
        timesheetId: timesheet._id,
        location: {
          type: "Point",
          coordinates: [position?.latitude, position?.longitude],
        },
      });
      setTimesheet(data);
    } catch (err) {
      console.log(err)
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to clock out",
        },
      });
    }
  };

  const [note, setNote] = useState(timesheet?.note || "");
  const [noteSaving, setNoteSaving] = useState(false);

  const updateTimesheet = async () => {
    if (!note || note === "") {
      return;
    }
    try {
      setNoteSaving(true);
      const { data } = await Api.post("/timesheet/update", {
        updateBody: { note: note },
      });
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to clock in",
        },
      });
    } finally {
      setNoteSaving(false);
    }
  };

  useDebounce(
    () => {
      updateTimesheet();
    },
    500,
    [note]
  );

  const [enableClockIn, setEnableClockIn] = useState(true);
  const [enableClockOut, setEnableClockOut] = useState(false);

  const enableClockButton = (button) => {
    if (button === "ClockIn") {
      setEnableClockIn(false);
      setEnableClockOut(true);
    }
    if (button === "ClockOut") {
      setEnableClockIn(true);
      setEnableClockOut(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={toggleDrawer(false)}
      PaperProps={{ style: { width: "40vw", backgroundColor: "#f5f5f5" } }}
    >
      <Box padding={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">
            {timesheet?.scope === "Organization"
              ? timesheet?.organization?.displayName + " 's Timesheet"
              : timesheet?.title}
          </Typography>
          <Box display="flex" alignItems="center">
            {/* <Tooltip title="Clock In" arrow>
              <IconButton onClick={clockIn} disabled={!enableClockIn}>
                <AlarmOnIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Clock Out" arrow>
              <IconButton onClick={clockOut} disabled={!enableClockOut}>
                <AlarmOffIcon />
              </IconButton>
            </Tooltip> */}

            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <ClockInButton
            size="small"
            variant="contained"
            fullWidth
            onClick={clockIn}
            disabled={!enableClockIn}
            style={{ marginRight: "10px" }}
          >
            Clock In
          </ClockInButton>
          <ClockOutButton
            size="small"
            variant="contained"
            fullWidth
            onClick={clockOut}
            disabled={!enableClockOut}
          >
            Clock Out
          </ClockOutButton>
        </Box>
        <Box style={{ marginTop: "15px" }}>
          <Typography variant="subtitle2">Clock Period</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box style={{ marginTop: "5px" }}>
              <DateTimePicker
                label="Clock In Time"
                value={dayjs(timesheet?.clockInTime) || null}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                    style: { backgroundColor: "#ffffff" },
                  },
                }}
                readOnly
              />
            </Box>
            <Box style={{ marginTop: "10px" }}>
              <DateTimePicker
                label="Clock Out Time"
                value={dayjs(timesheet?.clockOutTime) || null}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                    style: { backgroundColor: "#ffffff" },
                  },
                }}
                readOnly
              />
            </Box>
          </LocalizationProvider>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ marginTop: "10px" }}
          >
            <Typography variant="subtitle2">
              Total: <span style={{ color: "#dc2626" }}>{workingHours}</span>
            </Typography>
          </Box>
        </Box>
        <Box style={{ marginTop: "15px" }}>
          <Typography variant="subtitle2">Location</Typography>
          <Box>
            <GoogleMapComponent
              marks={positions}
              MakerType={"maker"}
              height={300}
              redius={800}
            />
          </Box>
        </Box>
        <Box style={{ marginTop: "15px" }}>
          <Typography variant="subtitle2">Notes</Typography>
          <TextField
            variant="outlined"
            multiline
            rows={6}
            fullWidth
            placeholder="Add notes"
            InputProps={{ className: classes.input }}
            style={{ marginTop: "10px" }}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            helperText={noteSaving ? "Saving..." : "Saved"}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default TimeSheetDrawer;
