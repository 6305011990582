import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles"
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SelectTree from "../styled/CommonComponents/SelectTree";
import { mainRoles } from "../../helpers/contactHelper"

const useStyles = makeStyles((theme) => ({
    roleAccessRoleCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap"
    },
    singleInputCont: {
        marginBottom: "5px",
        marginTop: "15px",
    },
    lableSty: {
        fontSize: "16px",
        fontWeight: "500",
    },
    mainTreeCont: {
        width: "100%",
        maxHeight: "calc(100% - 150px)",
        overflowX: "hidden",
        overflowY: "auto",
        backgroundColor: "#f9f9f9",
        border: "1px solid #dfdfdf",
        padding: "10px 15px",
        borderRadius: "5px"
    }
}));

const ParticipantAddBody = ({
    accessRoles, financialRelationType, setFinancialRelationType, selectedAcccessIds,
    setSelectedAcccessIds, selectedRoles, setSelectedRoles, accessOptions, isFullScreen = false
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        let locSelectedOptionIds = [];
        selectedRoles.map((roleObj) => {
            let locPermissions = roleObj?.permissions || [];
            locPermissions.map((item) => {
                locSelectedOptionIds.push(item.identifier)
                if (item?.fields && item.fields.length > 0) {
                    let arr = []
                    item.fields.map((fItem) => {
                        arr.push(`${item.identifier}-${fItem}`);
                    })
                    locSelectedOptionIds = [...locSelectedOptionIds, ...arr]
                }
            })
        })
        let selectedIdsSet = new Set([...locSelectedOptionIds]);
        setSelectedAcccessIds([...selectedIdsSet])
    }, [selectedRoles])

    return (
        <>
            <div className={classes.roleAccessRoleCont} >
                <div className={classes.singleInputCont} style={isFullScreen ? { width: "49%" } : { width: "100%" }} >
                    <p className={classes.lableSty} >Role<span style={{color: "red"}} >*</span></p>
                    <Autocomplete
                        value={financialRelationType}
                        options={mainRoles}
                        getOptionLabel={(option) => { return option || "" }}
                        getOptionSelected={(option) => {
                            return option == financialRelationType
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ margin: "0px" }}
                                margin="normal"
                                variant="outlined"
                                placeholder="Select role"
                            />
                        )}
                        onChange={(e, value) => { setFinancialRelationType(value) }}
                        size="small"
                        fullWidth
                    />
                </div>

                <div className={classes.singleInputCont} style={isFullScreen ? { width: "49%" } : { width: "100%" }} >
                    <p className={classes.lableSty} >Access Role(s)<span style={{color: "red"}} >*</span></p>
                    <Autocomplete
                        multiple
                        id="fixed-tags-demo"
                        value={selectedRoles}
                        onChange={(event, newValue) => {
                            setSelectedRoles(newValue)
                        }}
                        options={accessRoles}
                        getOptionLabel={(option) => option?.role}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.role}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        size="small"
                        fullWidth
                        renderInput={(params) => (
                            <TextField {...params}
                                variant="outlined"
                                size="small"
                                placeholder="Select Role(s)"
                            />
                        )}
                    />
                </div>
            </div>

            <p className={classes.lableSty} style={{ marginTop: "15px" }} >Accesses</p>
            <div className={classes.mainTreeCont} >
                <SelectTree
                    options={accessOptions}
                    selectedOptionIds={selectedAcccessIds}
                    setSelectedOptionIds={setSelectedAcccessIds}
                />
            </div>
        </>
    );
};

export default ParticipantAddBody; 