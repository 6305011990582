import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import DocumentCard from "../dashboard/cards/DocumentCard";
import PaymentsCard from "../dashboard/cards/PaymentsCard";
import ProjectManagementCard from "../dashboard/cards/ProjectManagementCard";
import ProjectAnalysisCard from "../dashboard/cards/Project.Analysis.Card";
import PropertyManagementUnitCard from "../propertyManagement/PropertyManagementUnitCard";
import EstimationAnalyticsCard from "../dashboard/cards/EstimationAnalyticsCard";
import BudgetCostAnalysisCard from "../dashboard/cards/BudgetCostAnalysisCard";
import { Button } from "@material-ui/core";
import SiteManagementCard from "../dashboard/cards/SiteManagementCard";
import ProcurementCard from "../dashboard/cards/ProcurementCard";
import InventoryCard from "../dashboard/cards/InventoryCard";
import WorkScheduleCard from "../dashboard/cards/WorkScheduleCard";
import OfferingsAndProductsCard from "../dashboard/cards/OfferingsAndProducts.Card";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  mainCont: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  singleCont: {
    width: "49%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const ProjectDashboard = ({
  reports,
  loader,
  docs,
  units,
  setUnits,
  projectBlocks,
  chartAccounts,
  teamId,
  transactions,
  issues,
  walletId,
  profileId,
  profile,
  setShowWorkspaceConfigDialog,
  config,
  projectData,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <div className={classes.header}>
        <Button
          onClick={() => setShowWorkspaceConfigDialog(true)}
          variant="contained"
          color="primary"
        >
          Add Apps +
        </Button>
      </div>
      <div className={classes.mainCont}>
        <div className={classes.singleCont}>
          <ProjectAnalysisCard
            reports={reports}
            loader={loader}
            teamId={teamId}
          />
        </div>
        {config &&
          config.apps &&
          config.apps.some((item) => item.code === "projectmanagement") && (
            <div className={classes.singleCont}>
              <ProjectManagementCard
                issues={issues}
                profileId={profileId}
                loader={loader}
              />
            </div>
          )}
        {config &&
          config.apps &&
          config.apps.some((item) => item.code === "accountspayments") && (
            <div className={classes.singleCont}>
              <PaymentsCard
                chartAccounts={chartAccounts}
                transactions={transactions}
                walletId={walletId}
                loader={loader}
              />
            </div>
          )}
        {config &&
          config.apps &&
          config.apps.some((item) => item.code === "propertymanagement") && (
            <div className={classes.singleCont}>
              <PropertyManagementUnitCard
                loader={loader}
                teamId={teamId}
                units={units}
                setUnits={setUnits}
              />
            </div>
          )}
        {config &&
          config.apps &&
          config.apps.some((item) => item.code === "documents") && (
            <div className={classes.singleCont}>
              <DocumentCard docs={docs} profileId={profileId} loader={loader} />
            </div>
          )}
        {config &&
          config.apps &&
          config.apps.some((item) => item.code === "estimationplanning") && (
            <div className={classes.singleCont}>
              <EstimationAnalyticsCard profile={profile} />
            </div>
          )}
        <div className={classes.singleCont}>
          <BudgetCostAnalysisCard projectBlocks={projectBlocks} />
        </div>
        <div className={classes.singleCont}>
          <SiteManagementCard profile={profile} projectData={projectData} />
        </div>
        {config &&
          config.apps &&
          config.apps.some((item) => item.code === "boq") && (
            <div className={classes.singleCont}>
              <ProcurementCard queryId={projectData._id} queryType="project" />
            </div>
          )}
        {config &&
          config.apps &&
          config.apps.some((item) => item.code === "inventory") && (
            <div className={classes.singleCont}>
              <InventoryCard queryId={projectData._id} queryType="project" />
            </div>
          )}

        <div className={classes.singleCont}>
          <WorkScheduleCard projectId={projectData._id} />
        </div>

        <div className={classes.singleCont}>
          <OfferingsAndProductsCard
             profileId={profileId}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectDashboard;
