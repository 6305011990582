import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory, useParams } from 'react-router-dom';
import { getUsersSkillsForFilter } from './api';
import CategoryFilter from "../styled/CommonComponents/Category.Filter";
import Drawer from "@material-ui/core/Drawer";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useMediaQuery } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import LocationSingleautoFilter from '../styled/CommonComponents/Location.Singleauto.Filter';
import UserSearchFeed from './User.Search.Feed';


const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: `calc(100vh - ${theme.appbar.height})`,
    display: "flex",
    padding: "5px",
    paddingTop: "25px",
    [theme.breakpoints.down('sm')]: {
      padding: "0px",
      paddingTop: "10px",
    },
    [theme.breakpoints.down('xs')]: {
      height: `calc(100vh - 120px)`,
    },
  },
  feedCont: {
    width: "730px",
    height: "100%",
    overflowY: "auto",
    padding: "0px 25px",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down('md')]: {
      width: "65%",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      padding: "0px 10px",
    },
  },
  leftSideCont: {
    width: "330px",
    height: "100%",
    overflowY: "auto",
    paddingLeft: "20px",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down('md')]: {
      width: "35%",
    }
  },
  titleSty: {
    fontSize: "20px",
    fontWeight: "600",
    color: "#00345D"
  },
  loaderCont: {
    width: "100%",
    borderTop: "1px solid #e3e0e0",
    height: "4px",
  },
  categoryCont: {
    height: "230px",
    width: "100%",
    overflowY: "auto",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
  },
  filterLocationCont: {
    height: "242px",
    width: "100%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  drawerPaper: {
    width: "300px",
    height: "100vh",
    padding: "20px 10px 0px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  arrowCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    "& p": {
      fontSize: "19px",
      fontWeight: "510"
    }
  },
  iconSty: {
    cursor: "pointer",
  }
}));


export default function S(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { filterLocationsArr, filterSkillsArr, drawerToggle } = useSelector((state) => state.profile);

  const [locationTags, setLocationTags] = useState([])
  const [filterCategories, setFilterCategories] = useState([])
  const [filterSelectedCategories, setFilterSelectedCategories] = useState([])
  const [getCategoryLoader, setCategoryLoader] = useState(false)

  const isMdSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    setLocationTags(filterLocationsArr || [])
    setFilterSelectedCategories(filterSkillsArr || [])
  }, [])

  function handleDrawerToggle() {
    dispatch({
      type: 'AddProfile',
      payload: {
        drawerToggle: !drawerToggle,
      },
    })
  }

  useEffect(() => {
    getUsersSkillsForFilter({})
      .then((data) => {

        let arr = data[0]?.category_details || []
        arr.sort((a, b) => {
          return b.count - a.count;
        })

        setFilterCategories(arr)
        setCategoryLoader(false)
      })
      .catch((err) => {
        console.log(err);
      })
  }, [])

  useEffect(() => {
    dispatch({
      type: 'AddProfile',
      payload: {
        filterLocationsArr: locationTags,
      },
    })
  }, [locationTags])

  useEffect(() => {
    dispatch({
      type: 'AddProfile',
      payload: {
        filterSkillsArr: filterSelectedCategories,
      },
    })
  }, [filterSelectedCategories])

  return (
    <div className={classes.mainCont} >
      {isMdSmall ? null : (
        <div className={classes.leftSideCont} >
          <Accordion
            style={{
              marginBottom: "20px",
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)"
            }}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ height: "50px", minHeight: "0px" }}
            >
              <h3 className={classes.titleSty}>Category</h3>
            </AccordionSummary>
            <div className={classes.loaderCont} >
            </div>
            <AccordionDetails>
              <div className={classes.categoryCont} >
                <CategoryFilter
                  selectedCategories={filterSelectedCategories}
                  setSelectedCategories={setFilterSelectedCategories}
                  categories={filterCategories}
                  getCategoryLoader={getCategoryLoader}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)"
            }}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ height: "50px", minHeight: "0px" }}
            >
              <h3 className={classes.titleSty}>Location</h3>
            </AccordionSummary>
            <div className={classes.loaderCont} >
            </div>
            <AccordionDetails>
              <div className={classes.filterLocationCont} >
                <LocationSingleautoFilter
                  locationTags={locationTags}
                  setLocationTags={setLocationTags}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
      <div className={classes.feedCont} >
        <UserSearchFeed
          locationTags={locationTags}
          filterSelectedCategories={filterSelectedCategories}
        />
      </div>


      <Drawer
        variant="temporary"
        anchor={"right"}
        open={drawerToggle}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper
        }}
        ModalProps={{
          keepMounted: true
        }}
      >
        <div className={classes.arrowCont} >
          <p>Filter</p>
          <CancelIcon
            className={classes.iconSty}
            onClick={() => {
              dispatch({
                type: 'AddProfile',
                payload: {
                  drawerToggle: !drawerToggle,
                },
              })
            }}
          />
        </div>
        <div>
          <Accordion
            style={{
              borderRadius: "7px",
              marginBottom: "20px",
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)"
            }}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ height: "50px", minHeight: "0px" }}
            >
              <h3 className={classes.titleSty}>Category</h3>
            </AccordionSummary>
            <div className={classes.loaderCont} >
            </div>
            <AccordionDetails>
              <div className={classes.categoryCont} >
                <CategoryFilter
                  selectedCategories={filterSelectedCategories}
                  setSelectedCategories={setFilterSelectedCategories}
                  categories={filterCategories}
                  getCategoryLoader={getCategoryLoader}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              borderRadius: "7px",
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)"
            }}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ height: "50px", minHeight: "0px" }}
            >
              <h3 className={classes.titleSty}>Location</h3>
            </AccordionSummary>
            <div className={classes.loaderCont} >
            </div>
            <AccordionDetails>
              <div className={classes.filterLocationCont} >
                <LocationSingleautoFilter
                  locationTags={locationTags}
                  setLocationTags={setLocationTags}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </Drawer>
    </div>
  );
}
