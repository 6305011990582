import { Box, Typography } from "@mui/material";
import React from "react";

const FormHeaderWithActions = ({
  label,
  labelTypographyVariant = "h6",
  actions,
  showBorderBottom = false,
  labelColor,
  children,
  ...props
}) => {
  return (
    <Box {...props} mt={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          borderBottom: showBorderBottom
            ? "1px solid rgba(0,0,0,0.10)"
            : "none",
        }}
      >
        <Typography variant={labelTypographyVariant} color={labelColor}>
          {label}
        </Typography>
        <Box>{actions}</Box>
      </Box>
      <Box mt={2}>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};

export default FormHeaderWithActions;
