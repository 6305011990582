import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMediaQuery } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { IconButton, Button } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import Avatar from "@material-ui/core/Avatar";
import { GiGearHammer } from "react-icons/gi";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import moment from "moment";
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import GooglePlaceAutocomplete from "../../styled/CommonComponents/Google.Place.Auto";
import {
    deletePublicProject,
    updateExpProjectAndRootProjectForPublicProject,
    updateExpProjectForPublicProject,
    updateRootProjectForPublicProject
} from "../api.call";
import ProjectPhotoEdit from "./Project.Photo.Edit";
import teamUtils from "../../team/team.utils";

const useStyles = makeStyles((theme) => ({
    singleEducationCont: {
        width: "750px",
        marginBottom: "20px",
        padding: "15px",
        marginBottom: "30px",
        position: "relative",
        paddingTop: "30px",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0px",
            paddingTop: "30px",
        },
    },
    textLineCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column"
        }
    },
    halfCont: {
        width: "47%",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }
    },
    checkedCont: {
        display: "flex",
        alignItems: "center",
        marginBottom: "-10px",
        "& h3": {
            fontSize: "15px",
            marginRight: "15px",
            fontWeight: "500",
        }
    },
    label: {
        fontSize: "15px",
        fontWeight: "500",
        marginTop: "7px",
        marginBottom: "0px"
    },
    addressCont: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    editIconBtn: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    singleExperienceCont: {
        display: "flex",
        alignItems: "flex-start",
        padding: "25px 5px"
    },
    imageContView: {
        marginRight: "15px"
    },
    experienceRight: {
        width: "calc(100% - 60px)",
        marginTop: "-4px",
        "& h3": {
            fontSize: "20px",
            fontWeight: "500"
        },
        "& h4": {
            fontSize: "16px",
            fontWeight: "450",
            display: "flex",
            alignItems: "center",
        },
        "& h5": {
            fontSize: "14px",
            fontWeight: "350",
            display: "flex",
            alignItems: "center",
        },
        [theme.breakpoints.down("xs")]: {
            "& h5": {
                flexDirection: "column",
                alignItems: "flex-start"
            }
        }
    },
}));

const calendarMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const colorArr = [
    "#F39014",
    "#0A66C2",
    "#36e055"
]

const ProjectEditSingle = ({ project, loading, setLoading, index, teamId, profile }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { handleTeams } = teamUtils;

    const state = useSelector((state) => state);
    const { teamDictionary } = useSelector((state) => state.team);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [lot, setLot] = useState("")
    const [area, setArea] = useState("")
    const [year, setYear] = useState("")
    const [price, setPrice] = useState("")
    const [fullAddressLine, setFullAddressLine] = useState("")
    const [streetAddress, setStreetAddress] = useState("")
    const [zip, setZip] = useState("")
    const [city, setCity] = useState("")
    const [region, setRegion] = useState("")
    const [regionCode, setRegionCode] = useState("")
    const [country, setCountry] = useState("India(IN)")
    const [countryCode, setCountryCode] = useState("in")
    const [latitude, setLatitude] = useState(null)
    const [longitude, setLongitude] = useState(null)
    const [projectExp, setProjectExp] = useState("");
    const [role, setRole] = useState("")
    const [startMonthVal, setStartMonthVal] = useState("")
    const [startYearVal, setStartYearVal] = useState("")
    const [endMonthVal, setEndMonthVal] = useState("")
    const [endYearVal, setEndYearVal] = useState("")
    const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false)
    const [calendarYear, setCalendarYear] = useState([]);
    const [fileObj, setFileObj] = useState([])
    const [openEditView, setOpenEditView] = useState(false)
    const [isAnyChangeInProExp, setIsAnyChangeInProExp] = useState(false)
    const [isAnyChangeInPro, setIsAnyChangeInPro] = useState(false)

    const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

    useEffect(() => {
        setTitle(project?.project?.displayName || "")
        setDescription(project?.project?.description || "")
        setLot(project?.project?.lotSize || "")
        setArea(project?.project?.area || "")
        setYear(project?.project?.year || "")
        setPrice(project?.project?.price || "")
        setStreetAddress(project?.project?.address?.streetAddress || "")
        setZip(project?.project?.address?.zip || "")
        setCity(project?.project?.address?.city || "")
        setRegion(project?.project?.address?.region || "")
        setRegionCode(project?.project?.address?.regionCode || "")
        setCountry(project?.project?.address?.country || "India(IN)")
        setLatitude(project?.project?.latitude || "")
        setLongitude(project?.project?.longitude || "")
        setProjectExp(project?.desc || "")
        setRole(project?.role || "")
        setStartMonthVal(project?.startMonth || "")
        setStartYearVal(project?.startYear || "")
        setEndMonthVal(project?.endMonth || "")
        setEndYearVal(project?.endYear || "")
        setIsCurrentlyWorking(project?.isCurrentlyWorking || false)

        let countryText = project?.project?.address?.country
        let lowCode = "us"
        if (countryText) {
            let len = countryText.length;
            let code = countryText.substring(len - 3, len - 1)
            lowCode = code.toLowerCase()
        }
        setCountryCode(lowCode || "in")

        let fileArr = project?.project?.docs || []
        let arr = []
        fileArr.map((file) => {
            arr.push({
                _id: file?._id,
                file: file?.files[0],
                title: file?.title,
                description: file?.description,
                tag: file?.tag
            })
        })
        setFileObj(arr)
    }, [project])

    useEffect(() => {
        const years = [];
        const date = new Date();
        const endYear = date.getFullYear();
        for (let i = 1951; i <= endYear; i++) {
            years.push(i + "");
        }
        setCalendarYear(years);
    }, []);

    const duration = (startMonth, startYear, isCurrentlyWorking, endMonth, endYeark) => {
        const calendarMonth = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const d = new Date();
        let endYear = d.getFullYear();
        let endMonthNum = d.getMonth() + 1

        if (!isCurrentlyWorking) {
            endMonthNum = calendarMonth.indexOf(endMonth) + 1
            endYear = endYeark
        }

        const startMonthNum = calendarMonth.indexOf(startMonth) + 1

        let sDateStr = `1.${startMonthNum}.${startYear}`
        let eDateStr = `1.${endMonthNum}.${endYear}`

        var startDate = moment(sDateStr, "DD.MM.YYYY");
        var endDate = moment(eDateStr, "DD.MM.YYYY");

        var result = endDate.diff(startDate, 'months')

        if (result > 12) {
            return `${parseInt(result / 12)} yr, ${result % 12} mos`
        } else {
            return `${result} mos`
        }
    }

    useEffect(() => {
        if (title !== project?.project?.displayName ||
            description !== project?.project?.description ||
            lot !== project?.project?.lotSize ||
            area !== project?.project?.area ||
            year !== project?.project?.year ||
            price !== project?.project?.price ||
            streetAddress !== project?.project?.address?.streetAddress ||
            zip !== project?.project?.address?.zip ||
            city !== project?.project?.address?.city ||
            region !== project?.project?.address?.region ||
            regionCode !== project?.project?.address?.regionCode ||
            country !== project?.project?.address?.country ||
            latitude !== project?.project?.latitude ||
            longitude !== project?.project?.longitude
        ) {
            setIsAnyChangeInPro(true)
        } else {
            setIsAnyChangeInPro(false)
        }

        if (projectExp !== project?.desc ||
            role !== project?.role ||
            startMonthVal !== project?.startMonth ||
            startYearVal !== project?.startYear ||
            endMonthVal !== project?.endMonth ||
            endYearVal !== project?.endYear ||
            isCurrentlyWorking !== project?.isCurrentlyWorking
        ) {
            setIsAnyChangeInProExp(true)
        } else {
            setIsAnyChangeInProExp(false)
        }
    }, [
        title, description, lot, area, year, price, streetAddress,
        zip, city, region, regionCode, country, latitude, longitude,
        projectExp, role, startMonthVal, startYearVal, endMonthVal,
        endYearVal, isCurrentlyWorking,
    ])

    const saveBasicData = async () => {
        setLoading(true)
        if (isAnyChangeInProExp && isAnyChangeInPro) {
            await updateExpProjectAndRootProjectForPublicProject({
                rootProjectId: project?.project?._id,
                rootProjectObj: {
                    displayName: title,
                    description: description,
                    lotSize: lot,
                    area: area,
                    year: year,
                    price: price,
                    latitude: latitude,
                    longitude: longitude,
                    address: {
                        streetAddress: streetAddress,
                        zip: zip,
                        city: city,
                        region: region,
                        regionCode: regionCode,
                        country: country
                    }
                },
                expProjectId: project?._id,
                expProjectObj: {
                    desc: projectExp,
                    role: role,
                    startMonth: startMonthVal,
                    startYear: startYearVal,
                    endMonth: endMonthVal,
                    endYear: endYearVal,
                    isCurrentlyWorking: isCurrentlyWorking
                }
            })
                .then((data) => {
                    if (data) {
                        let teamDataFromDic = teamDictionary[teamId]
                        let updatedExpProjectArr = teamDataFromDic?.parent?.projectExp || []
                        updatedExpProjectArr[index] = data
                        let teamParent = teamDataFromDic?.parent

                        const team = {
                            ...teamDataFromDic,
                            parent: {
                                ...teamParent,
                                projectExp: updatedExpProjectArr,
                            }
                        }
                        handleTeams([team], state, dispatch);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })

        } else if (isAnyChangeInProExp) {
            await updateExpProjectForPublicProject({
                _id: project?._id,
                desc: projectExp,
                role: role,
                startMonth: startMonthVal,
                startYear: startYearVal,
                endMonth: endMonthVal,
                endYear: endYearVal,
                isCurrentlyWorking: isCurrentlyWorking
            })
                .then((data) => {
                    if (data) {
                        let teamDataFromDic = teamDictionary[teamId]
                        let updatedExpProjectArr = teamDataFromDic?.parent?.projectExp || []
                        updatedExpProjectArr[index] = data
                        let teamParent = teamDataFromDic?.parent

                        const team = {
                            ...teamDataFromDic,
                            parent: {
                                ...teamParent,
                                projectExp: updatedExpProjectArr,
                            }
                        }
                        handleTeams([team], state, dispatch);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else if (isAnyChangeInPro) {
            await updateRootProjectForPublicProject({
                _id: project?.project?._id,
                displayName: title,
                description: description,
                lotSize: lot,
                area: area,
                year: year,
                price: price,
                latitude: latitude,
                longitude: longitude,
                address: {
                    streetAddress: streetAddress,
                    zip: zip,
                    city: city,
                    region: region,
                    regionCode: regionCode,
                    country: country
                }
            })
                .then((data) => {
                    if (data) {
                        let teamDataFromDic = teamDictionary[teamId]
                        let updatedExpProjectArr = teamDataFromDic?.parent?.projectExp || []
                        updatedExpProjectArr[index] = data
                        let teamParent = teamDataFromDic?.parent

                        const team = {
                            ...teamDataFromDic,
                            parent: {
                                ...teamParent,
                                projectExp: updatedExpProjectArr,
                            }
                        }
                        handleTeams([team], state, dispatch);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        setLoading(false)
    }

    const deleteProject = async (id) => {
        setLoading(true)
        await deletePublicProject({ projectExpId: id, orgId: profile?.parent?._id })
            .then((data) => {
                if (data) {
                    const updatedProjectExp = data?.projectExp || []
                    let teamDataFromDic = teamDictionary[teamId]
                    let teamParent = teamDataFromDic?.parent

                    const team = {
                        ...teamDataFromDic,
                        parent: {
                            ...teamParent,
                            projectExp: updatedProjectExp,
                        }
                    }
                    handleTeams([team], state, dispatch);
                }
            })
            .catch((err) => {
                console.log(err)
            })
        setLoading(false)
    }



    return (
        <div className={classes.singleEducationCont} >
            {!openEditView ? (<>
                <div className={classes.editIconBtn} >
                    <IconButton
                        onClick={() => { setOpenEditView(true) }}
                    >
                        <EditIcon />
                    </IconButton>

                    <IconButton
                        onClick={() => { deleteProject(project?._id) }}
                        style={{ marginLeft: "15px" }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>
                <div className={classes.singleExperienceCont} >
                    <Avatar
                        variant="square"
                        className={classes.imageContView}
                        style={{ backgroundColor: colorArr[index % 3] }}
                    >
                        <GiGearHammer />
                    </Avatar>
                    <div className={classes.experienceRight}>
                        <h3>{title}</h3>
                        <h5>{`lot ${lot} / area ${area} / year ${year} / price ₹${price}`}</h5>
                        <h4>{role}</h4>
                        <h5>
                            <div>{`${startMonthVal}, ${startYearVal}  -  ${isCurrentlyWorking ? "Present" : `${endMonthVal}, ${endYearVal}`}`}</div>
                            {isSmall ? null : (<FiberManualRecordIcon style={{ width: "5px", height: "5px", margin: "0px 4px" }} />)}
                            <div>{duration(startMonthVal, startYearVal, isCurrentlyWorking, endMonthVal, endYearVal)}</div>
                        </h5>
                        <h5>{streetAddress}</h5>
                        <p>{projectExp}</p>
                    </div>
                </div>
            </>) : (<>
                <IconButton className={classes.editIconBtn} onClick={() => { setOpenEditView(false) }} ><ClearIcon /></IconButton>
                <p className={classes.label} >Title</p>
                <TextField
                    required
                    id="school"
                    placeholder="Enter project name"
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value)
                    }}
                    size="small"
                    variant="outlined"
                    style={{ width: "100%" }}
                />
                <p className={classes.label} >Project Description</p>
                <TextField
                    id="description"
                    placeholder="Write anything notable..."
                    multiline
                    rows={3}
                    variant="outlined"
                    size="small"
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value);
                    }}
                    fullWidth
                />
                <GooglePlaceAutocomplete
                    inputContStyle={classes.addressCont}
                    autoWidth={"100%"}
                    textWidth={"100%"}
                    isGetLogLat={true}
                    fullAddressLine={fullAddressLine}
                    setFullAddressLine={setFullAddressLine}
                    streetAddress={streetAddress}
                    setStreetAddress={setStreetAddress}
                    zip={zip}
                    setZip={setZip}
                    city={city}
                    setCity={setCity}
                    region={region}
                    setRegion={setRegion}
                    regionCode={regionCode}
                    setRegionCode={setRegionCode}
                    country={country}
                    setCountry={setCountry}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    latitude={latitude}
                    setLatitude={setLatitude}
                    longitude={longitude}
                    setLongitude={setLongitude}
                    isShowCountry={true}
                    isShowCityStreet={true}
                    isStateZip={true}
                />
                <div className={classes.textLineCont} style={{ marginTop: "15px" }} >
                    <div className={classes.halfCont} >
                        <p className={classes.label} >Lot Size</p>
                        <TextField
                            required
                            id="school"
                            type="number"
                            placeholder="Enter lot size"
                            value={lot}
                            onChange={(e) => {
                                setLot(e.target.value)
                            }}
                            size="small"
                            variant="outlined"
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div className={classes.halfCont} >
                        <p className={classes.label} >Area</p>
                        <TextField
                            id="degree"
                            type="number"
                            placeholder="Enter area size"
                            value={area}
                            onChange={(e) => {
                                setArea(e.target.value);
                            }}
                            size="small"
                            variant="outlined"
                            style={{ width: "100%" }}
                        />
                    </div>
                </div>
                <div className={classes.textLineCont} >
                    <div className={classes.halfCont} >
                        <p className={classes.label} >Year of build</p>
                        <TextField
                            required
                            id="school"
                            type="number"
                            placeholder="Enter build year"
                            value={year}
                            onChange={(e) => {
                                setYear(e.target.value)
                            }}
                            size="small"
                            variant="outlined"
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div className={classes.halfCont} >
                        <p className={classes.label} >Project size (price)</p>
                        <TextField
                            id="degree"
                            type="number"
                            placeholder="Enter project size"
                            value={price}
                            onChange={(e) => {
                                setPrice(e.target.value);
                            }}
                            size="small"
                            variant="outlined"
                            style={{ width: "100%" }}
                        />
                    </div>
                </div>
                <p className={classes.label} >Project Role</p>
                <TextField
                    id="description"
                    placeholder="Enter your role in this Project"
                    variant="outlined"
                    size="small"
                    value={role}
                    onChange={(e) => {
                        setRole(e.target.value);
                    }}
                    fullWidth
                />
                <p className={classes.label} >Start date</p>
                <div className={classes.textLineCont} >
                    <div className={classes.halfCont} >
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            value={startMonthVal}
                            options={calendarMonth}
                            getOptionLabel={(option) => { return option }}
                            getOptionSelected={(option) => {
                                return option === startMonthVal
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    style={{ margin: "0px" }}
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={"Enter start month"}
                                />
                            )}
                            onChange={(event, value) => {
                                setStartMonthVal(value)
                            }}
                            style={{ width: "100%", marginBottom: "5px" }}
                            size="small"
                        />
                    </div>
                    <div className={classes.halfCont} >
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            value={startYearVal}
                            options={calendarYear}
                            getOptionLabel={(option) => { return option }}
                            getOptionSelected={(option) => {
                                return option === startYearVal
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    style={{ margin: "0px" }}
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={"Enter start year"}
                                />
                            )}
                            onChange={(event, value) => {
                                setStartYearVal(value)
                            }}
                            style={{ width: "100%", marginBottom: "5px" }}
                            size="small"
                        />
                    </div>
                </div>
                <div className={classes.checkedCont} >
                    <h3>Currently Working</h3>
                    <Checkbox
                        color="primary"
                        checked={isCurrentlyWorking}
                        onChange={(e) => { setIsCurrentlyWorking(e.target.checked) }}
                    />
                </div>
                {isCurrentlyWorking ? null : (<>
                    <p className={classes.label} >End date</p>
                    <div className={classes.textLineCont} >
                        <div className={classes.halfCont} >
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                value={endMonthVal}
                                options={calendarMonth}
                                getOptionLabel={(option) => { return option }}
                                getOptionSelected={(option) => {
                                    return option === endMonthVal
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        style={{ margin: "0px" }}
                                        margin="normal"
                                        variant="outlined"
                                        placeholder={"Enter End Month"}
                                    />
                                )}
                                onChange={(event, value) => {
                                    setEndMonthVal(value)
                                }}
                                style={{ width: "100%", marginBottom: "5px" }}
                                size="small"
                            />
                        </div>
                        <div className={classes.halfCont} >
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                value={endYearVal}
                                options={calendarYear}
                                getOptionLabel={(option) => { return option }}
                                getOptionSelected={(option) => {
                                    return option === endYearVal
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        style={{ margin: "0px" }}
                                        margin="normal"
                                        variant="outlined"
                                        placeholder={"Enter End Year"}
                                    />
                                )}
                                onChange={(event, value) => {
                                    setEndYearVal(value)
                                }}
                                style={{ width: "100%", marginBottom: "5px" }}
                                size="small"
                            />
                        </div>
                    </div>
                </>)}
                <p className={classes.label} >Exp Description</p>
                <TextField
                    id="description"
                    placeholder="Write anything notable..."
                    multiline
                    rows={3}
                    variant="outlined"
                    size="small"
                    value={projectExp}
                    onChange={(e) => {
                        setProjectExp(e.target.value);
                    }}
                    fullWidth
                />
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "10px 0px 20px"
                }} >
                    <div></div>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={(isAnyChangeInProExp || isAnyChangeInPro) ? false : true}
                            onClick={saveBasicData}
                        >
                            Save
                        </Button>
                    </div>
                </div>
                <ProjectPhotoEdit
                    fileObj={fileObj}
                    setFileObj={setFileObj}
                    index={index}
                    project={project}
                    loading={loading}
                    setLoading={setLoading}
                    teamId={teamId}
                />
            </>)}
        </div>);
};
export default ProjectEditSingle;