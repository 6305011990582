import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import Overview from "./overview/Overview";
import TabsWithActions from "../styled/generic/TabsWithActions";
import TabActionButton from "../styled/generic/TabActionButton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../helpers/Api";
import Bookings from "./bookings/Bookings";
import Requirements from "./requirements/Requirements";
import Listings from "./listings/Listings";

const ListingsHome = () => {
  const [listingTab, setListingTab] = useState("Overview");
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch()

  const createRequirement = async () => {
    try {
      const { data } = await Api.post("/listing/requirements/create", {
        owner: user?.profile,
      });

      if (data) {
        history.push(`/listing/requirement/${data?._id}`);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Requirement created successfully",
          },
        });
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to create requirement",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create requirement",
        },
      });
    }
  };

  return (
    <Box style={{ backgroundColor: "#F5F7FA", padding: "2rem" }}>
      <TabsWithActions
        onTabChange={(val) => setListingTab(val)}
        currentTabVariable={listingTab}
        tabs={[
          {
            title: "Overview",
          },
          {
            title: "My Listings",
          },
          {
            title: "Requirements",
            action: (
              <TabActionButton onClick={() => createRequirement()}>
                New Requirement
              </TabActionButton>
            ),
          },
          {
            title: "Bookings",
            action: <TabActionButton>New Booking</TabActionButton>,
          },
        ]}
      />

      <Box sx={{ padding: "2rem 0" }}>
        {listingTab === "Overview" && <Overview />}
        {listingTab === "My Listings" && <Listings />}
        {listingTab === "Requirements" && <Requirements />}
        {listingTab === "Bookings" && <Bookings />}
      </Box>
    </Box>
  );
};

export default ListingsHome;
