import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { getSOPs, updateSOP } from "./Api.call";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import AddIcon from "@material-ui/icons/Add";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import {
  Button,
  IconButton,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DeleteConfirmBox from "../styled/CommonComponents/DeleteConfirmBox";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { useDebounce } from "react-use";
import SOPCreateDrawer from "./SOP.create.drawer";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles((theme) => ({
  // ... (use the same styles as in TermsAndConditionsByLibrary)
}));

export default function SOPsByLibrary({
  libraryId,
  setResponseStatusAndMsg,
  setSanckBarBool,
  profileId,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false);
  const [deleteConfirmBox, setDeleteConfirmBox] = useState(false);
  const [changeState, setChangeState] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [createSOPDrawer, setCreateSOPDrawer] = useState(false);
  const [sops, setSOPs] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [curPage, setCurPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [curEditDeleteSOP, setCurEditDeleteSOP] = useState(null);

  const getSOPsPagination = async () => {
    setLoader(true);
    await getSOPs({
      libraryId,
      limit: pageLimit,
      curPage: curPage - 1,
      nameSearch: searchStr,
    })
      .then((data) => {
        console.log(data);
        const dataArr = data?.data || [];
        const dataCount = data?.count || 0;
        setSOPs(dataArr);
        let locTotalPage = Math.ceil(dataCount / pageLimit);
        setTotalPages(locTotalPage);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useDebounce(
    () => {
      getSOPsPagination();
    },
    1000,
    [curPage, changeState]
  );

  useEffect(() => {
    setCurPage(1);
    setChangeState(!changeState);
  }, [searchStr]);

  const sopAction = async (type, sop) => {
    setCreateSOPDrawer(false);
    setCurEditDeleteSOP(null);
    setDeleteConfirmBox(false);
    switch (type) {
      case "create":
        setCreateSOPDrawer(true);
        break;
      case "edit":
        setCurEditDeleteSOP(sop);
        setCreateSOPDrawer(true);
        break;
      case "delete":
        setCurEditDeleteSOP(sop);
        setDeleteConfirmBox(true);
        break;
    }
  };

  const afterSOPCreateOrEdit = async () => {
    if (curEditDeleteSOP?._id) {
      setChangeState(!changeState);
      setResponseStatusAndMsg({
        status: "success",
        message: `SOP successfully modified`,
      });
      setSanckBarBool(true);
    } else {
      setChangeState(!changeState);
      setCurPage(1);
      setResponseStatusAndMsg({
        status: "success",
        message: `SOP successfully added`,
      });
      setSanckBarBool(true);
    }
    setCurEditDeleteSOP(null);
  };

  const deleteSOP = async () => {
    if (curEditDeleteSOP?._id) {
      setLoader(true);
      await updateSOP({
        updateObj: { _id: curEditDeleteSOP?._id, isDeleted: true },
      })
        .then((data) => {
          setChangeState(!changeState);
          setLoader(false);
          setDeleteConfirmBox(false);
          setResponseStatusAndMsg({
            status: "success",
            message: `${curEditDeleteSOP?.title} successfully deleted`,
          });
          setSanckBarBool(true);
          setCurEditDeleteSOP(null);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  return (
    <div className={classes.mainCont}>
      <div className={classes.searchAndAddBtnCont}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          placeholder={"Enter name"}
          autoComplete="off"
          value={searchStr}
          style={{ width: "350px" }}
          onChange={(e) => {
            setSearchStr(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setCurEditDeleteSOP(null);
            sopAction("create", null);
          }}
        >
          New
        </Button>
      </div>
      <div style={{ width: "100%", height: "5px" }}>
        {loader && <LinearProgress />}
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Version</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created By</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sops &&
              sops.length > 0 &&
              sops.map((data) => {
                return (
                  <TableRow key={data._id}>
                    <TableCell align="left">
                      {String(data?.title).slice(0, 30)}
                    </TableCell>
                    <TableCell align="left">{String(data?.version)}</TableCell>
                    <TableCell align="center">{String(data?.status)}</TableCell>
                    <TableCell align="center">
                      {String(data?.creator?.displayName)}
                    </TableCell>
                    <TableCell align="center">
                      <MyPopOver
                        closeOnClick={true}
                        appearContent={
                          <span className={classes.iconBtn}>
                            <IconButton>
                              <MoreVertIcon />
                            </IconButton>
                          </span>
                        }
                        showContent={
                          <div style={{ padding: "5px 0px" }}>
                            <div
                              className={classes.singleOption}
                              onClick={() => {
                                sopAction("edit", data);
                              }}
                            >
                              Edit
                            </div>
                            <div
                              className={classes.singleOption}
                              onClick={() => {
                                sopAction("delete", data);
                              }}
                            >
                              Delete
                            </div>
                          </div>
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.paginationCont}>
        <Pagination
          count={totalPages}
          page={curPage}
          color="primary"
          siblingCount={0}
          onChange={(event, value) => {
            setCurPage(value);
          }}
        />
      </div>

      <SOPCreateDrawer
        createSOPDrawer={createSOPDrawer}
        setCreateSOPDrawer={setCreateSOPDrawer}
        profileId={profileId}
        afterSOPCreateOrEdit={(value) => {
          afterSOPCreateOrEdit();
        }}
        libraryId={libraryId}
        sopForEditDelete={curEditDeleteSOP}
      />

      <DeleteConfirmBox
        open={deleteConfirmBox}
        setOpen={setDeleteConfirmBox}
        loading={loader}
        pageTitle={`Delete ${curEditDeleteSOP?.title}`}
        cantent={
          <div className={classes.deleteConfirm}>
            <ReportProblemIcon />
            <h3>Are you sure?</h3>
            <p>You won't be able to revert this!</p>
          </div>
        }
        cancleConfirmDelete={() => {
          setDeleteConfirmBox(false);
        }}
        confirmDelete={deleteSOP}
      />
    </div>
  );
}