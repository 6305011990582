import React from "react";
import FormBox from "./FormBox";
import { Box, Checkbox, FormControlLabel } from "@mui/material";

const CheckGroupWithObj = ({
  label,
  options,
  selectedOptions,
  setSelectedOptions,
}) => {
  return (
    <FormBox label={label}>
      <Box display="flex" flexWrap="wrap">
        {options?.map((item) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedOptions
                  .map((o) => o.value)
                  .includes(item.value)}
                onChange={(evt, checked) => {
                  if (checked) {
                    setSelectedOptions([...selectedOptions, item]);
                  } else {
                    setSelectedOptions(
                      selectedOptions?.filter((o) => o.value !== item.value)
                    );
                  }
                }}
              />
            }
            label={item.title}
          />
        ))}
      </Box>
    </FormBox>
  );
};

export default CheckGroupWithObj;
