import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import DocPicker from './doc.picker';
import { makeStyles } from "@material-ui/core";
import Api from '../../helpers/Api';
import NormalDrawer from '../styled/CommonComponents/NormalDrawer';

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '15px',
    position: 'relative',
    paddingBottom: "65px"
  },
  bottomBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    padding: "0px 10px",
    borderTop: "1px solid #ececec",
  }
}));

const DocPickerDrawer = ({ open, setOpen, onClose, parentId, parentModelName, initialProfileId, initialModelName }) => {
  const classes = useStyles();

  const [selectedDocs, setSelectedDocs] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  // Handle document selection change
  const handleSelectionChange = (selectedItems) => {
    setSelectedDocs(selectedItems); // Update the selected documents in state
  };

  useEffect(() => {
    if (open) {
      setSelectedItems([])
    }
  }, [open])

  const handleClose = async () => {
    try {
      if (selectedDocs.length > 0) {
        // Make the backend call to update parent with selected docs
        const response = await Api.post('/doc/add-docs-to-parent', {
          docIds: selectedDocs.map(doc => doc._id),
          parentId,
          parentModelName,
        });

        if (response?.data?.docs.length > 0) {
          onClose(response.data.docs);
        } else {
          onClose(null)
        }
        // Return updated parent data with populated docs to the parent component
        // Pass updated data back to parent
      } else {
        // If no documents were selected, just close the drawer
        onClose(null);
      }
    } catch (error) {
      console.error('Error while closing drawer and updating parent with docs:', error);
    }
  };

  return (
    <NormalDrawer
      openDrawer={open}
      setOpenDrawer={setOpen}
      anchor={"right"}
      width={"100vw"}
      title={"Link Documentation(s)"}
      content={
        <div className={classes.mainCont} >
          <DocPicker
            initialProfileId={initialProfileId}
            initialModelName={initialModelName}
            onSelectionChange={handleSelectionChange}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            parentId={parentId} 
            parentModelName={parentModelName}
          />
          <div className={classes.bottomBar} >
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              disabled={selectedItems.length > 0 ? false : true}
            >
              Save
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default DocPickerDrawer;