import React, { useEffect, useState } from "react";
import TabBox from "./TabBox";
import TabItem from "./TabItem";
import { Box, InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

const TabsWithCommonAction = ({
  tabs,
  onTabChange,
  currentTabVariable,
  action,
  variant = "body1",
  disableUnderline,
}) => {
  const [curTab, setCurTab] = useState(tabs[0]);

  return (
    <TabBox
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      disableUnderline={disableUnderline}
    >
      <Box display="flex">
        {tabs?.map((tab) => (
          <TabItem
            variant={variant}
            active={currentTabVariable === tab}
            onClick={() => {
              onTabChange(tab);
              setCurTab(tab);
            }}
          >
            {tab}
          </TabItem>
        ))}
      </Box>

      <Box>{action}</Box>
    </TabBox>
  );
};

export default TabsWithCommonAction;
