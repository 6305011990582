import React, { useEffect, useState } from "react";

import LeadsView from "../../../common/leads/Leads";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../../../helpers/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import TabsWithSearch from "../../../../styled/generic/TabsWithSearch";
import { Box } from "@mui/material";
import MyLeads from "../../../common/tablecontainers/MyLeads";

const Leads = () => {
  const [activeTab, setActiveTab] = useState("Leads");

  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "10px", p: 2, mt: 2 }}>
      <TabsWithSearch
        tabs={["Leads", "Documents", "Tasks", "RFIs", "Events"]}
        onTabChange={(newTabVal) => setActiveTab(newTabVal)}
        currentTabVariable={activeTab}
        disableUnderline={true}
      />
      {activeTab === "Leads" && <MyLeads />}
    </Box>
  );
};

export default Leads;
