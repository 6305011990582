import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactQuill from "react-quill";
import { TextField, Button, Avatar } from "@material-ui/core";
import PostAddIcon from '@material-ui/icons/PostAdd';
import { useSelector, useDispatch } from "react-redux";
import CancelIcon from '@material-ui/icons/Cancel';
import ImageIcon from '@material-ui/icons/Image';
import moment from "moment";
import PollIcon from "@material-ui/icons/Poll";
import { getAllFiles } from "./api.call";
import LinearProgress from '@material-ui/core/LinearProgress';
import "../styled/CommonComponents/common.css"
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import PollInput from "../dashboard/Post/poll.input";
import AllFileViewerFeed from "../styled/CommonComponents/AllFiles.Viewer.Feed";




const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        backgroundColor: "white",
        borderRadius: "15px"
    },
    textCont: {
        paddingLeft: "50px",
        marginBottom: "20px"
    },
    noBorder: {
        border: "none",
    },
    closeIcon: {
        position: "absolute",
        top: "14px",
        right: "15px",
        cursor: "pointer",
    },
    userPic: {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        position: "absolute",
        top: "22px",
        left: '12px'
    },
    titleInput: {
        fontSize: "20px",
        padding: "0px",
        "& .MuiOutlinedInput-input": {
            paddingBottom: "7px",
            margin: "0px"
        }
    },
    toolbar: {
        borderTop: "1px solid rgba(0,0,0,0.1)",
        display: "flex",
        alignItems: "center",
        padding: "5px 10px",
        paddingRight: "20px",
        justifyContent: "space-between"
    },
    toolbarBtn: {
        fontSize: "22px",
        marginRight: "3px",
        color: theme.palette.primary.main
    },
    addText: {
        fontSize: "16px",
        fontWeight: "500",
        color: theme.palette.primary.main
    },
    pollBox: {
        margin: "20px 0px",
        padding: "0 10px",
        borderRadius: "3px",
    },
    pollHeader: {
        color: theme.palette.primary.main,
        margin: "10px 0",
        display: "flex",
        justifyContent: "space-between",
    }
}));

function getTomorrowDateTime() {
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const date = moment(tomorrow).format("YYYY-MM-DDTkk:mm");
    return date;
}

function CreateFeedsPost(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        titlePlaceholder, descPlaceholder, closeIt,
        isPoll, setIsPoll, pollOptions, setPollOptions,
        pollExireAt, setPollExireAt, publish, postCreateLoading,
        description, setDescription, title, setTitle, ...other
    } = props;

    const { createdFileIds } = useSelector((state) => state.file);
    const { user } = useSelector((state) => state.auth);
    const [files, setFiles] = useState([])


    useEffect(() => {
        if (createdFileIds) {
            getAllFiles({ files: createdFileIds })
                .then((data) => {
                    setFiles(data)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [createdFileIds])

    const removeFun = (id) => {
        let allfilesId = createdFileIds.filter((_id) => _id !== id)
        let allfiles = files.filter((obj) => obj?._id !== id)

        setFiles(allfiles)
        dispatch({
            type: "AddFile",
            payload: {
                createdFileIds: [...allfilesId],
            },
        })
    }


    return (
        <div className={classes.root}>
            <CancelIcon className={classes.closeIcon} onClick={closeIt} />
            <Avatar src={user?.displayPicture?.url} className={classes.userPic} />
            <div className={classes.textCont} >
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="phoneNumber"
                    disableUnderline={false}
                    value={title}
                    onChange={(e) => { setTitle(e.target.value); }}
                    autoFocus
                    placeholder={titlePlaceholder || "Title"}
                    InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                        className: classes.titleInput
                    }}
                    style={{ width: "90%", margin: "0px" }}
                />
                <ReactQuill
                    {...other}
                    className='style1'
                    value={description}
                    placeholder={descPlaceholder || "Enter Message"}
                    onChange={setDescription}
                    modules={{
                        toolbar: false
                    }}
                    theme='snow'
                >
                </ReactQuill>
            </div>
            {isPoll ? (
                <div className={classes.pollBox} >
                    <div className={classes.pollHeader}>
                        <p>Poll</p>
                    </div>
                    {pollOptions.map((el, idx) => (
                        <PollInput
                            pollOptions={pollOptions}
                            idx={idx}
                            key={idx}
                            last={idx == pollOptions.length - 1}
                            setPollOptions={setPollOptions}
                            setIsPoll={setIsPoll}
                        />
                    ))}
                    <TextField
                        id="poll-expiry"
                        label="Expire On"
                        type="datetime-local"
                        color="primary"
                        defaultValue={getTomorrowDateTime()}
                        value={pollExireAt}
                        onChange={(event) => setPollExireAt(event.target.value)}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                            className: classes.textFieldInput,
                        }}
                    />
                </div>
            ) : null}
            {files && files.length > 0 && (
                <AllFileViewerFeed
                    picVideoViewerHeight={"350px"}
                    picVideoViewerHeightSmall={"250px"}
                    picVideoViewerWidth={"100%"}
                    files={files ? files : []}
                    isDeletable={true}
                    removeFun={removeFun}
                />
            )}
            <div style={{ width: "100%", height: "5px", marginTop: "10px" }} >
                {postCreateLoading && (<LinearProgress />)}
            </div>
            <div className={classes.toolbar} >
                <div style={{ display: "flex", alignItems: "center" }} >
                    <CustomFileUploadButton
                        showComponent={
                            <Button
                            >
                                <ImageIcon className={classes.toolbarBtn} />
                                <span className={classes.addText} >F<span style={{ textTransform: "lowercase" }} >ile</span></span>
                            </Button>
                        }
                        parentType={"Post"}
                        parentId={null}
                        fileNum={25}
                        givenMaxSize={26214400}
                        closeFunCall={() => { console.log("close dialog") }}
                    />
                    <Button
                        onClick={() => { setIsPoll(true) }}
                        style={{ marginLeft: "40px" }}
                    >
                        <PollIcon className={classes.toolbarBtn} />
                        <span className={classes.addText} >P<span style={{ textTransform: "lowercase" }} >oll</span></span>
                    </Button>
                </div>
                <div style={{ display: "flex", alignItems: "center" }} >
                    {description && title ? (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<PostAddIcon />}
                            onClick={publish}
                        >
                            Post
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<PostAddIcon />}
                            disabled={true}
                        >
                            post
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CreateFeedsPost;
