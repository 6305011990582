import { Avatar, Box } from "@mui/material";
import React from "react";

const ListingTableSharedPeopleCell = ({ shared }) => {
  return (
    <div>
      {shared?.length <= 0 ? (
        <span>No results</span>
      ) : (
        <Box>
          <Box>
            <Box display="flex" alignItems="center">
              <Box display="flex">
                {shared?.slice(0, 3).map((shareItem) => (
                  <Avatar
                    alt={shareItem?.parent?.displayName}
                    src={shareItem?.parent?.displayPicture?.url}
                    sx={{
                      width: 30,
                      height: 30,
                      marginRight: "-5px",
                      border: "3px solid #ffffff",
                    }}
                  />
                ))}
              </Box>
              {shared?.length > 3 && (
                <span style={{ marginLeft: "5px", fontWeight: "normal" }}>
                  + {parseInt(shared?.length) - 3}
                </span>
              )}
            </Box>
          </Box>
          <div style={{ fontWeight: "normal" }}>{shared?.length} Matches</div>
        </Box>
      )}
    </div>
  );
};

export default ListingTableSharedPeopleCell;
