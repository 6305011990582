const initialState = {
  workspaceConfig: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "AddWorkspace":
      return {
        ...state,
        ...action.payload,
      };

    case "ResetWorkspace":
      return initialState;

    default:
      return state;
  }
};
