import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import EstimationAnalyticsCardSvg from "../../../Assets/proj_mgmt_logo.svg";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import emptyIcon from "../../../Assets/vivekEmpty.svg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "100%",
    height: "370px",
    borderRadius: "10px",
    marginBottom: "20px",
    padding: "10px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 5px",
    },
  },
  cardTopHeading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > h3": {
      fontWeight: "510",
      fontSize: "17px",
      color: "#193B56",
    },
  },
  createBtn: {
    width: "25px",
    height: "25px",
    borderRadius: "6px",
    backgroundColor: "#c1def6",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#70b8f4",
      color: "white",
    },
  },
  topBox: {
    width: "100%",
    padding: "3px 10px",
    display: "flex",
    alignItems: "flex-start",
    marginTop: "10px",
    cursor: "pointer",
    marginBottom: "20px",
    "& > p": {
      fontSize: "15px",
      marginTop: "10px",
      color: "#696969",
    },
    "&:hover": {
      backgroundColor: "#edecec",
    },
  },
  topBoxSvgCont: {
    width: "120px",
    height: "70px",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
    "& > img": {
      width: "95px",
      height: "95px",
    },
  },
  emptyCont: {
    display: "flex",
    width: "100%",
    height: "175px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    "& img": {
      height: "100px",
      width: "auto",
    },
    "& p": {
      fontSize: "12px",
      color: "gray",
      textAlign: "center",
      marginTop: "5px",
    },
  },
}));

const BudgetCostAnalysisCard = ({ projectBlocks }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  let { teamId } = useParams();
  const dispatch = useDispatch();

  const fileInputRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const { teamDictionary } = useSelector((state) => state.team);

  const [profileId, setProfileId] = useState(null)

  useEffect(() => {
    const teamData = teamDictionary[teamId];
    setProfileId(teamData?.parent?.profile?._id)
  }, [teamDictionary])

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      dispatch({
        type: "AddBudget",
        payload: {
          file: selectedFile,
        },
      });

      history.push(`/budget-analysis/upload/${teamId}`);
    }
  };


  return (
    <Paper className={classes.cardContainer}>
      <div className={classes.cardTopHeading}>
        <h3>Budget Analytics</h3>
        <div></div>
      </div>
      <Paper
        elevation={1}
        className={classes.topBox}
        onClick={() => {
          history.push(`/budget-analysis/budget-costs/${profileId}`)
        }}
      >
        <div className={classes.topBoxSvgCont}>
          <img src={EstimationAnalyticsCardSvg} />
        </div>
        <p>Budget Analytics</p>
      </Paper>
      <div className={classes.emptyCont}>
        <img src={emptyIcon} />
        <p>
          There is nothing to show here.
          <br />
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
            multiple={false}
          />
          <span
            style={{
              color: theme.palette.primary.main,
              marginLeft: "3px",
              cursor: "pointer",
            }}
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            Click to upload a new budget sheet.
          </span>
        </p>
      </div>
    </Paper>
  );
};

export default BudgetCostAnalysisCard;