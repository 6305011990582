import { Box } from "@mui/material";
import React, { useState } from "react";
import TabsWithSearch from "../../../../styled/generic/TabsWithSearch";
import MyRequests from "../../../common/tablecontainers/MyRequests";

const Requests = () => {
  const [requestsTab, setRequestsTab] = useState("Requests");

  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "10px", p: 2, mt: 2 }}>
      <TabsWithSearch
        tabs={["Requests"]}
        onTabChange={(newTabVal) => setRequestsTab(newTabVal)}
        currentTabVariable={requestsTab}
        disableUnderline={true}
      />
      {requestsTab === "Requests" && <MyRequests />}
    </Box>
  );
};

export default Requests;
