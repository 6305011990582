import React from "react";
import MyPopOver from "../CommonComponents/MyPopOver";
import MoreButton from "./MoreButton";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";

const ListingTableActionCell = ({
  cellActions,
  cellActionListener,
  cellData,
}) => {
  return (
    <MyPopOver
      closeOnClick={true}
      appearContent={<MoreButton />}
      showContent={
        <List sx={{ width: 200 }}>
          {cellActions?.map((cellAction) => (
            <ListItem
              disablePadding
              onClick={() => cellActionListener(cellAction, cellData)}
            >
              <ListItemButton>
                <ListItemText primary={cellAction} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      }
    />
  );
};

export default ListingTableActionCell;
