import React, { useEffect, useState } from "react";
import FormBox from "../../../styled/generic/FormBox";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import {
  PiGarageDuotone,
  PiTentDuotone,
  PiWarehouseDuotone,
} from "react-icons/pi";
import { LiaIndustrySolid } from "react-icons/lia";
import { BsHouseDoor } from "react-icons/bs";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { GiFarmTractor } from "react-icons/gi";

const COMMERCIAL_PROPERTY_OPTIONS = [
  {
    title: "Office",
    icon: HiOutlineOfficeBuilding,
  },
  {
    title: "Retail",
    icon: PiTentDuotone,
  },
  {
    title: "Industry",
    icon: LiaIndustrySolid,
  },
  {
    title: "Storage",
    icon: PiGarageDuotone,
  },
];

const RESIDENTIAL_PROPERTY_OPTIONS = [
  {
    title: "House / Villa",
    icon: BsHouseDoor,
  },
  {
    title: "Flat / Appartment",
    icon: HiOutlineBuildingOffice2,
  },
  {
    title: "Builder Floor",
    icon: PiWarehouseDuotone,
  },
  {
    title: "Farm House",
    icon: GiFarmTractor,
  },
];

const PropertyTypeChooser = ({
  propertyType,
  setPropertyType,
  defaultPropertyType,
}) => {
  const [propertyTypeTab, setPropertyTypeTab] = useState("Residential");

  useEffect(() => {
    if (defaultPropertyType) {
      let index = RESIDENTIAL_PROPERTY_OPTIONS.findIndex(
        (item) => item.title === defaultPropertyType
      );
      if (index === -1) {
        index = COMMERCIAL_PROPERTY_OPTIONS.findIndex(
          (item) => item.title === defaultPropertyType
        );
        setPropertyTypeTab("Commercial");
        setPropertyType(COMMERCIAL_PROPERTY_OPTIONS[index].title);
      } else {
        setPropertyTypeTab("Residential");
        setPropertyType(RESIDENTIAL_PROPERTY_OPTIONS[index].title);
      }
    }
  }, [defaultPropertyType]);

  return (
    <FormBox label="Property Type">
      <TabContext value={propertyTypeTab} sx={{ p: 0 }}>
        <TabList onChange={(evt, val) => setPropertyTypeTab(val)}>
          <Tab
            label="Residential"
            value="Residential"
            sx={{ textTransform: "none", fontSize: "1rem" }}
          />
          <Tab
            label="Commercial"
            value="Commercial"
            sx={{ textTransform: "none", fontSize: "1rem" }}
          />
        </TabList>
        <TabPanel value="Residential">
          <SpaceBetween
            left={
              <Box
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                sx={{
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {RESIDENTIAL_PROPERTY_OPTIONS.map((item) => (
                  <Box
                    minWidth={180}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    sx={{ mr: 4 }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      sx={{
                        cursor: "pointer",
                        border: (theme) =>
                          propertyType === item.title
                            ? `1px solid ${theme.palette.primary.main}`
                            : "1px solid rgba(0,0,0,0)",
                        borderRadius: "16px",
                        padding: "12px",
                        width: "100%",
                      }}
                      onClick={() => setPropertyType(item.title)}
                    >
                      <Box
                        sx={{
                          color: (theme) =>
                            propertyType === item.title
                              ? theme.palette.primary.main
                              : "auto",
                        }}
                      >
                        <item.icon size={42} />
                      </Box>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          color={
                            propertyType === item.title ? "primary" : "auto"
                          }
                        >
                          {item.title}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            }
          />
        </TabPanel>
        <TabPanel value="Commercial">
          <SpaceBetween
            left={
              <Box
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                sx={{
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {COMMERCIAL_PROPERTY_OPTIONS.map((item) => (
                  <Box
                    minWidth={180}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    sx={{ mr: 4 }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      sx={{
                        cursor: "pointer",
                        border: (theme) =>
                          propertyType === item.title
                            ? `1px solid ${theme.palette.primary.main}`
                            : "1px solid rgba(0,0,0,0)",
                        borderRadius: "16px",
                        padding: "12px",
                        width: "100%",
                      }}
                      onClick={() => setPropertyType(item.title)}
                    >
                      <Box
                        sx={{
                          color: (theme) =>
                            propertyType === item.title
                              ? theme.palette.primary.main
                              : "auto",
                        }}
                      >
                        <item.icon size={42} />
                      </Box>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          color={
                            propertyType === item.title ? "primary" : "auto"
                          }
                        >
                          {item.title}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            }
          />
        </TabPanel>
      </TabContext>
    </FormBox>
  );
};

export default PropertyTypeChooser;
