import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ImProfile } from "react-icons/im";
import { GiCrane } from "react-icons/gi";
import { TbCertificate } from "react-icons/tb";
import BasicInfoEdit from './BasicInfo.Edit';
import { GiTrophyCup } from "react-icons/gi";
import ExprojectEdit from './Exproject.Edit';
import AwardEdit from './AwardHonors.Edit';
import CertificatEdit from './CertificatLicense.Edit';
import Slide from '@material-ui/core/Slide';


const useStyles = makeStyles((theme) => ({
    mainEditCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        borderTop: "1px solid #e4e7e7",
    },
    leftNavBar: {
        width: "280px",
        height: "100%",
        borderRight: "1px solid #e4e7e7",
        [theme.breakpoints.down("md")]: {
            width: "200px",
        },
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    rightNavBar: {
        width: "calc(100% - 280px)",
        height: "100%",
        position: "relative",
        overflowX: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        [theme.breakpoints.down("md")]: {
            width: "calc(100% - 200px)",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    optionBtn: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        borderRadius: "0px",
        fontSize: "16px",
        opacity: "0.6",
        justifyContent: "flex-start",
        paddingLeft: "20px",
        height: "52px",
        borderTop: "1px solid #e4e7e7",
        "& p": {
            textTransform: "capitalize"
        },
        [theme.breakpoints.down("md")]: {
            fontSize: "15px",
            paddingLeft: "4px",
            "& p": {
                textTransform: "capitalize",
                textAlign: "left",
                width: "160px"
            },
        }
    },
    sideTopBar: {
        height: "70px"
    },
    sectionCont: {
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100%",
    }
}));

export default function OrgBothViewEdit({ setOpenEditView, profileEditView, setProfileEditView, profile, teamId }) {
    const classes = useStyles();

    return (
        <div className={classes.mainEditCont} >
            <div className={classes.leftNavBar} >
                <Button
                    className={classes.optionBtn}
                    onClick={() => { setProfileEditView("basicInfo") }}
                    style={profileEditView === "basicInfo" ? { opacity: "1", borderTop: "none" } : { borderTop: "none" }}
                >
                    <ImProfile style={{ fontSize: "19px", marginRight: "15px" }} /> <p>Basic Information</p>
                </Button>
                <Button
                    className={classes.optionBtn}
                    onClick={() => { setProfileEditView("project") }}
                    style={profileEditView === "project" ? { opacity: "1" } : {}}
                >
                    <GiCrane style={{ fontSize: "21px", marginRight: "15px" }} /> <p>Projects</p>
                </Button>
                <Button
                    className={classes.optionBtn}
                    onClick={() => { setProfileEditView("award") }}
                    style={profileEditView === "award" ? { opacity: "1" } : {}}
                >
                    <GiTrophyCup style={{ fontSize: "21px", marginRight: "15px" }} /> <p>award</p>
                </Button>
                <Button
                    className={classes.optionBtn}
                    style={profileEditView === "certificat" ? { opacity: "1", borderBottom: "1px solid #e4e7e7" } : { borderBottom: "1px solid #e4e7e7" }}
                    onClick={() => { setProfileEditView("certificat") }} >
                    <TbCertificate style={{ fontSize: "19px", marginRight: "15px" }} /> <p>Licenses & Certifications</p>
                </Button>
            </div>
            <div className={classes.rightNavBar} >
                <Slide direction="left" in={profileEditView === "basicInfo" ? true : false} mountOnEnter unmountOnExit>
                    <div className={classes.sectionCont} >
                        <BasicInfoEdit
                            setOpenEditView={setOpenEditView}
                            profile={profile}
                            teamId={teamId}
                        />
                    </div>
                </Slide>
                <Slide direction="left" in={profileEditView === "certificat" ? true : false} mountOnEnter unmountOnExit>
                    <div className={classes.sectionCont} >
                        <CertificatEdit
                            setOpenEditView={setOpenEditView}
                            profile={profile}
                            teamId={teamId}
                        />
                    </div>
                </Slide>
                <Slide direction="left" in={profileEditView === "award" ? true : false} mountOnEnter unmountOnExit>
                    <div className={classes.sectionCont} >
                        <AwardEdit
                            setOpenEditView={setOpenEditView}
                            profile={profile}
                            teamId={teamId}
                        />
                    </div>
                </Slide>
                <Slide direction="left" in={profileEditView === "project" ? true : false} mountOnEnter unmountOnExit>
                    <div className={classes.sectionCont} >
                        <ExprojectEdit
                            setOpenEditView={setOpenEditView}
                            profile={profile}
                            teamId={teamId}
                        />
                    </div>
                </Slide>
            </div>
        </div>
    );
}