import Api from "../../helpers/Api";

const updateListingApi = async (params) => {
  try {
    const res = await Api.post("/listing/updateListing", {
      ...params,
    });
    return res;
  } catch (err) {
    console.log(err);
    return {};
  }
};

const filterListingApi = async (params) => {
  try {
    const res = await Api.post("/listing/getListingsByFilters", {
      ...params,
    });
    return res;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getLeadsApi = async (params) => {
  try {
    const res = await Api.post("/listing/app/leads", {
      ...params,
    });
    return res;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const changeLeadStatusApi = async (params) => {
  try {
    const res = await Api.post("/listing/app/change-status", {
      ...params,
    });
    return res;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getRequestsApi = async (params) => {
  try {
    const res = await Api.post("/listing/app/requests", {
      ...params,
    });
    return res;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getApplicationsApi = async (params) => {
  try {
    const res = await Api.post("/listing/app/requirement-apps", {
      ...params,
    });
    return res;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getRequirementsForListingApi = async (params) => {
  try {
    const res = await Api.post(
      "/listing/requirements/getRequirementsForListing",
      {
        ...params,
      }
    );
    return res;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getListingsForRequirementApi = async (params) => {
  try {
    const res = await Api.post(
      "/listing/requirements/getListingsForRequirement",
      {
        ...params,
      }
    );
    return res;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const requestRequirementApi = async (params) => {
  try {
    const res = await Api.post("/listing/app/request", {
      ...params,
    });
    return res;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const applyListingRequirementApi = async (params) => {
  try {
    const res = await Api.post("/listing/app/apply", {
      ...params,
    });
    return res;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export {
  updateListingApi,
  filterListingApi,
  getLeadsApi,
  changeLeadStatusApi,
  getRequestsApi,
  getApplicationsApi,
  getRequirementsForListingApi,
  getListingsForRequirementApi,
  requestRequirementApi,
  applyListingRequirementApi,
};
