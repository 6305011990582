import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";
import { BiSave } from "react-icons/bi";
import { updateLibraryById } from "./Api.call";

export default function LibraryEditDrawer({
  open,
  setOpen,
  afterCreateOrEditNewLibrary,
  data,
}) {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (title) => {
    setLoading(true);
    const updatedObj = {
      name: title,
      _id: data?._id,
    };
    await updateLibraryById(updatedObj)
      .then((data) => {
        if (data) {
          afterCreateOrEditNewLibrary(data, true);
        }
        setOpen(() => false);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxWidth: "400px",
        },
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          const title = formJson.title;
          handleSubmit(title);
        },
      }}
    >
      <DialogTitle>Update Library</DialogTitle>
      <DialogContent>
        <DialogContentText>Edit your library title</DialogContentText>
        <TextField
          autoFocus
          size="small"
          required
          margin="dense"
          id="title"
          name="title"
          label="Title"
          type="text"
          fullWidth
          variant="outlined"
          sx={{
            borderRadius: "50%",
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          endIcon={<BiSave />}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
