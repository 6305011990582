import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { getTermsAndConditions, updateTermsAndCondition } from "./Api.call";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import AddIcon from "@material-ui/icons/Add";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import {
  Button,
  IconButton,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DeleteConfirmBox from "../styled/CommonComponents/DeleteConfirmBox";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { useDebounce } from "react-use";
import TermsAndConditionCreateDrawer from "./TermsAndCondition.Create.Drawer";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: "100%",
    "& table": {
      tableLayout: "auto",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "0px 10px",
    },
  },
  searchAndAddBtnCont: {
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    padding: "0px 5px",
    justifyContent: "space-between",
  },
  tableContainer: {
    width: "100%",
    height: "calc(100% - 106px)",
    "& tr": {
      height: "45px",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& table": {
      tableLayout: "auto",
    },
  },
  paginationCont: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid #ececec",
  },
  singleOption: {
    padding: "4px 15px",
    fontSize: "15px",
    fontWeight: "500",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
  iconBtn: {
    "& .MuiSvgIcon-root": {
      color: "black",
    },
  },
  deleteConfirm: {
    width: "350px",
    padding: "25px 0px 35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& .MuiSvgIcon-root": {
      fontSize: "75px",
      color: "red",
    },
    "& h3": {
      fontSize: "20px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
}));

export default function TermsAndConditionsByLibrary({
  libraryId,
  setResponseStatusAndMsg,
  setSanckBarBool,
  profileId,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false);
  const [deleteConfirmBox, setDeleteConfirmBox] = useState(false);
  const [changeState, setChangeState] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [createTCDrawer, setCreateTCDrawer] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [curPage, setCurPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [curEditDeleteTC, setCurEditDeleteTC] = useState(null);

  const getTCPagination = async () => {
    setLoader(true);
    await getTermsAndConditions({
      libraryId,
      limit: pageLimit,
      curPage: curPage - 1,
      nameSearch: searchStr,
    })
      .then((data) => {
        console.log(data);
        const dataArr = data?.data || [];
        const dataCount = data?.count || 0;
        setTermsAndConditions(dataArr);
        let locTotalPage = Math.ceil(dataCount / pageLimit);
        setTotalPages(locTotalPage);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useDebounce(
    () => {
      getTCPagination();
    },
    1000,
    [curPage, changeState]
  );

  useEffect(() => {
    setCurPage(1);
    setChangeState(!changeState);
  }, [searchStr]);

  const termsConditionAction = async (type, termCondition) => {
    setCreateTCDrawer(false);
    setCurEditDeleteTC(null);
    setDeleteConfirmBox(false);
    switch (type) {
      case "create":
        setCreateTCDrawer(true);
        break;
      case "edit":
        setCurEditDeleteTC(termCondition);
        setCreateTCDrawer(true);
        break;
      case "delete":
        setCurEditDeleteTC(termCondition);
        setDeleteConfirmBox(true);
        break;
    }
  };

  const afterTCCreateOrEdit = async () => {
    if (curEditDeleteTC?._id) {
      setChangeState(!changeState);
      setResponseStatusAndMsg({
        status: "success",
        message: `Tax successfully modified`,
      });
      setSanckBarBool(true);
    } else {
      setChangeState(!changeState);
      setCurPage(1);
      setResponseStatusAndMsg({
        status: "success",
        message: `Tax successfully added`,
      });
      setSanckBarBool(true);
    }
    setCurEditDeleteTC(null);
  };

  const deleteTems = async () => {
    if (curEditDeleteTC?._id) {
      setLoader(true);
      await updateTermsAndCondition({
        updateObj: { _id: curEditDeleteTC?._id, isDeleted: true },
      })
        .then((data) => {
          setChangeState(!changeState);
          setLoader(false);
          setDeleteConfirmBox(false);
          setResponseStatusAndMsg({
            status: "success",
            message: `${curEditDeleteTC?.title} successfully deleted`,
          });
          setSanckBarBool(true);
          setCurEditDeleteTC(null);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  return (
    <div className={classes.mainCont}>
      <div className={classes.searchAndAddBtnCont}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          placeholder={"Enter name"}
          autoComplete="off"
          value={searchStr}
          style={{ width: "350px" }}
          onChange={(e) => {
            setSearchStr(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setCurEditDeleteTC(null);
            termsConditionAction("create", null);
          }}
        >
          New
        </Button>
      </div>
      <div style={{ width: "100%", height: "5px" }}>
        {loader && <LinearProgress />}
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Language</TableCell>
              <TableCell align="center">Content</TableCell>
              <TableCell align="center">Add By</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {termsAndConditions &&
              termsAndConditions.length > 0 &&
              termsAndConditions.map((data) => {
                return (
                  <TableRow>
                    <TableCell align="left">
                      {String(data?.title).slice(0, 10)}
                    </TableCell>
                    <TableCell align="left">{String(data?.language)}</TableCell>
                    <TableCell align="left">
                      {ReactHtmlParser(
                        data?.content?.length > 0
                          ? String(data?.content).slice(0, 20).concat("...")
                          : ""
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {String(data?.creator?.displayName)}
                    </TableCell>
                    <TableCell align="center">
                      <MyPopOver
                        closeOnClick={true}
                        appearContent={
                          <span className={classes.iconBtn}>
                            <IconButton>
                              <MoreVertIcon />
                            </IconButton>
                          </span>
                        }
                        showContent={
                          <div style={{ padding: "5px 0px" }}>
                            <div
                              className={classes.singleOption}
                              onClick={() => {
                                termsConditionAction("edit", data);
                              }}
                            >
                              Edit
                            </div>
                            <div
                              className={classes.singleOption}
                              onClick={() => {
                                termsConditionAction("delete", data);
                              }}
                            >
                              Delete
                            </div>
                          </div>
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.paginationCont}>
        <Pagination
          count={totalPages}
          page={curPage}
          color="primary"
          siblingCount={0}
          onChange={(event, value) => {
            setCurPage(value);
          }}
        />
      </div>

      {/* after create  disccount relation drawer */}
      <TermsAndConditionCreateDrawer
        createTCDawer={createTCDrawer}
        setCreateTCDawer={setCreateTCDrawer}
        profileId={profileId}
        afterTCCreateOrEdit={(value) => {
          afterTCCreateOrEdit();
        }}
        libraryId={libraryId}
        tcForEditDelete={curEditDeleteTC}
      />
      {/* after create  disccount relation drawer */}

      {/* confirm box to delete offering and offering relation */}
      <DeleteConfirmBox
        open={deleteConfirmBox}
        setOpen={setDeleteConfirmBox}
        loading={loader}
        pageTitle={`Delete ${curEditDeleteTC?.title}`}
        cantent={
          <div className={classes.deleteConfirm}>
            <ReportProblemIcon />
            <h3>Are you sure?</h3>
            <p>You won't be able to revert this!</p>
          </div>
        }
        cancleConfirmDelete={() => {
          setDeleteConfirmBox(false);
        }}
        confirmDelete={deleteTems}
      />
      {/* confirm box to delete offering and offering relation */}
    </div>
  );
}
