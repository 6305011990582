import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { InputLabel, TextField } from "@material-ui/core";
import { Select } from "@mui/material";
import DateFnsUtils from "@date-io/date-fns";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useDebounce } from "react-use";
import { FormControl, MenuItem } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    width: (props) => props.width,
    padding: "12px 8px",
    transition: "all 0.2s",
  },
  labelSty: {
    fontSize: "13px",
    fontWeight: "500",
    marginBottom: "6px",
    color: theme.palette.text.secondary,
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      backgroundColor: "#FFFFFF",
      transition: "all 0.2s",
      
      "&:hover fieldset": {
        borderColor: theme.palette.primary.light,
      },
      
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
        borderWidth: "1px",
      },
      
      "& fieldset": {
        borderColor: theme.palette.grey[300],
      }
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
      fontSize: "14px",
      "&::placeholder": {
        color: theme.palette.text.disabled,
        opacity: 0.7,
      }
    },
    "& .Mui-disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
      cursor: "not-allowed",
    }
  },
  select: {
    "& .MuiSelect-select": {
      padding: "10px 14px",
      fontSize: "14px",
    }
  },
  dateField: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
    }
  },
  phoneField: {
    "& .react-tel-input .form-control": {
      width: "100%",
      height: "40px",
      borderRadius: "8px",
      fontSize: "14px",
      backgroundColor: "#FFFFFF",
      border: `1px solid ${theme.palette.grey[300]}`,
      transition: "all 0.2s",
      
      "&:hover": {
        borderColor: theme.palette.primary.light,
      },
      
      "&:focus": {
        borderColor: theme.palette.primary.main,
        boxShadow: "none",
        borderWidth: "1px",
      },
    },
    "& .react-tel-input .flag-dropdown": {
      backgroundColor: "#FFFFFF",
      borderRadius: "8px 0 0 8px",
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRight: "none",
      
      "&:hover": {
        backgroundColor: "#F5F5F5",
      },
    },
    "& .react-tel-input .selected-flag": {
      borderRadius: "8px 0 0 8px",
      "&:focus": {
        backgroundColor: "#F5F5F5",
      },
    },
    // Disabled state
    "&.Mui-disabled": {
      "& .form-control": {
        backgroundColor: theme.palette.action.disabledBackground,
        cursor: "not-allowed",
      },
      "& .flag-dropdown": {
        backgroundColor: theme.palette.action.disabledBackground,
        cursor: "not-allowed",
      },
    }
  }
}));

const AllCommonField = ({
  type,
  value,
  lable,
  row,
  width,
  fieldData,
  onChange,
  options = [],
  disabled = false,
}) => {
  const classes = useStyles({ width });
  let CommonComponent = null;

  const [locValue, setLocValue] = useState();

  useEffect(() => {
    setLocValue(value);
  }, [value]);

  useDebounce(
    () => {
      if (onChange && fieldData) {
        onChange(fieldData, locValue);
      }
    },
    1000,
    [locValue]
  );

  switch (type) {
    case "textField":
      CommonComponent = (
        <TextField
          placeholder={`Enter ${lable}`}
          value={locValue}
          type="text"
          onChange={(e) => {
            setLocValue(e.target.value);
          }}
          size="small"
          variant="outlined"
          fullWidth
          disabled={disabled}
          className={classes.textField}
        />
      );
      break;
    case "select":
      CommonComponent = (
        <Select
          value={locValue}
          defaultValue={options[0]}
          onChange={(e) => setLocValue(e.target.value)}
          fullWidth={true}
          variant="outlined"
          disabled={disabled}
          className={classes.select}
          sx={{ height: "2.5rem" }}
        >
          {options?.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      );
      break;
    case "numberField":
      CommonComponent = (
        <TextField
          placeholder={`Enter ${lable}`}
          value={locValue}
          type="number"
          onChange={(e) => {
            setLocValue(e.target.value);
          }}
          size="small"
          variant="outlined"
          fullWidth
          disabled={disabled}
          className={classes.textField}
        />
      );
      break;
    case "dateField":
      CommonComponent = (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            value={locValue}
            fullWidth
            margin="normal"
            id="due-date-picker"
            format="MM/dd/yyyy"
            variant="outlined"
            onChange={(date) => {
              setLocValue(date);
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            disabled={disabled}
            className={classes.dateField}
          />
        </MuiPickersUtilsProvider>
      );
      break;
    case "textArea":
      CommonComponent = (
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={row}
          placeholder={`Enter ${lable}`}
          fullWidth
          value={locValue}
          onChange={(e) => {
            setLocValue(e.target.value);
          }}
          variant="outlined"
          size="small"
          disabled={disabled}
          className={classes.textField}
        />
      );
      break;
    case "phoneField":
      CommonComponent = (
        <PhoneInput
          country={'in'} // default country
          value={locValue}
          onChange={(phone) => {
            setLocValue(phone);
          }}
          inputProps={{
            name: fieldData,
            required: true,
            disabled: disabled,
          }}
          containerClass={classes.phoneField}
          inputStyle={{
            width: '100%',
            height: '40px',
          }}
          buttonStyle={{
            backgroundColor: 'transparent',
          }}
          placeholder={`Enter ${lable}`}
        />
      );
      break;
  }

  return (
    <div className={classes.fieldContainer}>
      <p className={classes.labelSty}>{lable}</p>
      {CommonComponent}
    </div>
  );
};

export default AllCommonField;
