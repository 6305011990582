import React, { useEffect, useState } from "react";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import Api from "../../../helpers/Api";
import { useMediaQuery, useTheme, Button, MenuItem } from "@material-ui/core";
import ProfileSelect from "../../styled/profile.select";
import useGetAdminProfiles from "../../profile/useGetAdminProfiles";
import Radio from "@material-ui/core/Radio";
import { useDispatch, useSelector } from "react-redux";
import {
  AddCircleOutline,
  ArrowForward,
  ChevronRight,
  Clear,
  ClearAll,
  CloseOutlined,
  Home,
  KeyboardArrowDown,
  RemoveCircleOutline,
  Visibility,
} from "@material-ui/icons";
import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  RadioGroup,
  SvgIcon,
  Tab,
  Typography,
  styled,
} from "@mui/material";
import FormBox from "../../styled/generic/FormBox";
import SpaceBetween from "../../styled/generic/SpaceBetween";

import TextField from "../../styled/generic/TextField";
import ProjectPickerDropdown from "../../styled/generic/ProjectPickerDropdown";
import useGetTemplates from "../../issue/useGetTemplates";
import Select from "../../styled/generic/Select";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import OrgPickerDropdown from "../../styled/generic/OrgPickerDropdown";

const PrivateListingCreateDrawer = ({
  openDrawer,
  setOpenDrawer,
  contextModelName,
  context,
  teamId,
  onListingCreated,
}) => {
  const { adminProfiles } = useGetAdminProfiles();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const userProfileId = user?.profile;
  let baseOwnerType = contextModelName == "User" ? "User" : "Organization";

  const [ownerType, setOwnerType] = useState(baseOwnerType);
  var contextProjectId = contextModelName == "Project" ? context?._id : null;
  const [selectedProject, setSelectedProject] = useState();
  const [selectedDefaultProjectId, setSelectedDefaultProjectId] =
    useState(contextProjectId);
  const [showProjectBlockDrawer, setShowProjectBlockDrawer] =
    useState(contextProjectId);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [selectedOrg, setSelectedOrg] = useState();
  const [unitType, setUnitType] = useState("Multiple");
  const [owner, setOwner] = useState();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let width = isMobile ? "100vw" : "80vw";

  const [options, setOptions] = useState([]);
  const {
    templateIds,
    setTemplateIds,
    templateDictionary,
    setTemplateDictionary,
  } = useGetTemplates(userProfileId);
  useEffect(() => {
    // removing tempated with no title
    let temp = Object.keys(templateDictionary).filter((id) => {
      // let obj = teamDictionary["63fde0cab6d0d325bc69dbd3"];
      return Boolean(templateDictionary[id]?.title);
    });
    setOptions([...temp]);
  }, [templateDictionary]);

  useEffect(() => {
    setSelectedDefaultProjectId(contextProjectId);
  }, [context?._id]);

  const dispatch = useDispatch();

  const [listingTitle, setListingTitle] = useState("");
  const [listingDescription, setListingDescription] = useState("");

  const LISTING_TYPES = [
    "Job",
    "Rent",
    "Tender",
    "Sell",
    "Investment",
    "Roommate",
  ];
  const [listingType, setListingType] = useState("Sell");
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedDefaultOrganizationId, setSelectedDefaultOrganizationId] =
    useState();

  const createListing = async () => {
    try {
      const { data } = await Api.post("listing/createListing", {
        user: user?._id,
        title: listingTitle,
        description: listingDescription,
        isPrivate: true,
        issueTemplateId: selectedTemplateId,
        projectId: selectedProject?._id,
        organizationId: selectedOrganization?._id,
        listingType: listingType,
        owner:
          ownerType === "Organization"
            ? selectedOrganization?.profile?._id
            : user?.profile,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Listing created successfully",
          },
        });
        setOpenDrawer(false);
        if (onListingCreated) {
          onListingCreated({
            ...data,
            project: selectedProject?.displayName,
            organization: selectedOrganization?.displayName,
          });
        }
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  return (
    <DrawerContainer
      open={openDrawer}
      setOpen={setOpenDrawer}
      title={"Create Listing"}
    >
      <FormBox label="Title">
        <TextField
          value={listingTitle}
          onChange={(e) => setListingTitle(e.target.value)}
          fullWidth={true}
          placeholder="Enter"
        />
      </FormBox>

      <FormBox label="Description">
        <TextField
          value={listingDescription}
          onChange={(e) => setListingDescription(e.target.value)}
          fullWidth={true}
          placeholder="Enter"
          multiline={true}
          rows={4}
        />
      </FormBox>
      <FormBox label="Owner">
        {" "}
        <Radio
          checked={ownerType === "Organization"}
          onChange={() => {
            setOwnerType("Organization");
            setOwner(adminProfiles[0]);
          }}
          color="primary"
          value="Organization"
          name="radio-button-demo"
          inputProps={{ "aria-label": "Organization" }}
        />
        Organization
        <Radio
          checked={ownerType === "Personal"}
          onChange={() => {
            setOwnerType("Personal");
            setOwner(user);
          }}
          color="primary"
          value="Personal"
          name="radio-button-demo"
          inputProps={{ "aria-label": "Personal" }}
        />
        Personal
      </FormBox>

      {ownerType == "Organization" && (
        <FormBox label="Link to Organization">
          <OrgPickerDropdown
            selectedOrg={selectedOrganization}
            setSelectedOrg={setSelectedOrganization}
            hideLabel={true}
            defaultOrganizationId={selectedDefaultOrganizationId}
            fullWidth={true}
          />
        </FormBox>
      )}
      <FormBox label="Link to Project">
        <Box>
          <FormControl fullWidth>
            <ProjectPickerDropdown
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              hideLabel={true}
              defaultProjectId={selectedDefaultProjectId}
            />
          </FormControl>
          {unitType === "Multiple" && selectedProject?._id && (
            <Box
              display="flex"
              sx={{
                borderRadius: "12px",
                border: "1px solid var(--accent-tone-16, #F3F2F8)",
                background: "#F6F8FF",
                boxShadow: "0px 4px 4px 0px rgba(9, 11, 33, 0.02)",
                padding: "12px",
                mt: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box display="flex" alignItems="center">
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "12px",
                    backgroundColor: "#E7ECFD",
                    padding: "3px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src="/icons/app/rocket-emj.svg"
                  />
                </Box>
                <Box sx={{ ml: 2 }}>
                  <Typography>
                    Almost done! Manage your Project Units before Start.
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Button
                  variant="text"
                  color="primary"
                  endIcon={<ArrowForward />}
                  sx={{ textTransform: "none" }}
                  onClick={() => setShowProjectBlockDrawer(true)}
                >
                  Manage
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </FormBox>
      <FormBox label="Select Listing Type">
        <Select
          value={listingType}
          onChange={(evt) => {
            setListingType(evt.target.value);
          }}
          fullWidth={true}
          displayEmpty
        >
          <MenuItem value="">Select</MenuItem>
          {LISTING_TYPES.map((option) => {
            return <MenuItem value={option}>{option}</MenuItem>;
          })}
        </Select>
      </FormBox>
      <FormBox label="Select Team">
        <Select
          value={selectedTemplateId}
          onChange={(evt) => {
            setSelectedTemplateId(evt.target.value);
          }}
          fullWidth={true}
          displayEmpty
        >
          <MenuItem value="">Select</MenuItem>
          {options.map((option) => {
            return (
              <MenuItem value={option}>
                {templateDictionary[option]?.title}
              </MenuItem>
            );
          })}
        </Select>
      </FormBox>
      <DuoButtonGroup
        primaryButtonText="Create"
        primaryButtonListener={() => createListing()}
        secondaryButtonListener={() => setOpenDrawer(false)}
        secondaryButtonText="Cancel"
      />
    </DrawerContainer>
  );
};

export default PrivateListingCreateDrawer;
