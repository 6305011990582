import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import EducationSection from "../sections/EducationSection";
import ExperienceSection from "../sections/ExperienceSection";
import AboutSection from "../sections/AboutSection";
import ProjectSection from "../sections/ProjectSection";
import LanguageSection from "../sections/LanguageSection";
import LicenseSection from "../sections/LicenseSection";
import EmailSection from "../sections/EmailSection";

const useStyles = makeStyles((theme) => ({}));

const UserAbout = ({
  isOwnProfile,
  profile,
  setDialog,
  setShowExperience,
  setShowEducation,
  setShowProject,
  setShowLang,
  setShowLicense,
  setOpenEditView,
  setProfileEditView,
}) => {
  const classes = useStyles();

  return (
    <>
      <AboutSection
        isOwnProfile={isOwnProfile}
        profile={profile}
        setOpenEditView={setOpenEditView}
        setProfileEditView={setProfileEditView}
      />
      <ExperienceSection
        isOwnProfile={isOwnProfile}
        profile={profile}
        setDialog={setDialog}
        setOpenEditView={setOpenEditView}
        setProfileEditView={setProfileEditView}
        setShowExperience={setShowExperience}
      />
      <ProjectSection
        isOwnProfile={isOwnProfile}
        profile={profile}
        setDialog={setDialog}
        setShowProject={setShowProject}
        setOpenEditView={setOpenEditView}
        setProfileEditView={setProfileEditView}
      />
      <EducationSection
        isOwnProfile={isOwnProfile}
        profile={profile}
        setDialog={setDialog}
        setShowEducation={setShowEducation}
        setOpenEditView={setOpenEditView}
        setProfileEditView={setProfileEditView}
      />
      <LanguageSection
        isOwnProfile={isOwnProfile}
        profile={profile}
        setDialog={setDialog}
        setShowLang={setShowLang}
        setOpenEditView={setOpenEditView}
        setProfileEditView={setProfileEditView}
      />
      <LicenseSection
        isOwnProfile={isOwnProfile}
        profile={profile}
        setDialog={setDialog}
        setShowLicense={setShowLicense}
        setOpenEditView={setOpenEditView}
        setProfileEditView={setProfileEditView}
      />
      <EmailSection
        isOwnProfile={isOwnProfile}
        profile={profile}
        setDialog={setDialog}
        setOpenEditView={setOpenEditView}
        setProfileEditView={setProfileEditView}
      />
    </>
  );
};
export default UserAbout;
