import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { green, red } from "@material-ui/core/colors";
import ReactHtmlParser from "react-html-parser";
import IssueEditDrawer from "./issue.edit.drawer";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogTitle: {
    "& p": {
      display: "inline",
      color: "#00345D",
      fontWeight: "600",
    },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tag_container: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
  left_panel: {
    flex: "0.5",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  right_panel: {
    flex: "0.5",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  author_container: {
    marginTop: "0.5rem",
    "& p": {
      display: "inline",
    },
    display: "flex",
    alignItems: "center",
  },
  chip: {
    marginTop: "0.5rem",
    background: "#fde5d7",
    color: "orange",
  },
  statusButtons: {
    display: "flex",
    alignItems: "center",
    marginTop: "0.5rem",
  },
  subHeader: {
    marginTop: "1rem",
    color: "black",
    fontWeight: "500",
  },
  desc: {
    marginTop: "0",
  },
  // dialogContainer: {
  //   borderRadius: "5px",
  // },
  actionButtons: {
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    "& Button": {
      marginRight: "1rem",
      textTransform: "none",
    },
  },
  shared: {
    color: "red",
  },
  resultText: {
    fontWeight: "500",
    fontSize: "0.9rem",
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  // openButton: {
  //   height: "27px",
  //   borderRadius: "10px",
  // },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "red",
    backgroundColor: "#fcd2dc",
    "&:hover": {
      backgroundColor: "#f44336",
      color: "white",
    },
    textTransform: "none",
  },
}))(Button);

const ColorChipGreen = withStyles((theme) => ({
  root: {
    color: "green",
    backgroundColor: "#e6f4eb",
    textTransform: "none",
    marginRight: "1rem",
  },
}))(Chip);

export default function IssueSelectDialog({
  open,
  setOpen,
  setOpenDialogOC,
  issue,
}) {
  const classes = useStyles();
  const history = useHistory();
  const {
    subHeader,
    tag_container,
    left_panel,
    right_panel,
    dialogTitle,
    author_container,
    closeButton,
    chip,
    statusButtons,
    dialogContainer,
    actionButtons,
    openButton,
    desc,
    shared,
    resultText,
    avatar,
  } = classes;

  const handleClose = () => {
    setOpen(false);
  };

  const [showIssueEditDrawer, setShowIssueEditDrawer] = useState(false);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        className={dialogContainer}
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle id="alert-dialog-title" className={dialogTitle}>
          <p>{issue?.title !== "" ? issue?.title : "Untitled Ticket"}</p>
          <IconButton className={closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className={`${desc} ${subHeader}`}>Description</p>
            <div className={resultText}>
              {issue?.description ? (
                <>{ReactHtmlParser(issue.description)}</>
              ) : (
                "No Description"
              )}
            </div>
            <div className={tag_container}>
              <div className={left_panel}>
                <p className={subHeader}>Status</p>
                <div className={statusButtons}>
                  <ColorChipGreen
                    size="small"
                    className={openButton}
                    label="Open"
                  />
                  {/* <Chip className={chip} label="Open" /> */}
                  <ColorButton
                    size="small"
                    onClick={() => setOpenDialogOC(true)}
                  >
                    Close Ticket
                  </ColorButton>
                </div>
                <p className={subHeader}>Current State</p>
                <div>
                  <Chip
                    className={chip}
                    size="small"
                    label={issue?.CurrentStatus?.text || "Start"}
                  />
                </div>
                <p className={subHeader}>Assignee</p>
                <div>
                  {issue?.assigned?.length > 0 ? (
                    <AvatarGroup max={3}>
                      {issue?.assigned.map((person, idx) => {
                        return (
                          <Avatar
                            key={idx}
                            alt={person?.parent?.displayName}
                            src={person?.parent?.displayPicture?.thumbUrl}
                            className={avatar}
                          />
                        );
                      })}
                    </AvatarGroup>
                  ) : (
                    <p className={resultText}>No Assignee</p>
                  )}
                </div>
              </div>
              <div className={right_panel}>
                <p className={subHeader}>Author</p>
                <div className={author_container}>
                  <Avatar
                    className={classes.avatar}
                    alt={issue?.user?.displayName}
                    src={issue?.user?.displayPicture?.url}
                  />{" "}
                  &nbsp;
                  <p className={resultText}>{issue?.user?.displayName}</p>
                </div>
                <p className={subHeader}>Comments</p>
                <p className={resultText}>{issue?.comments?.length}</p>
                <p className={subHeader}>Shared</p>
                <div>
                  {issue?.shared?.length > 0 ? (
                    <AvatarGroup max={3}>
                      {issue?.shared.map((person, idx) => {
                        return (
                          <Avatar
                            key={idx}
                            alt={person?.parent?.displayName}
                            src={person?.parent?.displayPicture?.thumbUrl}
                          />
                        );
                      })}
                    </AvatarGroup>
                  ) : (
                    <p className={`${shared} ${resultText}`}>Not Shared</p>
                  )}
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={actionButtons}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => history.push("/issue/view/" + issue?._id)}
            >
              View Ticket
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push("/issue/edit/" + issue?._id);
              }}
            >
              Edit Ticket
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <IssueEditDrawer
        open={showIssueEditDrawer}
        setOpen={setShowIssueEditDrawer}
      />
    </div>
  );
}
