import React, { useState, useEffect, memo } from "react";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  IconButton,
  Paper,
  TextField,
  Button,
  Switch,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import InputWrapper from "../styled/CommonComponents/InputWrapper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import MultiplePaginationMUIAutocomplete from "../styled/CommonComponents/MultiplePaginatedMUIAutocomplete";
import SinglePaginationMUIAutocomplete from "../styled/CommonComponents/SinglePaginationMUIAutocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import PublicIcon from "@material-ui/icons/Public";
import { useDebounce } from "react-use";

const useStyles = makeStyles((theme) => ({
  paperHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px",
    justifyContent: "space-between",
    "& h3": {
      fontSize: "16px",
      fontWeight: "500",
    },
  },
  inputFieldsCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    padding: "10px",
    marginBottom: "15px",
    marginTop: "10px",
  },
  forPublicAccess: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& p": {
      fontSize: "13px",
      color: "gray",
    },
  },
}));
const StageCreateCard = memo(
  ({ stageData, index, setTempleteData, templeteData }) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { user } = useSelector((state) => state.auth);

    const [approvalType, setApprovalType] = useState("specific_same_power");
    const [requiredApprovalCount, setRequiredApprovalCount] = useState(0);
    const [approvalsWithType, setApprovalsWithType] = useState([]);
    const [canEditMainData, setCanEditMainData] = useState(false);

    useEffect(() => {
      setApprovalType(stageData?.participantsType || "specific_same_power");
      setRequiredApprovalCount(stageData?.numberOfApprovalNeed || 0);
      setApprovalsWithType(stageData?.approvalsWithType || []);
      setCanEditMainData(stageData?.canEditMainData || false);
    }, [stageData]);

    useDebounce(
      () => {
        let stageArr = templeteData?.stages || [];
        stageArr[index].participantsType = approvalType;
        stageArr[index].numberOfApprovalNeed = requiredApprovalCount;
        stageArr[index].approvalsWithType = approvalsWithType;
        stageArr[index].canEditMainData = canEditMainData;
        setTempleteData({
          ...templeteData,
          stages: stageArr,
        });
      },
      1500,
      [approvalType, requiredApprovalCount, approvalsWithType, canEditMainData]);

    return (
      <Paper className={classes.inputFieldsCont}>
        <div className={classes.paperHeader}>
          <h3>Stage {stageData?.stageNo}</h3>
          <div>{stageData?.status}</div>
        </div>
        <InputWrapper
          label={"Participants Type"}
          validation={""}
          isRequired={true}
          space={12}
          inputCom={
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="ParticipantsType"
                name="ParticipantsType"
                value={approvalType}
                onChange={(event) => {
                  setApprovalType(event.target.value);
                }}
              >
                <FormControlLabel
                  value={"specific_same_power"}
                  control={<Radio color="primary" />}
                  label="Specific(Same Power)"
                />
                <FormControlLabel
                  value={"specific_different_power"}
                  control={<Radio color="primary" />}
                  label="Specific(Different Power)"
                />
                <FormControlLabel
                  value={"anybody"}
                  control={<Radio color="primary" />}
                  label="Anybody"
                />
              </RadioGroup>
            </FormControl>
          }
        />
        <FormControlLabel
          value="start"
          control={<Switch
            color="primary"
            checked={canEditMainData}
            onChange={(e) => {
              setCanEditMainData(e.target.checked)
            }}
            name="checkedB"
          />}
          label="Can Edit Main Data"
          labelPlacement="start"
        />
        {approvalType === "specific_same_power" && (
          <>
            <InputWrapper
              label={
                "Specify how many approvals are required to move to the next step"
              }
              validation={""}
              isRequired={true}
              space={12}
              inputCom={
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="number"
                  placeholder="Enter required approvals"
                  autoComplete="off"
                  value={requiredApprovalCount}
                  fullWidth
                  onChange={(e) => {
                    setRequiredApprovalCount(e.target.value);
                  }}
                />
              }
            />
            <InputWrapper
              label={"Participants"}
              validation={""}
              isRequired={true}
              space={12}
              inputCom={
                <MultiplePaginationMUIAutocomplete
                  fullWidth={true}
                  value={approvalsWithType?.map((s) => s?.participant)}
                  onChange={(event, value) => {
                    const arr = value?.map((s) => {
                      return {
                        participant: s,
                        isRequired: false,
                      };
                    });
                    setApprovalsWithType(arr);
                  }}
                  addonFilter={{}}
                  apiUrl={"/users/get/pagination"}
                  placeholder={"Select participants"}
                  variant={"outlined"}
                  size="medium"
                />
              }
            />
          </>
        )}
        {approvalType === "specific_different_power" && (
          <>
            <InputWrapper
              label={
                "Specify how many approvals are required to move to the next step"
              }
              validation={""}
              isRequired={true}
              space={12}
              inputCom={
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="number"
                  placeholder="Enter required approvals"
                  autoComplete="off"
                  value={requiredApprovalCount}
                  fullWidth
                  onChange={(e) => {
                    setRequiredApprovalCount(e.target.value);
                  }}
                />
              }
            />
            <div className={classes.paperHeader} style={{ marginTop: "15px" }}>
              <h3>Participants</h3>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  let arr = [
                    ...approvalsWithType,
                    { participant: null, isRequired: false },
                  ];
                  setApprovalsWithType(arr);
                }}
              >
                + Add
              </Button>
            </div>
            <div
              className={classes.inputFieldsCont}
              style={{ marginTop: "0px" }}
            >
              {approvalsWithType &&
                approvalsWithType.map((approvalType, i) => (
                  <>
                    <InputWrapper
                      label={"User"}
                      validation={""}
                      isRequired={true}
                      space={9}
                      inputCom={
                        <SinglePaginationMUIAutocomplete
                          fullWidth={true}
                          value={approvalType?.participant}
                          onChange={(event, value) => {
                            let arr = [...approvalsWithType];
                            arr[i].participant = value;
                            setApprovalsWithType(arr);
                          }}
                          addonFilter={{}}
                          apiUrl={"/users/get/pagination"}
                          placeholder={"Select user"}
                          variant={"outlined"}
                          size="small"
                        />
                      }
                    />
                    <InputWrapper
                      label={"Is Mandatory"}
                      validation={""}
                      space={2}
                      inputCom={
                        <Switch
                          checked={approvalType?.isRequired}
                          onChange={(e) => {
                            let arr = [...approvalsWithType];
                            arr[i].isRequired = e.target.checked;
                            setApprovalsWithType(arr);
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                    />
                    <IconButton
                      onClick={() => {
                        let arr = [...approvalsWithType];
                        arr.splice(i, 1);
                        setApprovalsWithType(arr);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                ))}
            </div>
          </>
        )}
        {approvalType === "anybody" && (
          <div className={classes.forPublicAccess}>
            <PublicIcon />
            <p>Every participation can perform action in this stage</p>
          </div>
        )}
      </Paper>
    );
  }
);

export default StageCreateCard;
