import React, { useEffect, useState } from "react";
import StandardContainer from "../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import Image from "../styled/generic/Image";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuOverlay from "../styled/generic/MenuOverlay";
import ProjectContacts from "../projects/project.contacts";
import teamUtils from "./team.utils";
import DomainIcon from "@material-ui/icons/Domain";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import {
  Add,
  Filter,
  MoreVert,
  Settings,
  SettingsOutlined,
  Sort,
} from "@material-ui/icons";
import PopupMenuList from "../styled/generic/PopupMenuList";
import SpaceBetween from "../styled/generic/SpaceBetween";
import StatusButton from "../styled/generic/StatusButton";
import StatusChip from "../styled/generic/StatusChip";
import AvatarGroupButton from "../styled/generic/AvatarGroupButton";
import Chip from "../styled/generic/Chip";
import RoundedChip from "../styled/generic/RoundedChip";
import TabsWithActions from "../styled/generic/TabsWithActions";
import TabsWithCommonAction from "../styled/generic/TabsWithCommonAction";
import FormBox from "../styled/generic/FormBox";
import {
  addPhotosToProject,
  getParticipantsPermissionsForTeam,
  getFullProjectData,
  getPopulatedTeamDataByIdForProject,
  updateProjectDataWithPopulated,
} from "./api.call";
import useWorkspace from "../useWorkspace";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import WorkspaceApp from "../styled/generic/WorkspaceApp";
import ProjectHomeWorkspace from "./Project.Home.Workspace";
import FormHeaderWithActions from "../styled/generic/FormHeaderWithActions";
import ProjectHomeAbout from "./Project.Home.About";
import AddressText from "../styled/generic/AddressText";
import DateText from "../styled/generic/DateText";
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import CustomFileUploadDialog from "../file/Uploader/CustomUploadDialog";
import UploadButton from "../styled/generic/UploadButton";
import Api from "../../helpers/Api";
import ProjectHomeListings from "./Project.Home.Listings";
import ProjectHomeContacts from "./Project.Home.Contacts";
import AccessContacts from "../AccessControl/AccessContacts";
import AccessRoles from "../AccessControl/AccessRole";
import AccessControlStoreHook from "../AccessControl/AccessControlStoreHook";
import MultiplePhotoViewer from "../styled/CommonComponents/Multiple.Photo.Viewer";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { getAppsForWorkspace } from "../styled/generic/Api.call";
import CreateProjectBlockDrawer from "../listing/common/drawers/CreateProjectBlockDrawer";
import SelectProjectBlockDrawer from "../listing/common/drawers/SelectProjectBlockDrawer";
import _ from "lodash";
import ActivitiesList from "../activity/ActivitiesList";

const useStyles = makeStyles((theme) => ({
  headPart: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  profilePicSty: {
    width: "44%",
    height: "300px",
    position: "relative",
    cursor: "pointer",
    overflow: "hidden",
    padding: "10px",
    "&:hover": {
      "& $hoverUploadButton": {
        opacity: 1,
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "75%",
      marginBottom: "20px",
      height: "300px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "250px",
    },
  },
  hoverUploadButton: {
    transition: ".5s ease",
    position: "absolute",
    opacity: 0,
    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
    backgroundColor: "white",
    border: "1px solid white",
    borderRadius: "100%",
    top: "50%",
    left: "50%",
    padding: "3px",
    transform: "translate(-50%, -50%)",
    "& .MuiSvgIcon-root": {
      color: "gray",
    },
  },
  othersImagesCont: {
    width: "56%",
    height: "300px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "160px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "100px",
    },
  },
}));

const ProjectHome = () => {
  const classes = useStyles();
  const history = useHistory();
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [tab, setTab] = useState("");
  const [participants, setParticipants] = useState();
  const [actualImages, setActualImages] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [displayPicture, setDisplayPicture] = useState();
  const [team, setTeam] = useState({});
  const [profile, setProfile] = useState(null);
  const [docs, setDocs] = useState([]);
  const [issues, setIssues] = useState([]);
  const [projectBlocks, setProjectBlocks] = useState([]);
  const [rentalUnits, setRentalUnits] = useState([]);
  const [allRentalUnits, setAllRentalUnits] = useState([]);
  const [rentalUnitCount, setRentalUnitCount] = useState(0);
  const [workspaceApps, setWorkspaceApps] = useState([]);
  const [options, setOptions] = useState();
  const [userRole, setUserRole] = useState("Viewer");
  const [showProjectBlockDrawer, setShowProjectBlockDrawer] = useState(false);

  const { checkAccess, updateAccessByTeam } = AccessControlStoreHook();

  const { handleTeams } = teamUtils;
  let { teamId } = useParams();
  const state = useSelector((state) => state);
  const { user } = useSelector((state) => state.auth);
  const { createdFileIds } = useSelector((state) => state.file);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const { teamAccessMap, accessRoleMap } = useSelector(
    (state) => state.accessControl
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (teamId) {
      updateAccessByTeam(teamId);
    }
  }, [teamId]);

  useEffect(() => {
    getAppsForWorkspace({ parentmodel: "Project" })
      .then((data) => {
        let filteredApps = [];
        data.map((item) => {
          if (checkAccess((team?.parent?.profile?._id || team?.parent?.profile), item?.code)) {
            filteredApps.push(item);
          }
        });
   
        if(workspaceApps.length > filteredApps.length) {
          // don't do anything
        }else{
          setWorkspaceApps(filteredApps);
        }
        let arr = [];
        if ((filteredApps && filteredApps.length > 0 ) || workspaceApps.length>0) {
          arr.push("Workspace");
        }

        arr.push("Activities");

        if (checkAccess(profile?._id, "basicSettings-About")) {
          arr.push("About");
        }
        if (checkAccess(profile?._id, "basicSettings-Listing")) {
          arr.push("Listings");
        }
        if (checkAccess(profile?._id, "basicSettings-Bookings")) {
          arr.push("Bookings");
        }
        if (checkAccess(profile?._id, "basicSettings-Contacts")) {
          arr.push("Contacts");
        }
        if (checkAccess(profile?._id, "basicSettings-Access")) {
          arr.push("Access");
        }


        if (arr.includes("Access")) {
          setTab("Access");
        }
        if (arr.includes("Activities")) {
          setTab("Activities");
        }
        if (arr.includes("Contacts")) {
          setTab("Contacts");
        }
        if (arr.includes("Bookings")) {
          setTab("Bookings");
        }
        if (arr.includes("Listings")) {
          setTab("Listings");
        }
        if (arr.includes("Posts")) {
          setTab("Posts");
        }
        if (arr.includes("About")) {
          setTab("About");
        }
        if (arr.includes("Workspace")) {
          setTab("Workspace");
        }

        setOptions(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [profile, teamAccessMap, accessRoleMap]);

  const setUploadedProfilePicture = async () => {
    await updateProjectDataWithPopulated({
      _id: profile?.parent?._id,
      displayPicture: createdFileIds[0],
    })
      .then((data) => {
        setDisplayPicture(data?.displayPicture?.url);
        let teamDataFromDic = teamDictionary[teamId];
        let teamParent = teamDataFromDic?.parent;
        const team = {
          ...teamDataFromDic,
          parent: {
            ...teamParent,
            displayPicture: data?.displayPicture,
          },
        };
        handleTeams([team], state, dispatch);
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch({ type: "FileUploadReset" });
  };

  const getProjectData = async () => {
    if (teamDictionary[teamId]?.parent) {
      const teamData = teamDictionary[teamId];
      setProfile({
        _id: teamData?.parent?.profile?._id
          ? teamData?.parent?.profile?._id
          : teamData?.parent?.profile,
        parent: teamData?.parent,
        parentModelName: "Project",
      });
      const docsArr = teamData?.parent?.docs || [];
      let actualImagesArr = [];
      docsArr.map((docData) => {
        if (docData?.files && docData?.files.length > 0) {
          actualImagesArr.push(docData?.files[0]);
        }
      });
      setActualImages(actualImagesArr);
      setProjectData(teamData?.parent);
      setTeam(teamData);
      setDisplayPicture(teamData?.parent?.displayPicture?.url);
    } else {
      await getPopulatedTeamDataByIdForProject({ teamId: teamId })
        .then((updatedTeam) => {
          setProfile({
            _id: updatedTeam?.parent?.profile?._id
              ? updatedTeam?.parent?.profile?._id
              : updatedTeam?.parent?.profile,
            parent: updatedTeam?.parent,
            parentModelName: "Project",
          });
          const docsArr = updatedTeam?.parent?.docs || [];
          let actualImagesArr = [];
          docsArr.map((docData) => {
            if (docData?.files && docData?.files.length > 0) {
              actualImagesArr.push(docData?.files[0]);
            }
          });
          setActualImages(actualImagesArr);
          setProjectData(updatedTeam?.parent);
          setTeam(updatedTeam);
          setDisplayPicture(updatedTeam?.parent?.displayPicture?.url);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addPhotosToProjectApiCall = async () => {
    if (createdFileIds && createdFileIds.length > 0) {
      let projectId = projectData?._id;
      let userProfile = user?.profile;
      let userId = user?._id;
      let docsArr = [];

      createdFileIds.map((fileId) => {
        docsArr.push({
          image: fileId,
          title: "",
          desc: "",
          tag: "",
        });
      });

      await addPhotosToProject({
        projectId,
        userProfile,
        userId,
        docsArr,
      })
        .then((data) => {
          const updatedTeamData = {
            ...team,
            parent: data,
          };
          const docsArr = updatedTeamData?.parent?.docs || [];
          let actualImagesArr = [];
          docsArr.map((docData) => {
            if (docData?.files && docData?.files.length > 0) {
              actualImagesArr.push(docData?.files[0]);
            }
          });
          setActualImages(actualImagesArr);
          let updatedTeamDictionary = {
            ...teamDictionary,
          };
          updatedTeamDictionary[teamId] = updatedTeamData;
          dispatch({
            type: "AddTeam",
            payload: {
              teamDictionary: updatedTeamDictionary,
            },
          });
          dispatch({ type: "FileUploadReset" });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getProjectBlocks = async () => {
    try {
      const { data, rentalUnits } = await Api.post(
        "/public/project/block/get",
        {
          projectId: projectData?._id,
          getRentalUnits: true,
        }
      );
      setProjectBlocks(data);

      console.log(' getProjectBlocks ',data, rentalUnits,' aredataadn')
      //setRentalUnits(rentalUnits);
      //console.log("kollllllll9999", data, rentalUnits);

      let groupByRantelUnits = _.groupBy(rentalUnits, "projectBlock");

      setAllRentalUnits(groupByRantelUnits);

      if (data) {
        setProjectBlocks(data);
        let _unitCount = 0;
        for (let i = 0; i < data?.length; i++) {
          const block = data[i];
          _unitCount += parseInt(block?.unitCount) || 0;
        }
        setRentalUnitCount(_unitCount);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProjectData();
  }, []);

  useEffect(() => {
    if (projectData && projectData?._id) {
      getProjectBlocks();
    }
  }, [projectData]);

  const reloadProject = () => {
    getProjectData();
  };

  return (
    <StandardContainer>
      <StandardAppContainerRounded>
        <div className={classes.headPart}>
          <div className={classes.profilePicSty}>
            {displayPicture ? (
              <Avatar
                variant="square"
                src={displayPicture}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <Avatar
                style={{ width: "100%", height: "100%" }}
                variant="square"
              >
                <DomainIcon fontSize="large" />
              </Avatar>
            )}
            <div className={classes.hoverUploadButton}>
              <CustomFileUploadButton
                showComponent={
                  <PhotoCameraIcon
                    style={{
                      fontSize: "27px",
                    }}
                  />
                }
                parentType={"Project"}
                parentId={profile?.parent?._id}
                fileNum={1}
                givenFileTypes={["image"]}
                givenMaxSize={26214400}
                closeFunCall={setUploadedProfilePicture}
              />
            </div>
          </div>

          <div className={classes.othersImagesCont}>
            <MultiplePhotoViewer
              projectData={profile?.parent}
              actualImages={actualImages}
              addPhotosToProjectApiCall={addPhotosToProjectApiCall}
              // isViewOnlyMode={isViewOnlyMode}
            />
          </div>
        </div>

        <SpaceBetween
          sx={{ mt: 2 }}
          rightStyleProps={{ alignSelf: "flex-start" }}
          left={
            <Box>
              <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                <Typography sx={{ mr: 2, fontWeight: 600 }} variant="h5">
                  {projectData?.displayName}
                </Typography>
                <StatusChip variant="Warning" label="Private" />
              </Box>
              <AddressText location={projectData?.address} gutterBottom />
              <Typography variant="subtitle1" gutterBottom>
                <DateText
                  prefix="Created date: "
                  date={projectData?.createdAt}
                />
              </Typography>
              <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                <RoundedChip
                  color="primary"
                  label={`Blocks (${projectBlocks?.length})`}
                  variant="outlined"
                  clickable={true}
                  sx={{ mr: 3 }}
                  onClick={() => {
                    setShowProjectBlockDrawer(true);
                  }}
                />
                <RoundedChip
                  color="primary"
                  label={`Units (${rentalUnitCount})`}
                  variant="outlined"
                  clickable={true}
                  onClick={() => {
                    history.push(`/project/units/${teamId}`);
                  }}
                />
              </Box>
            </Box>
          }
          right={
            <Box>
              <AvatarGroupButton
                label="Add"
                onClick={() => {}}
                imgArr={teamDictionary[teamId]?.populatedParticipants}
              />
            </Box>
          }
        />
      </StandardAppContainerRounded>

      <SelectProjectBlockDrawer
        open={showProjectBlockDrawer}
        setOpen={setShowProjectBlockDrawer}
        projectId={projectData?._id}
        setProjectBlocks={setProjectBlocks}
        setRentalUnitCount={setRentalUnitCount}
        allRentalUnits={allRentalUnits}
        projectBlocks={projectBlocks}
      />

      {options && options.length > 0 ? (
        <TabsWithCommonAction
          tabs={options}
          currentTabVariable={tab}
          onTabChange={(val) => setTab(val)}
          variant="h6"
          action={
            <Button
              variant="outlined"
              startIcon={<SettingsOutlined />}
              color="primary"
              sx={{ textTransform: "none" }}
            >
              Settings
            </Button>
          }
        />
      ) : null}
      <Box>
        {tab === "Workspace" && (
          <ProjectHomeWorkspace
            project={projectData}
            team={team}
            workspaceApps={workspaceApps}
          />
        )}
        {tab === "About" && (
          <ProjectHomeAbout
            team={team}
            projectBlocks={projectBlocks}
            setProjectBlocks={setProjectBlocks}
            reloadProject={reloadProject}
          />
        )}
        {tab === "Posts" && <div>Posts</div>}
        {tab === "Listings" && <ProjectHomeListings team={team} />}
        {tab === "Bookings" && <div>Bookings</div>}
        {tab === "Contacts" && (
          <ProjectContacts
            team={team}
            profileId={profile?._id}
            userRole={userRole}
            wallet={profile?.parent?.wallet}
            parent={profile?.parent?._id}
            parentModelName={profile?.parentModelName}
          />
        )}
        {tab === "Access" && <AccessRoles team={team} />}
        {tab === "Activities" && <ActivitiesList modules={[]} profileId={profile?._id} entityType="Project" />}
      </Box>
      {/* {workspaceConfigDialog} */}
      <CustomFileUploadDialog
        open={showImageUploader}
        setOpen={setShowImageUploader}
        fileNum={25}
        givenFileTypes={["image"]}
        givenMaxSize={26214400}
        closeFunCall={(data) => console.log("Data: ", data)}
      />
    </StandardContainer>
  );
};

export default ProjectHome;
