import { Box, IconButton } from "@mui/material";
import React from "react";
import CustomFileUploadButton from "../../file/Uploader/CustomFileUploadButton";
import UploadButton from "./UploadButton";
import { HighlightOffOutlined } from "@material-ui/icons";

const ImageGroupUploader = ({ imageList, onDelete, onUpload, listingId }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      flexWrap="wrap"
    >
      <CustomFileUploadButton
        showComponent={<UploadButton />}
        parentType={"Project"}
        parentId={listingId}
        fileNum={25}
        givenFileTypes={["image"]}
        givenMaxSize={26214400}
        closeFunCall={(data) => {
          onUpload(data);
        }}
      />
      {imageList?.map((image) => (
        <Box
          sx={{
            width: "160px",
            height: "120px",
            borderRadius: "14px",
            overflow: "hidden",
            margin: 2,
            position: "relative",
          }}
        >
          <img
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            src={image.url}
          />
          <IconButton
            size="small"
            sx={{ position: "absolute", top: 0, right: 0, mt: 2 }}
            onClick={() => onDelete(image._id)}
          >
            <HighlightOffOutlined />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};

export default ImageGroupUploader;
