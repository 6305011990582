import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles"
import { Button } from "@material-ui/core"
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinearProgress from "@material-ui/core/LinearProgress";
import { createAndUpdateFinalcialRelation, getAllAdminProjectsAndOrgs } from "../styled/CommonComponents/api.call";
import PaginatedEntityDropdown from "../styled/CommonComponents/PaginatedEntityDropdown";
import { getProfilesByIdsArr } from "../ResourceAccessControl/Api.call";
import { removeDuplicates } from "../../helpers/arrayToReducer";
import AutocompleteWithAvatar from "../styled/CommonComponents/Autocomplete.With.Avatar";

const useStyles = makeStyles((theme) => ({
    mainBodyCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 50px)",
        padding: "0px 10px",
        overflowY: "auto",
    },
    bottomCont: {
        width: "100%",
        height: "45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 10px",
        borderTop: "1px solid #ececec",
    },
    lableSty: {
        fontSize: "16px",
        fontWeight: "500",
        marginBottom: "5px",
        marginTop: "15px",
    }
}));

const ContactCreateDrawer = ({
    openContactDrawer, setOpenContactDrawer, team, callAfterFun, 
    entityProfileId, roles
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [selectedContacts, setSelectedContacts] = useState([])
    const [loader, setLoader] = useState(false)
    const [financialRelationType, setFinancialRelationType] = useState("")
    const [sharedProfiles, setSharedProfiles] = useState([])
    const [shareable, setShareable] = useState([])

    useEffect(() => {
        if (entityProfileId) {
            let givenProfiles = []
            if (entityProfileId === user?.profile) {
                givenProfiles.push(entityProfileId)
            } else {
                givenProfiles.push(entityProfileId)
                givenProfiles.push(user?.profile)
            }
            getAllAdminProjectsAndOrgs({ givenProfiles })
                .then((data) => {
                    console.log(data)
                    const organizationArr = data?.organizations || [];
                    const projectArr = data?.projects || [];
                    let locEntities = [...organizationArr, ...projectArr];
                    getProfilesByIdsArr({ profileIds: givenProfiles })
                        .then((dataArr) => {
                            console.log(dataArr)
                            if (dataArr && dataArr.length > 0) {
                                locEntities = [locEntities, ...dataArr]
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                    let selectedProfile = null;
                    locEntities.map((s) => {
                        if (s?._id == entityProfileId) {
                            selectedProfile = s;
                        }
                    })

                    setShareable(removeDuplicates(locEntities, "_id"))
                    setSharedProfiles([selectedProfile])
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [entityProfileId]);

    useEffect(() => {
        if (openContactDrawer) {
            setSelectedContacts([])
            setLoader(false)
            setFinancialRelationType("")
        }
    }, [openContactDrawer])

    const onContactAddCall = async () => {
        if (selectedContacts && selectedContacts.length > 0) {
            setLoader(true)
            const relObj = {
                mainProfile: team?.parent?.profile?._id,
                shared: sharedProfiles.map((s) => s?._id),
                relationProfileIds: selectedContacts.map((profile) => profile?.data?._id),
                type: financialRelationType ? financialRelationType : "Customer",
                parent: team?.parent?._id,
                parentModelName: team?.parentModelName,
                addedBy: user?.profile,
                user: user?._id,
            };

            await createAndUpdateFinalcialRelation(relObj)
                .then(async (resData) => {
                    setOpenContactDrawer(false)
                    setLoader(false)
                    if (callAfterFun) {
                        callAfterFun()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    return (
        <NormalDrawer
            openDrawer={openContactDrawer}
            setOpenDrawer={setOpenContactDrawer}
            anchor={"right"}
            width={"40vw"}
            title={"Add New Contact(s)"}
            content={<div className={classes.mainBodyCont} >
                <div className={classes.bodyCont} >
                    <p className={classes.lableSty} >Contact(s)<span style={{ color: "red" }} >*</span></p>
                    <PaginatedEntityDropdown
                        value={selectedContacts}
                        onChange={(value) => {
                            setSelectedContacts(value)
                        }}
                        isMulti={true}
                        entity={team?.parentModelName}
                        curEntityId={team?.parent?._id}
                        givenProfileIdArr={[user?.profile]}
                        palCreate={true}
                        noFilter={false}
                        orgtype={"User"}
                        financialRelationType={financialRelationType}
                        givenFilterOption={[
                            {
                                option: "Network",
                                types: ["User", "Organization", "Project"]
                            }
                        ]}
                    />
                    <p className={classes.lableSty} >Role<span style={{ color: "red" }} >*</span></p>
                    <Autocomplete
                        value={financialRelationType}
                        options={roles}
                        getOptionLabel={(option) => { return option || "" }}
                        getOptionSelected={(option) => {
                            return option === financialRelationType
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ margin: "0px" }}
                                margin="normal"
                                variant="outlined"
                                placeholder="Select role"
                            />
                        )}
                        onChange={(e, value) => { setFinancialRelationType(value) }}
                        size="small"
                        fullWidth
                    />
                    <p className={classes.lableSty} >Share<span style={{ color: "red" }} >*</span></p>
                    <AutocompleteWithAvatar
                        value={sharedProfiles}
                        options={shareable}
                        placeholder={"Select organization, project or yourself"}
                        variant="outlined"
                        onChange={(e, value) => {
                            console.log(value)
                            setSharedProfiles(value)
                        }}
                        fullWidth={true}
                    />
                </div>
                <div style={{ width: "100%", height: "5px" }} >
                    {loader && (<LinearProgress />)}
                </div>
                <div className={classes.bottomCont} >
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={onContactAddCall}
                        disabled={(selectedContacts && selectedContacts.length > 0 && financialRelationType && sharedProfiles && sharedProfiles.length > 0) ? false : true}
                    >
                        Save
                    </Button>
                </div>
            </div>}
        />
    );
};

export default ContactCreateDrawer;