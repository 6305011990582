import {
  Autocomplete,
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { searchUsers } from "../../contacts/api.call";
import { useDispatch } from "react-redux";
import { useDebounce } from "react-use";
import { KeyboardArrowDown } from "@material-ui/icons";
import TextField from "./TextField";

const UserPickerDropdown = ({ selectedUsers, setSelectedUsers, hideLabel }) => {
  const [users, setUsers] = useState([]);
  const [text, setText] = useState("");

  const sendQuery = async () => {
    await searchUsers({ name: text })
      .then(({ data }) => {

        // If you select a user in autocomplete and then change search text,
        // New options will be loaded and if they contain old user in response,
        // Old user will not be selected by default
        // This means client can add same user twice when creating work schedule
        // To prevent this, perform a filter operation when fetching users

        setUsers(
          data.filter(
            (userA) => !selectedUsers?.some((userB) => userA._id === userB._id)
          ) || []
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useDebounce(
    () => {
      if (text && text.length > 2) {
        sendQuery();
      } else if (text && text.length === 0) {
        setUsers([]);
      }
    },
    1200,
    [text]
  );

  const dispatch = useDispatch();

  return (
    <Autocomplete
      multiple
      options={users}
      defaultValue={selectedUsers}
      onChange={(evt, newUsers) => setSelectedUsers(newUsers)}
      disableCloseOnSelect
      getOptionLabel={(option) => option?.displayName}
      popupIcon={<KeyboardArrowDown />}
      renderOption={(props, option, { selected }) => {
        return (
          <ListItemButton {...props} selected={selected}>
            <ListItemAvatar>
              <Avatar src={option?.displayPicture?.thumbUrl} />
            </ListItemAvatar>
            <ListItemText
              primary={option?.displayName}
              secondary={option?.email}
            />
          </ListItemButton>
        );
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={hideLabel ? null : "Employees"}
          placeholder="Select employees"
          value={text}
          onChange={(e) => setText(e.target.value)}
          variant="outlined"
        />
      )}
    />
  );
};

export default UserPickerDropdown;
