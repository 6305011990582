const { default: Api } = require("../../helpers/Api");

export const getOptionsForActivitiesFilter = async (obj) => {
    try {
        const res = await Api.post("activity/get/filter/options", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


export const getActivities = async (obj) => {
    try {
        const res = await Api.post("activity/filter", obj);
        const data = res?.data || {};
        return {
            activities: data.activities || [],
            totalCount: data.totalCount || 0,
            currentPage: data.currentPage || 0,
            totalPages: data.totalPages || 0
        };
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getActivityParentList = async (obj) => {
    try {
        const res = await Api.post("activity/parent-list", obj);
        console.log(res, ' is the getActivityParentList');
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getFilterOptionsForActivityParent = async (obj) => {
    try {
        const res = await Api.post("activity/parent/filter-options", obj);
        const data = res?.data || {};
        return {
            activityTypes: data.activityTypes || [],
            modules: data.modules || [],
            users: data.users || []
        };
    } catch (error) {
        console.log(error);
        return null;
    }
};