import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Avatar, Button, IconButton, useMediaQuery } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual";
import LinearProgress from "@material-ui/core/LinearProgress";
import GooglePlaceAutocomplete from "../../styled/CommonComponents/Google.Place.Auto";
import CategoryAutocomplete from "../../styled/CommonComponents/CategoryAutocomplate";
import AddLocationTag from "../../styled/CommonComponents/Add.Location.Tag";
import CustomFileUploadButton from "../../file/Uploader/CustomFileUploadButton";
import profilePicDefault from "../../../Assets/Profile_pic_default.svg";
import { updateOrgWithPopulate } from "../api.call";
import teamUtils from "../../team/team.utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDebounce } from "react-use";
import {
  getUserCallForUsernameCheck,
  updateProfileData,
} from "../../preLogin/api.call";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as yup from "yup";
import { Checkbox, FormControlLabel } from "@mui/material";

const usernameSchema = yup.object().shape({
  username: yup
    .string()
    .max(100, "username should have less then 100 characters.")
    .min(5, "username should be 5 characters minimum.")
    .required("username should be required"),
});

const useStyles = makeStyles((theme) => ({
  header: {
    height: "60px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #e4e7e7",
    padding: "0px 25px",
    "& h3": {
      fontSize: "17px",
      fontWeight: "450",
      color: "#170f49",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 10px",
      "& h3": {
        width: "150px",
      },
    },
  },
  body: {
    width: "100%",
    height: "calc(100% - 65px)",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  },
  editCont: {
    width: "100%",
    padding: "30px 45px 80px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      padding: "15px 10px 30px",
      flexDirection: "column",
    },
  },
  inputConts: {
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  roleAndCategoryCont: {
    width: "35%",
    paddingTop: "185px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingTop: "15px",
    },
  },
  profilePicCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "15px",
    },
  },
  profilePic: {
    width: "150px",
    height: "150px",
  },
  actionBtn: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "25px",
  },
  coverPicCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
  },
  editCoverButton: {
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    bottom: "0px",
    right: "0px",
    color: "white",
    "&:hover": {
      backgroundColor: "#076cbc",
    },
  },
  coverPic: {
    width: "250px",
    height: "150px",
  },
  picturesCont: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "35px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  editProfileButton: {
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    bottom: "0px",
    right: "0px",
    color: "white",
    "&:hover": {
      backgroundColor: "#076cbc",
    },
  },
  profileUploadButton: {
    width: "150px",
    "& p": {
      textTransform: "capital",
    },
  },
  fullNameCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  nameInput: {
    width: "47%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  bioSty: {
    width: "100%",
    marginBottom: "20px",
  },
  addressCont: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  labelStyle: {
    fontSize: "14px",
    fontWeight: "500",
    padding: "5px 0px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  warningSty: {
    fontSize: "14px",
    color: "red",
    fontWeight: "450",
    textAlign: "left",
  },
}));

const compSizeArray = [
  "0-1 employees",
  "2-10 employees",
  "11-50 employees",
  "51-200 employees",
  "201-500 employees",
  "501-1,000 employees",
  "1,001-5,000 employees",
  "5,001-10,000 employees",
  "10,000+ employees",
];

export default function BasicInfoEdit({ setOpenEditView, profile, teamId }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { handleTeams } = teamUtils;

  const state = useSelector((state) => state);
  const { teamDictionary } = useSelector((state) => state.team);
  const { user } = useSelector((state) => state.auth);
  const { createdFileIds } = useSelector((state) => state.file);

  //for address
  const [fullAddressLine, setFullAddressLine] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [regionCode, setRegionCode] = useState("");
  const [country, setCountry] = useState("India(IN)");
  const [countryCode, setCountryCode] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  //for markets
  const [marketList, setMarketList] = useState([]);
  //for skills
  const [selectedCategories, setSelectedCategories] = useState([]);
  //others basic info
  const [displayName, setDisplayName] = useState("");
  const [makePrivate, setMakePrivate] = useState(false);
  const [username, setUsername] = useState("");
  const [foundingYear, setFoundingYear] = useState("");
  const [totalProjectNo, setTotalProjectNo] = useState("");
  const [activeProjectNo, setActiveProjectNo] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [numbersOfEmployees, setNumbersOfEmployees] = useState("");
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [about, setAbout] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [coverPic, setCoverPic] = useState(null);
  const [loading, setLoading] = useState(false);
  const [usernameCheckLoading, setUsernameCheckLoading] = useState(false);
  const [usernameValidationObj, setUsernameValidationObj] = useState(null);
  const [isValidUsername, setIsValidUsername] = useState(true);

  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    if (profile?.parent?.displayPicture?.url) {
      setProfilePic(profile?.parent?.displayPicture);
    } else {
      setProfilePic(null);
    }
    if (profile?.parent?.cover?.url) {
      setCoverPic(profile?.parent?.cover);
    } else {
      setCoverPic(null);
    }
    setAbout(profile?.parent?.description);
    setBio(profile?.parent?.tagline);
    setDisplayName(profile?.parent?.displayName || "");
    setUsername(profile?.parent?.username || "");
    setFoundingYear(profile?.parent?.foundingYear || "");
    setTotalProjectNo(profile?.parent?.numberOfProjects || "");
    setActiveProjectNo(profile?.parent?.numberOfActiveProjects || "");
    setWebsiteLink(profile?.parent?.website || "");
    setNumbersOfEmployees(profile?.parent?.companySize || "");
    setMakePrivate(profile?.parent?.makePrivate || false);

    let countryText = profile?.parent?.address?.country;
    let lowerCode = "us";
    if (countryText && countryText.includes("(")) {
      let len = countryText.length;
      let code = countryText.substring(len - 3, len - 1);
      if (code) {
        lowerCode = code.toLowerCase();
      }
    }
    setCountry(profile?.parent?.address?.country || "United States(US)");
    setCountryCode(lowerCode || "in");
    setRegion(profile?.parent?.address?.region || "");
    setRegionCode(profile?.parent?.address?.postal_code || "");
    setZip(profile?.parent?.address?.zip || "");
    setCity(profile?.parent?.address?.city || "");
    setFullAddressLine(profile?.parent?.address?.line1 || "");
    setStreetAddress(profile?.parent?.address?.streetAddress || "");
    setLatitude(profile?.parent?.address?.latitude || "");
    setLongitude(profile?.parent?.address?.longitude || "");
    setEmail(profile?.parent?.email || "");
    setPhone(profile?.parent?.phone || "");

    let entireList = profile?.parent?.marketsList || [];
    let newList = [];

    entireList.map((ele) => {
      let address_line1 = ele?.address_line1;
      let address_line2 = ele?.address_line2;
      let name = ele?.name;
      let city = ele?.city;
      let country = ele?.country;
      let country_code = ele?.country_code;
      let lat = ele?.loc?.coordinates?.[1];
      let lon = ele?.loc?.coordinates?.[0];
      let state = ele?.state;
      let state_code = ele?.state_code;
      let postcode = ele?.postcode;
      let key = `${lat}$${lon}`;
      newList.push({
        key: key,
        label: name,
        address: {
          address_line1,
          address_line2,
          name,
          city,
          country,
          country_code,
          lat,
          lon,
          state,
          state_code,
          postcode,
        },
      });
    });
    setMarketList(newList);

    //have to check markets & skills
    let skillStrs = profile?.parent?.skills || [];
    let skillIds = profile?.parent?.skillTags || [];
    let objArr = [];

    if (
      skillStrs &&
      skillIds &&
      skillIds.length !== 0 &&
      skillIds.length === skillStrs.length
    ) {
      skillStrs.map((str, i) => {
        objArr.push({
          name: str,
          _id: skillIds[i],
        });
      });
      setSelectedCategories(objArr);
    }
  }, [profile?.parent]);

  //done profile pic update
  const setUploadedProfilePicture = async () => {
    setLoading(true);
    await updateOrgWithPopulate({
      _id: profile?.parent?._id,
      displayPicture: createdFileIds[0],
    })
      .then((data) => {
        setProfilePic(data?.displayPicture);

        let teamDataFromDic = teamDictionary[teamId];
        let teamParent = teamDataFromDic?.parent;
        const team = {
          ...teamDataFromDic,
          parent: {
            ...teamParent,
            displayPicture: data?.displayPicture,
          },
        };
        handleTeams([team], state, dispatch);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch({ type: "FileUploadReset" });
  };

  //done cover pic update
  const setUploadedCoverPicture = async () => {
    setLoading(true);
    await updateOrgWithPopulate({
      _id: profile?.parent?._id,
      cover: createdFileIds[0],
    })
      .then((data) => {
        setCoverPic(data?.cover);

        let teamDataFromDic = teamDictionary[teamId];
        let teamParent = teamDataFromDic?.parent;
        const team = {
          ...teamDataFromDic,
          parent: {
            ...teamParent,
            cover: data?.cover,
          },
        };
        handleTeams([team], state, dispatch);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch({ type: "FileUploadReset" });
  };

  //done full basic info update
  const updateProfileSection = async () => {
    setLoading(true);
    let strArr = new Set();
    let strArrId = new Set();
    let entireList = [];
    let marketsTags = [];

    marketList.map((ele) => {
      let address_line1 = ele?.address?.address_line1;
      let address_line2 = ele?.address?.address_line2;
      let name = ele?.label;
      let city = ele?.address?.city;
      let country = ele?.address?.country;
      let country_code = ele?.address?.country_code;
      let lat = ele?.address?.lat;
      let lon = ele?.address?.lon;
      let state = ele?.address?.state;
      let state_code = ele?.address?.state_code;
      let postcode = ele?.address?.postcode;
      entireList.push({
        address_line1,
        address_line2,
        name,
        city,
        country,
        country_code,
        loc: {
          type: "Point",
          coordinates: [lon, lat],
        },
        state,
        state_code,
        postcode,
      });

      if (ele?.address?.state) {
        let str = `${ele?.address?.state}_${ele?.address?.country}`;
        marketsTags.push(str);
      } else {
        let str = `${ele?.address?.country}_${ele?.address?.country}`;
        marketsTags.push(str);
      }
    });

    selectedCategories.map((str) => {
      strArr.add(str?.name);
      strArrId.add(str?._id);
    });

    const updatedOrgObj = {
      _id: profile?.parent?._id,
      description: about || "",
      tagline: bio || "",
      about: about || "",
      email: email || "",
      phone: phone || "",
      displayName: displayName,
      foundingYear: foundingYear,
      numberOfActiveProjects: activeProjectNo,
      numberOfProjects: totalProjectNo,
      website: websiteLink,
      makePrivate: makePrivate,
      companySize: numbersOfEmployees,
      skills: [...strArr],
      skillTags: [...strArrId],
      marketsList: entireList,
      markets: [...marketsTags],
      address: {
        line1: fullAddressLine,
        postal_code: regionCode,
        city: city,
        state: region,
        country: country,
        latitude: latitude,
        longitude: longitude,
        streetAddress: streetAddress,
        zip: zip,
        region: region,
      },
    };

    await updateOrgWithPopulate(updatedOrgObj)
      .then((updatedOrgData) => {
        let teamDataFromDic = teamDictionary[teamId];
        let teamParent = teamDataFromDic?.parent;
        const team = {
          ...teamDataFromDic,
          parent: {
            ...teamParent,
            ...updatedOrgData,
          },
        };
        handleTeams([team], state, dispatch);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setUsernameValidationObj(null);
  }, [username]);

  useDebounce(
    async () => {
      await usernameSchema
        .validate({ username: username }, { abortEarly: false })
        .then(async (data) => {
          await handleUsernameOnChange();
        })
        .catch((err) => {
          let errorArr = err?.inner;
          let obj = {};
          errorArr.map((err) => {
            obj[err?.path] = err?.message;
          });
          setUsernameValidationObj(obj);
        });
    },
    1500,
    [username]
  );

  const updateUsernameApiHit = async () => {
    await updateOrgWithPopulate({
      _id: profile?.parent?._id,
      username: username,
    })
      .then(async (data) => {
        let teamDataFromDic = teamDictionary[teamId];
        let teamParent = teamDataFromDic?.parent;
        const team = {
          ...teamDataFromDic,
          parent: {
            ...teamParent,
            username: username,
          },
        };
        handleTeams([team], state, dispatch);

        await updateProfileData({
          _id: profile?._id,
          username: username,
        })
          .then((data1) => {
            console.log("data1");
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUsernameOnChange = async () => {
    setUsernameCheckLoading(true);
    await getUserCallForUsernameCheck({ username: username })
      .then(async (data) => {
        if (data && data.length === 0) {
          setIsValidUsername(true);
          await updateUsernameApiHit();
        } else if (
          data &&
          data.length === 1 &&
          profile?.parent?.username === username
        ) {
          setIsValidUsername(true);
          await updateUsernameApiHit();
        } else {
          setIsValidUsername(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setUsernameCheckLoading(false);
  };

  return (
    <>
      <div className={classes.header}>
        <h3>{isSmall ? "Basic Informations" : "Edit Basic Informations"}</h3>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={makePrivate}
                onChange={(evt, checked) => {
                  setMakePrivate(checked);
                }}
              />
            }
            label="Make Private"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={updateProfileSection}
            size={isSmall ? "small" : "medium"}
          >
            Save
          </Button>
          <IconButton
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setOpenEditView(false);
            }}
            size={isSmall ? "small" : "medium"}
          >
            <ClearIcon />
          </IconButton>
        </div>
      </div>
      <div style={{ height: "5px" }}>{loading && <LinearProgress />}</div>
      <div className={classes.body}>
        <div className={classes.editCont}>
          <div className={classes.inputConts}>
            <div className={classes.picturesCont}>
              {profilePic?.url ? (
                <div className={classes.profilePicCont}>
                  <Avatar
                    src={profilePic?.url}
                    className={classes.profilePic}
                  />
                  <CustomFileUploadButton
                    showComponent={
                      <IconButton className={classes.editProfileButton}>
                        <EditIcon />
                      </IconButton>
                    }
                    parentType={"User"}
                    parentId={user?._id}
                    fileNum={1}
                    givenFileTypes={["image"]}
                    givenMaxSize={26214400}
                    closeFunCall={setUploadedProfilePicture}
                  />
                </div>
              ) : (
                <div className={classes.profilePicCont}>
                  <Avatar
                    src={profilePicDefault}
                    className={classes.profilePic}
                  />
                  <CustomFileUploadButton
                    showComponent={
                      <IconButton className={classes.editProfileButton}>
                        <EditIcon />
                      </IconButton>
                    }
                    parentType={"User"}
                    parentId={user?._id}
                    fileNum={1}
                    givenFileTypes={["image"]}
                    givenMaxSize={26214400}
                    closeFunCall={setUploadedProfilePicture}
                  />
                </div>
              )}
              {coverPic?.url ? (
                <div className={classes.coverPicCont}>
                  <Avatar
                    variant="square"
                    src={coverPic?.url}
                    className={classes.coverPic}
                  />
                  <CustomFileUploadButton
                    showComponent={
                      <IconButton className={classes.editCoverButton}>
                        <EditIcon />
                      </IconButton>
                    }
                    parentType={"User"}
                    givenFileTypes={["image"]}
                    parentId={user?._id}
                    fileNum={1}
                    givenMaxSize={26214400}
                    closeFunCall={setUploadedCoverPicture}
                  />
                </div>
              ) : (
                <div className={classes.coverPicCont}>
                  <PhotoSizeSelectActualIcon className={classes.coverPic} />
                  <CustomFileUploadButton
                    showComponent={
                      <IconButton className={classes.editCoverButton}>
                        <EditIcon />
                      </IconButton>
                    }
                    parentType={"User"}
                    givenFileTypes={["image"]}
                    parentId={user?._id}
                    fileNum={1}
                    givenMaxSize={26214400}
                    closeFunCall={setUploadedCoverPicture}
                  />
                </div>
              )}
            </div>

            <p className={classes.labelStyle}>Name</p>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Enter organization name"
              size="small"
              value={displayName}
              onChange={(e) => {
                setDisplayName(e.target.value);
              }}
              className={classes.bioSty}
            />

            <p className={classes.labelStyle}>
              {isValidUsername ? (
                "Username"
              ) : (
                <span style={{ color: "red" }}>Username already exists</span>
              )}
            </p>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Enter username"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {usernameCheckLoading && (
                      <CircularProgress size={27} thickness={3} />
                    )}
                  </InputAdornment>
                ),
              }}
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              className={classes.bioSty}
              style={{ marginBottom: "0px" }}
            />
            <div
              style={{ height: "10px", width: "100%", marginBottom: "15px" }}
            >
              {usernameValidationObj?.username && (
                <p className={classes.warningSty}>
                  {usernameValidationObj?.username}
                </p>
              )}
            </div>

            <div className={classes.fullNameCont}>
              <div className={classes.nameInput}>
                <p className={classes.labelStyle}>Year Of Establishment</p>
                <TextField
                  id="outlined-basic"
                  placeholder="Enter year of establishment"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={foundingYear}
                  onChange={(e) => {
                    setFoundingYear(e.target.value);
                  }}
                  style={{ width: "100%" }}
                />
              </div>
              <div
                className={classes.nameInput}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ width: "48%" }}>
                  <p className={classes.labelStyle}>Total Projects</p>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter number of total projects"
                    size="small"
                    type="number"
                    value={totalProjectNo}
                    onChange={(e) => {
                      setTotalProjectNo(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <p className={classes.labelStyle}>Active Projects</p>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter number of active projects"
                    size="small"
                    type="number"
                    value={activeProjectNo}
                    onChange={(e) => {
                      setActiveProjectNo(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>

            <div className={classes.fullNameCont}>
              <div className={classes.nameInput}>
                <p className={classes.labelStyle}>Website Link</p>
                <TextField
                  id="outlined-basic"
                  placeholder="Enter website link"
                  variant="outlined"
                  size="small"
                  value={websiteLink}
                  onChange={(e) => {
                    setWebsiteLink(e.target.value);
                  }}
                  style={{ width: "100%" }}
                />
              </div>
              <div className={classes.nameInput}>
                <p className={classes.labelStyle}>Number Of Employees</p>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  value={numbersOfEmployees}
                  options={compSizeArray}
                  getOptionLabel={(option) => {
                    return option;
                  }}
                  getOptionSelected={(option) => {
                    return option === numbersOfEmployees;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ margin: "0px" }}
                      margin="normal"
                      variant="outlined"
                      placeholder={"Enter number of employees"}
                    />
                  )}
                  onChange={(event, value) => {
                    setNumbersOfEmployees(value);
                  }}
                  style={{ width: "100%" }}
                  size="small"
                />
              </div>
            </div>

            <div className={classes.fullNameCont}>
              <div className={classes.nameInput}>
                <p className={classes.labelStyle}>Mail Id</p>
                <TextField
                  id="outlined-basic"
                  placeholder="Enter mail id"
                  variant="outlined"
                  size="small"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  style={{ width: "100%" }}
                />
              </div>
              <div className={classes.nameInput}>
                <p className={classes.labelStyle}>Phone Number</p>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter phone number"
                  size="small"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <p className={classes.labelStyle}>Bio</p>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Add bio"
              size="small"
              value={bio}
              onChange={(e) => {
                setBio(e.target.value);
              }}
              className={classes.bioSty}
            />
            <p className={classes.labelStyle}>About</p>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={5}
              variant="outlined"
              placeholder="Tell something about you"
              size="small"
              value={about}
              onChange={(e) => {
                setAbout(e.target.value);
              }}
              className={classes.bioSty}
            />
            <p
              style={{
                fontSize: "18px",
                fontWeight: "510",
                marginTop: "20px",
              }}
            >
              Headquarter Location
            </p>
            <div className={classes.bioSty}>
              <GooglePlaceAutocomplete
                inputContStyle={classes.addressCont}
                autoWidth={"100%"}
                textWidth={"100%"}
                isGetLogLat={true}
                fullAddressLine={fullAddressLine}
                setFullAddressLine={setFullAddressLine}
                streetAddress={streetAddress}
                setStreetAddress={setStreetAddress}
                zip={zip}
                setZip={setZip}
                city={city}
                setCity={setCity}
                region={region}
                setRegion={setRegion}
                regionCode={regionCode}
                setRegionCode={setRegionCode}
                country={country}
                setCountry={setCountry}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                latitude={latitude}
                setLatitude={setLatitude}
                longitude={longitude}
                setLongitude={setLongitude}
                isShowCountry={true}
                isShowCityStreet={true}
                isStateZip={true}
                noAddressShow={false}
                noMap={false}
              />
            </div>
          </div>
          <div className={classes.roleAndCategoryCont}>
            <p className={classes.labelStyle}>Add Role(s)</p>
            <div className={classes.bioSty}>
              <CategoryAutocomplete
                categories={selectedCategories}
                setCategories={setSelectedCategories}
                placeholder={"Search Roles"}
              />
            </div>

            <p className={classes.labelStyle}>Add Market(s)</p>
            <div className={classes.bioSty}>
              <AddLocationTag
                marketList={marketList}
                setMarketList={setMarketList}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
