import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import NormalDialog from './NormalDialog';
import { Button } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    btnCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        },
    }
}))

export default function DeleteConfirmBox({
    open, setOpen, loading, pageTitle, cantent, cancleConfirmDelete,
    confirmDelete }) {
    const classes = useStyles();

    return (
        <NormalDialog
            openDialog={open}
            handleCloseShare={() => {
                if (!loading) {
                    setOpen(false)
                }
            }}
            pageTitle={pageTitle}
            content={<>
                {cantent}
                <div style={{ width: "100%", height: "4px" }} >
                    {loading && <LinearProgress />}
                </div>
                <div className={classes.btnCont} >
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginRight: "10px" }}
                        onClick={cancleConfirmDelete}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={confirmDelete}
                        disabled={loading}
                    >
                        Yes, Delete it!
                    </Button>
                </div>
            </>
            }
        />
    );
}