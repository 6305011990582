import React, { useEffect, useState } from "react";
import { Box, MenuItem, Typography } from "@material-ui/core";
import TabsWithSearch from "../../styled/generic/TabsWithSearch";
import MyListings from "../common/tablecontainers/MyListings";
import MyLeads from "../common/tablecontainers/MyLeads";
import MyRequests from "../common/tablecontainers/MyRequests";
import MyApplications from "../common/tablecontainers/MyApplications";

export default function Listings() {
  const [listingTab, setListingTab] = useState("Listings");

  return (
    <Box>
      <Box sx={{ backgroundColor: "#ffffff", borderRadius: "10px", p: 2 }}>
        <TabsWithSearch
          tabs={["Listings", "Leads", "Requests", "My Applications"]}
          onTabChange={(newTabVal) => setListingTab(newTabVal)}
          currentTabVariable={listingTab}
          disableUnderline={true}
        />

        {listingTab === "Listings" && <MyListings />}
        {listingTab === "Leads" && <MyLeads />}
        {listingTab === "Requests" && <MyRequests />}
        {listingTab === "My Applications" && <MyApplications />}
      </Box>
    </Box>
  );
}
