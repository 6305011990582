import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import { addProfileSectionApiHit } from "../api";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Checkbox } from '@material-ui/core';
import { countriesStateMap } from "../../styled/countryState"
import SaveIcon from '@material-ui/icons/Save';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as yup from "yup";

const schema = yup.object().shape({
  employmentType: yup.string().required("Employment type should be required"),
  companyName: yup.string().min(2).required("Company name should be required"),
  designation: yup.string().required("Designation should be required"),
  startMonth: yup.string().required("Start month should be required"),
  startYear: yup.string().required("Start year should be required"),
  description: yup.string().max(350),
})

const useStyles = makeStyles((theme) => ({
  singleEducationCont: {
    width: "550px",
    maxHeight: "450px",
    overflowY: "auto",
    marginBottom: "20px",
    padding: "0px 15px 10px",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down("sm")]: {
      width: "450px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 5px 10px",
      width: "300px",
      maxHeight: "360px",
    },
  },
  textLineCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  halfCont: {
    width: "47%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    }
  },
  checkedCont: {
    display: "flex",
    alignItems: "center",
    marginBottom: "-10px",
    "& h3": {
      fontSize: "15px",
      marginRight: "15px",
      fontWeight: "500",
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    marginTop: "7px",
    marginBottom: "0px"
  }
}));


const calendarMonth = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const employmentTypes = [
  "Full-time",
  "Part-time",
  "Self-Employed",
  "Freelance",
  "Internship",
  "Trainee",
];


const ExperienceDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme()
  const state = useSelector((state) => state);
  const { user, organizationIds } = useSelector((state) => state.auth);

  const [employmentType, setEmploymentType] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [position, setPosition] = useState("");
  const [startMonthVal, setStartMonthVal] = useState("")
  const [startYearVal, setStartYearVal] = useState("")
  const [endMonthVal, setEndMonthVal] = useState("")
  const [endYearVal, setEndYearVal] = useState("")
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false)
  const [calendarYear, setCalendarYear] = useState([]);
  const [locationStrArr, setLocationStrArr] = useState([])
  const [loading, setLoading] = useState(false)
  const [validationObj, setValidationObj] = useState({})

  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    let allCountries = Object.keys(countriesStateMap)
    let objArr = []
    allCountries.map((country) => {
      const statsArr = countriesStateMap?.[country]
      objArr.push(country)

      statsArr.map((s) => {
        objArr.push(`${s}, ${country}`)
      })
    })
    setLocationStrArr(objArr)
  }, [])

  useEffect(() => {
    const years = [];
    const date = new Date();
    const endYear = date.getFullYear();
    for (let i = 1951; i <= endYear; i++) {
      years.push(i + "");
    }
    setCalendarYear(years);
  }, []);

  useEffect(() => {
    setValidationObj({})
  }, [
    employmentType,
    companyName,
    position,
    startMonthVal,
    startYearVal,
    description,
  ])

  const saveData = async (e) => {
    e.preventDefault();

    const formData = {
      employmentType: employmentType,
      companyName: companyName,
      designation: position,
      startMonth: startMonthVal,
      startYear: startYearVal,
      description: description,
    }

    await schema.validate(formData, { abortEarly: false })
      .then(async (data) => {
        setLoading(true)
        let userId = user?._id
        let arrayKey = "experience"
        let sectionDataArr = [{
          title: position,
          employmentType: employmentType,
          companyName: companyName,
          location: location,
          start_date: `${startMonthVal}-${startYearVal}`,
          isCurrentlyWorking: isCurrentlyWorking,
          end_date: `${endMonthVal}-${endYearVal}`,
          description: description,
        }]

        await addProfileSectionApiHit({ userId, arrayKey, sectionDataArr })
          .then((data) => {
            dispatch({
              type: "AddAuth",
              payload: {
                user: {
                  ...user,
                  experience: data?.experience,
                },
              },
            });
            setLoading(false)
          })
          .catch((err) => {
            console.log(err);
          })
        setEmploymentType("")
        setCompanyName("")
        setLocation("")
        setDescription("")
        setPosition("")
        setStartMonthVal("")
        setStartYearVal("")
        setEndMonthVal("")
        setEndYearVal("")
        setIsCurrentlyWorking(false)
        setOpen(false)
      })
      .catch((err) => {
        let errorArr = err?.inner
        let obj = {}
        errorArr.map((err) => {
          obj[err?.path] = err?.message
        })
        setValidationObj(obj)
      })
  };




  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={handleClose}
      pageTitle={"Add Experience"}
      content={<form onSubmit={saveData} >
        <div className={classes.singleEducationCont} >
          <div className={classes.textLineCont} >
            <div className={classes.halfCont} >
              <p className={classes.label} >Designation</p>
              <TextField
                id="school"
                placeholder="Enter designation"
                value={position}
                onChange={(e) => {
                  setPosition(e.target.value)
                }}
                size="small"
                variant="outlined"
                style={{ width: "100%" }}
              />
              {validationObj?.designation && (<p style={{ color: "#EE1D52" }} >{validationObj?.designation}</p>)}
            </div>
            <div className={classes.halfCont} >
              <p className={classes.label} >Employment Type</p>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={employmentType}
                options={employmentTypes}
                getOptionLabel={(option) => { return option }}
                getOptionSelected={(option) => {
                  return option == employmentType
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ margin: "0px" }}
                    margin="normal"
                    variant="outlined"
                    placeholder={"Enter Employment Type"}
                  />
                )}
                onChange={(event, value) => {
                  setEmploymentType(value);
                }}
                style={{ width: "100%" }}
                size="small"
              />
              {validationObj?.employmentType && (<p style={{ color: "#EE1D52" }} >{validationObj?.employmentType}</p>)}
            </div>
          </div>
          <div className={classes.textLineCont} >
            <div className={classes.halfCont} >
              <p className={classes.label} >Company Name</p>
              <TextField
                id="field"
                placeholder="Enter company name"
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                size="small"
                variant="outlined"
                fullWidth
              />
              {validationObj?.companyName && (<p style={{ color: "#EE1D52" }} >{validationObj?.companyName}</p>)}
            </div>
            <div className={classes.halfCont} >
              <p className={classes.label} >Location</p>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={location}
                options={locationStrArr}
                getOptionLabel={(option) => { return option }}
                getOptionSelected={(option) => {
                  return option == location
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ margin: "0px" }}
                    margin="normal"
                    variant="outlined"
                    placeholder={"Enter Location"}
                  />
                )}
                onChange={(event, value) => {
                  setLocation(value);
                }}
                style={{ width: "100%" }}
                size="small"
              />
            </div>
          </div>
          <p className={classes.label} >Start date</p>
          <div className={classes.textLineCont} >
            <div className={classes.halfCont} >
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={startMonthVal}
                options={calendarMonth}
                getOptionLabel={(option) => { return option }}
                getOptionSelected={(option) => {
                  return option === startMonthVal
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ margin: "0px" }}
                    margin="normal"
                    variant="outlined"
                    placeholder={"Enter Start Month"}
                  />
                )}
                onChange={(event, value) => {
                  setStartMonthVal(value)
                }}
                style={{ width: "100%", marginBottom: "5px" }}
                size="small"
              />
              {validationObj?.startMonth && (<p style={{ color: "#EE1D52" }} >{validationObj?.startMonth}</p>)}
            </div>
            <div className={classes.halfCont} >
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={startYearVal}
                options={calendarYear}
                getOptionLabel={(option) => { return option }}
                getOptionSelected={(option) => {
                  return option === startYearVal
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ margin: "0px" }}
                    margin="normal"
                    variant="outlined"
                    placeholder={"Enter Start Year"}
                  />
                )}
                onChange={(event, value) => {
                  setStartYearVal(value)
                }}
                style={{ width: "100%", marginBottom: "5px" }}
                size="small"
              />
              {validationObj?.startYear && (<p style={{ color: "#EE1D52" }} >{validationObj?.startYear}</p>)}
            </div>
          </div>
          <div className={classes.checkedCont} >
            <h3>Currently Working</h3>
            <Checkbox
              color="primary"
              checked={isCurrentlyWorking}
              onChange={(e) => { setIsCurrentlyWorking(e.target.checked) }}
            />
          </div>
          {isCurrentlyWorking ? null : (<>
            <p className={classes.label} >End date</p>
            <div className={classes.textLineCont} >
              <div className={classes.halfCont} >
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  value={endMonthVal}
                  options={calendarMonth}
                  getOptionLabel={(option) => { return option }}
                  getOptionSelected={(option) => {
                    return option === endMonthVal
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ margin: "0px" }}
                      margin="normal"
                      variant="outlined"
                      placeholder={"Enter End Month"}
                    />
                  )}
                  onChange={(event, value) => {
                    setEndMonthVal(value)
                  }}
                  style={{ width: "100%", marginBottom: "5px" }}
                  size="small"
                />
              </div>
              <div className={classes.halfCont} >
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  value={endYearVal}
                  options={calendarYear}
                  getOptionLabel={(option) => { return option }}
                  getOptionSelected={(option) => {
                    return option === endYearVal
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ margin: "0px" }}
                      margin="normal"
                      variant="outlined"
                      placeholder={"Enter End Year"}
                    />
                  )}
                  onChange={(event, value) => {
                    setEndYearVal(value)
                  }}
                  style={{ width: "100%", marginBottom: "5px" }}
                  size="small"
                />
              </div>
            </div>
          </>)}
          <p className={classes.label} >Description</p>
          <TextField
            id="description"
            placeholder="Write anything notable..."
            multiline
            rows={3}
            variant="outlined"
            size="small"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            fullWidth
          />
          {validationObj?.description && (<p style={{ color: "#EE1D52" }} >{validationObj?.description}</p>)}
        </div>
        <div style={{ height: "5px", marginTop: "2px" }} >
          {loading && (<LinearProgress />)}
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "3px 7px" }} >
          <div></div>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            startIcon={<SaveIcon />}
            size={isSmall ? "small" : "medium"}
          >Save</Button>
        </div>
      </form>}
    />);
};
export default ExperienceDialog;