import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsApi } from "../../listing.api";
import LeadsTableComponent from "../tables/LeadsTableComponent";

const MyLeads = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [leads, setLeads] = useState([]);
  const [leadsLoading, setLeadsLoading] = useState(false);
  const [actions, setActions] = useState(["TODO"]);
  const handleActionClick = () => {};

  const getLeads = async () => {
    try {
      setLeadsLoading(true);
      const { data } = await getLeadsApi({
        ownerId: user?.profile,
      });
      setLeads(data);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch listing apps",
        },
      });
    } finally {
      setLeadsLoading(false);
    }
  };

  useEffect(() => {
    getLeads();
  }, []);

  return (
    <LeadsTableComponent
      leads={leads}
      loading={leadsLoading}
      actions={actions}
      handleActionClick={handleActionClick}
    />
  );
};

export default MyLeads;
