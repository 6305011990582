import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import FormBox from "../styled/generic/FormBox";
import TextField from "../styled/generic/TextField";
import Departments from "../hr/settings/administration/Departments";
import Designations from "../hr/settings/administration/Designations";
const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  section: {
    width: "95%",
    paddingTop: "2rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
});

const AdministartionEdit = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <Departments />
        <Designations />
      </div>
    </div>
  );
};

export default AdministartionEdit;
