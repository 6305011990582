import React from "react";
import MuiButton from "@mui/material/Button";


const Button = ({ children, sx, ...props }) => {
  return (
    <MuiButton
      sx={{ textTransform: "none", ...sx }}
      disableElevation
      {...props}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
