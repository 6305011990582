import React, { useEffect } from "react";
import StandardContainer from "../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Box,
  FormControl,
  FormControlLabel,
  Typography,
} from "@mui/material";
import moment from "moment";
import StandardAppContainerRoundedWithHeader from "../styled/generic/StandardAppContainerRoundedWithHeader";
import { CheckBox } from "@mui/icons-material";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import HorizBox from "../styled/generic/HorizBox";
import Button from "../styled/generic/Button";
import { useState } from "react";
import Links from "../activity/links/index";

const MyNotifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { activityDictionary, topNotificationIds: reducerTopNotificationIds } =
    useSelector((state) => state.activity);
  const topNotificationIds = Array.isArray(reducerTopNotificationIds)
    ? reducerTopNotificationIds
    : [];
  const [anchorEl, setAnchorEl] = React.useState(null);

  const notifications = topNotificationIds.map((id) => activityDictionary[id]);
  var newNotifications = [];
  notifications.map((n) => {
    if (n?.seen) {
    } else {
      newNotifications.push(n?._id);
    }
  });



  const [selectAll, setSelectAll] = useState(false);
  const [selectedNotificationIds, setSelectedNotificationIds] = useState([]);

  useEffect(() => {
    if (selectAll) {
      setSelectedNotificationIds(topNotificationIds);
    } else {
      setSelectedNotificationIds([]);
    }
  }, [selectAll]);

  useEffect(() => {
    if (selectedNotificationIds.length === topNotificationIds.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedNotificationIds]);


  const handleClick = (notification) => {
    const activity = notification?.activity;
    if(activity){
      const linkData = getActivityLinkData(activity);
      if (linkData && linkData.link) {
        history.push(linkData.link);
      }
    }

  };

  const getActivityLinkData = (activity) => {
    const activityType = activity.type;
    console.log(activityType);

    const navFunction = Links[activityType];
    console.log(navFunction);
    
    if (Links[activityType]) {
      return Links[activityType](activity);
    }

    // Default fallback if no specific link is found
    // return {
    //   name: 'Activity Details',
    //   link: `/activities/${activity._id}`,
    //   // Additional properties can be added here in the future
    // };
  };

  return (
    <StandardContainer appBarTitle="Notifications" showAppBar={true}>
      <StandardAppContainerRoundedWithHeader
        headerComponent={
          <FormControlLabel
            control={
              <CheckBox
                color="primary"
                value={selectAll}
                onChange={(e) => setSelectAll(e.target.value)}
              />
            }
            label="Select All"
          />
        }
        actions={
          <Button variant="outlined" color="primary">
            Delete
          </Button>
        }
      >
        <Box>
          {topNotificationIds && topNotificationIds.length > 0 ? (
            <>
              {topNotificationIds.map((actId, i) => {
                const notification = activityDictionary[actId];
                const activity = notification?.activity;
                const user = activity?.profile?.parent;

                const { displayName = "Test", displayPicture } = user || {};

                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#FFFFFF",
                      p: 1,
                      cursor: "pointer",
                      borderRadius: "0rem",
                      "&: hover": {
                        backgroundColor: "#F9FAFC",
                        borderRadius: "0.5rem",
                      },
                      position: "relative",
                      borderBottom: "1px solid rgba(0,0,0,0.1)",
                    }}
                    onClick={() => handleClick(notification)}
                  >
                    {notification?.seen == false && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "#2D76E0",
                        }}
                      ></Box>
                    )}
                    <BoxSpaceBetween
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <Box
                          sx={{
                            alignSelf: "flex-start",
                          }}
                        >
                          <Avatar
                            alt={displayName}
                            src={displayPicture?.thumbUrl}
                            sx={{
                              height: "2.5rem",
                              width: "2.5rem",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            flex: 1,
                            marginLeft: "0.8rem",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            {activity?.title}
                          </Typography>
                          {activity?.body && (
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: "light",
                              }}
                              color="text.secondary"
                            >
                              {activity?.body}
                            </Typography>
                          )}
                          <Typography variant="subtitle2" color="primary">
                            {moment(activity?.createdAt).fromNow()}
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <CheckBox
                          color="primary"
                          checked={selectedNotificationIds.includes(
                            notification?._id
                          )}
                          onChange={(e) => {
                            e.stopPropagation();
                            if (e.target.checked) {
                              setSelectedNotificationIds([
                                ...selectedNotificationIds,
                                notification?._id,
                              ]);
                            } else {
                              setSelectedNotificationIds(
                                selectedNotificationIds.filter(
                                  (id) => id !== notification?._id
                                )
                              );
                            }
                          }}
                        />
                      </Box>
                    </BoxSpaceBetween>
                  </Box>
                );
              })}
            </>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="300px"
            >
              <Typography variant="subtitle1">No notification found</Typography>
            </Box>
          )}
        </Box>
      </StandardAppContainerRoundedWithHeader>
    </StandardContainer>
  );
};

export default MyNotifications;
