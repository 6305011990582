import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import EstimationAnalyticsCardSvg from "../../../Assets/proj_mgmt_logo.svg";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import config from "../../../config/index";
import emptyIcon from "../../../Assets/vivekEmpty.svg";
import Skeleton from "@material-ui/lab/Skeleton";
import Api from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "100%",
    height: "370px",
    borderRadius: "10px",
    marginBottom: "20px",
    padding: "10px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 5px",
    },
  },
  cardTopHeading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > h3": {
      fontWeight: "510",
      fontSize: "17px",
      color: "#193B56",
    },
  },
  createBtn: {
    width: "25px",
    height: "25px",
    borderRadius: "6px",
    backgroundColor: "#c1def6",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#70b8f4",
      color: "white",
    },
  },
  topBox: {
    width: "100%",
    padding: "3px 10px",
    display: "flex",
    alignItems: "flex-start",
    marginTop: "10px",
    cursor: "pointer",
    marginBottom: "20px",
    "& > p": {
      fontSize: "15px",
      marginTop: "10px",
      color: "#696969",
    },
    "&:hover": {
      backgroundColor: "#edecec",
    },
  },
  topBoxSvgCont: {
    width: "120px",
    height: "70px",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
    "& > img": {
      width: "95px",
      height: "95px",
    },
  },
  container: {
    width: "100%",
    height: "215px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  timeSty: {
    fontSize: "10px",
    fontWeight: "500",
    color: "gray",
  },
  iconSty: {
    fontSize: "45px",
  },
  tbHeaderCell: {
    borderTop: "1px solid #e0e0e0",
    color: "#696969",
    fontSize: "15px",
    padding: "0px",
  },
  tbBodyCell: {
    border: "none",
    padding: "10px 5px",
  },
  statusCont: {
    fontSize: "11px",
    padding: "1px 3px",
    borderRadius: "3px",
    backgroundColor: "gray",
    color: "white",
  },
  avatarGroupSty: {
    maxWidth: "100px",
    "& .MuiAvatar-root": {
      width: "30px",
      height: "30px",
    },
  },
  skeletonCont: {
    paddingTop: "10px",
    width: "100%",
    height: "175px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  emptyCont: {
    display: "flex",
    width: "100%",
    height: "175px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    "& img": {
      height: "100px",
      width: "auto",
    },
    "& p": {
      fontSize: "12px",
      color: "gray",
      textAlign: "center",
      marginTop: "5px",
    },
  },
}));

const EstimationAnalyticsCard = ({ profile }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const filePickerRef = useRef();
  let { teamId } = useParams();
  const dispatch = useDispatch();

  const openFilePicker = () => {
    filePickerRef.current.click();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // const token = localStorage.getItem("token");
      // const loggedInFirebaseUId = localStorage.getItem("loggedInFirebaseUId");
      // const activeUserId = localStorage.getItem("activeUserId");
      // const activeUserProfileId = localStorage.getItem("activeUserProfileId");

      const formData = new FormData();
      formData.append("csvFile", file);
      formData.append("profileId", profile._id);

      const res = await axios.post(`${config.apiUrl}/wbs/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data) {
        history.push(`/project/${teamId}/${profile._id}`);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: `Error: ${res.error}`,
          },
        });
      }
    }
  };

  return (
    <Paper className={classes.cardContainer}>
      <div className={classes.cardTopHeading}>
        <h3>Estimation & Analytics</h3>
        <IconButton className={classes.createBtn} color="primary">
          +
        </IconButton>
      </div>
      <Paper
        elevation={1}
        className={classes.topBox}
        onClick={() => history.push(`/project/${teamId}/${profile._id}`)}
      >
        <div className={classes.topBoxSvgCont}>
          <img src={EstimationAnalyticsCardSvg} />
        </div>
        <p>Estimate your expenses and analyse your project</p>
      </Paper>
      <div className={classes.emptyCont}>
        <img src={emptyIcon} />
        <p>
          Convert mpp to csv and then upload here
          <br />
          <input
            type="file"
            accept=".csv"
            multiple={false}
            hidden
            ref={filePickerRef}
            onChange={handleFileUpload}
          />
          <span
            style={{
              color: theme.palette.primary.main,
              marginLeft: "3px",
              cursor: "pointer",
            }}
            onClick={() => {
              openFilePicker();
            }}
          >
            Click to upload csv file
          </span>
        </p>
      </div>
    </Paper>
  );
};
export default EstimationAnalyticsCard;
