import { Box } from "@mui/material";
import React, { useState } from "react";
import TabsWithSearch from "../../../../styled/generic/TabsWithSearch";
import MyApplications from "../../../common/tablecontainers/MyApplications";

const Applications = () => {
  const [applicationsTab, setApplicationsTab] = useState("All Applications");

  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "10px", p: 2, mt: 2 }}>
      <TabsWithSearch
        tabs={["All Applications"]}
        onTabChange={(newTabVal) => setApplicationsTab(newTabVal)}
        currentTabVariable={applicationsTab}
        disableUnderline={true}
      />
      {applicationsTab === "All Applications" && <MyApplications />}
    </Box>
  );
};

export default Applications;
