import { Box } from "@mui/material";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import TableContainer from "../../../styled/generic/TableContainer";
import StatusChip from "../../../styled/generic/StatusChip";
import ListingTableVisiblityCell from "../../../styled/generic/ListingTableVisiblityCell";
import ListingTableDateAgoCell from "../../../styled/generic/ListingTableDateAgoCell";
import ListingTableSharedPeopleCell from "../../../styled/generic/ListingTableSharedPeopleCell";

const useStyles = makeStyles((theme) => ({
  tableSty: {
    width: "100%",
    border: "none",
    backgroundColor: "#F5F7FA",
    borderCollapse: "collapse",
    marginTop: "10px",
    "& tr": {
      height: "60px",
      marginTop: "5px",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      borderBottom: "1px solid rgba(0,0,0,0.10)",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.005)",
      },
    },
    "& th": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "10px",
      backgroundColor: "#F5F7FA",
      textAlign: "left",
      fontSize: "0.85rem",
    },
    "& td": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "15px",
      textAlign: "left",
      verticalAlign: "center",
      fontSize: "0.9rem",
    },
  },
}));

const ListingsTableComponent = ({
  listings,
  loading,
  tableStyle,
  enableCheck = false,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const columns = [
    "LISTING TITLE",
    "LISTING TYPE",
    "PROJECT",
    "ORGANIZATION",
    "VISIBILITY",
    "POSTED DATE",
    "DUE DATE",
    "VIEWS",
    "STATUS",
    //"ACTION",
  ];

  return (
    <Box sx={{ backgroundColor: "#FFFFFF", minHeight: "400px" }}>
      <TableContainer
        columns={columns}
        data={listings}
        loading={loading}
        tableStyle={tableStyle}
        enableSelectAll={enableCheck}
      >
        {listings.map((item, index) => {
          console.log(item);
          return (
            <tr
              key={index}
              onClick={() => history.push(`/listing/${item?._id}`)}
            >
              {/* {enableCheck && (
              <td>
                <Checkbox onClick={(e) => e.stopPropagation()} />
              </td>
            )} */}
              <td>{item?.title}</td>
              <td>{item?.type}</td>
              <td>{item?.project ? item?.project?.displayName : " "}</td>
              <td>
                {item?.organization ? item?.organization?.displayName : ""}
              </td>
              <td>
                <ListingTableVisiblityCell isPrivate={item?.isPrivate} />
              </td>
              <td>
                <ListingTableDateAgoCell date={item?.createdAt} />
              </td>
              <td>
                <ListingTableDateAgoCell date={item?.dueDate} />
              </td>
              <td>
                <ListingTableSharedPeopleCell shared={item?.shared} />
              </td>
              <td>
                <StatusChip
                  label={item?.status}
                  variant={item?.status === "Incomplete" ? "Error" : "Success"}
                />
              </td>
              {/* <td onClick={(e) => e.stopPropagation()}>
              <ListingTableActionCell
                cellData={item}
                cellActions={actions}
                cellActionListener={handleActionClick}
              />
            </td> */}
            </tr>
          );
        })}
      </TableContainer>
    </Box>
  );
};

export default ListingsTableComponent;
