import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import EstimationAnalyticsCardSvg from "../../../Assets/proj_mgmt_logo.svg";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import emptyIcon from "../../../Assets/vivekEmpty.svg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { createBudgetCostApi } from "../../budgetCostAnalysis/api.call";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Api from "../../../helpers/Api";
import CreateOrgDialog from "../../organization/create.org.dialog";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "100%",
    height: "370px",
    borderRadius: "10px",
    marginBottom: "20px",
    padding: "10px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 5px",
    },
  },
  cardTopHeading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > h3": {
      fontWeight: "510",
      fontSize: "17px",
      color: "#193B56",
    },
  },
  createBtn: {
    width: "25px",
    height: "25px",
    borderRadius: "6px",
    backgroundColor: "#c1def6",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#70b8f4",
      color: "white",
    },
  },
  topBox: {
    width: "100%",
    padding: "3px 10px",
    display: "flex",
    alignItems: "flex-start",
    marginTop: "10px",
    cursor: "pointer",
    marginBottom: "20px",
    "& > p": {
      fontSize: "15px",
      marginTop: "10px",
      color: "#696969",
    },
    "&:hover": {
      backgroundColor: "#edecec",
    },
  },
  topBoxSvgCont: {
    width: "120px",
    height: "70px",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
    "& > img": {
      width: "95px",
      height: "95px",
    },
  },
  emptyCont: {
    display: "flex",
    width: "100%",
    height: "175px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    "& img": {
      height: "100px",
      width: "auto",
    },
    "& p": {
      fontSize: "12px",
      color: "gray",
      textAlign: "center",
      marginTop: "5px",
    },
  },
}));

const InventoryCard = ({ queryId, queryType }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const dispatch = useDispatch();

  return (
    <Paper className={classes.cardContainer}>
      <div className={classes.cardTopHeading}>
        <h3>Inventory</h3>
        <IconButton className={classes.createBtn} color="primary">
          +
        </IconButton>
      </div>
      <Paper
        elevation={1}
        className={classes.topBox}
        onClick={() => {
          if (queryType === "project") {
            history.push(`/inventory?projectId=${queryId}`);
          } else if (queryType === "organization") {
            history.push(`/inventory?organizationId=${queryId}`);
          } else {
            history.push(`/procurements`);
          }
        }}
      >
        <div className={classes.topBoxSvgCont}>
          <img src={EstimationAnalyticsCardSvg} />
        </div>
        <p>Manage your inventory</p>
      </Paper>
      <div className={classes.emptyCont}>
        <img src={emptyIcon} />
        <p>
          There is nothing to show here.
          <br />
          <span
            style={{
              color: theme.palette.primary.main,
              marginLeft: "3px",
              cursor: "pointer",
            }}
          >
            Click here to configure
          </span>
        </p>
      </div>
    </Paper>
  );
};

export default InventoryCard;
