import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import FormBox from "../styled/generic/FormBox";
import TextField from "../styled/generic/TextField";
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Drawer,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Api from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditLocationDrawer from "./EditLocationDrawer";
const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto",
    paddingBottom: "2rem",
  },
  header: {
    width: "100%",
    height: "4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    paddingRight: "1rem",
    borderBottom: "1px solid #e4e7e7",
  },
  saveBtn: {
    width: "6rem",
    height: "2.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2d76e0",
    color: "white",
    borderRadius: "0.4rem",
    fontSize: "1.1rem",
  },
  inputCont: {
    paddingTop: "2rem",
    width: "95%",
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "1rem",
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  locationsCardCont: {
    paddingTop: "2rem",
    width: "95%",

    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },

  container1: {
    width: "50vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header1: {
    width: "100%",
    height: "4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    paddingRight: "1rem",
    borderBottom: "1px solid #e4e7e7",
  },

  inputCont1: {
    paddingTop: "2rem",
    width: "95%",
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "1rem",
  },
});

const LocationsEdit = ({ organization, selectView }) => {
  const classes = useStyles();
  const [country, setCountry] = useState("");
  const { user } = useSelector((state) => state.auth);

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [lodaing, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [locationName, setLocationName] = useState("");
  const [landmark, setLandmark] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");

  const [locations, setLocations] = useState([]);
  const [addAddres, setAddress] = useState(false);
  const [editAddress, setEditaddress] = useState({});
  const [locationEditId, setLocationEditId] = useState();
  const [openEditDr, setOpenEditDr] = useState(false);
  const [createAddressLoad, setCreateAddressLoad] = useState(false);
  const [updateAddressLoad, setUpdateAddressLoad] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState(null);

  const dispatch = useDispatch();

  const getLocations = async () => {
    try {
      const { data } = await Api.post(`/location/get`, {
        organization: organization?._id,
      });
      if (data) {
        setLocations(data);
        const defaultLoc = data.find(loc => loc.isDefault);
        setDefaultLocation(defaultLoc ? defaultLoc._id : null);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch locations",
        },
      });
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  const handleSetEditaddress = (address) => {
    setState(address?.location?.state);
    setLocationEditId(address?._id);
    setCity(address?.location?.city);
    setZip(address?.location?.postcode);
    setCountryCode(address?.location?.country_code);
    setCountry(address?.location?.country);
    setStateCode(address?.location?.state_code);
    setLocationName(address?.location?.name);
    setLandmark(address?.location?.name);
    setAddress1(address?.location?.address_line1);
    setAddress2(address?.location?.address_line2);
    setOpenEditDr(true);
  };
  const handleClearEdit = () => {
    setState("");
    setCity("");
    setZip("");
    setCountryCode("");
    setStateCode("");
    setCountry("");
    setLocationName("");
    setLandmark("");
    setAddress1("");
    setAddress2("");
    setLocationEditId("");
    setOpenEditDr(false);
  };

  const handleSetAsDefault = async (locationId) => {
    try {
      const { data } = await Api.post("/location/update", {
        _id: locationId,
        updateBody: { isDefault: true },
        organization: organization?._id
      });
      if (data) {
        setDefaultLocation(locationId);
        // Update the locations array to reflect the change
        setLocations(prevLocations => 
          prevLocations.map(loc => ({
            ...loc,
            isDefault: loc._id === locationId
          }))
        );
      }
    } catch (error) {
      console.error("Error setting default location:", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to set default location",
        },
      });
    }
  };

  console.log(locations);

  const createLocation = async () => {
    try {
      setCreateAddressLoad(true);
      const { data } = await Api.post("/location/create", {
        name: locationName,
        coverImage: "",
        location: {
          address_line1: address1,
          address_line2: address2,
          name: landmark,
          city: city,
          country: country,
          country_code: countryCode,
          state_code: stateCode,
          postcode: zip,
          state: state,
        },
        isDefault: locations.length === 0, // Set as default if it's the first location
        organization: organization?._id,
        author: user?._id,
      });

      
    if (data?._id) {
      setLocations(prevLocations => [...prevLocations, data]);
      if (data.isDefault) {
        setDefaultLocation(data._id);
      }
    }
    } catch (error) {
    } finally {
      setCreateAddressLoad(false);
    }
  };

  const updateLocation = async () => {
    try {
      const obj = {
        name: locationName,
        coverImage: "",
        location: {
          address_line1: address1,
          address_line2: address2,
          name: landmark,
          city: city,
          country: country,
          country_code: countryCode,
          state_code: stateCode,
          postcode: zip,
          state: state,
        },
        isDefault: defaultLocation === locationEditId,
        organization: organization?._id,
        author: user?._id,
      };
      setUpdateAddressLoad(true);
      const { data } = await Api.post("/location/update", {
        _id: locationEditId,
        updateBody: obj,
      });

      // const index = locations?.findIndex(({ _id }) => _id === data?._id);

      // const newLocations = [...locations];
      // newLocations.splice(index, 1, { ...data });
      // setLocations(newLocations);

      // handleClearEdit();


    setLocations(prevLocations => 
      prevLocations.map(loc => 
        loc._id === data._id ? data : loc
      )
    );

    handleClearEdit();
    } catch (error) {
      console.log(error);
    } finally {
      setUpdateAddressLoad(false);
    }
  };

  const deleteLocation = async (id) => {
    try {
      const { data } = await Api.post("/location/delete", { _id: id });

      const index = locations?.findIndex(({ _id }) => _id === data?._id);

      const newLocations = [...locations];
      newLocations.splice(index, 1);
      setLocations(newLocations);
    } catch (error) {}
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span style={{ fontSize: "1.4rem" }}>{selectView}</span>
        <div className={classes.headerRight}>
          {!openEditDr && (
            <div
              onClick={() => setAddress(true)}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.4rem",
                padding: "0.5rem 1rem",
                borderRadius: "0.4rem",
                backgroundColor: "#2d76e0",
                fontSize: "1.1rem",
                cursor: "pointer",
              }}
            >
              <span>
                <AddIcon style={{ color: "white" }} size={24} />{" "}
              </span>

              <span style={{ color: "white" }}>Add location</span>
            </div>
          )}
          {/* <span className={classes.saveBtn}>
            {lodaing ? (
              <CircularProgress style={{ color: "white" }} size={24} />
            ) : (
              <span onClick={() => saveEdit()} style={{ cursor: "pointer" }}>
                Save
              </span>
            )}
          </span> */}
        </div>
      </div>

      {addAddres && !openEditDr && (
        <>
          <div className={classes.inputCont}>
            <FormBox label="Location Name">
              <TextField
                value={locationName}
                onChange={(e) => setLocationName(e.target.value)}
                placeholder="Enter Location Name"
                fullWidth={true}
              />
            </FormBox>
            <FormBox label="Country">
              <TextField
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                placeholder="Enter Country"
                fullWidth={true}
              />
            </FormBox>
            <FormBox label="Country Code">
              <TextField
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                placeholder="Enter Country"
                fullWidth={true}
              />
            </FormBox>

            <FormBox label="State">
              <TextField
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="Enter Country Code"
                style={{ width: "100%" }}
              />
            </FormBox>
            <FormBox label="State Code">
              <TextField
                value={stateCode}
                onChange={(e) => setStateCode(e.target.value)}
                placeholder="Enter State Code"
                style={{ width: "100%" }}
              />
            </FormBox>

            <FormBox label="City">
              <TextField
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Enter City"
                style={{ width: "100%" }}
              />
            </FormBox>
            <FormBox label="Address 1">
              <TextField
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
                placeholder="Enter Address 1"
                style={{ width: "100%" }}
              />
            </FormBox>
            <FormBox label="Address 2">
              <TextField
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                placeholder="Enter Address 2"
                style={{ width: "100%" }}
              />
            </FormBox>
            <FormBox label="Landmark">
              <TextField
                value={landmark}
                onChange={(e) => setLandmark(e.target.value)}
                placeholder="Enter Landmark"
                style={{ width: "100%" }}
              />
            </FormBox>
            <FormBox label="Zip">
              <TextField
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                placeholder="Enter Zip"
                style={{ width: "100%" }}
              />
            </FormBox>
          </div>
          <div
            style={{
              width: "95%",
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <span
              onClick={() => createLocation()}
              style={{
                height: "2.5rem",
                width: "7rem",
                borderRadius: "0.2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                cursor: "pointer",
                background: "#2d76e0",
              }}
            >
              {createAddressLoad ? (
                <CircularProgress style={{ color: "white" }} size={24} />
              ) : (
                "Create"
              )}
            </span>
            <span
              onClick={() => {
                handleClearEdit();
                setAddress(false);
              }}
              style={{
                height: "2.5rem",
                width: "7rem",
                borderRadius: "0.2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#2d76e0",
                border: " 1px solid #2d76e0",
              }}
            >
              Cancel
            </span>
          </div>
        </>
      )}
      {openEditDr && (
        <>
          <div className={classes.inputCont}>
            <FormBox label="Location Name">
              <TextField
                value={locationName}
                onChange={(e) => setLocationName(e.target.value)}
                placeholder="Enter Location Name"
                fullWidth={true}
              />
            </FormBox>
            <FormBox label="Country">
              <TextField
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                placeholder="Enter Country"
                fullWidth={true}
              />
            </FormBox>
            <FormBox label="Country Code">
              <TextField
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                placeholder="Enter Country"
                fullWidth={true}
              />
            </FormBox>

            <FormBox label="State">
              <TextField
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="Enter Country Code"
                style={{ width: "100%" }}
              />
            </FormBox>
            <FormBox label="State Code">
              <TextField
                value={stateCode}
                onChange={(e) => setStateCode(e.target.value)}
                placeholder="Enter State Code"
                style={{ width: "100%" }}
              />
            </FormBox>

            <FormBox label="City">
              <TextField
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Enter City"
                style={{ width: "100%" }}
              />
            </FormBox>
            <FormBox label="Address 1">
              <TextField
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
                placeholder="Enter Address 1"
                style={{ width: "100%" }}
              />
            </FormBox>
            <FormBox label="Address 2">
              <TextField
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                placeholder="Enter Address 2"
                style={{ width: "100%" }}
              />
            </FormBox>
            <FormBox label="Landmark">
              <TextField
                value={landmark}
                onChange={(e) => setLandmark(e.target.value)}
                placeholder="Enter Landmark"
                style={{ width: "100%" }}
              />
            </FormBox>
            <FormBox label="Zip">
              <TextField
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                placeholder="Enter Zip"
                style={{ width: "100%" }}
              />
            </FormBox>
          </div>
          <div
            style={{
              width: "95%",
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <span
              onClick={() => updateLocation()}
              style={{
                height: "2.5rem",
                width: "7rem",
                borderRadius: "0.2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                cursor: "pointer",
                background: "#2d76e0",
              }}
            >
              {updateAddressLoad ? (
                <CircularProgress style={{ color: "white" }} size={24} />
              ) : (
                "Update"
              )}
            </span>
            <span
              onClick={() => {
                handleClearEdit();
                setAddress(false);
              }}
              style={{
                height: "2.5rem",
                width: "7rem",
                borderRadius: "0.2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#2d76e0",
                border: " 1px solid #2d76e0",
              }}
            >
              Cancel
            </span>
          </div>
        </>
      )}

      <div className={classes.locationsCardCont}>
        {locations?.map((location) => (
          <Card key={location?._id} sx={{ width: "23rem", height: "10rem" }}>
            <CardContent
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "0.5rem",
              }}
            >
            
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}
              >
                <span>Country : </span>
                <span style={{ fontSize: "1." }}>
                  {location?.location?.country}
                </span>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}
              >
                <span>State: </span>
                <span style={{ fontSize: "1." }}>
                  {location?.location?.state}
                </span>
              </div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}
              >
                <span>City: </span>
                <span style={{ fontSize: "1." }}>
                  {location?.location?.city}
                </span>
              </div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}
              >
                <span>Zip: </span>
                <span style={{ fontSize: "1." }}>
                  {location?.location?.postcode}
                </span>
              </div>

              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  top: "0.4rem",
                  right: "0.4rem",
                  gap: "0.5rem",
                }}
              >
                  {defaultLocation !== location?._id ? (
        <span
          style={{ cursor: "pointer", fontSize: "0.8rem" }}
          onClick={() => handleSetAsDefault(location?._id)}
        >
          Set as Default
        </span>
      ) : (
        <span
          style={{
            backgroundColor: "#B9D9EB",
            padding: "0.2rem 0.4rem",
            borderRadius: "0.2rem",
            fontSize: "0.8rem",
          }}
        >
          Default
        </span>
      )}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteLocation(location?._id)}
                >
                  <DeleteOutlineIcon />{" "}
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleSetEditaddress(location);
                  }}
                >
                  <ModeEditOutlineIcon />
                </span>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default LocationsEdit;
