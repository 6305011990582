import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import EstimationAnalyticsCardSvg from "../../../Assets/proj_mgmt_logo.svg";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import emptyIcon from "../../../Assets/vivekEmpty.svg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { createBudgetCostApi } from "../../budgetCostAnalysis/api.call";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Api from "../../../helpers/Api";
import CreateOrgDialog from "../../organization/create.org.dialog";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "100%",
    height: "370px",
    borderRadius: "10px",
    marginBottom: "20px",
    padding: "10px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 5px",
    },
  },
  cardTopHeading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > h3": {
      fontWeight: "510",
      fontSize: "17px",
      color: "#193B56",
    },
  },
  createBtn: {
    width: "25px",
    height: "25px",
    borderRadius: "6px",
    backgroundColor: "#c1def6",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#70b8f4",
      color: "white",
    },
  },
  topBox: {
    width: "100%",
    padding: "3px 10px",
    display: "flex",
    alignItems: "flex-start",
    marginTop: "10px",
    cursor: "pointer",
    marginBottom: "20px",
    "& > p": {
      fontSize: "15px",
      marginTop: "10px",
      color: "#696969",
    },
    "&:hover": {
      backgroundColor: "#edecec",
    },
  },
  topBoxSvgCont: {
    width: "120px",
    height: "70px",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
    "& > img": {
      width: "95px",
      height: "95px",
    },
  },
  emptyCont: {
    display: "flex",
    width: "100%",
    height: "175px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    "& img": {
      height: "100px",
      width: "auto",
    },
    "& p": {
      fontSize: "12px",
      color: "gray",
      textAlign: "center",
      marginTop: "5px",
    },
  },
}));

const SiteManagementCard = ({ profile, projectData }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const [showSiteManagementModal, setShowSiteManagementModal] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [orgDialogOpen, setOrgDialogOpen] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const [organizations, setOrganizations] = useState([]);
  const dispatch = useDispatch();
  const getOrganizations = async () => {
    try {
      const { data } = await Api.post("/team/getOrgTeams", {
        profile: user?.profile,
      });
      setOrganizations(data);
    } catch (err) {
      console.log(err);
    }
  };

  const configureSiteManagement = async () => {
    if (!selectedOrganization || selectedOrganization === "") {
      return;
    }
    try {

      const { data } = await Api.post("/appModule/sitemanagement", {
        projectId: projectData?._id,
        organizationId: selectedOrganization?._id,
        userProfileId: user?.profile,
      });
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Site management configured successfully",
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  return (
    <>
      <Paper className={classes.cardContainer}>
        <div className={classes.cardTopHeading}>
          <h3>Site Management</h3>
          <IconButton className={classes.createBtn} color="primary">
            +
          </IconButton>
        </div>
        <Paper
          elevation={1}
          className={classes.topBox}
          onClick={() =>
            history.push(`/project/sitemanagement/${projectData?._id}`)
          }
        >
          <div className={classes.topBoxSvgCont}>
            <img src={EstimationAnalyticsCardSvg} />
          </div>
          <p>Manage your site</p>
        </Paper>
        <div className={classes.emptyCont}>
          <img src={emptyIcon} />
          <p>
            There is nothing to show here.
            <br />
            <span
              style={{
                color: theme.palette.primary.main,
                marginLeft: "3px",
                cursor: "pointer",
              }}
              onClick={() => setShowSiteManagementModal(true)}
            >
              Click here to configure
            </span>
          </p>
        </div>
      </Paper>
      <Modal
        open={showSiteManagementModal}
        onClose={() => setShowSiteManagementModal(false)}
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Configure Site
          </Typography>
          <Typography style={{ marginTop: "15px" }}>
            Select Organization:
          </Typography>
          <FormControl
            sx={{ m: 1, minWidth: 120 }}
            variant="outlined"
            fullWidth
          >
            <Select
              value={selectedOrganization}
              onChange={(e, v) => setSelectedOrganization(e.target.value)}
              inputProps={{ "aria-label": "Without label" }}
            >
              {organizations.map((item, i) => (
                <MenuItem key={i} value={item?.parent}>
                  {item?.parent?.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ marginTop: "15px" }}
          >
            {organizations.length !== 0 && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setShowSiteManagementModal(false);
                  setOrgDialogOpen(true);
                }}
                style={{ marginRight: "15px" }}
              >
                Create Organization
              </Button>
            )}
            <Button
              color="primary"
              variant="contained"
              onClick={() => configureSiteManagement()}
              disabled={organizations.length === 0}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <CreateOrgDialog
        open={orgDialogOpen}
        setOpen={setOrgDialogOpen}
        onCreate={() => {
          setOrgDialogOpen(false);
        }}
      />
    </>
  );
};

export default SiteManagementCard;
