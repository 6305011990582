import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Avatar, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    default: {

    },
    optionCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& h3": {
            width: "100%",
            fontSize: "14px",
            fontWeight: "500",
        },
        "& p": {
            width: "100%",
            fontSize: "10px",
            fontWeight: "400",
        }
    },
    optionContNonWidth: {
        border: "1px solid #c9c2c2",
        display: "flex",
        height: "40px",
        borderRadius: "5px",
        padding: "0px 5px",
        margin: "3px",
        marginRight: "5px",
        backgroundColor: "#ececec",
        alignItems: "center",
        justifyContent: "space-between",
        "& h5": {
            fontSize: "14px",
            fontWeight: "500",
        },
        "& h6": {
            fontSize: "10px",
            fontWeight: "400",
        }
    },
    noBorder: {
        border: "none"
    },
}));

export default function AutocompleteWithAvatar({
    value, onChange, style = null, className = null, disableClearable = false, placeholder,
    fullWidth = false, label = null, size = "small", isNoBorder = false, options = [], variant,
    freeSolo = true, selectOnFocus = false, clearOnBlur = false, handleHomeEndKeys = false,
    disabled
}) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    console.log(options)

    return (
        <Autocomplete
            id="free-solo-demo"
            multiple={true}
            selectOnFocus={selectOnFocus}
            freeSolo={freeSolo}
            clearOnBlur={clearOnBlur}
            handleHomeEndKeys={handleHomeEndKeys}
            disabled={disabled}
            fullWidth={fullWidth}
            options={options}
            disableClearable={disableClearable}
            value={value}
            onChange={onChange}
            className={className ? className : classes.default}
            style={style ? style : {}}
            getOptionLabel={(option) => option?.parent?.displayName}
            getOptionSelected={(option) => option?._id == value?._id}
            renderTags={(value, getTagProps) => {
                let filteredRes = value.filter(s => s?._id != null || s?._id != undefined)
                if (filteredRes && filteredRes.length > 0) {
                    return filteredRes.map((option, index) => (
                        <li className={classes.optionContNonWidth} >
                            <Avatar
                                style={{ width: "25px", height: "25px" }}
                                src={option?.parent?.displayPicture?.thumbUrl ? option?.parent?.displayPicture?.thumbUrl : option?.parent?.displayPicture?.url}
                                alt={option?.parent?.displayName}
                            />
                            <div style={{ width: "100%", paddingLeft: "10px" }} >
                                <h5>{option?.parent?.displayName}</h5>
                                <h6>{option?.parent?.username}</h6>
                            </div>
                        </li>
                    ))
                }
            }}
            renderOption={(option, state) => (
                <li className={classes.optionCont} >
                    <Avatar
                        style={{ width: "25px", height: "25px" }}
                        src={option?.parent?.displayPicture?.thumbUrl ? option?.parent?.displayPicture?.thumbUrl : option?.parent?.displayPicture?.url}
                        alt={option?.parent?.displayName}
                    />
                    <div style={{ width: "100%", paddingLeft: "10px" }} >
                        <h3>{option?.parent?.displayName}</h3>
                        <p>{option?.parent?.username}</p>
                    </div>
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={variant}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        classes: isNoBorder ? { notchedOutline: classes.noBorder } : {},
                    }}
                    placeholder={placeholder}
                    size={size}
                />
            )}
        />
    );
}