import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { IconButton, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import { SlPencil } from "react-icons/sl";
import { useMediaQuery } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Carousel from "react-multi-carousel";
import { GiGearHammer } from "react-icons/gi";
import moment from "moment";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LessText from "../styled/CommonComponents/LessText";
import ProjectDialog from "./Dialogs/ProjectDialog";
import CertificatLicenseDialog from "./Dialogs/CertificatLicenseDialog";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { IoOpenOutline } from "react-icons/io5";
import "react-multi-carousel/lib/styles.css";
import "../profile/sections/crad.css"
import AwardHonorsDialog from "./Dialogs/AwardHonors.Dialog";
import { GiTrophyCup } from "react-icons/gi";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        padding: "25px",
        marginBottom: "20px",
        "& h2": {
            fontSize: "15px",
            marginTop: "15px"
        },
        "& h3": {
            fontSize: "22px",
            fontWeight: "700",
            marginBottom: "20px"
        },
        "& h4": {
            fontSize: "18px",
            fontWeight: "510"
        },
        "& h5": {
            fontSize: "15px",
            fontWeight: "500"
        },
        "& h6": {
            fontSize: "13px",
            fontWeight: "400",
            color: "gray"
        },
        "& p": {
            fontSize: "15px"
        },
    },
    allProjectsCont: {
        width: "100%",
        padding: "25px",
        marginBottom: "20px"
    },
    headerSty: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "20px",
        "& h3": {
            fontSize: "22px",
            fontWeight: "700"
        }
    },
    allExpEditLicense: {
        padding: "0px 30px",
        width: "80%",
        [theme.breakpoints.down("md")]: {
            width: "90%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "95%",
            padding: "0px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    allExpEditProject: {
        padding: "0px 30px",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            padding: "0px",
        },
    },
    singleExperienceCont: {
        display: "flex",
        alignItems: "flex-start",
        padding: "25px 5px"
    },
    imageCont: {
        marginRight: "15px"
    },
    experienceRight: {
        width: "calc(100% - 60px)",
        marginTop: "-4px",
        "& h3": {
            fontSize: "20px",
            fontWeight: "500"
        },
        "& h4": {
            fontSize: "16px",
            fontWeight: "450",
            display: "flex",
            alignItems: "center",
        },
        "& h5": {
            fontSize: "14px",
            fontWeight: "350",
            display: "flex",
            alignItems: "center",
        },
        [theme.breakpoints.down("xs")]: {
            "& h5": {
                flexDirection: "column",
                alignItems: "flex-start"
            }
        }
    },
    tagCont: {
        fontSize: "14px",
        color: "gray"
    },
    credentialLink: {
        width: "175px",
        border: "1px solid gray",
        padding: "2px 0px",
        fontSize: "16px",
        fontWeight: "500",
        color: "gray",
        marginTop: "10px",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    }
}));

const colorArr = [
    "#F39014",
    "#0A66C2",
    "#36e055"
]

const OrgAbout = ({ profile, teamId, setOpenEditView, setProfileEditView }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();

    const [orgData, setOrgData] = useState(null)
    const [openProjectDialog, setOpenProjectDialog] = useState(false)
    const [openCertificatLicenseDialog, setOpenCertificatLicenseDialog] = useState(false)
    const [openAwardHonorDialog, setOpenAwardHonorDialog] = useState(false)

    const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

    useEffect(() => {
        setOrgData(profile?.parent)
    }, [profile, profile?.parent])

    const duration = (startMonth, startYear, isCurrentlyWorking, endMonth, endYeark) => {
        const calendarMonth = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const d = new Date();
        let endYear = d.getFullYear();
        let endMonthNum = d.getMonth() + 1

        if (!isCurrentlyWorking && endMonth && endYeark) {
            endMonthNum = calendarMonth.indexOf(endMonth) + 1
            endYear = endYeark
        }

        const startMonthNum = calendarMonth.indexOf(startMonth) + 1

        let sDateStr = `1.${startMonthNum}.${startYear}`
        let eDateStr = `1.${endMonthNum}.${endYear}`

        var startDate = moment(sDateStr, "DD.MM.YYYY");
        var endDate = moment(eDateStr, "DD.MM.YYYY");

        var result = endDate.diff(startDate, 'months')

        if (result > 12) {
            return `${parseInt(result / 12)} yr, ${result % 12} mos`
        } else {
            return `${result} mos`
        }
    }

    const responsive = {
        desktop1: {
            breakpoint: { max: 3000, min: 2000 },
            items: 5
        },
        desktop2: {
            breakpoint: { max: 1999, min: 1500 },
            items: 4.3
        },
        tablet1: {
            breakpoint: { max: 1499, min: 1300 },
            items: 3.3
        },
        tablet2: {
            breakpoint: { max: 1299, min: 1100 },
            items: 2.6
        },
        mobile1: {
            breakpoint: { max: 1099, min: 800 },
            items: 2.1
        },
        mobile2: {
            breakpoint: { max: 799, min: 600 },
            items: 1.5
        },
        mobile3: {
            breakpoint: { max: 599, min: 400 },
            items: 1.2
        },
        mobile4: {
            breakpoint: { max: 399, min: 0 },
            items: 1
        },
    };


    return (
        <div>
            {orgData?.displayName || orgData?.username || orgData?.tagline || orgData?.description || orgData?.website || orgData?.email || orgData?.phone || orgData?.address?.line1 || orgData?.foundingYear || orgData?.companySize ? (
                <Paper elevation={2} className={classes.root}>
                    <div className={classes.headerSty} >
                        <h3>Basic Information</h3>

                        <IconButton
                            onClick={() => {
                                setProfileEditView("basicInfo")
                                setOpenEditView(true)
                            }}
                        >
                            <SlPencil />
                        </IconButton>
                    </div>
                    {orgData?.displayName && (<h4>{orgData?.displayName}</h4>)}
                    {orgData?.username && (<h6>{orgData?.username}</h6>)}
                    {orgData?.tagline && (<h5>{orgData?.tagline}</h5>)}


                    {orgData?.description && (<>
                        <h2>About</h2>
                        <p>{orgData?.description}</p>
                    </>)}

                    {orgData?.website && (<>
                        <h2>Website Link</h2>
                        <p>{orgData?.website}</p>
                    </>)}

                    {orgData?.email && (<>
                        <h2>Mail Id</h2>
                        <p>{orgData?.email}</p>
                    </>)}

                    {orgData?.phone && (<>
                        <h2>Phone No.</h2>
                        <p>{orgData?.phone}</p>
                    </>)}

                    {orgData?.address?.line1 && (<>
                        <h2>Headquarter Location</h2>
                        <p>{orgData?.address?.line1}</p>
                    </>)}

                    {orgData?.foundingYear && (<>
                        <h2>Year Of Establishment</h2>
                        <p>{orgData?.foundingYear}</p>
                    </>)}

                    {orgData?.companySize && (<>
                        <h2>Company Size</h2>
                        <p>{orgData?.companySize}</p>
                    </>)}
                </Paper>
            ) : null}

            {orgData?.projectExp && orgData?.projectExp?.length !== 0 && (
                <Paper elevation={2} className={classes.allProjectsCont} >
                    <div className={classes.headerSty} >
                        <div>
                            <h3>Projects</h3>
                            <p>{`Total - ${orgData?.numberOfProjects} / Active - ${orgData?.numberOfActiveProjects}`}</p>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }} >
                            <IconButton
                                onClick={() => {
                                    setProfileEditView("project")
                                    setOpenEditView(true)
                                }}
                            >
                                <SlPencil />
                            </IconButton>
                            <IconButton
                                style={{ marginLeft: "10px" }}
                                onClick={() => { setOpenProjectDialog(true) }}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className={classes.allExpEditProject} >
                        {orgData?.projectExp.map((project, index) => (
                            <div
                                className={classes.singleExperienceCont}
                                key={index}
                                style={index !== 0 ? { borderTop: "1px solid #e4e7e7" } : {}}
                            >
                                <Avatar
                                    variant="square"
                                    className={classes.imageCont}
                                    style={{ backgroundColor: colorArr[index % 3] }}
                                >
                                    <GiGearHammer />
                                </Avatar>
                                <div className={classes.experienceRight}>
                                    <h3>{project?.project?.displayName}</h3>
                                    <h5>{`lot ${project?.project?.lotSize} / area ${project?.project?.area} / year ${project?.project?.year} / price ₹${project?.project?.price}`}</h5>
                                    <h4>{project?.role}</h4>
                                    <h5>
                                        <div>{`${project?.startMonth}, ${project?.startYear}  -  ${project?.isCurrentlyWorking ? "Present" : `${project?.endMonth}, ${project?.endYear}`}`}</div>
                                        {isSmall ? null : (<FiberManualRecordIcon style={{ width: "5px", height: "5px", margin: "0px 4px" }} />)}
                                        <div>{duration(project?.startMonth, project?.startYear, project?.isCurrentlyWorking, project?.endMonth, project?.endYear)}</div>
                                    </h5>
                                    <h5>{project?.project?.address?.streetAddress}</h5>
                                    <p>{project?.desc}</p>
                                    {project?.project?.docs && project?.project?.docs.length > 0 && (
                                        <div style={{ width: "100%", marginTop: "15px" }} >
                                            <Carousel
                                                responsive={responsive}
                                                infinite={true}
                                                swipeable={false}
                                                draggable={false}
                                                keyBoardControl={true}
                                                showDots={false}
                                                autoPlaySpeed={1000}
                                                dotListClass="custom-dot-list-style"
                                                itemClass="carousel-item-padding-40-px"
                                                containerClass="carousel-container"
                                            >
                                                {project?.project?.docs.map((file, index) => (
                                                    <div key={index} style={{ padding: "10px", paddingLeft: "15px" }} >
                                                        <div className="photoCard">
                                                            <div
                                                                className="thumb"
                                                                style={{ backgroundImage: `url(${file?.files[0]?.url})` }}
                                                            ></div>
                                                            <div className="infos">
                                                                <h2 className="title">{file?.title}</h2>
                                                                <div className={classes.tagCont}>{file?.tag}</div>
                                                                <p className="txt" style={{ width: "100%", wordBreak: "break-all" }}>
                                                                    <LessText
                                                                        limit={350}
                                                                        string={file?.description}
                                                                    />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Carousel>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </Paper>
            )}

            {orgData?.licenses && orgData?.licenses?.length !== 0 && (
                <Paper elevation={2} className={classes.allProjectsCont}>
                    <div className={classes.headerSty} >
                        <h3>Licenses</h3>
                        <div style={{ display: "flex", alignItems: "center" }} >
                            <IconButton
                                onClick={() => {
                                    setProfileEditView("certificat")
                                    setOpenEditView(true)
                                }}
                            >
                                <SlPencil />
                            </IconButton>

                            <IconButton
                                style={{ marginLeft: "10px" }}
                                onClick={() => { setOpenCertificatLicenseDialog(true) }}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                    </div>

                    <div className={classes.allExpEditLicense} >
                        {orgData.licenses.map((license, index) => (<>
                            <div
                                className={classes.singleExperienceCont}
                                key={index}
                                style={index !== 0 ? { borderTop: "1px solid #e4e7e7" } : {}}
                            >
                                <Avatar
                                    variant="square"
                                    className={classes.imageCont}
                                    style={{ backgroundColor: colorArr[index % 3] }}
                                >
                                    <VerifiedUserIcon fontSize="large" />
                                </Avatar>
                                <div className={classes.experienceRight}>
                                    <h3>{license?.title}</h3>
                                    <h4>{license?.issuer}</h4>
                                    <h5>
                                        <div>{`Issued ${license?.start_date_honor}`}</div>
                                        {isSmall ? null : (<FiberManualRecordIcon style={{ width: "5px", height: "5px", margin: "0px 4px" }} />)}
                                        <div>{`Expires ${license?.end_date}`}</div>
                                    </h5>
                                    <h5>{license?.credentialId ? `Credential ID: ${license?.credentialId}` : null}</h5>
                                    {license?.license_url && (
                                        <div
                                            className={classes.credentialLink}
                                            onClick={() => { window.open(license?.license_url, '_blank').focus(); }}
                                        > Show credential <IoOpenOutline /> </div>
                                    )}
                                </div>
                            </div>
                        </>))}
                    </div>
                </Paper>
            )}

            {orgData?.honors && orgData?.honors?.length !== 0 && (
                <Paper elevation={2} className={classes.allProjectsCont}>
                    <div className={classes.headerSty} >
                        <h3>Awards & Honors</h3>
                        <div style={{ display: "flex", alignItems: "center" }} >
                            <IconButton
                                onClick={() => {
                                    setProfileEditView("award")
                                    setOpenEditView(true)
                                }}
                            >
                                <SlPencil />
                            </IconButton>

                            <IconButton
                                style={{ marginLeft: "10px" }}
                                onClick={() => { setOpenAwardHonorDialog(true) }}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className={classes.allExpEditLicense} >
                        {orgData.honors.map((honor, index) => (<>
                            <div
                                className={classes.singleExperienceCont}
                                key={index}
                                style={index !== 0 ? { borderTop: "1px solid #e4e7e7" } : {}}
                            >
                                <Avatar
                                    variant="square"
                                    className={classes.imageCont}
                                    style={{ backgroundColor: colorArr[index % 3] }}
                                >
                                    <GiTrophyCup fontSize="large" />
                                </Avatar>

                                <div className={classes.experienceRight}>
                                    <h3>{honor?.title}</h3>
                                    <h4>{honor?.issuer}</h4>
                                    <h5>{`Issued at ${honor?.issuing_date}`}</h5>
                                    <p>{honor?.description}</p>
                                </div>
                            </div>
                        </>))}
                    </div>
                </Paper>
            )}


            <AwardHonorsDialog
                open={openAwardHonorDialog}
                setOpen={setOpenAwardHonorDialog}
                profile={profile}
                teamId={teamId}
            />
            <CertificatLicenseDialog
                open={openCertificatLicenseDialog}
                setOpen={setOpenCertificatLicenseDialog}
                profile={profile}
                teamId={teamId}
            />
            <ProjectDialog
                open={openProjectDialog}
                setOpen={setOpenProjectDialog}
                profile={profile}
                teamId={teamId}
            />
        </div>
    );
};

export default OrgAbout;