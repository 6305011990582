import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import {
  Grid,
  Card,
  Box,
  CardContent,
  FormControl,
  Divider,
  Avatar,
  Container,
  Button,
  InputLabel,
  Input,
  Typography,
  TextareaAutosize,
  Select,
  MenuItem,
} from "@mui/material";
import Api from "../../../helpers/Api";
import FormBox from "../../styled/generic/FormBox";
import { Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const Overview = () => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();

  const [overviewCardData, setOverviewCardData] = useState();
  const [chartData, setChartData] = useState({});

  const getOverviewStats = async () => {
    try {
      const { data } = await Api.post("listing/getOverviewStats", {
        userProfile: user?.profile,
      });
      setOverviewCardData([
        {
          label: "Total Listings",
          value: data?.totalListings,
          imgUrl: "/icons/app/greennote.svg",
        },
        {
          label: "Requirements",
          value: data?.totalRequirements,
          imgUrl: "/icons/app/purplenote.svg",
        },
        {
          label: "New Leads",
          value: data?.totalLeads,
          imgUrl: "/icons/app/useryellow.svg",
        },
        {
          label: "Bookings",
          value: data?.totalBookings,
          imgUrl: "/icons/app/bluecal.svg",
        },
      ]);
      setChartData([
        {
          name: "Units Sold",
          value: data?.totalUnitsSold || 0,
          fill: "#FFCD29",
        },
        {
          name: "Units Listed",
          value: data?.totalUnitsListed || 0,
          fill: "#2D76E0",
        },
      ]);
    } catch (error) {
      console.log("Unable to get stats");
    }
  };

  const createListing = async (listingType) => {
    try {
      const obj = {
        listingType: listingType,
        user: user,
      };
      const { data } = await Api.post("listing/createListing", obj);
      if (data) {
        const listingId = data._id;
        history.push(`/listing/edit/${listingId}`);
      }
    } catch (error) {
      console.log("Unable to create listing");
    }
  };

  const cardData = [
    {
      imgUrl: "/icons/app/investmenticon.svg",
      title: "Rent Lease",
      caption: "Post Your Listing for free",
      type: "Rent",
    },
    {
      imgUrl: "/icons/app/procurementicon.svg",
      title: "Sell",
      caption: "Post Your Listing for free",
      type: "Sell",
    },
    {
      imgUrl: "/icons/app/professionalicon.svg",
      title: "For Roommates",
      caption: "Post Your Listing for free",
      type: "Roommate",
    },
    {
      imgUrl: "/icons/app/renticon.svg",
      title: "Professionals",
      caption: "Post Your Listing for free",
      type: "Job",
    },
    {
      imgUrl: "/icons/app/roommateicon.svg",
      title: "Investments",
      caption: "Post Your Listing for free",
      type: "Investment",
    },
    {
      imgUrl: "/icons/app/sellicon.svg",
      title: "Procurements",
      caption: "Post Your Listing for free",
      type: "Tender",
    },
  ];

  useEffect(() => {
    getOverviewStats();
  }, []);

  const OverviewCard = ({ imgUrl, label, value, onClick }) => {
    return (
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "20px",
          p: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          mr: 4,
        }}
      >
        <Box sx={{ mr: 4 }}>
          <img src={imgUrl} width={50} />
        </Box>
        <Box>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            {value}
          </Typography>
          <Typography variant="body1">{label}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box display="flex" alignItems="stretch" justifyContent="space-between">
      <Box flex="0.75">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            minHeight: "100px",
          }}
        >
          {overviewCardData?.map((item) => (
            <OverviewCard
              label={item.label}
              value={item.value}
              imgUrl={item.imgUrl}
            />
          ))}
        </Box>
        <FormBox
          label="Add Listings"
          sx={{ backgroundColor: "#fff", mt: 4, p: 3, borderRadius: "10px" }}
        >
          <Typography variant="body1" gutterBottom>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
          <Box>
            <Grid container>
              {cardData?.map((item, index) => (
                <Grid xs={4} key={index}>
                  <Box
                    sx={{
                      border: "1px solid rgba(0,0,0,0.10)",
                      borderRadius: "12px",
                      padding: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mx: 4,
                      my: 2,
                      cursor: "pointer",
                    }}
                    onClick={() => createListing(item?.type)}
                  >
                    <Box sx={{ mr: 4 }}>
                      <img src={item.imgUrl} width={60} />
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "600" }}
                        color="primary"
                      >
                        {item.title}
                      </Typography>
                      <Typography variant="body1">{item.caption}</Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </FormBox>
      </Box>
      <Box
        flex="0.25"
        display="flex"
        alignItems="top"
        justifyContent="center"
        sx={{ p: 2 }}
      >
        <Box
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            p: 2,
            width: "100%",
          }}
        >
          <FormBox disableMargins={true} label="Listing Status">
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Tooltip />
                <Legend iconType="circle" />
                <Pie
                  fill="#fff"
                  dataKey="value"
                  startAngle={0}
                  endAngle={360}
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  innerRadius={80}
                  strokeWidth={3}
                />
              </PieChart>
            </ResponsiveContainer>
          </FormBox>
        </Box>
      </Box>
    </Box>
  );
};

export default Overview;
