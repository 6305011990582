import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, IconButton, useMediaQuery } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useSelector, useDispatch } from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';
import { updateOrgWithPopulate } from '../api.call';
import teamUtils from "../../team/team.utils";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    header: {
        height: "60px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #e4e7e7",
        padding: "0px 25px",
        "& h3": {
            fontSize: "17px",
            fontWeight: "450",
            color: "#170f49"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0px 10px",
        }
    },
    body: {
        width: "100%",
        height: "calc(100% - 65px)",
        padding: "20px 40px 40px",
        paddingRight: "0px",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        [theme.breakpoints.down("md")]: {
            padding: "15px 10px 30px",
        }
    },
    singleEducationCont: {
        width: "700px",
        marginBottom: "20px",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    textLineCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column"
        }
    },
    halfCont: {
        width: "47%",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }
    },
    checkedCont: {
        display: "flex",
        alignItems: "center",
        marginTop: "10px",
        marginBottom: "-10px",
        "& h3": {
            fontSize: "15px",
            marginRight: "15px",
            fontWeight: "500",
        }
    },
    label: {
        fontSize: "15px",
        fontWeight: "500",
        marginTop: "15px",
        marginBottom: "2px"
    }
}));

const calendarMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export default function CertificatEdit({ setOpenEditView, profile, teamId }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch()
    const { handleTeams } = teamUtils;

    const state = useSelector((state) => state);
    const { teamDictionary } = useSelector((state) => state.team);
    const { user } = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false)
    const [stateChange, setStateChange] = useState(false)
    const [calendarYear, setCalendarYear] = useState([]);
    const [certificationObjArr, setCertificationObjArr] = useState([])

    const isSmall = useMediaQuery(theme.breakpoints.down("xs"));


    useEffect(() => {
        let localLicense = teamDictionary[teamId]?.parent?.licenses || []
        if (localLicense && localLicense.length > 0) {
            let arr = []
            localLicense.map((license) => {
                let loc_startMonth
                let loc_startYear
                let loc_endMonth
                let loc_endYear
                let loc_issuedMonth
                let loc_issuedYear

                let locStartDate = license?.start_date || ""
                if (locStartDate && locStartDate.length > 3) {
                    loc_startMonth = locStartDate.split("-")[0]
                    loc_startYear = locStartDate.split("-")[1]
                } else {
                    loc_startMonth = ""
                    loc_startYear = ""
                }

                let locEndDate = license?.end_date || ""
                if (locEndDate && locEndDate.length > 3) {
                    loc_endMonth = locEndDate.split("-")[0]
                    loc_endYear = locEndDate.split("-")[1]
                } else {
                    loc_endMonth = ""
                    loc_endYear = ""
                }

                let locIssuedDate = license?.start_date_honor || ""
                if (locIssuedDate && locIssuedDate.length > 3) {
                    loc_issuedMonth = locIssuedDate.split("-")[0]
                    loc_issuedYear = locIssuedDate.split("-")[1]
                } else {
                    loc_issuedMonth = ""
                    loc_issuedYear = ""
                }

                arr.push({
                    name: license?.title,
                    IssuingOrganization: license?.issuer,
                    credentialId: license?.credentialId,
                    credentialUrl: license?.license_url,
                    startMonth: loc_startMonth,
                    startYear: loc_startYear,
                    endMonth: loc_endMonth,
                    endYear: loc_endYear,
                    issuedMonth: loc_issuedMonth,
                    issuedYear: loc_issuedYear,
                    description: license?.description
                })
            })

            setCertificationObjArr(arr)
        }
    }, [teamDictionary[teamId], teamDictionary[teamId]?.parent, teamDictionary[teamId]?.parent?.licenses])


    useEffect(() => {
        const years = [];
        const date = new Date();
        const endYear = date.getFullYear();
        for (let i = 1951; i <= endYear; i++) {
            years.push(i + "");
        }
        setCalendarYear(years);
    }, []);

    const addNewCertificate = () => {
        setStateChange(!stateChange)
        setCertificationObjArr([
            {
                name: "",
                IssuingOrganization: "",
                credentialId: "",
                credentialUrl: "",
                startMonth: "",
                startYear: "",
                endMonth: "",
                endYear: "",
                issuedMonth: "",
                issuedYear: "",
                description: ""
            },
            ...certificationObjArr
        ])
        setStateChange(!stateChange)
    }

    const onChangeValue = (value, i, key) => {
        setStateChange(!stateChange)
        let arr = [...certificationObjArr]
        arr[i][key] = value
        setCertificationObjArr(arr)
        setStateChange(!stateChange)
    }

    const deleteLine = (i) => {
        setStateChange(!stateChange)
        let arr = [...certificationObjArr]
        arr.splice(i, 1)
        setCertificationObjArr(arr)
        setStateChange(!stateChange)
    }

    const updateProfileSection = async () => {
        setLoading(true)
        let objArr = []
        certificationObjArr.map((certificationData) => {
            objArr.push({
                title: certificationData?.name,
                start_date: `${certificationData?.startMonth}-${certificationData?.startYear}`,
                end_date: `${certificationData?.endMonth}-${certificationData?.endYear}`,
                credentialId: certificationData?.credentialId,
                license_url: certificationData?.credentialUrl,
                start_date_honor: `${certificationData?.issuedMonth}-${certificationData?.issuedYear}`,
                issuer: certificationData?.IssuingOrganization,
                description: certificationData?.description
            })
        })

        await updateOrgWithPopulate({
            _id: profile?.parent?._id,
            licenses: objArr
        })
            .then((data) => {
                let teamDataFromDic = teamDictionary[teamId]
                let teamParent = teamDataFromDic?.parent
                let updatedLicense = data?.licenses || []
                const team = {
                    ...teamDataFromDic,
                    parent: {
                        ...teamParent,
                        licenses: updatedLicense
                    }
                }
                handleTeams([team], state, dispatch);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            })
    }


    return (
        <>
            <div className={classes.header} >
                <h3>Add & Edit Certificats</h3>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={addNewCertificate}
                        size={isSmall ? "small" : "medium"}
                    >
                        Add
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        size={isSmall ? "small" : "medium"}
                        style={{ marginLeft: "10px" }}
                        onClick={updateProfileSection}
                    >
                        Save
                    </Button>

                    <IconButton
                        style={{ marginLeft: "10px" }}
                        onClick={() => { setOpenEditView(false) }}
                        size={isSmall ? "small" : "medium"}
                    >
                        <ClearIcon />
                    </IconButton>
                </div>
            </div>
            <div style={{ height: "5px" }} >
                {loading && (<LinearProgress />)}
            </div>
            <div className={classes.body} >
                {certificationObjArr && certificationObjArr.length > 0 && certificationObjArr.map((certificationObj, i) => (
                    <div key={i} className={classes.singleEducationCont} >
                        <p className={classes.label} >License</p>
                        <TextField
                            required
                            id="school"
                            placeholder="Enter license"
                            value={certificationObj?.name}
                            onChange={(e) => {
                                onChangeValue(e.target.value, i, "name")
                            }}
                            size="small"
                            variant="outlined"
                            style={{ width: "100%" }}
                        />
                        <p className={classes.label} >Issuing organization</p>
                        <TextField
                            required
                            id="school"
                            placeholder="Enter issuing organization name"
                            value={certificationObj?.IssuingOrganization}
                            onChange={(e) => {
                                onChangeValue(e.target.value, i, "IssuingOrganization")
                            }}
                            size="small"
                            variant="outlined"
                            style={{ width: "100%" }}
                        />
                        <div className={classes.textLineCont} >
                            <div className={classes.halfCont} >
                                <p className={classes.label} >Credential ID</p>
                                <TextField
                                    required
                                    id="school"
                                    placeholder="Enter credential ID"
                                    value={certificationObj?.credentialId}
                                    onChange={(e) => {
                                        onChangeValue(e.target.value, i, "credentialId")
                                    }}
                                    size="small"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div className={classes.halfCont} >
                                <p className={classes.label} >Credential URL</p>
                                <TextField
                                    id="degree"
                                    placeholder="Enter credential URL"
                                    value={certificationObj?.credentialUrl}
                                    onChange={(e) => {
                                        onChangeValue(e.target.value, i, "credentialUrl");
                                    }}
                                    size="small"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>
                        <p className={classes.label} >Start date</p>
                        <div className={classes.textLineCont} >
                            <div className={classes.halfCont} >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={certificationObj?.startMonth}
                                    options={calendarMonth}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option === certificationObj?.startMonth
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Enter start month"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "startMonth")
                                    }}
                                    style={{ width: "100%", marginBottom: "5px" }}
                                    size="small"
                                />
                            </div>
                            <div className={classes.halfCont} >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={certificationObj?.startYear}
                                    options={calendarYear}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option === certificationObj?.startYear
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Enter start year"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "startYear")
                                    }}
                                    style={{ width: "100%", marginBottom: "5px" }}
                                    size="small"
                                />
                            </div>
                        </div>
                        <p className={classes.label} >End date</p>
                        <div className={classes.textLineCont} >
                            <div className={classes.halfCont} >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={certificationObj?.endMonth}
                                    options={calendarMonth}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option === certificationObj?.endMonth
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Enter end month"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "endMonth")
                                    }}
                                    style={{ width: "100%", marginBottom: "5px" }}
                                    size="small"
                                />
                            </div>
                            <div className={classes.halfCont} >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={certificationObj?.endYear}
                                    options={calendarYear}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option === certificationObj?.endYear
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Enter end year"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "endYear")
                                    }}
                                    style={{ width: "100%", marginBottom: "5px" }}
                                    size="small"
                                />
                            </div>
                        </div>
                        <p className={classes.label} >Issued date</p>
                        <div className={classes.textLineCont} >
                            <div className={classes.halfCont} >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={certificationObj?.issuedMonth}
                                    options={calendarMonth}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option === certificationObj?.issuedMonth
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Enter issued month"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "issuedMonth")
                                    }}
                                    style={{ width: "100%", marginBottom: "5px" }}
                                    size="small"
                                />
                            </div>
                            <div className={classes.halfCont} >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={certificationObj?.issuedYear}
                                    options={calendarYear}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option === certificationObj?.issuedYear
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Enter issued year"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "issuedYear")
                                    }}
                                    style={{ width: "100%", marginBottom: "5px" }}
                                    size="small"
                                />
                            </div>
                        </div>
                        <p className={classes.label} >Description</p>
                        <TextField
                            id="description"
                            placeholder="Write anything notable..."
                            multiline
                            rows={3}
                            variant="outlined"
                            size="small"
                            value={certificationObj?.description}
                            onChange={(e) => {
                                onChangeValue(e.target.value, i, "description");
                            }}
                            fullWidth
                        />
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "15px 0px 30px" }} >
                            <div></div>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<DeleteIcon />}
                                onClick={() => { deleteLine(i) }}
                                size={isSmall ? "small" : "medium"}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}