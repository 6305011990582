import React, { useEffect } from "react";
import FormBox from "./FormBox";
import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";

const CheckGroup = ({
  label,
  options,
  selectedOptions,
  setSelectedOptions,
  blockMode = false,
}) => {
  return (
    <FormBox label={label}>
      <Box display="flex" flex="wrap">
        {options?.map((item) => (
          <Stack direction={blockMode ? "row" : "row"}>
            <FormControlLabel
              key={item}
              control={
                <Checkbox
                  checked={selectedOptions.includes(item)}
                  onChange={(evt, checked) => {
                    if (checked) {
                      setSelectedOptions([...selectedOptions, item]);
                    } else {
                      setSelectedOptions(
                        selectedOptions?.filter((o) => o !== item)
                      );
                    }
                  }}
                />
              }
              label={item}
            />
          </Stack>
        ))}
      </Box>
    </FormBox>
  );
};

export default CheckGroup;
