import React, { useEffect, useState } from "react";
import FormBox from "./FormBox";
import { Box } from "@mui/material";

const Count = ({ value, variable, setVariable }) => {
  return (
    <Box
      key={value}
      minWidth={40}
      height={40}
      display="flex"
      alignItems="center"
      justifyContent="center"
      onClick={() => setVariable(value)}
      sx={{
        borderRadius: "15px",
        border: variable === value ? "1px solid #2196F3" : "1px solid #DADADA",
        padding: "7px 15px",
        marginRight: "7px",
        cursor: "pointer",
        color: variable === value ? "#2196F3" : "#111111",
      }}
    >
      {value}
    </Box>
  );
};

const CountPicker = ({
  label,
  countFrom,
  countTo,
  showMoreButton,
  count,
  setCount,
}) => {
  const [range, setRange] = useState([]);

  const getRange = () => {
    let ary = [];
    for (let i = countFrom; i <= countTo; i++) {
      ary.push(i);
    }
    setRange(ary);
  };

  useEffect(() => {
    getRange();
  }, [countFrom, countTo]);

  return (
    <FormBox label={label} >
      <Box display="flex" flexWrap="wrap" >
        {range?.map((num) => (
          <Count value={num} variable={count} setVariable={setCount} />
        ))}
        {showMoreButton && (
          <Count value="More" variable={count} setVariable={setCount} />
        )}
      </Box>
    </FormBox>
  );
};

export default CountPicker;
