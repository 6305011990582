import React from "react";
import ErrorImage from "../../Assets/403.svg";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

function Error403() {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<img src={ErrorImage} style={{ height: "24rem", width: "35rem" }} />
			<Typography
				variant="h4"
				style={{ marginTop: "1rem", textAlign: "center" }}
			>
				Access Denied!!
			</Typography>
			<Typography variant="h5" style={{ textAlign: "center" }}>
				We're sorry. You don't have the required permission to proceed.
			</Typography>
			<Typography variant="subtitle1">
				<Link to="/">Go to RealList's Homepage</Link>
			</Typography>
		</div>
	);
}

export default Error403;
