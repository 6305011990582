import { Box } from "@mui/material";
import React from "react";

const TabBox = ({ sx, children, disableUnderline, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        borderBottom: disableUnderline ? "none" : "1px solid rgba(0,0,0,0.10)",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default TabBox;
