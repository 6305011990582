import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Lottie from "react-lottie";
import building from "../../../Assets/lottie/city-building.json";
import profit from "../../../Assets/lottie/Profit.json";
import invest from "../../../Assets/lottie/Invest_buy.json";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        marginTop: "30px",
        marginBottom: "30px",
        padding: "0px 180px 50px",
        [theme.breakpoints.down("lg")]: {
            padding: "0px 120px 50px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "0px 50px 40px",
        },
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            padding: "0px 30px 30px"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0px 15px 30px"
        },
    },
    mainCont: {
        width: "100%",
        height: "calc( 100vh - 120px )",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            height: "auto",
        }
    },
    allTextCont: {
        width: "35%",
        marginLeft: "100px",
        [theme.breakpoints.down("sm")]: {
            width: "90%",
            marginLeft: "0px",
            marginBottom: "60px"
        }
    },
    titleSty: {
        fontSize: "35px",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            fontSize: "30px",
            marginBottom: "70px"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "25px",
            fontWeight: "600"
        },
    },
    notActiveSingleTextCont: {
        marginBottom: "30px",
        opacity: "0.5",
        borderLeft: `0px solid ${theme.palette.primary.main}`,
        transition: `opacity 0.1s, border-left 0.1s`,
        cursor: "pointer",
        "& h3": {
            fontSize: "27px",
            fontWeight: "700"
        },
        "& p": {
            fontSize: "19px",
            fontWeight: "600"
        },
        [theme.breakpoints.down("sm")]: {
            "& h3": {
                fontSize: "22px",
                fontWeight: "650"
            },
            "& p": {
                fontSize: "16px",
                fontWeight: "550"
            },
        }
    },
    activeSingleTextCont: {
        marginBottom: "30px",
        opacity: "1",
        borderLeft: `6px solid ${theme.palette.primary.main}`,
        transition: `opacity 0.1s, border-left 0.1s`,
        paddingLeft: "20px",
        cursor: "pointer",
        "& h3": {
            fontSize: "27px",
            fontWeight: "700"
        },
        "& p": {
            fontSize: "19px",
            fontWeight: "600"
        },
        [theme.breakpoints.down("sm")]: {
            "& h3": {
                fontSize: "22px",
                fontWeight: "650"
            },
            "& p": {
                fontSize: "16px",
                fontWeight: "550"
            },
        }
    },
}));


const Goal = ({ pageRef }) => {
    const classes = useStyles();
    const theme = useTheme();

    const [activeIndex, setActiveIndex] = useState(0);
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: (activeIndex == 0) ? building : (activeIndex == 1) ? invest : profit,
    };

    const isMdSmall = useMediaQuery(theme.breakpoints.down("md"));
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));


    return (
        <section ref={pageRef} className={classes.root} >
            <h3 className={classes.titleSty} data-aos="fade-down" >
                Investors achieve their goal in 3 easy steps
            </h3>

            <div className={classes.mainCont} >
                {isSmall && (
                    <div className={classes.allTextCont} data-aos="fade-left" >
                        <div
                            className={activeIndex === 0 ? classes.activeSingleTextCont : classes.notActiveSingleTextCont}
                            onClick={() => { setActiveIndex(0) }}
                        >
                            <h3>1. Pick a Property</h3>
                            <p>Choose from our thoroughly vetted developer and apartment projects for their return potential</p>
                        </div>

                        <div
                            className={activeIndex === 1 ? classes.activeSingleTextCont : classes.notActiveSingleTextCont}
                            onClick={() => { setActiveIndex(1) }}
                        >
                            <h3>2.Invest/Buy</h3>
                            <p>Buy flat or Invest fractionally in projects.</p>
                        </div>

                        <div
                            className={activeIndex === 2 ? classes.activeSingleTextCont : classes.notActiveSingleTextCont}
                            onClick={() => { setActiveIndex(2) }}
                        >
                            <h3>3. Profit</h3>
                            <p>Track the project and your returns easily on the platform</p>
                        </div>
                    </div>
                )}
                <span data-aos="fade-right" >
                    <Lottie
                        className="lottieAnimation"
                        options={defaultOptions}
                        width={isExSmall ? "80vw" : isSmall ? "60vw" : isMdSmall ? "40vw" : "34vw"}
                        height={"auto"}
                        style={{ margin: "0px" }}
                    />
                </span>
                {!isSmall && (
                    <div className={classes.allTextCont} data-aos="fade-left" >
                        <div
                            className={activeIndex === 0 ? classes.activeSingleTextCont : classes.notActiveSingleTextCont}
                            onClick={() => { setActiveIndex(0) }}
                        >
                            <h3>1. Pick a Property</h3>
                            <p>Choose from our thoroughly vetted developer and apartment projects for their return potential</p>
                        </div>

                        <div
                            className={activeIndex === 1 ? classes.activeSingleTextCont : classes.notActiveSingleTextCont}
                            onClick={() => { setActiveIndex(1) }}
                        >
                            <h3>2.Invest/Buy</h3>
                            <p>Buy flat or Invest fractionally in projects.</p>
                        </div>

                        <div
                            className={activeIndex === 2 ? classes.activeSingleTextCont : classes.notActiveSingleTextCont}
                            onClick={() => { setActiveIndex(2) }}
                        >
                            <h3>3. Profit</h3>
                            <p>Track the project and your returns easily on the platform</p>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};
export default Goal;