import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RequestsTableComponent from "../tables/RequestsTableComponent";
import { getRequestsApi } from "../../listing.api";

const MyRequests = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [requests, setRequests] = useState([]);
  const [requestsLoading, setRequestsLoading] = useState(false);
  const [actions, setActions] = useState(["TODO"]);
  const handleActionClick = () => {};

  const getRequests = async () => {
    try {
      setRequestsLoading(true);
      const { data } = await getRequestsApi({
        ownerId: user?.profile,
        applicationType: "Listing",
      });

      setRequests(data);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch requests",
        },
      });
    } finally {
      setRequestsLoading(false);
    }
  };

  useEffect(() => {
    getRequests();
  }, []);

  return (
    <RequestsTableComponent
      requests={requests}
      setRequests={setRequests}
      loading={requestsLoading}
      actions={actions}
      handleActionClick={handleActionClick}
    />
  );
};

export default MyRequests;
