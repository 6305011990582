import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SpaceBetween from "../../styled/generic/SpaceBetween";
import FormBox from "../../styled/generic/FormBox";
import TextField from "../../styled/generic/TextField";
import Select from "../../styled/generic/Select";
import {
  CurrencyRupee,
  KeyboardArrowDown,
  LocationOnOutlined,
  Search,
} from "@mui/icons-material";
import useLocationForm from "../../styled/hooks/useLocationForm";
import CheckGroup from "../../styled/generic/CheckGroup";
import CountPicker from "../../styled/generic/CountPicker";
import CheckGroupGrid from "../../styled/generic/CheckGroupGrid";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import CheckGroupWithObj from "../../styled/generic/CheckGroupWithObj";
import { isNull } from "lodash";
import PropertyTypeChooser from "../editlisting/components/PropertyTypeChooser";
import UnitAutocomplete from "../../styled/CommonComponents/UnitAutocomplete";

const REQUIREMENT_TYPE_OPTIONS = [
  "Rent",
  "Sell",
  "Roommate",
  "Job",
  "Investment",
  "Tender",
];

const FURNISHING_OPTIONS = [
  "Fully Furnished",
  "Semi Furnished",
  "Un Furnished",
];

const FLOOR_OPTIONS = [
  { title: "Ground Floor", value: 0 },
  { title: "1st Floor", value: 1 },
  { title: "2nd Floor", value: 2 },
  { title: "3rd Floor", value: 3 },
  { title: "4th Floor", value: 4 },
  { title: "5th or More", value: -1 },
];

const AMENITY_OPTIONS = [
  "Power Backup",
  "Wifi",
  "Gym",
  "Air conditioner",
  "Swimming Pool",
  "Gas Station",
  "Park",
  "Fire Safety",
  "Elevator",
  "Play Area",
  "Park",
];

const EditRequirement = () => {
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");

  const [title, setTitle] = useState(""); // Requirement title

  const [selectedRequirementType, setSelectedRequirementType] = useState(
    REQUIREMENT_TYPE_OPTIONS[0]
  );

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  const [minArea, setMinArea] = useState(0);
  const [maxArea, setMaxArea] = useState(0);

  const [propertyType, setPropertyType] = useState();
  const [propertyAreaUnit, setPropertyAreaUnit] = useState();

  const [furnishings, setFurnishings] = useState([]);
  const [bedroomCount, setBedroomCount] = useState();
  const [bathroomCount, setBathroomCount] = useState();
  const [balconyCount, setBalconyCount] = useState();
  const [floors, setFloors] = useState([]);
  const [amenities, setAmenities] = useState([]);

  const [isPrivate, setIsPrivate] = useState(false);

  const { locationForm, locations, setLocations } = useLocationForm();
  const dispatch = useDispatch();
  const { requirementId } = useParams();

  const getRequirementData = async () => {
    try {
      const { data } = await Api.post("/listing/requirements/get", {
        requirementId: requirementId,
      });
      if (data) {
        if (data?.contactName) {
          setContactName(data.contactName);
        }

        if (data?.contactEmail) {
          setContactEmail(data.contactEmail);
        }

        if (data?.contactPhone) {
          setContactPhone(data.contactPhone);
        }

        if (data?.title) {
          setTitle(data.title);
        }

        if (data?.requirementType) {
          setSelectedRequirementType(data.requirementType);
        }

        if (data?.minPrice) {
          setMinPrice(data.minPrice);
        }

        if (data?.maxPrice) {
          setMaxPrice(data.maxPrice);
        }

        if (data?.minArea) {
          setMinArea(data.minArea);
        }

        if (data?.maxArea) {
          setMaxArea(data.maxArea);
        }

        if (data?.areaUnit) {
          setPropertyAreaUnit(data.areaUnit);
        }

        if (data?.propertyType) {
          setPropertyType(data.propertyType);
        }

        if (data?.furnishings) {
          setFurnishings(data.furnishings);
        }

        if (data?.bedRoomCount) {
          setBedroomCount(data.bedRoomCount);
        }

        if (data?.bathRoomCount) {
          setBathroomCount(data.bathRoomCount);
        }

        if (data?.balconyCount) {
          setBalconyCount(data.balconyCount);
        }

        if (Array.isArray(data?.amenities)) {
          setAmenities(data.amenities);
        }

        setFloors(FLOOR_OPTIONS.filter((f) => data?.floors.includes(f.value)));

        if (Array.isArray(data?.locations)) {
          setLocations(data.locations);
        }

        setIsPrivate(data?.isPrivate);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to get requirement",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get requirement",
        },
      });
    }
  };

  const updateRequirement = async () => {
    try {
      const { data } = await Api.post("/listing/requirements/update", {
        requirementId: requirementId,
        updateData: {
          contactName,
          contactEmail,
          contactPhone,
          title,
          requirementType: selectedRequirementType,
          isPrivate,
          minPrice,
          maxPrice,
          minArea,
          maxArea,
          areaUnit: propertyAreaUnit,
          propertyType,
          furnishings,
          bedRoomCount: isNaN(bedroomCount) ? null : bedroomCount,
          bathRoomCount: isNaN(bathroomCount) ? null : bathroomCount,
          balconyCount: isNaN(balconyCount) ? null : balconyCount,
          amenities,
          floors: floors.map((item) => item.value),
          locations: locations,
        },
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Requirement saved successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to update requirement",
        },
      });
    }
  };

  useEffect(() => {
    getRequirementData();
  }, []);

  return (
    <Box sx={{ px: 3, py: 2, backgroundColor: "#F4F7FF" }}>
      <Box
        sx={{ px: 3, py: 2, borderRadius: "10px", backgroundColor: "#FFFFFF" }}
      >
        <FormBox label="Contact Details">
          <Typography variant="body1" gutterBottom>
            Please Enter contact Details Below
          </Typography>
        </FormBox>

        <Box sx={{ mt: 2 }}>
          <SpaceBetween
            left={
              <FormBox label="Name">
                <TextField
                  fullWidth
                  placeholder="Enter Name"
                  value={contactName}
                  onChange={(evt) => setContactName(evt.target.value)}
                />
              </FormBox>
            }
            right={
              <FormBox label="Phone Number">
                <TextField
                  fullWidth
                  placeholder="Enter Phone Number"
                  value={contactPhone}
                  onChange={(evt) => setContactPhone(evt.target.value)}
                />
              </FormBox>
            }
          />
          <SpaceBetween
            left={
              <FormBox label="Email">
                <TextField
                  fullWidth
                  placeholder="Enter Email Id"
                  value={contactEmail}
                  onChange={(evt) => setContactEmail(evt.target.value)}
                />
              </FormBox>
            }
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <FormBox label="Make Private">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPrivate}
                  onChange={(evt, checked) => {
                    setIsPrivate(checked);
                  }}
                />
              }
              label="Make this requirement private"
            />
          </FormBox>
          <FormBox label="Requirements">
            <Typography variant="body1" gutterBottom>
              Tell Us About Your Requirments
            </Typography>
          </FormBox>
          <SpaceBetween
            left={
              <FormBox label="Title">
                <TextField
                  fullWidth
                  placeholder="Enter Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </FormBox>
            }
            right={
              <FormBox label="Requirement Type">
                <Select
                  fullWidth
                  value={selectedRequirementType}
                  onChange={(evt) =>
                    setSelectedRequirementType(evt.target.value)
                  }
                >
                  {REQUIREMENT_TYPE_OPTIONS.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormBox>
            }
          />
          <SpaceBetween
            left={
              <FormBox label="Price Range">
                <TextField
                  placeholder="Min"
                  type="Number"
                  sx={{ mr: 2 }}
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupee />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  placeholder="Max"
                  type="Number"
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupee />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormBox>
            }
          />
          <SpaceBetween left={locationForm} />

          <PropertyTypeChooser
            propertyType={propertyType}
            setPropertyType={setPropertyType}
          />

          <SpaceBetween
            left={
              <FormBox label="Area">
                <TextField
                  placeholder="Min"
                  type="Number"
                  sx={{ mr: 2 }}
                  value={minArea}
                  onChange={(e) => setMinArea(e.target.value)}
                />
                <TextField
                  placeholder="Max"
                  type="Number"
                  sx={{ mr: 2 }}
                  value={maxArea}
                  onChange={(e) => setMaxArea(e.target.value)}
                />
                <UnitAutocomplete
                  value={propertyAreaUnit}
                  onChange={(evt, value) => setPropertyAreaUnit(value)}
                  size="small"
                />
              </FormBox>
            }
          />

          <SpaceBetween
            left={
              <CheckGroup
                label="Furnishing"
                options={FURNISHING_OPTIONS}
                selectedOptions={furnishings}
                setSelectedOptions={setFurnishings}
              />
            }
          />

          <SpaceBetween
            left={
              <CountPicker
                countFrom={1}
                countTo={5}
                label="Bedrooms"
                count={bedroomCount}
                setCount={(val) => setBedroomCount(val)}
                showMoreButton={true}
              />
            }
          />

          <SpaceBetween
            left={
              <CountPicker
                countFrom={1}
                countTo={5}
                label="Bathroom"
                count={bathroomCount}
                setCount={(val) => setBathroomCount(val)}
                showMoreButton={true}
              />
            }
          />

          <SpaceBetween
            left={
              <CountPicker
                countFrom={1}
                countTo={5}
                label="Balcony"
                count={balconyCount}
                setCount={(val) => setBalconyCount(val)}
                showMoreButton={true}
              />
            }
          />

          <SpaceBetween
            left={
              <CheckGroupWithObj
                label="Floor"
                options={FLOOR_OPTIONS}
                selectedOptions={floors}
                setSelectedOptions={setFloors}
              />
            }
          />

          <SpaceBetween
            left={
              <CheckGroupGrid
                label="Amenities"
                options={AMENITY_OPTIONS}
                selectedOptions={amenities}
                setSelectedOptions={setAmenities}
              />
            }
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            color="primary"
            variant="outlined"
            sx={{ mr: 2, minWidth: 120 }}
          >
            Discard
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ minWidth: 120 }}
            onClick={() => updateRequirement()}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EditRequirement;
