import { Box, Collapse, Typography } from "@mui/material";
import { useState } from "react";
import SpaceBetween from "./SpaceBetween";
import { ChevronRight } from "@material-ui/icons";

import React from "react";

const Accordion = ({ title, icon, collapseContent }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        px: 2,
        py: 2,
        mb: 2,
        boxShadow: "0px 0.50086px 44.70194px 0px rgba(45, 118, 224, 0.05);",
        borderRadius: "16px",
      }}
    >
      <SpaceBetween
        onClick={() => setIsCollapsed(!isCollapsed)}
        disableResponsive={true}
        left={
          <Box
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
          >
            <div
              style={{
                backgroundColor: "#2D76E0",
                padding: "2px",
                borderRadius: "100%",
                width: "45px",
                height: "45px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "15px",
              }}
            >
              <img src={icon} />
            </div>
            <Typography variant="h6">{title}</Typography>
          </Box>
        }
        right={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            sx={{
              cursor: "pointer",
            }}
          >
            <ChevronRight
              fontSize="large"
              style={{
                transform: isCollapsed ? "rotate(90deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}
            />
          </Box>
        }
      ></SpaceBetween>

      <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
        <Box sx={{ px: "60px", py: 2, bgcolor: "background.paper" }}>
          {collapseContent}
        </Box>
      </Collapse>
    </Box>
  );
};

export default Accordion;
