import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  IconButton,
  Menu,
  Fade,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { IoIosMore, IoMdTrash } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: theme.shadows[3],
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.grey[300],
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.grey[400],
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main,
  },
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  height: "56px",
  width: "150px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
}));

const columns = [
  { id: "email", label: "Email", minWidth: 200 },
  { id: "emailProvider", label: "Provider", minWidth: 100 },
  { id: "isVerified", label: "Verification Status", minWidth: 150 },
];

export default function BusinessEmail({ setOpenEditView }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [personalEmail, setPersonalEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [businessEmail, setBusinessEmail] = useState("");
  const [emailProvider, setEmailProvider] = useState("");
  const [error, setError] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isVerifyDialogOpen, setIsVerifyDialogOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState({});
  const [verificationPassword, setVerificationPassword] = useState("");

  useEffect(() => {
    setPersonalEmail(user?.email || "");
  }, [user]);

  useEffect(() => {
    const fetchEmails = async () => {
      const data = await Api.get(`user/getemails/${user._id}`);
      if (data) {
        setEmails(data.emails);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Error while fetching emails",
          },
        });
      }
    };
    fetchEmails();
  }, [user._id]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeEmail = (event) => {
    setBusinessEmail(event.target.value);
    setError(false);
  };

  const handleChangeProvider = (event) => {
    setEmailProvider(event.target.value);
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedEmail(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    setLoading(true);
    setAnchorEl(null);
    const data = await Api.delete(
      `user/deletebusinessemail/${selectedEmail._id}`
    );
    if (data) {
      setEmails((prev) =>
        prev.filter((email) => email._id !== selectedEmail._id)
      );
      dispatch({
        type: "SET_AUTH_USER",
        user: data?.user,
      });
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Email deleted successfully",
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error deleting email",
        },
      });
    }
    setLoading(false);
  };

  const handleDefault = async () => {
    setLoading(true);
    setAnchorEl(null);
    const data = await Api.post(
      `user/changedefaultemail/${selectedEmail._id}`,
      {
        userId: user._id,
      }
    );
    if (data) {
      dispatch({
        type: "SET_AUTH_USER",
        user: data?.user,
      });
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Default Business Email updated successfully",
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error while changing default business email",
        },
      });
    }
    setLoading(false);
  };

  const handleSubmitEmail = async () => {
    if (!businessEmail || !emailProvider) {
      setError(true);
      return;
    }
    setLoading(true);
    const data = await Api.post("user/addbusinessemail", {
      email: businessEmail,
      emailProvider: emailProvider,
      userId: user._id,
    });
    if (data) {
      setEmails((prev) => [...prev, data.businessEmail]);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Email added successfully",
        },
      });
      setBusinessEmail("");
      setEmailProvider("");
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error while adding email",
        },
      });
    }
    setLoading(false);
  };

  const verifyEmail = async () => {
    setLoading(true);
    try {
      const response = await Api.post("user/verifybusinessemail", {
        businessEmailId: selectedEmail._id,
        password: verificationPassword,
        userId: user._id,
      });

      if (response.status === "success") {
        setEmails((prevEmails) =>
          prevEmails.map((email) =>
            email._id === selectedEmail._id
              ? { ...email, isVerified: true }
              : email
          )
        );

        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Email verified successfully",
          },
        });

        if (response.user) {
          dispatch({
            type: "SET_AUTH_USER",
            user: response.user,
          });
        }
      } else {
        throw new Error(response.error || "Verification failed");
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: error.message || "Error verifying email",
        },
      });
    } finally {
      setLoading(false);
      setIsVerifyDialogOpen(false);
      setVerificationPassword("");
    }
  };

  return (
    <StyledBox>
      <Typography variant="h5" gutterBottom>
        Business Email Management
      </Typography>
      <Divider sx={{ mb: 3 }} />

      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          Personal Email
        </Typography>
        <StyledTextField
          fullWidth
          variant="outlined"
          value={personalEmail}
          disabled
          InputProps={{
            startAdornment: (
              <MdEmail color="action" style={{ marginRight: 8 }} />
            ),
          }}
        />
      </Box>

      <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
        <StyledTextField
          fullWidth
          label="Business Email"
          variant="outlined"
          value={businessEmail}
          onChange={handleChangeEmail}
          error={error}
          helperText={error ? "Please enter a valid email" : ""}
          InputProps={{
            startAdornment: (
              <MdEmail color="action" style={{ marginRight: 8 }} />
            ),
          }}
        />
        <StyledSelect
          value={emailProvider}
          onChange={handleChangeProvider}
          displayEmpty
          fullWidth
          error={error}
        >
          <MenuItem value="" disabled>
            Select Provider
          </MenuItem>
          <MenuItem value="gmail">Gmail</MenuItem>
          <MenuItem value="outlook">Outlook</MenuItem>
          <MenuItem value="yahoo">Yahoo</MenuItem>
          <MenuItem value="zoho">Zoho</MenuItem>
          <MenuItem value="custom">Custom</MenuItem>
        </StyledSelect>
        <StyledLoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          onClick={handleSubmitEmail}
        >
          Add Email
        </StyledLoadingButton>
      </Box>

      {emails.length > 0 && (
        <TableContainer component={Paper} elevation={2}>
          <Table stickyHeader aria-label="business emails table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
                <StyledTableCell align="right">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emails
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow hover key={row._id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      console.log(value);
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "isVerified" ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              {value ? (
                                <Tooltip title="Verified">
                                  <FaCheckCircle color="green" />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Unverified">
                                  <FaTimesCircle color="red" />
                                </Tooltip>
                              )}
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                {value ? "Verified" : "Unverified"}
                              </Typography>
                              {user.defaultBusinessEmail === value && (
                                <Typography variant="body2">
                                  <FaCheckCircle
                                    color="green"
                                    sx={{
                                      ml: 1,
                                    }}
                                  />
                                  default email
                                </Typography>
                              )}
                              {!value && (
                                <LoadingButton
                                  size="small"
                                  onClick={() => {
                                    setSelectedEmail(row);
                                    setIsVerifyDialogOpen(true);
                                  }}
                                  sx={{ ml: 1 }}
                                >
                                  Verify
                                </LoadingButton>
                              )}
                            </Box>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                    <TableCell align="right">
                      <Tooltip title="Email actions">
                        <IconButton
                          size="small"
                          onClick={(event) => handleClick(event, row)}
                        >
                          <IoIosMore />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={emails.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleDefault}>
          <FaCheckCircle style={{ marginRight: 8 }} />
          Make Default
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <IoMdTrash style={{ marginRight: 8 }} />
          Delete Email
        </MenuItem>
      </Menu>

      <Dialog
        open={isVerifyDialogOpen}
        onClose={() => setIsVerifyDialogOpen(false)}
      >
        <DialogTitle>Verify Email</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            value={verificationPassword}
            onChange={(e) => setVerificationPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsVerifyDialogOpen(false)}>Cancel</Button>
          <LoadingButton onClick={verifyEmail} loading={loading}>
            Verify
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </StyledBox>
  );
}
