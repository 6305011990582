import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";

import FormBox from "../styled/generic/FormBox";
import TextField from "../styled/generic/TextField";
import Api from "../../helpers/Api";
const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    width: "100%",
    height: "4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    paddingRight: "1rem",
    borderBottom: "1px solid #e4e7e7",
  },
  saveBtn: {
    width: "6rem",
    height: "2.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2d76e0",
    color: "white",
    borderRadius: "0.4rem",
    fontSize: "1.1rem",
  },
  inputCont: {
    paddingTop: "2rem",
    width: "95%",
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "1rem",
  },
});

const BasicInformation = ({ organization }) => {
  const classes = useStyles();
  const [displayName, setDisplayNmae] = useState(organization?.displayName);
  const [description, setDescription] = useState(organization?.description);
  const [email, setEmail] = useState(organization?.email);
  const [phone, setPhone] = useState(organization?.phone);
  const [tagLine, setTagLine] = useState(organization?.tagLine);
  const [userName, setUserName] = useState(organization?.userName);
  const [panNo, setPanNo] = useState(organization?.panNo); 
  const [gstNo, setGstNo] = useState(organization?.gstNo); 
  const { user } = useSelector((state) => state.auth);
  const [lodaing, setLoading] = useState(false);

  const saveEdit = async () => {
    try {
      setLoading(true);
      const obj = {
        displayName,
        description,
        email,
        phone,
        tagLine,
        userName,
        panNo,
        gstNo,
      };
      const { data } = await Api.post("/organization/update/edit", {
        _id: organization?._id,
        obj,
      });
      console.log(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span sty onClick={() => saveEdit()} className={classes.saveBtn}>
          {lodaing ? (
            <CircularProgress style={{ color: "white" }} size={24} />
          ) : (
            <span style={{ cursor: "pointer" }}>Save</span>
          )}
        </span>
      </div>
      <div className={classes.inputCont}>
        <FormBox label="Display Name">
          <TextField
            value={displayName}
            onChange={(e) => setDisplayNmae(e.target.value)}
            placeholder="Enter Display Name"
            fullWidth={true}
          />
        </FormBox>
        <FormBox label="Email">
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Email"
            style={{ width: "100%" }}
          />
        </FormBox>
        <FormBox label="Description">
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Description"
            style={{ width: "100%" }}
          />
        </FormBox>
        <FormBox label="Phone">
          <TextField
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Enter Phone"
            style={{ width: "100%" }}
          />
        </FormBox>

        <FormBox label="Tag line">
          <TextField
            value={tagLine}
            onChange={(e) => setTagLine(e.target.value)}
            placeholder="Enter tag line"
            style={{ width: "100%" }}
          />
        </FormBox>
        <FormBox label="User Name">
          <TextField
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="Enter User Name"
            style={{ width: "100%" }}
          />
        </FormBox>
        <FormBox label="PAN No.">
          <TextField
            value={panNo}
            onChange={(e) => setPanNo(e.target.value)}
            placeholder="Enter PAN No."
            style={{ width: "100%" }}
          />
        </FormBox>
        <FormBox label="GST No.">
          <TextField
            value={gstNo}
            onChange={(e) => setGstNo(e.target.value)}
            placeholder="Enter GST No."
            style={{ width: "100%" }}
          />
        </FormBox>
      </div>
    </div>
  );
};

export default BasicInformation;
