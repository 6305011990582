import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import EmailAdd from "./email.add";
import PlatformAdd from "./platform.add";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			alignItems: "center"
		},
		[theme.breakpoints.down("xs")]: {
			width: "350px",
		}
	},
	item: {
		width: "49%",
		minHeight: "400px",
		padding: "15px",
		paddingLeft: "35px",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			paddingRight: "0px",
			paddingLeft: "20px",
		},
	},
	divider: {
		borderRight: "1px solid #d3d3d3",
		[theme.breakpoints.down("sm")]: {
			border: "none",
			borderTop: "1px solid #d3d3d3",
		},
	}
}));

const AddMember = (props) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.item}>
				<PlatformAdd {...props} />
			</div>
			<div className={classes.divider} ></div>
			<div className={classes.item}>
				<EmailAdd {...props} />
			</div>
		</div>
	);
};

export default AddMember;