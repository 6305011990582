import React, { useEffect, useState } from "react";
import TabBox from "./TabBox";
import TabItem from "./TabItem";
import { Box, InputAdornment, TextField } from "@mui/material";
import { Home, Search } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import DescriptionIcon from "@mui/icons-material/Description";
import HandshakeIcon from "@mui/icons-material/Handshake";
import GavelIcon from "@mui/icons-material/Gavel";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const TabsWithActions = ({ tabs, onTabChange, currentTabVariable }) => {
  const [curTab, setCurTab] = useState(
    tabs.find((tab) => tab.title === currentTabVariable) || tabs[0]
  );
  const [action, setAction] = useState();

  useEffect(() => {
    if (curTab?.action) setAction(curTab.action);
  }, [curTab]);

  return (
    <TabBox display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex">
        {tabs?.map((tab, index) => (
          <TabItem
            key={index}
            variant="body1"
            active={currentTabVariable === tab.title}
            onClick={() => {
              onTabChange(tab.title);
              setCurTab(tab);
            }}
          >
            <span
              style={{
                marginRight: 5,
              }}
            >
              {tab?.icon && <>{tab?.icon}</>}
            </span>
            {tab.title}
          </TabItem>
        ))}
      </Box>

      {action ? <Box>{action}</Box> : <Box></Box>}
    </TabBox>
  );
};

export default TabsWithActions;
