import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "styled-components";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router-dom";
import emptyIcon from "../../Assets/vivekEmpty.svg";
import propertyManagement from "../../Assets/propertyManagement.svg";
import Skeleton from "@material-ui/lab/Skeleton";
import { getRentalUnitsByTeam } from "./apiCall";
import Api from "../../helpers/Api";
import LessText from "../styled/CommonComponents/LessText";
import AddNewUnit from "./AddNewUnit";
import { useSelector } from "react-redux";

const groupBy = (key) => (array) =>
	array.reduce((objectsByKeyValue, obj) => {
		const value = obj[key];
		objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
		return objectsByKeyValue;
	}, {});
const groupByParent = groupBy("parent");

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		width: "100%",
		height: "370px",
		borderRadius: "10px",
		marginBottom: "20px",
		padding: "10px 15px",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
		[theme.breakpoints.down("xs")]: {
			padding: "10px 5px",
		},
	},
	cardTopHeading: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		"& > h3": {
			fontWeight: "510",
			fontSize: "17px",
			color: "#193B56",
		},
	},
	createBtn: {
		width: "25px",
		height: "25px",
		borderRadius: "6px",
		backgroundColor: "#c1def6",
		color: theme.palette.primary.main,
		"&:hover": {
			backgroundColor: "#70b8f4",
			color: "white",
		},
	},
	topBox: {
		width: "100%",
		padding: "3px 10px",
		display: "flex",
		alignItems: "flex-start",
		marginTop: "10px",
		cursor: "pointer",
		marginBottom: "10px",
		"& > p": {
			fontSize: "15px",
			marginTop: "10px",
			color: "#696969",
		},
		"&:hover": {
			backgroundColor: "#edecec",
		},
	},
	topBoxSvgCont: {
		width: "120px",
		height: "70px",
		overflow: "hidden",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginRight: "10px",
		"& > img": {
			width: "95px",
			height: "95px",
		},
	},
	container: {
		width: "100%",
		height: "160px",
		"&::-webkit-scrollbar": {
			display: "none",
		},
	},
	timeSty: {
		fontSize: "10px",
		fontWeight: "500",
		color: "gray",
	},
	iconSty: {
		fontSize: "45px",
	},
	tbHeaderCell: {
		borderTop: "1px solid #e0e0e0",
		color: "#696969",
		fontSize: "15px",
		padding: "0px",
	},
	tbBodyCell: {
		border: "none",
		padding: "10px 5px",
	},
	statusCont: {
		fontSize: "11px",
		padding: "1px 4px",
		borderRadius: "3px",
	},
	valuesCont: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		padding: "0px 5px 7px",
		marginBottom: "15px",
		justifyContent: "space-between",
		"& div": {
			width: "24%",
			"& h3": {
				fontSize: "14px",
				fontWeight: "510",
				textAlign: "center",
				marginBottom: "8px",
			},
			"& p": {
				fontSize: "12px",
				fontWeight: "510",
				textAlign: "center",
			},
		},
	},
	skeletonCont: {
		paddingTop: "10px",
		width: "100%",
		height: "125px",
		overflowY: "auto",
		"&::-webkit-scrollbar": {
			display: "none",
		},
	},
	emptyCont: {
		display: "flex",
		width: "100%",
		height: "120px",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "center",
		overflow: "hidden",
		"& img": {
			height: "70px",
			width: "auto",
		},
		"& p": {
			fontSize: "12px",
			color: "gray",
			marginTop: "5px",
			textAlign: "center",
		},
	},
}));

const getFormatedData = (data) => {
	let formatedData = new Intl.NumberFormat("en-GB", {
		notation: "compact",
		compactDisplay: "short",
	}).format(data);
	return formatedData;
};

const PropertyManagementUnitCard = ({ teamId, units, setUnits, loader }) => {
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();

	const { teamDictionary } = useSelector((state) => state.team);
	let team = teamDictionary[teamId];

	const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

	const [createDialog, setCreateDialog] = useState(false);
	const [allTransactions, setAllTransactions] = useState([]);
	const [topData, setTopData] = useState({
		income: 0,
		occupied: "0% (0)",
		dues: 0,
	});

	useEffect(() => {
		if (units && units?.length > 0) {
			let occupiedVar = 0;
			units.forEach((unit) => {
				if (unit?.rentalRelation) {
					occupiedVar += 1;
				}
			});
			setTopData((prev) => {
				return {
					...prev,
					occupied: `${((occupiedVar * 100) / units?.length).toFixed(
						0
					)}% (${occupiedVar})`,
				};
			});
		}
	}, [units]);

	const getAllTransactions = async (rentals) => {
		const res = await Api.post("property/getalltransactions", {
			rentalIds: rentals,
		});
		if (res?.data) {
			setAllTransactions(groupByParent(res?.data));
			let dues = 0;
			let income = 0;
			(res?.data).forEach((doc) => {
				income += doc?.finalAmount || 0;
				if (doc?.status != "Paid") {
					dues += doc?.finalAmount || 0;
				}
			});
			setTopData((prev) => {
				return { ...prev, income: income, dues: dues };
			});
		}
	};

	useEffect(() => {
		let rentals = [];
		(units || []).forEach((doc) => {
			if (doc?.rentalRelation) {
				rentals.push(doc?.rentalRelation?._id);
			}
		});
		if (rentals.length > 0) {
			getAllTransactions(rentals);
		}
	}, [units]);

	const reloadRentalRelationUnits = async () => {
		await getRentalUnitsByTeam({ teamId: teamId })
			.then((data) => {
				setUnits(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			<Paper className={classes.cardContainer}>
				<div className={classes.cardTopHeading}>
					<h3>Property Manangement</h3>
					<IconButton
						className={classes.createBtn}
						onClick={() => {
							setCreateDialog(true);
						}}
						color="primary"
					>
						+
					</IconButton>
				</div>

				<Paper
					elevation={1}
					onClick={() => {
						if (teamId) {
							history.push("/propertymanagement/" + teamId);
						}
					}}
					className={classes.topBox}
				>
					<div className={classes.topBoxSvgCont}>
						<img src={propertyManagement} />
					</div>
					<p>
						Manage leases, automate rent collection and handle
						service requests from your tenants
					</p>
				</Paper>

				<div className={classes.valuesCont}>
					<div>
						<h3>{isExSmall ? "Income" : "Rental Income"}</h3>
						<p style={{ color: "#219653" }}>
							{`₹ ${getFormatedData(topData?.income)}`}
						</p>
					</div>
					<div>
						<h3>Rental Dues</h3>
						<p style={{ color: "#EB5757" }}>
							{`₹ ${getFormatedData(topData?.dues)}`}
						</p>
					</div>
					<div>
						<h3>Units</h3>
						<p style={{ color: "#219653" }}>{`${units?.length}`}</p>
					</div>
					<div>
						<h3>Occupied</h3>
						<p style={{ color: "#EB5757" }}>{`${topData?.occupied || 0
							}`}</p>
					</div>
				</div>

				{loader ? (
					<>
						<TableContainer style={{ width: "100%" }}>
							<Table
								stickyHeader
								aria-label="sticky table"
								size="small"
							>
								<TableHead>
									<TableRow
										style={{
											position: "relative",
											zIndex: "99",
											height: "40px",
										}}
									>
										<TableCell
											align="left"
											className={classes.tbHeaderCell}
											style={{ paddingLeft: "5px" }}
										>
											Unit Title
										</TableCell>
										<TableCell
											align="center"
											className={classes.tbHeaderCell}
											style={{
												padding: "0px",
												width: "90px",
											}}
										>
											Status
										</TableCell>
										<TableCell
											align="right"
											className={classes.tbHeaderCell}
											style={{
												paddingRight: "5px",
												width: "120px",
											}}
										>
											Market Rent
										</TableCell>
									</TableRow>
								</TableHead>
							</Table>
						</TableContainer>
						<div className={classes.skeletonCont}>
							<Skeleton
								variant="rect"
								style={{ width: "100%", marginBottom: "10px" }}
								height={50}
							/>
							<Skeleton
								variant="rect"
								style={{ width: "100%", marginBottom: "10px" }}
								height={50}
							/>
							<Skeleton
								variant="rect"
								style={{ width: "100%", marginBottom: "10px" }}
								height={50}
							/>
						</div>
					</>
				) : (
					<>
						{units && units?.length > 0 ? (
							<TableContainer className={classes.container}>
								<Table
									stickyHeader
									aria-label="sticky table"
									size="small"
								>
									<TableHead>
										<TableRow
											style={{
												position: "relative",
												zIndex: "99",
												height: "40px",
											}}
										>
											<TableCell
												align="left"
												className={classes.tbHeaderCell}
												style={{ paddingLeft: "5px" }}
											>
												Unit Title
											</TableCell>
											<TableCell
												align="center"
												className={classes.tbHeaderCell}
												style={{
													padding: "0px",
													width: "90px",
												}}
											>
												Status
											</TableCell>
											<TableCell
												align="right"
												className={classes.tbHeaderCell}
												style={{
													paddingRight: "5px",
													width: "120px",
												}}
											>
												Market Rent
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{units &&
											units?.length > 0 &&
											units.map((unit, index) => (
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={index}
													onClick={() => {
														const path = `/propertymanagement/${teamId}/${unit?._id}`;
														history.push(path);
													}}
													style={{
														cursor: "pointer",
													}}
												>
													<TableCell
														align="left"
														className={
															classes.tbBodyCell
														}
														style={{
															paddingLeft: "0px",
														}}
													>
														<p
															style={{
																fontSize:
																	"12px",
																color: "#333333",
																fontWeight:
																	"510",
																textTransform:
																	"capitalize",
															}}
														>
															<LessText
																limit={24}
																string={
																	unit?.name
																}
															/>
														</p>
													</TableCell>
													<TableCell
														align="center"
														className={
															classes.tbBodyCell
														}
														style={{
															padding: "0px",
															color: "#4F4F4F",
															fontSize: "13px",
															fontWeight: "510",
														}}
													>
														{unit?.rentalRelation
															? "Occupied"
															: "Vacant"}
													</TableCell>
													<TableCell
														align="right"
														className={
															classes.tbBodyCell
														}
														style={{
															padding: "10px 5px",
														}}
													>
														{unit?.marketRent || 0}
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						) : (
							<>
								<TableContainer style={{ width: "100%" }}>
									<Table
										stickyHeader
										aria-label="sticky table"
										size="small"
									>
										<TableHead>
											<TableRow
												style={{
													position: "relative",
													zIndex: "99",
													height: "40px",
												}}
											>
												<TableCell
													align="left"
													className={
														classes.tbHeaderCell
													}
													style={{
														paddingLeft: "5px",
													}}
												>
													Unit Title
												</TableCell>
												<TableCell
													align="center"
													className={
														classes.tbHeaderCell
													}
													style={{
														padding: "0px",
														width: "90px",
													}}
												>
													Status
												</TableCell>
												<TableCell
													align="right"
													className={
														classes.tbHeaderCell
													}
													style={{
														paddingRight: "5px",
														width: "110px",
													}}
												>
													Market Rent
												</TableCell>
											</TableRow>
										</TableHead>
									</Table>
								</TableContainer>
								<div className={classes.emptyCont}>
									<img src={emptyIcon} />
									<p>
										There is nothing to show here.
										<br />
										<span
											style={{
												color: theme.palette.primary
													.main,
												marginLeft: "3px",
												cursor: "pointer",
											}}
											onClick={() => {
												setCreateDialog(true);
											}}
										>
											Click to add new unit(s).
										</span>
									</p>
								</div>
							</>
						)}
					</>
				)}
			</Paper>

			<AddNewUnit
				openCreateDialog={createDialog}
				setOpenCreateDialog={setCreateDialog}
				team={team}
				reloadUnits={reloadRentalRelationUnits}
				numRentalUnits={units?.length || 0}
			/>
		</>
	);
};
export default PropertyManagementUnitCard;
