import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";

const UploadButton2 = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#F4F7FF",
        borderWidth: "2px",
        borderStyle: "dashed",
        p: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h5">Drag And Drop</Typography>
      <Typography variant="h5">or</Typography>
      <Button color="primary" variant="contained" size="large" disableElevation>
        Upload
      </Button>
    </Box>
  );
};

export default UploadButton2;
