import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper, TextField, Button } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { useParams, useHistory } from "react-router-dom";
import DashBoardCard from "./card/DashBoard.Card";
import construction from "../../Assets/constructionSVG.svg";
import capital from "../../Assets/capital.svg";
import materials from "../../Assets/materials.svg";
import rentingOutOwnPlace from "../../Assets/renting-out-own-place.svg";
import roommates from "../../Assets/roommates-relations.svg";
import sellHome from "../../Assets/sell-home.svg";
import NewLeadsCard from "./card/NewLeads.Card";
import ListedPropertyCard from "./card/ListedProperty.Card";
import { IoHomeOutline } from "react-icons/io5";
import { GiHouseKeys } from "react-icons/gi";
import { BsBuildings } from "react-icons/bs";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdConstruction } from "react-icons/md";
import { GiSofa } from "react-icons/gi";
import { BsFillPersonVcardFill } from "react-icons/bs";
import SearchIcon from "@material-ui/icons/Search";
import { TfiTarget } from "react-icons/tfi";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useMediaQuery } from "@material-ui/core";
import Api from "../../helpers/Api";
import { Box, Grid, Typography } from "@mui/material";
import StandardContainer from "../styled/generic/StandardContainer";
import FormBox from "../styled/generic/FormBox";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import ListingCard from "../styled/generic/ListingCard";
import { CallMadeOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  bodyCont: {
    width: "100%",
  },
  mainCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  leftSide: {
    width: "calc(100% - 450px)",
    padding: "20px",
    paddingRight: "0px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingRight: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5px",
    },
  },
  left_cards: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
    },
  },
  rightSide: {
    width: "430px",
    padding: "20px",
    paddingLeft: "0px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      paddingLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5px",
    },
  },
  searchCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "90px 0px 50px",
  },
  searchPaper: {
    width: "100%",
    padding: "20px 40px",
    backgroundColor: "rgba(128, 128, 128, 0.3)",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  iconsCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  iconsSty: {
    position: "absolute",
    top: "-85px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "6px",
    boxShadow:
      "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "5px",
      top: "-75px",
    },
  },
  iconTextCont: {
    width: "94px",
    padding: "10px 16px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    cursor: "pointer",
    "& p": {
      fontSize: "12px",
      fontWeight: "500",
      color: "gray",
      marginTop: "5px",
      textAlign: "center",
    },
    "&:hover": {
      transform: "scale(1.05)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "68px",
      padding: "10px 4px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "45px",
      margin: "3px",
      padding: "5px 2px",
      "& p": {
        fontSize: "9px",
        fontWeight: "450",
      },
    },
  },
  inputAndBtnCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "45px",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
    },
  },
  inputText: {
    width: "calc(100% - 150px)",
    backgroundColor: "white",
    borderRadius: "5px",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 75px)",
    },
  },
  noBorder: {
    border: "none",
  },
  iconCont: {
    height: "35px",
    width: "50px",
    textAlign: "center",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      height: "25px",
      width: "29px",
    },
  },
  unSelectedGray: {
    height: "30px",
    fontSize: "30px",
    position: "absolute",
    top: "0px",
    left: "9px",
    transition: "all .5s ease-in-out",
    [theme.breakpoints.down("xs")]: {
      height: "20px",
      fontSize: "20px",
      left: "5px",
    },
  },
  selectedGray: {
    height: "30px",
    fontSize: "30px",
    position: "absolute",
    top: "-35px",
    left: "9px",
    transition: "all .5s ease-in-out",
    [theme.breakpoints.down("xs")]: {
      height: "20px",
      fontSize: "20px",
      left: "5px",
    },
  },
  unSelectedBlue: {
    height: "30px",
    fontSize: "30px",
    position: "absolute",
    top: "35px",
    color: theme.palette.primary.main,
    left: "9px",
    transition: "all .3s ease-in-out",
    [theme.breakpoints.down("xs")]: {
      height: "20px",
      fontSize: "20px",
      left: "5px",
    },
  },
  selectedBlue: {
    height: "30px",
    fontSize: "30px",
    position: "absolute",
    top: "0px",
    color: theme.palette.primary.main,
    left: "9px",
    transition: "all .3s ease-in-out",
    [theme.breakpoints.down("xs")]: {
      height: "20px",
      fontSize: "20px",
      left: "5px",
    },
  },
  unselectedBorderBottom: {
    width: "0%",
    height: "2px",
    backgroundColor: theme.palette.primary.main,
    transition: "all .3s ease-in-out",
    [theme.breakpoints.down("xs")]: {
      height: "1px",
    },
  },
  selectedBorderBottom: {
    width: "100%",
    height: "2px",
    backgroundColor: theme.palette.primary.main,
    transition: "all .3s ease-in-out",
    [theme.breakpoints.down("xs")]: {
      height: "1px",
    },
  },
}));

const Marketplace = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();

  const { user } = useSelector((state) => state.auth);

  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("buyProperties");

  const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const onSubmitSearch = (e) => {
    e.preventDefault();
  };

  const createListing = async (listingType) => {
    try {
      const obj = {
        modelType: listingType,
        user: user,
      };
      const res = await Api.post("listing/createListing", obj);
      if (res?.status === 200) {
        const listingId = res?.results._id;
        history.push(`/index/${listingType}/${listingId}`);
      }
    } catch (error) {
      console.error("Error fetching listing forms:", error);
    }
  };

  const [categoryData, setCategoryData] = useState([
    {
      imgUrl: "/icons/app/renticon.svg",
      title: "Rent Lease",
      caption: "100 Active Listings",
      type: "Rent",
    },
    {
      imgUrl: "/icons/app/sellicon.svg",
      title: "Buy",
      caption: "100 Active Listings",
      type: "Sell",
    },
    {
      imgUrl: "/icons/app/roommateicon.svg",
      title: "For Roommates",
      caption: "100 Active Listings",
      type: "Roommate",
    },
    {
      imgUrl: "/icons/app/professionalicon.svg",
      title: "Professionals",
      caption: "100 Active Listings",
      type: "Job",
    },
    {
      imgUrl: "/icons/app/investmenticon.svg",
      title: "Investments",
      caption: "100 Active Listings",
      type: "Investment",
    },
    {
      imgUrl: "/icons/app/procurementicon.svg",
      title: "Procurements",
      caption: "100 Active Listings",
      type: "Tender",
    },
  ]);

  const ActivityCard = ({ label, value, backgroundColor }) => {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          p: 2,
          backgroundColor: backgroundColor,
          cursor: "pointer",
        }}
        onClick={() => history.push("/marketplace/search")}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">{value}</Typography>
          <Box>
            <CallMadeOutlined color="primary" />
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">{label}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <StandardContainer>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FormBox label="Explore all Categories">
            <Grid sx={{ mt: 3 }} container spacing={2}>
              {categoryData?.map((item, index) => (
                <Grid xs={4} key={index}>
                  <Box
                    sx={{
                      borderRadius: "12px",
                      padding: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mx: 4,
                      mr: 2,
                      cursor: "pointer",
                      "&:hover": {
                        boxShadow: "0px 12px 48px 0px rgba(0, 44, 109, 0.10)",
                      },
                    }}
                  >
                    <Box sx={{ mr: 4 }}>
                      <img src={item.imgUrl} width={60} />
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "600" }}
                        color="primary"
                      >
                        {item.title}
                      </Typography>
                      <Typography variant="body1">{item.caption}</Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </FormBox>
        </Grid>
        <Grid item xs={4}>
          <StandardAppContainerRounded>
            <FormBox label="Your Activities"></FormBox>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ActivityCard
                  label="Shortlisted"
                  value="1"
                  backgroundColor="#FDD79F4D"
                />
              </Grid>
              <Grid item xs={6}>
                <ActivityCard
                  label="Viewed"
                  value="20"
                  backgroundColor="#C4D1FF4D"
                />
              </Grid>
              <Grid item xs={6}>
                <ActivityCard
                  label="Contacted"
                  value="10"
                  backgroundColor="#8BCCFA4D"
                />
              </Grid>
              <Grid item xs={6}>
                <ActivityCard
                  label="Applications"
                  value="04"
                  backgroundColor="#E1A0FF4D"
                />
              </Grid>
            </Grid>
          </StandardAppContainerRounded>
        </Grid>
      </Grid>
      <FormBox label="New Listings">
        <Grid container spacing={2}>
          {[1, 2, 3, 4].map((item) => (
            <Grid item xs={3}>
              <ListingCard />
            </Grid>
          ))}
        </Grid>
      </FormBox>
    </StandardContainer>
  );
};

export default Marketplace;

// return (
//     <div className={classes.bodyCont} >
//         <div className={classes.mainCont} >
//             <div className={classes.leftSide} >
//                 <div className={classes.searchCont} >
//                     <Paper className={classes.searchPaper} >
//                         <div className={classes.iconsCont} >
//                             <div className={classes.iconsSty} >

//                                 <div className={classes.iconTextCont} onClick={() => { setSearchType("plotLand") }} >
//                                     <div className={classes.iconCont} >
//                                         <GiTakeMyMoney className={searchType === "plotLand" ? classes.selectedGray : classes.unSelectedGray} />
//                                         <GiTakeMyMoney className={searchType === "plotLand" ? classes.selectedBlue : classes.unSelectedBlue} />
//                                     </div>
//                                     <p>Invest in projects</p>
//                                     <div style={{ width: "100%" }} >
//                                         <div className={searchType === "plotLand" ? classes.selectedBorderBottom : classes.unselectedBorderBottom} ></div>
//                                     </div>
//                                 </div>

//                                 <div className={classes.iconTextCont} onClick={() => { setSearchType("findAgents") }} >
//                                     <div className={classes.iconCont} >
//                                         <BsFillPersonVcardFill className={searchType === "findAgents" ? classes.selectedGray : classes.unSelectedGray} />
//                                         <BsFillPersonVcardFill className={searchType === "findAgents" ? classes.selectedBlue : classes.unSelectedBlue} />
//                                     </div>
//                                     <p>Find Professionals</p>
//                                     <div style={{ width: "100%" }} >
//                                         <div className={searchType === "findAgents" ? classes.selectedBorderBottom : classes.unselectedBorderBottom} ></div>
//                                     </div>
//                                 </div>

//                                 <div className={classes.iconTextCont} onClick={() => { setSearchType("newProjects") }} >
//                                     <div className={classes.iconCont} >
//                                         <MdConstruction className={searchType === "newProjects" ? classes.selectedGray : classes.unSelectedGray} />
//                                         <MdConstruction className={searchType === "newProjects" ? classes.selectedBlue : classes.unSelectedBlue} />
//                                     </div>
//                                     <p>Procurement Solution</p>
//                                     <div style={{ width: "100%" }} >
//                                         <div className={searchType === "newProjects" ? classes.selectedBorderBottom : classes.unselectedBorderBottom} ></div>
//                                     </div>
//                                 </div>

//                                 <div className={classes.iconTextCont} onClick={() => { setSearchType("buyProperties") }}  >
//                                     <div className={classes.iconCont} >
//                                         <IoHomeOutline className={searchType === "buyProperties" ? classes.selectedGray : classes.unSelectedGray} />
//                                         <IoHomeOutline className={searchType === "buyProperties" ? classes.selectedBlue : classes.unSelectedBlue} />
//                                     </div>
//                                     <p>Buy Properties</p>
//                                     <div style={{ width: "100%" }} >
//                                         <div className={searchType === "buyProperties" ? classes.selectedBorderBottom : classes.unselectedBorderBottom} ></div>
//                                     </div>
//                                 </div>

//                                 <div className={classes.iconTextCont} onClick={() => { setSearchType("rentProperties") }} >
//                                     <div className={classes.iconCont} >
//                                         <GiHouseKeys className={searchType === "rentProperties" ? classes.selectedGray : classes.unSelectedGray} />
//                                         <GiHouseKeys className={searchType === "rentProperties" ? classes.selectedBlue : classes.unSelectedBlue} />
//                                     </div>
//                                     <p>Rent/Book Properties</p>
//                                     <div style={{ width: "100%" }} >
//                                         <div className={searchType === "rentProperties" ? classes.selectedBorderBottom : classes.unselectedBorderBottom} ></div>
//                                     </div>
//                                 </div>

// {/*
//                                 <div className={classes.iconTextCont} onClick={() => { setSearchType("commercialProperties") }} >
//                                     <div className={classes.iconCont} >
//                                         <GiSofa className={searchType === "commercialProperties" ? classes.selectedGray : classes.unSelectedGray} />
//                                         <GiSofa className={searchType === "commercialProperties" ? classes.selectedBlue : classes.unSelectedBlue} />
//                                     </div>
//                                     <p>Find Roommates</p>
//                                     <div style={{ width: "100%" }} >
//                                         <div className={searchType === "commercialProperties" ? classes.selectedBorderBottom : classes.unselectedBorderBottom} ></div>
//                                     </div>
//                                 </div> */}

//                             </div>
//                         </div>

//                         <form className={classes.inputAndBtnCont} onSubmit={onSubmitSearch} >
//                             <TextField
//                                 variant="outlined"
//                                 placeholder={"Search by Location"}
//                                 value={searchText}
//                                 size={isExSmall ? "small" : "medium"}
//                                 InputProps={{
//                                     classes: { notchedOutline: classes.noBorder },
//                                     endAdornment: (<InputAdornment position="end" >
//                                         <Button
//                                             variant="contained"
//                                             color="primary"
//                                             type="submit"
//                                             style={isExSmall ? { minWidth: "35px" } : {}}
//                                             size={isExSmall ? "small" : "medium"}
//                                             startIcon={isExSmall ? null : <TfiTarget />}
//                                         >
//                                             {isExSmall ? <TfiTarget /> : "Near me"}
//                                         </Button>
//                                     </InputAdornment>)
//                                 }}
//                                 onChange={(e) => { setSearchText(e.target.value); }}
//                                 className={classes.inputText}
//                             />
//                             <Button
//                                 variant="contained"
//                                 color="primary"
//                                 type="submit"
//                                 startIcon={isExSmall ? null : <SearchIcon style={{ fontSize: "30px" }} />}
//                                 style={isExSmall ? { padding: "5px 10px" } : { padding: "10px 19px", fontSize: "18px" }}
//                             >
//                                 {isExSmall ? <SearchIcon style={{ fontSize: "25px" }} /> : "Search"}
//                             </Button>
//                         </form>
//                     </Paper>
//                 </div>

//                 <div className={classes.left_cards} >
//                     <DashBoardCard
//                         imgPath={capital}
//                         createListing={createListing}
//                         listingType={"Investment"}
//                         headerText={"Raise Money"}
//                         subText={"Get equity / debt funding from institutional / individual investors"}
//                     />

//                     <DashBoardCard
//                         imgPath={construction}
//                         createListing={createListing}
//                         listingType={"Job"}
//                         headerText={"Job listing"}
//                         subText={"Post a job to find people on you network"}
//                     />

//                     <DashBoardCard
//                         imgPath={materials}
//                         createListing={createListing}
//                         listingType={"Materials"}
//                         headerText={"List Inventory"}
//                         subText={"Become a vendor and sell to our network"}
//                     />

//                     <DashBoardCard
//                         imgPath={sellHome}
//                         createListing={createListing}
//                         listingType={"SellListing"}
//                         headerText={"List for Sale"}
//                         subText={"Sell properties, flats, Plots to our network"}
//                     />

//                     <DashBoardCard
//                         imgPath={rentingOutOwnPlace}
//                         createListing={createListing}
//                         listingType={"RentListing"}
//                         headerText={"List for Rent/Lease"}
//                         subText={"Put your residential / commercial property on long / short term lease"}
//                     />

//                     {/* <DashBoardCard
//                         imgPath={roommates}
//                         createListing={createListing}
//                         listingType={"Roommate"}
//                         headerText={"List for Roommates"}
//                         subText={"Find roommates on our network"}
//                     /> */}
//                 </div>
//             </div>

//             <div className={classes.rightSide} >
//                 <ListedPropertyCard />
//                 <NewLeadsCard />
//             </div>
//         </div>
//     </div>
// );
