import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import { ImProfile } from "react-icons/im";
import { IoLanguageSharp } from "react-icons/io5";
import { MdCastForEducation } from "react-icons/md";
import { GiCrane } from "react-icons/gi";
import { TbCertificate } from "react-icons/tb";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaAward } from "react-icons/fa";
import CertificateEdit from "./Certifications.Edit";
import ProjectEdit from "./Project.Edit";
import BasicInfoEdit from "./BasicInfo.Edit";
import EducationEdit from "./Education.Edit";
import ExperienceEdit from "./Experience.Edit";
import LanguagesEdit from "./Languages.Edit";
import BusinessEmail from "./BusniessEmail";

const useStyles = makeStyles((theme) => ({
  mainEditCont: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    borderTop: "1px solid #e4e7e7",
  },
  leftNavBar: {
    width: "280px",
    height: "100%",
    borderRight: "1px solid #e4e7e7",
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  rightNavBar: {
    width: "calc(100% - 280px)",
    height: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 200px)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  optionBtn: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: "0px",
    fontSize: "16px",
    opacity: "0.6",
    justifyContent: "flex-start",
    paddingLeft: "20px",
    height: "52px",
    borderTop: "1px solid #e4e7e7",
    "& p": {
      textTransform: "capitalize",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
      paddingLeft: "4px",
      "& p": {
        textTransform: "capitalize",
        textAlign: "left",
        width: "160px",
      },
    },
  },
  sideTopBar: {
    height: "70px",
  },
}));

export default function AccountEdit({
  setOpenEditView,
  profileEditView,
  setProfileEditView,
}) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);

  return (
    <div className={classes.mainEditCont}>
      <div className={classes.leftNavBar}>
        <Button
          className={classes.optionBtn}
          onClick={() => {
            setProfileEditView("basicInfo");
          }}
          style={
            profileEditView === "basicInfo"
              ? { opacity: "1", borderTop: "none" }
              : { borderTop: "none" }
          }
        >
          <ImProfile style={{ fontSize: "19px", marginRight: "15px" }} />{" "}
          <p>Basic Information</p>
        </Button>
        <Button
          className={classes.optionBtn}
          onClick={() => {
            setProfileEditView("experience");
          }}
          style={profileEditView === "experience" ? { opacity: "1" } : {}}
        >
          <FaAward style={{ fontSize: "19px", marginRight: "15px" }} />{" "}
          <p>Experience</p>
        </Button>
        <Button
          className={classes.optionBtn}
          onClick={() => {
            setProfileEditView("project");
          }}
          style={profileEditView === "project" ? { opacity: "1" } : {}}
        >
          <GiCrane style={{ fontSize: "21px", marginRight: "15px" }} />{" "}
          <p>Projects</p>
        </Button>
        <Button
          className={classes.optionBtn}
          onClick={() => {
            setProfileEditView("education");
          }}
          style={profileEditView === "education" ? { opacity: "1" } : {}}
        >
          <MdCastForEducation
            style={{ fontSize: "19px", marginRight: "15px" }}
          />{" "}
          <p>Education</p>
        </Button>
        <Button
          className={classes.optionBtn}
          onClick={() => {
            setProfileEditView("language");
          }}
          style={profileEditView === "language" ? { opacity: "1" } : {}}
        >
          <IoLanguageSharp style={{ fontSize: "19px", marginRight: "15px" }} />{" "}
          <p>Languages</p>
        </Button>
        <Button
          className={classes.optionBtn}
          style={
            profileEditView === "certificat"
              ? { opacity: "1", borderBottom: "1px solid #e4e7e7" }
              : { borderBottom: "1px solid #e4e7e7" }
          }
          onClick={() => {
            setProfileEditView("certificat");
          }}
        >
          <TbCertificate style={{ fontSize: "19px", marginRight: "15px" }} />{" "}
          <p>Licenses & Certifications</p>
        </Button>
        <Button
          className={classes.optionBtn}
          style={
            profileEditView === "business"
              ? { opacity: "1", borderBottom: "1px solid #e4e7e7" }
              : { borderBottom: "1px solid #e4e7e7" }
          }
          onClick={() => {
            setProfileEditView("business");
          }}
        >
          <MdOutlineMailOutline
            style={{ fontSize: "19px", marginRight: "15px" }}
          />{" "}
          <p>Business Emails</p>
        </Button>
      </div>
      <div className={classes.rightNavBar}>
        {profileEditView === "basicInfo" && (
          <BasicInfoEdit setOpenEditView={setOpenEditView} />
        )}
        {profileEditView === "certificat" && (
          <CertificateEdit setOpenEditView={setOpenEditView} />
        )}
        {profileEditView === "education" && (
          <EducationEdit setOpenEditView={setOpenEditView} />
        )}
        {profileEditView === "experience" && (
          <ExperienceEdit setOpenEditView={setOpenEditView} />
        )}
        {profileEditView === "language" && (
          <LanguagesEdit setOpenEditView={setOpenEditView} />
        )}
        {profileEditView === "project" && (
          <ProjectEdit setOpenEditView={setOpenEditView} />
        )}
        {profileEditView === "business" && (
          <BusinessEmail setOpenEditView={setOpenEditView} />
        )}
      </div>
    </div>
  );
}
