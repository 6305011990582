import React from "react";

const ListingTableVisiblityCell = ({ isPrivate }) => {
  return (
    <span style={{ fontWeight: "bold", color: "#2D76E0" }}>
      • {isPrivate ? "Private" : "Public"}
    </span>
  );
};

export default ListingTableVisiblityCell;
