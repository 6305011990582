import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  getProfileForEntitySearch,
  createAndUpdateFinalcialRelation,
} from "./api.call";
import NormalDrawer from "./NormalDrawer.js";
import "./common.css";
import PaginatedEntityDropdown from "./PaginatedEntityDropdown.jsx";

const useStyles = makeStyles((theme) => ({
  mainDialogCont: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px",
    },
  },
  bodyCont: {
    width: "100%",
    height: "calc(100% - 55px)",
    overflow: "hidden",
  },
  bottomAct: {
    width: "100%",
    height: "50px",
    padding: "0px 7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: "1px solid #ececec",
  },
  searchCont: {
    height: "70px",
    padding: "0px 10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

//required************
//1.entity = current entity, like "Project", "User" or "Organization"***(require)***
//2.curEntityId = id of that entity(_id)***(require)***
//3.onSave = function will return selected item(if multiple is true then it will return array of profile and if if not then a single profile)***(require)****
//4.open = to open and close drawer
//5.setOpen = to open and close drawer
//6.title = title which will show on the top of the drawer

//optional
//1.searchParameter = through which entityes and roles you want to search. follow structher of "defaultSearchParameter",
//2.givenProfileIdArr = which profiles contact you want to see in search reasult
//3.givenNotAllowedProfileId = which profiles you don't want to see in the search reasult
//4.noFilter = if you don't want to show filters to the users, just make it "true"
//5.multiple = multiple select item
//6.onClickOptionHideOptions = if you make it true then when you click any option on dropdown, all the options will hide after that click
//7.financialRelationType = financial relation type
//8.selectedProfile = all selected Profiles, it can be a single object or array of object

export default function SearchEntityDialog({
  givenFilterOption,
  givenProfileIdArr = [],
  noFilter = false,
  entity,
  curEntityId,
  multiple = false,
  onSave,
  open,
  setOpen,
  orgtype, 
  financialRelationType = "Customer",
  title,
  selectedProfile,
  noPalCreate = false,
  notCreateFinancialRelation = false,
}) {

  const classes = useStyles();
  const theme = useTheme();

  const { user } = useSelector((state) => state.auth);

  const [selectedEntities, setSelectedEntities] = useState([]);
  const [mainProfile, setMainProfile] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (selectedProfile) {
      if (Array.isArray(selectedProfile)) {
        setSelectedEntities(
          selectedProfile.map((s) => {
            return {
              value: s?._id,
              label: s?.parent?.displayName,
              data: s,
            };
          })
        );
      } else {
        setSelectedEntities({
          value: selectedProfile?._id,
          label: selectedProfile?.parent?.displayName,
          data: selectedProfile,
        });
      }
    }
  }, [selectedProfile]);

  useEffect(() => {
    if (curEntityId && entity) {
      getProfileForEntitySearch({
        entityId: curEntityId,
        type: entity,
      })
        .then((data) => {
          const currentProfile = data?.currentProfile;
          setMainProfile(currentProfile);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [curEntityId, entity]);

  const findOrAddRel = async () => {
    if (notCreateFinancialRelation) {
      if (multiple) {
        onSave(selectedEntities.map((s) => s?.data));
      } else {
        onSave(selectedEntities?.data);
      }
      setOpen(false);
    } else {
      setLoader(true);
      const relationProfileIds = multiple ? selectedEntities.map((entityProfile) => entityProfile?.data?._id) : [selectedEntities?.data?._id];
      const relObj = {
        mainProfile: mainProfile,
        shared: [mainProfile],
        relationProfileIds: relationProfileIds,
        notOnPlatformProfileIds: [],
        type: financialRelationType ? financialRelationType : "Customer",
        parent: curEntityId,
        parentModelName: entity,
        addedBy: user?.profile,
        user: user?._id,
      };
      await createAndUpdateFinalcialRelation(relObj)
        .then((data) => {
          setLoader(false);
          if (multiple) {
            onSave(selectedEntities.map((s) => s?.data));
          } else {
            onSave(selectedEntities?.data);
          }
          setSelectedEntities(null);
          setOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <NormalDrawer
      openDrawer={open}
      setOpenDrawer={setOpen}
      anchor={"right"}
      title={title}
      width={"40vw"}
      content={
        <div className={classes.mainDialogCont}>
          <div className={classes.bodyCont}>
            <div className={classes.searchCont}>
              <PaginatedEntityDropdown
                value={selectedEntities}
                onChange={(value) => {
                  setSelectedEntities(value);
                }}
                orgtype={orgtype}
                isMulti={multiple}
                curEntityId={curEntityId}
                entity={entity}
                givenProfileIdArr={givenProfileIdArr}
                financialRelationType={financialRelationType}
                palCreate={!noPalCreate}
                givenFilterOption={givenFilterOption}
                noFilter={noFilter}
              />
            </div>
          </div>
          <div style={{ width: "100%", height: "5px" }}>
            {loader && <LinearProgress />}
          </div>
          <div className={classes.bottomAct}>
            <Button
              onClick={findOrAddRel}
              variant="contained"
              size="small"
              color="primary"
              disabled={
                selectedEntities?.data?._id ||
                (selectedEntities && selectedEntities.length > 0)
                  ? false
                  : true
              }
            >
              Save & Close
            </Button>
          </div>
        </div>
      }
    />
  );
}
