import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, InputAdornment } from "@material-ui/core";
import { useTheme } from "styled-components";
import SearchIcon from '@material-ui/icons/Search';
import { useParams, useHistory } from 'react-router-dom';
import ProfileLibrarayAccordion from "./Profile.Library.Accordion";

const useStyles = makeStyles((theme) => ({
    searchCont: {
        width: "100%",
        height: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    profilesCont: {
        width: "100%",
        height: "calc(100% - 80px)",
        overflowX: "hidden",
        overflowY: "auto"
    }
}));

const LibraryImportAccordionBody = ({
    profilesArr, selectedLibrarIds,
    setSelectedLibrarIds, selectedLibrarIdProfileIdObjArr,
    setSelectedLibrarIdProfileIdObjArr,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();

    const [showProfiles, setShowProfiles] = useState([])
    const [searchStr, setSearchStr] = useState("")

    useEffect(() => {
        setShowProfiles(profilesArr)
    }, [profilesArr])


    useEffect(() => {
        let filteredArr = [];
        if (searchStr && searchStr.length > 0) {
            profilesArr.map((profile) => {
                const patt = new RegExp(searchStr, "i");
                const res = patt.test(profile?.parent?.displayName);
                if (res) {
                    filteredArr.push(profile);
                }
            })
        } else {
            filteredArr = [...profilesArr]
        }
        setShowProfiles(filteredArr)
    }, [searchStr])


    return (
        <>
            <div className={classes.searchCont} >
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder={"Enter name or username"}
                    autoComplete='off'
                    value={searchStr}
                    style={{ width: "75%" }}
                    onChange={(e) => {
                        setSearchStr(e.target.value)
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </div>
            <div className={classes.profilesCont} >
                {showProfiles && showProfiles.map((profile) => (
                    <ProfileLibrarayAccordion
                        profile={profile}
                        selectedLibrarIds={selectedLibrarIds}
                        setSelectedLibrarIds={setSelectedLibrarIds}
                        selectedLibrarIdProfileIdObjArr={selectedLibrarIdProfileIdObjArr}
                        setSelectedLibrarIdProfileIdObjArr={setSelectedLibrarIdProfileIdObjArr}
                    />
                ))}
            </div>
        </>
    );
};

export default LibraryImportAccordionBody;