import configObject from "../../../config";

function planningGetLink(path) {
    if (configObject.mode === 'prod') {
        return `https://planning.reallist.ai${path}`;
    }
    return path; // For dev mode or any other mode, return the path as is
}

function accountingLink(path) {
    if (configObject.mode === 'prod') {
        return `https://accounts.reallist.ai${path}`;
    }
    return path; // For dev mode or any other mode, return the path as is
}

function estimationLink(path) {
    if (configObject.mode === 'prod') {
        return `https://estimation.reallist.ai${path}`;
    }
    return path; // For dev mode or any other mode, return the path as is
}

function operationsLink(path) {
    if (configObject.mode === 'prod') {
        return `https://operations.reallist.ai${path}`;
    }
    return path; // For dev mode or any other mode, return the path as is
}

const approval = {
    "InvoiceRejectedForNextStage": (activity) => ({
        name: 'Invoice Fully rejected',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/invoice/edit/${activity?.parent?._id}`)
    }),
    "InvoiceApprovedForNextStage": (activity) => ({
        name: 'Invoice Approved for next stage',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/invoice/edit/${activity?.parent?._id}`)
    }),
    "InvoiceRejectedInSameStage": (activity) => ({
        name: 'Invoice rejected',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/invoice/edit/${activity?.parent?._id}`)
    }),
    "InvoiceApprovedInSameStage": (activity) => ({
        name: 'Invoice approved',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/invoice/edit/${activity?.parent?._id}`)
    }),
    "CommentAddForInvoiceApprovalProcess": (activity) => ({
        name: 'Comment added in invoice approval',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/invoice/edit/${activity?.parent?._id}`)
    }),
    "BillRejectedForNextStage": (activity) => ({
        name: 'Bill fully rejected',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/bill/edit/${activity?.parent?._id}`)
    }),
    "BillApprovedForNextStage": (activity) => ({
        name: 'Bill approved for next stage',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/bill/edit/${activity?.parent?._id}`)
    }),
    "BillRejectedInSameStage": (activity) => ({
        name: 'Bill rejected',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/bill/edit/${activity?.parent?._id}`)
    }),
    "BillApprovedInSameStage": (activity) => ({
        name: 'Bill approved',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/bill/edit/${activity?.parent?._id}`)
    }),
    "CommentAddForBillApprovalProcess": (activity) => ({
        name: 'Comment added in bill approval process',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/bill/edit/${activity?.parent?._id}`)
    }),
    "DebitNoteRejectedForNextStage": (activity) => ({
        name: 'Debit note fully rejected',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/debit-note/edit/${activity?.parent?._id}`)
    }),
    "DebitNoteApprovedForNextStage": (activity) => ({
        name: 'Debit note approved for next stage',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/debit-note/edit/${activity?.parent?._id}`)
    }),
    "DebitNoteRejectedInSameStage": (activity) => ({
        name: 'Debit note rejected',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/debit-note/edit/${activity?.parent?._id}`)
    }),
    "DebitNoteApprovedInSameStage": (activity) => ({
        name: 'Debit note approved',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/debit-note/edit/${activity?.parent?._id}`)
    }),
    "CommentAddForDebitNoteApprovalProcess": (activity) => ({
        name: 'Comment added in debit note approval process',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/debit-note/edit/${activity?.parent?._id}`)
    }),
    "CreditNoteRejectedForNextStage": (activity) => ({
        name: 'Credit note fully rejected',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/credit-note/edit/${activity?.parent?._id}`)
    }),
    "CreditNoteApprovedForNextStage": (activity) => ({
        name: 'Credit note approved for next stage',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/credit-note/edit/${activity?.parent?._id}`)
    }),
    "CreditNoteRejectedInSameStage": (activity) => ({
        name: 'Credit note rejected',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/credit-note/edit/${activity?.parent?._id}`)
    }),
    "CreditNoteApprovedInSameStage": (activity) => ({
        name: 'Credit note approved',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/credit-note/edit/${activity?.parent?._id}`)
    }),
    "CommentAddForCreditNoteApprovalProcess": (activity) => ({
        name: 'Comment added in credit note approval process',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/credit-note/edit/${activity?.parent?._id}`)
    }),
    "BillTemplateRejectedForNextStage": (activity) => ({
        name: 'Bill template fully rejected',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/bill-generator/edit/${activity?.parent?._id}`)
    }),
    "BillTemplateApprovedForNextStage": (activity) => ({
        name: 'Bill template approved for next stage',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/bill-generator/edit/${activity?.parent?._id}`)
    }),
    "BillTemplateRejectedInSameStage": (activity) => ({
        name: 'Bill template rejected',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/bill-generator/edit/${activity?.parent?._id}`)
    }),
    "BillTemplateApprovedInSameStage": (activity) => ({
        name: 'Bill template approved',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/bill-generator/edit/${activity?.parent?._id}`)
    }),
    "CommentAddForBillTemplateApprovalProcess": (activity) => ({
        name: 'Comment added in bill template approval process',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/bill-generator/edit/${activity?.parent?._id}`)
    }),
    "InvoiceTemplateRejectedForNextStage": (activity) => ({
        name: 'Invoice template fully rejected',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/invoice-generator/edit/${activity?.parent?._id}`)
    }),
    "InvoiceTemplateApprovedForNextStage": (activity) => ({
        name: 'Invoice template approved for next stage',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/invoice-generator/edit/${activity?.parent?._id}`)
    }),
    "InvoiceTemplateRejectedInSameStage": (activity) => ({
        name: 'Invoice template rejected',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/invoice-generator/edit/${activity?.parent?._id}`)
    }),
    "InvoiceTemplateApprovedInSameStage": (activity) => ({
        name: 'Invoice template approved',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/invoice-generator/edit/${activity?.parent?._id}`)
    }),
    "CommentAddForInvoiceTemplateApprovalProcess": (activity) => ({
        name: 'Comment added in invoice template approval process',
        link: accountingLink(`/finance/${activity?.parent?.firstPartyWallet}/invoice-generator/edit/${activity?.parent?._id}`)
    }),
    "FinancialCertificateRejectedForNextStage": (activity) => ({
        name: 'Financial certificate fully rejected',
        link: accountingLink(`finance/${activity?.parent?.walletId}/workorder-certificate/edit/${activity?.parent?._id}`)
    }),
    "FinancialCertificateApprovedForNextStage": (activity) => ({
        name: 'Financial certificate approved for next stage',
        link: accountingLink(`finance/${activity?.parent?.walletId}/workorder-certificate/edit/${activity?.parent?._id}`)
    }),
    "FinancialCertificateRejectedInSameStage": (activity) => ({
        name: 'Financial certificate rejected',
        link: accountingLink(`finance/${activity?.parent?.walletId}/workorder-certificate/edit/${activity?.parent?._id}`)
    }),
    "FinancialCertificateApprovedInSameStage": (activity) => ({
        name: 'Financial certificate approved',
        link: accountingLink(`finance/${activity?.parent?.walletId}/workorder-certificate/edit/${activity?.parent?._id}`)
    }),
    "CommentAddForFinancialCertificateApprovalProcess": (activity) => ({
        name: 'Comment added in financial certificate approval process',
        link: accountingLink(`finance/${activity?.parent?.walletId}/workorder-certificate/edit/${activity?.parent?._id}`)
    }),
    "ObservationRejectedForNextStage": (activity) => ({
        name: 'Observation fully rejected',
        link: planningGetLink(`/observation/preview/${activity?.parent?._id}`)
    }),
    "ObservationApprovedForNextStage": (activity) => ({
        name: 'Observation approved for next stage',
        link: planningGetLink(`/observation/preview/${activity?.parent?._id}`)
    }),
    "ObservationRejectedInSameStage": (activity) => ({
        name: 'Observation rejected',
        link: planningGetLink(`/observation/preview/${activity?.parent?._id}`)
    }),
    "ObservationApprovedInSameStage": (activity) => ({
        name: 'Observation approved',
        link: planningGetLink(`/observation/preview/${activity?.parent?._id}`)
    }),
    "CommentAddForObservationApprovalProcess": (activity) => ({
        name: 'Comment added in observation approval process',
        link: planningGetLink(`/observation/preview/${activity?.parent?._id}`)
    }),
    "BudgetCostRejectedForNextStage": (activity) => ({
        name: 'Budget cost fully rejected',
        link: estimationLink(`/budget-analysis/budget-cost/edit/${activity?.parent?._id}`)
    }),
    "BudgetCostApprovedForNextStage": (activity) => ({
        name: 'Budget cost approved for next stage',
        link: estimationLink(`/budget-analysis/budget-cost/edit/${activity?.parent?._id}`)
    }),
    "BudgetCostRejectedInSameStage": (activity) => ({
        name: 'Budget cost rejected',
        link: estimationLink(`/budget-analysis/budget-cost/edit/${activity?.parent?._id}`)
    }),
    "BudgetCostApprovedInSameStage": (activity) => ({
        name: 'Budget cost approved',
        link: estimationLink(`/budget-analysis/budget-cost/edit/${activity?.parent?._id}`)
    }),
    "CommentAddForBudgetCostApprovalProcess": (activity) => ({
        name: 'Comment added in budget cost approval process',
        link: estimationLink(`/budget-analysis/budget-cost/edit/${activity?.parent?._id}`)
    }),
    "BillOfQuantitiesRejectedForNextStage": (activity) => ({
        name: 'Bill of quantities fully rejected',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "BillOfQuantitiesApprovedForNextStage": (activity) => ({
        name: 'Bill of quantities approved for next stage',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "BillOfQuantitiesRejectedInSameStage": (activity) => ({
        name: 'Bill of quantities rejected',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "BillOfQuantitiesApprovedInSameStage": (activity) => ({
        name: 'Bill of quantities approved',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "CommentAddForBillOfQuantitiesApprovalProcess": (activity) => ({
        name: 'Comment added in bill of quantities approval process',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "GRNRejectedForNextStage": (activity) => ({
        name: 'GRN fully rejected',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "GRNApprovedForNextStage": (activity) => ({
        name: 'GRN approved for next stage',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "GRNRejectedInSameStage": (activity) => ({
        name: 'GRN rejected',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "GRNApprovedInSameStage": (activity) => ({
        name: 'GRN approved',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "CommentAddForGRNApprovalProcess": (activity) => ({
        name: 'Comment added in GRN approval process',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "MaterialRequestRejectedForNextStage": (activity) => ({
        name: 'Material request fully rejected',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "MaterialRequestApprovedForNextStage": (activity) => ({
        name: 'Material request approved for next stage',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "MaterialRequestRejectedInSameStage": (activity) => ({
        name: 'Material request rejected',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "MaterialRequestApprovedInSameStage": (activity) => ({
        name: 'Material request approved',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "CommentAddForMaterialRequestApprovalProcess": (activity) => ({
        name: 'Comment added in material request approval process',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "PurchaseOrderRejectedForNextStage": (activity) => ({
        name: 'Purchase order fully rejected',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "PurchaseOrderApprovedForNextStage": (activity) => ({
        name: 'Purchase order approved for next stage',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "PurchaseOrderRejectedInSameStage": (activity) => ({
        name: 'Purchase order rejected',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "PurchaseOrderApprovedInSameStage": (activity) => ({
        name: 'Purchase order approved',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "CommentAddForPurchaseOrderApprovalProcess": (activity) => ({
        name: 'Comment added in purchase order approval process',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "WorkOrderRejectedForNextStage": (activity) => ({
        name: 'Work order fully rejected',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "WorkOrderApprovedForNextStage": (activity) => ({
        name: 'Work order approved for next stage',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "WorkOrderRejectedInSameStage": (activity) => ({
        name: 'Work order rejected',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "WorkOrderApprovedInSameStage": (activity) => ({
        name: 'Work order approved',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    }),
    "CommentAddForWorkOrderApprovalProcess": (activity) => ({
        name: 'Comment added in work order approval process',
        link: operationsLink(`/procurements/ops-order/${activity?.parent?._id}/edit`)
    })
}
export default approval;