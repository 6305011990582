import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginBottom: "30px",
        borderRadius: "5px",
        [theme.breakpoints.down("md")]: {
            width: "49%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "85%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }
    },
    header: {
        padding: "17px",
        fontSize: "17px",
        fontWeight: "510",
        textAlign: "left",
        borderBottom: "1px solid #ececec"
    },
    bodyCont: {
        width: "100%",
        height: "350px",
        overflowY: "auto",
    }
}));

const ListedPropertyCard = ({ imgPath, headerText, subText }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme()

    const { user } = useSelector((state) => state.auth);

    return (
        <Paper className={classes.root} >
            <div className={classes.header} >
                My Listings
            </div>
            <div className={classes.bodyCont} >

            </div>
        </Paper>
    );
};

export default ListedPropertyCard;