import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "32%",
        height: "120px",
        padding: "10px",
        marginBottom: "30px",
        borderRadius: "5px",
        display: "flex",
        cursor: "pointer",
        justifyContent: "space-between",
        "& h3": {
            fontSize: "16px",
            fontWeight: "510",
            marginBottom: "5px"
        },
        "& p": {
            fontSize: "13px",
            fontWeight: "400",
            color: "gray"
        },
        "&:hover": {
            transform: "scale(1.03)",
        },
        [theme.breakpoints.down("md")]: {
            width: "32%",
            height: "110px",
            padding: "10px",
            borderRadius: "3px",
            "& h3": {
                fontSize: "15px",
                marginBottom: "7px"
            },
            "& p": {
                fontSize: "11px"
            },
        },
        [theme.breakpoints.down("sm")]: {
            width: "215px"
        },
        [theme.breakpoints.down("xs")]: {
            width: "48%",
            height: "77px",
            padding: "7px",
            marginBottom: "15px",
            borderRadius: "4px",
            justifyContent: "space-between",
            "& h3": {
                fontSize: "12px",
                marginBottom: "5px"
            },
            "& p": {
                fontSize: "9px"
            },
        },
    },
    imageCont: {
        width: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            width: "100%",
            height: "auto"
        },
        [theme.breakpoints.down("xs")]: {
            width: "35px",
        }
    },
    textCont: {
        width: "calc(100% - 70px)",
        [theme.breakpoints.down("xs")]: {
            width: "calc(100% - 45px)",
        }
    }
}));

const DashBoardCard = ({ imgPath, createListing, listingType, headerText, subText }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme()

    const { user } = useSelector((state) => state.auth);


    return (
        <Paper className={classes.root} onClick={() => createListing(listingType)}>
            <div className={classes.imageCont} >
                <img src={imgPath} />
            </div>
            <div className={classes.textCont} >
                <h3>{headerText}</h3>
                <p>{subText}</p>
            </div>
        </Paper>
    );
};

export default DashBoardCard;