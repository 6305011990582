import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { TextField, InputAdornment } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import CreateOrgDialog from "./create.org.dialog";
import IconButton from "@material-ui/core/IconButton";
import OrgTable from "./OrgTable";
import DashboardInvitations from "../dashboard/Dashboard.Invitations";

const useStyles = makeStyles((theme) => ({
	invitationAndOrgCont: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		[theme.breakpoints.down("md")]: {
			flexDirection: "row",
			justifyContent: "space-between",
		},
		[theme.breakpoints.down("sm")]: {
			justifyContent: "center",
			flexDirection: "column",
		},
	},
	orgTableCard: {
		width: "100%",
		padding: "10px 15px",
		borderRadius: "10px",
		marginBottom: "20px",
		[theme.breakpoints.down("md")]: {
			width: "49%",
		},
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	orgCreateBtnSearch: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		"& > h3": {
			fontSize: "17px",
			fontWeight: "510",
			color: "#193B56",
			marginBottom: "10px",
		},
	},
	searchTextBox: {
		width: "calc(100% - 60px)",
		padding: "5px 10px",
		backgroundColor: "#F5F4F6",
		borderRadius: "8px",
		caretColor: "#2F80ED",
		fontWidth: "400",
	},
	createBtn: {
		width: "35px",
		height: "35px",
		borderRadius: "6px",
		backgroundColor: "#c1def6",
		color: theme.palette.primary.main,
		"&:hover": {
			backgroundColor: "#70b8f4",
			color: "white",
		},
	},
	allInvitationsCont: {
		width: "100%",
		overflowY: "auto",
		"&::-webkit-scrollbar": {
			display: "none",
		},
	},
}));

export default function Organizations() {
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();

	const { auth, team: teamReducer } = useSelector((state) => state);
	const { teamIds, teamDictionary } = teamReducer;

	const [rows, setRows] = useState([]);
	const [filteredRows, setFilteredRows] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [dialogOpen, setDialogOpen] = useState(false);

	const getDataFromReducer = () => {
		var tempRows = [];
		teamIds.map((teamId) => {
			var value = teamDictionary[teamId];
			if (value?.parentModelName === "Organization" && value?.parent) {
				tempRows.push(value);
			}
		});
		setRows(tempRows);
		setFilteredRows(tempRows);
	};

	useEffect(() => {
		getDataFromReducer();
	}, [teamDictionary, teamIds]);

	const addCreatedOne = (newOrg) => {
		let updatedOrgs = rows || [];
		updatedOrgs.push(newOrg);
		setRows(updatedOrgs);
		setFilteredRows(updatedOrgs);
	};

	const onOrgNameChange = async (value) => {
		setSearchQuery(value);
		if (value) {
			let filteredOrgs = [];
			rows.map((org) => {
				let name = org?.parent?.displayName;
				const patt = new RegExp(value, "i");
				const res = patt.test(name);
				if (res) {
					filteredOrgs.push(org);
				}
			});
			setFilteredRows(filteredOrgs);
		} else {
			setFilteredRows(rows);
		}
	};

	const onCreate = (newTeam) => {
		let locRows = [newTeam, ...rows]
		let locFilteredRows = [newTeam, ...filteredRows]
		setRows(locRows);
		setFilteredRows(locFilteredRows);

		setDialogOpen(false)
	}

	return (
		<div className={classes.invitationAndOrgCont}>
			<Paper elevation={2} className={classes.orgTableCard}>
				<div className={classes.orgCreateBtnSearch}>
					<h3>Organizations</h3>
					<div></div>
				</div>
				<div className={classes.orgCreateBtnSearch}>
					<TextField
						placeholder="Search org"
						type="text"
						onChange={(e) => onOrgNameChange(e.target.value)}
						value={searchQuery}
						className={classes.searchTextBox}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon style={{ color: "#7C7C8D" }} />
								</InputAdornment>
							),
							disableUnderline: true,
						}}
					/>
					<IconButton
						aria-label="add org"
						className={classes.createBtn}
						onClick={() => {
							setDialogOpen(true);
						}}
						color="primary"
					>
						+
					</IconButton>
				</div>
				<OrgTable rows={filteredRows} />
			</Paper>

			<Paper elevation={2} className={classes.orgTableCard}>
				<div className={classes.orgCreateBtnSearch}>
					<h3>Invites</h3>
					<div></div>
				</div>
				<div className={classes.allInvitationsCont}>
					<DashboardInvitations addCreatedOne={addCreatedOne} />
				</div>
			</Paper>

			<CreateOrgDialog
				open={dialogOpen}
				setOpen={setDialogOpen}
				onCreate={onCreate}
			/>
		</div>
	);
}