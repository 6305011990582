import { Box, Typography } from "@mui/material";

import React from "react";

const FormBoxWithTwoLabels = ({
  labelPrimary,
  labelPrimaryTypographyVariant = "h6",
  labelSecondary,
  labelSecondaryTypographyVariant = "subtitle1",
  children,
  disableMargins,
  actions,
  ...props
}) => {
  return (
    <Box width="100%" sx={{ mb: disableMargins ? 0 : 3 }} {...props}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography
            style={{ marginRight: "10px" }}
            variant={labelPrimaryTypographyVariant}
            gutterBottom
          >
            {labelPrimary}
          </Typography>
          <Typography variant={labelSecondaryTypographyVariant} gutterBottom>
            {labelSecondary}
          </Typography>
        </Box>
        <Box>
          {actions}
        </Box>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default FormBoxWithTwoLabels;
