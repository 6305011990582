import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { getTermsAndConditions, updateTermsAndCondition } from "./Api.call";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import AddIcon from "@material-ui/icons/Add";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import { Button, IconButton, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DeleteConfirmBox from "../styled/CommonComponents/DeleteConfirmBox";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { useDebounce } from "react-use";
import TermsAndConditionCreateDrawer from "./TermsAndCondition.Create.Drawer";
import ReactHtmlParser from "react-html-parser";
import Api from "../../helpers/Api";
import DrawerContainer from "../styled/generic/DrawerContainer";
import FormBox from "../styled/generic/FormBox";
import TextField from "../styled/generic/TextField";
import DuoButtonGroup from "../styled/generic/DuoButtonGroup";
import FormHeaderWithActions from "../styled/generic/FormHeaderWithActions";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: "100%",
    "& table": {
      tableLayout: "auto",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "0px 10px",
    },
  },
  searchAndAddBtnCont: {
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    padding: "0px 5px",
    justifyContent: "space-between",
  },
  tableContainer: {
    width: "100%",
    height: "calc(100% - 106px)",
    "& tr": {
      height: "45px",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& table": {
      tableLayout: "auto",
    },
  },
  paginationCont: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid #ececec",
  },
  singleOption: {
    padding: "4px 15px",
    fontSize: "15px",
    fontWeight: "500",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
  iconBtn: {
    "& .MuiSvgIcon-root": {
      color: "black",
    },
  },
  deleteConfirm: {
    width: "350px",
    padding: "25px 0px 35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& .MuiSvgIcon-root": {
      fontSize: "75px",
      color: "red",
    },
    "& h3": {
      fontSize: "20px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
}));

export default function SignaturesByLibrary({ libraryId, profileId }) {
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [deleteConfirmBox, setDeleteConfirmBox] = useState(false);
  const [changeState, setChangeState] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [createTCDrawer, setCreateTCDrawer] = useState(false);
  const [signatures, setSignatures] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [curPage, setCurPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [showAddSignatureDrawer, setShowAddSignatureDrawer] = useState(false);
  const [signatureName, setSignatureName] = useState("");
  const [signatureFields, setSignatureFields] = useState([]);
  const [signatureToEdit, setSignatureToEdit] = useState();
  const dispatch = useDispatch();

  const getSignatures = async () => {
    const { data } = await Api.post("/signature/get/", {
      libraryId,
      limit: pageLimit,
      curPage: curPage - 1,
      nameSearch: searchStr,
    });

    console.log(data);
    setSignatures(data);
  };

  useDebounce(
    () => {
      getSignatures();
    },
    1000,
    [curPage, changeState]
  );

  useEffect(() => {
    setCurPage(1);
    setChangeState(!changeState);
  }, [searchStr]);

  const addSignature = async () => {
    try {
      const { data } = await Api.post("/signature/create", {
        library: libraryId,
        title: signatureName,
        fields: signatureFields,
      });

      if (data) {
        setShowAddSignatureDrawer(false);
        setSignatureName("");
        setSignatures([...signatures, data]);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Signature added successfully",
          },
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred ",
        },
      });
    }
  };

  const updateSignature = async () => {
    try {
      const { data } = await Api.post("/signature/update", {
        _id: signatureToEdit?._id,
        library: libraryId,
        title: signatureName,
        fields: signatureFields,
      });
      console.log("siiiiiiiiii", data);

      if (data) {
        setShowAddSignatureDrawer(false);
        setSignatureName("");

        setSignatures((prevSignatures) => {
          const index = prevSignatures?.findIndex(
            (sig) => sig?._id === signatureToEdit?._id
          );
          if (index !== -1) {
            const updatedSignatures = [...prevSignatures];
            updatedSignatures[index] = data;
            return updatedSignatures;
          }
          return prevSignatures;
        });

        // setSignatures([...signatures, data]);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Signature added successfully",
          },
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred ",
        },
      });
    }
  };

  const editSignature = (data) => {
    console.log("data:", data);
    setSignatureToEdit(data);
    setSignatureName(data.title);
    setSignatureFields(data.fields || []);
    setShowAddSignatureDrawer(true);
  };

  const deleteSignature = (data) => {};

  const addSignatureField = () => {
    setSignatureFields([...signatureFields, {name:'',action:''}]);
  };

  return (
    <div className={classes.mainCont}>
      <div className={classes.searchAndAddBtnCont}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          placeholder={"Enter name"}
          autoComplete="off"
          value={searchStr}
          style={{ width: "350px" }}
          onChange={(e) => {
            setSearchStr(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setShowAddSignatureDrawer(true);
          }}
        >
          New
        </Button>
      </div>
      <div style={{ width: "100%", height: "5px" }}>
        {loader && <LinearProgress />}
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Fields</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {signatures &&
              signatures.length > 0 &&
              signatures.map((data) => {

                return (
                  <TableRow>
                    <TableCell align="left">
                      {String(data?.title).slice(0, 10)}
                    </TableCell>
                    <TableCell align="left">{data.fields.map(field=>field?.name?.toString())}</TableCell>
                    <TableCell align="right">
                      <MyPopOver
                        closeOnClick={true}
                        appearContent={
                          <span className={classes.iconBtn}>
                            <IconButton>
                              <MoreVertIcon />
                            </IconButton>
                          </span>
                        }
                        showContent={
                          <div style={{ padding: "5px 0px" }}>
                            <div
                              className={classes.singleOption}
                              onClick={() => {
                                editSignature(data);
                              }}
                            >
                              Edit
                            </div>
                            <div
                              className={classes.singleOption}
                              onClick={() => {
                                deleteSignature(data);
                              }}
                            >
                              Delete
                            </div>
                          </div>
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <DrawerContainer
        title={signatureToEdit ? "Edit Signature" : "Add Signature"}
        open={showAddSignatureDrawer}
        setOpen={setShowAddSignatureDrawer}
      >
        <FormBox label="Name">
          <TextField
            value={signatureName}
            onChange={(e) => setSignatureName(e.target.value)}
            fullWidth={true}
            placeholder="Enter"
          />
        </FormBox>

        <FormHeaderWithActions
          label="Fields"
          actions={
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => addSignatureField()}
            >
              Add Field
            </Button>
          }
        >
          {signatureFields.map((field, index) => (
            <FormBox
              sx={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}
              label={`Field ${index + 1}`}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.8rem",
                }}
              >
                <TextField
                  label="Designation"
                  value={field?.name}
                  onChange={(e) => {
                    const newFields = [...signatureFields];
                    let oldObj = signatureFields[index]
                    newFields[index] = {...oldObj, name: e.target.value };
                    setSignatureFields(newFields);
                  }}
                  fullWidth={true}
                  placeholder="Manager"
                />
                <TextField
                  label="Action"
                  value={field?.action}
                  onChange={(e) => {
                    const newFields = [...signatureFields];
                    let oldObj = signatureFields[index]
                    newFields[index] = {...oldObj, action: e.target.value };
                    setSignatureFields(newFields);
                  }}
                  fullWidth={true}
                  placeholder="Approved By"
                />
              </div>
            </FormBox>
          ))}
        </FormHeaderWithActions>

        <DuoButtonGroup
          primaryButtonText={signatureToEdit ? "Update" : "Add"}
          primaryButtonListener={() => {
            if (signatureToEdit) {
              updateSignature();
            } else {
              addSignature();
            }
          }}
          secondaryButtonText="Cancel"
          secondaryButtonListener={() => setShowAddSignatureDrawer(false)}
        />
      </DrawerContainer>
    </div>
  );
}
