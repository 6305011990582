import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Divider,
  Chip,
  Table,
  useTheme,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Checkbox,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import {
  Close as CloseIcon,
  ArrowBack,
  Add,
  MoreVert,
  Add as AddIcon,
  Home as HomeIcon,
  Close,
  Edit as EditIcon,
  Delete,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../../helpers/Api";
import CreateProjectBlockDrawer from "./CreateProjectBlockDrawer";
import { unitTypes } from "./CreateProjectBlockDrawer";
import FormBox from "../../../styled/generic/FormBox";
import ConfirmationDialog from "../../../global/ConfirmationDialog";
import allUnitStatuses from "../../../../helpers/allUnitStatuses";
import { useParams } from "react-router-dom";
import BlockUnitBulkActionDrawer from './BlockUnitBulkActionDrawer';

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[4],
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${TableCell.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${TableCell.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SelectProjectBlockDrawer = ({
  open,
  setOpen,
  projectId,
  setProjectBlocks,
  projectBlocks,
  setRentalUnitCount,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [showCreateProjectBlockDrawer, setShowCreateProjectBlockDrawer] =
    useState(false);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [blockUnits, setBlockUnits] = useState([]);
  const [units, setUnits] = useState([]);

  // const getProjectBlocks = async () => {
  //   try {
  //     const { data } = await Api.post("/project/unit/getUnitsByProject", {
  //       projectId,
  //     });

  //     console.log("projectdata", data);

  //     let resUnits = data?.units || [];
  //     let blockUnits = _.groupBy(resUnits, "projectBlock");

  //     setProjectBlocks(data?.blocks || []);
  //     setRentalUnitCount(data?.units?.length || 0);
  //     setBlockUnits(blockUnits);
  //   } catch (error) {
  //     dispatch({
  //       type: "AddApiAlert",
  //       payload: { success: false, message: "Unable to fetch blocks" },
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (open) {
  //     getProjectBlocks();
  //   }
  // }, [open]);

  const handleBlockClick = async (block) => {
    setSelectedBlock(block);
    const newUnits = blockUnits?.[block?._id] || [];
    setUnits(newUnits);
  };

  useState(() => {
    setUnits(blockUnits?.[selectedBlock?._id] || []);
  }, [blockUnits]);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ sx: { width: "80%", maxWidth: 1000 } }}
      >
        <DrawerHeader>
          <Typography variant="h6">Select Block</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box sx={{ p: 3 }}>
          <Grid container spacing={1}>
            {projectBlocks?.map((block) => (
              <Grid item xs={12} sm={6} md={4} key={block?._id}>
                <StyledCard>
                  <CardActionArea onClick={() => handleBlockClick(block)}>
                    <CardMedia
                      component="img"
                      image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShVtVCppmEQr5JQA_pd-oAto8oJuw6uUrP3g&s"
                      alt={block?.name}
                      sx={{
                        height: "150px",
                      }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        {block?.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <HomeIcon
                          fontSize="small"
                          sx={{ mr: 1, verticalAlign: "middle" }}
                        />
                        {block?.unitCount}{" "}
                        {block?.unitCount === 1 ? "Unit" : "Units"}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Created:{" "}
                        {new Date(block?.createdAt).toLocaleDateString()}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setShowCreateProjectBlockDrawer(true)}
              fullWidth
            >
              Add New Block
            </Button>
          </Box>
        </Box>
      </Drawer>

      {selectedBlock && (
        <BlockDetailsDrawer
          projectId={projectId}
          open={Boolean(selectedBlock)}
          onClose={() => {
            setSelectedBlock(null);
          }}
          block={selectedBlock}
          units={units}
          getProjectBlocks={getProjectBlocks}
        />
      )}

      <CreateProjectBlockDrawer
        open={showCreateProjectBlockDrawer}
        getProjectBlocks={getProjectBlocks}
        setOpen={setShowCreateProjectBlockDrawer}
        projectId={projectId}
        setProjectBlocks={setProjectBlocks}
        setRentalUnitCount={setRentalUnitCount}
      />
    </>
  );
};

const BlockDetailsDrawer = ({
  open,
  onClose,
  block,
  units,
  projectId,
  getProjectBlocks,
}) => {
  const dispatch = useDispatch();
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [showNewUnitDialog, setShowNewUnitDialog] = useState(false);
  const [isOpenEditModel, setIsOpenEditModel] = useState(false);
  const [openConformationDialog, setOpenConformationDialog] = useState(false);
  const [bulkActionModel, setBulkActionModel] = useState(false);

  const handleDelete = async () => {
    const data = await Api.post("/project/unit/remove-unit", {
      unitId: selectedUnit?._id,
    });
    if (data) {
      dispatch({
        type: "AddApiAlert",
        payload: { success: true, message: data?.message },
      });
      getProjectBlocks();
      onClose();
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: { success: false, message: "Unable to delete unit" },
      });
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "80%", maxWidth: 1800 } }}
    >
      <Box
        sx={{
          p: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{block?.name} Details</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ p: 3 }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h5" gutterBottom>
            {block?.name}
          </Typography>
          <Chip
            icon={<HomeIcon />}
            label={`${block?.unitCount ?? 0} ${
              block?.unitCount === 1 ? "Unit" : "Units"
            }`}
            color="primary"
            sx={{ mr: 1 }}
          />
          <Chip
            label={`Created: ${new Date(
              block?.createdAt
            ).toLocaleDateString()}`}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Units
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => {
                setBulkActionModel(true);
              }}
              variant="outlined"
              size="small"
            >
              Bulk Actions
            </Button>
            <Button
              size="small"
              onClick={() => setShowNewUnitDialog(true)}
              variant="contained"
              startIcon={<AddIcon />}
            >
              Unit
            </Button>
          </Box>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Unit Name</StyledTableCell>
                <StyledTableCell align="right">Type</StyledTableCell>
                <StyledTableCell align="right">Status</StyledTableCell>
                <StyledTableCell align="right">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {units?.map((unit) => (
                <StyledTableRow key={unit?._id}>
                  <StyledTableCell component="th" scope="row">
                    {unit?.name}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {unit?.unitType}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Chip
                      label={unit?.vacant ? "Vacant" : "Occupied"}
                      color={unit?.vacant ? "success" : "error"}
                      size="small"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                          setSelectedUnit(unit);
                          setIsOpenEditModel(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color={"error"}
                        size="small"
                        onClick={() => {
                          setSelectedUnit(unit);
                          setOpenConformationDialog(true);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <BlockUnitBulkActionDrawer
          open={bulkActionModel}
          onClose={() => setBulkActionModel(false)}
          projectId={projectId}
        />
        <CreateUnitDialog
          onClose={() => setShowNewUnitDialog(false)}
          units={units}
          open={showNewUnitDialog}
          block={block}
          projectId={projectId}
          getProjectBlocks={getProjectBlocks}
        />

        <ConfirmationDialog
          cancelButtonText={"cancel"}
          cancelListener={() => {
            setOpenConformationDialog(false);
          }}
          message={"Are you sure you want to delete this unit?"}
          open={openConformationDialog}
          setOpen={setOpenConformationDialog}
          successListener={() => {
            handleDelete();
          }}
          title={"Delete Unit"}
          successButtonText={"confirm"}
        />

        <EditUnitDialog
          onClose={() => setIsOpenEditModel(false)}
          onSave={() => {}}
          unit={selectedUnit}
          open={isOpenEditModel}
          getProjectBlocks={getProjectBlocks}
        />
      </Box>
    </Drawer>
  );
};

const units = [
  {
    _id: 1,
    name: "Unit 2A",
    block: "Block 1",
    type: "2BHK",
    createdDate: "12 June, 4 AM",
    status: [
      {
        activityType: "Listing",
        activityStatus: "Unlisted",
      },
    ],
  },
  {
    _id: 2,
    name: "Unit 2A",
    block: "Block 1",
    type: "2BHK",
    createdDate: "12 June, 4 AM",
    status: [
      {
        activityType: "Listing",
        activityStatus: "Unlisted",
      },
    ],
  },
];

const getProjectBlocks = async (projectId) => {
  const { data } = await Api.post("/project/unit/getUnitsByProject", {
    projectId,
  });

  let resUnits = data?.units || [];
  let blockUnits = _.groupBy(resUnits, "projectBlock");
  return {
    blocks: data?.blocks || [],
    units: blockUnits || [],
  };
};

const CreateUnitDialog = ({
  open,
  onClose,
  setUnits,
  units,
  block,
  projectId,
  getProjectBlocks,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    floor: 0,
    name: "",
    unitType: "1BHK",
    vacant: true,
  });

  async function handleCreate() {
    setLoading(true);
    const payload = {
      ...formData,
      projectBlock: block._id,
      blockId: block._id,
      project: projectId,
    };

    const data = await Api.post("/project/unit/add-unit", payload);
    if (data) {
      console.log(data);
      dispatch({
        type: "AddApiAlert",
        payload: { success: true, message: "Unit created successfully" },
      });
      getProjectBlocks();
      onClose();
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: { success: false, message: "Failed to create unit" },
      });
    }
    setLoading(false);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Unit</DialogTitle>
      <DialogContent
        sx={{
          minWidth: 400,
        }}
      >
        <FormBox label={"Select Floor"}>
          <Select
            variant="outlined"
            value={formData?.floor}
            onChange={(e) => {
              setFormData({ ...formData, floor: e.target.value });
            }}
            label="Floor"
            fullWidth
            defaultValue={block?.totalFloors - 1}
          >
            {Array(block?.totalFloors)
              .fill("")
              .map((item, index) => {
                return <MenuItem value={index}>{index}</MenuItem>;
              })}
          </Select>
        </FormBox>

        <FormBox label={"Unit name"}>
          <TextField
            sx={{ mb: 1 }}
            autoFocus
            value={formData?.name}
            onChange={(e) => {
              setFormData({ ...formData, name: e.target.value });
            }}
            margin="dense"
            type="text"
            fullWidth
          />
        </FormBox>
        <FormBox label={"Unit type"}>
          <Select
            value={formData?.unitType}
            onChange={(e) => {
              setFormData({ ...formData, unitType: e.target.value });
            }}
            fullWidth
            variant="outlined"
            label={"fix"}
            displayEmpty
          >
            {unitTypes?.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormBox>
        <FormBox label={"Vacant"}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Switch
              checked={formData?.vacant}
              onChange={(e) => {
                setFormData({ ...formData, vacant: e.target.checked });
              }}
            />
            <Typography>{formData?.vacant ? "Vacant" : "Occupied"}</Typography>
          </Box>
        </FormBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleCreate} variant="contained">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditUnitDialog = ({ open, onClose, unit, onSave, getProjectBlocks }) => {
  const [editedUnit, setEditedUnit] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  React.useEffect(() => {
    if (unit) {
      setEditedUnit({ ...unit });
    }
  }, [unit]);

  const handleSave = async () => {
    setIsSaving(true);
    const data = await Api.post("/project/unit/update", {
      rentalUnit: editedUnit,
    });
    getProjectBlocks();
    console.log("data", data);
    setIsSaving(false);
    onClose();
  };

  if (!unit) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Unit: {unit.name}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Unit Name"
          type="text"
          fullWidth
          value={editedUnit.name || ""}
          onChange={(e) =>
            setEditedUnit({ ...editedUnit, name: e.target.value })
          }
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Unit Type</InputLabel>
          <Select
            value={editedUnit.unitType || ""}
            onChange={(e) =>
              setEditedUnit({ ...editedUnit, unitType: e.target.value })
            }
            label="Unit Type"
          >
            <MenuItem value="1BHK">1BHK</MenuItem>
            <MenuItem value="2BHK">2BHK</MenuItem>
            <MenuItem value="3BHK">3BHK</MenuItem>
            <MenuItem value="4BHK">4BHK</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <InputLabel>Status</InputLabel>
          <Select
            value={editedUnit.vacant ? "Vacant" : "Occupied"}
            onChange={(e) =>
              setEditedUnit({
                ...editedUnit,
                vacant: e.target.value === "Vacant",
              })
            }
            label="Status"
          >
            <MenuItem value="Vacant">Vacant</MenuItem>
            <MenuItem value="Occupied">Occupied</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={isSaving}>
          {isSaving ? <CircularProgress size={24} /> : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectProjectBlockDrawer;
