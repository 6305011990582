import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { addProfileSectionForOrgApiHit } from "../api.call";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMediaQuery } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import LinearProgress from '@material-ui/core/LinearProgress';
import * as yup from "yup";
import teamUtils from "../../team/team.utils";

const schema = yup.object().shape({
  license: yup.string().min(3).max(250).required("License should be required"),
  issuer: yup.string().required("Issuing organization should be required"),
  issuedMonth: yup.string().required("Issued month should be required"),
  issuedYear: yup.string().required("Issued year should be required"),
  endMonth: yup.string().required("End month should be required"),
  endYear: yup.string().required("End year should be required"),
  description: yup.string().max(350)
})

const useStyles = makeStyles((theme) => ({
  singleEducationCont: {
    width: "550px",
    maxHeight: "525px",
    overflowY: "auto",
    marginBottom: "20px",
    padding: "0px 15px 10px",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down("sm")]: {
      width: "450px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 5px 10px",
      width: "300px",
      maxHeight: "360px",
    },
  },
  textLineCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  halfCont: {
    width: "47%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    }
  },
  checkedCont: {
    display: "flex",
    alignItems: "center",
    marginBottom: "-10px",
    "& h3": {
      fontSize: "15px",
      marginRight: "15px",
      fontWeight: "500",
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    marginTop: "7px",
    marginBottom: "0px"
  }
}));

const calendarMonth = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const CertificatLicenseDialog = ({ open, setOpen, profile, teamId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { handleTeams } = teamUtils;

  const state = useSelector((state) => state);
  const { user } = useSelector((state) => state.auth);
  const { teamDictionary } = useSelector((state) => state.team);

  const [title, setTitle] = useState("");
  const [credentialId, setCredentialId] = useState("");
  const [licenseUrl, setLicenseUrl] = useState("");
  const [issuerHonor, setIssuerHonor] = useState("");
  const [description, setDescription] = useState("");
  const [startMonthVal, setStartMonthVal] = useState("")
  const [startYearVal, setStartYearVal] = useState("")
  const [endMonthVal, setEndMonthVal] = useState("")
  const [endYearVal, setEndYearVal] = useState("")
  const [issuedMonthVal, setIssuedMonthVal] = useState("")
  const [issuedYearVal, setIssuedYearVal] = useState("")
  const [calendarYear, setCalendarYear] = useState([]);
  const [loading, setLoading] = useState(false)
  const [validationObj, setValidationObj] = useState({})

  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const years = [];
    const date = new Date();
    const endYear = date.getFullYear();
    for (let i = 1951; i <= endYear; i++) {
      years.push(i + "");
    }
    setCalendarYear(years);
  }, []);


  useEffect(() => {
    setValidationObj({})
  }, [
    title,
    issuerHonor,
    issuedMonthVal,
    issuedYearVal,
    endMonthVal,
    endYearVal,
    description,
  ])


  const saveData = async (e) => {
    e.preventDefault();
    const formData = {
      license: title,
      issuer: issuerHonor,
      issuedMonth: issuedMonthVal,
      issuedYear: issuedYearVal,
      endMonth: endMonthVal,
      endYear: endYearVal,
      description: description
    }

    await schema.validate(formData, { abortEarly: false })
      .then(async (data) => {
        setLoading(true)
        let orgId = profile?.parent?._id
        let arrayKey = "licenses"
        let sectionDataArr = [{
          title: title,
          start_date: `${startMonthVal}-${startYearVal}`,
          end_date: `${endMonthVal}-${endYearVal}`,
          credentialId: credentialId,
          license_url: licenseUrl,
          start_date_honor: `${issuedMonthVal}-${issuedYearVal}`,
          issuer: issuerHonor,
          description: description
        }]

        await addProfileSectionForOrgApiHit({ orgId, arrayKey, sectionDataArr })
          .then((data) => {

            const updatedLicenses = data?.licenses || []
            let teamDataFromDic = teamDictionary[teamId]
            let teamParent = teamDataFromDic?.parent

            const team = {
              ...teamDataFromDic,
              parent: {
                ...teamParent,
                licenses: updatedLicenses,
              }
            }
            handleTeams([team], state, dispatch);
          })
          .catch((err) => {
            console.log(err);
          })

        setTitle("")
        setCredentialId("")
        setLicenseUrl("")
        setIssuerHonor("")
        setDescription("")
        setStartMonthVal("")
        setStartYearVal("")
        setEndMonthVal("")
        setEndYearVal("")
        setIssuedMonthVal("")
        setIssuedYearVal("")
        setOpen(false)
      })
      .catch((err) => {
        let errorArr = err?.inner
        let obj = {}
        errorArr.map((err) => {
          obj[err?.path] = err?.message
        })
        setValidationObj(obj)
      })
  };





  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={handleClose}
      pageTitle={"Add Certification"}
      content={<form onSubmit={saveData} >
        <div className={classes.singleEducationCont} >
          <p className={classes.label} >License</p>
          <TextField
            id="school"
            placeholder="Enter license"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            size="small"
            variant="outlined"
            style={{ width: "100%" }}
          />
          {validationObj?.license && (<p style={{ color: "#EE1D52" }} >{validationObj?.license}</p>)}
          <p className={classes.label} >Issuing organization</p>
          <TextField
            id="school"
            placeholder="Enter issuing organization name"
            value={issuerHonor}
            onChange={(e) => {
              setIssuerHonor(e.target.value)
            }}
            size="small"
            variant="outlined"
            style={{ width: "100%" }}
          />
          {validationObj?.issuer && (<p style={{ color: "#EE1D52" }} >{validationObj?.issuer}</p>)}
          <div className={classes.textLineCont} >
            <div className={classes.halfCont} >
              <p className={classes.label} >Credential ID</p>
              <TextField
                id="school"
                placeholder="Enter credential ID"
                value={credentialId}
                onChange={(e) => {
                  setCredentialId(e.target.value)
                }}
                size="small"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className={classes.halfCont} >
              <p className={classes.label} >Credential URL</p>
              <TextField
                id="degree"
                placeholder="Enter credential URL"
                value={licenseUrl}
                onChange={(e) => {
                  setLicenseUrl(e.target.value);
                }}
                size="small"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <p className={classes.label} >Start date</p>
          <div className={classes.textLineCont} >
            <div className={classes.halfCont} >
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={startMonthVal}
                options={calendarMonth}
                getOptionLabel={(option) => { return option }}
                getOptionSelected={(option) => {
                  return option === startMonthVal
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ margin: "0px" }}
                    margin="normal"
                    variant="outlined"
                    placeholder={"Enter start month"}
                  />
                )}
                onChange={(event, value) => {
                  setStartMonthVal(value)
                }}
                style={{ width: "100%", marginBottom: "5px" }}
                size="small"
              />
            </div>
            <div className={classes.halfCont} >
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={startYearVal}
                options={calendarYear}
                getOptionLabel={(option) => { return option }}
                getOptionSelected={(option) => {
                  return option === startYearVal
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ margin: "0px" }}
                    margin="normal"
                    variant="outlined"
                    placeholder={"Enter start year"}
                  />
                )}
                onChange={(event, value) => {
                  setStartYearVal(value)
                }}
                style={{ width: "100%", marginBottom: "5px" }}
                size="small"
              />
            </div>
          </div>
          <p className={classes.label} >End date</p>
          <div className={classes.textLineCont} >
            <div className={classes.halfCont} >
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={endMonthVal}
                options={calendarMonth}
                getOptionLabel={(option) => { return option }}
                getOptionSelected={(option) => {
                  return option === endMonthVal
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ margin: "0px" }}
                    margin="normal"
                    variant="outlined"
                    placeholder={"Enter end month"}
                  />
                )}
                onChange={(event, value) => {
                  setEndMonthVal(value)
                }}
                style={{ width: "100%", marginBottom: "5px" }}
                size="small"
              />
              {validationObj?.endMonth && (<p style={{ color: "#EE1D52" }} >{validationObj?.endMonth}</p>)}
            </div>
            <div className={classes.halfCont} >
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={endYearVal}
                options={calendarYear}
                getOptionLabel={(option) => { return option }}
                getOptionSelected={(option) => {
                  return option === endYearVal
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ margin: "0px" }}
                    margin="normal"
                    variant="outlined"
                    placeholder={"Enter end year"}
                  />
                )}
                onChange={(event, value) => {
                  setEndYearVal(value)
                }}
                style={{ width: "100%", marginBottom: "5px" }}
                size="small"
              />
              {validationObj?.endYear && (<p style={{ color: "#EE1D52" }} >{validationObj?.endYear}</p>)}
            </div>
          </div>
          <p className={classes.label} >Issued date</p>
          <div className={classes.textLineCont} >
            <div className={classes.halfCont} >
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={issuedMonthVal}
                options={calendarMonth}
                getOptionLabel={(option) => { return option }}
                getOptionSelected={(option) => {
                  return option === issuedMonthVal
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ margin: "0px" }}
                    margin="normal"
                    variant="outlined"
                    placeholder={"Enter issued month"}
                  />
                )}
                onChange={(event, value) => {
                  setIssuedMonthVal(value)
                }}
                style={{ width: "100%", marginBottom: "5px" }}
                size="small"
              />
              {validationObj?.issuedMonth && (<p style={{ color: "#EE1D52" }} >{validationObj?.issuedMonth}</p>)}
            </div>
            <div className={classes.halfCont} >
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={issuedYearVal}
                options={calendarYear}
                getOptionLabel={(option) => { return option }}
                getOptionSelected={(option) => {
                  return option === issuedYearVal
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ margin: "0px" }}
                    margin="normal"
                    variant="outlined"
                    placeholder={"Enter issued year"}
                  />
                )}
                onChange={(event, value) => {
                  setIssuedYearVal(value)
                }}
                style={{ width: "100%", marginBottom: "5px" }}
                size="small"
              />
              {validationObj?.issuedYear && (<p style={{ color: "#EE1D52" }} >{validationObj?.issuedYear}</p>)}
            </div>
          </div>
          <p className={classes.label} >Description</p>
          <TextField
            id="description"
            placeholder="Write anything notable..."
            multiline
            rows={3}
            variant="outlined"
            size="small"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            fullWidth
          />
          {validationObj?.description && (<p style={{ color: "#EE1D52" }} >{validationObj?.description}</p>)}
        </div>
        <div style={{ height: "5px", marginTop: "2px" }} >
          {loading && (<LinearProgress />)}
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "3px 7px 0px" }} >
          <div></div>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            type="submit"
            startIcon={<SaveIcon />}
            size={isSmall ? "small" : "medium"}
          >Save</Button>
        </div>
      </form>}
    />);
};

export default CertificatLicenseDialog;