import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import ProjectContactAnalysis from "./ProjectContactAnalysis";
import { getAggregatedAmountProjectWise, getProcurementsAggregatedAmounts, getProjectsByOwner } from "./api.call";
import CustomSelectAutocomplete from "../styled/CommonComponents/CustomSelectAutocomplete";
import WorkorderPurchaseTotalCard from "./WorkorderPurchaseTotalCard";
import AmountStatusChart from "./AmountStatusChart";
import { Paper } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
    loaderCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    allProjectsAnalysisCont: {
        width: "100%",
        height: "100%"
    },
    headerCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        marginTop: "30px",
        marginBottom: "10px",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #ebeaea",
        padding: "0px 5px",
        "& h3": {
            fontSize: "18px",
            fontWeight: "600",
        }
    },
    optionsCont: {
        minWidth: "350px"
    },
    projectAnalysisBody: {
        width: "100%",
        overflow: "auto",
        padding: "10px"
    },
    cardsCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
    },
    tableCont: {
        width: "100%",
        padding: "20px",
        marginTop: "30px",
        "& h3": {
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "20px"
        }
    }
}));

export default function OrganizationContactAnalysis({
    entity, curEntityId, curEntityProfileId, secondPartyId
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [aggregatedValuesPO, setAggregatedValuesPO] = useState(null)
    const [aggregatedValuesWO, setAggregatedValuesWO] = useState(null)
    const [loading, setLoading] = useState(false)
    const [projects, setProjects] = useState([])
    const [selectedProject, setSelectedProject] = useState(null)
    const [projectsWiseAmountsPO, setProjectsWiseAmountsPO] = useState([])
    const [projectsWiseAmountsWO, setProjectsWiseAmountsWO] = useState([])

    const getAggregatedValuesForPurchaseOrder = async () => {
        setLoading(true)
        getProcurementsAggregatedAmounts({
            entity,
            curEntityId,
            type: "Purchase Order",
            vendorProfileId: secondPartyId
        })
            .then((data) => {
                console.log(data)
                setAggregatedValuesPO(data[0])
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getAggregatedValuesForWorkOrder = async () => {
        setLoading(true)
        getProcurementsAggregatedAmounts({
            entity,
            curEntityId,
            type: "Work Order",
            vendorProfileId: secondPartyId
        })
            .then((data) => {
                console.log(data)
                setAggregatedValuesWO(data[0])
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getAggregatedAmountForProjectsPO = async () => {
        getAggregatedAmountProjectWise({
            curEntityId: curEntityId,
            type: "Purchase Order",
            vendorProfileId: secondPartyId
        })
            .then((data) => {
                console.log(data)
                setProjectsWiseAmountsPO(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getAggregatedAmountForProjectsWO = async () => {
        getAggregatedAmountProjectWise({
            curEntityId: curEntityId,
            type: "Work Order",
            vendorProfileId: secondPartyId
        })
            .then((data) => {
                console.log(data)
                setProjectsWiseAmountsWO(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (curEntityId) {
            getAggregatedValuesForPurchaseOrder()
            getAggregatedValuesForWorkOrder()
            getAggregatedAmountForProjectsPO()
            getAggregatedAmountForProjectsWO()
        }
    }, [curEntityId])

    useEffect(() => {
        if (curEntityProfileId) {
            setLoading(true)
            getProjectsByOwner({
                ownerProfile: curEntityProfileId
            })
                .then((projects) => {
                    setProjects(projects)
                    if (projects && projects.length > 0) {
                        setSelectedProject(projects[0])
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [curEntityProfileId])

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (<>{loading ? (<div className={classes.loaderCont} >
        <CircularProgress />
    </div>) : (<div>
        <div className={classes.cardsCont} >
            <WorkorderPurchaseTotalCard
                cardType={"Purchase Order"}
                mainTotal={aggregatedValuesPO?.totalOpsOrderAmount || 0}
                draftAmount={aggregatedValuesPO?.draftAmount || 0}
                submittedAmount={aggregatedValuesPO?.submittedAmount || 0}
                approvedAmount={aggregatedValuesPO?.approvedAmount || 0}
                issuedAmount={aggregatedValuesPO?.issuedAmount || 0}
                acknowledgedAmount={aggregatedValuesPO?.acknowledgedAmount || 0}
                fullyFulfilledAmount={aggregatedValuesPO?.fullyFulfilledAmount || 0}
                partiallyFulfilledAmount={aggregatedValuesPO?.partiallyFulfilledAmount || 0}
                rejectedAmount={aggregatedValuesPO?.rejectedAmount || 0}
                certifiedAmount={aggregatedValuesPO?.certifiedAmount || 0}
                paidAmount={aggregatedValuesPO?.paidAmount || 0}
                retentionDeductionAmount={aggregatedValuesPO?.retentionDeductionAmount || 0}
                retentionReleaseAmount={aggregatedValuesPO?.retentionReleaseAmount || 0}
                advanceDeductionAmount={aggregatedValuesPO?.advanceDeductionAmount || 0}
                advanceAmount={aggregatedValuesPO?.advanceAmount || 0}
            />
            <WorkorderPurchaseTotalCard
                cardType={"Workorder Order"}
                mainTotal={aggregatedValuesWO?.totalOpsOrderAmount || 0}
                draftAmount={aggregatedValuesWO?.draftAmount || 0}
                submittedAmount={aggregatedValuesWO?.submittedAmount || 0}
                approvedAmount={aggregatedValuesWO?.approvedAmount || 0}
                issuedAmount={aggregatedValuesWO?.issuedAmount || 0}
                acknowledgedAmount={aggregatedValuesWO?.acknowledgedAmount || 0}
                fullyFulfilledAmount={aggregatedValuesWO?.fullyFulfilledAmount || 0}
                partiallyFulfilledAmount={aggregatedValuesWO?.partiallyFulfilledAmount || 0}
                rejectedAmount={aggregatedValuesWO?.rejectedAmount || 0}
                certifiedAmount={aggregatedValuesWO?.certifiedAmount || 0}
                paidAmount={aggregatedValuesWO?.paidAmount || 0}
                retentionDeductionAmount={aggregatedValuesWO?.retentionDeductionAmount || 0}
                retentionReleaseAmount={aggregatedValuesWO?.retentionReleaseAmount || 0}
                advanceDeductionAmount={aggregatedValuesWO?.advanceDeductionAmount || 0}
                advanceAmount={aggregatedValuesWO?.advanceAmount || 0}
            />
        </div>
        <AmountStatusChart
            cardType={"Purchase Order"}
            mainTotal={aggregatedValuesPO?.totalOpsOrderAmount || 0}
            draftAmount={aggregatedValuesPO?.draftAmount || 0}
            submittedAmount={aggregatedValuesPO?.submittedAmount || 0}
            approvedAmount={aggregatedValuesPO?.approvedAmount || 0}
            issuedAmount={aggregatedValuesPO?.issuedAmount || 0}
            acknowledgedAmount={aggregatedValuesPO?.acknowledgedAmount || 0}
            fullyFulfilledAmount={aggregatedValuesPO?.fullyFulfilledAmount || 0}
            partiallyFulfilledAmount={aggregatedValuesPO?.partiallyFulfilledAmount || 0}
            rejectedAmount={aggregatedValuesPO?.rejectedAmount || 0}
            certifiedAmount={aggregatedValuesPO?.certifiedAmount || 0}
            paidAmount={aggregatedValuesPO?.paidAmount || 0}
            retentionDeductionAmount={aggregatedValuesPO?.retentionDeductionAmount || 0}
            retentionReleaseAmount={aggregatedValuesPO?.retentionReleaseAmount || 0}
            advanceDeductionAmount={aggregatedValuesPO?.advanceDeductionAmount || 0}
            advanceAmount={aggregatedValuesPO?.advanceAmount || 0}
        />
        <AmountStatusChart
            cardType={"Workorder Order"}
            mainTotal={aggregatedValuesWO?.totalOpsOrderAmount || 0}
            draftAmount={aggregatedValuesWO?.draftAmount || 0}
            submittedAmount={aggregatedValuesWO?.submittedAmount || 0}
            approvedAmount={aggregatedValuesWO?.approvedAmount || 0}
            issuedAmount={aggregatedValuesWO?.issuedAmount || 0}
            acknowledgedAmount={aggregatedValuesWO?.acknowledgedAmount || 0}
            fullyFulfilledAmount={aggregatedValuesWO?.fullyFulfilledAmount || 0}
            partiallyFulfilledAmount={aggregatedValuesWO?.partiallyFulfilledAmount || 0}
            rejectedAmount={aggregatedValuesWO?.rejectedAmount || 0}
            certifiedAmount={aggregatedValuesWO?.certifiedAmount || 0}
            paidAmount={aggregatedValuesWO?.paidAmount || 0}
            retentionDeductionAmount={aggregatedValuesWO?.retentionDeductionAmount || 0}
            retentionReleaseAmount={aggregatedValuesWO?.retentionReleaseAmount || 0}
            advanceDeductionAmount={aggregatedValuesWO?.advanceDeductionAmount || 0}
            advanceAmount={aggregatedValuesWO?.advanceAmount || 0}
        />

        <Paper className={classes.tableCont} >
            <h3>Project Wise Work Order Distribution</h3>
            <TableContainer className={classes.container} style={{ overflowX: 'auto' }} >
                <Table stickyHeader aria-label="sticky table" size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{ width: "250px" }} >
                                Project
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }}  >
                                Advance(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }}  >
                                Draft(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Submitted(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Approved(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "250px" }} >
                                Retention Deduction(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "250px" }} >
                                Retention Release(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "250px" }} >
                                Advance Deduction(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }}  >
                                Issued(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }}  >
                                Acknowledged(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Fully Fulfilled(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Partially Fulfilled(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Rejected(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Certified(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Paid(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Total(₹)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projectsWiseAmountsWO && projectsWiseAmountsWO.map((rowData) => (
                            <TableRow>
                                <TableCell align="left" >
                                    {rowData?.projectDetails?.displayName}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.totalAdvanceAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.draftAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center" >
                                    {numberWithCommas(parseFloat(rowData?.submittedAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.approvedAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.retentionDeductionAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.retentionReleaseAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.advanceDeductionAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.issuedAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.acknowledgedAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center" >
                                    {numberWithCommas(parseFloat(rowData?.fullyFulfilledAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.partiallyFulfilledAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.rejectedAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.certifiedAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.paidAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.totalOpsOrderAmount || 0).toFixed(2))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>

        <Paper className={classes.tableCont} >
            <h3>Project Wise Purchase Order Distribution</h3>
            <TableContainer className={classes.container} style={{ overflowX: 'auto' }} >
                <Table stickyHeader aria-label="sticky table" size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{ width: "250px" }} >
                                Project
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }}  >
                                Advance(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }}  >
                                Draft(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Submitted(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Approved(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "250px" }} >
                                Retention Deduction(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "250px" }} >
                                Retention Release(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "250px" }} >
                                Advance Deduction(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }}  >
                                Issued(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }}  >
                                Acknowledged(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Fully Fulfilled(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Partially Fulfilled(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Rejected(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Certified(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Paid(₹)
                            </TableCell>
                            <TableCell align="center" style={{ width: "200px" }} >
                                Total(₹)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projectsWiseAmountsPO && projectsWiseAmountsPO.map((rowData) => (
                            <TableRow>
                                <TableCell align="left" >
                                    {rowData?.projectDetails?.displayName}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.totalAdvanceAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.draftAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center" >
                                    {numberWithCommas(parseFloat(rowData?.submittedAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.approvedAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.retentionDeductionAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.retentionReleaseAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.advanceDeductionAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.issuedAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.acknowledgedAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center" >
                                    {numberWithCommas(parseFloat(rowData?.fullyFulfilledAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.partiallyFulfilledAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.rejectedAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.certifiedAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.paidAmount || 0).toFixed(2))}
                                </TableCell>
                                <TableCell align="center"  >
                                    {numberWithCommas(parseFloat(rowData?.totalOpsOrderAmount || 0).toFixed(2))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>

        {(projects && projects.length > 0) && (
            <div className={classes.allProjectsAnalysisCont} >
                <div className={classes.headerCont} >
                    <h3>{selectedProject?._id ? selectedProject?.displayName : "Select Project"}</h3>
                    <div className={classes.optionsCont} >
                        <CustomSelectAutocomplete
                            value={selectedProject}
                            onChange={(value) => {
                                setSelectedProject(value);
                            }}
                            isMulti={false}
                            options={projects}
                            fullWidth={true}
                            placeholder={"Select Project"}
                            getLabel={(s) => s?.displayName}
                            getIdentity={(s) => s?._id}
                            getImage={(s) => s?.displayPicture?.url}
                            getDisplayName={(s) => s?.displayName}
                            getUsername={(s) => s?.username}
                        />
                    </div>
                </div>
                <div className={classes.projectAnalysisBody} >
                    {selectedProject?._id && (
                        <ProjectContactAnalysis
                            entity={"Project"}
                            curEntityId={selectedProject?._id}
                            secondPartyId={secondPartyId}
                        />
                    )}
                </div>
            </div>
        )}
    </div>)}</>);
}