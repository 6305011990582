import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import locationEmptyIcon from "../../../Assets/locationTagEmpty.svg"
import GooglePlaceAutocomplete from "./Google.Place.Auto";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
    statesNameCont: {
        paddingTop: "10px",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        "& div": {
            display: "flex",
            position: "relative",
            alignItems: "center",
            marginRight: "8px",
            marginBottom: "7px",
            fontSize: "16px",
            fontWeight: "400",
            borderRadius: "4px",
            padding: "0px 6px",
            backgroundColor: "#E9F0F8",
            "& .iconCont": {
                display: "none"
            },
            "&:hover .iconCont": {
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                top: "0px",
                left: "0px",
                paddingTop: "1px",
                backgroundColor: "rgba(255, 255, 255, 0.4)"
            }
        }
    },
    searchFieldStyle: {
        backgroundColor: "white",
        border: "2px solid #f5f5f5",
    },
    addressContStyle: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    removeLocation: {
        fontSize: "24px",
        cursor: "pointer",
    },
    autocompleteCont: {
        display: "flex",
        alignItems: "center",
    },
    iconTagCont: {
        width: "100%",
        marginBottom: "15px",
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "10px 0px",
        "& img": {
            height: "150px",
            width: "auto",
        },
        [theme.breakpoints.down('md')]: {
            "& img": {
                width: "220px",
                height: "auto"
            }
        },
        [theme.breakpoints.down('sm')]: {
            "& img": {
                width: "170px",
                height: "auto"
            }
        }
    }
}));

function AddLocationTag(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { marketList, setMarketList } = props;

    //for google autocomplete
    const [streetAddress, setStreetAddress] = useState("");
    const [city, setCity] = useState("");
    const [region, setRegion] = useState("");
    const [country, setCountry] = useState("India(IN)");
    const [zip, setZip] = useState("");
    const [fullAddressLine, setFullAddressLine] = useState("");
    const [regionCode, setRegionCode] = useState("");
    const [countryCode, setCountryCode] = useState("in");
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [marketSet, setMarketSet] = useState(new Set());

    const handleDeleteChip = (chipToDelete) => {
        setMarketList((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
        let newSet = new Set(marketSet);
        newSet.delete(chipToDelete.key);
        setMarketSet(newSet);
    }

    const addChip = (lat, lon, val, newAddress) => {
        let key = `${lat}$${lon}`;
        let newSet = new Set(marketSet);
        if (newSet.has(key) == false) {
            let updatedList = [...marketList];
            updatedList.push({ key: key, label: val, address: newAddress });

            setMarketSet([...marketSet, key]);
            setMarketList(updatedList);
        }

        setStreetAddress("");
        setCity("");
        setRegion("");
        setCountry("India(IN)");
        setZip("");
        setFullAddressLine("");
        setRegionCode("");
        setCountryCode("in");
        setLatitude(null);
        setLongitude(null);
    }

    return (
        <>
            <GooglePlaceAutocomplete
                inputContStyle={classes.addressContStyle}
                autoWidth={"100%"}
                textWidth={"100%"}
                isGetLogLat={false}
                fullAddressLine={fullAddressLine}
                setFullAddressLine={setFullAddressLine}
                streetAddress={streetAddress}
                setStreetAddress={setStreetAddress}
                zip={zip}
                setZip={setZip}
                city={city}
                setCity={setCity}
                region={region}
                setRegion={setRegion}
                regionCode={regionCode}
                setRegionCode={setRegionCode}
                country={country}
                setCountry={setCountry}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                latitude={latitude}
                setLatitude={setLatitude}
                longitude={longitude}
                setLongitude={setLongitude}
                isShowCountry={false}
                isShowCityStreet={false}
                isStateZip={false}
                addChip={addChip}
                onlySearchBox={true}
            />

            {marketList?.length > 0 ? (
                <div className={classes.tagsContainer}>
                    {marketList.map((ele) => (
                        <Chip
                            key={ele.key}
                            label={ele.label}
                            style={{ marginRight: "5px", marginTop: "5px" }}
                            onDelete={() => handleDeleteChip(ele)}
                        />
                    ))}
                </div>
            ) : (
                <div className={classes.iconTagCont} >
                    <img src={locationEmptyIcon} />
                </div>
            )}
        </>
    );
}

export default AddLocationTag;