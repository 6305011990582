import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import AnalysisCardSvg from "../../../Assets/analysisCard.svg";
import AnalysisSingleRow from "./Analysis.Single.Card";
import Table from "@material-ui/core/Table";
import { useMediaQuery } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from '@material-ui/lab/Skeleton';
import emptyIcon from "../../../Assets/vivekEmpty.svg"

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "100%",
    height: "370px",
    borderRadius: "10px",
    marginBottom: "20px",
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 5px",
    },
  },
  cardTopHeading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > h3": {
      fontWeight: "510",
      fontSize: "17px",
      color: "#193B56"
    }
  },
  createBtn: {
    width: "25px",
    height: "25px",
    borderRadius: "6px",
    backgroundColor: "#c1def6",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#70b8f4",
      color: "white"
    }
  },
  topBoxsCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10px",
    marginBottom: "20px"
  },
  topBoxSvgCont: {
    width: "120px",
    height: "70px",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    "& > img": {
      width: "95px",
      height: "95px",
    }
  },
  topBox: {
    width: "100%",
    padding: "3px 10px",
    display: "flex",
    cursor: "pointer",
    alignItems: "flex-start",
    marginTop: "10px",
    marginBottom: "20px",
    marginRight: "15px",
    "& > p": {
      fontSize: "15px",
      marginTop: "10px",
      color: "#696969"
    },
    "&:hover": {
      backgroundColor: "#edecec"
    }
  },
  criteriaImageCont: {
    width: "120px",
    height: "70px",
    paddingTop: "11px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > img": {
      width: "80px",
      height: "80px",
    }
  },
  compareImageCont: {
    width: "100px",
    height: "70px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > img": {
      width: "100px",
      height: "100px",
    }
  },
  tbHeaderCell: {
    borderTop: "1px solid #e0e0e0",
    color: "#696969",
    fontSize: "15px",
    padding: "0px",
  },
  container: {
    width: "100%",
    height: "215px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  skeletonCont: {
    paddingTop: "10px",
    width: "100%",
    height: "155px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  emptyCont: {
    display: "flex",
    width: "100%",
    height: "150px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    "& img": {
      height: "100px",
      width: "auto"
    },
    "& p": {
      fontSize: "12px",
      color: "gray",
      textAlign: "center",
      marginTop: "5px"
    }
  }
}));

const ProjectAnalysisCard = ({ reports, loader, teamId }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Paper className={classes.cardContainer}>
      <div className={classes.cardTopHeading} >
        <h3>Investment Analysis</h3>
        <IconButton
          className={classes.createBtn}
          onClick={() => { history.push("/investment/analysis/new") }}
          color="primary"
        >+</IconButton>
      </div>

      <Paper
        elevation={1}
        onClick={() => history.push("/analysis/" + teamId)}
        className={classes.topBox}
      >
        <div className={classes.topBoxSvgCont} >
          <img src={AnalysisCardSvg} />
        </div>
        <p>Create Rentals, BRRRRs, Flips report for analyzing this investment property</p>
      </Paper>

      {loader ? (<>
        <TableContainer style={{ width: "100%" }} >
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow style={{ position: "relative", zIndex: "99", height: "40px" }} >
                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px" }} >
                  Details
                </TableCell>
                <TableCell align="center" className={classes.tbHeaderCell} style={{ padding: "0px", width: "90px" }} >
                  ROI
                </TableCell>
                <TableCell align="right" className={classes.tbHeaderCell} style={isExSmall ? { paddingRight: "5px", width: "85px" } : { paddingRight: "5px", width: "120px" }} >
                  {isExSmall ? "Purchase" : "Purchase Price"}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <div className={classes.skeletonCont} >
          <Skeleton variant="rect" style={{ width: "100%", marginBottom: "10px" }} height={50} />
          <Skeleton variant="rect" style={{ width: "100%", marginBottom: "10px" }} height={50} />
          <Skeleton variant="rect" style={{ width: "100%", marginBottom: "10px" }} height={50} />
        </div>
      </>) : (<>{reports && reports.length > 0 ? (
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow style={{ position: "relative", zIndex: "99", height: "40px" }} >
                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px" }} >
                  Details
                </TableCell>
                <TableCell align="center" className={classes.tbHeaderCell} style={{ padding: "0px", width: "90px" }} >
                  ROI
                </TableCell>
                <TableCell align="right" className={classes.tbHeaderCell} style={isExSmall ? { paddingRight: "5px", width: "85px" } : { paddingRight: "5px", width: "120px" }} >
                  {isExSmall ? "Purchase" : "Purchase Price"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map((reportData, index) => (
                <AnalysisSingleRow key={index} reportData={reportData} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (<>
        <TableContainer style={{ width: "100%" }} >
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow style={{ position: "relative", zIndex: "99", height: "40px" }} >
                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px" }} >
                  Details
                </TableCell>
                <TableCell align="center" className={classes.tbHeaderCell} style={{ padding: "0px", width: "90px" }} >
                  ROI
                </TableCell>
                <TableCell align="right" className={classes.tbHeaderCell} style={isExSmall ? { paddingRight: "5px", width: "85px" } : { paddingRight: "5px", width: "120px" }} >
                  {isExSmall ? "Purchase" : "Purchase Price"}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <div className={classes.emptyCont} >
          <img src={emptyIcon} />
          <p>There is nothing to show here.
            <br />
            <span
              style={{
                color: theme.palette.primary.main,
                marginLeft: "3px",
                cursor: "pointer"
              }}
              onClick={() => { history.push("/analysis/" + teamId) }}
            >Click to add analysis report.</span>
          </p>
        </div>
      </>)}</>)}
    </Paper>
  );
};
export default ProjectAnalysisCard;