const { default: Api } = require("../../../helpers/Api");

export const getCategoryByName = async (obj) => {
  try {
    const res = await Api.post("category/getCats", obj);
    const data = res?.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createNewCategory = async (obj) => {
  try {
    const res = await Api.post("create/category/new", obj);
    const data = res?.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const pinUpBlogAndUnPinBlogs = async (obj) => {
  try {
    const res = await Api.post("blog/pinUpBlogs", obj);
    const data = res?.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllFiles = async (obj) => {
  try {
    const res = await Api.post("file/get", obj);
    const data = res?.result;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const userAdvanceSearch = async (obj) => {
  try {
    const res = await Api.post("search/advance/globalSearch", obj);
    const data = res?.data || [];
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getProfileForEntitySearch = async (obj) => {
  try {
    const res = await Api.post("get/entity/profiles", obj);
    const data = res?.data || {};
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createAndUpdateFinalcialRelation = async (obj) => {
  try {
    const res = await Api.post("wallet/relation/find/update/add/multiple-relation",obj);
    const data = res?.data || {};
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllAdminProjectsAndOrgs = async (obj) => {
  try {
    const res = await Api.post("profile/get/admin/project/organization", obj);
    const data = res?.data || {};
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createPalApiCall = async (obj) => {
  try {
    const res = await Api.post("pal/create", obj);
    const data = res?.data || {};
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updatePalApiCall = async (obj) => {
  try {
    const res = await Api.post("pal/update", obj);
    const data = res?.data || {};
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getOptionsForMuiAutocomplete = async (url, obj) => {
  try {
    const res = await Api.post(url, obj);
    const data = res?.data || [];

    const checkDisplayNames = data?.map((user) => {
      if (!user?.displayName) {
        const obj = user;
        obj["displayName"] = " ";
        return obj;
      }
      return user;
    });
    return checkDisplayNames;
  } catch (error) {
    console.log(error);
  }
};

export const updateFileInParent = async (obj) => {
  try {
    console.log("abi", obj);
    const res = await Api.post("file/update/in-parent", obj);
    console.log("abi", obj);
    const data = res?.data || [];
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getEntityByFilter = async (obj) => {
  try {
    const res = await Api.post("search/entity/by-filter", obj);
    const data = res?.data || [];
    return data;
  } catch (error) {
    console.log(error);
    return []
  }
};
