import React, { useEffect, useState } from "react";
import Dialog from "../styled/generic/Dialog";
import SpaceBetween from "../styled/generic/SpaceBetween";
import FormBox from "../styled/generic/FormBox";
import Select from "../styled/generic/Select";
import { MenuItem } from "@mui/material";
import TextField from "../styled/generic/TextField";

const ProjectUnitCreateDialog = ({ open, setOpen, project, projectBlocks }) => {
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [unitName, setUnitName] = useState("");
  const [buildupArea, setBuildupArea] = useState("");
  const [unitType, setUnitType] = useState("2BHK");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (projectBlocks?.length > 0) {
      setSelectedBlock(projectBlocks[0]);
    }
  }, [projectBlocks]);

  const unitTypes = ["2BHK", "3BHK", "4BHK", "5BHK", "6BHK", "7BHK"];

  return (
    <Dialog title="Add Unit" open={open} setOpen={setOpen}>
      <SpaceBetween
        left={
          <FormBox label="Block">
            <Select
              value={selectedBlock}
              onChange={(e) => setSelectedBlock(e.target.value)}
              fullWidth
            >
              {projectBlocks?.map((item) => (
                <MenuItem key={item?._id} value={item}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormBox>
        }
        right={
          <FormBox label="Unit Type">
            <Select
              fullWidth
              value={unitType}
              onChange={(e) => {
                setUnitType(e.target.value);
              }}
            >
              {unitTypes.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormBox>
        }
      />
      <SpaceBetween
        left={
          <FormBox label="Unit Name">
            <TextField
              fullWidth
              value={unitName}
              onChange={(e) => setUnitName(e.target.value)}
            />
          </FormBox>
        }
        right={
          <FormBox label="Buildup Area">
            <TextField
              fullWidth
              value={buildupArea}
              onChange={(e) => setBuildupArea(e.target.value)}
            />
          </FormBox>
        }
      />
      <FormBox label="Description">
        <TextField
          value={description}
          onChange={(evt) => setDescription(evt.target.value)}
          fullWidth
          multiline
          rows={4}
          placeholder="Enter Description"
          inputProps={{ maxLength: 200 }}
          helperText={`${description.length}/200`}
        />
      </FormBox>
    </Dialog>
  );
};

export default ProjectUnitCreateDialog;
