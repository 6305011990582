import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import UserPalAutocomplete from "../../UserPalAutocomplete";
import teamUtils from "../team.utils";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		[theme.breakpoints.down("xs")]: {
			alignItems: "flex-start",
		}
	},
	titleSty: {
		fontSize: "18px",
		fontWeight: "510",
		marginBottom: "25px"
	},
	cardStyle: {
		width: "400px",
		borderRadius: "8px",
		display: "flex",
		flexDirection: "column",
		padding: "10px",
		marginTop: "25px",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		}
	},
	rowDiv: {
		width: "100%",
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "space-between",
		marginBottom: "15px",
		"& h3": {
			fontSize: "14px",
			fontWeight: "510"
		},
		"& p": {
			fontSize: "11px",
			fontWeight: "350",
			color: "gray"
		}
	},
	rowDivWrap: {
		width: "100%",
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
	},
	chipStyle: {
		marginTop: "7px",
		marginRight: "7px",
		height: "25px"
	},
	inviteChipStyle: {
		height: 28,
		width: 100,
	},
	loadingCont: {
		width: "100%",
		height: "350px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	doneSty: {
		backgroundColor: "ececec",
		color: "white",
		padding: "1px 7px",
		borderRadius: "3px",
		display: "flex",
		alignItems: "center",
	}
}));

const PlatformAdd = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { _createInvites } = teamUtils;
	const { teamId, invites, setInvites, roles, invitesLoading } = props;

	const { teamDictionary } = useSelector((state) => state.team);
	const { user } = useSelector((state) => state.auth);
	const team = teamDictionary[teamId]

	const [selectedMembers, setSelectedMembers] = useState([]);
	const [selectedMember, setSelectedMember] = useState(null);
	const [inviteeIds, setInviteeIds] = useState([]);
	const [notAllowedUserIds, setNotAllowedUserIds] = useState([]);
	const [notAllowedOrgIds, setNotAllowedOrgIds] = useState([]);
	const [notAllowedProfileIds, setNotAllowedProfileIds] = useState([]);



	useEffect(() => {
		let locNotAllowedUserIds = [], locNotAllowedOrgIds = [], locNotAllowedProfileIds = []
		let locParticipantsRole = teamDictionary[teamId]?.participantsRole || []
		invites.map((inviteData) => {
			if (inviteData?.invitee?.parentModelName === "User") {
				locNotAllowedUserIds.push(inviteData?.invitee?.parent?._id)
			} else if (inviteData?.invitee?.parentModelName === "Organization") {
				locNotAllowedOrgIds.push(inviteData?.invitee?.parent?._id)
			}
			locNotAllowedProfileIds.push(inviteData?.invitee?._id)
		})
		locParticipantsRole.map((participantRoleData) => {
			if (participantRoleData?.user?.parentModelName === "User") {
				locNotAllowedUserIds.push(participantRoleData?.user?.parent?._id)
			} else if (participantRoleData?.user?.parentModelName === "Organization") {
				locNotAllowedOrgIds.push(participantRoleData?.user?.parent?._id)
			}
			locNotAllowedProfileIds.push(participantRoleData?.user?._id)
		})
		setNotAllowedUserIds(locNotAllowedUserIds)
		setNotAllowedOrgIds(locNotAllowedOrgIds)
		setNotAllowedProfileIds(locNotAllowedProfileIds)


		let locInviteeIds = []
		invites.map((invite) => {
			if (invite?.invitee?._id) {
				locInviteeIds.push(invite.invitee._id);
			}
		});
		setInviteeIds(locInviteeIds)
	}, [invites, teamDictionary[teamId]?.participantsRole])

	const onUserSelect = (member) => {
		const selectedMemberIds = selectedMembers.map((member) => member?.member?._id) || [];
		const totalIds = Array.from(new Set([...selectedMemberIds, ...notAllowedProfileIds]));

		if (member && member?._id && !totalIds.includes(member?._id)) {
			const newObj = {
				member: member,
				role: "Viewer",
				invitionSend: false
			}
			setSelectedMembers([newObj, ...selectedMembers]);
			setSelectedMember(member);

			let parentId = member?.parent?._id
			let memberProfileId = member?._id
			if (member?.parentModelName === "User") {
				setNotAllowedUserIds([...notAllowedUserIds, parentId])
			} else if (member?.parentModelName === "Organization") {
				setNotAllowedOrgIds([...notAllowedOrgIds, parentId])
			}
			setNotAllowedProfileIds([...notAllowedProfileIds, memberProfileId])
		}
	};

	const _createPlatformInvites = (memberRoles, team, user, index) => {
		_createInvites(memberRoles, team, user)
			.then((res) => {
				const results = res.result;
				if (results && results.length > 0) {
					setInvites([...invites, ...results]);
				}

				let locArr = [...selectedMembers]
				locArr[index].invitionSend = true;
				setSelectedMembers(locArr)
			})
			.catch((err) => {
				console.log(err);
			})
	};




	return (
		<div className={classes.root}>
			{invitesLoading && notAllowedUserIds.length > 0 ? (<div className={classes.loadingCont} >
				<CircularProgress />
			</div>) : (<>
				<h3 className={classes.titleSty} >Search on our network</h3>
				<UserPalAutocomplete
					placeholder={"Search "}
					participant={selectedMember}
					setParticipant={onUserSelect}
					inviteeIds={inviteeIds}
					notAllowedUserIds={notAllowedUserIds}
					notAllowedOrgIds={notAllowedOrgIds}
					notAllowedProfileIds={notAllowedProfileIds}
				/>
				{selectedMembers.map((member, index) => (
					<Paper key={index} elevation={1} className={classes.cardStyle} >
						<div className={classes.rowDiv}>
							<div style={{ display: "flex", alignItems: "center" }} >
								<Avatar
									style={{ width: "35px", height: "35px" }}
									alt={member?.member?.parent?.displayName}
									src={member?.member?.parent?.displayPicture?.thumbUrl || member?.member?.parent?.displayPicture?.url}
								/>
								<div style={{ marginLeft: "5px" }} >
									<h3>{member?.member?.parent?.displayName}</h3>
									<p>{member?.member?.parent?.email}</p>
								</div>
							</div>


							{member?.invitionSend ? (<div className={classes.doneSty} >
								<CheckCircleIcon style={{ fontSize: "25px", color: "green", marginRight: "5px" }} /> <p>Sent</p>
							</div>) : (
								<Chip
									label={"Send Invite"}
									color={"primary"}
									className={classes.inviteChipStyle}
									style={{ marginLeft: "9px" }}
									onClick={() => {
										const memberRole = {
											member: member?.member,
											role: member?.role || "Viewer",
										};
										_createPlatformInvites([memberRole], team, user, index);
									}}
								/>
							)}
						</div>
						<div className={classes.rowDivWrap}>
							{roles.map((role, i) => (
								<Chip
									key={i}
									label={role}
									className={classes.chipStyle}
									color={role === member?.role ? "primary" : "disabled"}
									onClick={() => {
										let locSelectedMembers = [...selectedMembers]
										locSelectedMembers[index].role = role;
										setSelectedMembers(locSelectedMembers)
									}}
								/>
							))}
						</div>
					</Paper>
				))}
			</>)}
		</div>
	);
};

export default PlatformAdd;