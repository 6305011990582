import {
    Box, Grid, MenuItem, Pagination, Skeleton
} from "@mui/material";
import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@material-ui/core";
import Button from "../styled/generic/Button";
import SearchField from "../styled/generic/SearchField";
import Select from "../styled/generic/Select";
import { getActivities, getOptionsForActivitiesFilter } from "./api.call";
import { useDebounce } from "react-use";
import { FaFilter } from "react-icons/fa6";
import { useHistory } from "react-router-dom";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import ActivityCard from "./activity.card";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white"
      },
      header: {
        padding: theme.spacing(1, 2),
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      bodyCont: {
        flexGrow: 1,
        overflowY: "auto",
        padding: theme.spacing(2),
      },
      paginationCont: {
        padding: theme.spacing(1),
        borderTop: `1px solid ${theme.palette.divider}`,
      },
      filterInputOp: {
        width: 450,
        padding: theme.spacing(2),
      },
      activityCard: {
          minWidth: 380, // Set a minimum width for the cards
          maxWidth: 420, // Set a maximum width for the cards
      }
}));

const ORDER_OPTIONS = {
    "Title": "title",
    "Created Date": "createdAt"
}

const ActivitiesList = ({ entityType, modules: propModules, profileId: propProfileId  }) => {
    const classes = useStyles();
    const { profileId: paramProfileId } = useParams();
    const profileId = propProfileId || paramProfileId;

    const dispatch = useDispatch();
    const history = useHistory();

    const [searchStr, setSearchStr] = useState("");
    const [orderFilter, setOrderFilter] = useState("");
    const [orderFilterOrder, setOrderFilterOrder] = useState("Descending");
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)
    const [totalPages, setTotalPages] = useState(0)
    const [stateChange, setStateChange] = useState(false)
    const [filterProjects, setFilterProjects] = useState([])
    const [filterProjectsOptions, setFilterProjectsOptions] = useState([])
    const [filterType, setFilterType] = useState("")
    const [filterTypeOptions, setFilterTypeOptions] = useState([])
    const [filterModuleOptions, setFilterModuleOptions] = useState(propModules || [])
    const [filterModule, setFilterModule] = useState("")
    const [totalCount, setTotalCount] = useState(0);

    const [profile, setProfile] = useState(null);
    const [filterModules, setFilterModules] = useState(propModules || []);

    useEffect(() => {
        getOptionsForActivitiesFilter({ profileId: profileId })
            .then((data) => {
                console.log(data);
                setProfile(data?.profile);
                setFilterProjectsOptions(data?.projects || []);
                setFilterTypeOptions(data?.activityTypes || []);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [profileId]);

    const getActivitiesForListView = async () => {
        try {
            setLoading(true);
            const result = await getActivities({
                profileId,
                searchStr,
                orderFilter: ORDER_OPTIONS?.[orderFilter] || 'createdAt',
                orderFilterOrder: orderFilterOrder === "Ascending" ? 1 : -1,
                curPage: curPage - 1,
                limit: pageLimit,
                types: filterType ? [filterType] : [],
                projects: filterProjects ? filterProjects.map((s) => s?._id) : [],
                modules: filterModules,
            });

            if (result) {
                setActivities(result.activities || []);
                setTotalCount(result.totalCount || 0);
                setTotalPages(result.totalPages || 0);
            } else {
                dispatch({
                    type: "AddApiAlert",
                    payload: {
                        success: false,
                        message: "An unknown error occurred while fetching activities",
                    },
                });
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: false,
                    message: "An unknown error occurred while fetching activities",
                },
            });
        } finally {
            setLoading(false);
        }
    };

    useDebounce(() => {
        getActivitiesForListView();
    }, 1000, [stateChange])

    useEffect(() => {
        setStateChange(!stateChange)
    }, [curPage]);

    useEffect(() => {
        setCurPage(1)
        setStateChange(!stateChange)
    }, [searchStr, orderFilter, orderFilterOrder, filterType, filterProjects, filterModules]);

    return (
        <div className={classes.mainCont} >
            <div className={classes.header} >
                <MyPopOver
                    closeOnClick={false}
                    appearContent={
                        <Button
                            startIcon={<FaFilter />}
                            variant="outlined"
                            color="primary"
                        >
                            Add Filter
                        </Button>
                    }
                    showContent={<div className={classes.filterInputOp} >
                        <Box flex="1" style={{ marginBottom: "15px" }} >
                            <SearchField
                                value={searchStr}
                                onChange={(e) => setSearchStr(e.target.value)}
                                sx={{
                                    backgroundColor: "white",
                                    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.04)",
                                }}
                                fullWidth={true}
                                size="small"
                            />
                        </Box>
                        {entityType === "Organization" && (
                            <Box flex="1" style={{ marginBottom: "15px" }} >
                                <Autocomplete
                                    options={filterProjectsOptions}
                                    value={filterProjects}
                                    onChange={(event, value) => {
                                        setFilterProjects(value)
                                    }}
                                    fullWidth
                                    multiple={true}
                                    size="small"
                                    getOptionLabel={(option) => option?.displayName}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            {...params}
                                            placeholder={"Select project"}
                                        />
                                    )}
                                />
                            </Box>
                        )}
                        <Box flex="1" style={{ marginBottom: "15px" }} >
                            <Select
                                value={filterType}
                                onChange={(e) => setFilterType(e.target.value)}
                                displayEmpty
                                size="small"
                                style={{ width: "100%" }}
                            >
                                <MenuItem value="">Filter by Type (None)</MenuItem>
                                {["Comment", "Like", "Share", "Create", "Update", "Delete"].map((option) => (
                                    <MenuItem value={option}>{option}</MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box flex="1" style={{ marginBottom: "15px" }}>
                            <Autocomplete
                                multiple
                                options={filterModuleOptions}
                                value={filterModules}
                                onChange={(event, value) => setFilterModules(value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Filter by Modules"
                                        placeholder="Select modules"
                                    />
                                )}
                            />
                        </Box>
                        <Box flex="1" >
                            <BoxSpaceBetween>
                                <Select
                                    value={orderFilter}
                                    onChange={(e) => setOrderFilter(e.target.value)}
                                    displayEmpty
                                    size="small"
                                    style={{ width: "200px" }}
                                >
                                    <MenuItem value="">Filter (None)</MenuItem>
                                    {Object.keys(ORDER_OPTIONS).map((option) => (
                                        <MenuItem value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    value={orderFilterOrder}
                                    onChange={(e) => setOrderFilterOrder(e.target.value)}
                                    displayEmpty
                                    size="small"
                                    style={{ width: "200px" }}
                                >
                                    <MenuItem value="Ascending">Ascending</MenuItem>
                                    <MenuItem value="Descending">Descending</MenuItem>
                                </Select>
                            </BoxSpaceBetween>
                        </Box>
                    </div>}
                />
                <div></div>
            </div>
            <Box className={classes.bodyCont}>
                <Grid container spacing={2}>
                {loading ? (
                    Array.from(new Array(9)).map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Skeleton variant="rectangular" width="100%" height={160} />
                    </Grid>
                    ))
                ) : (
                    activities.map((activity) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={activity._id} className={classes.activityCard}>
                            <ActivityCard
                                activity={activity}
                            />
                        </Grid>
                        ))
                )}
                </Grid>
            </Box>
            <div className={classes.paginationCont} >
                <Pagination
                    count={totalPages}
                    page={curPage}
                    color="primary"
                    siblingCount={0}
                    onChange={(event, value) => { setCurPage(value) }}
                />
            </div>
        </div>
    );
};

export default ActivitiesList;