import { Box, Typography } from "@mui/material";
import React from "react";

const FormBox = ({
  label,
  labelTypographyVariant = "h6",
  children,
  disableMargins,
  labelColor = "auto",
  hideLabel = false,
  fontWeight = "normal",
  sx = {}, // Allow custom styles to be passed in
  isRequired = false,
  requiredLabel = " *",
  requiredLabelColor = "error",
  fontSize,
  ...props
}) => {
  return (
    <Box
      width="100%"
      sx={{
        mb: disableMargins ? 0 : 2, // Default margin-bottom if not disabled
        pt: 0, // Optional: Ensure no top padding if not needed
        pb: 0, // Optional: Ensure no bottom padding if not needed
        ...sx // Apply any custom styles passed via `sx`
      }}
      {...props}
    >
      {!hideLabel && (
        <Typography
          color={labelColor}
          variant={labelTypographyVariant}
          gutterBottom
          fontSize={fontSize}
          sx={{
            fontWeight: fontWeight,
            mb: 0, // Ensure no margin-bottom for the label
          }}
          {...props}
        >
          {label}
          {isRequired && (
            <Typography
              color={requiredLabelColor}
              component="span"
              variant={labelTypographyVariant}
              fontSize={fontSize}
              sx={{
                fontWeight: fontWeight,
                ml: 0.5, // Add minimal spacing between label and required label
              }}
              
            >
              {requiredLabel}
            </Typography>
          )}
        </Typography>
      )}
      <Box sx={{ mt: 0 }}>{children}</Box> {/* Ensure no top margin for children */}
    </Box>
  );
};

export default FormBox;
