const getColorByLabel = (variant) => {
  let statusTextColor;
  let statusBackgroundColor;
  switch (variant) {
    case "Error":
      statusTextColor = "#E01507";
      statusBackgroundColor = "#E015071A";
      break;
    case "Success":
      statusTextColor = "#13B619";
      statusBackgroundColor = "#13B6191A";
      break;
    case "Info":
      statusTextColor = "#0671E0";
      statusBackgroundColor = "#0671E01A";
      break;
    case "Warning":
      statusTextColor = "#E48900";
      statusBackgroundColor = "#E489001A";
      break;
    default:
      statusTextColor = "#03CA17";
      statusBackgroundColor = "#03CA171A";
  }
  return { statusTextColor, statusBackgroundColor };
};

const getProgressColor = (progress) => {
  let color, backgroundColor;

  if (progress < 30) {
    color = "#E01507";
    backgroundColor = "#F9D0CD";
  } else if (progress < 70) {
    color = "#FAAD14";
    backgroundColor = "#FAE5CC";
  } else {
    color = "#127E1D";
    backgroundColor = "#D0E5D2";
  }
  return {
    color,
    backgroundColor,
  };
};

function getContrastColor(hexColor) {
  // Convert HEX color to RGB
  const rgb = hexToRgb(hexColor);
  // Calculate luminance
  const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
  // Return white or black based on luminance
  return luminance > 0.5 ? '#000000' : '#ffffff';
}

function hexToRgb(hexColor) {
  const hex = hexColor.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => {
      return r + r + g + g + b + b;
  });
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
  } : null;
}

export { getColorByLabel, getProgressColor, getContrastColor };
