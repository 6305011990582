import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import propertyManagement from "../../../Assets/propertyManagement.svg";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import emptyIcon from "../../../Assets/vivekEmpty.svg";
import Skeleton from "@material-ui/lab/Skeleton";
import Api from "../../../helpers/Api";
import CreatePortfolioDialog from "../Property/createPortfolio.dialog";
import { Avatar } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import LessText from "../../styled/CommonComponents/LessText";
import { format } from "timeago.js";

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		width: "100%",
		height: "370px",
		borderRadius: "10px",
		marginBottom: "20px",
		padding: "10px 15px",
		[theme.breakpoints.down("xs")]: {
			padding: "10px 5px",
		},
	},
	cardTopHeading: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		"& > h3": {
			fontWeight: "510",
			fontSize: "17px",
			color: "#193B56",
		},
	},
	createBtn: {
		width: "25px",
		height: "25px",
		borderRadius: "6px",
		backgroundColor: "#c1def6",
		color: theme.palette.primary.main,
		"&:hover": {
			backgroundColor: "#70b8f4",
			color: "white",
		},
	},
	topBox: {
		width: "100%",
		padding: "3px 10px",
		display: "flex",
		alignItems: "flex-start",
		marginTop: "10px",
		cursor: "pointer",
		marginBottom: "20px",
		"& > p": {
			fontSize: "15px",
			marginTop: "10px",
			color: "#696969",
		},
		"&:hover": {
			backgroundColor: "#edecec",
		},
	},
	topBoxSvgCont: {
		width: "120px",
		height: "70px",
		display: "flex",
		overflow: "hidden",
		alignItems: "center",
		justifyContent: "center",
		marginRight: "15px",
		"& > img": {
			width: "95px",
			height: "95px",
		},
	},
	container: {
		width: "100%",
		height: "215px",
		"&::-webkit-scrollbar": {
			display: "none",
		},
	},
	timeSty: {
		fontSize: "10px",
		fontWeight: "500",
		color: "gray",
	},
	iconSty: {
		fontSize: "45px",
	},
	tbHeaderCell: {
		borderTop: "1px solid #e0e0e0",
		color: "#696969",
		fontSize: "15px",
		padding: "0px",
	},
	tbBodyCell: {
		border: "none",
		padding: "10px 5px",
	},
	statusCont: {
		fontSize: "11px",
		padding: "1px 3px",
		borderRadius: "3px",
		backgroundColor: "gray",
		color: "white",
	},
	avatarGroupSty: {
		maxWidth: "100px",
		"& .MuiAvatar-root": {
			width: "30px",
			height: "30px",
		},
	},
	skeletonCont: {
		paddingTop: "10px",
		width: "100%",
		height: "175px",
		overflowY: "auto",
		"&::-webkit-scrollbar": {
			display: "none",
		},
	},
	emptyCont: {
		display: "flex",
		width: "100%",
		height: "175px",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "center",
		overflow: "hidden",
		"& img": {
			height: "100px",
			width: "auto",
		},
		"& p": {
			fontSize: "12px",
			color: "gray",
			textAlign: "center",
			marginTop: "5px",
		},
	},
}));

const PropertyManagementCard = ({ projectPortfolios, setProjectPortfolios, profileId, loader }) => {
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();

	const [open, setOpen] = useState(false);

	const getPortfolios = async () => {
		const res = await Api.post("projectportfolio/getbyuser", { profile: profileId });
		const data = res?.data;
		setProjectPortfolios(data);
	};


	return (<>
		<Paper className={classes.cardContainer}>
			<div className={classes.cardTopHeading}>
				<h3>Portfolio Management</h3>
				<IconButton
					className={classes.createBtn}
					onClick={() => {
						setOpen(true);
					}}
					color="primary"
				>
					+
				</IconButton>
			</div>

			<Paper
				elevation={1}
				onClick={() => history.push("/projectportfolio/" + profileId)}
				className={classes.topBox}
			>
				<div className={classes.topBoxSvgCont}>
					<img src={propertyManagement} />
				</div>
				<p>
					Create different portfolios for your rental units and analyze them
					
				</p>
			</Paper>


			{loader ? (<>
				<TableContainer style={{ width: "100%" }} >
					<Table stickyHeader aria-label="sticky table" size="small">
						<TableHead>
							<TableRow style={{ position: "relative", zIndex: "99", height: "40px" }} >
								<TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px" }} >
									Project Portfolio
								</TableCell>
								<TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "110px" }} >
									Participants
								</TableCell>
							</TableRow>
						</TableHead>
					</Table>
				</TableContainer>
				<div className={classes.skeletonCont} >
					<Skeleton variant="rect" style={{ width: "100%", marginBottom: "10px" }} height={50} />
					<Skeleton variant="rect" style={{ width: "100%", marginBottom: "10px" }} height={50} />
					<Skeleton variant="rect" style={{ width: "100%", marginBottom: "10px" }} height={50} />
				</div>
			</>) : (<>{projectPortfolios && projectPortfolios.length > 0 ? (<>
				<TableContainer className={classes.container}>
					<Table stickyHeader aria-label="sticky table" size="small">
						<TableHead>
							<TableRow style={{ position: "relative", zIndex: "99", height: "40px" }} >
								<TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px" }} >
									Portfolio Name
								</TableCell>
								<TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "150px" }} >
									Participants
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{projectPortfolios && projectPortfolios.length > 0 && projectPortfolios.map((row, index) => (
								<TableRow
									hover
									role="checkbox"
									tabIndex={-1}
									key={index}
									onClick={() => { history.push(`/projectportfolio/view/${row?._id}`) }}
									style={{ cursor: "pointer" }}
								>
									<TableCell align="left" className={classes.tbBodyCell} style={{ paddingLeft: "0px" }} >
										<div style={{ display: "flex", alignItems: "center" }} >
											<Avatar src={row?.displayPicture?.urll} style={{ width: "30px", height: "30px" }} />
											<div style={{ marginLeft: "5px" }} >
												<p style={{ fontSize: "13px", color: "#333333", fontWeight: "510", textTransform: "capitalize" }} >
													<LessText
														limit={23}
														string={row?.name}
													/>
												</p>
												<p className={classes.timeSty} >{format(row?.createdAt)}</p>
											</div>
										</div>
									</TableCell>
									<TableCell align="right" className={classes.tbBodyCell} style={{ padding: "10px 5px" }} >
										<div style={{ display: "flex", justifyContent: "flex-end" }} >
											<AvatarGroup max={4} className={classes.avatarGroupSty} >
												{row?.access && row?.access.length > 0 && row?.access.map((participant, idx) => (
													<Avatar
														key={idx}
														alt={participant?.parent?.displayName}
														src={participant?.parent?.displayPicture?.url}
													/>
												))}
											</AvatarGroup>
										</div>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</>) : (<>
				<TableContainer style={{ width: "100%" }} >
					<Table stickyHeader aria-label="sticky table" size="small">
						<TableHead>
							<TableRow style={{ position: "relative", zIndex: "99", height: "40px" }} >
								<TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px" }} >
									Project Portfolio
								</TableCell>
								<TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "110px" }} >
									Participants
								</TableCell>
							</TableRow>
						</TableHead>
					</Table>
				</TableContainer>
				<div className={classes.emptyCont} >
					<img src={emptyIcon} />
					<p>There is nothing to show here.
						<br />
						<span
							style={{
								color: theme.palette.primary.main,
								marginLeft: "3px",
								cursor: "pointer"
							}}
							onClick={() => { setOpen(true) }}
						>Click to add new Portfolio.</span>
					</p>
				</div>
			</>)}</>)}
		</Paper>

		<CreatePortfolioDialog
			open={open}
			setOpen={setOpen}
			addCreatedOne={getPortfolios}
		/>
	</>);
};
export default PropertyManagementCard;