import dayjs from "dayjs";
import React from "react";
import StatusChip from "./StatusChip";

const ListingTableNewItemCell = ({ date, hideAfterDays = 7 }) => {
  return (
    <div>
      {dayjs(date).isAfter(dayjs().subtract(hideAfterDays, "days")) && (
        <StatusChip variant="Success" label="New" />
      )}
    </div>
  );
};

export default ListingTableNewItemCell;
