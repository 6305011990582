import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Box,
  CircularProgress,
  Button,
  Drawer,
  IconButton,
  makeStyles,
  Paper,
  Grid
} from '@material-ui/core';
import emptyPage from "../../Assets/EmptyPageSVG.svg"
import { useHistory, useParams } from "react-router-dom";
import Api from '../../helpers/Api';
import ObservationCard from './ObservationCard';
import { useSelector, useDispatch } from 'react-redux';
import NormalDrawer from "../styled/CommonComponents/NormalDrawer"
import { getProfileDataPopulate } from '../team/procurements/api.call';
import { createDefaultTemplateHook } from '../approval/approvalHelper';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 400,
    [theme.breakpoints.up('sm')]: {
      width: 500,
    },
  },
  drawerPaper: {
    width: 400,
    [theme.breakpoints.up('sm')]: {
      width: 500,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  content: {
    padding: theme.spacing(2),
    height: drawerMode => drawerMode ? 'calc(100% - 64px)' : 'auto',
    overflowY: drawerMode => drawerMode ? 'auto' : 'visible',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  loadMoreButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  listContainer: {
    maxHeight: '600px',
    overflowY: 'auto',
    padding: theme.spacing(2),
  },
  createBtnAndCardsCont: {
    width: "100%",
    height: "100%",
    overflow: "hidden"
  },
  addBtnCont: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 10px",
  },
  allCardsCont: {
    width: "100%",
    height: "calc(100% - 50px)",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "10px"
  },
  emptyLibrarayCont: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "200px",
      height: "auto",
    },
    "& div": {
      width: "100%",
      marginTop: "10px",
      padding: "0px 20px",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "gray",
      textAlign: "center",
    },
    "& a": {
      textDecoration: "none",
      fontSize: "14px",
      marginLeft: "5px",
      cursor: "pointer",
      color: "blue"
    }
  },
}));

const ObservationList = ({
  open, setOpen, drawerMode = true, xs = 12, md = 6, lg = 4,
  queryProps, profileId, onObservationCreate
}) => {
  const classes = useStyles(drawerMode);
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  const [profileData, setProfileData] = useState(null)
  const [observations, setObservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [canCreate, setCanCreate] = useState(false);
  const [defaultCreateLoader, setDefaultCreateLoader] = useState(false)
  const limit = 10;

  //get details data of current scope
  const getProjectData = async () => {
    await getProfileDataPopulate({ profileId })
      .then((data) => {
        console.log(data)
        setProfileData(data)
        if (data?.parent?.wallet?.defaultLibrary?.defaultSiteObservationApproval?.stages && data.parent.wallet.defaultLibrary.defaultSiteObservationApproval.stages.length > 0) {
          const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultSiteObservationApproval?.stages[0];
          const approversArr = initialStage.approvers.map((approver) => approver?.profile)
          if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
            setCanCreate(true)
          } else {
            setCanCreate(false)
          }
        } else {
          setCanCreate(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getProjectData()
  }, [])

  const fetchObservations = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await Api.post('site-observation/getAll', {
        ...queryProps,
        skip: (page - 1) * limit,
        limit: limit,
      });
      const { observations: newObservations, totalCount } = response.data;
      setObservations(newObservations);
      setHasMore(observations.length + newObservations.length < totalCount);
    } catch (err) {
      setError('Failed to fetch observations. Please try again.');
      console.error('Error fetching observations:', err);
    } finally {
      setLoading(false);
    }
  }, [queryProps, page]);

  useEffect(() => {

    fetchObservations();

  }, [queryProps.parent, page]);

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  const createDefaultsTemplates = async () => {
    setDefaultCreateLoader(true)
    const entity = profileData?.parent?._id;
    const entityModelName = profileData?.parentModelName;
    const entityParent = profileData?.parent?.ownerProfile?.parent;
    const entityParentModelName = profileData?.parent?.ownerProfile?.parentModelName;
    await createDefaultTemplateHook({
      entity,
      entityModelName,
      entityParent,
      entityParentModelName,
      profileId: profileId,
      userId: user?._id,
      ownerProfileId: profileData?.parent?.ownerProfile?._id,
      types: ["Site Observation"],
      libraryId: profileData?.parent?.wallet?.defaultLibrary?._id
    })
      .then(async (data) => {
        console.log(data)
      })
      .catch(async (error) => {
        console.log(error)
      })
      .finally(async () => {
        await getProjectData();
      });
  }

  const createObservation = async () => {
    setLoading(true)
    try {
      const newObservation = await onObservationCreate();
      history.push(`/observation/edit/${newObservation?._id}`)
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Observation created successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to create observation",
        },
      });
    }
    setLoading(false)
  };

  const renderContent = (isForDrawer) => (
    <>
      {!profileData?.parent?.wallet?.defaultLibrary?._id ? (
        <div className={classes.emptyLibrarayCont} >
          <img src={emptyPage} />
          <div>
            <p>
              No Library exists in the current directory or any library still didn't selected as default library.<br />
              Click
              <a
                onClick={() => {
                  history.push(`/offerings-categories/management/${profileId}`)
                }}
              >here</a> to create and manage your library(s), category(s), product(s) and service(s)
            </p>
          </div>
        </div>
      ) : (<>{(!profileData?.parent?.wallet?.defaultLibrary?.defaultSiteObservationApproval) ? (
        <div className={classes.emptyLibrarayCont} >
          <img src={emptyPage} />
          <div>
            <p>{defaultCreateLoader ? `Template Creating....` : (<>
              Some template(s) are missing in the current directory or some template(s) are still didn't selected as default.<br />
              Click <a
                onClick={createDefaultsTemplates}
              >here</a> to create default template(s)
            </>)}
            </p>
          </div>
        </div>
      ) : (
        <div className={classes.createBtnAndCardsCont} >
          <div className={classes.addBtnCont} >
            <div></div>
            {canCreate ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={loading}
                onClick={createObservation}
              >
                Create Observation
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={true}
              >
                Create Observation
              </Button>
            )}
          </div>
          <div className={classes.allCardsCont} >
            {error && <Typography color="error">{error}</Typography>}
            <Grid container spacing={2}>
              {observations.map((observation) => (
                <ObservationCard
                  key={observation._id}
                  observation={observation}
                  xs={isForDrawer ? 12 : xs}
                  md={isForDrawer ? 12 : md}
                  lg={isForDrawer ? 12 : lg}
                  onClick={() => {
                    history.push(`/observation/preview/${observation?._id}`)
                  }}
                />
              ))}
            </Grid>
            {loading && (
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            )}
            {!loading && hasMore && (
              <Box className={classes.loadMoreButton}>
                <Button onClick={handleLoadMore} variant="contained" color="primary">
                  Load More
                </Button>
              </Box>
            )}
          </div>
        </div>
      )}
      </>
      )}
    </>
  );

  if (drawerMode) {
    return (
      <NormalDrawer
        openDrawer={open}
        setOpenDrawer={setOpen}
        anchor={"right"}
        width={"40vw"}
        title={"Observations"}
        content={<>
          {renderContent(true)}
        </>}
      />
    );
  } else {
    return (
      <>
        {renderContent(false)}
      </>
    );
  }
};

export default ObservationList;