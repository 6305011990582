import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { SlPencil } from "react-icons/sl";
import { IconButton } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Avatar from "@material-ui/core/Avatar";
import profileLanguage from "../../../Assets/profileCertification.svg";
import { TbCertificate } from "react-icons/tb";
import { IoOpenOutline } from "react-icons/io5";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Email } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  paper__section: {
    display: "flex",
    flexDirection: "column",
    fontSize: "1.05rem",
    marginTop: "1rem",
    border: "1px solid white",
    borderRadius: "3px",
    padding: "5px 15px 15px",
    "& $h2": {
      fontWeight: "400",
      fontSize: "1.5rem",
      display: "inline",
    },
    "& p": {
      color: "grey",
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      "& p": {
        width: "100%",
      },
    },
  },
  section__header: {
    display: "flex",
    // justifyContent: "space-between",
    gap: "10px",
    alignItems: "center",
    "& h3": {
      fontSize: "20px",
      fontWeight: "600",
      color: "#00345d",
    },
    [theme.breakpoints.down("xs")]: {
      "& h3": {
        width: "160px",
      },
    },
  },
  section__header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h3": {
      fontSize: "20px",
      color: "#2e73f8",
    },
  },
  emptyCont: {
    padding: "10px 0px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "& img": {
      width: "auto",
      height: "130px",
      marginTop: "-40px",
      marginBottom: "-15px",
    },
  },
  allExpEdit: {
    padding: "0px 30px",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      padding: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  singleExperienceCont: {
    display: "flex",
    alignItems: "flex-start",
    padding: "25px 5px",
  },
  imageCont: {
    marginRight: "15px",
  },
  experienceRight: {
    width: "calc(100% - 60px)",
    marginTop: "-4px",
    "& h3": {
      fontSize: "20px",
      fontWeight: "500",
    },
    "& h4": {
      fontSize: "16px",
      fontWeight: "450",
      display: "flex",
      alignItems: "center",
    },
    "& h5": {
      fontSize: "14px",
      fontWeight: "350",
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      "& h5": {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
  },
  credentialLink: {
    width: "175px",
    border: "1px solid gray",
    padding: "2px 0px",
    fontSize: "16px",
    fontWeight: "500",
    color: "gray",
    marginTop: "10px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
}));

const colorArr = ["#F39014", "#0A66C2", "#36e055"];

const EmailSection = ({
  isOwnProfile,
  profile,
  setShowLicense,
  setDialog,
  setOpenEditView,
  setProfileEditView,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const { user } = useSelector((state) => state.auth);

  return (
    <div>
      {isOwnProfile && (
        <Paper className={classes.paper__section}>
          <div className={classes.section__header}>
            <Email style={{ marginBottom: "-2px" }} />
            <h3>Business emails</h3>
          </div>
          <Box
            sx={{
              padding: 1,
            }}
          >
            {user.verifiedBusinessEmails.map((email, index) => (
              <Typography variant="body1" color={"HighlightText"} key={index}>
                {index + 1}) {email}
              </Typography>
            ))}
          </Box>
        </Paper>
      )}
    </div>
  );
};

export default EmailSection;
