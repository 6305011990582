import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Checkbox from '@material-ui/core/Checkbox';
import { v4 as uuidv4 } from 'uuid';
import {
  Typography, Dialog, Chip, Box,
  DialogTitle, DialogContent, DialogActions, Radio, RadioGroup,
  InputBase, Switch, Paper
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { alpha } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring/web.cjs';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  addCheckpointToParent,
  removeCheckpointFromParent,
  updateCheckpoint,
  addCheckpointToChecklist,
  removeCheckpointFromChecklist,
} from './Api.call';

import {
  addObservationToParent
} from '../sitereport/Api.call';
import { useSelector, useDispatch } from "react-redux";
import { debounce } from 'lodash';
import AddObservationDialog from '../sitereport/AddObservationDialog'
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import EditObservationDialog from '../sitereport/EditObservationDialog';
import { FaBinoculars } from 'react-icons/fa'
import ObservationList from '../sitereport/ObservationList';
import CustomFileUploadButton from '../file/Uploader/CustomFileUploadButton';
import { getAllFiles } from '../community/api.call';
import Modal from '@material-ui/core/Modal';
import AttachmentIcon from '@material-ui/icons/Attachment';
import FilesViewer from '../file/Viewer/FilesViewer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // maxWidth: 800,
  },
  loader: {
    marginLeft: theme.spacing(1),
  },
  itemContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between",
    padding: theme.spacing(0.5, 0),
  },
  leftPartCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  rightPartCont: {
    display: "flex",
    alignItems: "center",
    "& .MuiSelect-selectMenu": {
      padding: "10px 10px !important"
    }
  },
  checkpointType: {
    marginLeft: theme.spacing(1),
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
  },
  label: {
    marginRight: theme.spacing(1),
  },
  inputField: {
    marginLeft: theme.spacing(1),
    minWidth: 150,
  },
  blockLabel: {
    fontWeight: 'bold',
  },
  addCheckpointForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  addCheckpointInput: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
  },
  typeChip: {
    marginLeft: theme.spacing(1),
    height: 24,
  },
  title: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  statsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  statChip: {
    marginLeft: theme.spacing(1),
    height: 24,
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.contrastText,
  },
  blockStats: {
    marginLeft: theme.spacing(1),
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
  },
  textInput: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  editButton: {
    marginLeft: theme.spacing(1),
  },
  textDisplay: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  observationButton: {
    color: theme.palette.info.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.info.main, 0.1),
    },
  },
}));


const CheckpointAddModal = ({
  open, onClose, onAdd, isEditMode, initialData, parentCheckpoint, parentId, parentModelName, checklistId
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { createdFileIds } = useSelector((state) => state.file);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [checkpointType, setCheckpointType] = useState('boolean');
  const [options, setOptions] = useState(['']);
  const [isBlock, setIsBlock] = useState(false);
  const [currentParentCheckpoint, setCurrentParentCheckpoint] = useState(null);
  const [file, setFile] = useState(null)

  useEffect(() => {
    if (open) {
      setTitle(initialData?.title || '');
      setDescription(initialData?.description || '');
      setCheckpointType(initialData?.checkpointType || 'boolean');
      setOptions(initialData?.options || ['']);
      setIsBlock(initialData?.isBlock || false);
      setCurrentParentCheckpoint(parentCheckpoint);
    }
  }, [open, initialData, parentCheckpoint]);

  const handleAdd = () => {
    onAdd({
      title,
      description,
      checkpointType: isBlock ? 'boolean' : checkpointType,
      options: checkpointType === 'multipleChoice' ? options : [],
      isBlock,
      files: file?._id ? [file?._id] : [],
      checklistId,
      parentId,
      parentModelName,
      parentCheckpoint: currentParentCheckpoint ? currentParentCheckpoint._id : null,
      ancestorCheckpoints: currentParentCheckpoint
        ? [currentParentCheckpoint._id, ...(currentParentCheckpoint.ancestorCheckpoints || [])]
        : []
    });
    onClose();
    setFile(null)
  };

  const handleAddOption = () => {
    setOptions([...options, '']);
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const createNewDocs = async () => {
    if (createdFileIds.length > 0) {
      await getAllFiles({ files: createdFileIds })
        .then((data) => {
          dispatch({ type: "FileReset" });
          dispatch({ type: "FileUploadReset" });
          if (data && data.length > 0) {
            setFile(data[0])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{isEditMode ? 'Edit Checkpoint' : 'Add New Checkpoint'}</DialogTitle>
      {currentParentCheckpoint && (
        <Typography variant="subtitle2">
          Parent: {currentParentCheckpoint.title || currentParentCheckpoint.description}
        </Typography>
      )}
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Title"
          type="text"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Description"
          type="text"
          style={{ marginBottom: "15px" }}
          fullWidth
          multiline
          rows={3}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <CustomFileUploadButton
          showComponent={
            <Box
              sx={{
                width: "100%",
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '16px 20px',
                gap: '12px',
                flexShrink: 0,
                borderRadius: '10px',
                border: '1px dashed #ABBED1',
                background: '#F5F7FA',
              }}
            >
              <img
                src="/icons/app/document-upload.svg"
                style={{ width: '28px', height: '28px' }}
                alt="upload icon"
              />
            </Box>
          }
          parentType={parentModelName}
          parentId={parentId}
          fileNum={1}
          givenFileTypes={["image"]}
          givenMaxSize={26214400}
          closeFunCall={createNewDocs}
        />
        {(file && file?._id) && (
          <Chip
            label={file?.name}
            size="small"
            style={{ margin: "0px", marginTop: "5px" }}
            className={classes.typeChip}
          />
        )}
        <div style={{ height: "15px" }} ></div>
        {isEditMode ? null : <FormControlLabel
          control={
            <Checkbox
              checked={isBlock}
              onChange={(e) => setIsBlock(e.target.checked)}
              name="isBlock"
            />
          }
          label="Make this a block"
        />}
        {!isBlock && (
          <RadioGroup
            value={checkpointType}
            onChange={(e) => setCheckpointType(e.target.value)}
          >
            <FormControlLabel value="boolean" control={<Radio />} label="Boolean" />
            <FormControlLabel value="text" control={<Radio />} label="Text" />
            <FormControlLabel value="number" control={<Radio />} label="Number" />
            <FormControlLabel value="multipleChoice" control={<Radio />} label="Multiple Choice" />
          </RadioGroup>
        )}
        {checkpointType === 'multipleChoice' && (
          <>
            {options.map((option, index) => (
              <TextField
                key={index}
                margin="dense"
                label={`Option ${index + 1}`}
                type="text"
                fullWidth
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
              />
            ))}
            <Button onClick={handleAddOption}>Add Option</Button>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary">
          {isEditMode ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// New CheckpointEditModal component
const CheckpointEditModal = ({
  open, onClose, onUpdate, initialData
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { createdFileIds } = useSelector((state) => state.file);
  const [title, setTitle] = useState(initialData?.title || '');
  const [description, setDescription] = useState(initialData?.description || '');
  const [checkpointType, setCheckpointType] = useState(initialData?.checkpointType || 'boolean');
  const [options, setOptions] = useState(initialData?.options || ['']);
  const [isBlock] = useState(initialData?.isBlock || false); // Cannot change isBlock
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (open) {
      setTitle(initialData?.title || '');
      setDescription(initialData?.description || '');
      setCheckpointType(initialData?.checkpointType || 'boolean');
      setOptions(initialData?.options || ['']);
      // isBlock cannot be changed in edit mode
    }
  }, [open, initialData]);

  const handleUpdate = () => {
    onUpdate({
      ...initialData,
      title,
      description,
      checkpointType: isBlock ? 'boolean' : checkpointType,
      options: checkpointType === 'multipleChoice' ? options : [],
      isBlock,
      files: file?._id ? [file?._id] : []
    });
    onClose();
    setFile(null);
  };

  const handleAddOption = () => {
    setOptions([...options, '']);
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const createNewDocs = async () => {
    if (createdFileIds.length > 0) {
      await getAllFiles({ files: createdFileIds })
        .then((data) => {
          dispatch({ type: "FileReset" });
          dispatch({ type: "FileUploadReset" });
          if (data && data.length > 0) {
            setFile(data[0]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Checkpoint</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Title"
          type="text"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Description"
          type="text"
          style={{ marginBottom: "15px" }}
          fullWidth
          multiline
          rows={3}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <CustomFileUploadButton
          showComponent={
            <Box
              sx={{
                width: "100%",
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '16px 20px',
                gap: '12px',
                flexShrink: 0,
                borderRadius: '10px',
                border: '1px dashed #ABBED1',
                background: '#F5F7FA',
              }}
            >
              <img
                src="/icons/app/document-upload.svg"
                style={{ width: '28px', height: '28px' }}
                alt="upload icon"
              />
            </Box>
          }
          parentType={initialData?.parentModelName}
          parentId={initialData?.parentId}
          fileNum={1}
          givenFileTypes={["image"]}
          givenMaxSize={26214400}
          closeFunCall={createNewDocs}
        />
        {(file && file?._id) && (
          <Chip
            label={file?.name}
            size="small"
            style={{ margin: "0px", marginTop: "5px" }}
            className={classes.typeChip}
          />
        )}
        <div style={{ height: "15px" }} ></div>
        {/* Remove isBlock checkbox in edit mode */}
        {!isBlock && (
          <RadioGroup
            value={checkpointType}
            onChange={(e) => setCheckpointType(e.target.value)}
          >
            <FormControlLabel value="boolean" control={<Radio />} label="Boolean" />
            <FormControlLabel value="text" control={<Radio />} label="Text" />
            <FormControlLabel value="number" control={<Radio />} label="Number" />
            <FormControlLabel value="multipleChoice" control={<Radio />} label="Multiple Choice" />
          </RadioGroup>
        )}
        {checkpointType === 'multipleChoice' && (
          <>
            {options.map((option, index) => (
              <TextField
                key={index}
                margin="dense"
                label={`Option ${index + 1}`}
                type="text"
                fullWidth
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
              />
            ))}
            <Button onClick={handleAddOption}>Add Option</Button>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CheckpointTreeView = ({
  checkpointIds,
  checkpointDict,
  setCheckpointIds,
  setCheckpointDict,
  parentId,
  profileId,
  parentModelName,
  setLoader,
  checklistId,
  isImageUploaderActive, // New prop
  onImageUploaderActivate, // New prop
  onImageUploaderDeactivate
}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);
  const [editingCheckpoint, setEditingCheckpoint] = useState(null);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingCheckpointData, setEditingCheckpointData] = useState(null);

  const [observationsOpen, setObservationsOpen] = useState(false);
  const [selectedCheckpoint, setSelectedCheckpoint] = useState(null);


  // New state variables for file modal
  const [filesModalOpen, setFilesModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedCheckpointForFiles, setSelectedCheckpointForFiles] = useState(null);

  const updateTimeoutRef = useRef(null);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [deletingCheckpoints, setDeletingCheckpoints] = useState(new Set());
  const [observationDialogOpen, setObservationDialogOpen] = useState(false);
  const [selectedObservation, setSelectedObservation] = useState(null);
  const [observationsDrawerOpen, setObservationsDrawerOpen] = useState(false);
  const [isCreatingObservation, setIsCreatingObservation] = useState(false);

  const handleCreateObservation = async () => {
    if (!selectedCheckpoint) return;
    try {
      const response = await addObservationToParent({
        checkpointId: selectedCheckpoint._id,
        parentId: selectedCheckpoint._id,
        parentModelName: 'Checkpoint',
        profileId,
        userProfile: user?.profile,
        observationData: {
          user: user?._id,
          project: selectedCheckpoint?.project,
          organization: selectedCheckpoint?.organization,
          projectBlocks: selectedCheckpoint?.projectBlocks,
          labels: selectedCheckpoint?.labels,
          userProfile: user?.profile,
          tags: [
            { data: parentId, dataModel: parentModelName },
            { data: selectedCheckpoint._id, dataModel: 'Checkpoint' }
          ],
        }
      });
      return response?.newObservation;
    } catch (error) {
      console.error("Error creating observation:", error);
      throw error;
    }
  };


  const handleObservationUpdate = (updatedObservation) => {
    // Handle the updated observation if needed
    console.log("Observation updated:", updatedObservation);
    setObservationDialogOpen(false);
    if (onImageUploaderDeactivate) {
      onImageUploaderDeactivate();
    }
  };

  const handleFilesIconClick = async (checkpoint) => {
    try {
      console.log(checkpoint,' is the checkpoint')
      setSelectedCheckpointForFiles(checkpoint);
      setFilesModalOpen(true);
      setSelectedFiles(checkpoint.files);

    } catch (error) {
      console.error('Error fetching files:', error);
      setSelectedFiles([]);
    }
  };


  const renderFilesModal = () => (
    <Modal
      open={filesModalOpen}
      onClose={() => setFilesModalOpen(false)}
      aria-labelledby="files-modal-title"
      aria-describedby="files-modal-description"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Paper style={{ padding: '20px', maxWidth: '80%', maxHeight: '80%', overflowY: 'auto' }}>
        <Typography variant="h6" id="files-modal-title">
          Attached Files
        </Typography>
        <div id="files-modal-description">
          {selectedFiles.length > 0 ? (
            <FilesViewer fileIds={selectedFiles} isGallery={true} />
          ) : (
            <Typography>No files attached.</Typography>
          )}
        </div>
      </Paper>
    </Modal>
  );

  const calculateStats = useCallback((nodeId) => {
    const node = checkpointDict[nodeId];
    if (!node) return { total: 0, completed: 0 };

    let total = 0;
    let completed = 0;

    if (node.isBlock) {
      // For blocks, recursively calculate stats for all children
      if (node.childCheckpoints) {
        node.childCheckpoints.forEach(childId => {
          const childStats = calculateStats(childId);
          total += childStats.total;
          completed += childStats.completed;
        });
      }
    } else {
      // For non-block checkpoints
      total = 1;
      completed = node.isCompleted ? 1 : 0;
    }

    return { total, completed };
  }, [checkpointDict]);

  const overallStats = useMemo(() => {
    return checkpointIds.reduce((acc, id) => {
      const stats = calculateStats(id);
      return {
        total: acc.total + stats.total,
        completed: acc.completed + stats.completed
      };
    }, { total: 0, completed: 0 });
  }, [checkpointIds, calculateStats]);

  const handleToggle = useCallback(async (event, itemId) => {
    try {
      console.log("toggle start")
      setLoader(true)
      const newIsCompleted = event.target.checked;
      const updatedCheckpoint = await updateCheckpoint({
        checkpointId: itemId,
        updateData: { isCompleted: newIsCompleted },
        userId: user?._id
      });
      setCheckpointDict(prevDict => ({
        ...prevDict,
        [itemId]: { ...prevDict[itemId], isCompleted: newIsCompleted }
      }));
      setLoader(false)
      console.log("toggle end")
    } catch (error) {
      console.error("Error updating checkpoint:", error);
    }
  }, [checkpointDict]);


  const handleBooleanToggle = useCallback(async (checkpointId, newValue) => {
    try {
      const updatedCheckpoint = await updateCheckpoint({
        checkpointId: checkpointId,
        updateData: { isCompleted: newValue },
        userId: user?._id
      });

      setCheckpointDict(prevDict => ({
        ...prevDict,
        [checkpointId]: { ...prevDict[checkpointId], isCompleted: newValue }
      }));


    } catch (error) {
      console.error("Error updating checkpoint boolean value:", error);
    }
  }, [checkpointDict]);


  const updateBackend = useCallback(async (checkpointId, updateData) => {
    try {
      await updateCheckpoint({
        checkpointId: checkpointId,
        updateData: updateData,
        userId: user?._id
      });

    } catch (error) {
      console.error("Error updating checkpoint:", error);
    }
  }, [checkpointDict]);

  const handleInputChange = useCallback((checkpointId, key, newValue) => {
    setCheckpointDict(prevDict => ({
      ...prevDict,
      [checkpointId]: { ...prevDict[checkpointId], [key]: newValue }
    }));

    // Clear any existing timeout
    if (updateTimeoutRef.current) {
      clearTimeout(updateTimeoutRef.current);
    }

    // Set a new timeout
    updateTimeoutRef.current = setTimeout(() => {
      updateBackend(checkpointId, { [key]: newValue });
    }, 1000);
  }, [updateBackend]);

  const handleTextChange = useCallback((checkpointId, newText) => {
    setCheckpointDict(prevDict => ({
      ...prevDict,
      [checkpointId]: { ...prevDict[checkpointId], text: newText }
    }));

    // Clear any existing timeout
    if (updateTimeoutRef.current) {
      clearTimeout(updateTimeoutRef.current);
    }

    // Set a new timeout
    updateTimeoutRef.current = setTimeout(() => {
      updateBackend(checkpointId, newText);
    }, 1000);
  }, [updateBackend]);

  const handleKeyDown = useCallback((e, checkpointId) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      setEditingCheckpoint(null);
      updateBackend(checkpointId, checkpointDict[checkpointId].text);
    }
  }, []);


  const handleAddCheckpoint = async (checkpointData) => {
    try {
      let data;

      if (checklistId) {
        data = await addCheckpointToChecklist({
          checklistId,
          checkpointData
        });
      } else if (parentId) {
        data = await addCheckpointToParent({
          parentId,
          parentModelName,
          checkpointData
        });
      } else {
        console.log('inside handleadd checkpointIds ', checkpointIds)
        var oldCheckpoints = checkpointIds.map(id => checkpointDict[id]) || []
        console.log('oldCheckpoints', oldCheckpoints)
        data = {
          checkpoints: [...oldCheckpoints, {
            _id: uuidv4(),
            ...checkpointData
          }]
        }
        console.log('data', data)
        //  make temp checkpoint locally 

      }

      if (data && data.checkpoints) {
        const newCheckpoint = data.checkpoints[data.checkpoints.length - 1];
        const newCheckpointDict = {
          ...checkpointDict,
          [newCheckpoint._id]: newCheckpoint
        };

        // Update the parent's childCheckpoints if there's a parent
        if (checkpointData.parentCheckpoint) {
          const parentId = checkpointData.parentCheckpoint;
          newCheckpointDict[parentId] = {
            ...newCheckpointDict[parentId],
            childCheckpoints: [
              ...(newCheckpointDict[parentId].childCheckpoints || []),
              newCheckpoint._id
            ]
          };
        }


        setCheckpointIds(prevIds => [...prevIds, newCheckpoint._id]);
        setCheckpointDict(newCheckpointDict);
        setForceUpdate(prev => prev + 1);
      }
    } catch (error) {
      console.error("Error adding checkpoint:", error);
    }
  };

  // Use useEffect to update expanded state when checkpointDict changes
  useEffect(() => {
    const updateExpanded = () => {
      const newExpanded = [...expanded];
      checkpointIds.forEach(id => {
        const checkpoint = checkpointDict[id];
        if (checkpoint && checkpoint.childCheckpoints && checkpoint.childCheckpoints.length > 0) {
          newExpanded.push(id);
        }
      });
      setExpanded([...new Set(newExpanded)]);
    };
    updateExpanded();
  }, [checkpointDict, checkpointIds]);


  const handleValueChange = useCallback(async (itemId, value) => {
    try {
      await updateCheckpoint({
        checkpointId: itemId,
        updateData: { value: value },
        userId: user?._id
      });

      setCheckpointDict(prevDict => ({
        ...prevDict,
        [itemId]: { ...prevDict[itemId], value: value }
      }));

      (Object.values({
        ...checkpointDict,
        [itemId]: { ...checkpointDict[itemId], value: value }
      }));
    } catch (error) {
      console.error("Error updating checkpoint value:", error);
    }
  }, [checkpointDict, user]);

  const handleRemoveCheckpoint = useCallback(async (checkpointId) => {
    setDeletingCheckpoints(prev => new Set(prev).add(checkpointId));

    try {
      let response;
      if (parentId && parentModelName) {
        response = await removeCheckpointFromParent({
          parentId,
          parentModelName,
          checkpointId
        });
      } else if (checklistId) {
        response = await removeCheckpointFromChecklist({
          userId: user?._id,
          checklistId,
          checkpointId
        });
      }

      if (response && response.data && response.data.checkpoints) {
        const newCheckpoints = response.data.checkpoints;
        setCheckpointDict(newCheckpoints.reduce((acc, cp) => {
          acc[cp._id] = cp;
          return acc;
        }, {}));
        setCheckpointIds(newCheckpoints.map(cp => cp._id));
      } else {
        // Handle local removal if no response from backend
        setCheckpointDict(prevDict => {
          const newDict = { ...prevDict };
          delete newDict[checkpointId];
          return newDict;
        });
        setCheckpointIds(prevIds => prevIds.filter(id => id !== checkpointId));
      }
    } catch (error) {
      console.error("Error removing checkpoint:", error);
    } finally {
      setDeletingCheckpoints(prev => {
        const newSet = new Set(prev);
        newSet.delete(checkpointId);
        return newSet;
      });
    }
  }, [parentId, parentModelName, checklistId, user])


  const handleUpdateCheckpoint = async (updatedData) => {
    try {
      const updatedCheckpoint = await updateCheckpoint({
        checkpointId: updatedData._id,
        updateData: {
          title: updatedData.title,
          description: updatedData.description,
          checkpointType: updatedData.checkpointType,
          options: updatedData.options,
          isBlock: updatedData.isBlock,
          files: updatedData.files,
        },
        userId: user?._id,
      });
      // Update the checkpointDict state
      setCheckpointDict(prevDict => ({
        ...prevDict,
        [updatedData._id]: {
          ...prevDict[updatedData._id],
          ...updatedData,
        }
      }));
      setEditModalOpen(false);
    } catch (error) {
      console.error('Error updating checkpoint:', error);
    }
  };

  const handleExpand = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const renderInputField = (node) => {
    if (node.isBlock) return null;

    switch (node.checkpointType) {
      case 'boolean':
        return (
          <Switch
            checked={node.isCompleted}
            onChange={(e) => handleInputChange(node._id, 'isCompleted', e.target.checked)}
            color="primary"
          />
        );
      case 'text':
        if (editingCheckpoint === node._id) {
          return (
            <InputBase
              className={classes.textInput}
              multiline
              fullWidth
              value={node.text || ''}
              onChange={(e) => handleInputChange(node._id, 'text', e.target.value)}
              onBlur={() => setEditingCheckpoint(null)}
              autoFocus
            />
          );
        } else {
          const displayText = node.text && node.text.length > 50
            ? node.text.substring(0, 50) + '...'
            : node.text || '';
          return (
            <>
              <Typography className={classes.ellipsisText}>{displayText}</Typography>
              <IconButton
                className={classes.editButton}
                size="small"
                onClick={() => setEditingCheckpoint(node._id)}
              >
                <EditIcon />
              </IconButton>
            </>
          );
        }
      case 'number':
        return (
          <TextField
            type="number"
            value={node.value || ''}
            onChange={(e) => handleInputChange(node._id, 'value', e.target.value)}
            className={classes.numberInput}
            variant="outlined"
            size="small"
          />
        );
      case 'multipleChoice':
        return (
          <Select
            className={classes.inputField}
            value={node.value || (node.options && node.options[0]) || ''}
            onChange={(e) => handleValueChange(node._id, e.target.value)}
            size="small"
            variant="outlined"
          >
            {node.options && node.options.map((option, index) => (
              <MenuItem size="small" key={index} value={option}>{option}</MenuItem>
            ))}
          </Select>
        );
      default:
        return null;
    }
  };


  const renderTree = (nodeId) => {
    const node = checkpointDict[nodeId];
    if (!node) return null;
    const isDeleting = deletingCheckpoints.has(nodeId);
    const stats = calculateStats(nodeId);

    return (
      <TreeItem
        key={nodeId}
        nodeId={nodeId}
        label={
          <div className={classes.itemContent}>
            <div className={classes.leftPartCont} >
              {node.isBlock ? (
                <>
                  <Typography className={classes.blockLabel}>
                    {node.title || node.description}
                  </Typography>
                  <Typography className={classes.blockStats}>
                    ({stats.completed}/{stats.total})
                  </Typography>
                </>
              ) : (
                <>
                  {node.checkpointType !== 'boolean' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={node.isCompleted || false}
                          onChange={(event) => handleToggle(event, nodeId)}
                        />
                      }
                      label={<span className={classes.label}>{node.description}</span>}
                    />
                  )}
                  {node.checkpointType === 'boolean' && (
                    <span className={classes.label}>{node.description}</span>
                  )}
                  <Typography className={classes.title}>{node.title}</Typography>
                  <Chip
                    label={node.checkpointType}
                    size="small"
                    className={classes.typeChip}
                  />
                  <Chip
                    label={`${stats.completed}/${stats.total}`}
                    size="small"
                    className={classes.statChip}
                  />
                </>
              )}
            </div>
            <div style={{ minWidth: "10px", maxWidth: "10px", width: "10px" }} ></div>
            <div className={classes.rightPartCont} >
              {!node.isBlock && (<>
                {renderInputField(node)}
              </>)}
              {isDeleting && <CircularProgress size={20} className={classes.loader} />}
              {!isDeleting && (
                <>
                    <IconButton onClick={() => handleRemoveCheckpoint(nodeId)} size="small">
                        <DeleteIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                          setEditingCheckpointData(node);
                          setEditModalOpen(true);
                        }}
                        size="small"
                      >
                        <InfoIcon />
                    </IconButton>
                    {node.files && node.files.length > 0 && (
                      <IconButton
                        onClick={() => handleFilesIconClick(node)}
                        size="small"
                        className={classes.fileIconButton}
                      >
                        <AttachmentIcon />
                        <Typography variant="caption">{node.files.length}</Typography>
                      </IconButton>
                    )}
                </>

              )}
              {node.isBlock && (
                <IconButton
                  onClick={() => {
                    setSelectedParent(node)
                    setModalOpen(true)
                  }}
                  size="small"
                >
                  <AddIcon style={{ fontSize: "20px" }} />
                </IconButton>
              )}
              {parentId && !node.isBlock && (
                <IconButton
                  onClick={() => {
                    setSelectedCheckpoint(node);
                    setObservationsOpen(true);
                  }}
                  size="small"
                  className={classes.observationButton}
                >
                  <FaBinoculars /> {node.observations && node.observations.length > 0 && node.observations.length}
                </IconButton>
              )}
            </div>
          </div>
        }
      >
        {node.childCheckpoints && node.childCheckpoints.map((childId) => renderTree(childId))}
      </TreeItem>
    );
  };


  const rootNodes = checkpointIds.filter(id => {
    const checkpoint = checkpointDict[id];
    return checkpoint && (!checkpoint.ancestorCheckpoints || checkpoint.ancestorCheckpoints.length === 0);
  });

  if (checkpointIds.length === 0) {
    return (
      <Paper className={classes.emptyState}>
        <Typography variant="body1">No checkpoints added yet.</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setModalOpen(true)}
          className={classes.addFirstCheckpointButton}
        >
          Add Checkpoint
        </Button>
        <CheckpointAddModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onAdd={(checkpointData) => {
            handleAddCheckpoint(checkpointData);
            setModalOpen(false);
          }}
          isEditMode={false}
          checklistId={checklistId}
          parentCheckpoint={selectedParent}
          parentId={parentId}
          parentModelName={parentModelName}
        />
      </Paper>
    );
  }

  return (
    <div className={classes.root}>

      <Box className={classes.statsBox}>
        {parentModelName && (
          <Typography variant="subtitle1">
            Overall Progress: {overallStats.completed}/{overallStats.total} completed
          </Typography>)}
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setSelectedParent(null);
            setModalOpen(true);
          }}
        >
          Add Root Checkpoint
        </Button>
      </Box>

      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={handleExpand}
      >
        {rootNodes.map((nodeId) => renderTree(nodeId))}
      </TreeView>
      <CheckpointAddModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onAdd={handleAddCheckpoint}
        parentCheckpoint={selectedParent}
        parentId={parentId}
        parentModelName={parentModelName}
        checklistId={checklistId}
      />
      <CheckpointEditModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        onUpdate={handleUpdateCheckpoint}
        initialData={editingCheckpointData}
      />
       {renderFilesModal()}
      <ObservationList
        open={observationsOpen}
        setOpen={setObservationsOpen}
        onClose={() => setObservationsOpen(false)}
        queryProps={{
          parent: selectedCheckpoint?._id,
          parentModelName: 'Checkpoint'
          // Add any other query parameters you need
        }}
        profileId={profileId}
        onObservationCreate={handleCreateObservation}
        isImageUploaderActive={isImageUploaderActive}
        onImageUploaderActivate={onImageUploaderActivate}
        onImageUploaderDeactivate={onImageUploaderDeactivate}
        drawerMode={true}
      />
    </div>
  );
};

export default CheckpointTreeView;