import { FormControl, InputLabel, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import Api from "../../../helpers/Api";
import { useSelector } from "react-redux";
import Select from "./Select";

const OrgPickerDropdown = ({
  selectedOrg,
  setSelectedOrg,
  defaultOrganizationId,
  size,
  hideLabel,
  fullWidth = false,
  disabled = false,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [orgs, setOrgs] = useState([]);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);

  const setUpOrgs = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Organization") {
        setOrgs((prev) => [...prev, team?.parent]);
      }
    }
  };

  const handleDefaultSelection = () => {
    if (orgs?.length > 0 && defaultOrganizationId) {
      let index = orgs.findIndex((item) => item?._id === defaultOrganizationId);
      if (index !== -1) {
        setSelectedOrg(orgs[index]);
      }
    } else {
      setSelectedOrg(orgs[0]);
    }
  };

  useEffect(() => {
    handleDefaultSelection();
  }, [orgs, defaultOrganizationId]);

  useEffect(() => {
    setUpOrgs();
  }, []);

  return (
    <FormControl fullWidth={fullWidth} size={size} disabled={disabled}>
      {!hideLabel && (
        <InputLabel
          id="demo-simple-select-helper-label"
          shrink={selectedOrg !== null}
        >
          {selectedOrg ? "Organization" : "Select Organization"}
        </InputLabel>
      )}

      <Select
        value={selectedOrg}
        onChange={(evt) => setSelectedOrg(evt.target.value)}
        label={selectedOrg ? "Organization" : "Select Organization"}
        notched={hideLabel ? false : selectedOrg !== null}
        displayEmpty
      >
        <MenuItem>{selectedOrg?.displayName || "Select"}</MenuItem>
        {orgs.length > 0 &&
          orgs.map((obj) => (
            <MenuItem key={obj._id} value={obj}>
              {obj?.displayName}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default OrgPickerDropdown;
