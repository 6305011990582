import React, { useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import MuiAlert from "@material-ui/lab/Alert";

import MentionOutput from "../../styled/mention.output";
import NotificationBody from "./NotificationBody";
import ActivityCard from "../activity.card";
import useTimesheetDrawer from "../../useTimesheetDrawer";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
}));

function TimesheetNotification({ notification, isActivity, handleTimesheetOpen }) {
  const classes = useStyles();
  const history = useHistory();

  const NotificationComponent = useMemo(() => {
    const Component = isActivity ? ActivityCard : NotificationBody;

    switch (notification?.type) {
      case "Timesheet":
        return (
          <div onClick={() => handleTimesheetOpen(notification?.data)}>
            <Component
              notification={{ ...notification }}
              activity={{ ...notification }}
              actId={notification?._id}
              link="/"
            />
          </div>
        );
        break;
      default:
        return <></>;
    }
  }, [notification, isActivity]);
  return <div>{NotificationComponent}</div>;
}

export default TimesheetNotification;
