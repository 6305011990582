const { default: Api } = require("../../helpers/Api");

export const getPropertyType = async () => {
  try {
    const propertyType = await Api.post("property/type");
    const data = propertyType?.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateData = async (ProjectData, DescriptionObject) => {
  try {
    const res = await Api.post("property/description/updateData", {
      ...ProjectData,
      ...DescriptionObject,
    });
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const followUnfollowApiHit = async (obj) => {
  try {
    const updatedData = await Api.post("follow/follow/for-org", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const getFollowerAndFollowingsApiHit = async (obj) => {
  try {
    const updatedData = await Api.post("get/follower/following", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserInfo = async (userData) => {
  try {
    const updatedData = await Api.post("/user/updateInfo", userData);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateOrgWithPopulate = async (obj) => {
  try {
    const resData = await Api.post("/organization/update/with-populate", obj);
    const result = resData.data
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getFullOrgData = async (obj) => {
  try {
      const propertyType = await Api.post("project/get/data/for/dashboard", obj);
      const data = propertyType?.data;
      return data;
  } catch (error) {
      console.log(error);
      return null;
  }
};

export const deletePublicProject = async (obj) => {
  try {
    const updatedData = await Api.post("public/project/delete/by-id/for-org", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateExpProjectAndRootProjectForPublicProject = async (obj) => {
  try {
    const updatedData = await Api.post("public/project/update/exp-project/root-project", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateExpProjectForPublicProject = async (obj) => {
  try {
    const updatedData = await Api.post("public/project/update/exp-project", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateRootProjectForPublicProject = async (obj) => {
  try {
    const updatedData = await Api.post("public/project/update/root-project", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const addFilesToPublicProject = async (obj) => {
  try {
    const updatedData = await Api.post("public/project/addFiles", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateDoc = async (obj) => {
  try {
    const updatedData = await Api.post("doc/update/by-id", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const removeFiles = async (obj) => {
  try {
    const updatedData = await Api.post("public/project/removeFile", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const addExpProjectHitApiForOrg = async (obj) => {
  try {
    const updatedData = await Api.post("public/project/create/for-org", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};

export const addProfileSectionForOrgApiHit = async (obj) => {
  try {
    const updatedData = await Api.post("organization/edit/add-profile-section", obj);
    return updatedData.data;
  } catch (error) {
    console.log(error);
  }
};