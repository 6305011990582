import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { SlPencil } from "react-icons/sl";
import Carousel from "react-multi-carousel";
import { GiGearHammer } from "react-icons/gi";
import moment from "moment";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import LessText from "../styled/CommonComponents/LessText";
import ProjectDialog from "./Dialogs/ProjectDialog";
import CertificatLicenseDialog from "./Dialogs/CertificatLicenseDialog";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { IoOpenOutline } from "react-icons/io5";
import "react-multi-carousel/lib/styles.css";
import "../profile/sections/crad.css";
import AwardHonorsDialog from "./Dialogs/AwardHonors.Dialog";
import { GiTrophyCup } from "react-icons/gi";
import LaunchOutlinedIcon from "@material-ui/icons/LaunchOutlined";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
  Paper,
  useMediaQuery,
  Chip,
} from "@material-ui/core";
import Api from "../../helpers/Api";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Autocomplete, Checkbox, FormControlLabel } from "@mui/material";
import WorkspaceConfigDialog from "./Dialogs/Workspace.Config.Dialog";
import useWorkspace from "../useWorkspace";
import { Delete } from "@material-ui/icons";
import PublishIcon from "@material-ui/icons/Publish";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "25px",
    marginBottom: "20px",
    "& h2": {
      fontSize: "15px",
      marginTop: "15px",
    },
    "& h3": {
      fontSize: "22px",
      fontWeight: "700",
      marginBottom: "20px",
    },
    "& h4": {
      fontSize: "18px",
      fontWeight: "510",
    },
    "& h5": {
      fontSize: "15px",
      fontWeight: "500",
    },
    "& h6": {
      fontSize: "13px",
      fontWeight: "400",
      color: "gray",
    },
    "& p": {
      fontSize: "15px",
    },
  },
  cardRoot: {
    width: "100%",
    padding: "15px",
    borderRadius: "5px",
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  cardSubTitle: {
    fontSize: 15,
  },
  cardTopHeading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > h3": {
      fontWeight: "510",
      fontSize: "17px",
      color: "#193B56",
    },
  },
  createBtn: {
    width: "25px",
    height: "25px",
    borderRadius: "6px",
    backgroundColor: "#c1def6",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#70b8f4",
      color: "white",
    },
  },
  allProjectsCont: {
    width: "100%",
    padding: "25px",
    marginBottom: "20px",
  },
  headerSty: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h3": {
      fontSize: "22px",
      fontWeight: "700",
    },
  },
  allExpEditLicense: {
    padding: "0px 30px",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      padding: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  allExpEditProject: {
    padding: "0px 30px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  singleExperienceCont: {
    display: "flex",
    alignItems: "flex-start",
    padding: "25px 5px",
  },
  imageCont: {
    marginRight: "15px",
  },
  experienceRight: {
    width: "calc(100% - 60px)",
    marginTop: "-4px",
    "& h3": {
      fontSize: "20px",
      fontWeight: "500",
    },
    "& h4": {
      fontSize: "16px",
      fontWeight: "450",
      display: "flex",
      alignItems: "center",
    },
    "& h5": {
      fontSize: "14px",
      fontWeight: "350",
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      "& h5": {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
  },
  tagCont: {
    fontSize: "14px",
    color: "gray",
  },
  credentialLink: {
    width: "175px",
    border: "1px solid gray",
    padding: "2px 0px",
    fontSize: "16px",
    fontWeight: "500",
    color: "gray",
    marginTop: "10px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
}));

const OrgSettings = ({
  profile,
  teamId,
  setShowWorkspaceConfigDialog,
  orgId,
  defaultLibId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [customMaterialLibraryName, setCustomMaterialLibraryName] =
    useState("");

  const user = useSelector((state) => state.auth.user);

  const filePickerRef = useRef();
  const [showChooseLibraryDialog, setShowChooseLibraryDialog] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const [existingLibs, setExistingLibs] = useState([]);

  const getExistingLibs = async () => {
    try {
      const data = await Api.post("/estimate/getlib", {
        organizationId: orgId,
      });
      setExistingLibs(data.data);
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred" + err,
        },
      });
    }
  };

  useEffect(() => {
    getExistingLibs();
  }, []);

  const handleFileSelection = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const openFilePicker = () => {
    filePickerRef.current.click();
  };
  const handleLibraryCreation = async () => {
    if (!customMaterialLibraryName) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Please enter library name",
        },
      });
      return;
    }
    if (!selectedFile) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Please upload the library data",
        },
      });
      return;
    }

    dispatch({
      type: "AddEstimation",
      payload: {
        materialFile: { name: customMaterialLibraryName, data: selectedFile },
      },
    });

    history.push(`/organization/library/configure/${profile._id}/${orgId}`);
  };

  const viewLibrary = async (id) => {
    history.push(`/project/${teamId}/${profile._id}/library/${id}`);
  };

  const deleteLibrary = async (id) => {
    try {
      const data = await Api.post("/estimate/delete", {
        libraryId: id,
      });
      if (data) {
        setExistingLibs(existingLibs.filter((item) => item._id !== id));
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Library deleted successfully!",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred" + err,
        },
      });
    }
  };

  return (
    <div>
      <Paper elevation={2} className={classes.root}>
        <div className={classes.headerSty}>
          <h3>Settings</h3>
        </div>
        <div>
          <Paper className={classes.cardRoot} elevation={2}>
            <div className={classes.cardTopHeading}>
              <h3>Workspace Configuration</h3>
            </div>
            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setShowWorkspaceConfigDialog(true)}
              >
                Configure Workspace
              </Button>
            </div>
          </Paper>

          <Paper
            className={classes.cardRoot}
            elevation={2}
            style={{ marginTop: "25px" }}
          >
            <div className={classes.cardTopHeading}>
              <h3>Estimation & Analytics</h3>
              <IconButton
                className={classes.createBtn}
                color="primary"
                onClick={() => setShowChooseLibraryDialog(true)}
              >
                +
              </IconButton>
            </div>
            <p className={classes.cardSubTitle}>
              Manage Material library for your project
              <br />
              <input
                type="file"
                accept=".xlsb"
                multiple={false}
                hidden
                ref={filePickerRef}
                onChange={handleFileSelection}
              />
            </p>
            <div
              style={{ maxHeight: 300, overflowY: "auto", marginTop: "15px" }}
            >
              {existingLibs && existingLibs.length > 0 ? (
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      {existingLibs.map((lib, i) => (
                        <TableRow key={i} style={{ cursor: "pointer" }}>
                          <TableCell align="left" style={{ padding: "0px" }}>
                            <Box display="flex" alignItems="center">
                              <p
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "510",
                                  marginRight: "15px",
                                }}
                              >
                                {lib.name}
                              </p>
                              {String(lib._id) === String(defaultLibId) && (
                                <Chip
                                  size="small"
                                  label="Default"
                                  color="primary"
                                />
                              )}
                            </Box>
                            {/* <p>{defaultLibId}</p>
                            <p>{lib._id}</p> */}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ paddingLeft: "5px" }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar
                                src={lib.user?.displayPicture?.url}
                                style={{ width: "30px", height: "30px" }}
                              />
                              <div style={{ marginLeft: "5px" }}>
                                <p
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "510",
                                  }}
                                >
                                  {lib.user?.displayName}
                                </p>
                              </div>
                            </div>
                          </TableCell>

                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <IconButton
                                aria-label="Delete"
                                onClick={() => viewLibrary(lib._id)}
                                style={{ marginRight: "10px" }}
                              >
                                <LaunchOutlinedIcon />
                              </IconButton>
                              <IconButton
                                aria-label="Delete"
                                onClick={() => deleteLibrary(lib._id)}
                              >
                                <Delete />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <p>No Libraries</p>
              )}
            </div>
          </Paper>
        </div>
      </Paper>

      <Modal
        open={showChooseLibraryDialog}
        onClose={() => setShowChooseLibraryDialog(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <Box>
            <Typography id="modal-modal-title" variant="h6">
              Material Library Name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              style={{ marginTop: "0.5rem" }}
              value={customMaterialLibraryName}
              onChange={(e) => setCustomMaterialLibraryName(e.target.value)}
            />
            <Typography
              id="modal-modal-title"
              variant="h6"
              style={{ marginTop: "1rem" }}
            >
              Upload Material Library Data
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => openFilePicker()}
              size="small"
              style={{ marginTop: "0.5rem" }}
              startIcon={<PublishIcon />}
            >
              Upload
            </Button>
            {selectedFile && (
              <Box>
                <p style={{ color: "green" }}>(File Selected)</p>
              </Box>
            )}
          </Box>

          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "1rem" }}
              onClick={() => handleLibraryCreation()}
            >
              Upload Library
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default OrgSettings;
