import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Paper, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { useHistory } from 'react-router-dom';
import emptyPage from "../../Assets/EmptyPageSVG.svg"
import InputWrapper from '../styled/CommonComponents/InputWrapper';
import StageCreateCard from './Stage.Create.Card';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { approvalTypesDefaultFieldsMap, approvalTypesModuleMap, getLinerStatuses } from '../../helpers/approval';
import { createApprovalTemplate, updateApprovalTemplateById } from './api.call';
import * as yup from "yup";
import NotifyAlert from '../styled/CommonComponents/NotifyAlert';

const validationSchema = yup.object().shape({
    title: yup.string().min(2).max(250).required("Name is required"),
    type: yup.string().required("Type is required"),
    code: yup.string().required("Selling price is required")
});

const useStyles = makeStyles((theme) => ({
    mainDialogCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "14px"
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "15px"
    },
    bottomAct: {
        width: "100%",
        height: "50px",
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #ececec"
    },
    emptyCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 30px",
        "& img": {
            width: "200px",
            height: "auto"
        },
        "& p": {
            fontSize: "14px",
            color: "gray",
            textAlign: "center",
            margin: "10px 0px 25px"
        },
        "& a": {
            color: theme.palette.primary.main,
            cursor: "pointer",
        }
    },
    paperHeader: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "0px 10px",
        justifyContent: "space-between",
        "& h3": {
            fontSize: "18px",
            fontWeight: "500"
        }
    },
    inputFieldsCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: "10px",
        marginBottom: "30px"
    }
}));

export default function ApprovalTempleteCreateDrawer({
    createTempleteDawer, setCreateTempleteDrawer, afterTempleteCreateOrEdit,
    curEditDeleteTemplete, profileData, loader, setLoader
}) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const { user } = useSelector((state) => state.auth);

    const [statusesAgainstType, setStatusesAgainstType] = useState([])
    const [validationObj, setValidationObj] = useState({})
    const [alertObj, setAlertObj] = useState({})
    const [templeteData, setTempleteData] = useState({
        title: "",
        type: "",
        code: "",
        description: "",
        stages: []
    })

    const resetData = () => {
        setStatusesAgainstType([])
        setTempleteData({
            title: "",
            type: "",
            code: "",
            description: "",
            stages: []
        })
        setLoader(false)
        setValidationObj({})
    }

    useEffect(() => {
        if (createTempleteDawer) {
            if (curEditDeleteTemplete?._id) {
                const stagesArr = curEditDeleteTemplete?.stages || []
                const locStagesArr = stagesArr.map((stage) => {
                    let locApprovers = stage?.approvers || [];
                    let feApprovers = locApprovers.map((approver) => {
                        return {
                            _id: approver?._id,
                            participant: approver?.profile?.parent,
                            isRequired: approver?.mandatory
                        }
                    })
                    return {
                        _id: stage?._id,
                        stageNo: stage?.stageNo,
                        status: stage?.status,
                        canEditMainData: stage?.canEditMainData,
                        participantsType: stage?.approvalType,
                        numberOfApprovalNeed: stage?.approvalsNeededCustom,
                        approvalsWithType: feApprovers
                    }
                });
                const statusArr = getLinerStatuses(curEditDeleteTemplete?.type)
                setStatusesAgainstType(statusArr)
                setTempleteData({
                    _id: curEditDeleteTemplete?._id,
                    title: curEditDeleteTemplete?.title,
                    type: curEditDeleteTemplete?.type,
                    code: curEditDeleteTemplete?.code,
                    description: curEditDeleteTemplete?.description,
                    stages: locStagesArr
                })
            } else {
                resetData()
            }
        }
    }, [curEditDeleteTemplete, createTempleteDawer])

    const typeChangeHandler = (type) => {
        const statusArr = getLinerStatuses(type)
        setStatusesAgainstType(statusArr)
        let stagesArr = []
        statusArr.map((status, inx) => {
            stagesArr.push({
                stageNo: (inx + 1),
                status: status,
                participantsType: "specific_same_power",
                canEditMainData: (status === "Submitted" || status === "Draft") ? true : false,
                numberOfApprovalNeed: 0,
                approvalsWithType: []
            })
        })
        setTempleteData({
            ...templeteData,
            type: type,
            stages: stagesArr
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            title: templeteData?.title,
            type: templeteData?.type,
            code: templeteData?.code,
        }
        await validationSchema.validate(formData, { abortEarly: false })
            .then(async (validationRes) => {
                let validationArr = []
                let locStagesArr = templeteData?.stages || []
                if (locStagesArr.length === 0) {
                    validationArr.push("No Stage added!, you have to add stage(s)")
                } else {
                    locStagesArr.map((stage) => {
                        if (stage?.participantsType === "specific_same_power") {
                            if (stage?.approvalsWithType === null || stage?.approvalsWithType === undefined || stage.approvalsWithType.length === 0) {
                                validationArr.push(`No participants is selected under stage - ${stage?.stageNo}`)
                            }else if(parseInt(stage?.numberOfApprovalNeed || 0) === 0) {
                                validationArr.push(`Required approvals number can't be zero! under stage - ${stage?.stageNo}`)
                            } else {
                                if (parseInt(stage?.numberOfApprovalNeed || 0) > parseInt(stage?.approvalsWithType?.length || 0)) {
                                    validationArr.push(`Required approvals number is more then selected approvers! under stage - ${stage?.stageNo}`)
                                }
                            }
                        } else if (stage?.participantsType === "specific_different_power") {
                            if (stage?.approvalsWithType === null || stage?.approvalsWithType === undefined || stage.approvalsWithType.length === 0) {
                                validationArr.push(`No participants is selected under stage - ${stage?.stageNo}`)
                            } else {
                                let optionalPartyNumber = 0;
                                let mandatoryPartyNumber = 0;
                                stage.approvalsWithType.map((s) => {
                                    if (!s?.isRequired) {
                                        optionalPartyNumber = optionalPartyNumber + 1;
                                    }else{
                                        mandatoryPartyNumber = mandatoryPartyNumber + 1;
                                    }
                                })

                                if (optionalPartyNumber !== 0 && parseInt(stage?.numberOfApprovalNeed || 0) > optionalPartyNumber) {
                                    validationArr.push(`Required approvals number is more then selected approvers! under stage - ${stage?.stageNo}`)
                                }
                            }
                        }
                    })
                }
                if (validationArr.length === 0) {
                    setLoader(true)
                    if (curEditDeleteTemplete?._id) {
                        let oldstageIds = []
                        let oldApproverIds = []
                        let locStage = templeteData?.stages || []
                        locStage.map((s) => {
                            oldstageIds.push(s?._id)
                            s?.feApprovers && s.feApprovers.map((sp) => {
                                oldApproverIds.push(sp?._id)
                            })
                        })
                        await updateApprovalTemplateById({
                            modules: [approvalTypesModuleMap[templeteData?.type]],
                            templeteData,
                            oldstageIds,
                            oldApproverIds,
                            profileId: user?.profile
                        })
                            .then((data) => {
                                console.log(data)
                                resetData()
                                setCreateTempleteDrawer(false)
                                afterTempleteCreateOrEdit()
                            })
                            .catch((err) => {
                                console.log(err);
                                setLoader(false)
                            })
                    } else {
                        const entity = profileData?.parent?._id;
                        const entityModelName = profileData?.parentModelName;
                        const entityParent = profileData?.parent?.ownerProfile?.parent;
                        const entityParentModelName = profileData?.parent?.ownerProfile?.parentModelName;
                        let shareArr = []
                        if (profileData?._id) {
                            shareArr.push(profileData?._id)
                        }
                        if (profileData?.parent?.ownerProfile?._id) {
                            shareArr.push(profileData?.parent?.ownerProfile?._id)
                        }
                        await createApprovalTemplate({
                            templeteData,
                            modules: [approvalTypesModuleMap[templeteData?.type]],
                            libraryId: profileData?.parent?.wallet?.defaultLibrary,
                            shared: shareArr,
                            userId: user?._id,
                            entity,
                            entityModelName,
                            entityParent,
                            entityParentModelName
                        })
                            .then((data) => {
                                console.log(data)
                                resetData()
                                setCreateTempleteDrawer(false)
                                afterTempleteCreateOrEdit()
                            })
                            .catch((err) => {
                                console.log(err);
                                setLoader(false)
                            })
                    }
                } else {
                    setAlertObj({
                        show: true,
                        message: (<>{validationArr.map((s) => (<p>{s}</p>))}</>),
                        status: "error"
                    })
                }
            })
            .catch((err) => {
                console.log(err);
                let errorArr = err?.inner;
                let obj = {};
                errorArr.map((err) => {
                    obj[err?.path] = err?.message;
                });
                setValidationObj(obj);
            });
    }

    return (
        <NormalDrawer
            openDrawer={createTempleteDawer}
            setOpenDrawer={setCreateTempleteDrawer}
            anchor={"right"}
            width={"60vw"}
            title={curEditDeleteTemplete?._id ? "Edit Approval Template " : "Create Approval Template "}
            content={<>
                {profileData?.parent?.wallet?.defaultLibrary ? (<>
                    <form className={classes.mainDialogCont} onSubmit={onSubmit} >
                        <div className={classes.bodyCont} >
                            <Paper className={classes.inputFieldsCont} >
                                <div className={classes.paperHeader} >
                                    <h3>Basic Data</h3>
                                    <div></div>
                                </div>
                                <InputWrapper
                                    label={"Title"}
                                    validation={validationObj?.title}
                                    isRequired={true}
                                    space={12}
                                    inputCom={
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size="small"
                                            placeholder="Enter title"
                                            autoComplete='off'
                                            value={templeteData?.title}
                                            fullWidth
                                            onChange={(e) => {
                                                setTempleteData({
                                                    ...templeteData,
                                                    title: e.target.value
                                                })
                                            }}
                                        />
                                    }
                                />
                                <InputWrapper
                                    label={"Type"}
                                    validation={validationObj?.type}
                                    isRequired={true}
                                    space={6}
                                    inputCom={
                                        <Autocomplete
                                            id="free-solo-demo"
                                            freeSolo
                                            value={templeteData?.type}
                                            options={Object.keys(approvalTypesDefaultFieldsMap)}
                                            disableClearable
                                            getOptionLabel={(option) => { return option }}
                                            getOptionSelected={(option) => {
                                                return option === templeteData?.type
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    style={{ margin: "0px" }}
                                                    margin="normal"
                                                    variant="outlined"
                                                    placeholder={"Select Type"}
                                                />
                                            )}
                                            onChange={(event, value) => {
                                                typeChangeHandler(value)
                                            }}
                                            fullWidth
                                            size="small"
                                        />
                                    }
                                />
                                <InputWrapper
                                    label={"Code"}
                                    validation={validationObj?.code}
                                    isRequired={true}
                                    space={6}
                                    inputCom={
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size="small"
                                            placeholder="Enter code"
                                            autoComplete='off'
                                            value={templeteData?.code}
                                            fullWidth
                                            onChange={(e) => {
                                                setTempleteData({
                                                    ...templeteData,
                                                    code: e.target.value
                                                })
                                            }}
                                        />
                                    }
                                />
                                <InputWrapper
                                    label={"Description"}
                                    validation={""}
                                    space={12}
                                    inputCom={
                                        <TextField
                                            id="outlined-textarea"
                                            variant="outlined"
                                            size="small"
                                            multiline
                                            rows={4}
                                            placeholder="Enter description"
                                            autoComplete='off'
                                            value={templeteData?.description}
                                            fullWidth
                                            onChange={(e) => {
                                                setTempleteData({
                                                    ...templeteData,
                                                    description: e.target.value
                                                })
                                            }}
                                        />
                                    }
                                />
                            </Paper>

                            {templeteData && templeteData?.type && (
                                <Paper className={classes.inputFieldsCont} >
                                    <div className={classes.paperHeader} >
                                        <h3>Approval Stages</h3>
                                        {statusesAgainstType && statusesAgainstType.length === 0 ? (
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                onClick={() => {
                                                    let arr = templeteData?.stages || [];
                                                    arr.push({
                                                        stageNo: (arr.length + 1),
                                                        status: "",
                                                        participantsType: "specific_same_power",
                                                        numberOfApprovalNeed: 0,
                                                        canEditMainData:false,
                                                        approvalsWithType: []
                                                    })
                                                    setTempleteData({
                                                        ...templeteData,
                                                        stages: arr
                                                    })
                                                }}
                                            >
                                                Add Stage
                                            </Button>
                                        ) : null}
                                    </div>
                                    {templeteData?.stages && templeteData.stages.map((stageData, index) => (
                                        <StageCreateCard
                                            stageData={stageData}
                                            setTempleteData={setTempleteData}
                                            templeteData={templeteData}
                                            index={index}
                                            key={index}
                                        />
                                    ))}
                                </Paper>
                            )}
                            <NotifyAlert
                                alertObj={alertObj}
                                setAlertObj={setAlertObj}
                            />
                        </div>
                        <div style={{ width: "100%", height: "5px" }} >
                            {loader && (<LinearProgress />)}
                        </div>
                        <div className={classes.bottomAct} >
                            <Button
                                variant="contained"
                                size="small"
                                disabled={loader}
                                color="primary"
                                type="submit"
                            >
                                Save & Close
                            </Button>
                        </div>
                    </form>
                </>) : (
                    <div className={classes.emptyCont} >
                        <img src={emptyPage} />
                        <p>
                            No Library exists in the current directory or any library still didn't selected as default library.<br />
                            Click <a
                                onClick={() => {
                                    history.push(`/offerings-categories/management/${profileData?._id}`)
                                }}
                            >here</a> to create and manage your library(s), category(s), product(s) and service(s)
                        </p>
                    </div>
                )}
            </>}
        />
    );
}