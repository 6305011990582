import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import UserProfileTopView from "../profile/UserProfileTopView";
import AccountEdit from "../profile/ProfileEdit/Account.Edit";
import Slide from "@material-ui/core/Slide";
import axios from "axios";
import AccountSkeleton from "../styled/CommonComponents/Account.Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "calc(100vh - 65px)",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 120px)",
      marginTop: "-1px"
    },
  },
  page1: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    padding: "20px 90px 50px",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px 50px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 50px",
    },
  },
  page2: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0px",
    left: "0px",
    zIndex: "1000",
    backgroundColor: "white"
  }
}));

const Account = (props) => {
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  const [openEditView, setOpenEditView] = useState(false)
  const [profileEditView, setProfileEditView] = useState("basicInfo")
  const [profile, setProfile] = useState(null)


  useEffect(() => {
    axios.get(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd.json`)
      .then((res) => {
        console.log(res.data);
      })
  }, []);


  useEffect(() => {
    setProfile({
      _id: user?.profile,
      parent: user,
      parentModelName: "User",
    })
  }, [
    user?.education, user?.experience, user?.licenses,
    user?.languages, user?.projectExp, user?.displayName,
    user?.displayPicture, user?.displayPicture?.url,
    user?.cover, user?.about, user?.displayName,
    user?.description, user?.address, user?.markets,
    user?.skillTags, user?.skills, user?.cover?.url,
  ])


  return (
    <div className={classes.root}>
      <div className={classes.page1} >
        {profile ? (
          <UserProfileTopView
            profile={profile}
            isOwnProfile={true}
            setOpenEditView={setOpenEditView}
            setProfileEditView={setProfileEditView}
          />
        ) : (
          <AccountSkeleton />
        )}
      </div>
      <Slide direction="left" in={openEditView} mountOnEnter unmountOnExit>
        <div className={classes.page2} >
          <AccountEdit
            setOpenEditView={setOpenEditView}
            profileEditView={profileEditView}
            setProfileEditView={setProfileEditView}
          />
        </div>
      </Slide>
    </div>
  );
};
export default Account;