import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import CustomFileUploadButton from "../../file/Uploader/CustomFileUploadButton";
import CustomBtn from "../../styled/CommonComponents/CustomBtn"
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { addFilesToPublicProject, updateDoc, removeFiles } from "../api";
import { IconButton } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import LessText from "../../styled/CommonComponents/LessText";

const useStyles = makeStyles((theme) => ({
    singleFileCont: {
        width: '100%',
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        marginTop: "15px",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "center",
        }
    },
    editBtn: {
        position: "absolute",
        top: "3px",
        right: "3px",
        backgroundColor: theme.palette.primary.main,
        color: "white",
        "&:hover": {
            backgroundColor: "#076cbc"
        }
    },
    textInputCont: {
        paddingLeft: "15px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            padding: "0px"
        }
    },
    descAndBtnCont: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end"
    },
    btnCont: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "20px"
    },
    fileCont: {
        display: "flex",
        flexWrap: "wrap",
        paddingTop: "40px"
    },
    singleFileCont2: {
        width: "150px",
        marginRight: "15px",
        marginBottom: "15px",
        position: "relative",
    },
    imageStyle: {
        minWidth: "150px",
        maxHeight: "150px",
    },
    imageContView: {
        width: "150px",
        height: "150px",
        overflow: "hidden"
    },
    imageCont: {
        width: "200px",
        overflow: "hidden",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "10px"
        }
    },
    imageSty: {
        float: "left",
        width: "200px",
        height: "200px",
        objectFit: "cover",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            height: "auto"
        }
    },
    detailsCont: {
        padding: "10px"
    }
}));



const ProjectPhotoEdit = ({ fileObj, setFileObj, index, project, loading, setLoading }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { createdFileIds } = useSelector((state) => state.file);

    const [stateChange, setStateChange] = useState(false)
    const [fTitle, setFTitle] = useState("")
    const [fileUrl, setFileUrl] = useState(null)
    const [fDesc, setFDesc] = useState("")
    const [fTag, setFTag] = useState("")
    const [fIndex, setFTIndex] = useState(null)
    const [fId, setFId] = useState(null)


    const addFileObj = async () => {
        if (createdFileIds && createdFileIds.length > 0) {
            setLoading(true)
            let filesArr = []
            createdFileIds.map((file) => {
                filesArr.push({
                    image: file,
                    title: "",
                    desc: "",
                    tag: ""
                })
            })

            await addFilesToPublicProject({
                docsArr: filesArr,
                projectId: project?.project?._id,
                userProfile: user?.profile,
                userId: user?._id
            })
                .then((data) => {
                    let updatedExpProjectArr = user?.projectExp || []
                    let locExp = updatedExpProjectArr[index];
                    locExp.project = data
                    updatedExpProjectArr[index] = locExp

                    const userObj = {
                        projectExp: updatedExpProjectArr,
                    };

                    dispatch({
                        type: "AddAuth",
                        payload: {
                            user: {
                                ...user,
                                ...userObj,
                            },
                        },
                    });

                    let fileArr = data?.docs || []
                    let newFilesArr = []
                    fileArr.map((file) => {
                        newFilesArr.push({
                            _id: file?._id,
                            file: file?.files[0],
                            title: file?.title,
                            description: file?.description,
                            tag: file?.tag
                        })
                    })
                    setFileObj(newFilesArr)
                })
                .catch((err) => {
                    console.log(err)
                })
            setLoading(false)
        }
    }

    const removeFile = async (id) => {
        setLoading(true)
        await removeFiles({
            projectId: project?.project?._id,
            docIds: [id]
        })
            .then((data) => {
                if (data) {
                    let updatedExpProjectArr = user?.projectExp || []
                    let locExp = updatedExpProjectArr[index];
                    locExp.project = data
                    updatedExpProjectArr[index] = locExp

                    const userObj = {
                        projectExp: updatedExpProjectArr,
                    };

                    dispatch({
                        type: "AddAuth",
                        payload: {
                            user: {
                                ...user,
                                ...userObj,
                            },
                        },
                    });

                    let fileArr = data?.docs || []
                    let newFilesArr = []
                    fileArr.map((file) => {
                        newFilesArr.push({
                            _id: file?._id,
                            file: file?.files[0],
                            title: file?.title,
                            description: file?.description,
                            tag: file?.tag
                        })
                    })
                    setFileObj(newFilesArr)
                }
                setFTitle("")
                setFDesc("")
                setFTag("")
                setFTIndex(null)
                setFId(null)
                setFileUrl(null)
            })
            .catch((err) => {
                console.log(err)
            })
        setLoading(false)
    }

    const updateSingleDoc = async (i) => {
        setLoading(true)
        const obj = {
            _id: fId,
            title: fTitle,
            tag: fTag,
            description: fDesc
        }

        await updateDoc(obj)
            .then((data) => {
                if (data) {
                    let updatedExpProjectArr = user?.projectExp || []
                    let locDocs = updatedExpProjectArr[index]?.project?.docs
                    locDocs[i] = data
                    updatedExpProjectArr[index].project.docs = locDocs

                    const userObj = {
                        projectExp: updatedExpProjectArr,
                    };

                    let newFilesArr = []
                    locDocs.map((file) => {
                        newFilesArr.push({
                            _id: file?._id,
                            file: file?.files[0],
                            title: file?.title,
                            description: file?.description,
                            tag: file?.tag
                        })
                    })
                    setFileObj(newFilesArr)

                    dispatch({
                        type: "AddAuth",
                        payload: {
                            user: {
                                ...user,
                                ...userObj,
                            },
                        },
                    });
                }
                setFTitle("")
                setFDesc("")
                setFTag("")
                setFTIndex(null)
                setFId(null)
                setFileUrl(null)
            })
            .catch((err) => {
                console.log(err)
            })
        setLoading(false)
    }


    return (<div style={{ padding: "15px 0px" }} >
        {fileUrl && fId && (
            <div className={classes.singleFileCont} >
                <div className={classes.imageCont} >
                    <img src={fileUrl} className={classes.imageSty} />
                </div>
                <div className={classes.textInputCont} >
                    <TextField
                        id="Title"
                        placeholder="Enter photo title"
                        variant="outlined"
                        size="small"
                        value={fTitle}
                        onChange={(e) => {
                            setFTitle(e.target.value);
                        }}
                        fullWidth
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        id="Title"
                        placeholder="Enter photo tag"
                        variant="outlined"
                        size="small"
                        value={fTag}
                        onChange={(e) => {
                            setFTag(e.target.value);
                        }}
                        fullWidth
                        style={{ marginBottom: "10px" }}
                    />
                    <div className={classes.descAndBtnCont} >
                        <TextField
                            id="Description"
                            placeholder="Enter description"
                            variant="outlined"
                            size="small"
                            rows={3}
                            multiline
                            value={fDesc}
                            onChange={(e) => {
                                setFDesc(e.target.value);
                            }}
                            style={{ width: "100%" }}
                        />
                        <div className={classes.btnCont} >
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => { removeFile(fId) }}
                            >
                                Delete
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginTop: "5px" }}
                                onClick={() => { updateSingleDoc(fIndex) }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )}


        {fileObj && fileObj.length > null ? (<div className={classes.fileCont} >
            {fileObj && fileObj.map((file, i) => (
                <div key={i} className={classes.singleFileCont2} >
                    {fId === file?._id ? (
                        <IconButton
                            onClick={() => {
                                setFTitle("")
                                setFDesc("")
                                setFTag("")
                                setFTIndex(null)
                                setFId(null)
                                setFileUrl(null)
                            }}
                            className={classes.editBtn}
                            size="small"
                        >
                            <ClearIcon />
                        </IconButton>
                    ) : (
                        <IconButton
                            onClick={() => {
                                setFTitle(file?.title)
                                setFDesc(file?.description)
                                setFTag(file?.tag)
                                setFTIndex(i)
                                setFId(file?._id)
                                setFileUrl(file?.file?.url)
                            }}
                            className={classes.editBtn}
                            size="small"
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                    <div className={classes.imageContView} >
                        <img className={classes.imageStyle} src={file?.file?.url} />
                    </div>
                    <div className={classes.detailsCont} >
                        <h3>{file?.title}</h3>
                        <p>
                            <LessText
                                limit={15}
                                string={file?.description}
                            />
                        </p>
                    </div>
                </div>
            ))}
        </div>) : (
            <h3>No photo or video available, edit to add file(s)</h3>
        )}

        <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "15px" }} >
            <CustomFileUploadButton
                showComponent={
                    <CustomBtn
                        text={"Add Photo/Video"}
                        startPart={<AddIcon />}
                    />
                }
                parentType={"ProjectExp"}
                parentId={null}
                fileNum={25}
                givenFileTypes={["image", "video"]}
                givenMaxSize={26214400}
                closeFunCall={addFileObj}
            />
        </div>
    </div>);
};

export default ProjectPhotoEdit;
