import React from "react";
import { getColorByLabel } from "../../../helpers/colorHelper";

const StatusChip = ({
  label,
  variant,
  styleProps,
  rounded = false,
  fontSize = "0.8rem",
  icon,
}) => {
  const { statusTextColor, statusBackgroundColor } = getColorByLabel(variant);

  return (
    <span
      style={{
        color: statusTextColor,
        backgroundColor: statusBackgroundColor,
        width: "fit-content",
        padding: "5px 10px",
        borderRadius: rounded ? "32px" : "5px",
        fontWeight: "normal",
        fontSize: fontSize,
        display: "flex",
        alignItems: "center",
        ...styleProps,
      }}
    >
      {icon && <span style={{ marginRight: "5px" }}>{icon}</span>}
      {label}
    </span>
  );
};

export default StatusChip;
