import { Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";

const DateText = ({
  prefix,
  date,
  variant = "subtitle1",
  mode = "date",
  ...props
}) => {
  return (
    <Typography variant={variant} {...props}>
      {prefix}
      {dayjs(date).format(mode === "date" ? "DD MMM YYYY" : "hh:mm A")}
    </Typography>
  );
};

export default DateText;
