import React, { useState, useEffect } from "react";
import TimeSheetDrawer from "./global/TimeSheetDrawer";

const useTimesheetDrawer = () => {
  const [isTimesheetDrawerOpen, setIsTimesheetDrawerOpen] = useState(false);
  const [timesheetData, setTimesheetData] = useState(null);

  const handleTimesheetOpen = (data) => {
    setIsTimesheetDrawerOpen(true);
    setTimesheetData(data);
  };

  const timesheetDrawer = (
    <TimeSheetDrawer
      isDrawerOpen={isTimesheetDrawerOpen}
      setIsDrawerOpen={setIsTimesheetDrawerOpen}
      timesheet={timesheetData}
      setTimesheet={setTimesheetData}
    />
  );

  return {
    timesheetData,
    timesheetDrawer,
    handleTimesheetOpen,
  };
};

export default useTimesheetDrawer;
