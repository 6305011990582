import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    dataLineCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBottom: "5px",
        "& h3": {
            width: "120px",
            fontSize: "15px",
            fontWeight: "500",
            color: "gray"
        },
        "& p": {
            width: "calc(100% - 125px)",
            fontSize: "15px",
            fontWeight: "500",
            color: "black",
            paddingLeft: "10px",
        },
    }
}));

export default function ContactBasicData({
    contactData
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    return (<>
        {contactData?.profile?.parent?.type === "User" ? (<>
            <div className={classes.dataLineCont} >
                <h3>Mail Id</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.email}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Phone No.</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.phone}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Street Address</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.address?.street}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>City</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.address?.city}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>State</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.address?.state}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Country</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.address?.country}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Postal Code</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.address?.postalCode}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Tax Id</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.taxId}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Pan</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.pan}</p>
            </div>
        </>) : (<>{contactData?.profile?.parent?.type === "Organization" ? (<>
            <div className={classes.dataLineCont} >
                <h3>Mail Id</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.email}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Phone No.</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.phone}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Street Address</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.address?.street}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>City</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.address?.city}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>State</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.address?.state}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Country</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.address?.country}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Postal Code</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.address?.postalCode}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Tax Id</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.taxId}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Pan</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.pan}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Web Site</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.website}</p>
            </div>
        </>) : (<>
            <div className={classes.dataLineCont} >
                <h3>Mail Id</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.email}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Phone No.</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.phone}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Tax Id</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.taxId}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Hourly Rate</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.hourlyRate}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Cost Code</h3>
                <span>:</span>
                <p>{contactData?.profile?.parent?.costCode}</p>
            </div>
        </>)}</>)}
    </>);
}