const initialState = {
    teamAccessMap: {},
    accessAbleTeams: [],
    accessAbleProjectTeams: [],
    accessAbleOrganizationTeams: [],
    accessAbleProfiles: [],
    accessAbleProjectProfiles: [],
    accessAbleOrganizationProfiles: [],
    accessRoleMap: {},
    loader: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SetAllAccessControl':
            return {
                ...action.payload,
            };

        case 'AddAccessControl':
            return {
                ...state,
                ...action.payload,
            };

        case 'AddAccessControlReset':
            return initialState;

        default:
            return state;
    }
};