import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Avatar } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import LessText from "../../styled/CommonComponents/LessText";
import { format } from "timeago.js";
import { RentalROICalcuations, BRRRRROICalcuations, FlipROICalculations } from "./Analysis.Calculation"

const useStyles = makeStyles((theme) => ({
    tbBodyCell: {
        border: "none",
        padding: "8px 5px"
    },
    timeSty: {
        fontSize: "10px",
        fontWeight: "500",
        color: "gray"
    },
}));

const AnalysisSingleRow = ({ reportData }) => {
    const classes = useStyles();
    const history = useHistory();
    const [roi, setRoi] = useState()

    useEffect(() => {
        let roiData = 0;
        if (reportData?.reportType === "Rental") {
            roiData = RentalROICalcuations(reportData)
        } else if (reportData?.reportType === "BRRRR") {
            roiData = BRRRRROICalcuations(reportData)
        } else if (reportData?.reportType === "Flip") {
            roiData = FlipROICalculations(reportData)
        }
        setRoi(roiData)
    }, [reportData])

    const getFormatedData = (data) => {
        let formatedData = new Intl.NumberFormat('en-GB', { notation: "compact", compactDisplay: "short" }).format(data);
        return formatedData;
    }


    return (
        <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={reportData?._id}
            onClick={() => { history.push(`/analysis/${reportData?.teamId?._id}/report/${reportData?._id}`) }}
            style={{ cursor: "pointer" }}
        >
            <TableCell align="left" className={classes.tbBodyCell} style={{ paddingLeft: "0px" }} >
                <div style={{ display: "flex", alignItems: "center" }} >
                    <Avatar src={reportData?.teamId?.parent?.displayPicture?.url} style={{ width: "35px", height: "35px" }} />
                    <div style={{ marginLeft: "10px" }} >
                        <p style={{ fontSize: "12px", color: "#333333", fontWeight: "510", textTransform: "capitalize" }} >
                            <LessText
                                limit={13}
                                string={reportData?.reportTitle}
                            />
                        </p>
                        <p style={{ fontSize: "10px", color: "gray" }} >
                            {`By `}
                            <LessText limit={11} string={reportData?.teamId?.parent?.displayName} />
                        </p>
                        <p className={classes.timeSty} >{format(reportData?.createdAt)}</p>
                    </div>
                </div>
            </TableCell>
            <TableCell align="center" className={classes.tbBodyCell} style={{ padding: "0px", color: "#4F4F4F", fontSize: "13px", fontWeight: "510" }} >
                {parseFloat(roi).toFixed(1)} %
            </TableCell>
            <TableCell align="right" className={classes.tbBodyCell} style={{ color: "#4F4F4F", fontSize: "13px", fontWeight: "500", paddingRight: "5px" }} >
                {`₹ ${getFormatedData(reportData?.purchasePrice || 0)}`}
            </TableCell>
        </TableRow>
    );
};
export default AnalysisSingleRow;