import { Box } from "@mui/material";
import React, { useState } from "react";

const Image = ({
  src,
  aspectRatio,
  objectFit = "cover",
  imageProps,
  width = "100%",
  height = "auto",
  onClick,
}) => {
  const [isImageBroken, setIsImageBroken] = useState(false);

  const handleImageError = () => {
    setIsImageBroken(true);
  };

  const getAspectRatio = (ratio) => {
    switch (ratio) {
      case "16:9":
        return "56.25%";
      case "3:1":
        return "31.44%";
      case "1:1":
        return "100%";
      default:
        return "0%";
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        paddingBottom: getAspectRatio(aspectRatio),
        overflow: "hidden",
        width: width,
        height: height,
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {isImageBroken || !src ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: objectFit,
              position: "absolute",
              top: 0,
              left: 0,
              ...imageProps,
            }}
            src="/icons/app/image_placeholder.jpg"
            alt="placeholder"
          />
        </Box>
      ) : (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: objectFit,
            position: "absolute",
            top: 0,
            left: 0,
            ...imageProps,
          }}
          src={src}
          onError={handleImageError}
        />
      )}
    </Box>
  );
};

export default Image;
