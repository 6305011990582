import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DownloadDocCard from "./DownloadDocCard";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import DocCustomActionBar from "./DocCustomActionBar";
import "./DocumentViewer.css";

const useStyles = makeStyles((theme) => ({
    downloadCont: {
        display: "flex",
        flexWrap: "wrap",
        "& div": {
            margin: "0px",
            marginRight: "10px",
            marginBottom: "10px",
            width: "270px"
        }
    },
    allActionAbleCont: {
        width: "100%",
        padding: "35px 0px 0px",
        "& h4": {
            fontSize: "16px",
            fontWeight: "500",
        }
    }
}));

export default function AllKindOfDocViews(props) {
    const classes = useStyles();
    const { files, removeFun, isDeletable = false } = props

    const [docsArr, setDocsArr] = useState([])
    const [downloadDocsArr, setDownloadDocsArr] = useState([])

    useEffect(() => {
        let arr = [];
        let docs = [];
        files && files.length > 0 && files.map((file) => {
            docs.push({
                _id: file?._id,
                url: file?.url,
                name: file?.name,
                type: file?.type,
            })
            arr.push({
                uri: file?.url,
                name: file?.name
            })
        })
        setDownloadDocsArr(docs)
        setDocsArr(arr)
    }, [files])

    return (<>
        <div className="viewerContainer">
            <DocViewer
                className="viewer"
                documents={docsArr}
                pluginRenderers={DocViewerRenderers}
                config={{
                    header: {
                        overrideComponent: DocCustomActionBar,
                        disableHeader: false,
                        disableFileName: false,
                        retainURLParams: false
                    },
                    csvDelimiter: ",",
                    pdfZoom: {
                        defaultZoom: 1,
                        zoomJump: 0.08
                    }
                }}
            />
        </div>

        <div className={classes.allActionAbleCont} >
            {downloadDocsArr && downloadDocsArr.length > 0 && (<>
                <h4>Documents</h4>
                <div className={classes.downloadCont} >
                    {downloadDocsArr.length > 0 && downloadDocsArr.map((file, i) => (
                        <DownloadDocCard
                            key={i}
                            file={file}
                            isDeletable={isDeletable}
                            removeFun={removeFun}
                        />
                    ))}
                </div>
            </>)}
        </div>
    </>);
}