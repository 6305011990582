import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import { Box, IconButton, Typography } from "@mui/material";

import React from "react";

const Counter = ({ label, icon, count, setCount }) => {
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <img src={icon} style={{ marginRight: "10px" }} />
        <Typography variant="h6">{label}</Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <IconButton onClick={() => setCount(count + 1)} color="primary">
          <AddCircleOutline />
        </IconButton>
        <Typography variant="h5" color="primary">
          {count}
        </Typography>
        <IconButton onClick={() => setCount(count - 1)} color="primary">
          <RemoveCircleOutline />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Counter