import React, { useState, useEffect } from "react";
import Api from "../helpers/Api";
import WorkspaceConfigDialog from "./organization/Dialogs/Workspace.Config.Dialog";
import { useDispatch, useSelector } from "react-redux";
import arrayToReducer from "../helpers/arrayToReducer";

const useWorkspace = (props) => {
  const { type, title, configTitle } = props;

  const dispatch = useDispatch();

  const [showWorkspaceConfigDialog, setShowWorkspaceConfigDialog] =
    useState(false);
  const [apps, setApps] = useState([]);
  const [previousApps, setPreviousApps] = useState([]);
  const [config, setConfig] = useState({});
  const [id, setId] = useState(null);

  const { workspaceConfig } = useSelector((state) => state.workspace);
  const workspaceObj = useSelector((state) => state.workspace);


  // Get default / native apps
  const getWorkspaceApps = async () => {
    try {
      const data = await Api.post("appModule/apps");
      if (type === "User") {
        setApps(data.data.userApps);
      } else if (type === "Project") {
        setApps(data.data.projectApps);
      } else if (type === "Organization") {
        setApps(data.data.organizationApps);
      } else {
        setApps([]);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching workspace apps",
        },
      });
    }
  };

  const getWorkspaceConfig = async () => {
    if (!workspaceConfig || !workspaceConfig[id]) {
      try {
        const { data } = await Api.post("appModule/", {
          id: id,
          workspaceType: type,
        });
        setConfig(data);
        let updatedConfig = workspaceConfig;
        updatedConfig[id] = data;

        dispatch({
          type: "AddWorkspace",
          payload: {
            workspaceConfig: updatedConfig,
          },
        });
      } catch (err) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred" + err,
          },
        });
      }
    } else {
      setConfig(workspaceConfig[id]);
    }
  };

  const updateWorkspaceConfig = async () => {
    try {
      const { data } = await Api.post("appModule/update", {
        id: id,
        workspaceType: type,
        ...config,
      });

      setConfig(data);
      let updatedConfig = workspaceConfig;
      updatedConfig[id] = data;

      dispatch({
        type: "AddWorkspace",
        payload: {
          workspaceConfig: updatedConfig,
        },
      });

      setShowWorkspaceConfigDialog(false);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Workspace updated successfully",
        },
      });
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred" + err,
        },
      });
    }
  };

  useEffect(() => {
    if (id) {
      getWorkspaceApps();
      getWorkspaceConfig();
    }
  }, [id]);

  // For making MUI dropdown default value selection
  useEffect(() => {
    if (
      id &&
      apps &&
      config &&
      apps.length &&
      config.apps &&
      config.apps.length
    ) {
      const allApps = apps;
      const configApps = config.apps;
      let common = [];
      for (let i = 0; i < allApps.length; i++) {
        let allApp = allApps[i];
        for (let j = 0; j < configApps.length; j++) {
          let configApp = configApps[j];
          if (configApp._id === allApp._id) {
            common.push(allApp);
          }
        }
      }
      setPreviousApps(common);
    }
  }, [id, apps, config]);

  const workspaceConfigDialog = (
    <WorkspaceConfigDialog
      showWorkspaceConfigDialog={showWorkspaceConfigDialog}
      setShowWorkspaceConfigDialog={setShowWorkspaceConfigDialog}
      title={title}
      configTitle={configTitle}
      configAppList={apps}
      config={config}
      setConfig={setConfig}
      saveFunction={updateWorkspaceConfig}
      previousApps={previousApps}
      setOpen={setShowWorkspaceConfigDialog}
    />
  );

  return {
    showWorkspaceConfigDialog,
    setShowWorkspaceConfigDialog,
    id,
    setId,
    workspaceConfigDialog,
    config,
  };
};

export default useWorkspace;
