import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationsApi } from "../../listing.api";
import ApplicationsTableComponent from "../tables/ApplicationsTableComponent";
import Api from "../../../../helpers/Api";

const MyRequirementApplications = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [myApplications, setMyApplications] = useState([]);
  const [myApplicationsLoading, setMyApplicationsLoading] = useState(false);

  const actions = ["TODO"];
  const handleActionClick = () => {};

  const getMyApplications = async () => {
    try {
      setMyApplicationsLoading(true);
      const { data } = await Api.post("/listing/app/listing-apps", {
        ownerId: user?.profile,
      });
      setMyApplications(data);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch my applications",
        },
      });
    } finally {
      setMyApplicationsLoading(false);
    }
  };

  useEffect(() => {
    getMyApplications();
  }, []);

  return (
    <ApplicationsTableComponent
      applications={myApplications}
      setApplications={setMyApplications}
      loading={myApplicationsLoading}
      actions={actions}
      handleActionClick={handleActionClick}
    />
  );
};

export default MyRequirementApplications;
