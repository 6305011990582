import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import { useParams, useHistory } from 'react-router-dom';
import { getLibrariesByProfileId } from "./Api.call";
import Skeleton from '@material-ui/lab/Skeleton';
import emptyPage from "../../Assets/EmptyPageSVG.svg"
import { Checkbox } from "@mui/material";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

const useStyles = makeStyles((theme) => ({
    emptyLibrary: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "30px 0px",
        "& img": {
            width: "100px",
            height: "auto",
            marginBottom: "10px"
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "400",
            color: "gray"
        }
    },
    singleLibrary: {
        padding: "4px 10px",
        cursor: "pointer",
        borderBottom: "1px solid #ececec",
        display: "flex",
        alignItems: "center",
        "& p": {
            width: "calc(100% - 80px)",
            fontSize: "15px",
            fontWeight: "500",
            marginLeft: "5px"
        }
    },
    iconSty: {
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            color: "gray"
        },
    }
}));

const ImportLibraryBodyAccordion = ({
    profile, existingLibraries, selectedLibrarIds,
    setSelectedLibrarIds, selectedLibrarIdProfileIdObjArr,
    setSelectedLibrarIdProfileIdObjArr,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();

    const [libraries, setLibraries] = useState([])
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setLoader(true)
        getLibrariesByProfileId({ profileId: profile?._id })
            .then((data) => {
                setLibraries(data)
                setLoader(false)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [profile?._id])

    return (
        <div style={{ backgroundColor: "#f5f5f5" }} >
            {loader ? (<>
                <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "10px" }} />
                <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "10px" }} />
                <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "10px" }} />
                <Skeleton variant="rect" style={{ width: "100%", height: "40px", marginBottom: "10px" }} />
                <Skeleton variant="rect" style={{ width: "100%", height: "40px" }} />
            </>) : (<>
                {libraries && libraries.length > 0 ? (<>
                    {libraries && libraries.map((library) => (
                        <div className={classes.singleLibrary} >
                            <Checkbox
                                checked={selectedLibrarIds.includes(library?._id)}
                                onChange={(evt, checked) => {
                                    let arrObj = []
                                    let arr = []
                                    const libraryId = library?._id;
                                    if (checked) {
                                        const Obj = {
                                            library: library,
                                            profileId: profile?._id
                                        }
                                        arrObj = [...selectedLibrarIdProfileIdObjArr, Obj]
                                        arr = [...selectedLibrarIds, libraryId]
                                    } else {
                                        arrObj = selectedLibrarIdProfileIdObjArr.filter(s => s?.library?._id != libraryId)
                                        arr = selectedLibrarIds.filter(id => id != libraryId)
                                    }
                                    setSelectedLibrarIds(arr)
                                    setSelectedLibrarIdProfileIdObjArr(arrObj)
                                }}
                            />
                            <span className={classes.iconSty} >
                                <LibraryBooksIcon style={{ marginTop: "7px" }} />
                            </span>
                            <p>{library?.name}</p>
                        </div>
                    ))}
                </>) : (<div className={classes.emptyLibrary} >
                    <img src={emptyPage} />
                    <p>No Library is available in current directory</p>
                </div>)}
            </>)}
        </div>
    );
};

export default ImportLibraryBodyAccordion;