import { FormControl, InputLabel, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import Api from "../../../helpers/Api";
import { useSelector } from "react-redux";
import Select from "./Select";

const ProjectPickerDropdown = ({
  selectedProject,
  setSelectedProject,
  defaultProjectId,
  size,
  hideLabel,
  fullWidth = false,
  disabled = false,
}) => {
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const [projects, setProjects] = useState([]);

  const setUpProjects = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Project") {
        setProjects((prev) => [...prev, team?.parent]);
      }
    }
  };

  const handleDefaultSelection = () => {
    if (projects?.length > 0 && defaultProjectId) {
      let index = projects.findIndex((item) => item?._id === defaultProjectId);
      if (index !== -1) {
        setSelectedProject(projects[index]);
      }
    } else if (projects?.length > 0) {
      setSelectedProject(projects[0]);
    } else {
      // NO-OP
    }
  };

  useEffect(() => {
    setUpProjects();
  }, []);

  useEffect(() => {
    handleDefaultSelection();
  }, [projects, defaultProjectId]);

  return (
    <FormControl size={size} fullWidth={fullWidth} disabled={disabled}>
      {!hideLabel && (
        <InputLabel
          id="demo-simple-select-helper-label"
          shrink={selectedProject !== null}
        >
          {selectedProject ? "Project" : "Select Project"}
        </InputLabel>
      )}

      <Select
        value={selectedProject}
        onChange={(evt) => setSelectedProject(evt.target.value)}
        label={
          hideLabel ? null : selectedProject ? "Project" : "Select Project"
        }
        notched={hideLabel ? false : selectedProject !== null}
        displayEmpty
      >
        <MenuItem>{selectedProject?.displayName || "Select"}</MenuItem>
        {projects?.map((obj) => (
          <MenuItem key={obj._id} value={obj}>
            {obj?.displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ProjectPickerDropdown;
