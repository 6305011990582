import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AutocompleteOption from './AutocompleteOption';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, ClickAwayListener, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    autocompleteCont: {
        width: "100%",
        position: "relative",
        "& .MuiIconButton-root": {
            padding: "5px"
        }
    },
    optionsContShow: {
        width: "100%",
        maxHeight: "280px",
        position: "absolute",
        left: "0px",
        top: "40px",
        backgroundColor: "white",
        zIndex: "99999",
        borderTop: "none",
        borderBottomLeftRadius: "3px",
        borderBottomRightRadius: "3px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        overflowX: "hidden",
        overflowY: "auto",
        transition: "all .3s ease-in-out",
    },
    optionsContHide: {
        width: "100%",
        maxHeight: "0px",
        position: "absolute",
        left: "0px",
        top: "40px",
        borderTop: "none",
        zIndex: "99999",
        backgroundColor: "white",
        borderBottomLeftRadius: "3px",
        borderBottomRightRadius: "3px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        overflowX: "hidden",
        overflowY: "auto",
        transition: "all .3s ease-in-out",
    },
}));

export default function CustomAutocomplete({
    options, selectedValue, onSelect, keyField,
    lable, placeholder,
}) {
    const classes = useStyles();
    const theme = useTheme();

    const [isSearching, setIsSearching] = useState(false)
    const [showBool, setShowBool] = useState(false)
    const [searchStr, setSearchStr] = useState("")

    return (<>
        <ClickAwayListener
            onClickAway={() => {
                setIsSearching(false)
                setShowBool(false)
            }}
        >
            <div className={classes.autocompleteCont} >
                {isSearching ? (
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        label={lable}
                        placeholder={placeholder}
                        autoComplete='off'
                        value={searchStr}
                        size="small"
                        fullWidth
                        onChange={(e) => { setSearchStr(e.target.value); }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" >
                                    <IconButton>
                                        <ArrowDropDownIcon fontSize="small" />
                                    </IconButton>
                                </InputAdornment>)
                        }}
                    />
                ) : (
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        label={lable}
                        size="small"
                        placeholder={placeholder}
                        autoComplete='off'
                        value={selectedValue && selectedValue?.[keyField] ? selectedValue?.[keyField] : ""}
                        fullWidth
                        onClick={() => {
                            setIsSearching(true)
                            setShowBool(true)
                            setSearchStr("")
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" >
                                    {selectedValue && selectedValue?.[keyField] ? (
                                        <IconButton
                                            onClick={() => {
                                                onSelect(null)
                                                setSearchStr("")
                                            }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    ) : (<span></span>)}
                                </InputAdornment>)
                        }}
                    />
                )}
                <div className={showBool ? classes.optionsContShow : classes.optionsContHide} >
                    {options && options.map((optionData) => (
                        <AutocompleteOption
                            optionData={optionData}
                            paddingLeft={10}
                            searchStr={searchStr}
                            onSelect={onSelect}
                            keyField={keyField}
                            isSearching={isSearching}
                            setSearchStr={setSearchStr}
                            selectedValue={selectedValue}
                            setShowBool={setShowBool}
                            setIsSearching={setIsSearching}
                        />
                    ))}
                </div>
            </div>
        </ClickAwayListener>
    </>);
}