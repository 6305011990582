import { styled } from "@mui/material";
import MuiChip from "@mui/material/Chip";

const Chip = styled(MuiChip)({
  "&.MuiChip-root": {
    padding: "22px 20px",
    fontSize: "1rem",
    margin: "4px",
  },
});

export default Chip;
