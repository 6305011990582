import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import { createOrg } from "./organization.utils";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NormalDialog from "../styled/CommonComponents/NormalDialog";
import GooglePlaceAutocomplete from "../styled/CommonComponents/Google.Place.Auto";
import Checkbox from "@material-ui/core/Checkbox";
import AccessControlStoreHook from "../AccessControl/AccessControlStoreHook";

const useStyles = makeStyles((theme) => ({
  titleStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "7px 10px",
    backgroundColor: theme.palette.primary.main
  },
  mainCont: {
    width: "400px",
    [theme.breakpoints.down("sm")]: {
      width: "400px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "290px",
    },
  },
  bodyCont: {
    width: "100%",
    height: "350px",
    padding: "15px 0px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down("xs")]: {
      height: "380px",
      padding: "0px 5px 15px"
    }
  },
  projectTitle: {
    width: "100%",
    marginBottom: "15px"
  },
  textArgu: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: "30px",
    "& p": {
      fontSize: "12px"
    },
    [theme.breakpoints.down("sm")]: {
      "& p": {
        fontSize: "10px"
      },
    }
  },
  descStyle: {
    width: "100%",
    marginBottom: "15px",
  },
  addressCont: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  select_input: {
    width: "100%",
  },
}));

const compSizeArray = [
  "0-1 employees",
  "2-10 employees",
  "11-50 employees",
  "51-200 employees",
  "201-500 employees",
  "501-1,000 employees",
  "1,001-5,000 employees",
  "5,001-10,000 employees",
  "10,000+ employees",
];

const agreementSentence = "I verify that I am an authorized representative of this organization and have the right to act on its behalf in the creation and management of this page. The organization and I agree to the additional terms for Pages.";

export default function CreateOrgDialog(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { setOpen, open, onCreate } = props;

  const { updateAccessByTeam } = AccessControlStoreHook()

  const state = useSelector((state) => state);
  const { user, userProfile } = useSelector((state) => state.auth);
  const userId = user._id;

  const [displayName, setDisplayName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [description, setDescription] = useState("");
  const [checkedAgreement, setChecked] = useState(false);
  const [fullAddressLine, setFullAddressLine] = useState("")
  const [streetAddress, setStreetAddress] = useState("")
  const [zip, setZip] = useState("")
  const [city, setCity] = useState("")
  const [region, setRegion] = useState("")
  const [regionCode, setRegionCode] = useState("")
  const [country, setCountry] = useState("India(IN)")
  const [countryCode, setCountryCode] = useState("in")
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const callbackLocal = () => {
    setOpen(false);
  };

  const callback = onCreate ? onCreate : callbackLocal;

  const createOrgApi = async (e) => {
    e.preventDefault()
    await createOrg(
      {
        user: userId,
        creator: userId,
        participants: [user.profile],
        displayName,
        companySize,
        address: {
          line1: fullAddressLine,
          postal_code: zip,
          city: city,
          state: region,
          country: country,
          streetAddress: streetAddress,
          zip: zip,
          region: region,
          latitude: latitude || 0,
          longitude: longitude || 0
        }
      },
      state,
      dispatch,
      callback,
      setLoading
    ).then(async (data) => {
      await updateAccessByTeam(data)
    })
      .catch((err) => {
        console.log(err)
      });

    setDisplayName("");
    setCompanySize("");
    setDescription("");
    setChecked(false);
    setFullAddressLine("")
    setStreetAddress("")
    setZip("")
    setCity("")
    setRegion("")
    setRegionCode("")
    setCountry("India(IN)")
    setCountryCode("in")
    setLatitude(null)
    setLongitude(null)
  };




  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={handleClose}
      pageTitle={"Create Organization"}
      content={<>
        <form className={classes.mainCont} onSubmit={createOrgApi} >
          <div className={classes.bodyCont} >
            <TextField
              id="outlined-basic"
              label={<>Organization Name<span style={{ color: "red" }} >*</span></>}
              variant="outlined"
              size="small"
              value={displayName}
              onChange={(e) => {
                setDisplayName(e.target.value);
              }}
              className={classes.projectTitle}
            />
            <TextField
              id="outlined-multiline-static"
              label="Description"
              rows={3}
              variant="outlined"
              size="small"
              multiline
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              className={classes.descStyle}
            />

            <FormControl variant="outlined" className={classes.select_input} size="small">
              <InputLabel htmlFor="outlined-age-native-simple">
                Select company size
              </InputLabel>
              <Select
                native
                value={companySize}
                onChange={(e) => {
                  setCompanySize(e.target.value);
                }}
                label="Select company size"
              >
                <option aria-label="None" value="" />
                {compSizeArray.map((entry, index) => {
                  return (
                    <option key={index} value={entry}>
                      {entry}
                    </option>
                  );
                })}
              </Select>
            </FormControl>

            {/* <GooglePlaceAutocomplete
              inputContStyle={classes.addressCont}
              autoWidth={"100%"}
              textWidth={"100%"}
              isGetLogLat={true}
              fullAddressLine={fullAddressLine}
              setFullAddressLine={setFullAddressLine}
              streetAddress={streetAddress}
              setStreetAddress={setStreetAddress}
              zip={zip}
              setZip={setZip}
              city={city}
              setCity={setCity}
              region={region}
              setRegion={setRegion}
              regionCode={regionCode}
              setRegionCode={setRegionCode}
              country={country}
              setCountry={setCountry}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              latitude={latitude}
              setLatitude={setLatitude}
              longitude={longitude}
              setLongitude={setLongitude}
              isShowCountry={true}
              isShowCityStreet={true}
              isStateZip={true}
            /> */}
            <div className={classes.textArgu} >
              <Checkbox
                checked={checkedAgreement}
                onChange={() => { setChecked(!checkedAgreement) }}
                color="primary"
                style={{ padding: "0px 9px 9px" }}
              />
              <p>{agreementSentence}</p>
            </div>
          </div>
          {loading && (
            <div style={{ width: "100%", height: "4px" }} >
              <LinearProgress />
            </div>
          )}
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "10px" }} >
            <div></div>
            {checkedAgreement && displayName && displayName.length > 2 ? (
              <Button
                color="primary"
                type="submit"
                variant="outlined"
              >
                Create
              </Button>
            ) : (
              <Button
                color="primary"
                variant="outlined"
                disabled
              >
                Create
              </Button>
            )}
          </div>
        </form>
      </>}
    />
  );
}