import {
  Avatar,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import TableContainer from "../../../styled/generic/TableContainer";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ListingTableSingleUserCell from "../../../styled/generic/ListingTableSingleUserCell";
import ListingTableNewItemCell from "../../../styled/generic/ListingTableNewItemCell";
import ListingTableDateAgoCell from "../../../styled/generic/ListingTableDateAgoCell";
import ListingTableActionCell from "../../../styled/generic/ListingTableActionCell";

const RequirementsTableComponent = ({
  requirements,
  loading,
  actions,
  handleActionClick,
}) => {
  const columns = [
    "TITLE",
    "REQUIREMENT TYPE",
    "LEAD NAME",
    "CREATED DATE",
    "STATUS",
    "MATCHES",
    "ACTIONS",
  ];

  const history = useHistory();

  return (
    <Box sx={{ backgroundColor: "#FFFFFF", minHeight: "400px" }}>
      <TableContainer
        columns={columns}
        data={requirements}
        loading={loading}
      >
        {requirements?.map((item) => (
          <tr
            onClick={() => history.push(`/requirements/listings/${item?._id}`)}
          >
            <td>{item?.title || "Untitled"}</td>
            <td>{item?.requirementType}</td>
            <td>
              <ListingTableSingleUserCell
                label={item?.owner?.parent?.displayName}
                url={item?.owner?.parent?.displayPicture?.url}
              />
            </td>
            <td>
              <ListingTableDateAgoCell date={item?.createdAt} />
            </td>
            <td>
              <ListingTableNewItemCell date={item?.createdAt} />
            </td>
            <td>Matches</td>
            <td onClick={(e) => e.stopPropagation()}>
              <ListingTableActionCell
                cellData={item}
                cellActions={actions}
                cellActionListener={handleActionClick}
              />
            </td>
          </tr>
        ))}
      </TableContainer>
    </Box>
  );
};

export default RequirementsTableComponent;
