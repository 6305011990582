import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';
import NormalDialog from "../styled/CommonComponents/NormalDialog";
import { getFollowerAndFollowingsApiHit } from "./api.call";
import FollowerSingle from "./Follower.Single";
import FollowingSingle from "./Following.Single";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        width: "450px",
        height: "350px",
        overflowY: "auto",
        [theme.breakpoints.down("xs")]: {
            width: "290px",
        }
    },
    singleLine: {
        width: '100%',
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px"
    },
    picNameCont: {
        display: "flex",
        alignItems: "center",
    },
    detailsCont: {
        marginLeft: "10px"
    }
}));

const FollowingsFollowers = ({
    openFollowersFollowing, setOpenFollowersFollowing, title, curProfile,
    dataArr, followerIdsArr, followingIdsArr, getFollowerAndFollowings
}) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const { user } = auth;

    const [locFollowerIdsArr, setLocFollowerIdsArr] = useState([])
    const [locFollowingIdsArr, setLocFollowingIdsArr] = useState([])

    const handleClose = () => {
        setOpenFollowersFollowing(false)
    }

    const getFolloerAndFollowingIds = async () => {
        if (curProfile?._id == user?.profile) {
            setLocFollowerIdsArr(followerIdsArr)
            setLocFollowingIdsArr(followingIdsArr)
        } else {
            await getFollowerAndFollowingsApiHit({ profileId: user?.profile })
                .then((data) => {
                    let followerIdsLocArr = []
                    let followingIdsLocArr = []

                    data.map((follow) => {
                        if (user?.profile === follow?.profile?._id) {
                            followerIdsLocArr.push(follow?.userProfile?._id)
                        } else if (user?.profile === follow?.userProfile?._id) {
                            followingIdsLocArr.push(follow?.profile?._id)
                        }
                    })
                    setLocFollowerIdsArr(followerIdsLocArr)
                    setLocFollowingIdsArr(followingIdsLocArr)
                })
                .catch((err) => {
                    console.error(err);
                })
        }
    }

    useEffect(() => {
        getFolloerAndFollowingIds()
    }, [curProfile, followerIdsArr, followingIdsArr])

    return (
        <NormalDialog
            openDialog={openFollowersFollowing}
            handleCloseShare={handleClose}
            pageTitle={title}
            content={<div className={classes.mainContainer} >
                {title === "Following" && (<>
                    {dataArr && dataArr.length > 0 && dataArr.map((userData, i) => (
                        <FollowingSingle
                            userData={userData}
                            key={i}
                            curProfile={curProfile}
                            locFollowingIdsArr={locFollowingIdsArr}
                            getFollowerAndFollowings={getFollowerAndFollowings}
                            getFolloerAndFollowingIds={getFolloerAndFollowingIds}
                        />
                    ))}
                </>)}

                {title === "Followers" && (<>
                    {dataArr && dataArr.length > 0 && dataArr.map((userData, i) => (
                        <FollowerSingle
                            userData={userData}
                            key={i}
                            curProfile={curProfile}
                            locFollowingIdsArr={locFollowingIdsArr}
                            getFollowerAndFollowings={getFollowerAndFollowings}
                            getFolloerAndFollowingIds={getFolloerAndFollowingIds}
                        />
                    ))}
                </>)}
            </div>}
        />
    );
};
export default FollowingsFollowers;