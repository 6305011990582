import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import sarah from '../../../Assets/Ellipse 3.svg'
import David from '../../../Assets/Ellipse 6.svg'
import jane from '../../../Assets/Ellipse 5.svg'
import mark from '../../../Assets/Ellipse 2.svg'
import emily from '../../../Assets/Ellipse 4.svg'
import Card from "./card";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        marginTop: "30px",
        padding: "0px 140px 0px",
        [theme.breakpoints.down("lg")]: {
            padding: "0px 70px 0px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "0px 45px 0px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "0px 30px 0px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0px 15px 0px"
        },
    },
    mainCont: {
        height: "calc( 100vh - 120px )",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            height: "calc( 100vh - 200px )",
        }
    },
    titleSty: {
        fontSize: "35px",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            fontSize: "30px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "25px",
            fontWeight: "600"
        },
    },
    cardsCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        overflowX: "auto",
        padding: "15px 20px",
        "& .carousel-container": {
            width: "100%",
            height: "500px"
        },
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        [theme.breakpoints.down("sm")]: {
            "& .carousel-container": {
                width: "100%",
                height: "380px"
            },
        }
    }
}));

const reviews = [
    {
        name: "Sarah Lea",
        role: "Investor",
        comment: "As a first-time real estate investor, I was initially hesitant, but RealList exceeded my expectations. The diverse range of investment opportunities allowed me to diversify my portfolio easily. The team provided excellent guidance, and the investment process was seamless.",
        profilePicture: sarah,
    },
    {
        name: "David McAllister",
        role: "Investor",
        comment: "I've been investing in real estate for years, and this platform has become my go-to resource. RealList's robust property listings, along with detailed financial data, make it effortless to evaluate potential investments. The opportunities I've found through this platform have been top-notch, and the platform's reliability and efficiency are unmatched.",
        profilePicture: David,
    },
    {
        name: "Jane Rivers",
        role: "Investor",
        comment: "Partnering with this platform has been a game-changer for my real estate projects. Their extensive network of investors allows me to connect with individuals who are genuinely interested in investing in my projects. The platform's rigorous due diligence process gives investors confidence, making it easier to secure funding. Highly recommended! ",
        profilePicture: jane,
    },
    {
        name: "Mark Summer",
        role: "Investor",
        comment: "I've had a fantastic experience working with this platform. Their team understands the challenges faced by developers, and they provide excellent support throughout the fundraising process. The platform's user-friendly interface makes it simple for investors to discover and evaluate our projects. It's been a pleasure collaborating with them.",
        profilePicture: mark
    },
    {
        name: "Emily Howards",
        role: "Investor",
        comment: "This platform has become an invaluable resource for sourcing investors for my real estate developments. The platform's wide reach and targeted investor base have helped me secure funding for various projects. Their professionalism, prompt communication, and commitment to quality make them an ideal partner for developers like myself. I look forward to continued collaboration. ",
        profilePicture: emily
    },
];

const responsive = {
    desktop1: {
        breakpoint: { max: 3000, min: 2000 },
        items: 4
    },
    desktop2: {
        breakpoint: { max: 1999, min: 1500 },
        items: 3.2
    },
    tablet1: {
        breakpoint: { max: 1499, min: 1300 },
        items: 2.8
    },
    tablet2: {
        breakpoint: { max: 1299, min: 1100 },
        items: 2.1
    },
    mobile1: {
        breakpoint: { max: 1099, min: 800 },
        items: 2.1
    },
    mobile2: {
        breakpoint: { max: 799, min: 600 },
        items: 1.8
    },
    mobile3: {
        breakpoint: { max: 599, min: 400 },
        items: 1.6
    },
    mobile4: {
        breakpoint: { max: 399, min: 0 },
        items: 1.4
    },
};

export default function Review({ pageRef }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    return (
        <section ref={pageRef} className={classes.root} >
            <h3 className={classes.titleSty} data-aos="fade-down" >
                Investors achieve their goal in 3 easy steps
            </h3>

            <div className={classes.mainCont}  >
                <div className={classes.cardsCont} style={{ width: "100%" }} data-aos="fade-up" >
                    <Carousel
                        responsive={responsive}
                        infinite={true}
                        swipeable={false}
                        draggable={false}
                        keyBoardControl={true}
                        showDots={false}
                        autoPlaySpeed={1000}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        containerClass="carousel-container"
                    >
                        {reviews && reviews.map((reviewData, i) => (
                            <Card
                                text={reviewData?.comment}
                                name={reviewData?.name}
                                key={i}
                                role={reviewData?.role}
                                img={reviewData?.profilePicture}
                            />
                        ))}
                    </Carousel>
                </div>
            </div>
        </section>
    );
}