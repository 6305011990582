import { Box, Typography } from "@mui/material";
import React from "react";
import BoxSpaceBetween from "./BoxSpaceBetween";
import HorizBox from "./HorizBox";

const StandardAppContainerRoundedWithHeader = ({
  sx,
  fullHeight,
  children,
  title,
  icon,
  actions,
  headerTypographyVariant = "h6",
  headerTypographySx = {},
  onClick,
  padding = 2,
  headerComponent,
  ...props
}) => {
  return (
    <Box
      sx={{
        my: 2,
        backgroundColor: sx?.backgroundColor || "#FFFFFF",
        borderRadius: sx?.borderRadius || "10px",
        border: "2px solid #DCDCDC",
        boxShadow: sx?.boxShadow || "0px 5px 10px 0px rgba(0, 0, 0, 0.10)",
        minHeight: fullHeight ? "80vh" : "auto",
        overflow: "hidden",
        ...sx,
      }}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      {...props}
    >
      <BoxSpaceBetween
        sx={{
          backgroundColor: "#F5F7FA",
          borderBottom: "2px solid #DCDCDC",
          p: padding,
        }}
      >
        <HorizBox>
          {icon}
          {title && (
            <Typography
              variant={headerTypographyVariant}
              sx={headerTypographySx}
            >
              {title}
            </Typography>
          )}
          {headerComponent && <Box sx={{ p: 1 }}>{headerComponent}</Box>}
        </HorizBox>
        <Box> {actions}</Box>
      </BoxSpaceBetween>
      <Box
        sx={{
          p: padding,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default StandardAppContainerRoundedWithHeader;
