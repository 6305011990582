import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Button } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { format } from "timeago.js";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import html2plaintext from "html2plaintext";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import LessText from "../../styled/CommonComponents/LessText";

const useStyles = makeStyles((theme) => ({
  allCradCont: {
    width: "450px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  dataSingleCont: {
    width: "50%",
    padding: "5px 10px",
    marginBottom: "5px",
    [theme.breakpoints.down("xs")]: {
      padding: "3px 7px",
      "& h3": {
        fontSize: "15px",
      },
      "& p": {
        fontSize: "13px",
      },
    },
  },
  authorDataCont: {
    display: "flex",
    alignItems: "center",
    "& p": {
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      "& p": {
        marginLeft: "0px",
      },
    },
  },
  actionBtnCont: {
    width: "100%",
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& div": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

export default function PreviewDialog({
  data,
  openAddNew,
  setOpenAddNew,
  envelopeBool,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClose = () => {
    setOpenAddNew(false);
  };

  return (
    <NormalDialog
      openDialog={openAddNew}
      handleCloseShare={handleClose}
      pageTitle={<LessText limit={15} string={data?.title} />}
      content={
        <div className={classes.allCradCont}>
          <div className={classes.dataSingleCont}>
            <h3> Author</h3>
            <div className={classes.authorDataCont}>
              <Avatar
                alt={data?.user?.displayName}
                src={
                  data?.user?.displayPicture?.thumbUrl ||
                  data?.user?.displayPicture?.url
                }
                style={{ width: "30px", height: "30px" }}
              />
              <p>{data?.user?.displayName}</p>
            </div>
          </div>
          <div className={classes.dataSingleCont}>
            <h3> Participants</h3>
            <AvatarGroup max={4}>
              {data?.shared &&
                data?.shared.map((userData, i) => (
                  <Avatar
                    key={i}
                    alt={userData?.parent?.displayName}
                    src={
                      userData?.parent?.displayPicture?.thumbUrl ||
                      userData?.parent?.displayPicture?.url
                    }
                    style={{ width: "30px", height: "30px" }}
                  />
                ))}
            </AvatarGroup>
          </div>
          <div className={classes.dataSingleCont}>
            <h3>{isMobile ? "File(s)" : "Number of Files"}</h3>
            <p>{data?.files?.length || 0}</p>
          </div>
          <div className={classes.dataSingleCont}>
            <h3>{isMobile ? "Version(s)" : "Number of Versions"}</h3>
            <p>{data?.allVersions?.length || 1}</p>
          </div>
          <div className={classes.dataSingleCont}>
            <h3> Likes</h3>
            <p>{data?.likeCount}</p>
          </div>
          <div className={classes.dataSingleCont}>
            <h3> Comments</h3>
            <p>{data?.commentCount}</p>
          </div>
          {envelopeBool && (
            <div className={classes.dataSingleCont}>
              <h3> Status</h3>
              <p>{html2plaintext(data?.status)}</p>
            </div>
          )}
          <div className={classes.dataSingleCont}>
            <h3> Last Modified</h3>
            <p>{format(data?.updatedAt)}</p>
          </div>
          <div className={classes.actionBtnCont}>
            <div></div>
            <div>
              <Button
                variant="contained"
                color="primary"
                startIcon={<VisibilityIcon />}
                size="small"
                style={{ marginRight: "15px" }}
                onClick={() => {
                  dispatch({
                    type: "AddDoc",
                    payload: {
                      tempDoc: data,
                    },
                  });
                  var path = "/doc/view/" + data?._id;
                  history.push(path);
                }}
              >
                View
              </Button>
              {data?.docType !== "ESIGN_ENVELOPE" && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  size="small"
                  onClick={() => {
                    dispatch({
                      type: "AddDoc",
                      payload: {
                        tempDoc: data,
                      },
                    });
                    let path = "/";
                    if (data?.docType === "PUBLICDOC") {
                      path = "/publicdoc/edit/" + data?._id;
                    } else {
                      path = "/doc/edit/" + data?._id;
                    }
                    history.push(path);
                  }}
                >
                  Edit
                </Button>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
}
