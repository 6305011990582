import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { countriesStateMap } from "../countryState"
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    statesNameCont: {
        maxHeight: "140px",
        overflowY: "auto",
        paddingTop: "10px",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        "& div": {
            display: "flex",
            height: "25px",
            position: "relative",
            alignItems: "center",
            marginRight: "8px",
            marginBottom: "7px",
            fontSize: "16px",
            fontWeight: "400",
            borderRadius: "4px",
            padding: "0px 6px",
            backgroundColor: "#E9F0F8",
            "& .iconCont": {
                display: "none"
            },
            "&:hover .iconCont": {
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                top: "0px",
                left: "0px",
                paddingTop: "1px",
                backgroundColor: "rgba(255, 255, 255, 0.4)"
            }
        }
    },
    removeLocation: {
        fontSize: "24px",
        cursor: "pointer",
    },
}));

function LocationCountryState(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { locationTags, setLocationTags } = props;

    const [countries, setCountries] = useState([])
    const [countryObj, setCountryObj] = useState(null)
    const [countryText, setCountryText] = useState("")
    const [states, setStates] = useState([])
    const [stateObj, setStateObj] = useState(null)
    const [stateText, setStateText] = useState("")

    useEffect(() => {
        let allCountries = Object.keys(countriesStateMap)
        setCountries(allCountries)
    }, [])

    const onCountrySelect = (value) => {
        if (value) {
            let arr = [...locationTags, value]
            var stateSet = new Set(arr)
            setLocationTags([...stateSet])
            let allStates = countriesStateMap?.[value]
            setStates(allStates)
            setCountryObj(value)
        }
    }

    const onStateSelect = (val) => {
        let str = val + "_" + countryObj
        let arr = [...locationTags, str]
        var stateSet = new Set(arr)
        setLocationTags([...stateSet])
        setStateObj(null)
        setStateText("")
    }

    const onStateRemove = (val) => {
        let arr = locationTags.filter((tag) => tag !== val)
        setLocationTags(arr)
    }

    return (
        <>
            <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={countryObj}
                inputValue={countryText}
                options={countries}
                getOptionLabel={(option) => { return option }}
                onInputChange={(event, newValue) => {
                    setCountryText(newValue);
                }}
                getOptionSelected={(option) => {
                    return option == countryObj
                }}
                renderInput={(params) => (
                    <TextField {...params} style={{ margin: "0px" }} margin="normal" variant="outlined" placeholder={"Enter Country Name"} />
                )}
                onChange={(event, value) => {
                    onCountrySelect(value)
                }}
                style={{ width: "100%" }}
                size="small"
            />
            <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={stateObj}
                inputValue={stateText}
                options={states}
                getOptionLabel={(option) => { return option }}
                onInputChange={(event, newValue) => {
                    setStateText(newValue);
                }}
                getOptionSelected={(option) => {
                    return option == stateObj
                }}
                renderInput={(params) => (
                    <TextField {...params} style={{ margin: "0px" }} margin="normal" variant="outlined" placeholder={"Enter State Name"} />
                )}
                onChange={(event, value) => {
                    onStateSelect(value)
                }}
                style={{ width: "100%", marginTop: "10px" }}
                size="small"
            />
            <div className={classes.statesNameCont} >
                {locationTags && locationTags.map((loc, i) => (
                    <div key={i} >
                        {loc.split("_")[0]}
                        <div className="iconCont" >
                            <CloseIcon className={classes.removeLocation} onClick={() => { onStateRemove(loc) }} />
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default LocationCountryState;