import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { createLibrary, getOfferingsByCategories } from './Api.call';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        "& table": {
            tableLayout: "auto"
        },
        "& .MuiTableCell-sizeSmall": {
            padding: "0px 10px"
        }
    },
    tableContainer: {
        width: "100%",
        height: "calc(100% - 5px)",
        "& tr": {
            height: "45px"
        },
        "&::-webkit-scrollbar": {
            display: "none"
        },
        "& table": {
            tableLayout: "auto"
        }
    }
}));

export default function OfferingsDrawer({
    openOfferingDrawer, setOpenOfferingDrawer,
    selectedCategory, allCategories, profileId
}) {
    const classes = useStyles();
    const theme = useTheme();
    const { user } = useSelector((state) => state.auth);

    const [loader, setLoader] = useState(false)
    const [offerings, setOfferings] = useState([])

    useEffect(() => {
        if (openOfferingDrawer && selectedCategory?._id) {
            setLoader(true)
            let arrIds = [selectedCategory?._id]
            allCategories.map((category) => {
                const parents = category?.ancestors || []
                if (parents.includes(selectedCategory?._id)) {
                    arrIds.push(category?._id)
                }
            })
            const arrSet = new Set([...arrIds])

            getOfferingsByCategories({
                categoryIds: [...arrSet],
                profileId: profileId
            })
                .then((data) => {
                    setOfferings(data)
                    setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [openOfferingDrawer, selectedCategory?._id])

    return (
        <NormalDrawer
            openDrawer={openOfferingDrawer}
            setOpenDrawer={setOpenOfferingDrawer}
            anchor={"right"}
            title={"Products/Services"}
            width={"50vw"}
            content={
                <div className={classes.mainCont} >
                    <div style={{ width: "100%", height: "5px" }} >
                        {loader && (<LinearProgress />)}
                    </div>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="sticky table" size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" style={{ minWidth: "230px" }} >
                                        Name
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "120px" }} >
                                        Code
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "170px" }} >
                                        Price
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "70px" }} >
                                        Unit
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "220px" }} >
                                        Income Chart Account
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "100px" }} >
                                        Tax
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "170px" }} >
                                        Purchase price
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "70px" }} >
                                        Unit
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "220px" }} >
                                        Expense Chart Account
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "80px" }} >
                                        Type
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "150px" }} >
                                        Category
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "150px" }} >
                                        Add By
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {offerings && offerings.length > 0 && offerings.map((data) => {
                                    return (
                                        <TableRow>
                                            <TableCell align="left" >
                                                {data?.name}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {data?.code}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {`₹${data?.price}`}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {data?.unit}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {data?.incomeChartAccount?.name}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {data?.taxType === "%" ? `${data?.tax}%` : `₹${data?.tax}`}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {`₹${data?.purchasePrice}`}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {data?.purchaseUnit}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {data?.expenseChartAccount?.name}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {data?.model}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {data?.category?.name}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {data?.isPublic ? "RealList" : data?.user?.displayName}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>}
        />
    );
}