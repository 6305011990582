import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import { Button, IconButton, Checkbox, useMediaQuery } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from "@material-ui/core/TextField";
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import { degreesArray, fieldOfStudyArray } from "../../styled/CommonComponents/degreeArray"
import { updateUserInfo } from '../api';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    header: {
        height: "60px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #e4e7e7",
        padding: "0px 25px",
        "& h3": {
            fontSize: "17px",
            fontWeight: "450",
            color: "#170f49"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0px 10px",
        }
    },
    body: {
        width: "100%",
        height: "calc(100% - 65px)",
        overflowY: "auto",
        padding: "20px 40px 40px",
        paddingRight: "0px",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        [theme.breakpoints.down("md")]: {
            padding: "15px 10px 30px",
        }
    },
    singleEducationCont: {
        width: "700px",
        marginBottom: "20px",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    textLineCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column"
        }
    },
    halfCont: {
        width: "47%",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }
    },
    checkedCont: {
        display: "flex",
        alignItems: "center",
        marginTop: "10px",
        marginBottom: "-10px",
        "& h3": {
            fontSize: "15px",
            marginRight: "15px",
            fontWeight: "500",
        }
    },
    label: {
        fontSize: "15px",
        fontWeight: "500",
        marginTop: "15px",
        marginBottom: "2px"
    }
}));

const calendarMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export default function EducationEdit({ setOpenEditView }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const [calendarYear, setCalendarYear] = useState([]);
    const [stateChange, setStateChange] = useState(false)
    const [educationObjArr, setEducationObjArr] = useState([])
    const [loading, setLoading] = useState(false)

    const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

    useEffect(() => {
        let localEducation = user?.education || []
        if (localEducation && localEducation.length > 0) {
            let arr = []
            localEducation.map((education) => {
                let loc_startMonth
                let loc_startYear
                let loc_endMonth
                let loc_endYear

                let locStartDate = education?.start_date || ""
                if (locStartDate && locStartDate.length > 3) {
                    loc_startMonth = locStartDate.split("-")[0]
                    loc_startYear = locStartDate.split("-")[1]
                } else {
                    loc_startMonth = ""
                    loc_startYear = ""
                }

                let locEndDate = education?.end_date || ""
                if (locEndDate && locEndDate.length > 3) {
                    loc_endMonth = locEndDate.split("-")[0]
                    loc_endYear = locEndDate.split("-")[1]
                } else {
                    loc_endMonth = ""
                    loc_endYear = ""
                }

                arr.push({
                    school: education?.school || "",
                    degree: education?.degree || "",
                    field_of_study: education?.field || "",
                    grade: education?.grade,
                    startMonth: loc_startMonth,
                    startYear: loc_startYear,
                    isCurrentlyStudying: education?.isCurrentlyStudying || false,
                    endMonth: loc_endMonth,
                    endYear: loc_endYear,
                    description: education?.description || ""
                })
            })

            setEducationObjArr(arr)
        } else {
            setEducationObjArr([{
                school: "",
                degree: null,
                field_of_study: null,
                grade: "",
                startMonth: "",
                startYear: "",
                isCurrentlyStudying: false,
                endMonth: "",
                endYear: "",
                description: ""
            }])
        }
    }, [user, user?.education])

    useEffect(() => {
        const years = [];
        const date = new Date();
        const endYear = date.getFullYear();
        for (let i = 1951; i <= endYear; i++) {
            years.push(i + "");
        }
        setCalendarYear(years);
    }, []);

    const addNewEducation = () => {
        setEducationObjArr([
            ...educationObjArr,
            {
                school: "",
                degree: null,
                field_of_study: null,
                grade: "",
                startMonth: "",
                startYear: "",
                isCurrentlyStudying: false,
                endMonth: "",
                endYear: "",
                description: ""
            }])
        setStateChange(!stateChange)
    }

    const onChangeValue = (value, i, key) => {
        let arr = [...educationObjArr]
        arr[i][key] = value
        setEducationObjArr(arr)
        setStateChange(!stateChange)
    }

    const deleteLine = (i) => {
        let arr = [...educationObjArr]
        arr.splice(i, 1)
        setEducationObjArr(arr)
        setStateChange(!stateChange)
    }

    const updateProfileSection = async () => {
        setLoading(true)
        let objArr = []
        educationObjArr.map((educationData) => {
            objArr.push({
                school: educationData?.school,
                degree: educationData?.degree,
                field: educationData?.field_of_study,
                start_date: `${educationData?.startMonth}-${educationData?.startYear}`,
                isCurrentlyStudying: educationData?.isCurrentlyStudying,
                end_date: `${educationData?.endMonth}-${educationData?.endYear}`,
                grade: educationData?.grade,
                description: educationData?.description,
            })
        })

        const userObj = {
            education: objArr,
        };

        await updateUserInfo({
            userId: user?._id,
            userInfo: userObj,
        })
            .then((data) => {
                dispatch({
                    type: "AddAuth",
                    payload: {
                        user: {
                            ...user,
                            ...userObj,
                        },
                    },
                });
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            })
    }


    return (
        <>
            <div className={classes.header} >
                <h3>{isSmall ? "Education" : "Edit Education"}</h3>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={addNewEducation}
                        size={isSmall ? "small" : "medium"}
                    >
                        Add
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        style={{ marginLeft: "10px" }}
                        onClick={updateProfileSection}
                        size={isSmall ? "small" : "medium"}
                    >
                        Save
                    </Button>
                    <IconButton
                        style={{ marginLeft: "10px" }}
                        onClick={() => { setOpenEditView(false) }}
                        size={isSmall ? "small" : "medium"}
                    >
                        <ClearIcon />
                    </IconButton>
                </div>
            </div>
            <div style={{ height: "5px" }} >
                {loading && (<LinearProgress />)}
            </div>
            <div className={classes.body} >
                {educationObjArr && educationObjArr.length > 0 && educationObjArr.map((educationObj, i) => (
                    <div key={i} className={classes.singleEducationCont} >
                        <div className={classes.textLineCont} >
                            <div className={classes.halfCont} >
                                <p className={classes.label} >School</p>
                                <TextField
                                    required
                                    id="school"
                                    placeholder="Ex: Stanford University"
                                    value={educationObj?.school}
                                    onChange={(e) => {
                                        onChangeValue(e.target.value, i, "school")
                                    }}
                                    size="small"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div className={classes.halfCont} >
                                <p className={classes.label} >Degree</p>
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={educationObj?.degree}
                                    options={degreesArray}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option === educationObj?.degree
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Ex: Bachelor's"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "degree");
                                    }}
                                    style={{ width: "100%" }}
                                    size="small"
                                />
                            </div>
                        </div>
                        <div className={classes.textLineCont} >
                            <div className={classes.halfCont} >
                                <p className={classes.label} >Field of Study</p>
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={educationObj?.field_of_study}
                                    options={fieldOfStudyArray}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option === educationObj?.field_of_study
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="Ex: Business"
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "field_of_study");
                                    }}
                                    style={{ width: "100%" }}
                                    size="small"
                                />
                            </div>
                            <div className={classes.halfCont} >
                                <p className={classes.label} >Grade</p>
                                <TextField
                                    id="grade"
                                    placeholder="Ex: CGPA/Percentage"
                                    value={educationObj?.grade}
                                    onChange={(e) => {
                                        onChangeValue(e.target.value, i, "grade");
                                    }}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <p className={classes.label} >Start date</p>
                        <div className={classes.textLineCont} >
                            <div className={classes.halfCont} >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={educationObj?.startMonth}
                                    options={calendarMonth}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option === educationObj?.startMonth
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Enter Start Month"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "startMonth")
                                    }}
                                    style={{ width: "100%", marginBottom: "5px" }}
                                    size="small"
                                />
                            </div>
                            <div className={classes.halfCont} >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={educationObj?.startYear}
                                    options={calendarYear}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option === educationObj?.startYear
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Enter Start Year"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "startYear")
                                    }}
                                    style={{ width: "100%", marginBottom: "5px" }}
                                    size="small"
                                />
                            </div>
                        </div>
                        <div className={classes.checkedCont} >
                            <h3>Currently Studing</h3>
                            <Checkbox
                                color="primary"
                                checked={educationObj?.isCurrentlyStudying}
                                onChange={(e) => { onChangeValue(e.target.checked, i, "isCurrentlyStudying") }}
                            />
                        </div>
                        {educationObj?.isCurrentlyStudying ? null : (<>
                            <p className={classes.label} >End date</p>
                            <div className={classes.textLineCont} >
                                <div className={classes.halfCont} >
                                    <Autocomplete
                                        id="free-solo-demo"
                                        freeSolo
                                        value={educationObj?.endMonth}
                                        options={calendarMonth}
                                        getOptionLabel={(option) => { return option }}
                                        getOptionSelected={(option) => {
                                            return option === educationObj?.endMonth
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                style={{ margin: "0px" }}
                                                margin="normal"
                                                variant="outlined"
                                                placeholder={"Enter End Month"}
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            onChangeValue(value, i, "endMonth")
                                        }}
                                        style={{ width: "100%", marginBottom: "5px" }}
                                        size="small"
                                    />
                                </div>
                                <div className={classes.halfCont} >
                                    <Autocomplete
                                        id="free-solo-demo"
                                        freeSolo
                                        value={educationObj?.endYear}
                                        options={calendarYear}
                                        getOptionLabel={(option) => { return option }}
                                        getOptionSelected={(option) => {
                                            return option === educationObj?.endYear
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                style={{ margin: "0px" }}
                                                margin="normal"
                                                variant="outlined"
                                                placeholder={"Enter End Year"}
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            onChangeValue(value, i, "endYear")
                                        }}
                                        style={{ width: "100%", marginBottom: "5px" }}
                                        size="small"
                                    />
                                </div>
                            </div>
                        </>)}
                        <p className={classes.label} >Description</p>
                        <TextField
                            id="description"
                            placeholder="Write anything notable..."
                            multiline
                            rows={3}
                            variant="outlined"
                            size="small"
                            value={educationObj?.description}
                            onChange={(e) => {
                                onChangeValue(e.target.value, i, "description");
                            }}
                            fullWidth
                        />
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "15px 0px 30px" }} >
                            <div></div>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<DeleteIcon />}
                                onClick={() => { deleteLine(i) }}
                                size={isSmall ? "small" : "medium"}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}