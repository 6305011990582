import React, { useMemo } from "react";
import LikeNotifications from "./notificationComponents/LikeNotifications";
import IssueNotifications from "./notificationComponents/IssueNotifications";
import TimesheetNotification from "./notificationComponents/TimesheetNotification";
import useTimesheetDrawer from "../useTimesheetDrawer";

export default function useClickableSelectNotificationComponent({
  notification,
  isActivity = false,
}) {
  const { handleTimesheetOpen, timesheetDrawer } = useTimesheetDrawer();

  const NotificationComponent = useMemo(() => {
    const activity = notification?.activity;
    switch (activity?.dataModel) {
      case "Like":
        return (
          <LikeNotifications notification={activity} isActivity={isActivity} />
        );
        break;
      case "Issue":
        return (
          <IssueNotifications notification={activity} isActivity={isActivity} />
        );
        break;
      case "TimeSheetItem":
        return (
          <TimesheetNotification
            notification={activity}
            isActivity={isActivity}
            handleTimesheetOpen={handleTimesheetOpen}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
  }, [notification]); //Dependencies: notification, isActivity

  return { NotificationComponent, timesheetDrawer };
}
