import { PersonAddOutlined } from "@material-ui/icons";
import { Avatar, Box, Button } from "@mui/material";
import React from "react";

const AvatarGroupButton = ({ imgArr, label, onClick }) => {

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <Box
        display="flex"
        alignItems="center"
        sx={{
          border: "1px solid #48A8F5",
          borderStyle: "dashed",
          mr: 2,
        }}
      >
        <Box display="flex" sx={{ mr: 1 }}>
          {imgArr?.map((item) => (
            <Avatar
              alt={item?.parent?.displayName}
              src={item?.parent?.displayPicture?.thumbUrl ? item?.parent?.displayPicture?.thumbUrl : item?.parent?.displayPicture?.url}
              sx={{
                width: 30,
                height: 30,
                marginRight: "-5px",
                border: "3px solid #ffffff",
              }}
            />
          ))}
        </Box>
        <Button
          variant="text"
          startIcon={<PersonAddOutlined />}
          color="primary"
          sx={{ mr: 1, textTransform: "none" }}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          disableElevation
          onClick={() => onClick()}
        >
          {label}
        </Button>
      </Box>
    </Box>
  );
};

export default AvatarGroupButton;
