import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SvgIcon from '@material-ui/core/SvgIcon';
import TreeView from '@material-ui/lab/TreeView';
import SelectTreeBody from "./SelectTreeBody";

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 12, height: 12 }} {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 12, height: 12 }} {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props) {
    return (
        <SvgIcon className="close" fontSize="inherit" style={{ width: 12, height: 12 }} {...props}>
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

const useStyles = makeStyles((theme) => ({
    treeCont: {
        width: "100%",
        "& .MuiIconButton-root": {
            padding: "2px",
            marginRight: "5px",
        },
        "& .MuiTreeItem-content": {
            marginBottom: "3px"
        }
    }
}));

const SelectTree = ({
    options, selectedOptionIds, setSelectedOptionIds
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const [accessOptionsParentChildMap, setAccessOptionsParentChildMap] = useState({});

    function buildParentChildMap(input, parentMap = {}) {
        input.forEach(item => {
            const parentId = item._id;
            const children = item.children || [];

            if (children.length > 0) {
                parentMap[parentId] = children.map(child => child._id);
                buildParentChildMap(children, parentMap);
            } else {
                parentMap[parentId] = [];
            }

            children.forEach(child => {
                if (child.children && child.children.length > 0) {
                    parentMap[parentId] = parentMap[parentId].concat(buildChildList(child));
                }
            });
        });

        return parentMap;
    }

    function buildChildList(item) {
        const childrenList = [item._id];
        if (item.children) {
            item.children.forEach(child => {
                childrenList.push(...buildChildList(child));
            });
        }
        return childrenList;
    }

    useEffect(() => {
        const parentChildrenMap = buildParentChildMap(options);
        setAccessOptionsParentChildMap(parentChildrenMap)
    }, [options])

    const updateSelectedIdsForSelect = (selectedIds) => {
        let locSelectedIds = [...selectedIds];
        let parents = Object.keys(accessOptionsParentChildMap) || [];
        for (var i = 0; i < parents.length; i++) {
            let children = accessOptionsParentChildMap[parents[i]];
            if (!selectedIds.includes(parents[i]) && children.length > 0) {
                for (var j = 0; j < children.length; j++) {
                    if (selectedIds.includes(children[j])) {
                        locSelectedIds.push(parents[i]);
                        break;
                    }
                }
            }
            if (locSelectedIds.length !== selectedIds.length) {
                break;
            }
        }
        if (locSelectedIds.length > selectedIds.length) {
            locSelectedIds = updateSelectedIdsForSelect(locSelectedIds)
        }
        return locSelectedIds;
    }

    const updateSelectedIdsForUnSelect = (selectedIds) => {
        let locSelectedIds = [...selectedIds];
        let parents = Object.keys(accessOptionsParentChildMap) || [];
        for (var i = 0; i < parents.length; i++) {
            let children = accessOptionsParentChildMap[parents[i]];
            if (selectedIds.includes(parents[i]) && children.length > 0) {
                let count = 0;
                for (var j = 0; j < children.length; j++) {
                    if (!selectedIds.includes(children[j])) {
                        count = count + 1;
                    }
                }
                if (count === children.length) {
                    const index = locSelectedIds.indexOf(parents[i]);
                    locSelectedIds.splice(index, 1);
                    break;
                }
            }
        }
        if (locSelectedIds.length < selectedIds.length) {
            locSelectedIds = updateSelectedIdsForUnSelect(locSelectedIds)
        }
        return locSelectedIds;
    }

    const handleCheck = (_id) => {
        let selectedIds = [...selectedOptionIds]
        const index = selectedIds.indexOf(_id);
        if (index !== -1) {
            let allUnselectedIds = [];
            if (accessOptionsParentChildMap[_id] && accessOptionsParentChildMap[_id].length > 0) {
                allUnselectedIds = accessOptionsParentChildMap[_id]
            }
            allUnselectedIds.push(_id)
            const result = selectedIds.filter(id1 => !allUnselectedIds.includes(id1))
            setSelectedOptionIds(result)
            const ids =  updateSelectedIdsForUnSelect(result);
            let newSet = new Set([...ids])
            setSelectedOptionIds([...newSet])
        } else {
            selectedIds.push(_id);
            let allChildren = [];
            if (accessOptionsParentChildMap[_id] && accessOptionsParentChildMap[_id].length > 0) {
                allChildren = accessOptionsParentChildMap[_id]
            }
            selectedIds = [...selectedIds, ...allChildren]
            setSelectedOptionIds(selectedIds)
            const ids = updateSelectedIdsForSelect(selectedIds)
            let newSet = new Set([...ids])
            setSelectedOptionIds([...newSet])
        }

    }

    return (
        <TreeView
            className={classes.treeCont}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={<CloseSquare />}
        >
            {options && options.map((item, i) => (
                <SelectTreeBody
                    item={item}
                    key={item?._id}
                    selectedOptionIds={selectedOptionIds}
                    handleCheck={handleCheck}
                />
            ))}
        </TreeView>
    );
};

export default SelectTree;