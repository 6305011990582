import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CreateChartAccountForTax from './CreateChartAccountForTax';
import CreateChartAccountForDiscount from './CreateChartAccountForDiscoun';
import { getIncomeChartAccounts } from '../../../productsAndServices/utils';
import { createDiscountOrTaxAndRelation, updateDiscountTaxRelation } from './api';
import LinearProgress from "@material-ui/core/LinearProgress";
import InputAdornment from '@material-ui/core/InputAdornment';
import NormalDrawer from '../../../../styled/CommonComponents/NormalDrawer';
import * as yup from "yup";

const taxDiscountValidationSchema = yup.object().shape({
    Name: yup.string().min(2).max(250).required("Name is required"),
    Category: yup.object().required("Category is required")
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflowY: "auto",
        padding: "20px 15px",
        overflowX: "hidden",
    },
    categoryAndValueCont: {
        width: "100%",
        marginTop: "20px",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    halfWidth: {
        width: "48%"
    },
    amountCont: {
        width: "100%",
        display: "flex",
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: "10px"
        },
    },
    btnCont: {
        height: "50px",
        alignItems: 'center',
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "15px"
    },
    taxDiscountCont: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: "10px"
        },
    },
    rateText: {
        width: "calc(100% - 100px)",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },
    validationSty: {
        fontSize: "12px",
        color: "red",
        fontWeight: "400",
        marginTop: "5px"
    },
}));

export default function TaxDiscountCreateDrawer({
    open, setOpen, onSelect, createType, walletId,
    libraryId, curEditDeleteTax, profileId
}) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const { auth } = useSelector((state) => state);
    const { user } = auth;

    const [openChartAcc, setOpenChartAcc] = useState(false)
    const [chartAccounts, setChartAccounts] = useState([]);
    const [loader, setLoader] = useState(false)
    const [validationObj, setValidationObj] = useState({})

    //for values
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [amountType, setAmountType] = useState("%")
    const [selectedChartAccount, setSelectedChartAccount] = useState({});
    const [amount, setAmount] = useState(0)

    useEffect(() => {
        if (open && curEditDeleteTax?._id) {
            setName(curEditDeleteTax?.name)
            setDescription(curEditDeleteTax?.description)
            setAmountType(curEditDeleteTax?.type)
            setSelectedChartAccount(curEditDeleteTax?.chartAccount)
            if (curEditDeleteTax?.type === "%") {
                setAmount(curEditDeleteTax?.percent)
            } else {
                setAmount(curEditDeleteTax?.amount)
            }
        }
    }, [open, curEditDeleteTax])

    const reloadChartAccount = async (isAfterNew) => {
        let type
        if (createType == 'Discount') {
            type = { qbName: "Discounts/Refunds Given", wallet: walletId }
        } else if (createType == 'Tax') {
            type = { classification: "Other Current Liabilities", wallet: walletId }
        }

        await getIncomeChartAccounts({ type })
            .then((accounts) => {
                const addObject = {
                    _id: 'New',
                    name: "+ Add New",
                    numDays: "0"
                };
                const newFiltered = [addObject, ...accounts]
                const len = accounts?.length
                setChartAccounts(newFiltered)
                let selectedAccount = null;
                if (isAfterNew) {
                    selectedAccount = accounts[len - 1];
                } else {
                    selectedAccount = accounts[0];
                }
                setSelectedChartAccount(selectedAccount)
            })
    }

    useEffect(() => {
        if (open) {
            reloadChartAccount(false)
        }
    }, [walletId, open])

    const chartAccountCreateDialog = (value) => {
        setOpenChartAcc(false)
        reloadChartAccount(true)
    }

    const saveAndClose = async (e) => {
        e.preventDefault();
        const formData = {
            Name: name,
            Category: selectedChartAccount
        }
        await taxDiscountValidationSchema.validate(formData, { abortEarly: false })
            .then(async (data) => {
                setLoader(true)
                let discountOrTaxRelation = {
                    chartAccount: selectedChartAccount?._id,
                    name: name,
                    description: description,
                    type: amountType,
                }
                let discountOrTax = {
                    name: name,
                    description: description,
                    type: amountType,
                }
                if (amountType === "%") {
                    discountOrTax.percent = amount;
                    discountOrTaxRelation.percent = amount;
                    discountOrTax.amount = 0;
                    discountOrTaxRelation.amount = 0;
                } else {
                    discountOrTax.amount = amount;
                    discountOrTaxRelation.amount = amount;
                    discountOrTax.percent = 0;
                    discountOrTaxRelation.percent = 0;
                }
                if (curEditDeleteTax?._id) {
                    //edit
                    discountOrTax._id = curEditDeleteTax?._id
                    if (curEditDeleteTax?.discountOrTaxRelation?._id) {
                        discountOrTaxRelation._id = curEditDeleteTax?.discountOrTaxRelation?._id
                    }
                    await updateDiscountTaxRelation({ discountOrTax, discountOrTaxRelation })
                        .then((savedData) => {
                            onSelect(savedData);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    //add
                    discountOrTaxRelation.model = createType;
                    discountOrTaxRelation.codeLibrary = libraryId;
                    discountOrTaxRelation.wallet = walletId;
                    discountOrTaxRelation.addedBy = user?.profile;
                    discountOrTaxRelation.user = user._id
                    discountOrTax.model = createType;
                    discountOrTax.originProfile = profileId;
                    discountOrTax.codeLibrary = libraryId;
                    discountOrTax.wallet = walletId;
                    discountOrTax.addedBy = user?.profile;
                    discountOrTax.user = user._id
                    await createDiscountOrTaxAndRelation({ discountOrTax, discountOrTaxRelation })
                        .then((savedData) => {
                            let orgData = savedData?.savedRelation
                            orgData.chartAccount = selectedChartAccount
                            orgData.discountOrTax = savedData?.savedDiscountOrTax
                            onSelect(orgData);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                setLoader(false)
                setOpen(false)
            })
            .catch((err) => {
                console.log(err)
                let errorArr = err?.inner
                let obj = {}
                errorArr.map((err) => {
                    obj[err?.path] = err?.message
                })
                setValidationObj(obj)
            })
    };

    return (
        <NormalDrawer
            openDrawer={open}
            setOpenDrawer={setOpen}
            anchor={"right"}
            width={"40vw"}
            title={`Add New ${createType}`}
            content={
                <form className={classes.root} onSubmit={saveAndClose} >
                    <div style={{ width: "100%", height: "5px" }} >
                        {loader && (<LinearProgress />)}
                    </div>
                    <div className={classes.bodyCont} >
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            label={<>Name<span style={{ color: "red" }}>*</span></>}
                            placeholder="Enter Name"
                            autoComplete='off'
                            value={name}
                            fullWidth
                            onChange={(e) => { setName(e.target.value); }}
                        />
                        <p className={classes.validationSty} >{validationObj?.Name}</p>
                        <div className={classes.categoryAndValueCont} >
                            <div className={classes.halfWidth} >
                                <Autocomplete
                                    id="free-solo-demo"
                                    value={selectedChartAccount}
                                    options={chartAccounts}
                                    disableClearable
                                    getOptionLabel={(option) => { return option?.name || " " }}
                                    getOptionSelected={(option) => {
                                        return option?._id === selectedChartAccount?._id
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            label={<>Chart Account<span style={{ color: "red" }}>*</span></>}
                                            placeholder={"Select chart account"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        if (value?._id === "New") {
                                            setOpenChartAcc(true);
                                        } else {
                                            setSelectedChartAccount(value);
                                        }
                                    }}
                                    fullWidth
                                    size="small"
                                />
                                <p className={classes.validationSty} >{validationObj?.Category}</p>
                                {createType === "Discount" ? (
                                    <CreateChartAccountForDiscount
                                        walletId={walletId}
                                        openDialog={openChartAcc}
                                        setOpenChart={chartAccountCreateDialog}
                                    />
                                ) : (
                                    <CreateChartAccountForTax
                                        walletId={walletId}
                                        openDialog={openChartAcc}
                                        setOpenChart={chartAccountCreateDialog}
                                    />
                                )}
                            </div>
                            <div className={classes.halfWidth} >
                                <div className={classes.amountCont} >
                                    <div className={classes.taxDiscountCont} >
                                        <Button
                                            variant={amountType === "%" ? "contained" : "outlined"}
                                            size="small"
                                            color="primary"
                                            onClick={() => { setAmountType("%") }}
                                            style={{ minWidth: "40px", height: "100%", marginRight: "5px" }}
                                        >
                                            %
                                        </Button>
                                        <Button
                                            variant={amountType === "$" ? "contained" : "outlined"}
                                            size="small"
                                            color="primary"
                                            onClick={() => { setAmountType("$") }}
                                            style={{ minWidth: "40px", height: "100%" }}
                                        >
                                            ₹
                                        </Button>
                                    </div>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        size="small"
                                        label={"Rate"}
                                        className={classes.rateText}
                                        placeholder="Enter Rate"
                                        autoComplete='off'
                                        type="number"
                                        value={amount}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {amountType === "%" && <>%</>}
                                                    {amountType === "$" && <>₹</>}
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={(e) => {
                                            setAmount(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <TextField
                            id="outlined-multiline-static"
                            label="Product Description"
                            multiline
                            style={{ marginTop: "20px" }}
                            rows={4}
                            variant="outlined"
                            value={description}
                            onChange={(e) => { setDescription(e.target.value) }}
                            fullWidth
                        />
                    </div>
                    <div className={classes.btnCont}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            type="submit"
                        >
                            Save and Close
                        </Button>
                    </div>
                </form>
            }
        />
    );
}
