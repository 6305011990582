import { makeStyles } from "@material-ui/core";
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import MyPopOver from "../../../styled/CommonComponents/MyPopOver";
import TableContainer from "../../../styled/generic/TableContainer";
import MoreButton from "../../../styled/generic/MoreButton";
import ListingTableDateAgoCell from "../../../styled/generic/ListingTableDateAgoCell";
import ListingTableActionCell from "../../../styled/generic/ListingTableActionCell";
import ListingTableNewItemCell from "../../../styled/generic/ListingTableNewItemCell";

const LeadsTableComponent = ({
  leads,
  actions,
  handleActionClick,
  loading,
}) => {
    
  const columns = [
    "NAME",
    "LISTINGS",
    "LISTING TYPE",
    "LEAD SOURCE",
    "CREATED DATE",
    "STATUS",
    "ACTIONS",
  ];

  return (
    <Box sx={{ backgroundColor: "#FFFFFF", minHeight: "400px" }}>
      <TableContainer
        columns={columns}
        data={leads}
        loading={loading}
      >
        {leads?.map((item, index) => (
          <tr>
            <td>{item?.title || "Untitled"}</td>
            <td>{item?.listing?.title || "Untitled"}</td>
            <td>{item?.listing?.type}</td>
            <td>{item?.source}</td>
            <td>
              <ListingTableDateAgoCell date={item?.createdAt} />
            </td>
            <td>
              <ListingTableNewItemCell
                date={
                  item?.history?.find((h) => h.status === "Approved")?.timestamp
                }
              />
            </td>
            <td onClick={(e) => e.stopPropagation()}>
              <ListingTableActionCell
                cellData={item}
                cellActions={actions}
                cellActionListener={handleActionClick}
              />
            </td>
          </tr>
        ))}
      </TableContainer>
    </Box>
  );
};

export default LeadsTableComponent;
