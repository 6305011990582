import { Drawer, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import FormBox from "../styled/generic/FormBox";

const useStyles = makeStyles({
  container1: {
    width: "50vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header1: {
    width: "100%",
    height: "4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    paddingRight: "1rem",
    borderBottom: "1px solid #e4e7e7",
  },

  inputCont1: {
    paddingTop: "2rem",
    width: "95%",
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "1rem",
  },
});

const EditLocationDrawer = ({ open, setOpen, organization, address }) => {
  const classes = useStyles();
  //   const [country, setCountry] = useState("");

  //   const [state, setState] = useState(address?.location?.state);
  //   const [city, setCity] = useState();
  //   const [zip, setZip] = useState(address?.location?.postCode);
  //   const [lodaing, setLoading] = useState(false);
  //   const [countryCode, setCountryCode] = useState(
  //     address?.location?.countryCode
  //   );
  //   const [stateCode, setStateCode] = useState(false);
  //   const [locationName, setLocationName] = useState(
  //     address?.location?.locationName
  //   );
  //   const [landmark, setLandmark] = useState(address?.name);
  //   const [address1, setAddress1] = useState(address?.location?.address_line1);
  //   const [address2, setAddress2] = useState(address?.location?.address_line2);

  //   const [addAddres, setAddress] = useState(false);

  //   console.log("address--->>>>>>>>", address);

  //   const dispatch = useDispatch();

  //   const handleSetValues = (e) => {
  //     setCity(e.target.value);
  //   };

  return (
    <Drawer
      open={open}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 55 }}
      anchor="right"
    >
      <div className={classes.container}>
        <div className={classes.inputCont}>
          <FormBox label="City">
            <TextField
              //value={city}
              //onChange={handleSetValues}
              placeholder="Enter City"
              style={{ width: "100%" }}
            />
          </FormBox>
          {/* <FormBox label="Location Name">
            <TextField
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
              placeholder="Enter Location Name"
              fullWidth={true}
            />
          </FormBox> */}
          {/* <FormBox label="Country">
            <TextField
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              placeholder="Enter Country"
              fullWidth={true}
            />
          </FormBox>
          <FormBox label="Country Code">
            <TextField
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              placeholder="Enter Country"
              fullWidth={true}
            />
          </FormBox>

          <FormBox label="State">
            <TextField
              value={state}
              onChange={(e) => setState(e.target.value)}
              placeholder="Enter Country Code"
              style={{ width: "100%" }}
            />
          </FormBox>
          <FormBox label="State Code">
            <TextField
              value={stateCode}
              onChange={(e) => setStateCode(e.target.value)}
              placeholder="Enter State Code"
              style={{ width: "100%" }}
            />
          </FormBox>

          <FormBox label="City">
            <TextField
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="Enter City"
              style={{ width: "100%" }}
            />
          </FormBox>
          <FormBox label="Address 1">
            <TextField
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              placeholder="Enter Address 1"
              style={{ width: "100%" }}
            />
          </FormBox>
          <FormBox label="Address 2">
            <TextField
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              placeholder="Enter Address 2"
              style={{ width: "100%" }}
            />
          </FormBox>
          <FormBox label="Landmark">
            <TextField
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
              placeholder="Enter Landmark"
              style={{ width: "100%" }}
            />
          </FormBox>
          <FormBox label="Zip">
            <TextField
              value={zip}
              onChange={(e) => setZip(e.target.value)}
              placeholder="Enter Zip"
              style={{ width: "100%" }}
            />
          </FormBox> */}
        </div>
        <div
          style={{
            width: "95%",
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <span
            style={{
              height: "2.5rem",
              width: "7rem",
              borderRadius: "0.2rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              background: "#2d76e0",
            }}
          >
            Update
          </span>
          <span
            onClick={() => setOpen(false)}
            style={{
              height: "2.5rem",
              width: "7rem",
              borderRadius: "0.2rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#2d76e0",
              border: " 1px solid #2d76e0",
            }}
          >
            Cancel
          </span>
        </div>
      </div>
    </Drawer>
  );
};

export default EditLocationDrawer;
