import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import { Typography } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import GroupInfoDrawer from "./GroupInfoDrawer";
import IconButton from "@material-ui/core/IconButton";
import { Badge, styled } from "@mui/material";

const useStyles = makeStyles(() => ({
  avatarStyle: {
    height: "35px",
    width: "35px",
  },
  mainDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 0px",
    cursor: "pointer",

  },
  nameBox: {
    fontSize: "16px",
    fontWeight: "520",
    marginLeft: "15px",
    display: "flex",
    alignItems: "center",
  },
  nameChainingStyle: {
    width: "100%",
    fontSize: "12px",
    marginLeft: "15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  moreOptions: {
    color: "white",
  },
  infoDataStyle: {
    maxWidth: "100%",
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Appbar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const {
    conversationId,
    conversationDictionary,
    setConversationDictionary,
    profile,
    getProfileChat,
    setLoadingBool,
    onlineUsers,
  } = props;

  const [groupInfoOpen, setGroupInfoOpen] = useState(false);
  let currentConversation = conversationDictionary[conversationId];

  let primaryUser;
  let displayPicture;
  let displayName;

  if (conversationId) {
    const conversation = conversationDictionary[conversationId];

    if (conversation && conversation.participants) {
      const participants = conversation.participants;

      const otherUsers = participants.filter(
        (participant) => participant._id !== profile?._id
      );

      primaryUser = otherUsers[0];
      if (primaryUser) {
        displayName = primaryUser?.parent?.displayName;
        displayPicture = primaryUser?.parent?.displayPicture;
      }
    }
  } else {
    if (primaryUser) {
      displayName = primaryUser?.parent?.displayName;
      displayPicture = primaryUser?.parent?.displayPicture;
    }
  }

  let nameChaining = "";
  let allParticipants = currentConversation?.participants;

  allParticipants?.length > 0 &&
    allParticipants.map((participant, i) => {
      if (i === allParticipants?.length - 1) {
        nameChaining = nameChaining + participant?.parent?.displayName;
      } else {
        nameChaining = nameChaining + participant?.parent?.displayName + ",";
      }
    });

  return (
    <>
      {conversationId || primaryUser ? (
        <div className={classes.mainDiv}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {currentConversation?.type === "Group" ? (
              <Avatar
                alt={displayName}
                src={conversationDictionary[conversationId]?.groupDP?.url}
                className={classes.avatarStyle}
                onClick={() => {
                  if (primaryUser?._id) {
                    const path = "/profile/" + primaryUser?._id;
                    history.push(path);
                  }
                }}
              />
            ) : (
              <>
                {onlineUsers && onlineUsers.indexOf(primaryUser?._id) > -1 ? (
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                  >
                    <Avatar
                      alt={displayName}
                      src={displayPicture?.thumbUrl}
                      className={classes.avatarStyle}
                      onClick={() => {
                        if (primaryUser?._id) {
                          const path = "/profile/" + primaryUser?._id;
                          history.push(path);
                        }
                      }}
                    />
                  </StyledBadge>
                ) : (
                  <Avatar
                    alt={displayName}
                    src={displayPicture?.thumbUrl}
                    className={classes.avatarStyle}
                    onClick={() => {
                      if (primaryUser?._id) {
                        const path = "/profile/" + primaryUser?._id;
                        history.push(path);
                      }
                    }}
                  />
                )}
              </>
            )}

            {currentConversation?.type === "Group" ? (
              <div className={classes.infoDataStyle}>
                <div className={classes.nameBox}>
                  {currentConversation?.groupName}
                </div>
                <Typography className={classes.nameChainingStyle}>
                  {nameChaining}
                </Typography>
              </div>
            ) : (
              <>
                <Typography className={classes.nameBox}>
                  {displayName}
                </Typography>
              </>
            )}
          </div>

          {currentConversation?.type === "Group" && (
            <>
              <IconButton
                className={classes.moreOptions}
                onClick={() => {
                  setGroupInfoOpen(true);
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <GroupInfoDrawer
                setGroupInfoOpen={setGroupInfoOpen}
                groupInfoOpen={groupInfoOpen}
                conversationDictionary={conversationDictionary}
                conversationId={conversationId}
                setConversationDictionary={setConversationDictionary}
                getProfileChat={getProfileChat}
                setLoadingBool={setLoadingBool}
              />
            </>
          )}
        </div>
      ) : (
        <>
          <ButtonBase>
            <Avatar alt={"displayName"} className={classes.avatarStyle} />
          </ButtonBase>
          <Typography className={classes.nameBox}></Typography>
        </>
      )}
    </>
  );
};

export default Appbar;
