import React, { useState } from "react";
import FormBox from "./FormBox";
import { Box } from "@mui/material";
import Chip from "./Chip";

const YesNoChooser = ({ label, icon, onYes, onNo, defaultYesActive }) => {
  const [yesActive, setYesActive] = useState(defaultYesActive);

  return (
    <FormBox label={label}>
      <Box
        sx={{
          border: "1px solid #E0E0E0",
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 1,
          py: 1,
        }}
      >
        <Box>{icon}</Box>
        <Box display="flex" alignItems="center">
          <Chip
            label="Yes"
            variant="outlined"
            clickable
            onClick={() => {
              onYes(label);
              setYesActive(true);
            }}
            color={yesActive ? "primary" : "default"}
          />
          <Chip
            label="No"
            variant="outlined"
            clickable
            onClick={() => {
              onNo(label);
              setYesActive(false);
            }}
            color={!yesActive ? "primary" : "default"}
          />
        </Box>
      </Box>
    </FormBox>
  );
};

export default YesNoChooser;
