import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, IconButton, useMediaQuery } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useSelector, useDispatch } from "react-redux";
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { allLanguagesName } from "../../styled/CommonComponents/languages.array"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { updateUserInfo } from '../api';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    header: {
        height: "60px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #e4e7e7",
        padding: "0px 25px",
        "& h3": {
            fontSize: "17px",
            fontWeight: "450",
            color: "#170f49"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0px 10px",
        }
    },
    body: {
        width: "100%",
        height: "calc(100% - 65px)",
        padding: "20px 40px 40px",
        paddingRight: "0px",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        [theme.breakpoints.down("md")]: {
            padding: "15px 10px 30px",
        }
    },
    singleMainCont: {
        display: "flex",
        alignItems: "center",
        marginBottom: "20px"
    },
    autocompleteCont: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            width: "90%"
        }
    },
    actionBtnCont: {
        display: "flex",
        alignItems: "center",
    },
    inputText: {
        width: "350px",
        marginRight: "15px",
        [theme.breakpoints.down("md")]: {
            width: "280px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "200px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }
    }
}));

const proficiencyArray = [
    "Elementary Proficiency",
    "Limited Working Proficiency",
    "Professional Working Proficiency",
    "Full Professional Proficiency",
    "Native or Billingual Proficiency",
]

export default function LanguagesEdit({ setOpenEditView }) {
    const classes = useStyles();
    const theme = useTheme()
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const [stateChange, setStateChange] = useState(false)
    const [languageTypeObjArr, setLanguageTypeObjArr] = useState([])
    const [loading, setLoading] = useState(false)

    const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

    useEffect(() => {
        let localLanguages = user?.languages || []
        if (localLanguages && localLanguages.length > 0) {
            let arr = []
            localLanguages.map((languageType) => {
                arr.push({
                    language: languageType?.name || null,
                    proficiency: languageType?.proficiency || null,
                })
            })
            setLanguageTypeObjArr(arr)
        } else {
            setLanguageTypeObjArr([{
                language: null,
                proficiency: null
            }])
        }
    }, [user, user?.languages])


    const addNewLine = () => {
        setLanguageTypeObjArr([...languageTypeObjArr, { language: null, proficiency: null }])
    }

    const deleteLine = (i) => {
        let arr = [...languageTypeObjArr]
        arr.splice(i, 1)
        setLanguageTypeObjArr(arr)
        setStateChange(!stateChange)
    }

    const onChangeValue = (value, i, key) => {
        let arr = [...languageTypeObjArr]
        arr[i][key] = value
        setLanguageTypeObjArr(arr)
        setStateChange(!stateChange)
    }

    const updateProfileSection = async () => {
        setLoading(true)
        let objArr = []
        languageTypeObjArr.map((languageTypeData) => {
            objArr.push({
                name: languageTypeData?.language,
                proficiency: languageTypeData?.proficiency
            })
        })

        const userObj = {
            languages: objArr,
        };

        await updateUserInfo({
            userId: user?._id,
            userInfo: userObj,
        })
            .then((data) => {
                dispatch({
                    type: "AddAuth",
                    payload: {
                        user: {
                            ...user,
                            ...userObj,
                        },
                    },
                });
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            })
    }


    return (
        <>
            <div className={classes.header} >
                <h3>{isSmall ? "Languages" : "Edit Languages"}</h3>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={addNewLine}
                        size={isSmall ? "small" : "medium"}
                    >
                        Add
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        style={{ marginLeft: "10px" }}
                        onClick={updateProfileSection}
                        size={isSmall ? "small" : "medium"}
                    >
                        Save
                    </Button>
                    <IconButton
                        style={{ marginLeft: "10px" }}
                        onClick={() => { setOpenEditView(false) }}
                        size={isSmall ? "small" : "medium"}
                    >
                        <ClearIcon />
                    </IconButton>
                </div>
            </div>
            <div style={{ height: "5px" }} >
                {loading && (<LinearProgress />)}
            </div>
            <div className={classes.body} >
                {languageTypeObjArr && languageTypeObjArr.length > 0 && languageTypeObjArr.map((languageType, i) => (
                    <div key={i} className={classes.singleMainCont} >
                        <div className={classes.autocompleteCont} >
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                value={languageType?.language}
                                options={allLanguagesName}
                                getOptionLabel={(option) => { return option }}
                                getOptionSelected={(option) => {
                                    return option === languageType?.language
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        style={{ margin: "0px" }}
                                        margin="normal"
                                        variant="outlined"
                                        placeholder={"Enter language"}
                                    />
                                )}
                                onChange={(event, value) => {
                                    onChangeValue(value, i, "language")
                                }}
                                className={classes.inputText}
                                size="small"
                            />
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                value={languageType?.proficiency}
                                options={proficiencyArray}
                                getOptionLabel={(option) => { return option }}
                                getOptionSelected={(option) => {
                                    return option === languageType?.proficiency
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        style={{ margin: "0px" }}
                                        margin="normal"
                                        variant="outlined"
                                        placeholder={"Enter proficiency"}
                                    />
                                )}
                                onChange={(event, value) => {
                                    onChangeValue(value, i, "proficiency")
                                }}
                                className={classes.inputText}
                                size="small"
                            />
                        </div>
                        <IconButton
                            style={{ marginRight: "15px" }}
                            onClick={() => { deleteLine(i) }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ))}
            </div>
        </>
    );
}