import React from "react";
import TabBox from "./TabBox";
import TabItem from "./TabItem";
import { Box, InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

const TabsWithSearch = ({
  tabs,
  onTabChange,
  currentTabVariable,
  disableUnderline,
}) => {
  return (
    <TabBox
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      disableUnderline={disableUnderline}
    >
      <Box display="flex" alignItems="center">
        {tabs?.map((tab) => (
          <TabItem
            variant="body1"
            active={currentTabVariable === tab}
            onClick={() => onTabChange(tab)}
            disableUnderline={disableUnderline}
          >
            {tab}
          </TabItem>
        ))}
      </Box>
      <Box>
        <TextField
          placeholder="Search"
          size="small"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </TabBox>
  );
};

export default TabsWithSearch;
