import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Avatar, Box, Chip, Tooltip } from "@material-ui/core";
import moment from "moment";
import Links from "./links/index";
import { useHistory } from "react-router-dom";
import configObject from "../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    transition: 'box-shadow 0.3s',
    '&:hover': {
      boxShadow: theme.shadows[4],
      cursor: 'pointer',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 0.5),
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    fontSize: '0.75rem',
  },
  title: {
    fontWeight: 600,
    fontSize: '0.85rem',
    lineHeight: 1.2,
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0.5, 1),
  },
  body: {
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    lineHeight: 1.3,
    marginBottom: theme.spacing(0.5),
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0.5, 1),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  footerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  chip: {
    height: 20,
    fontSize: '0.6rem',
    marginRight: theme.spacing(0.5),
  },
  date: {
    fontSize: '0.6rem',
    color: theme.palette.text.secondary,
  },
  project: {
    fontSize: '0.7rem',
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const ActivityCard = ({ activity, onClick }) => {
  const classes = useStyles();
  const user = activity?.user || activity?.profile?.parent || {};
  const history = useHistory();

  const handleClick = () => {
    const linkData = getActivityLinkData(activity);
    if (linkData && linkData.link) {
      if (configObject.mode === 'prod') {
        window.open(linkData.link, '_self');
      } else {
        history.push(linkData.link);
      }
    }
  };

  const getActivityLinkData = (activity) => {
    const activityType = activity.type;
    console.log(activityType);

    const navFunction = Links[activityType];
    console.log(navFunction);
    
    if (Links[activityType]) {
      return Links[activityType](activity);
    }

    // Default fallback if no specific link is found
    // return {
    //   name: 'Activity Details',
    //   link: `/activities/${activity._id}`,
    //   // Additional properties can be added here in the future
    // };
  };

  return (
    <Card className={classes.root} onClick={handleClick}>
      <Box className={classes.header}>
        <Avatar 
          className={classes.avatar}
          src={user?.displayPicture?.thumbUrl || user?.displayPicture?.url}
          alt={user?.displayName}
        >
          {user?.displayName?.charAt(0)}
        </Avatar>
        <Typography className={classes.title} title={activity.title}>
          {activity.title}
        </Typography>
      </Box>
      <CardContent className={classes.content}>
        <Typography className={classes.body}>
          {activity.body}
        </Typography>
      </CardContent>
      <Box className={classes.footer}>
        <Box className={classes.footerRow}>
          <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
            {activity.modules && activity.modules.length > 0 ? (
              activity.modules.map((module, index) => (
                <Chip 
                  key={index}
                  label={module || 'N/A'} 
                  size="small" 
                  className={classes.chip}
                />
              ))
            ) : (
              <Chip 
                label="No Module" 
                size="small" 
                className={classes.chip}
              />
            )}
          </Box>
          <Typography className={classes.date}>
            {moment(activity.createdAt).fromNow()}
          </Typography>
        </Box>
        <Box className={classes.footerRow}>
          <Chip 
            label={activity.type || 'N/A'} 
            size="small" 
            className={classes.chip}
          />
          <Tooltip title={activity.project?.displayName || 'No Project'}>
            <Typography className={classes.project}>
              {activity.project?.displayName || 'No Project'}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    </Card>
  );
};

export default ActivityCard;