import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";

export default function PopupMenuList({
  appearContent,
  closeOnClick = true,
  actions,
  secondaryActions,
  minWidth = 200,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <span onClick={handleClick}>{appearContent}</span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <span
          onClick={() => {
            if (closeOnClick) {
              handleClose();
            }
          }}
        >
          <List sx={{ minWidth: minWidth }}>
            {actions?.map((item, i) => (
              <ListItem disablePadding key={i}>
                <ListItemButton onClick={() => item?.action()}>
                  <ListItemText primary={item?.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {secondaryActions && actions && <hr />}
          {secondaryActions && (
            <List sx={{ minWidth: minWidth }}>
              {secondaryActions?.map((item, i) => (
                <ListItem disablePadding key={i}>
                  <ListItemButton onClick={() => item?.action()}>
                    <ListItemText primary={item?.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </span>
      </Popover>
    </>
  );
}
