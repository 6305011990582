import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import ListingRequirementsTableComponent from "../tables/ListingRequirementsTableComponent";
import Api from "../../../../helpers/Api";
import {
  applyListingRequirementApi,
  getListingsForRequirementApi,
} from "../../listing.api";

const ListingsForRequirement = () => {
  const actions = ["TODO"];
  const handleActionClick = () => {};

  const { requirementId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [matches, setMatches] = useState([]);
  const [matchesLoading, setMatchesLoading] = useState(false);

  const getMatches = async () => {
    try {
      setMatchesLoading(true);
      const { data } = await getListingsForRequirementApi({
        requirementId: requirementId,
      });
      let processed = [];
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item?.applicants.includes(user?.profile)) {
          processed.push({ ...item, applied: true });
        } else {
          processed.push({ ...item, applied: false });
        }
      }
      setMatches(processed);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch matches",
        },
      });
    } finally {
      setMatchesLoading(false);
    }
  };

  useEffect(() => {
    getMatches();
  }, []);

  const requestListing = async (listingId) => {
    try {
      const { success } = await Api.post("/listing/app/request", {
        listingId: listingId,
        requirementId: requirementId,
        userId: user?._id,
        profileId: user?.profile,
        applicationType: "Listing",
      });
      if (success) {
        setMatches(
          matches?.map((item) => {
            if (item._id === listingId) {
              return { ...item, applied: true };
            } else {
              return item;
            }
          })
        );

        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Request sent successfully!",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to make request",
        },
      });
    }
  };

  const applyListing = async (listingId) => {
    try {
      const { success } = await applyListingRequirementApi({
        listingId: listingId,
        requirementId: requirementId,
        userId: user?._id,
        profileId: user?.profile,
        applicationType: "Listing",
      });
      if (success) {
        setMatches(
          matches?.map((item) => {
            if (item._id === listingId) {
              return { ...item, applied: true };
            } else {
              return item;
            }
          })
        );

        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Applied successfully!",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to apply for listing",
        },
      });
    }
  };

  return (
    <ListingRequirementsTableComponent
      matches={matches}
      setMatches={setMatches}
      loading={matchesLoading}
      actions={actions}
      handleActionClick={handleActionClick}
      requestListing={requestListing}
      applyListing={applyListing}
    />
  );
};

export default ListingsForRequirement;
