import React, { useEffect, useState } from "react";
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { Button, IconButton } from '@material-ui/core';
import { useSpring, animated } from 'react-spring/web.cjs';
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';

function TransitionComponent(props) {
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles((theme) => ({
    labelSty: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px 0px",
        borderBottom: "1px solid #ececec",
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
            marginLeft: "5px"
        }
    },
    checkAndNameCont: {
        width: "calc(100% - 230px)"
    },
    btnsCont: {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-label": {
            fontSize: "13px"
        },
        "& .MuiIconButton-root": {
            padding: "6px"
        }
    },
    iconBtn: {
        "& .MuiSvgIcon-root": {
            color: "black"
        }
    },
    singleOption: {
        padding: "5px 10px",
        fontSize: "15px",
        fontWeight: "500",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    }
}));

const CategoryTree = ({
    category, searchStr, categoryAction, openOfferingsDrawer
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const [isMatch, setIsMatch] = useState(true);

    const handleLableClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    useEffect(() => {
        if (searchStr && searchStr.length > 0) {
            const patt = new RegExp(searchStr, "i");
            const res = patt.test(category?.name);
            if (res) {
                setIsMatch(true)
            } else {
                setIsMatch(false)
            }
        } else {
            setIsMatch(true)
        }
    }, [searchStr])

    return (<>
        {isMatch ? (
            <StyledTreeItem
                nodeId={category?._id}
                onLabelClick={handleLableClick}
                label={<div className={classes.labelSty} >
                    <div className={classes.checkAndNameCont} >
                        <p>{category?.name || ""}</p>
                    </div>

                    <div className={classes.btnsCont} >
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            startIcon={<VisibilityIcon />}
                            onClick={() => {
                                openOfferingsDrawer(category)
                            }}
                            style={{ marginRight: "15px" }}
                        >
                            Product/Service
                        </Button>
                        <MyPopOver
                            closeOnClick={true}
                            appearContent={<span className={classes.iconBtn} >
                                <IconButton>
                                    <MoreVertIcon />
                                </IconButton>
                            </span>}
                            showContent={<div style={{ padding: "5px 0px" }} >
                                <div
                                    className={classes.singleOption}
                                    onClick={() => { categoryAction("createWithParent", category) }}
                                >
                                    Add Category
                                </div>
                                <div
                                    className={classes.singleOption}
                                    onClick={() => { categoryAction("edit", category) }}
                                >
                                    Edit
                                </div>
                                {category.children && category.children.length > 0 ? null : (
                                    <div
                                        className={classes.singleOption}
                                        onClick={() => { categoryAction("delete", category) }}
                                    >
                                        Delete
                                    </div>
                                )}
                            </div>}
                        />
                    </div>
                </div>}
            >
                {
                    category.children && category.children.map((categoryK, p) => (
                        <CategoryTree
                            category={categoryK}
                            key={p}
                            searchStr={searchStr}
                            categoryAction={categoryAction}
                            openOfferingsDrawer={openOfferingsDrawer}
                        />
                    ))
                }
            </StyledTreeItem>
        ) : (<>
            {category.children && category.children.map((categoryK, p) => (
                <CategoryTree
                    category={categoryK}
                    key={p}
                    searchStr={searchStr}
                    categoryAction={categoryAction}
                    openOfferingsDrawer={openOfferingsDrawer}
                />
            ))}
        </>)}
    </>);
};

export default CategoryTree;