import React, { useState, useEffect } from "react";
import FormBox from "../generic/FormBox";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import keyConfig from "../../../config/keys.config";
import axios from "axios";
import { useDebounce } from "react-use";

const useLocationForm = (size = "medium") => {
  const [placeText, setPlaceText] = useState("");
  const [places, setPlaces] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [mode, setMode] = useState("locality");

  const searchPlaces = async (text) => {
    const options = {
      method: "GET",
      url: "https://address-completion.p.rapidapi.com/v1/geocode/autocomplete",
      params: {
        text: text,
        limit: "15",
        lang: "en",
        countrycodes: "in",
      },
      headers: {
        "X-RapidAPI-Key": keyConfig?.rapidApi,
        "X-RapidAPI-Host": "address-completion.p.rapidapi.com",
      },
    };

    let response = await axios.request(options);

    let places = response?.data?.features;
    let formatted_places = [];

    for (let i = 0; i < places?.length; i++) {
      let place = places[i];

      if (mode === "locality" && !place?.properties?.postcode) {
        continue;
      } else if (mode === "city" && !place?.properties?.city) {
        continue;
      } else if (mode === "state" && !place?.properties?.state) {
        continue;
      }

      if (place?.properties?.name) {
        formatted_places.push(place?.properties);
      } else {
        formatted_places.push({
          ...place?.properties,
          name: place?.properties?.address_line1,
        });
      }
    }

    setPlaces(formatted_places);
  };

  useDebounce(
    () => {
      if (placeText?.length >= 2) {
        searchPlaces(placeText);
      }
    },
    1000,
    [placeText]
  );

  const handleLocationAdd = (newPlace) => {
    if (newPlace?.place_id) {
      setSelectedPlaces([
        ...selectedPlaces,
        {
          id: newPlace?.place_id,
          mode: mode,
          queryString: newPlace?.name,
          resultValue:
            mode === "locality"
              ? newPlace?.name
              : mode === "city"
              ? newPlace?.city
              : newPlace?.state,
          location: newPlace,
        },
      ]);
    }
  };

  const locationForm = (
    <FormBox label="Search by">
      <Box>
        <FormControl>
          <RadioGroup value={mode} onChange={(evt, val) => setMode(val)} row>
            <FormControlLabel
              value="locality"
              control={<Radio />}
              label="Locality"
            />
            <FormControlLabel value="city" control={<Radio />} label="City" />
            <FormControlLabel value="state" control={<Radio />} label="State" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <Autocomplete
          options={places}
          onChange={(evt, newPlace) => handleLocationAdd(newPlace)}
          fullWidth
          getOptionLabel={(option) => option?.name || option}
          popupIcon={<KeyboardArrowDown />}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              placeholder="Location"
              value={placeText}
              onChange={(e) => setPlaceText(e.target.value)}
              size={size}
            />
          )}
        />
      </Box>
    </FormBox>
  );

  return {
    locationForm,
    locations: selectedPlaces,
    setLocations: setSelectedPlaces,
  };
};

export default useLocationForm;

// const { type, title, configTitle } = props;
// const dispatch = useDispatch();
// const [showWorkspaceConfigDialog, setShowWorkspaceConfigDialog] =
//   useState(false);
// const [apps, setApps] = useState([]);
// const [previousApps, setPreviousApps] = useState([]);
// const [config, setConfig] = useState({});
// const [id, setId] = useState(null);
// const { workspaceConfig } = useSelector((state) => state.workspace);
// const workspaceObj = useSelector((state) => state.workspace);

// // Get default / native apps
// const getWorkspaceApps = async () => {
//   try {
//     const data = await Api.post("appModule/apps");
//     if (type === "User") {
//       setApps(data.data.userApps);
//     } else if (type === "Project") {
//       setApps(data.data.projectApps);
//     } else if (type === "Organization") {
//       setApps(data.data.organizationApps);
//     } else {
//       setApps([]);
//     }
//   } catch (err) {
//     dispatch({
//       type: "AddApiAlert",
//       payload: {
//         success: false,
//         message: "An unknown error occurred while fetching workspace apps",
//       },
//     });
//   }
// };
// const getWorkspaceConfig = async () => {
//   if (!workspaceConfig || !workspaceConfig[id]) {
//     try {
//       const { data } = await Api.post("appModule/", {
//         id: id,
//         workspaceType: type,
//       });
//       setConfig(data);
//       let updatedConfig = workspaceConfig;
//       updatedConfig[id] = data;
//       dispatch({
//         type: "AddWorkspace",
//         payload: {
//           workspaceConfig: updatedConfig,
//         },
//       });
//     } catch (err) {
//       dispatch({
//         type: "AddApiAlert",
//         payload: {
//           success: false,
//           message: "An unknown error occurred" + err,
//         },
//       });
//     }
//   } else {
//     setConfig(workspaceConfig[id]);
//   }
// };
// const updateWorkspaceConfig = async () => {
//   try {
//     const { data } = await Api.post("appModule/update", {
//       id: id,
//       workspaceType: type,
//       ...config,
//     });
//     setConfig(data);
//     let updatedConfig = workspaceConfig;
//     updatedConfig[id] = data;
//     dispatch({
//       type: "AddWorkspace",
//       payload: {
//         workspaceConfig: updatedConfig,
//       },
//     });
//     setShowWorkspaceConfigDialog(false);
//     dispatch({
//       type: "AddApiAlert",
//       payload: {
//         success: true,
//         message: "Workspace updated successfully",
//       },
//     });
//   } catch (err) {
//     dispatch({
//       type: "AddApiAlert",
//       payload: {
//         success: false,
//         message: "An unknown error occurred" + err,
//       },
//     });
//   }
// };
// useEffect(() => {
//   if (id) {
//     getWorkspaceApps();
//     getWorkspaceConfig();
//   }
// }, [id]);
// // For making MUI dropdown default value selection
// useEffect(() => {
//   if (
//     id &&
//     apps &&
//     config &&
//     apps.length &&
//     config.apps &&
//     config.apps.length
//   ) {
//     const allApps = apps;
//     const configApps = config.apps;
//     let common = [];
//     for (let i = 0; i < allApps.length; i++) {
//       let allApp = allApps[i];
//       for (let j = 0; j < configApps.length; j++) {
//         let configApp = configApps[j];
//         if (configApp._id === allApp._id) {
//           common.push(allApp);
//         }
//       }
//     }
//     setPreviousApps(common);
//   }
// }, [id, apps, config]);
// const workspaceConfigDialog = (
//   <WorkspaceConfigDialog
//     showWorkspaceConfigDialog={showWorkspaceConfigDialog}
//     setShowWorkspaceConfigDialog={setShowWorkspaceConfigDialog}
//     title={title}
//     configTitle={configTitle}
//     configAppList={apps}
//     config={config}
//     setConfig={setConfig}
//     saveFunction={updateWorkspaceConfig}
//     previousApps={previousApps}
//   />
// );
// return {
//   showWorkspaceConfigDialog,
//   setShowWorkspaceConfigDialog,
//   id,
//   setId,
//   workspaceConfigDialog,
//   config,
// };
