import React from "react";
import FormBox from "./FormBox";
import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";

const CheckGroupGrid = ({
  label,
  options,
  selectedOptions,
  setSelectedOptions,
  xs = 6,
}) => {
  return (
    <FormBox label={label}>
      <Grid sx={{ width: "100%" }} container>
        {options?.map((item) => (
          <Grid item xs={xs}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOptions.includes(item)}
                  onChange={(evt, checked) => {
                    if (checked) {
                      setSelectedOptions([...selectedOptions, item]);
                    } else {
                      setSelectedOptions(
                        selectedOptions?.filter((o) => o !== item)
                      );
                    }
                  }}
                />
              }
              label={item}
            />
          </Grid>
        ))}
      </Grid>
    </FormBox>
  );
};

export default CheckGroupGrid;
