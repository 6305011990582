import React, { useEffect, useState } from "react";
import { IconButton, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import emptyIcon from "../../../Assets/vivekEmpty.svg";
import EstimationAnalyticsCardSvg from "../../../Assets/proj_mgmt_logo.svg";
import { useSelector } from "react-redux";
import WorkScheduleConfigModal from "./WorkScheduleConfigModal";
import Api from "../../../helpers/Api";
const { addMinutes } = require("date-fns");

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "100%",
    height: "370px",
    borderRadius: "10px",
    marginBottom: "20px",
    padding: "10px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 5px",
    },
  },
  cardTopHeading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > h3": {
      fontWeight: "510",
      fontSize: "17px",
      color: "#193B56",
    },
  },
  createBtn: {
    width: "25px",
    height: "25px",
    borderRadius: "6px",
    backgroundColor: "#c1def6",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#70b8f4",
      color: "white",
    },
  },
  topBox: {
    width: "100%",
    padding: "3px 10px",
    display: "flex",
    alignItems: "flex-start",
    marginTop: "10px",
    cursor: "pointer",
    marginBottom: "20px",
    "& > p": {
      fontSize: "15px",
      marginTop: "10px",
      color: "#696969",
    },
    "&:hover": {
      backgroundColor: "#edecec",
    },
  },
  topBoxSvgCont: {
    width: "120px",
    height: "70px",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
    "& > img": {
      width: "95px",
      height: "95px",
    },
  },
  emptyCont: {
    display: "flex",
    width: "100%",
    height: "175px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    "& img": {
      height: "100px",
      width: "auto",
    },
    "& p": {
      fontSize: "12px",
      color: "gray",
      textAlign: "center",
      marginTop: "5px",
    },
  },
  tabs: {
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start",
    },
  },
}));

const WorkScheduleCard = ({ projectId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [showConfigureModal, setShowConfigureModal] = useState(false);

  const { teamId } = useParams();
  const [org, setOrg] = useState(null);

  const { user, userProfile } = useSelector((state) => state.auth);

  const getProject = async () => {
    try {
      const { data } = await Api.post("/project/org", { projectId: projectId });
      if (setOrg(data.organization)) {
        setOrg(data.organization);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (projectId) {
      getProject();
    }
  }, [projectId]);

  return (
    <>
      <Paper className={classes.cardContainer}>
        <div className={classes.cardTopHeading}>
          <h3>Work Schedule</h3>
          <IconButton className={classes.createBtn} color="primary">
            +
          </IconButton>
        </div>
        <Paper
          elevation={1}
          className={classes.topBox}
          onClick={() => {
            if (projectId && org?._id) {
              history.push(
                `/organization/workschedule/${org?.team}?projectId=${projectId}`
              );
            } else {
              history.push(`/organization/workschedule/${teamId}`);
            }
          }}
        >
          <div className={classes.topBoxSvgCont}>
            <img src={EstimationAnalyticsCardSvg} />
          </div>
          <p>Manage work schedule</p>
        </Paper>
        <div className={classes.emptyCont}>
          <img src={emptyIcon} />
          <p>
            There is nothing to show here.
            <br />
            <span
              style={{
                color: theme.palette.primary.main,
                marginLeft: "3px",
                cursor: "pointer",
              }}
              onClick={() => setShowConfigureModal(true)}
            >
              Click here to configure
            </span>
          </p>
        </div>
      </Paper>
      <WorkScheduleConfigModal
        open={showConfigureModal}
        setOpen={setShowConfigureModal}
      />
    </>
  );
};

export default WorkScheduleCard;
