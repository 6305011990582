import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Api from "../../helpers/Api";
import { useMediaQuery } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import LogoPrimary from "../../Assets/RealList-01.svg";
import cx from "clsx";
import { inviteAccept } from "./api.call";
import teamUtils from "../team/team.utils";
import AccessControlStoreHook from "../AccessControl/AccessControlStoreHook";

const useStyles = makeStyles((theme) => ({
  root: {},

  row: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },

  col: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },

  logoBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
  },

  logoStyle: {
    height: "80px",
    width: "Auto"
  },

  logoTextSize: {
    fontWeight: "800",
    fontSize: "2rem",
    color: theme.palette.primary.main,
  },

  authParent: {
    paddingTop: "50px",
    minHeight: "100vh",
    backgroundColor: "rgb(245, 245, 242)",
  },

  center: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    paddingTop: "160px",
  },

  progressBox: {
    // height: "100vh",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  },

  mainTextBox: {
    display: "flex",
    alignItems: "center",
    maxWidth: "60%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  icon: {
    height: "50px",
    [theme.breakpoints.down("sm")]: {
      height: "75px",
    },
    // height: "90px",
    // color: theme.palette.primary.main,
  },

  organizatioName: {
    textDecoration: "underline",
  },

  button: {
    marginTop: "30px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  loaderBox: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export default function InvitationLinkHandler(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const { invitationId } = useParams();

  const state = useSelector((state) => state);
  const { user } = useSelector((state) => state.auth);
  let { invitations } = useSelector((state) => state.team);

  const { handleTeams } = teamUtils;
  const { updateAccessByTeam } = AccessControlStoreHook()

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [teamPath, setTeamPath] = useState("/");
  const [loading, setLoading] = useState(true);
  const [acceptTeamData, setAcceptTeamData] = useState(null);

  function handleClick(el) {
    history.push(teamPath);
  }

  useEffect(() => {
    if (user?._id && user?.profile) {
      inviteAccept({
        invite: invitationId,
        acceptBy : "_id",
        profile: user.profile,
      })
        .then((teamData) => {
          setAcceptTeamData(teamData)
          let filteredInvites = invitations.filter((el) => el._id !== invitationId);
          dispatch({
            type: "AddTeam",
            payload: {
              invitations: filteredInvites,
            },
          });
          if (teamData?.parentModelName === "Project") {
            setTeamPath(`/project/${teamData?._id}`);
          } else if (teamData?.parentModelName === "Organization") {
            setTeamPath(`/organization/${teamData?._id}`);
          }
          if (teamData?.parentModelName === "Project" || teamData?.parentModelName === "Organization") {
            handleTeams([teamData], state, dispatch);
            updateAccessByTeam(teamData?._id)
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch({
        type: "AddAuth",
        payload: {
          redirect: {
            type: "Invite",
            payload: invitationId,
          },
        },
      });

      history.push("/authenticate");
    }
  }, [user?._id]);

  return (
    <div className={classes.root}>
      {user?._id ? (
        !loading ? (
          <>
            <div className={classes.center}>
              <div className={classes.logoBox}>
                <img src={LogoPrimary} className={classes.logoStyle} />
              </div>
              <div className={classes.mainTextBox}>
                <Typography variant="h5" align="center">
                  Invitation For{" "}
                  <span className={classes.organizatioName}>
                    {acceptTeamData?.parent?.displayName || ""}
                  </span>{" "}
                  {acceptTeamData?.parentModelName} Is Accepted
                </Typography>
              </div>
              <Button className={classes.button} onClick={handleClick}>
                Go to {acceptTeamData?.parentModelName}
              </Button>
            </div>
          </>
        ) : (
          <div className={classes.loaderBox}>
            <CircularProgress size={isSmall ? "50px" : "80px"} />
          </div>
        )
      ) : (
        <div />
      )}
    </div>
  );
}