import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Button } from "@material-ui/core";
import { useTheme } from "styled-components";
import { useParams, useHistory } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ImportLibraryBodyAccordion from "./Import.Library.Body.Accordion";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%"
    },
    accordionHead: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px 10px",
        border: "1px solid #ececec",
        cursor: "pointer"
    },
    headLeft: {
        display: "flex",
        alignItems: "center",
    },
    details: {
        marginLeft: "7px",
        "& h3": {
            fontSize: "16px",
            fontWeight: "500",
            marginBottom: "3px"
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "400",
            color: "gray"
        }
    },
    expanded: {
        transform: "rotate(180deg)",
        transition: "all .3s ease-in-out",
    },
    collapse: {
        transform: "rotate(0deg)",
        transition: "all .3s ease-in-out",
    },
}));

const ProfileLibrarayAccordion = ({
    profile, selectedLibrarIds,
    setSelectedLibrarIds, selectedLibrarIdProfileIdObjArr,
    setSelectedLibrarIdProfileIdObjArr,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();

    const [expend, setExpend] = React.useState(false);

    return (
        <div className={classes.mainCont} >
            <div
                className={classes.accordionHead}
                onClick={() => { setExpend(!expend) }}
            >
                <div className={classes.headLeft} >
                    <Avatar src={profile?.parent?.displayPicture?.url} alt={profile?.parent?.displayName} />
                    <div className={classes.details} >
                        <h3>
                            {profile?.parent?.displayName}
                        </h3>
                        <p>
                            {profile?.parent?.username}
                        </p>
                    </div>
                </div>

                <KeyboardArrowDownIcon className={expend ? classes.expanded : classes.collapse} />
            </div>
            <Collapse in={expend} >
                <div>
                    {expend && (
                        <ImportLibraryBodyAccordion
                            profile={profile}
                            selectedLibrarIds={selectedLibrarIds}
                            setSelectedLibrarIds={setSelectedLibrarIds}
                            selectedLibrarIdProfileIdObjArr={selectedLibrarIdProfileIdObjArr}
                            setSelectedLibrarIdProfileIdObjArr={setSelectedLibrarIdProfileIdObjArr}
                        />
                    )}
                </div>
            </Collapse>
        </div>
    );
};

export default ProfileLibrarayAccordion;